import React, { useEffect, useState } from "react";

import NavigationBarContainer from "../NavigationBarContainer";
import UbetalteIleggelserFilterForm from "./UbetalteIleggelserFilterForm";

import { rapporter } from "../../../constants/titles";
import UbetalteIleggelserList from "./UbetalteIleggelserList";
import { useSelector } from "react-redux";
import Excel from "../../Common/Excel";
import { maxRows } from "../../../actions/rapporter/ubetalteIleggelserActions";

const UbetalteIleggelserPage = () => {
  const [oppdatertTid, setOppdatertTid] = useState("");
  const [sort, setSort] = useState({
    cellKey: "belop",
    orders: "desc",
  });

  const ubetalteIleggelserKjennemerkeGroups = useSelector(
    (state) => state.rapporter.ubetalteIleggelserKjennemerkeGroups
  );

  useEffect(() => {
    document.title = rapporter.ubetalteIleggelser;
  }, []);

  const ubetalteIleggelserExcel = () => {
    const data = ubetalteIleggelserKjennemerkeGroups.map(
      (ubetalteIleggelserKjennemerkeGroup) => ({
        Regnr: ubetalteIleggelserKjennemerkeGroup.kjennemerke,
        Nasjon: ubetalteIleggelserKjennemerkeGroup.nasjon,
        Fabrikkmerke: ubetalteIleggelserKjennemerkeGroup.fabrikkmerke,
        Kjøretøystype: ubetalteIleggelserKjennemerkeGroup.kjoretoyType,
        Farge: ubetalteIleggelserKjennemerkeGroup.farge,
        Beløp: ubetalteIleggelserKjennemerkeGroup.belop,
        Antall: ubetalteIleggelserKjennemerkeGroup.antall,
      })
    );

    return [{ name: "Ubetalte Ileggelser", data: data }];
  };

  const rader = () => {
    if (
      ubetalteIleggelserKjennemerkeGroups?.length &&
      ubetalteIleggelserKjennemerkeGroups.length >= maxRows // Litt rar då den ikke kan være større enn maxRows...
    ) {
      return `Fler enn ${maxRows} rader`;
    }
    return `${ubetalteIleggelserKjennemerkeGroups?.length} rader`;
  };

  return (
    <React.Fragment>
      <NavigationBarContainer />
      <UbetalteIleggelserFilterForm
        setOppdatertTid={setOppdatertTid}
        oppdatertTid={oppdatertTid}
        sort={sort}
      />
      <div style={{ paddingTop: 30, paddingLeft: 50 }}>
        <Excel
          element={(onClick) => (
            <button
              className="btn btn-bym-medium btn-bym-action"
              onClick={onClick}
            >
              Lag rapport
            </button>
          )}
          filename="UbetalteIleggelser.xlsx"
          sheets={ubetalteIleggelserExcel(ubetalteIleggelserExcel)}
        />
      </div>
      <div className="rapport-table">
        <div className="pull-right list-page-total" style={{ paddingTop: 20 }}>
          {rader()}
        </div>
        <UbetalteIleggelserList
          setOppdatertTid={setOppdatertTid}
          setSort={setSort}
          sort={sort}
        />
      </div>
    </React.Fragment>
  );
};

export default UbetalteIleggelserPage;
