import {
  handleChangeActionType,
  handleChangeAllActionType,
  handleChangeToInitStateActionType,
} from "../../actions/handleChangeAction";

export const INITIAL_STATE = {
  ileggelsesnummer: "",
  fraDato: "",
  fraTid: "",
  tilDato: "",
  tilTid: "",
  tjenestenummer: "",
  hasMiljogebyrId: false,
  avsluttet: false,
  gatenavn: "",
  gatenummer: "",
  bydel: "",
  overtredelseskode: "",
};

export default function miljokontrollOversiktFilterFormReducer(
  state = { ...INITIAL_STATE },
  action
) {
  switch (action.type) {
    case handleChangeActionType("MILJOKONTROLL_OVERSIKT_FILTER_FORM"):
      return { ...state, [action.variableName]: action.payload };
    case handleChangeAllActionType("MILJOKONTROLL_OVERSIKT_FILTER_FORM"):
      return { ...action.payload };
    case handleChangeToInitStateActionType(
      "MILJOKONTROLL_OVERSIKT_FILTER_FORM"
    ):
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}
