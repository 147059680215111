import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";

import ModalGenericPostCancelContainer from "../../../Common/ModalGenericPostCancelContainer";

import { getIleggelse as getIleggelseAction } from "../../../../actions/ileggelserActions";
import { putIleggelseEttersendelserTilGodkjenning as putIleggelseEttersendelserTilGodkjenningAction } from "../../../../actions/ettersendelserActions";

class SendTilGodkjenningContainer extends React.Component {
  static propTypes = {
    ileggelse: PropTypes.object,
    latestEttersendelseId: PropTypes.string,
    getIleggelse: PropTypes.func.isRequired,
    putIleggelseEttersendelserTilGodkjenning: PropTypes.func.isRequired,
    reloadHistorikk: PropTypes.func,
  };

  handleApiCall(ettersendelseId, data) {
    return this.props
      .putIleggelseEttersendelserTilGodkjenning(ettersendelseId, data)
      .then(() => {
        this.props.getIleggelse(this.props.ileggelse.id);
        this.props.reloadHistorikk();
        return Promise.resolve();
      })
      .catch((error) => Promise.reject(error));
  }

  render() {
    if (
      this.props.latestEttersendelseId &&
      this.props.ileggelse.isTilEttersendelse &&
      !this.props.ileggelse.isMakulert &&
      this.props.ileggelse.forsinketMakuleringStatus &&
      !this.props.ileggelse.forsinketMakuleringStatus.deletePossible &&
      !this.props.ileggelse.isBetalt
    ) {
      return (
        <ModalGenericPostCancelContainer
          id={this.props.latestEttersendelseId}
          apiCallData={{ tilGodkjenning: true }}
          modalTitle={`Send ileggelse ileggelse ${this.props.ileggelse.ileggelsesnummer} til godkjenning`}
          submitButtonText="Send til godkjenning"
          submittingButtonText="Sender til godkjenning..."
          successPostCallFlashMessage="Endring fullført"
          errorPostCallFlashMessage="Endring feilet"
          handleApiCall={this.handleApiCall.bind(this)}
          openModalComponentFunction={(props) => (
            <Button
              className="btn btn-bym-standard"
              style={{ marginLeft: 20 }}
              onClick={props.openModal}
            >
              Send til godkjenning
            </Button>
          )}
        >
          <p>{`Vil du sende ileggelse ${this.props.ileggelse.ileggelsesnummer} til godkjenning?`}</p>
        </ModalGenericPostCancelContainer>
      );
    }
    return <span />;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ileggelse: state.ileggelse,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getIleggelse: (ileggelseId) => dispatch(getIleggelseAction(ileggelseId)),
    putIleggelseEttersendelserTilGodkjenning: (ileggelseId, data) =>
      dispatch(
        putIleggelseEttersendelserTilGodkjenningAction(ileggelseId, data)
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SendTilGodkjenningContainer);
