export const getIleggelserVedtakVenterTitle = (reportCountData) => {
  return reportCountData &&
    (reportCountData.a_IleggelseVedtakInTimeQuarantineCount ||
      reportCountData.a_IleggelseVedtakInTimeQuarantineCount === 0)
    ? `Klagevedtak som venter på å bli sendt (${reportCountData.a_IleggelseVedtakInTimeQuarantineCount})`
    : "Klagevedtak som venter på å bli sendt";
};

export const getIleggelserVedtakStoppetTitle = (reportCountData) => {
  return reportCountData &&
    (reportCountData.b_IleggelseVedtakStoppetAvHgkSystemetCount ||
      reportCountData.b_IleggelseVedtakStoppetAvHgkSystemetCount === 0)
    ? `Klagevedtak stoppet av fagsystemet (${reportCountData.b_IleggelseVedtakStoppetAvHgkSystemetCount})`
    : "Klagevedtak stoppet av fagsystemet";
};

export const getIleggelserVedtakKlarTitle = (reportCountData) => {
  return reportCountData &&
    (reportCountData.c_IleggelseVedtakWillBeSentCount ||
      reportCountData.c_IleggelseVedtakWillBeSentCount === 0)
    ? `Klagevedtak klare til å sendes (${reportCountData.c_IleggelseVedtakWillBeSentCount})`
    : "Klagevedtak klare til å sendes";
};

export const getIleggelserVedtakBehandlesInnkrevingTitle = (
  reportCountData
) => {
  return reportCountData &&
    (reportCountData.d_IleggelseVedtakInIneQueueCount ||
      reportCountData.d_IleggelseVedtakInIneQueueCount === 0)
    ? `Klagevedtak under behandling hos Innkreving (${reportCountData.d_IleggelseVedtakInIneQueueCount})`
    : "Klagevedtak under behandling hos Innkreving";
};

export const getIleggelserVedtakFeiletTitle = (reportCountData) => {
  return reportCountData &&
    (reportCountData.f_IleggelseVedtakHasBeenRejectedCount ||
      reportCountData.f_IleggelseVedtakHasBeenRejectedCount === 0)
    ? `Klagevedtak feilet hos Innkreving (${reportCountData.f_IleggelseVedtakHasBeenRejectedCount})`
    : "Klagevedtak feilet hos Innkreving";
};

export const getIleggelserVedtakVellykketTitle = (reportCountData) => {
  return reportCountData &&
    (reportCountData.e_IleggelseVedtakHasBeenAcceptedCount ||
      reportCountData.e_IleggelseVedtakHasBeenAcceptedCount === 0)
    ? `Klagevedtak som er behandlet (${reportCountData.e_IleggelseVedtakHasBeenAcceptedCount})`
    : "Klagevedtak som er behandlet";
};

export const getIleggelserVedtakVenterHeaders = {
  Saksnummer: { cellKey: "saksnummer" },
  Saksbehandler: {},
  Vedtaksdato: { cellKey: "vedtaksdato" },
  "Ilegg.nr.": {},
  Ileggelsestype: {},
  Klagekode: { cellKey: "klagekode" },
  Navn: {},
  Adresse: {},
  "Postnr.": {},
  Poststed: {},
  Nasjon: { cellKey: "land" },
};

export const getIleggelserVedtakStoppetHeaders = {
  Saksnummer: { cellKey: "saksnummer" },
  Saksbehandler: {},
  Vedtaksdato: { cellKey: "vedtaksdato" },
  "Ilegg.nr.": {},
  Ileggelsestype: {},
  Klagekode: { cellKey: "klagekode" },
  Navn: {},
  Adresse: {},
  "Postnr.": {},
  Poststed: {},
  Nasjon: { cellKey: "land" },
  Feil: {},
};

export const getIleggelserVedtakKlarHeaders = {
  Saksnummer: { cellKey: "saksnummer" },
  Saksbehandler: {},
  Vedtaksdato: { cellKey: "vedtaksdato" },
  "Ilegg.nr.": {},
  Ileggelsestype: {},
  Klagekode: { cellKey: "klagekode" },
  Navn: {},
  Adresse: {},
  "Postnr.": {},
  Poststed: {},
  Nasjon: { cellKey: "land" },
};

export const getIleggelserVedtakBehandlesInnkrevingHeaders = {
  Saksnummer: { cellKey: "saksnummer" },
  Saksbehandler: {},
  Vedtaksdato: { cellKey: "vedtaksdato" },
  "Ilegg.nr.": {},
  Ileggelsestype: {},
  Klagekode: { cellKey: "klagekode" },
  Navn: {},
  Adresse: {},
  "Postnr.": {},
  Poststed: {},
  Nasjon: { cellKey: "land" },
  "Sent INE dato": { cellKey: "sent_to_ine" },
};

export const getIleggelserVedtakFeiletHeaders = {
  Saksnummer: { cellKey: "saksnummer" },
  Saksbehandler: {},
  Vedtaksdato: { cellKey: "vedtaksdato" },
  "Ilegg.nr.": {},
  Ileggelsestype: {},
  Klagekode: { cellKey: "klagekode" },
  Navn: {},
  Adresse: {},
  "Postnr.": {},
  Poststed: {},
  Nasjon: { cellKey: "land" },
  "Behandlet INE dato": { cellKey: "received_from_ine" },
  Feil: {},
};

export const getIleggelserVedtakVellykketHeaders = {
  Saksnummer: { cellKey: "saksnummer" },
  Saksbehandler: {},
  Vedtaksdato: { cellKey: "vedtaksdato" },
  "Ilegg.nr.": {},
  Ileggelsestype: {},
  Klagekode: { cellKey: "klagekode" },
  Navn: {},
  Adresse: {},
  "Postnr.": {},
  Poststed: {},
  Nasjon: { cellKey: "land" },
  "Behandlet INE dato": { cellKey: "received_from_ine" },
};
