import * as piggdekkontrollerActions from "../actions/piggdekkontrollerActions";

const INITIAL_STATE = [];

export default function piggdekkontrollerReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case piggdekkontrollerActions.GET_PIGGDEKKONTROLLER_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
