import React from "react";
import CommonNavigationBar from "../Common/NavigationBar";

import sitePaths from "../../constants/sitePaths";

const NavigationBar = () => {
  const getIleggelserPath = () => {
    switch (window.location.pathname) {
      case sitePaths.innkreving.ileggelser.venter.shortUrl:
        return sitePaths.innkreving.ileggelser.venter.shortUrl;
      case sitePaths.innkreving.ileggelser.stoppet.shortUrl:
        return sitePaths.innkreving.ileggelser.stoppet.shortUrl;
      case sitePaths.innkreving.ileggelser.klar.shortUrl:
        return sitePaths.innkreving.ileggelser.klar.shortUrl;
      case sitePaths.innkreving.ileggelser.behandlesInnkreving.shortUrl:
        return sitePaths.innkreving.ileggelser.behandlesInnkreving.shortUrl;
      case sitePaths.innkreving.ileggelser.feilet.shortUrl:
        return sitePaths.innkreving.ileggelser.feilet.shortUrl;
      case sitePaths.innkreving.ileggelser.vellykket.shortUrl:
        return sitePaths.innkreving.ileggelser.vellykket.shortUrl;
      default:
        return sitePaths.innkreving.ileggelser.default;
    }
  };

  const getMiljogebyrPath = () => {
    switch (window.location.pathname) {
      case sitePaths.innkreving.miljogebyr.venter.shortUrl:
        return sitePaths.innkreving.miljogebyr.venter.shortUrl;
      case sitePaths.innkreving.miljogebyr.stoppet.shortUrl:
        return sitePaths.innkreving.miljogebyr.stoppet.shortUrl;
      case sitePaths.innkreving.miljogebyr.klar.shortUrl:
        return sitePaths.innkreving.miljogebyr.klar.shortUrl;
      case sitePaths.innkreving.miljogebyr.behandlesInnkreving.shortUrl:
        return sitePaths.innkreving.miljogebyr.behandlesInnkreving.shortUrl;
      case sitePaths.innkreving.miljogebyr.feilet.shortUrl:
        return sitePaths.innkreving.miljogebyr.feilet.shortUrl;
      case sitePaths.innkreving.miljogebyr.vellykket.shortUrl:
        return sitePaths.innkreving.miljogebyr.vellykket.shortUrl;
      default:
        return sitePaths.innkreving.miljogebyr.default;
    }
  };

  const getNavigationItems = () => {
    return [
      { navn: "Ileggelser", path: getIleggelserPath() },
      { navn: "Miljøgebyr", path: getMiljogebyrPath() },
    ];
  };

  return (
    <div>
      <CommonNavigationBar navigationItems={getNavigationItems()} />
    </div>
  );
};

export default NavigationBar;
