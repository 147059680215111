import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { default as CommonHistorikk } from "../../../Common/Detalj/Historikk";
import { getAllMiljoKontrollHistorikk } from "../../../../actions/miljokontrollerActions"
import { historikkToHistorikkComponentData } from "../../../../utils/historikkUtils";

const Historikk = ({miljoKontrollId}) => {

  const dispatch = useDispatch();
  const historikkData = useSelector((store) =>
    store.miljokontrollHistorikk
  );

  useEffect(() => {
    const fetchMiljoKontrollHistorikk = async () => {
      await dispatch(getAllMiljoKontrollHistorikk(miljoKontrollId));
    }

    fetchMiljoKontrollHistorikk()
  }, [miljoKontrollId, dispatch]);
    
  return (
    <>
      <h2 style={{fontSize: 19, fontWeight: 'bold'}}>Historikk</h2>
      <CommonHistorikk historikkArray={historikkToHistorikkComponentData(historikkData)} />
    </>
  );
}

export default Historikk;