// import PropTypes from "prop-types";
import React from "react";
import SpinLoader from "../Common/SpinLoader";

import Gallery from "../Common/Gallery";

import { authorized } from "../../utils/authorizationUtils";
import { bypatruljen, juridisk } from "../../constants/roles";

class BilderAndObservasjonsbilder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingImages: false,
    };
  }

  isAuthorizedForToastUi = () =>
    authorized([
      bypatruljen.saksbehandler,
      bypatruljen.saksbehandleradmin,
      juridisk.saksbehandler,
    ]);

  spinnerLoaderProps = {
    height: 42,
    width: 42,
    thickness: 3,
    pColor: "#ffffff",
    sColor: "#4d4d4d",
  };

  isMiljogebyrBilde = (bildeId) => {
    const { miljogebyrThumbnailBilder } = this.props;
    return miljogebyrThumbnailBilder.some((bilde) => bilde.id === bildeId);
  };

  getIndex1Index2ForBildeInDatoSortedBilder = (bildeId) => {
    const { datoSortedThumbnailBilder } = this.props;

    for (let index1 = 0; index1 < datoSortedThumbnailBilder.length; index1++) {
      const datoBilder = datoSortedThumbnailBilder[index1];
      if (!datoBilder || !datoBilder.images) {
        return { index1: null, index2: null };
      }
      for (let index2 = 0; index2 < datoBilder.images.length; index2++) {
        const bilde = datoBilder.images[index2];
        if (bilde.id === bildeId) {
          return { index1: index1, index2: index2 };
        }
      }
    }
    return { index1: null, index2: null };
  };

  getBildeUrl = (bildeId) => {
    const { datoSortedThumbnailBilder, miljogebyr } = this.props;

    const isMiljogebyrBilde = this.isMiljogebyrBilde(bildeId);
    if (miljogebyr && isMiljogebyrBilde) {
      return `klage/miljogebyrer/${miljogebyr.id}/bilder/${bildeId}`;
    }
    const { index1: bildeIndex1, index2: bildeIndex2 } =
      this.getIndex1Index2ForBildeInDatoSortedBilder(bildeId);

    let bilde =
      bildeIndex1 || bildeIndex1 === 0 || bildeIndex2 || bildeIndex2 === 0
        ? datoSortedThumbnailBilder[bildeIndex1].images[bildeIndex2]
        : null;
    if (bilde && bilde.orginalMiljoKontrollObservasjonBildeId) {
      const { index1: orginalBildeIndex1, index2: orginalBildeIndex2 } =
        this.getIndex1Index2ForBildeInDatoSortedBilder(
          bilde.orginalMiljoKontrollObservasjonBildeId
        );

      bilde =
        orginalBildeIndex1 ||
        orginalBildeIndex1 === 0 ||
        orginalBildeIndex2 ||
        orginalBildeIndex2 === 0
          ? datoSortedThumbnailBilder[orginalBildeIndex1].images[
              orginalBildeIndex2
            ]
          : null;
    }
    const observasjonId = bilde ? bilde.observasjonId : null;
    bildeId = bilde ? bilde.id : bildeId;

    return `klage/miljogebyrer/${miljogebyr.id}/tidskontroller/${miljogebyr.miljoKontrollId}/observasjoner/${observasjonId}/bilder/${bildeId}`;
  };

  getToastUiUrl = (bildeId) => {
    return `${this.getBildeUrl(bildeId)}/toastui`;
    // const { datoSortedThumbnailBilder, miljogebyr } = this.props;
    // const isMiljogebyrBilde = this.isMiljogebyrBilde(bildeId);
    // if (miljogebyr && isMiljogebyrBilde) {
    //   return `klage/miljogebyrer/${miljogebyr.id}/bilder/${bildeId}/toastui`;
    // }
    // const { index1: bildeIndex1, index2: bildeIndex2 } =
    //   this.getIndex1Index2ForBildeInDatoSortedBilder(bildeId);
    // let bilde =
    //   bildeIndex1 || bildeIndex1 === 0 || bildeIndex2 || bildeIndex2 === 0
    //     ? datoSortedThumbnailBilder[bildeIndex1].images[bildeIndex2]
    //     : null;
    // if (bilde && bilde.orginalMiljoKontrollObservasjonBildeId) {
    //   const { index1: orginalBildeIndex1, index2: orginalBildeIndex2 } =
    //     this.getIndex1Index2ForBildeInDatoSortedBilder(
    //       bilde.orginalMiljoKontrollObservasjonBildeId
    //     );
    //   bilde =
    //     orginalBildeIndex1 ||
    //     orginalBildeIndex1 === 0 ||
    //     orginalBildeIndex2 ||
    //     orginalBildeIndex2 === 0
    //       ? datoSortedThumbnailBilder[orginalBildeIndex1].images[
    //           orginalBildeIndex2
    //         ]
    //       : null;
    // }
    // const observasjonId = bilde ? bilde.observasjonId : null;
    // bildeId = bilde ? bilde.id : bildeId;
    // return `klage/miljogebyrer/${miljogebyr.id}/tidskontroller/${miljogebyr.miljoKontrollId}/observasjoner/${observasjonId}/bilder/${bildeId}/toastui`;
  };

  render() {
    const { datoSortedThumbnailBilder, datoSortedLightboxBilder } = this.props;
    const { isLoadingImages } = this.state;
    return (
      <section style={{ marginRight: 50, marginLeft: 50 }}>
        <div style={{ marginTop: 10 }}>
          {isLoadingImages && (
            <div style={{ margin: "auto", width: 0 }}>
              <SpinLoader {...this.spinnerLoaderProps} />
            </div>
          )}
        </div>
        {datoSortedThumbnailBilder &&
          datoSortedThumbnailBilder.length > 0 &&
          datoSortedThumbnailBilder.map((datoBilder, index) => (
            <div key={`${datoBilder.dato}${datoBilder.tidspan}`}>
              <h3
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                  marginTop: 20,
                  marginBottom: 20,
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
              >
                {datoBilder.dato} {datoBilder.tidspan}
              </h3>
              <Gallery
                images={datoBilder.images}
                lightboxImages={datoSortedLightboxBilder[index]?.images}
                isAuthorizedForToastUi={this.isAuthorizedForToastUi()}
                getToastUiUrl={this.getToastUiUrl}
                getBildeUrl={this.getBildeUrl}
              />
            </div>
          ))}
      </section>
    );
  }
}

export default BilderAndObservasjonsbilder;
