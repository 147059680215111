export const CreateNewReducer = (
  state,
  action,
  actionType,
  firstItem = false
) => {
  switch (action.type) {
    case `GET_ALL_${actionType}_SUCCESS`:
      return GetAll(action);
    case `GET_ONE_${actionType}_SUCCESS`:
      return GetOne(state, action.payload);
    case `POST_${actionType}_SUCCESS`:
      return Post(state, action.payload, firstItem);
    case `DELETE_${actionType}_SUCCESS`:
      return Delete(state, action.payload);
    case `PUT_${actionType}_SUCCESS`:
      return Update(state, action.payload);
    case `RESET_${actionType}_SUCCESS`:
      return ResetState(state);
    default:
      return state;
  }
};

export const GetAll = action => {
  return action.payload;
};
export const GetOne = (state, action) => {
  return action;
};
export const Post = (state, action, firstItem) => {
  if (state) {
    if (!firstItem) return [...state, action];
    return action;
  } else return action;
};
export const Delete = (state, actionId) => {
  return [...state.filter(item => item.id !== actionId)];
};
export const Update = (state, updateObj) => {
  const indexToUpdate = state.findIndex(item => item.id === updateObj.id);
  return [
    ...state.slice(0, indexToUpdate),
    updateObj,
    ...state.slice(indexToUpdate + 1)
  ];
};

export const ResetState = state => {
  if (typeof state === "object" && state !== null) return {};
  else if (state !== null && Array.isArray(state)) return [];
};
