import {
  handleChangeActionType,
  handleChangeAllActionType,
  handleChangeToInitStateActionType,
} from "../../actions/handleChangeAction";

export const INITIAL_STATE = {
  ileggelsesnummer: "",
  fraDato: "",
  fraTid: "",
  tilDato: "",
  tilTid: "",
  tjenestenummer: "",
  mottaker: "",
  gatenavn: "", // gatenavn/ved
  gatenummer: "", // gatenummer/ved nr
  bydel: "",
  overtredelseskode: "",
  leveringstype: "",
};

export default function miljogebyrTilBehandlingFilterFormReducer(
  state = { ...INITIAL_STATE },
  action
) {
  switch (action.type) {
    case handleChangeActionType("MILJOGEBYRER_TIL_BEHANDLING_FILTER_FORM"):
      return { ...state, [action.variableName]: action.payload };
    case handleChangeAllActionType("MILJOGEBYRER_TIL_BEHANDLING_FILTER_FORM"):
      return { ...action.payload };
    case handleChangeToInitStateActionType(
      "MILJOGEBYRER_TIL_BEHANDLING_FILTER_FORM"
    ):
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}
