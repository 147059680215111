import React from "react";
import { connect } from "react-redux";
import Utskrift from "../Utskrift";
import {
  getIleggelseQueryParameters,
  getMiljogebyrTableRows,
} from "../../../selectors/miljogebyrerSelector";
import { handleChangeAll as handleChangeAllAction } from "../../../actions/handleChangeAction";
import { getOvertredelseskoderMiljogebyr as getOvertredelseskoderMiljogebyrAction } from "../../../actions/overtredelseskoderMiljogebyrActions";
import { getMiljoleveringstyper as getMiljoleveringstyperAction } from "../../../actions/miljoleveringstyperActions";
import { getMiljogebyrer as getMiljogebyrerAction } from "../../../actions/miljogebyrerActions";

import { miljogebyr as miljogebyrTitle } from "../../../constants/titles";

export const UtskriftContainer = (props) => (
  <Utskrift
    {...props}
    title="Miljøgebyroversikt"
    documentTitle={miljogebyrTitle.miljogebyroversikt}
  />
);

const mapStateToProps = (state) => {
  let miljogebyrTableHeaders = [
    "Ilegg.nr.",
    "Ilegg.dato",
    "Klokkeslett",
    "Tjenestenr.",
    "Overt.kode",
    "Mottaker",
    "Gatenavn",
    "Gatenr.",
    "Ved",
    "Ved nr.",
    "GNR",
    "BNR",
    "Bilde",
  ];

  return {
    queryParameters: getIleggelseQueryParameters(state),
    miljogebyrTableHeaders,
    miljogebyrTableRows: getMiljogebyrTableRows(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMiljogebyrer: (params, page, count) =>
      dispatch(getMiljogebyrerAction(params, page, count)),
    handleChangeAll: (payload) =>
      dispatch(
        handleChangeAllAction("MILJOGEBYRER_OVERSIKT_FILTER_FORM", payload)
      ),
    getOvertredelseskoderMiljogebyr: () =>
      dispatch(getOvertredelseskoderMiljogebyrAction()),
    getMiljoleveringstyper: () => dispatch(getMiljoleveringstyperAction()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UtskriftContainer);
