import {
  handleChangeActionType,
  handleChangeAllActionType,
  handleChangeToInitStateActionType,
} from "../../actions/handleChangeAction";

export const INITIAL_STATE = {
  status: "",
  adresse: "",
  fraDato: undefined,
  tilDato: undefined,
  fraInntauetDato: undefined,
  tilInntauetDato: undefined,
  kjoretoyType: "",
  kjoretoyMerke: "",
};

export default function uregistrertekontrollerFilterFormReducer(
  state = { ...INITIAL_STATE },
  action
) {
  switch (action.type) {
    case handleChangeActionType("UREGISTRERTE_KONTROLLER_FILTER_FORM"):
      return { ...state, [action.variableName]: action.payload };
    case handleChangeAllActionType("UREGISTRERTE_KONTROLLER_FILTER_FORM"):
      return { ...action.payload };
    case handleChangeToInitStateActionType(
      "UREGISTRERTE_KONTROLLER_FILTER_FORM"
    ):
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}
