import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import KopiContainer from "../../Common/Miljogebyr/Detalj/KopiContainer";
import MakulerModalContainer from "../../Common/MakulerModalContainer";
import PrintOut from "../../Common/PrintOut";
import OpphevMakuleringContainer from "./ButtonPanelButtons/OpphevMakuleringContainer";
import SendTilIneContainer from "./ButtonPanelButtons/SendTilIneContainer";
import SendTilSvarUtContainer from "./ButtonPanelButtons/SendTilSvarUtContainer";
import GodkjennContainer from "./ButtonPanelButtons/GodkjennContainer";
import NyEttersendelseContainer from "./ButtonPanelButtons/NyEttersendelseContainer";
import EndreMiljogebyrContainer from "./ButtonPanelButtons/EndreMiljogebyrContainer";

import OpenMakulerModalStandardButton from "../../Common/IleggelseClient/OpenMakulerModalStandardButton";

import { postMiljoMakulering as postMiljoMakuleringAction } from "../../../actions/miljoMakuleringerActions";
import {
  getMiljogebyr as getMiljogebyrAction,
  getMiljogebyrerHistorikk as getMiljogebyrerHistorikkAction,
} from "../../../actions/miljogebyrerActions";
import { getMiljoMakuleringer as getMiljoMakuleringerAction } from "../../../actions/miljoMakuleringerActions";

import { getMostRecentObjectComparedOnParameter } from "../../../utils/datetimeUtils";
import { authorized } from "../../../utils/authorizationUtils";
import { bypatruljen } from "../../../constants/roles";

class ButtonsContainer extends React.Component {
  static propTypes = {
    miljogebyrId: PropTypes.string.isRequired,
    miljogebyr: PropTypes.object,
    miljogebyrEttersendelser: PropTypes.array,
    postMiljoMakulering: PropTypes.func.isRequired,
    getMiljogebyr: PropTypes.func.isRequired,
    getMiljoMakuleringer: PropTypes.func.isRequired,
  };

  authorizedSaksbehandlerAndSaksbehandleradmin = authorized([
    bypatruljen.saksbehandler,
    bypatruljen.saksbehandleradmin,
  ]);

  authorizedMakuler = this.authorizedSaksbehandlerAndSaksbehandleradmin;
  authorizedOppehevMakulering =
    this.authorizedSaksbehandlerAndSaksbehandleradmin;
  authorizedSendTilIne = this.authorizedSaksbehandlerAndSaksbehandleradmin;
  authorizedGodkjennSkrivUt = this.authorizedSaksbehandlerAndSaksbehandleradmin;
  authorizedGodkjenn = this.authorizedSaksbehandlerAndSaksbehandleradmin;
  authorizedNyEttersendelse = this.authorizedSaksbehandlerAndSaksbehandleradmin;
  authorizedAvbrytEttersendelse =
    this.authorizedSaksbehandlerAndSaksbehandleradmin;
  authorizedEndreMiljogebyr = this.authorizedSaksbehandlerAndSaksbehandleradmin;

  constructor(props) {
    super(props);
    this.state = {
      latestEttersendelse: null,
    };

    this.postMiljoMakulering = this.postMiljoMakulering.bind(this);
  }

  componentDidMount() {
    if (this.props.miljogebyrEttersendelser.length > 0) {
      let latestEttersendelse = this.getLatestEttersendelse(
        this.props.miljogebyrEttersendelser
      );
      this.setState({
        ...this.state,
        latestEttersendelse,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.miljogebyrEttersendelser.length > 0 &&
      JSON.stringify(nextProps.miljogebyrEttersendelser) !==
        JSON.stringify(this.props.miljogebyrEttersendelser)
    ) {
      let latestEttersendelse = this.getLatestEttersendelse(
        nextProps.miljogebyrEttersendelser
      );
      this.setState({
        ...this.state,
        latestEttersendelse,
      });
    }
  }
  reloadHistorikk = async () => {
    await this.props.getMiljogebyrerHistorikk(this.props.miljogebyrId);
  };

  postMiljoMakulering = (miljogebyrId, kommentar) => {
    return this.props
      .postMiljoMakulering(miljogebyrId, kommentar)
      .then(() => {
        this.props.getMiljogebyr(miljogebyrId);
        this.props.getMiljoMakuleringer(miljogebyrId);
        this.reloadHistorikk();
      })
      .catch(() => Promise.reject(false));
  };

  getLatestEttersendelse = (miljogebyrEttersendelser) => {
    return getMostRecentObjectComparedOnParameter(
      miljogebyrEttersendelser,
      "ettersendelseDato"
    );
  };

  showMakuler = () =>
    !this.props.miljogebyr.isMakulert &&
    !this.props.miljogebyr.isBetalingsfristUtlopt &&
    !this.props.miljogebyr.isSendtKemner; // Skal stå isSendtKemner tils backend er endret til isSendtIne

  render() {
    return (
      <section
        className="detalj-miljogebyr-section-margin no-print"
        style={{ height: 40 }}
      >
        {this.props.miljogebyr &&
          this.props.miljogebyr.id === this.props.miljogebyrId && (
            <>
              <div className="pull-left">
                <KopiContainer />
                <PrintOut />
              </div>
              <div className="pull-right">
                {this.authorizedMakuler && this.showMakuler() && (
                  <MakulerModalContainer
                    id={this.props.miljogebyr.id}
                    titleType="miljøgebyr"
                    ileggelsesnummer={this.props.miljogebyr.ileggelsesnummer}
                    openMakulerModal={OpenMakulerModalStandardButton}
                    postMakulering={this.postMiljoMakulering}
                  />
                )}
                {this.authorizedOppehevMakulering && (
                  <OpphevMakuleringContainer
                    reloadHistorikk={this.reloadHistorikk}
                  />
                )}
                {this.authorizedSendTilIne && <SendTilIneContainer />}
                {this.authorizedGodkjennSkrivUt && (
                  <SendTilSvarUtContainer
                    latestEttersendelseId={
                      this.state.latestEttersendelse &&
                      this.state.latestEttersendelse.id
                    }
                    reloadHistorikk={this.reloadHistorikk}
                  />
                )}
                {this.authorizedGodkjenn && (
                  <GodkjennContainer reloadHistorikk={this.reloadHistorikk} />
                )}
                {this.authorizedNyEttersendelse && (
                  <NyEttersendelseContainer
                    reloadHistorikk={this.reloadHistorikk}
                  />
                )}
                {this.authorizedEndreMiljogebyr && <EndreMiljogebyrContainer />}
              </div>
            </>
          )}
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    miljogebyr: state.miljogebyr,
    miljogebyrEttersendelser: state.miljogebyrEttersendelser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    postMiljoMakulering: (miljogebyrId, kommentar) =>
      dispatch(postMiljoMakuleringAction(miljogebyrId, kommentar)),
    getMiljogebyr: (miljogebyrId) =>
      dispatch(getMiljogebyrAction(miljogebyrId)),
    getMiljoMakuleringer: (miljogebyrId) =>
      dispatch(getMiljoMakuleringerAction(miljogebyrId)),
    getMiljogebyrerHistorikk: (miljogebyrId) =>
      dispatch(getMiljogebyrerHistorikkAction(miljogebyrId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ButtonsContainer);
