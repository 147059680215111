import React from "react";
import Excel from "../../Common/Excel";

import {
  formatDateHavingNorwegianDateTime,
  formatTimeHavingNorwegianDateTime,
  formatDateTimeHavingNorwegianDateTime,
} from "../../../utils/datetimeUtils";

const getExcelRows = (skiftlogger) => {
  let data = skiftlogger.list.map((sle) => ({
    Tjenestenummer: sle.tjenestenummer,
    Dato: sle.dato ? formatDateHavingNorwegianDateTime(sle.dato) : "",
    Klokkeslett: sle.dato ? formatTimeHavingNorwegianDateTime(sle.dato) : "",
    Miljøgebyr: sle.antallMiljogebyr,
    Parkeringsgebyr: sle.antallParkeringsgebyr,
    Piggdekk: sle.antallPiggdekk,
    Kontrollsanksjon: sle.antallKontrollsanksjon,
    Inntauinger: sle.antallInntauing,
    Kommentar: sle.kommentar,
  }));

  data = [
    ...data,
    {
      Tjenestenummer: "",
      Dato: "",
      Klokkeslett: "",
      Miljøgebyr: "",
      Parkeringsgebyr: "",
      Piggdekk: "",
      Kontrollsanksjon: "",
      Inntauinger: "",
      Kommentar: "",
    },
    {
      Tjenestenummer: "",
      Dato: "",
      Klokkeslett: skiftlogger.sum ? "SUM" : "",
      Miljøgebyr: skiftlogger.sum ? skiftlogger.sum.antallMiljogebyr : "",
      Parkeringsgebyr: skiftlogger.sum
        ? skiftlogger.sum.antallParkeringsgebyr
        : "",
      Piggdekk: skiftlogger.sum ? skiftlogger.sum.antallPiggdekk : "",
      Kontrollsanksjon: skiftlogger.sum
        ? skiftlogger.sum.antallKontrollsanksjon
        : "",
      Inntauinger: skiftlogger.sum ? skiftlogger.sum.antallInntauing : "",
      Kommentar: "",
    },
  ];

  return data;
};

const SkiftloggExcel = ({ skiftlogger, skiftloggSubmittedForm }) => {
  return (
    <Excel
      element={(onClick) => (
        <button className="btn btn-bym-medium btn-bym-action" onClick={onClick}>
          Lag rapport
        </button>
      )}
      filename={"Skiftlogg.xlsx"}
      sheets={[
        {
          name: "Skiftlogg",
          data: getExcelRows(skiftlogger),
        },
        {
          name: "Søkdata",
          data: [
            {
              "Timestamp for søk": formatDateTimeHavingNorwegianDateTime(
                skiftloggSubmittedForm.submitTimestamp
              ),
              "Dato fra": formatDateTimeHavingNorwegianDateTime(
                skiftloggSubmittedForm.fraDato
              ),
              "Dato til": formatDateTimeHavingNorwegianDateTime(
                skiftloggSubmittedForm.tilDato
              ),
              Teams: skiftloggSubmittedForm.teams,
              Tjenestenummer: skiftloggSubmittedForm.tjenestenummer,
            },
          ],
          excelColumns: [
            { label: "Timestamp for søk", value: "submitTimestamp" },
            { label: "Dato fra", value: "fraDato" },
            { label: "Dato til", value: "tilDato" },
            { label: "Teams", value: "teams" },
            { label: "Tjenestenummer", value: "tjenestenummer" },
          ],
        },
      ]}
    />
  );
};

export default SkiftloggExcel;
