import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import FormButtonsForCloseAndSubmit from "../../Common/Forms/FormButtonsForCloseAndSubmit";
import DatetimePicker from "../../Common/Forms/DatetimePickerEnhanced";

const PiggdekkontrollFilterForm = (props) => {
  return (
    <form onSubmit={props.handleSubmit} className="SearchHistorikkForm">
      <div className="searchHistorikkOverskrift">Søk i tabellen</div>
      <div className="filter-flexbox-container piggdekkontroll-filter-flexbox">
        <DatetimePicker
          id="fraDato"
          name="fraDato"
          locale="nb"
          label="Dato fra"
          placeHolder="Velg dato"
          onChange={props.handleChange}
          value={props.piggdekkontrollFilterFormState.fraDato}
          validationError={
            props.piggdekkontrollFilterFormState.fraDatoValidationErrorText
          }
          timeFormat={false}
        />
        <DatetimePicker
          id="tilDato"
          name="tilDato"
          locale="nb"
          label="Dato til"
          placeHolder="Velg dato"
          onChange={props.handleChange}
          value={props.piggdekkontrollFilterFormState.tilDato}
          validationError={
            props.piggdekkontrollFilterFormState.tilDatoValidationErrorText
          }
          timeFormat={false}
        />
        <div>
          <label htmlFor="kjennemerke">Kjennemerke</label>
          <input
            id="kjennemerke"
            name="kjennemerke"
            className="form-control"
            type="text"
            placeholder="Kjennemerke"
            value={props.piggdekkontrollFilterFormState.kjennemerke}
            onChange={props.handleChange}
          />
        </div>
      </div>
      <Row>
        <Col md={12}>{props.error}</Col>
      </Row>
      <div style={{ height: 20 }} />
      <FormButtonsForCloseAndSubmit
        submitText="Søk"
        onClose={props.handleChangeToInitState}
        showSubmitSpinner={props.showSokSpinner}
      />
    </form>
  );
};

PiggdekkontrollFilterForm.propTypes = {
  piggdekkontrollFilterFormState: PropTypes.shape({
    kjennemerke: PropTypes.any,
    fraDato: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    tilDato: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  handleChange: PropTypes.func,
  handleChangeToInitState: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default PiggdekkontrollFilterForm;
