import { connect } from "react-redux";
import { getVersion } from "../../actions/versionActions";

import Version from "./Version";

const mapDispatchToProps = (dispatch) => {
  return {
    getVersion: () => dispatch(getVersion()),
  };
};

export default connect(null, mapDispatchToProps)(Version);
