const environmet = "REACT_APP_ENV";
const environment = window[environmet] || process.env[environmet];
export const IsLocal = environment && environment === "local";
export const IsDevelopment = environment && environment === "dev";
export const IsExternalDevelopment =
  environment && environment === "externaldev";
export const IsTesting = environment && environment === "test";
export const IsProduction = environment && environment === "prod";

console.log("Environment is: ", environment);

/* This method is used for running locally and for running unittests*/
/* it returns empty in other environemnets */
function getEnvironmentAppendix() {
  switch (window["REACT_APP_ENV"] || process.env.REACT_APP_ENV) {
    case "local":
      return "";
    case "dev":
      return "_DEV";
    case "externaldev":
      return "_EXTERNALDEV";
    case "test":
      return "_TEST";
    case "stage":
      return "_STAGE";
    case "prod":
      return "_PROD";
    default:
      return "";
  }
}

export function getHostFromEnv(url_name) {
  const key = "REACT_APP_" + url_name + getEnvironmentAppendix();
  const host = window[key] || process.env[key];
  console.log(`${key} host:`, host);
  if (host != null) return host;
  throw new Error(`Missing environment configuration with key '${key}'`);
}

export const ILEGGELSE_SERVICE_BASE_URL =
  getHostFromEnv("URL_SERVICE") + "/api";

export const INNTAUING_WEB_BASE_URL = getHostFromEnv("URL_INNTAUING_WEB");
