import React, { useState, useEffect } from "react";

import Table from "../../Common/Tables/BaseTable";

import InformationButton from "../../Common/InformationButton";

import { numberWithSpace } from "../../../utils/stringUtils";

const headers = [
  "Måned",
  "Total antall",
  "Klagesaker",
  "Antall frafall",
  "Klageprosent",
  "Frafallsprosent",
];

const toFloat = (value) => {
  if (parseInt(value, 10) >= 0) return value.toFixed(2);
  else return value;
};

const AArsrapportList = ({ heading, aarsrapport }) => {
  const [showInformation, setShowInformation] = useState(false);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", resizeEventListener);

    return function cleanUp() {
      window.removeEventListener("resize", resizeEventListener);
    };
  }, []);

  const resizeEventListener = () => {
    setInnerWidth(window.innerWidth);
  };

  return (
    <React.Fragment>
      <h3
        style={
          innerWidth > 767
            ? { fontSize: 16, fontWeight: "bold" }
            : {
                fontSize: 16,
                fontWeight: "bold",
                marginRight: 20,
                marginLeft: 20,
              }
        }
      >
        {heading}{" "}
        <InformationButton
          onClick={() => setShowInformation(!showInformation)}
        />
      </h3>
      {showInformation && (
        <div
          style={
            innerWidth > 767
              ? {}
              : { marginRight: 20, marginLeft: 20, whiteSpace: "normal" }
          }
        >
          <i>
            <b>Måned:</b> Måned av året
          </i>
          <br />
          <i>
            <b>Total antall:</b> Antall ileggelser som ikke er makulerte og er i
            henhold til filtreringen
          </i>
          <br />
          <i>
            <b>Klagesaker:</b> Antall ileggelser med ett eller flere
            ferdigbehandlede vedtak som ikke er makulerte
          </i>
          <br />
          <i>
            <b>Antall frafall:</b> Antall ileggelser med ett eller flere
            ferdigbehandlede vedtak som ikke er makulert og som har klagekode 3
            eller 13 (medhold)
          </i>
          <br />
          <i>
            <b>Klageprosent:</b> Basert på “Total antall” og “Klagesaker”
          </i>
          <br />
          <i>
            <b>Frafallsprosent:</b> Basert på “Klagesaker” og “Antall frafall”
          </i>
        </div>
      )}
      <Table headerRow={headers} striped>
        <tbody>
          {aarsrapport.map((monthReport, index) =>
            monthReport.maaned !== "Total" ? (
              <tr key={`AArsrapportList${heading}${index}`}>
                <td>{monthReport.maaned}</td>
                <td>{numberWithSpace(monthReport.totalAntall)}</td>
                <td>{numberWithSpace(monthReport.klagesaker)}</td>
                <td>{numberWithSpace(monthReport.antallFrafall)}</td>
                <td>{toFloat(monthReport.klageprosent)}%</td>
                <td>{toFloat(monthReport.frafallsprosent)}%</td>
              </tr>
            ) : (
              <tr
                className="RapportListTotalRow"
                key={`AArsrapportList${heading}${index}`}
              >
                <td>{monthReport.maaned}</td>
                <td>{numberWithSpace(monthReport.totalAntall)}</td>
                <td>{numberWithSpace(monthReport.klagesaker)}</td>
                <td>{numberWithSpace(monthReport.antallFrafall)}</td>
                <td>{toFloat(monthReport.klageprosent)}%</td>
                <td>{toFloat(monthReport.frafallsprosent)}%</td>
              </tr>
            )
          )}
        </tbody>
      </Table>
    </React.Fragment>
  );
};

export default AArsrapportList;
