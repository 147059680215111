import classNames from "classnames";
import React, { Component } from "react";
import { Table as BSTable } from "react-bootstrap";
import PropTypes from "prop-types";

import "./Table.css";
import SpinLoader from "../SpinLoader";

class Table extends Component {
  static propTypes = {
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    cols: PropTypes.func,
    items: PropTypes.array,
    showSpinner: PropTypes.bool,
    renderRow: PropTypes.func.isRequired,
    sort: PropTypes.shape({
      cellKey: PropTypes.string.isRequired,
      orders: PropTypes.string,
    }),
    onSort: PropTypes.func,
    children: PropTypes.any,
  };

  // spinnerLoaderProps = {
  //   height: 32,
  //   width: 32,
  //   thickness: 3,
  //   pColor: "#0f20f2",
  //   sColor: "#F0FFFF",
  // };

  spinnerLoaderProps = {
    height: 68,
    width: 68,
    thickness: 3,
    pColor: "#4d4d4d",
    sColor: "#F0FFFF",
  };

  render() {
    const {
      id,
      className,
      cols,
      items,
      renderRow,
      children,
      sort,
      onSort,
      showSpinner = false,
      ...rest
    } = this.props;

    let headers = children;

    if (sort || onSort) {
      headers = React.Children.map(children, (child) =>
        React.cloneElement(child, { sort, onSort })
      );
    }

    return (
      <>
        <BSTable
          className={classNames("bym-table", className)}
          hover
          striped
          {...rest}
        >
          {cols && <colgroup>{cols()}</colgroup>}
          <thead>
            <tr>{headers}</tr>
          </thead>
          <tbody>
            {!showSpinner &&
              items &&
              items.map((item, index) => {
                return (
                  <React.Fragment key={`${id}${index}`}>
                    {renderRow(item)}
                  </React.Fragment>
                );
              })}
          </tbody>
        </BSTable>
        {showSpinner && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SpinLoader {...this.spinnerLoaderProps} />
          </div>
        )}
      </>
    );
  }
}

export default Table;
