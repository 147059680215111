import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import GridTable from "../../GridTable";

import { getMottakertyper as getMottakertyperAction } from "../../../../actions/mottakertyperActions";

import { getGridTableRowData } from "../../../../selectors/miljogebyrMottakerAvMiljogebyrSelector";

class MottakerAvMiljogebyrContainer extends React.Component {
  static propTypes = {
    mottakertyper: PropTypes.array.isRequired,
    gridTableRowData: PropTypes.array,
    getMottakertyper: PropTypes.func.isRequired,
  };

  componentDidMount() {
    if (this.props.mottakertyper.length === 0) {
      this.props.getMottakertyper();
    }
  }

  render() {
    return (
      <section>
        <div className="detalj-ileggelse-header-wrapper">
          <h2>Mottaker av miljøgebyr</h2>
        </div>
        <div>
          <GridTable
            name="mottakerAvMiljogebyr"
            rows={this.props.gridTableRowData}
            oneColoredGridTable
          />
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    gridTableRowData: getGridTableRowData(state),
    mottakertyper: state.mottakertyper,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getMottakertyper: () => dispatch(getMottakertyperAction()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MottakerAvMiljogebyrContainer);
