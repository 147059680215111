import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";

import DropDown from "../../Common/Forms/DropDown";
import FormButtonsForCloseAndSubmit from "../../Common/Forms/FormButtonsForCloseAndSubmit";
import { useDispatch, useSelector } from "react-redux";
import {
  handleChange,
  handleChangeToInitStateActionType,
} from "../../../actions/handleChangeAction";
import { getUbetalteIleggelser } from "../../../actions/rapporter/ubetalteIleggelserActions";
import {
  formatDateTimeHavingDateTime,
  toNorwegianDatetime,
} from "../../../utils/datetimeUtils";

const UbetalteIleggelserFilterForm = (props) => {
  const [showSokSpinner, setShowSokSpinner] = useState(false);

  const ileggelseType = useSelector(
    (state) => state.forms.ubetalteIleggelserFilterForm.ileggelseType
  );
  const nasjon = useSelector(
    (state) => state.forms.ubetalteIleggelserFilterForm.nasjon
  );
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    props.setOppdatertTid(new Date());

    setShowSokSpinner(true);
    await dispatch(
      getUbetalteIleggelser({
        ileggelseType: ileggelseType,
        nasjon: nasjon,
        sort: props.sort.cellKey,
        sortOrder: props.sort.orders,
      })
    )
      .then(() => setShowSokSpinner(false))
      .catch(() => setShowSokSpinner(false));
  };

  const handleChangeToInitState = () => {
    dispatch({
      type: handleChangeToInitStateActionType(
        "RAPPORT_UBETALTE_ILEGGELSER_FILTER_FORM"
      ),
    });
  };

  const handleChangeIleggelseType = (e) => {
    dispatch(
      handleChange(
        "RAPPORT_UBETALTE_ILEGGELSER_FILTER_FORM",
        "ileggelseType",
        e.target.value
      )
    );
  };

  const handleChangeNasjon = (e) => {
    dispatch(
      handleChange(
        "RAPPORT_UBETALTE_ILEGGELSER_FILTER_FORM",
        "nasjon",
        e.target.value
      )
    );
  };

  return (
    <div className="filter-container" style={{ display: "flex" }}>
      <form onSubmit={handleSubmit}>
        <div className="searchHistorikkOverskrift">Ubetalte ileggelser</div>
        <div style={{ display: "flex", gap: 20 }}>
          <div style={{ minWidth: 250 }}>
            <DropDown
              label="Type ileggelse"
              name="ileggelseType"
              items={[
                { id: "V", navn: "Kontrollsanksjon" },
                { id: "P", navn: "Tilleggsgebyr piggdekk" },
                { id: "G", navn: "Parkeringsgebyr" },
              ]}
              selected={ileggelseType}
              title="Velg"
              onChange={handleChangeIleggelseType}
            />
          </div>
          <div style={{ minWidth: 250 }}>
            <DropDown
              label="Nasjon"
              name="nasjon"
              items={[
                { id: "Norge", navn: "Norge" },
                { id: "Utland", navn: "Utland" },
              ]}
              selected={nasjon}
              title="Velg"
              onChange={handleChangeNasjon}
            />{" "}
          </div>
        </div>
        <Row>
          <Col sm={12}>{props.error}</Col>
        </Row>
        <Row>
          <Col sm={12}>
            <FormButtonsForCloseAndSubmit
              submitText="Søk"
              onClose={handleChangeToInitState}
              showSubmitSpinner={showSokSpinner}
            />
          </Col>
        </Row>
      </form>
      <div
        style={{
          backgroundColor: "#f3f3f3",
          display: "flex",
          justifyContent: "right",
          padding: "10 50 10 50",
          width: "100%",
        }}
      >
        <div>
          {props.oppdatertTid
            ? `Sist oppdatert ${formatDateTimeHavingDateTime(
                toNorwegianDatetime(props.oppdatertTid)
              )}`
            : ""}
        </div>
      </div>
    </div>
  );
};

export default UbetalteIleggelserFilterForm;
