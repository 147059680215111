import { connect } from "react-redux";

import EndreMiljokontroll from "./EndreMiljokontroll";

import {
  getMiljokontroller,
  getMiljokontroll,
  getAllMiljoKontrollHistorikk,
} from "../../../../actions/miljokontrollerActions";
import { putStedbeskrivelse } from "../../../../actions/stedbesrkivelserActions";

const mapStateToProps = (state, ownProps) => ({
  miljokontroll: state.miljokontroll,
  miljokontrollOversiktFilterForm: state.forms.miljokontrollOversiktFilterForm,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getMiljokontroller: (filter) => dispatch(getMiljokontroller(filter)),
  getMiljokontroll: (miljokontrollId) =>
    dispatch(getMiljokontroll(miljokontrollId)),
  getAllMiljoKontrollHistorikk: (miljokontrollId) =>
    dispatch(getAllMiljoKontrollHistorikk(miljokontrollId)),
  putStedbeskrivelse: (
    stedbeskrivelseId,
    payload,
    getMiljokontroller,
    getMiljokontroll,
    getAllMiljoKontrollHistorikk
  ) =>
    dispatch(
      putStedbeskrivelse(
        stedbeskrivelseId,
        {
          gateNavn: payload.gatenavn,
          gateNummer: payload.gatenummer,
          oppgang: payload.oppgang,
          bydel: payload.bydel,
          vedGate: payload.vedGate,
          vedGateNummer: payload.vedGatenummer,
          vedOppgang: payload.vedOppgang,
        },
        getMiljokontroller,
        getMiljokontroll,
        getAllMiljoKontrollHistorikk
      )
    ),
});

const mergeProps = (stateProps, dispatchProps) => ({
  miljokontroll: stateProps.miljokontroll,
  putStedbeskrivelse: (payload) =>
    dispatchProps.putStedbeskrivelse(
      stateProps.miljokontroll.stedbeskrivelseId,
      payload,
      () =>
        dispatchProps.getMiljokontroller(
          stateProps.miljokontrollOversiktFilterForm
        ),
      () => dispatchProps.getMiljokontroll(stateProps.miljokontroll.id),
      () => dispatchProps.getAllMiljoKontrollHistorikk(stateProps.miljokontroll.id),
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(EndreMiljokontroll);
