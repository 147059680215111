import React from "react";
import SpinnerBase from "react-spinkit";

//https://kyleamathews.github.io/react-spinkit/    for more shapes

const spinnerDiv = {
  position: "fixed",
  zIndex: 3000000,
  width: "100px",
  height: "20px",
  marginLeft: "-50px",
  marginTop: "-50px",
  top: "20%",
  left: "50%"
};

const renderSpinner = () => {
  return (
    <SpinnerBase
      className="three-bounce"
      style={{ width: "75px", height: "25px" }} /*fadeIn="quarter"*/
    />
  );
};
const Spinner = ({ isLoading }) => {
  return <div style={spinnerDiv}>{isLoading && renderSpinner()}</div>;
};

export default Spinner;
