import * as axiosUtils from "../../utils/axiosUtils";
import * as api from "../../constants/api";
import { toIsoString } from "../../utils/datetimeUtils";

export const actionTypeAArsrapport = "AARSRAPPORT";

export const getAArsrapport = (params) => {
  return axiosUtils.GetAllWithParamsSerializer(
    api.GET_AARSRAPPORT_URL,
    {
      fraDato: toIsoString(params.fraDato),
      tilDato: toIsoString(params.tilDato),
      ileggelsestypeIds: params.ileggelsestypeIds,
    },
    actionTypeAArsrapport
  );
};
