export const handleChangeActionType = (reducerName) =>
  `HANDLE_CHANGE_${reducerName}`;

export const handleChange = (reducerName, variableName, payload) => {
  return {
    type: handleChangeActionType(reducerName),
    variableName: variableName,
    payload: payload,
  };
};

export const handleChangeAllActionType = (reducerName) =>
  `HANDLE_CHANGE_ALL_${reducerName}`;

export const handleChangeAll = (reducerName, payload) => {
  return {
    type: handleChangeAllActionType(reducerName),
    payload,
  };
};

export const handleChangeToInitStateActionType = (reducerName) =>
  `HANDLE_CHANGE_${reducerName}_TO_INIT_STATE`;

export const handleChangeToInitState = (reducerName) => {
  return {
    type: handleChangeToInitStateActionType(reducerName),
  };
};
