import React from "react";
import { connect } from "react-redux";
import Utskrift from "../Utskrift";
import {
  getIleggelseQueryParameters,
  getIleggelseTableRows,
} from "../../../selectors/ileggelserSelector";
import { handleChangeAll as handleChangeAllAction } from "../../../actions/handleChangeAction";
import { getIleggelser as getIleggelserAction } from "../../../actions/ileggelserActions";
import * as title from "../../../constants/titles";

class UtskriftContainer extends React.Component {
  async componentDidMount() {
    const { handleChangeAll, getIleggelser } = this.props;
    document.title = title.ileggelse.ileggelsesoversikt;
    const sessionStoragePrintData = JSON.parse(
      window.sessionStorage.getItem("printData")
    );
    handleChangeAll(sessionStoragePrintData.queryData);
    await getIleggelser(
      sessionStoragePrintData.queryData,
      sessionStoragePrintData.paginationData.page,
      sessionStoragePrintData.paginationData.count
    );
    setTimeout(() => {
      window.print();
      window.close();
    }, 1000);
  }

  render() {
    const { ileggelseTableHeaders, ileggelseTableRows, queryParameters } =
      this.props;
    return (
      <Utskrift
        title="Ileggelsesoversikt"
        ileggelseTableHeaders={ileggelseTableHeaders}
        ileggelseTableRows={ileggelseTableRows}
        queryParameters={queryParameters}
      />
    );
  }

  componentWillUnmount() {
    window.sessionStorage.removeItem("printData");
  }
}

const mapStateToProps = (state) => {
  let ileggelseTableHeaders = [
    "Ilegg.nr.",
    "Ilegg.dato",
    "Klokkeslett",
    "Tjenestenr.",
    "Overt.kode",
    "Kj.merke",
    "Nasjon",
    "Gatenavn",
    "Gatenr.",
    "Ved",
    "Ved nr.",
    "Bydel",
    "Ileggelsestype",
    "Bilde",
  ];

  return {
    ileggelseTableHeaders,
    ileggelseTableRows: getIleggelseTableRows(state),
    queryParameters: getIleggelseQueryParameters(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getIleggelser: (params, page, count) =>
      dispatch(getIleggelserAction(params, page, count)),
    handleChangeAll: (payload) =>
      dispatch(
        handleChangeAllAction("ILEGGELSER_OVERSIKT_FILTER_FORM", payload)
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UtskriftContainer);
