import React from "react";
import PropTypes from "prop-types";

import TextInput from "../Common/Forms/TextInput";
import DatetimePicker from "../Common/Forms/DatetimePickerEnhanced";
import FormButtonsForCloseAndSubmit from "../Common/Forms/FormButtonsForCloseAndSubmit";

export const InnsynMotorvognFilterForm = (props) => {
  const {
    showSokSpinner,
    innsynMotorvognFilterForm,
    handleOnChange,
    handleOnSubmit,
    handleOnChangeToInitState,
  } = props;
  return (
    <form
      id="motorvogn-filter"
      className="SearchFormDiv"
      onSubmit={handleOnSubmit}
    >
      <div className="SearchFilterOverskrift">Søk etter motorvogn</div>
      <div className="filter-flexbox-container innsyn-motorvogn-filter-flexbox-item">
        <TextInput
          label="Kjennemerke"
          name="kjennemerke"
          onChange={handleOnChange}
          onBlur={(e) =>
            handleOnChange({
              target: {
                name: e.target.name,
                value: e.target.value.replace(/\s/g, ""),
              },
            })
          }
          value={innsynMotorvognFilterForm.kjennemerke}
          validationError={innsynMotorvognFilterForm.kjennemerkeValidationError}
        />
        <DatetimePicker
          id="dato"
          name="dato"
          locale="nb"
          label="Dato"
          placeholder="Velg"
          onChange={handleOnChange}
          value={innsynMotorvognFilterForm.dato}
          timeFormat={false}
          validationError={innsynMotorvognFilterForm.datoValidationError}
        />
        <DatetimePicker
          id="tid"
          name="tid"
          locale="nb"
          label="Tid"
          placeholder="Velg"
          onChange={handleOnChange}
          value={innsynMotorvognFilterForm.tid}
          dateFormat={false}
          validationError={innsynMotorvognFilterForm.tidValidationError}
        />
      </div>
      <div className="understellsnummer-filter-flexbox-container innsyn-motorvogn-filter-flexbox-item">
        <TextInput
          label="Understellsnummer"
          name="understellsnummer"
          onChange={handleOnChange}
          onBlur={(e) =>
            handleOnChange({
              target: {
                name: e.target.name,
                value: e.target.value.replace(/\s/g, ""),
              },
            })
          }
          value={innsynMotorvognFilterForm.understellsnummer}
          validationError={
            innsynMotorvognFilterForm.understellsnummerValidationError
          }
        />
      </div>
      <div style={{ marginTop: 20 }}>
        <FormButtonsForCloseAndSubmit
          showSubmitSpinner={showSokSpinner}
          submitText="Søk"
          onClose={handleOnChangeToInitState}
        />
      </div>
    </form>
  );
};

InnsynMotorvognFilterForm.propTypes = {
  innsynMotorvognFilterState: PropTypes.shape({
    kjennemerke: PropTypes.string,
  }),
  kjoretoefarger: PropTypes.array,
  merker: PropTypes.array,
  handleOnChange: PropTypes.func.isRequired,
  handleOnSubmit: PropTypes.func.isRequired,
};

export default InnsynMotorvognFilterForm;
