import React, { useState } from "react";
import moment from "moment";

import DatetimePicker from "../../Common/Forms/DatetimePickerEnhanced";
import FormButtonsForCloseAndSubmit from "../../Common/Forms/FormButtonsForCloseAndSubmit";
import * as inputValidation from "../../../utils/inputValidationUtils";

const handleChangeDateInputs = (e, handleChange) => {
  const name = e.target.name;
  let value = e.target.value;

  if (name === "fraDato" && value && moment.isMoment(value)) {
    handleChange(name, value.startOf("day"));
  } else if (name === "tilDato" && value && moment.isMoment(value)) {
    handleChange(name, value.endOf("day"));
  } else {
    handleChange(name, value);
  }
};

const isValid = (antallIleggelserPerIleggelsetypeFilterForm, handleChange) => {
  return (
    inputValidation.fraDateTimeTilDateTimeValidation(
      antallIleggelserPerIleggelsetypeFilterForm,
      handleChange
    ) &&
    inputValidation.fromDateToDateRequiredValidation(
      antallIleggelserPerIleggelsetypeFilterForm.fraDato,
      antallIleggelserPerIleggelsetypeFilterForm.tilDato,
      handleChange
    )
  );
};

const handleSubmit = (
  e,
  antallIleggelserPerIleggelsetypeFilterForm,
  handleChange,
  handleChangeAll,
  getAntallIleggelserPerIleggelsetype,
  setShowSokSpinner
) => {
  e.preventDefault();
  if (isValid(antallIleggelserPerIleggelsetypeFilterForm, handleChange)) {
    handleChangeAll(
      "RAPPORT_ANTALL_ILEGGELSER_PER_ILEGGELSETYPE_SUBMITTED_FORM",
      {
        fraDato: antallIleggelserPerIleggelsetypeFilterForm.fraDato,
        tilDato: antallIleggelserPerIleggelsetypeFilterForm.tilDato,
        submitTimestamp: moment(),
      }
    );
    setShowSokSpinner(true);
    getAntallIleggelserPerIleggelsetype({
      fraDato: antallIleggelserPerIleggelsetypeFilterForm.fraDato,
      tilDato: antallIleggelserPerIleggelsetypeFilterForm.tilDato,
    })
      .then(() => setShowSokSpinner(false))
      .catch(() => setShowSokSpinner(false));
  }
};

const AntallIleggelserPerIleggelsetypeFilterForm = ({
  antallIleggelserPerIleggelsetypeFilterForm,
  handleChange,
  handleChangeAll,
  handleChangeToInitState,
  getAntallIleggelserPerIleggelsetype,
}) => {
  const [showSokSpinner, setShowSokSpinner] = useState(false);

  return (
    <form
      className="rapportSearch SearchHistorikkForm no-print"
      onSubmit={(e) =>
        handleSubmit(
          e,
          antallIleggelserPerIleggelsetypeFilterForm,
          handleChange,
          handleChangeAll,
          getAntallIleggelserPerIleggelsetype,
          setShowSokSpinner
        )
      }
    >
      <div className="searchHistorikkOverskrift">
        Søk etter antall ileggelser per ileggelsetype
      </div>
      <div className="filter-flexbox-container ventilstilling-filter-flexbox">
        <DatetimePicker
          id="fraDato"
          name="fraDato"
          locale="nb"
          label="Dato fra"
          placeHolder="Velg dato"
          onChange={(e) => handleChangeDateInputs(e, handleChange)}
          value={antallIleggelserPerIleggelsetypeFilterForm.fraDato}
          validationError={
            antallIleggelserPerIleggelsetypeFilterForm.fraDatoValidationErrorText
          }
          timeFormat={false}
        />
        <DatetimePicker
          id="tilDato"
          name="tilDato"
          locale="nb"
          label="Dato til"
          placeHolder="Velg dato"
          onChange={(e) => handleChangeDateInputs(e, handleChange)}
          value={antallIleggelserPerIleggelsetypeFilterForm.tilDato}
          validationError={
            antallIleggelserPerIleggelsetypeFilterForm.tilDatoValidationErrorText
          }
          timeFormat={false}
        />
      </div>
      <div style={{ marginTop: 10 }}>
        <FormButtonsForCloseAndSubmit
          submitText="Søk"
          onClose={() =>
            handleChangeToInitState(
              "RAPPORT_ANTALL_ILEGGELSER_PER_ILEGGELSETYPE_FILTER_FORM"
            )
          }
          showSubmitSpinner={showSokSpinner}
        />
      </div>
    </form>
  );
};

export default AntallIleggelserPerIleggelsetypeFilterForm;
