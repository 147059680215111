import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";

import PiggdekkontrollerFilterForm from "./PiggdekkontrollFilterForm";

import {
  handleChange as handleChangeAction,
  handleChangeAll as handleChangeAllAction,
  handleChangeToInitState as handleChangeToInitStateAction,
} from "../../../actions/handleChangeAction";

import { getPiggdekkontroller as getPiggdekkontrollerAction } from "../../../actions/piggdekkontrollerActions";

import * as inputValidation from "../../../utils/inputValidationUtils";
import { setPage as setPageAction } from "../../../actions/advancedListActions";

class PiggdekkontrollFilterContainer extends React.Component {
  static propTypes = {
    piggdekkontrollFilterFormState: PropTypes.shape({
      kjennemerke: PropTypes.any,
      fraDato: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      tilDato: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    }),
    handleChange: PropTypes.func,
    handleChangeToInitState: PropTypes.func,
    getPiggdekkontroller: PropTypes.func,
    setPage: PropTypes.func.isRequired,
  };

  state = {
    showSokSpinner: false,
  };

  handleChange = (e) => {
    this.props.handleChange(e.target.name, e.target.value);
  };

  resetValidationErrorText() {
    this.props.handleChange("fraDatoValidationErrorText", null);
    this.props.handleChange("tilDatoValidationErrorText", null);
  }

  validate() {
    return inputValidation.fraDateTimeTilDateTimeValidation(
      this.props.piggdekkontrollFilterFormState,
      this.props.handleChange
    );
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { piggdekkontrollFilterFormState, setPage, handleChangeAll } =
      this.props;
    this.resetValidationErrorText();
    if (this.validate()) {
      handleChangeAll("RAPPORT_PIGGDEKKONTROLLER_SUBMITTED_FORM", {
        fraDato: piggdekkontrollFilterFormState.fraDato,
        tilDato: piggdekkontrollFilterFormState.tilDato,
        submitTimestamp: moment(),
        kjennemerke: piggdekkontrollFilterFormState.kjennemerke,
      });
      this.setState(() => ({ showSokSpinner: true }));
      const page = 1;
      this.props
        .getPiggdekkontroller(
          {
            ...this.props.piggdekkontrollFilterFormState,
          },
          page,
          this.props.piggdekkontroller.count
        )
        .then(() => {
          this.setState(() => ({ showSokSpinner: false }));
          setPage(page);
        })
        .catch(this.setState(() => ({ showSokSpinner: false })));
    }
  };

  render() {
    const { showSokSpinner } = this.state;
    return (
      <PiggdekkontrollerFilterForm
        showSokSpinner={showSokSpinner}
        piggdekkontrollFilterFormState={
          this.props.piggdekkontrollFilterFormState
        }
        handleChange={this.handleChange}
        handleChangeToInitState={this.props.handleChangeToInitState}
        handleSubmit={this.handleSubmit}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    piggdekkontrollFilterFormState: state.forms.piggdekkontrollFilterForm,
    piggdekkontroller: state.piggdekkontroller,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPiggdekkontroller: (params, page, count) =>
      dispatch(getPiggdekkontrollerAction(params, page, count)),
    handleChange: (variableName, value) =>
      dispatch(
        handleChangeAction("PIGGDEKKONTROLL_FILTER_FORM", variableName, value)
      ),
    handleChangeAll: (reducerName, payload) =>
      dispatch(handleChangeAllAction(reducerName, payload)),
    handleChangeToInitState: () =>
      dispatch(handleChangeToInitStateAction("PIGGDEKKONTROLL_FILTER_FORM")),
    setPage: (page) => dispatch(setPageAction("piggdekkontroller", page)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PiggdekkontrollFilterContainer);
