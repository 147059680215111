import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";

import ForsinketMakuleringModal from "./UpdateModals/ForsinketMakuleringModal";
import Table from "../../Common/Tables/BaseTable";
import LoadButton from "../../Common/LoadButton";

import {
  getForsinketMakuleringMalPreview,
  getForsinketMakuleringMalDocx,
} from "../../../actions/forsinketMakuleringActions";
import * as title from "../../../constants/titles";

const ForsinketMakuleringList = ({ authorizedToCreateAndEdit }) => {
  const [
    showForsinketMakuleringEksempelSpinner,
    setShowForsinketMakuleringEksempelSpinnse,
  ] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    document.title = title.grunndataForsinketMakulering;
  }, []);

  const handleSeEksempelClick = () => {
    setShowForsinketMakuleringEksempelSpinnse(true);
    dispatch(getForsinketMakuleringMalPreview())
      .then(() => {
        setShowForsinketMakuleringEksempelSpinnse(false);
      })
      .catch(() => {
        setShowForsinketMakuleringEksempelSpinnse(false);
      });
  };

  const handleLastaNedDocxClick = () => {
    dispatch(getForsinketMakuleringMalDocx());
  };

  const renderRow = () => {
    return (
      <tr>
        <td>Forsinket makuleringsmal</td>
        <td>
          <LoadButton
            isLoading={showForsinketMakuleringEksempelSpinner}
            buttonProps={{
              id: "submitButton",
              className: "btn btn-link",
              style: { fontWeight: "normal", padding: 0 },
              disabled: showForsinketMakuleringEksempelSpinner,
              onClick: handleSeEksempelClick,
            }}
          >
            Se eksempel
          </LoadButton>
        </td>
        <td>
          {authorizedToCreateAndEdit && (
            <Button
              className="btn btn-link"
              style={{ fontWeight: "normal", padding: 0 }}
              onClick={() => handleLastaNedDocxClick()}
            >
              Lasta ned som .docx
            </Button>
          )}
        </td>
        <td>{authorizedToCreateAndEdit && <ForsinketMakuleringModal />}</td>
      </tr>
    );
  };

  const headerRow = ["Navn", "", "", ""];
  return (
    <div className="table-wrapper-horizontal-scroll">
      <Table
        id="forsinketMakulering-liste"
        headerRow={headerRow}
        defaultStyle
        striped
      >
        <tbody>{renderRow()}</tbody>
      </Table>
    </div>
  );
};

export default ForsinketMakuleringList;
