import { connect } from "react-redux";

import MakuleringerFilterForm from "./MakuleringerFilterForm";

import {
  handleChange as handleChangeAction,
  handleChangeAll as handleChangeAllAction,
  handleChangeToInitState as handleChangeToInitStateAction,
} from "../../../actions/handleChangeAction";
import { getMakuleringer as getRapportMakuleringerAction } from "../../../actions/rapporter/makuleringerActions";

const mapStateToProps = (state) => ({
  makuleringerFilterForm: state.forms.makuleringerFilterForm,
  makuleringerRapport: state.rapporter.makuleringerRapport,
});

const mapDispatchToProps = (dispatch) => ({
  handleChange: (variableName, value) =>
    dispatch(
      handleChangeAction(
        "RAPPORT_MAKULERINGER_FILTER_FORM",
        variableName,
        value
      )
    ),
  handleChangeAll: (reducerName, payload) =>
    dispatch(handleChangeAllAction(reducerName, payload)),
  handleChangeToInitState: (actionType) =>
    dispatch(handleChangeToInitStateAction(actionType)),
  getRapportMakuleringer: (params, page, pageSize, sort) =>
    dispatch(getRapportMakuleringerAction(params, page, pageSize, sort)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const page =
    stateProps &&
    stateProps.makuleringerRapport &&
    stateProps.makuleringerRapport.page
      ? stateProps.makuleringerRapport.page
      : 1;

  const pageSize =
    stateProps &&
    stateProps.makuleringerRapport &&
    stateProps.makuleringerRapport.count
      ? stateProps.makuleringerRapport.count
      : 1;

  const sort =
    stateProps &&
    stateProps.makuleringerRapport &&
    stateProps.makuleringerRapport.sort;

  return {
    makuleringerFilterForm: stateProps.makuleringerFilterForm,
    ...dispatchProps,
    getRapportMakuleringer: (params) =>
      dispatchProps.getRapportMakuleringer(params, page, pageSize, sort),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(MakuleringerFilterForm);
