import React from "react";
import { Link } from "react-router-dom";
import Table from "../../Common/SortableTableContainer";
import { HeaderCell } from "../../Common/Tables/index";
import {
  formatDateHavingNorwegianDateTime,
  formatTimeHavingNorwegianDateTime,
} from "../../../utils/datetimeUtils";
import sitePaths from "../../../constants/sitePaths";

const renderRow = (miljokontroll) => {
  if (miljokontroll) {
    const id = miljokontroll.id;
    const ileggelsesnummer = miljokontroll.ileggelsesnummer;
    const mottattDato = formatDateHavingNorwegianDateTime(
      miljokontroll.mottattDato
    );
    const mottattTid = formatTimeHavingNorwegianDateTime(
      miljokontroll.mottattDato
    );
    const tjenestenummer = miljokontroll.tjenestenummer;
    const overtredelsekode = miljokontroll.overtredelse1.id;
    const gatenavn = miljokontroll.gatenavn;
    const gatenummer = miljokontroll.gatenummer;
    const vedGate = miljokontroll.vedGate;
    const vedGatenummer = miljokontroll.vedGatenummer;
    const antallObservasjoner = miljokontroll.observasjoner
      ? miljokontroll.observasjoner.length
      : 0;
    return (
      <tr
        key={`ileggelserTableBodyTr${miljokontroll.id}`}
        style={
          miljokontroll.deaktivertDato
            ? { backgroundColor: "#d32316", color: "white" }
            : {}
        }
      >
        <td>
          {miljokontroll.deaktivertDato && (
            <Link
              to={`${sitePaths.miljogebyrer.miljokontrolloversikt.shortUrl}/${id}`}
              style={{ textDecoration: "none" }}
            >
              <span className="link-makulert">{miljokontroll.nummer}</span>
            </Link>
          )}
          {!miljokontroll.deaktivertDato && (
            <Link
              to={`${sitePaths.miljogebyrer.miljokontrolloversikt.shortUrl}/${id}`}
              className="underline"
            >
              <span>{miljokontroll.nummer}</span>
            </Link>
          )}
        </td>
        <td>
          {ileggelsesnummer && (
            <Link
              to={`${sitePaths.miljogebyrer.miljokontrolloversikt.miljogebyrer.detalj.shortUrl.getUrl(
                miljokontroll.miljogebyrId
              )}`}
              className="link-makulert"
            >
              <span>{ileggelsesnummer}</span>
            </Link>
          )}
        </td>
        <td>{mottattDato}</td>
        <td>{mottattTid}</td>
        <td>{tjenestenummer}</td>
        <td>{overtredelsekode}</td>
        <td>{gatenavn}</td>
        <td>{gatenummer}</td>
        <td>{vedGate}</td>
        <td>{vedGatenummer}</td>
        <td>{antallObservasjoner}</td>
      </tr>
    );
  }
};

const MiljokontrollOversiktList = ({ miljokontroller }) => {
  return (
    <div style={{ marginTop: 20, paddingRight: 50, paddingLeft: 50 }}>
      <div className="ileggelse-list-page-content-container">
        <Table
          id="ileggelse-liste"
          storeKey="ileggelser"
          items={miljokontroller}
          renderRow={renderRow}
        >
          <HeaderCell>Id</HeaderCell>
          <HeaderCell>Ilegg.nr.</HeaderCell>
          <HeaderCell>Dato</HeaderCell>
          <HeaderCell>Klokkeslett</HeaderCell>
          <HeaderCell>Tjenestenr.</HeaderCell>
          <HeaderCell>Overt.kode</HeaderCell>
          <HeaderCell>Gatenavn</HeaderCell>
          <HeaderCell>Gatenr.</HeaderCell>
          <HeaderCell>Ved</HeaderCell>
          <HeaderCell>Ved nr.</HeaderCell>
          <HeaderCell>Antall obs.</HeaderCell>
        </Table>
      </div>
      {/* <PaginationControl /> */}
    </div>
  );
};

export default MiljokontrollOversiktList;
