import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { withAuth } from "react-oidc-context";

class PrivateRoute extends Component {
  render() {
    const { component, ...rest } = this.props;
    return <Route {...rest} render={() => this.renderRoute(this.props)} />;
  }

  renderRoute = props => {
    const { component: Comp, auth } = this.props;
    if (auth.isAuthenticated) {
      return <Comp {...props} />;
    } else if (auth.isLoading) {
      return <></>;
    } else {
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: {
              from:
                props !== undefined &&
                props != null &&
                props.location !== undefined
                  ? props.location
                  : ""
            }
          }}
        />
      );
    }
  };
}

export default withAuth(PrivateRoute);
