import * as axiosUtils from "../../utils/axiosUtils";
import * as api from "../../constants/api";
import axios from "../../services/axios";
import isEmpty from "lodash/isEmpty";
import first from "lodash/first";
export const ILEGGELSE_DETAIL = "ILEGGELSE_DETAIL";
export const GET_KLAGE_ILEGGELSE = "GET_KLAGE_ILEGGELSE";
export const KLAGE_ILEGGELSER = "KLAGE_ILEGGELSER";
export const KLAGE_DETAIL = "KLAGE_DETAIL";
export const KLAGE_ILEGGELSE_IMAGE = "KLAGE_ILEGGELSE_IMAGE";
export const KLAGE_ILEGGELSE_THUMBNAIL_IMAGE =
  "KLAGE_ILEGGELSE_THUMBNAIL_IMAGE";
export const KLAGE_ILEGGELSE_LIGHTBOX_IMAGE = "KLAGE_ILEGGELSE_LIGHTBOX_IMAGE";
export const KLAGE_MILJOKONTROLL_IMAGE = "KLAGE_MILJOKONTROLL_IMAGE";
export const KLAGE_MILJOKONTROLL_THUMBNAIL_IMAGE =
  "KLAGE_MILJOKONTROLL_THUMBNAIL_IMAGE";
export const KLAGE_MILJOKONTROLL_LIGHTBOX_IMAGE =
  "KLAGE_MILJOKONTROLL_LIGHTBOX_IMAGE";
export const KLAGE_RAPPORT = "KLAGE_RAPPORT";
export const KLAGE_SAKSBEHANDLERRAPPORT = "KLAGE_SAKSBEHANDLERRAPPORT";

function getIleggelse(ileggelsesnummer) {
  return axios.get(
    `${api.GET_ILEGGELSER_URL}?ileggelsesnummer=${ileggelsesnummer}`
  );
}

function getMiljogebyr(ileggelsesnummer) {
  return axios.get(
    `${api.GET_MILJOGEBYRER_URL}?ileggelsesnummer=${ileggelsesnummer}`
  );
}

export function getMiljogebyrByNummer(ileggelsesnummer) {
  return async (dispatch, getState) => {
    return axios
      .get(api.GET_MILJOGEBYRER_URL, {
        params: { ileggelsesnummer: ileggelsesnummer },
      })
      .then((response) => {
        const result = response.data.result;
        const miljogebyr = first(result);
        dispatch({ type: GET_KLAGE_ILEGGELSE, payload: miljogebyr });
        return result;
      });
  };
}

export function getIleggelseByNummer(ileggelsesnummer) {
  return async (dispatch, getState) => {
    return axios
      .get(api.GET_ILEGGELSER_URL, {
        params: { ileggelsesnummer: ileggelsesnummer },
      })
      .then((response) => {
        const result = response.data.result;
        const ileggelse = first(result);
        dispatch({ type: GET_KLAGE_ILEGGELSE, payload: ileggelse });
        return result;
      });
  };
}

export function getIleggelseDetailByNummer(
  ileggelsesnummer,
  type,
  shouldUpdateReducer = true
) {
  return async (dispatch) => {
    if (!type) {
      return Promise.all([
        getIleggelse(ileggelsesnummer),
        getMiljogebyr(ileggelsesnummer),
      ]).then((response) => {
        const ileggelser = response[0] ? response[0].data.result : {};
        const miljogebyrer = response[1] ? response[1].data.result : {};
        if (!isEmpty(ileggelser)) {
          const ileggelse = first(ileggelser);
          if (shouldUpdateReducer) {
            dispatch({ type: GET_KLAGE_ILEGGELSE, payload: ileggelse });
          }
          return ileggelse;
        } else if (!isEmpty(miljogebyrer)) {
          const miljogebyr = first(miljogebyrer);
          if (shouldUpdateReducer) {
            dispatch({ type: GET_KLAGE_ILEGGELSE, payload: miljogebyr });
          }
          return miljogebyr;
        }
      });
    } else if (type === "M") dispatch(getMiljogebyrByNummer(ileggelsesnummer));
    else dispatch(getIleggelseByNummer(ileggelsesnummer));
  };
}

export function setKlageIleggelseInReducer(ileggelse) {
  return {
    type: GET_KLAGE_ILEGGELSE,
    payload: ileggelse,
  };
}

export const getKlageInfo = (
  saksnummer,
  silence = true,
  actionType = KLAGE_DETAIL
) => {
  const url = api.GET_KLAGER_URL(saksnummer);
  return axiosUtils.GetOne(url, actionType, true, { silence });
};

export const addKlage = (payload) => {
  const url = api.ADD_KLAGE_URL;
  let metaData = {
    entitet: "Klage",
    mergeObj: payload,
    firstItem: true,
    notify: false,
  };
  return axiosUtils.Post(url, payload, KLAGE_DETAIL, metaData);
};

export const updateKlage = (payload) => {
  const url = api.UPDATE_KLAGER_URL(payload.id);
  let metaData = { entitet: "Klage", mergeObj: payload, notify: false };
  return axiosUtils.Put(url, payload, null, metaData);
};

export const deletePermanentKlage = (klageId) => {
  const url = api.DELETE_PERMANENT_KLAGE_URL(klageId);
  return axiosUtils.Delete(url, null);
};

export const getKlageIleggelser = (klageId) => {
  const url = api.KLAGE_ILEGGELSE_URL(klageId);
  return axiosUtils.GetAll(url, KLAGE_ILEGGELSER);
};

export const addKlageIleggelse = (klageId, ileggelse) => {
  const payload = {
    ileggelsetypeId: ileggelse.ileggelsestypeId,
    ileggelsesnummer: ileggelse.ileggelsesnummer,
    ileggelseId: ileggelse.id,
  };
  const url = api.KLAGE_ILEGGELSE_URL(klageId);
  return axiosUtils.Post(url, payload);
};

export const deleteKlageIleggelse = (klageId, ileggeseId) => {
  const url = api.DELETE_KLAGE_ILEGGELSE_URL(klageId, ileggeseId);
  return axiosUtils.Delete(url, KLAGE_ILEGGELSER);
};

const imageUrl = (ileggelseId, ileggelsetype, imageId = "") => {
  let baseUrl =
    ileggelsetype === "M"
      ? api.GET_MILJOGEBYRER_BILDER_BASE_URL(ileggelseId)
      : api.GET_ILEGGELSER_BILDER_BASE_URL(ileggelseId);
  if (imageId && imageId !== "") {
    return baseUrl + "/" + imageId;
  }
  return baseUrl + "/base64";
};

const getIleggelsetypeAdjustedS3BucketCategory = (
  s3BucketCategory,
  ileggelsetype
) => {
  if (!s3BucketCategory) {
    return undefined;
  }

  return ileggelsetype === "M"
    ? "miljogebyrer_" + s3BucketCategory
    : "ileggelser_" + s3BucketCategory;
};

export const getAllBilderInBase64 = (
  ileggelseId,
  ileggelsetype,
  s3BucketCategory = undefined
) => {
  const url = imageUrl(ileggelseId, ileggelsetype);

  const ileggelsetypeAdjustedS3BucketCategory =
    getIleggelsetypeAdjustedS3BucketCategory(s3BucketCategory, ileggelsetype);

  let actionType = KLAGE_ILEGGELSE_IMAGE;
  if (s3BucketCategory === "thumbnail") {
    actionType = KLAGE_ILEGGELSE_THUMBNAIL_IMAGE;
  } else if (s3BucketCategory === "lightbox") {
    actionType = KLAGE_ILEGGELSE_LIGHTBOX_IMAGE;
  }

  if (s3BucketCategory) {
    return axiosUtils.GetAllWithParams(
      url,
      {
        s3BucketCategory: ileggelsetypeAdjustedS3BucketCategory,
      },
      actionType,
      null,
      true
    );
  }

  return axiosUtils.GetAll(url, actionType);
};

export const getAllMiljoKontrollBilderInBase64 = (
  miljoKontrollId,
  s3BucketCategory = undefined
) => {
  switch (s3BucketCategory) {
    case "thumbnail":
      return axiosUtils.GetAllWithParams(
        api.GET_MILJOKONTROLLER_BILDER_BASE_URL(miljoKontrollId),
        { s3BucketCategory: "miljogebyrer_thumbnail" },
        KLAGE_MILJOKONTROLL_THUMBNAIL_IMAGE
      );
    case "lightbox":
      return axiosUtils.GetAllWithParams(
        api.GET_MILJOKONTROLLER_BILDER_BASE_URL(miljoKontrollId),
        { s3BucketCategory: "miljogebyrer_lightbox" },
        KLAGE_MILJOKONTROLL_LIGHTBOX_IMAGE
      );
    default:
      break;
  }

  return axiosUtils.GetAll(
    api.GET_MILJOKONTROLLER_BILDER_BASE_URL(miljoKontrollId),
    "KLAGE_MILJOKONTROLL_IMAGE"
  );
};

export const getKlageLedelseRapport = (params) => {
  const url = api.GET_KLAGE_RAPPORT_LEDELSE_URL;
  return axiosUtils.GetAllWithParamsSerializer(url, params, KLAGE_RAPPORT);
};
export const ResetKlageLedelseRapport = () => {
  return axiosUtils.ResetToInitiateState(KLAGE_RAPPORT, {});
};
export const resetKlageIleggelser = () => {
  return axiosUtils.ResetToInitiateState(KLAGE_ILEGGELSER, {});
};

export const getKlagerSaksbehandlerrapport = (params) => {
  return axiosUtils.GetAllWithParamsSerializer(
    api.GET_KLAGER_SAKSBEHANDERRAPPORTER_URL,
    params,
    KLAGE_SAKSBEHANDLERRAPPORT
  );
};

export const ResetKlageSaksbehandlerRapport = () =>
  axiosUtils.ResetToInitiateState(KLAGE_SAKSBEHANDLERRAPPORT, {});
