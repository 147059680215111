import { GET_KLAGE_ILEGGELSE } from "../actions/klager/klageAction";

const initialState = {};

export const klageIleggelseReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_KLAGE_ILEGGELSE: {
      return action.payload;
    }
    default:
      return state;
  }
};

export default klageIleggelseReducer;
