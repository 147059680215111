import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { toNorwegianDatetime } from "../../../utils/datetimeUtils";

import { ventilstillingsdetalj } from "../../../constants/titles";

const asyncGetVentilstilling = async (id, getVentilstilling) =>
  await getVentilstilling(id);

const VentilstillingsdetailUtskrift = ({
  ventilstilling,
  getVentilstilling,
}) => {
  let dato =
    ventilstilling.dato &&
    toNorwegianDatetime(ventilstilling.dato).format("DD.MM.YYYY HH:mm");

  let planlagtOppfolgingDato =
    ventilstilling.planlagtOppfolgingDato &&
    toNorwegianDatetime(ventilstilling.planlagtOppfolgingDato).format(
      "DD.MM.YYYY HH:mm"
    );

  let oppfolgingDato =
    ventilstilling.oppfolgningDato &&
    toNorwegianDatetime(ventilstilling.oppfolgningDato).format(
      "DD.MM.YYYY HH:mm"
    );

  useEffect(() => {
    const id = JSON.parse(window.sessionStorage.getItem("printData"));
    window.sessionStorage.removeItem("printData");
    document.title = ventilstillingsdetalj(id);
    asyncGetVentilstilling(id, getVentilstilling);
    setTimeout(() => {
      window.print();
      window.close();
    }, 1000);
  }, [getVentilstilling]);

  return (
    <div>
      <div>
        <h1>Ventilstillingsdetaljer</h1>
        <div
          className="fake-label"
          style={{ fontSize: 16 }}
        >{`${dato} for kjennemerke ${ventilstilling.kjennemerke}`}</div>
      </div>
      <div>
        <h2>Generellt</h2>
        <div>Dato: {dato}</div>
        <div>Status: {ventilstilling.status}</div>
        <div>Ileggelsesnummer: {ventilstilling.ileggelsesnummer}</div>
        <div>Planlagt oppfølgning: {planlagtOppfolgingDato}</div>
        <div>Oppfølgning: {oppfolgingDato}</div>
        <div>Tjenestenummer: {ventilstilling.tjenesteNummer}</div>
      </div>
      <div>
        <h2>Adresse</h2>
        <div>Gate: {ventilstilling.gatenavn}</div>
        <div>Husnummer: {ventilstilling.gatenummer}</div>
        <div>Ved nr.: {ventilstilling.vedGatenummer}</div>
        <div>Ved: {ventilstilling.vedGate}</div>
      </div>
      <div>
        <h2>Kjøretøy</h2>
        <div>Kjennemerke: {ventilstilling.kjennemerke}</div>
        <div>Kjøretøytype: {ventilstilling.kjoretoyType}</div>
        <div>Fabrikkmerke: {ventilstilling.fabrikkmerke}</div>
        <div>Annen informasjon: {ventilstilling.annetinformasjon}</div>
      </div>
      <div>
        <h2>Ventilstillinger</h2>
        <div>VF: {ventilstilling.ventilVf}</div>
        <div>HF: {ventilstilling.ventilHf}</div>
        <div>VB: {ventilstilling.ventilVb}</div>
        <div>HB: {ventilstilling.ventilHb}</div>
      </div>
    </div>
  );
};

VentilstillingsdetailUtskrift.propTypes = {
  ventilstilling: PropTypes.object,
  getVentilstilling: PropTypes.func.isRequired,
};

export default VentilstillingsdetailUtskrift;
