function getEnvironmentVariable(key) {
  // check global window objekt first since when running in docker containers REACT_APP_* variables
  // are added to the window object (via app-config.js) at container start (to avoid having
  // to build the project each time the container starts).
  const env = window[key] || process.env[key];
  if (env != null) return env;
  throw new Error(`Missing environment configuration with key '${key}'`);
}

export default getEnvironmentVariable;
