import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import map from "lodash/map";

import Table from "../../Common/Tables/BaseTable";
import NasjonerModal from "./UpdateModals/NasjonerModal";

import { getNasjoner } from "../../../actions/nasjonerActions";
import * as title from "../../../constants/titles";

class NasjonerList extends Component {
  static propTypes = {
    authorizedToCreateAndEdit: PropTypes.bool.isRequired,
    nasjoner: PropTypes.arrayOf(
      PropTypes.shape({
        kode: PropTypes.string,
        navn: PropTypes.string,
      })
    ),
  };

  constructor(props) {
    super(props);
    this.renderRow = this.renderRow.bind(this);
  }
  componentDidMount() {
    document.title = title.grunndataNasjoner;
    this.props.getNasjoner();
  }

  renderRow() {
    return map(this.props.nasjoner, (nasjon) => (
      <tr key={`nasjonerTableBodyTr${nasjon.id.toString()}`}>
        <td>{nasjon.kode}</td>
        <td>{nasjon.navn}</td>
        <td>
          {this.props.authorizedToCreateAndEdit && (
            <NasjonerModal nasjon={nasjon} />
          )}
        </td>
      </tr>
    ));
  }

  render() {
    const headerRow = ["Kode", "Navn", ""];
    return (
      <div className="table-wrapper-horizontal-scroll">
        <Table id="nasjon-liste" headerRow={headerRow} defaultStyle striped>
          <tbody>{this.renderRow()}</tbody>
        </Table>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    nasjoner: state.nasjoner,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getNasjoner }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(NasjonerList);
