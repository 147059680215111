import { combineReducers } from "redux";

import aarsrapportSubmittedFormReducer from "./aarsrapportSubmittedFormReducer";
import antallIleggelserPerIleggelsetypeSubmittedFormReducer from "./antallIleggelserPerIleggelsetypeSubmittedFormReducer";
import ventilstillingerSubmittedFormReducer from "./ventilstillingerSubmittedFormReducer";
import piggdekkontrollerSubmittedFormReducer from "./piggdekkontrollerSubmittedFormReducer";
import uferdigstilteKlagesakerSubmittedFormReducer from "./uferdigstilteKlagesakerSubmittedFormReducer";
import skiftloggSubmittedFormReducer from "./skiftloggSubmittedFormReducer";
import makuleringerSubmittedFormReducer from "./makuleringerSubmittedFormReducer";

export default combineReducers({
  aarsrapportSubmittedForm: aarsrapportSubmittedFormReducer,
  antallIleggelserPerIleggelsetypeSubmittedForm: antallIleggelserPerIleggelsetypeSubmittedFormReducer,
  ventilstillingerSubmittedForm: ventilstillingerSubmittedFormReducer,
  piggdekkontrollerSubmittedForm: piggdekkontrollerSubmittedFormReducer,
  uferdigstilteKlagesakerSubmittedForm: uferdigstilteKlagesakerSubmittedFormReducer,
  skiftloggSubmittedForm: skiftloggSubmittedFormReducer,
  makuleringerSubmittedForm: makuleringerSubmittedFormReducer
});
