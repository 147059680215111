import { actionTypeSkiftlogger } from "../actions/skiftloggerActions";
import { CreateNewReducer } from "../utils/reducerUtils";

const initialState = { list: [], sum: {} };
export const skiftloggerReducer = (
  state = { list: [...initialState.list], sum: { ...initialState.sum } },
  action
) => CreateNewReducer(state, action, actionTypeSkiftlogger);

export default skiftloggerReducer;
