import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ModalGeneric from "../../Common/ModalGeneric";

import OpenModalComponent from "./OpenModalComponent";
import InternkoderForm from "../InternkoderForm";

import {
  postInternkoderIleggelser,
  getInternkoderIleggelser,
} from "../../../actions/internkoderIleggelserActions";
import {
  postInternkoderMiljogebyr,
  getInternkoderMiljogebyr,
} from "../../../actions/internkoderMiljogebyrActions";
import {
  IsValidated,
  onChangeValidation,
} from "../../../utils/validationUtils";

class InternkoderModal extends React.Component {
  static propTypes = {
    postInternkoderIleggelser: PropTypes.func,
    postInternkoderMiljogebyr: PropTypes.func,
    modalToRender: PropTypes.string,
  };
  get initState() {
    return {
      open: false,
      submitButtonText: "Legg til ny",
      id: "",
      beskrivelse: "",
      aktivertDato: "",
      deaktivertDato: "",
      aktiv: true,
      validationError: {},

      openDeaktiverConfirm: false,
      deaktiveringConfirmed: false,
    };
  }
  rules = {
    id: "required;isDigit;minValue=1",
    beskrivelse: "required;minLength=3;maxLength=50",
    aktivertDato: "isDato;isLessThan=deaktivertDato",
    deaktivertDato: "isDato;isLargerThan=aktivertDato",
  };
  state = this.initState;

  componentDidUpdate = (prevProps, prevState) => {
    const { deaktiveringConfirmed } = this.state;
    if (
      deaktiveringConfirmed === true &&
      prevState.deaktiveringConfirmed !== deaktiveringConfirmed
    ) {
      this.saveAndClose();
    }
  };

  closeModal = () => {
    this.setState(this.initState);
  };

  openModal = () => {
    this.setState({ open: true });
  };

  handlePost = async () => {
    const { modalToRender } = this.props;

    this.setState({ submitButtonText: "Legger til..." });

    const aktivertDato = this.state.aktivertDato
      ? this.state.aktivertDato
      : null;
    const deaktivertDato = this.state.deaktivertDato
      ? this.state.deaktivertDato
      : null;

    const payload = {
      id: this.state.id && Number(this.state.id),
      aktivertDato,
      deaktivertDato,
      beskrivelse: this.state.beskrivelse,
    };
    if (modalToRender === "ileggelser") {
      this.props
        .postInternkoderIleggelser(payload)
        .then(() => {
          this.closeModal();
        })
        .catch(() => this.setState({ submitButtonText: "Legg til ny" }));
    } else {
      this.props
        .postInternkoderMiljogebyr(payload)
        .then(() => {
          this.closeModal();
        })
        .catch(() => this.setState({ submitButtonText: "Legg til ny" }));
    }
  };

  isValid = () => {
    const { isValid, validationError } = IsValidated(this.state, this.rules);
    this.setState({ validationError });
    return isValid;
  };

  saveAndClose = (e) => {
    const { deaktiveringConfirmed, deaktivertDato } = this.state;

    if (this.isValid() && (deaktiveringConfirmed || !deaktivertDato)) {
      this.handlePost();
    } else if (this.isValid() && deaktivertDato) {
      this.setState({ open: false, openDeaktiverConfirm: true });
    }
  };

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
    onChangeValidation(e, this.state, this.rules);
  };

  render() {
    const { modalToRender } = this.props;
    const { submitButtonText, openDeaktiverConfirm } = this.state;

    const modalTilte =
      modalToRender === "ileggelser"
        ? "Legg til ny internkode for ileggelser"
        : "Legg til ny internkode for miljøgebyr";

    return (
      <>
        <ModalGeneric
          modalTitle={modalTilte}
          open={this.state.open}
          closeModal={this.closeModal}
          openModal={this.openModal}
          OpenModalComponent={OpenModalComponent}
          submit={this.saveAndClose}
          submitButtonText={submitButtonText}
        >
          <InternkoderForm
            id={this.state.id}
            beskrivelse={this.state.beskrivelse}
            aktivertDato={this.state.aktivertDato}
            deaktivertDato={this.state.deaktivertDato}
            validationError={this.state.validationError}
            handleChange={this.handleChange}
          />
        </ModalGeneric>
        <ModalGeneric
          modalTitle="Deaktiver internkode"
          open={openDeaktiverConfirm}
          closeModal={() =>
            this.setState({ openDeaktiverConfirm: false, open: true })
          }
          submit={() => this.setState({ deaktiveringConfirmed: true })}
          submitButtonText={this.state.submitButtonText}
        >
          <p>Vil du deaktivere internkode {this.state.id}?</p>
        </ModalGeneric>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(
    {
      postInternkoderIleggelser,
      postInternkoderMiljogebyr,
      getInternkoderIleggelser,
      getInternkoderMiljogebyr,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(InternkoderModal);
