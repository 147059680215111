import { actionType } from "../actions/overtredelseskoderIleggelserActions";
import { CreateNewReducer } from "../utils/reducerUtils";

const INITIAL_STATE = [];

export const overtredelseskoderIleggelserReducer = (
  state = INITIAL_STATE,
  action
) => CreateNewReducer(state, action, actionType);

export default overtredelseskoderIleggelserReducer;
