export const getMiljogebyrVedtakVenterTitle = (reportCountData) => {
  return reportCountData &&
    (reportCountData.a_MiljoGebyrVedtakInTimeQuarantineCount ||
      reportCountData.a_MiljoGebyrVedtakInTimeQuarantineCount === 0)
    ? `Klagevedtak som venter på å bli sendt (${reportCountData.a_MiljoGebyrVedtakInTimeQuarantineCount})`
    : "Klagevedtak som venter på å bli sendt";
};

export const getMiljogebyrVedtakStoppetTitle = (reportCountData) => {
  return reportCountData &&
    (reportCountData.b_MiljoGebyrVedtakStoppetAvHgkSystemetCount ||
      reportCountData.b_MiljoGebyrVedtakStoppetAvHgkSystemetCount === 0)
    ? `Klagevedtak stoppet av fagsystemet (${reportCountData.b_MiljoGebyrVedtakStoppetAvHgkSystemetCount})`
    : "Klagevedtak stoppet av fagsystemet";
};

export const getMiljogebyrVedtakKlarTitle = (reportCountData) => {
  return reportCountData &&
    (reportCountData.c_MiljoGebyrVedtakWillBeSentCount ||
      reportCountData.c_MiljoGebyrVedtakWillBeSentCount === 0)
    ? `Klagevedtak klare til å sendes (${reportCountData.c_MiljoGebyrVedtakWillBeSentCount})`
    : "Klagevedtak klare til å sendes";
};

export const getMiljogebyrVedtakBehandlesInnkrevingTitle = (
  reportCountData
) => {
  return reportCountData &&
    (reportCountData.d_MiljoGebyrVedtakInIneQueueCount ||
      reportCountData.d_MiljoGebyrVedtakInIneQueueCount === 0)
    ? `Klagevedtak under behandling hos Innkreving (${reportCountData.d_MiljoGebyrVedtakInIneQueueCount})`
    : "Klagevedtak under behandling hos Innkreving";
};

export const getMiljogebyrVedtakFeiletTitle = (reportCountData) => {
  return reportCountData &&
    (reportCountData.f_MiljoGebyrVedtakHasBeenRejectedCount ||
      reportCountData.f_MiljoGebyrVedtakHasBeenRejectedCount === 0)
    ? `Klagevedtak feilet hos Innkreving (${reportCountData.f_MiljoGebyrVedtakHasBeenRejectedCount})`
    : "Klagevedtak feilet hos Innkreving";
};

export const getMiljogebyrVedtakVellykketTitle = (reportCountData) => {
  return reportCountData &&
    (reportCountData.e_MiljoGebyrVedtakHasBeenAcceptedCount ||
      reportCountData.e_MiljoGebyrVedtakHasBeenAcceptedCount === 0)
    ? `Klagevedtak som er behandlet (${reportCountData.e_MiljoGebyrVedtakHasBeenAcceptedCount})`
    : "Klagevedtak som er behandlet";
};

export const getMiljogebyrVedtakVenterHeaders = {
  Saksnummer: { cellKey: "saksnummer" },
  Saksbehandler: {},
  Vedtaksdato: { cellKey: "vedtaksdato" },
  "Ilegg.nr.": {},
  Klagekode: { cellKey: "klagekode" },
  "Navn på mottaker": {},
  Adresse: {},
  "Postnr.": {},
  Poststed: {},
  // beløp
  // nytt beløp
};

export const getMiljogebyrVedtakStoppetHeaders = {
  Saksnummer: { cellKey: "saksnummer" },
  Saksbehandler: {},
  Vedtaksdato: { cellKey: "vedtaksdato" },
  "Ilegg.nr.": {},
  Klagekode: { cellKey: "klagekode" },
  "Navn på mottaker": {},
  Adresse: {},
  "Postnr.": {},
  Poststed: {},
  // beløp
  // nytt beløp
  Feil: {},
};

export const getMiljogebyrVedtakKlarHeaders = getMiljogebyrVedtakVenterHeaders;

export const getMiljogebyrVedtakBehandlesInnkrevingHeaders = {
  Saksnummer: { cellKey: "saksnummer" },
  Saksbehandler: {},
  Vedtaksdato: { cellKey: "vedtaksdato" },
  "Ilegg.nr.": {},
  Klagekode: { cellKey: "klagekode" },
  "Navn på mottaker": {},
  Adresse: {},
  "Postnr.": {},
  Poststed: {},
  "Sent INE dato": { cellKey: "sent_to_ine" },
  // beløp
  // nytt beløp
};

export const getMiljogebyrVedtakFeiletHeaders = {
  Saksnummer: { cellKey: "saksnummer" },
  Saksbehandler: {},
  Vedtaksdato: { cellKey: "vedtaksdato" },
  "Ilegg.nr.": {},
  Klagekode: { cellKey: "klagekode" },
  "Navn på mottaker": {},
  Adresse: {},
  "Postnr.": {},
  Poststed: {},
  // beløp
  // nytt beløp
  "Behandlet INE dato": { cellKey: "received_to_ine" },
  Feil: {},
};

export const getMiljogebyrVedtakVellykketHeaders = {
  Saksnummer: { cellKey: "saksnummer" },
  Saksbehandler: {},
  Vedtaksdato: { cellKey: "vedtaksdato" },
  "Ilegg.nr.": {},
  Klagekode: { cellKey: "klagekode" },
  "Navn på mottaker": {},
  Adresse: {},
  "Postnr.": {},
  Poststed: {},
  // beløp
  // nytt beløp
  "Behandlet INE dato": { cellKey: "received_to_ine" },
};
