import React from "react";
import { connect } from "react-redux";
import SendTilIne from "../../../Common/Detalj/SendTilIne";
import { getMiljogebyr as getMiljogebyrAction } from "../../../../actions/miljogebyrerActions";
import { putMiljogebyrerSettKlarTilIne as putMiljogebyrerSettKlarTilIneAction } from "../../../../actions/miljogebyrerActions";

class SendTilIneContainer extends React.Component {
  render() {
    const { miljogebyr } = this.props;
    if (!miljogebyr.isMakulert && miljogebyr.isFeiletKemner) {
      return <SendTilIne {...this.props} />;
    } else {
      return <span />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    miljogebyr: state.miljogebyr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMiljogebyr: (miljogebyrId) =>
      dispatch(getMiljogebyrAction(miljogebyrId)),
    putMiljogebyrerSettKlarTilIne: (miljogebyrId) =>
      dispatch(putMiljogebyrerSettKlarTilIneAction(miljogebyrId)),
  };
};

const mergeProps = (stateProps, dispatchProps) => {
  return {
    miljogebyr: stateProps.miljogebyr,
    putMiljogebyrerSettKlarTilIne: () =>
      dispatchProps
        .putMiljogebyrerSettKlarTilIne(stateProps.miljogebyr.id)
        .then(() => dispatchProps.getMiljogebyr(stateProps.miljogebyr.id))
        .catch(),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(SendTilIneContainer);
