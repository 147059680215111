import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";

import ModalGenericPostCancelContainer from "../../../Common/ModalGenericPostCancelContainer";

import { getMiljogebyr as getMiljogebyrAction } from "../../../../actions/miljogebyrerActions";
import { deleteMiljoMakulering as deleteMiljoMakuleringAction } from "../../../../actions/miljoMakuleringerActions";

import { getMostRecentObjectComparedOnParameter } from "../../../../utils/datetimeUtils";

class OpphevMakuleringContainer extends React.Component {
  static propTypes = {
    miljogebyr: PropTypes.object,
    mostRecentMakulering: PropTypes.object,
    getMiljogebyr: PropTypes.func.isRequired,
    deleteMiljoMakulering: PropTypes.func.isRequired,
    reloadHistorikk: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      kommentar: null,
      kommentarValidationError: null,
    };

    this.handleApiCall = this.handleApiCall.bind(this);
  }

  async handleApiCall(makuleringId) {
    return this.props
      .deleteMiljoMakulering(makuleringId)
      .then(() => {
        this.props.getMiljogebyr(this.props.miljogebyr.id);
        this.props.reloadHistorikk();
        return Promise.resolve();
      })
      .catch((error) => Promise.reject(error));
  }

  showOppehevMakulering = () => {
    if (!this.props.miljogebyr.isMakulert) {
      return false;
    }
    if (!this.props.mostRecentMakulering) {
      return false;
    }
    if (
      this.props.miljogebyr.id !== this.props.mostRecentMakulering.miljogebyrId
    ) {
      return false;
    }
    return true;
  };

  render() {
    if (this.showOppehevMakulering()) {
      return (
        <ModalGenericPostCancelContainer
          id={this.props.mostRecentMakulering.id}
          submitButtonText="Opphev makulering"
          submittingButtonText="Opphever makulering..."
          successPostCallFlashMessage="Endring vellykket"
          errorPostCallFlashMessage="Endring feilet"
          handleApiCall={this.handleApiCall}
          modalTitle={`Opphev makulering for miljogebyr  ${this.props.miljogebyr.ileggelsesnummer}`}
          openModalComponentFunction={(props) => (
            <Button
              className="btn btn-bym-standard"
              style={{ marginLeft: 20 }}
              onClick={props.openModal}
            >
              Opphev makulering
            </Button>
          )}
        >
          <p>{`Vil du oppheve makulering av miljøgebyr ${this.props.miljogebyr.ileggelsesnummer}?`}</p>
        </ModalGenericPostCancelContainer>
      );
    }
    return <span />;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    miljogebyr: state.miljogebyr,
    mostRecentMakulering:
      state.miljoMakuleringer &&
      getMostRecentObjectComparedOnParameter(
        state.miljoMakuleringer,
        "makuleringDato"
      ),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getMiljogebyr: (miljogebyrId) =>
      dispatch(getMiljogebyrAction(miljogebyrId)),
    deleteMiljoMakulering: (makuleringId) =>
      dispatch(deleteMiljoMakuleringAction(makuleringId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OpphevMakuleringContainer);
