import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import LoadButton from "./LoadButton";
import "./ModalGeneric.css";

const getModalBodyAndFooter = (
  isForm,
  hasSubmitButton,
  showSubmitSpinner,
  disabled,
  closeButtonText,
  submitButtonText,
  submit,
  closeModal,
  children
) => {
  if (isForm) {
    return (
      <form onSubmit={submit}>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <div className="col-xs-12">
            <button
              type="button"
              className="btn btn-link pull-left"
              onClick={closeModal}
            >
              {closeButtonText ? closeButtonText : "Avbryt"}
            </button>
            {hasSubmitButton && (
              <LoadButton
                isLoading={showSubmitSpinner}
                buttonProps={{
                  id: "submitButton",
                  className: "btn btn-bym-action btn-default pull-left",
                  disabled: disabled,
                  type: "submit",
                }}
              >
                {submitButtonText}
              </LoadButton>
            )}
          </div>
        </Modal.Footer>
      </form>
    );
  } else {
    return (
      <React.Fragment>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <div className="col-xs-12">
            <button className="btn btn-link pull-left" onClick={closeModal}>
              {closeButtonText ? closeButtonText : "Avbryt"}
            </button>
            {hasSubmitButton && (
              <LoadButton
                isLoading={showSubmitSpinner}
                buttonProps={{
                  id: "submitButton",
                  className: "btn btn-bym-action btn-default pull-left",
                  onClick: submit,
                  disabled: disabled,
                }}
              >
                {submitButtonText}
              </LoadButton>
            )}
          </div>
        </Modal.Footer>
      </React.Fragment>
    );
  }
};

class ModalGeneric extends PureComponent {
  static defaultProps = {
    hasSubmitButton: true,
    hasCloseButton: true,
    submitButtonText: "Send",
    disabled: false,
  };
  static propTypes = {
    id: PropTypes.string,
    modalTitle: PropTypes.string,
    submit: PropTypes.func,
    close: PropTypes.func,
    open: PropTypes.bool,
    OpenModalComponent: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.element,
    ]),
    className: PropTypes.string,
    children: PropTypes.any,
    openModal: PropTypes.func,
    closeModal: PropTypes.func,
    closeButtonText: PropTypes.string,
    hasCloseButton: PropTypes.bool,
    submitButtonText: PropTypes.string,
    submitButtonType: PropTypes.string,
    hasSubmitButton: PropTypes.bool,
    popUpButtonText: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    popupButtonClassName: PropTypes.string,
    popupButtonDisabled: PropTypes.bool,
    isButtonLink: PropTypes.bool,
    disabled: PropTypes.bool,
    showSubmitSpinner: PropTypes.bool,
  };
  render() {
    const {
      id,
      modalTitle,
      open,
      OpenModalComponent,
      isForm,
      children,
      submit,
      disabled,
      openModal,
      closeModal,
      closeButtonText,
      hasSubmitButton,
      submitButtonText,
      popUpButtonText,
      popUpButtonType,
      popupButtonClassName,
      popupButtonDisabled,
      isButtonLink,
      className,
      showSubmitSpinner,
    } = this.props;
    const popupBottonClassName = popupButtonClassName
      ? popupButtonClassName
      : "btn btn-default btn-bym-standard";

    return (
      <React.Fragment>
        {OpenModalComponent && <OpenModalComponent openModal={openModal} />}
        {isButtonLink && popUpButtonText !== "" && (
          <button
            id={id}
            className={
              popUpButtonType && popUpButtonType === "button"
                ? popupBottonClassName
                : "btn btn-link"
            }
            disabled={popupButtonDisabled}
            onClick={this.props.openModal}
          >
            {popUpButtonText}
          </button>
        )}
        <Modal
          dialogClassName={className}
          className="modalGeneric"
          show={open}
          onHide={closeModal}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <strong>{modalTitle}</strong>
            </Modal.Title>
          </Modal.Header>
          {getModalBodyAndFooter(
            isForm,
            hasSubmitButton,
            showSubmitSpinner,
            disabled,
            closeButtonText,
            submitButtonText,
            submit,
            closeModal,
            children
          )}
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalGeneric;
