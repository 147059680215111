import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import { getLandingPage } from "../utils/pathnameUtils";

class LandingPageContainer extends React.Component {
  static propTypes = {
    auth: PropTypes.object,
    history: PropTypes.object,
  };

  componentDidMount() {
    let landingPage = getLandingPage();
    this.props.history.push(landingPage);
  }

  render() {
    return <></>;
  }
}

export default withRouter(LandingPageContainer);
