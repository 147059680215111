import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";

import TextInput from "../Common/Forms/TextInput";
import Datepicker from "../Common/Forms/Datepicker";

export default class InternkoderForm extends React.Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    beskrivelse: PropTypes.string,
    aktivertDato: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    deaktivertDato: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    validationError: PropTypes.object,
    handleChange: PropTypes.func,
    rules: PropTypes.object,
    actionType: PropTypes.string,
  };

  render() {
    const {
      beskrivelse,
      id,
      aktivertDato,
      deaktivertDato,
      validationError,
      handleChange,
      actionType,
    } = this.props;
    return (
      <form>
        <Row>
          <Col md={12}>
            <TextInput
              label="ID"
              value={id}
              name="id"
              type="number"
              disabled={actionType === "update" ? true : false}
              onChange={handleChange}
              validationError={validationError.id}
            />
          </Col>
          <Col md={12}>
            <TextInput
              label="Forklaring"
              onChange={handleChange}
              value={beskrivelse}
              name="beskrivelse"
              validationError={validationError.beskrivelse}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Datepicker
              label="Aktivert dato"
              id="aktivertDato"
              onChange={handleChange}
              locale="nb"
              timeFormat={false}
              value={aktivertDato}
              placeHolder="Velg"
              validationError={validationError.aktivertDato}
            />
          </Col>
          <Col md={6}>
            <Datepicker
              label="Deaktivert dato"
              id="deaktivertDato"
              onChange={handleChange}
              locale="nb"
              timeFormat={false}
              value={deaktivertDato}
              placeHolder="Velg"
              validationError={validationError.deaktivertDato}
            />
          </Col>
        </Row>
      </form>
    );
  }
}
