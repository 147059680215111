import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";

import ModalGenericPostCancelContainer from "../../../Common/ModalGenericPostCancelContainer";

import { getMiljogebyr as getMiljogebyrAction } from "../../../../actions/miljogebyrerActions";
import { putMiljogebyrIsKvalitetsikret as putMiljogebyrIsKvalitetsikretAction } from "../../../../actions/miljogebyrerActions";

class GodkjennContainer extends React.Component {
  static propTypes = {
    miljogebyr: PropTypes.object,
    getMiljogebyr: PropTypes.func.isRequired,
    putMiljogebyrIsKvalitetsikret: PropTypes.func.isRequired,
    reloadHistorikk: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.handleApiCall = this.handleApiCall.bind(this);
  }

  async handleApiCall(miljogebyrId) {
    let params = { isKvalitetsikret: true };
    return await this.props
      .putMiljogebyrIsKvalitetsikret(miljogebyrId, params)
      .then((response) => {
        this.props.getMiljogebyr(this.props.miljogebyr.id);
        this.props.reloadHistorikk();
        return Promise.resolve();
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  render() {
    if (
      this.props.miljogebyr.isMaKvalitetsikres &&
      !this.props.miljogebyr.isMakulert
    ) {
      return (
        <ModalGenericPostCancelContainer
          id={this.props.miljogebyr.id}
          submitButtonText="Godkjenn miljøgebyr"
          submittingButtonText="Godkjenner miljøgebyr..."
          successPostCallFlashMessage="Endring fullført"
          errorPostCallFlashMessage="Endring feilet"
          handleApiCall={this.handleApiCall}
          modalTitle={`Godkjenn miljøgebyr ${this.props.miljogebyr.ileggelsesnummer}`}
          openModalComponentFunction={(props) => (
            <Button
              className="btn btn-bym-standard"
              style={{ marginLeft: 20 }}
              onClick={props.openModal}
            >
              Godkjenn
            </Button>
          )}
        >
          <p>
            {`Vil du godkjenne miljøgebyr ${this.props.miljogebyr.ileggelsesnummer}?`}
          </p>
        </ModalGenericPostCancelContainer>
      );
    }
    return <span />;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    miljogebyr: state.miljogebyr,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getMiljogebyr: (miljogebyrId) =>
      dispatch(getMiljogebyrAction(miljogebyrId)),
    putMiljogebyrIsKvalitetsikret: (miljogebyrId, params) =>
      dispatch(putMiljogebyrIsKvalitetsikretAction(miljogebyrId, params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GodkjennContainer);
