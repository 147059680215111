import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import map from "lodash/map";

import Table from "../../Common/Tables/BaseTable";
import RettekoderModal from "./UpdateModals/RettekoderModal";

import { getRettekoder } from "../../../actions/rettekoderActions";
import * as title from "../../../constants/titles";

class RettekoderList extends Component {
  static propTypes = {
    authorizedToCreateAndEdit: PropTypes.bool.isRequired,
    rettekoder: PropTypes.arrayOf(
      PropTypes.shape({
        kode: PropTypes.string,
        navn: PropTypes.string,
      })
    ),
  };

  constructor(props) {
    super(props);
    this.renderRow = this.renderRow.bind(this);
  }
  componentDidMount() {
    document.title = title.grunndataRettekoder;
    this.props.getRettekoder();
  }

  renderRow() {
    return map(this.props.rettekoder, (rettekode) => (
      <tr key={`rettekoderTableBodyTr${rettekode.id.toString()}`}>
        <td>{rettekode.kode}</td>
        <td>{rettekode.navn}</td>
        <td>
          {this.props.authorizedToCreateAndEdit && (
            <RettekoderModal rettekode={rettekode} />
          )}
        </td>
      </tr>
    ));
  }

  render() {
    const headerRow = ["Kode", "Navn", ""];
    return (
      <div className="table-wrapper-horizontal-scroll">
        <Table id="rettekode-liste" headerRow={headerRow} defaultStyle striped>
          <tbody>{this.renderRow()}</tbody>
        </Table>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    rettekoder: state.rettekoder,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getRettekoder }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(RettekoderList);
