import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { map } from "lodash";
import moment from "moment";

import Table from "../../Common/Tables/BaseTable";

class SkiftloggList extends Component {
  static propTypes = {
    body: PropTypes.object,
  };

  getHeader = () => {
    return [
      "Tjenestenummer",
      "Dato",
      "Klokkeslett",
      "Miljøgebyr",
      "Parkeringsgebyr",
      "Piggdekk",
      "Kontrollsanksjon",
      "Inntauinger",
      "Kommentar",
    ];
  };

  renderTableBody = () => {
    const { body } = this.props;
    let skiftlogger = body ? body.list : [];
    let sum = body ? body.sum : {};
    if (skiftlogger && skiftlogger.length === 0) return null;
    return (
      <tbody>
        {map(skiftlogger, (i, index) => {
          return (
            <tr key={index}>
              <td>{i.tjenestenummer}</td>
              <td>{moment(i.dato).format("DD.MM.YYYY")}</td>
              <td>{moment(i.dato).format("HH:mm")}</td>
              <td>{i.antallMiljogebyr}</td>
              <td>{i.antallParkeringsgebyr}</td>
              <td>{i.antallPiggdekk}</td>
              <td>{i.antallKontrollsanksjon}</td>
              <td>{i.antallInntauing}</td>
              <td>{i.kommentar}</td>
            </tr>
          );
        })}
        {sum && (
          <tr key={"sum"} id="table-sum-row">
            <td />
            <td />
            <td>Sum</td>
            <td>{sum.antallMiljogebyr}</td>
            <td>{sum.antallParkeringsgebyr}</td>
            <td>{sum.antallPiggdekk}</td>
            <td>{sum.antallKontrollsanksjon}</td>
            <td>{sum.antallInntauing}</td>
            <td />
          </tr>
        )}
      </tbody>
    );
  };

  render() {
    return (
      <div className="table-wrapper-horizontal-scroll">
        <Table headerRow={this.getHeader()} striped>
          {this.renderTableBody()}
        </Table>
      </div>
    );
  }
}

export default SkiftloggList;
