import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  handleChange as handleChangeAction,
  handleChangeToInitState as handleChangeToInitStateAction,
} from "../../actions/handleChangeAction";
import { setPage as setPageAction } from "../../actions/advancedListActions";

import { getIleggelser } from "../../actions/ileggelserActions";
import { getIleggelsestyper } from "../../actions/ileggelsestyperActions";
import { getOvertredelserkoderIleggelser } from "../../actions/overtredelseskoderIleggelserActions";
import { handleFromToDateTime } from "../../utils/fromToDateTimeUtils";
import * as inputValidation from "../../utils/inputValidationUtils";
import map from "lodash/map";

import { INITIAL_STATE as InnsynIleggelserFilterFormStateInitial } from "../../reducers/forms/InnsynIleggelserFilterFormReducer";
import InnsynIleggelserFilterForm from "./InnsynIleggelserFilterForm";
import { replaceWhitespacesWithEmptyStrings } from "../../utils/sanitizationUtils";
import { IsValidated } from "../../utils/validationUtils";

class InnsynIleggelserFilterContainer extends Component {
  static propTypes = {
    InnsynIleggelserFilterFormState: PropTypes.object,
    getOvertredelserkoderIleggelser: PropTypes.func,
    getIleggelsestyper: PropTypes.func,
    ileggelsestyper: PropTypes.array,
    overtredelseskoderIleggelser: PropTypes.array,
    setPage: PropTypes.func.isRequired,
  };

  state = {
    shallGetIleggelserDueToHandleChangeToInitState: false,
    showSokSpinner: false,
  };

  componentDidMount() {
    this.props.getOvertredelserkoderIleggelser();
    this.props.getIleggelsestyper();
  }

  componentDidUpdate() {
    if (
      JSON.stringify(this.props.InnsynIleggelserFilterFormState) ===
        JSON.stringify(InnsynIleggelserFilterFormStateInitial) &&
      this.state.shallGetIleggelserDueToHandleChangeToInitState === true
    ) {
      this.setState({ shallGetIleggelserDueToHandleChangeToInitState: false });
    }
  }

  rules = {
    ileggelsesnummer: "isPositiveInteger",
  };

  handleChange = (e) => {
    const { InnsynIleggelserFilterFormState } = this.props;
    if (
      e.target.name === "fraDato" ||
      e.target.name === "tilDato" ||
      e.target.name === "fraTid" ||
      e.target.name === "tilTid"
    ) {
      handleFromToDateTime(
        e,
        InnsynIleggelserFilterFormState,
        this.props.handleChange
      );
      return;
    }

    this.props.handleChange(e.target.name, e.target.value);
  };

  handleChangeToInitState = () => {
    this.props.handleChangeToInitState();
    this.setState({ shallGetIleggelserDueToHandleChangeToInitState: true });
  };

  resetValidationErrorText() {
    this.props.handleChange("fraDatoValidationErrorText", null);
    this.props.handleChange("fraTidValidationErrorText", null);
    this.props.handleChange("tilDatoValidationErrorText", null);
    this.props.handleChange("tilTidValidationErrorText", null);
  }

  validate() {
    const { InnsynIleggelserFilterFormState, handleChange } = this.props;

    const { isValid, validationError } = IsValidated(
      { ...InnsynIleggelserFilterFormState },
      this.rules
    );
    handleChange(
      "validationError.ileggelsesnummer",
      validationError.ileggelsesnummer
    );
    const fraDateTimeTilDateTimeValid =
      inputValidation.fraDateTimeTilDateTimeValidation(
        InnsynIleggelserFilterFormState,
        handleChange
      );

    return isValid && fraDateTimeTilDateTimeValid;
  }

  checkFiltersValue = () => {
    const filters = this.props.InnsynIleggelserFilterFormState;
    var hasValue = false;
    map(filters, (v, k) => {
      if (v !== null && v !== "") hasValue = true;
    });
    return hasValue;
  };

  getFilters() {
    let {
      fraTid,
      tilTid,
      fraDatoValidationErrorText,
      fraTidValidationErrorText,
      tilDatoValidationErrorText,
      tilTidValidationErrorText,
      status,
      ...filter
    } = this.props.InnsynIleggelserFilterFormState;

    filter = {
      ...filter,
      fraDato: filter.fraDato && filter.fraDato.toISOString(),
      tilDato: filter.tilDato && filter.tilDato.toISOString(),
    };
    return filter;
  }

  getIleggelserByFilters = () => {
    if (
      this.checkFiltersValue() &&
      this.props.ileggelser.page &&
      this.props.ileggelser.count
    ) {
      this.setState(() => ({ showSokSpinner: true }));
      const page = 1;
      this.props
        .getIleggelser(this.getFilters(), page, this.props.ileggelser.count)
        .then(() => {
          this.setState(() => ({ showSokSpinner: false }));
          this.props.setPage(page);
        })
        .catch(() => this.setState(() => ({ showSokSpinner: false })));
    }
  };

  handleBlur = (e) => {
    const { handleChange } = this.props;
    if (e.target.name === "ileggelsesnummer") {
      handleChange(
        e.target.name,
        replaceWhitespacesWithEmptyStrings(e.target.value)
      );
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.resetValidationErrorText();
    if (this.validate()) {
      this.getIleggelserByFilters();
    }
  };

  render() {
    let {
      InnsynIleggelserFilterFormState,
      ileggelsestyper,
      overtredelseskoderIleggelser,
    } = this.props;
    const { showSokSpinner } = this.state;
    return (
      <InnsynIleggelserFilterForm
        showSokSpinner={showSokSpinner}
        InnsynIleggelserFilterFormState={InnsynIleggelserFilterFormState}
        handleChange={this.handleChange}
        handleChangeToInitState={this.handleChangeToInitState}
        handleBlur={this.handleBlur}
        onSubmit={this.onSubmit}
        ileggelsestyper={ileggelsestyper}
        overtredelseskoderIleggelser={overtredelseskoderIleggelser}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  ileggelser: state.ileggelser,
  InnsynIleggelserFilterFormState: state.forms.InnsynIleggelserFilterFormState,
  ileggelsestyper: state.ileggelsestyper,
  overtredelseskoderIleggelser: state.overtredelseskoderIleggelser,
});

const mapDispatchToProps = (dispatch) => {
  return {
    handleChange: (variableName, value) =>
      dispatch(
        handleChangeAction("INNSYN_ILEGGELSER_FILTER_FORM", variableName, value)
      ),
    handleChangeToInitState: () =>
      dispatch(handleChangeToInitStateAction("INNSYN_ILEGGELSER_FILTER_FORM")),
    getIleggelsestyper: () => dispatch(getIleggelsestyper()),
    getOvertredelserkoderIleggelser: () =>
      dispatch(getOvertredelserkoderIleggelser()),
    getIleggelser: (InnsynIleggelserFilter, page, count) =>
      dispatch(getIleggelser(InnsynIleggelserFilter, page, count)),
    setPage: (page) => dispatch(setPageAction("ileggelser", page)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InnsynIleggelserFilterContainer);
