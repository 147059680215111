import React from "react";
import PropTypes from "prop-types";

import ModalGeneric from "./ModalGeneric";
import MakulerModalContent from "./IleggelseClient/MakulerModalContent";

import { required } from "../../utils/inputValidationUtils";
import { text } from "../../constants/text";

import { authorized } from "../../utils/authorizationUtils";
import { bypatruljen } from "../../constants/roles";

class MakulerModalContainer extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    ileggelsesnummer: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    titleType: PropTypes.string,
    openMakulerModal: PropTypes.func,
    postMakulering: PropTypes.func.isRequired,
    reloadHistorikk: PropTypes.func,
  };

  state = {
    open: false,
    submitButtonText: text.makulerModal.saveButtonText,
    kommentar: "",
    kommentarValidationText: null,
  };

  authorizedSaksbehandlerAndSaksbehandleradmin = authorized([
    bypatruljen.saksbehandler,
    bypatruljen.saksbehandleradmin,
  ]);

  closeModal = () => {
    this.setState({
      open: false,
      kommentar: this.props.kommentar,
      kommentarValidationText: null,
    });
  };

  openModal = () => {
    this.setState({ open: true });
  };

  IsValid = () => {
    let kommentarValidationText = required(
      text.makulerModal.inputLabel,
      this.state.kommentar
    );

    this.setState({
      kommentarValidationText: kommentarValidationText,
    });

    return !kommentarValidationText;
  };

  saveAndClose = () => {
    this.setState({ submitButtonText: text.makulerModal.saveButtonIsSaving });
    if (this.IsValid()) {
      this.props
        .postMakulering(this.props.id, this.state.kommentar)
        .then(() => {
          this.closeModal();
        })
        .catch(() =>
          this.setState({ submitButtonText: text.makulerModal.saveButtonText })
        );
    }
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { openMakulerModal: OpenMakulerModal } = this.props;
    if (!this.authorizedSaksbehandlerAndSaksbehandleradmin) return null;
    return (
      <ModalGeneric
        modalTitle={text.makulerModal.title(
          this.props.titleType,
          this.props.ileggelsesnummer
        )}
        open={this.state.open}
        closeModal={this.closeModal}
        openModal={this.openModal}
        OpenModalComponent={OpenMakulerModal}
        submit={this.saveAndClose}
        submitButtonText={text.makulerModal.saveButtonText}
      >
        <MakulerModalContent
          ileggelsesnummer={this.props.ileggelsesnummer}
          kommentar={this.state.kommentar}
          kommentarValidationText={this.state.kommentarValidationText}
          handleChange={this.handleChange.bind(this)}
        />
      </ModalGeneric>
    );
  }
}

export default MakulerModalContainer;
