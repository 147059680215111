import * as axiosUtils from "../../utils/axiosUtils";
import * as api from "../../constants/api";
import { toIsoString } from "../../utils/datetimeUtils";

export const actionTypeAntallIleggelserPerIleggelsetype =
  "ANTALL_ILEGGELSER_PER_ILEGGELSETYPE";

export const getAntallIleggelserPerIleggelsetype = (params) => {
  return axiosUtils.GetAllWithParams(
    api.GET_ANTALL_ILEGGELSER_PER_ILEGGELSETYPE_URL,
    {
      fraDato: toIsoString(params.fraDato),
      tilDato: toIsoString(params.tilDato),
    },
    actionTypeAntallIleggelserPerIleggelsetype
  );
};
