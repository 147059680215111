const getSaksnummerObject = (saksnummer) => {
  if (!saksnummer || typeof saksnummer !== "string") {
    return null;
  }
  const trimmedSaksnummer = saksnummer.trim().replace(" ", "");
  const splittedSaksnummer = trimmedSaksnummer.split("/");
  const regexp = new RegExp("^\\d+\\/\\d+$");
  if (splittedSaksnummer.length === 2 && regexp.test(saksnummer, "g")) {
    const year = splittedSaksnummer[0];
    const nummer = splittedSaksnummer[1];
    return { year, nummer };
  }
  return null;
};

const getSakDokumentNummerObject = (sakDokumentNummer) => {
  if (!sakDokumentNummer || typeof sakDokumentNummer !== "string") {
    return null;
  }
  const trimmedSaksnummer = sakDokumentNummer.trim().replace(" ", "");
  const splittedSaksnummer = trimmedSaksnummer.split("/");
  const regexp = new RegExp("^\\d+\\/\\d+-\\d+$");
  if (splittedSaksnummer.length === 2 && regexp.test(sakDokumentNummer, "g")) {
    const year = splittedSaksnummer[0];
    const nummer = splittedSaksnummer[1];
    return { year, nummer };
  }
  return null;
};

const KlageSvarDokumentTittel = "Svar - ileggelse ";
const DokumentInn = "Dokument inn";
const DokumentUt = "Dokument ut";

const exportDefault = {
  getSaksnummerObject,
  getSakDokumentNummerObject,
  KlageSvarDokumentTittel,
  DokumentInn,
  DokumentUt,
};

export default exportDefault;
