function createAsyncListReducer(name, reducer, sort = null) {
  return (
    state = {
      page: 1,
      count: 100,
      total: 0,
      sort,
      filters: {},
      items: [],
    },
    action
  ) => {
    switch (action.type) {
      case `GET_${name}_REQUEST`:
        state = {
          ...state,
          isFetching: true,
        };
        break;
      case `GET_${name}_SUCCESS`:
        state = {
          ...state,
          isFetching: false,
          updated: Date.now(),
          etag: action.meta && action.meta.etag,
        };
        break;
      case `GET_${name}_FAILURE`:
        state = {
          ...state,
          isFetching: false,
        };
        break;
      case `SET_PAGE_COUNT_${name}`:
        state = {
          ...state,
          count: action.payload,
          page: 1,
        };
        break;
      case `SET_PAGE_${name}`:
        state = {
          ...state,
          page: action.payload,
        };
        break;
      case `SET_TOTAL_${name}`:
        state = {
          ...state,
          total: action.payload,
        };
        break;
      case `SET_FILTER_${name}`:
        state = {
          ...state,
          filters: {
            ...state.filter,
            [action.meta.filterKey]: action.payload,
          },
          page: 1,
        };
        break;
      case `SET_SORT_${name}`:
        state = {
          ...state,
          sort: action.payload,
        };
        break;
      default:
        break;
    }

    if (action.not_modified !== true) {
      const items = reducer(state.items, action);
      if (items !== state.items) {
        state = {
          ...state,
          items: items,
        };
      }
    }

    return state;
  };
}

export default createAsyncListReducer;
