import ileggelserSitePaths from "./SitePaths/ileggelserSitePaths";
import miljogebyrerSitePaths from "./SitePaths/miljogebyrerSitePaths";
import uregistrerteSitePaths from "./SitePaths/uregistrerteSitePaths";
import klageSitePaths from "./SitePaths/klageSitePaths";
import grunndataSitePaths from "./SitePaths/grunndataSitePaths";
import rapporterSitePaths from "./SitePaths/rapporterSitePaths";
import innkrevingSitePaths from "./SitePaths/innkrevingSitePaths";
import innsynSitePaths from "./SitePaths/innsynSitePaths";
import inntauingSitePaths from "./SitePaths/inntauingSitePaths";

const sitePaths = {
  version: "/version",
  login: "/login",
  logout: "/logout",
  glemtPassord: "/glemt-passord",
  landingPage: "/landingpage",
  profil: "/profil",
  redirect: "/redirect",
  konto: {
    glemtPassordLenkeSendt: "/konto/linkforglemtpassordersent",
    nyttPassord: "/konto/passord/:key",
  },
  passordAktivering: "/passordaktivering/:key",
  ileggelser: ileggelserSitePaths,
  miljogebyrer: miljogebyrerSitePaths,
  uregistrerte: uregistrerteSitePaths,
  klage: klageSitePaths,
  grunndata: grunndataSitePaths,
  rapporter: rapporterSitePaths,
  innkreving: innkrevingSitePaths,
  innsyn: innsynSitePaths,
  inntauing: inntauingSitePaths,
  admin: {
    page: "/admin",
  },
  linker: "/linker",
};

export default sitePaths;
