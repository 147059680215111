export const getIleggelserVenterTitle = (reportCountData) => {
  return reportCountData &&
    (reportCountData.a_IleggelseInTimeQuarantineCount ||
      reportCountData.a_IleggelseInTimeQuarantineCount === 0)
    ? `Ileggelser som venter på å bli sendt (${reportCountData.a_IleggelseInTimeQuarantineCount})`
    : "Ileggelser som venter på å bli sendt";
};

export const getIleggelserStoppetTitle = (reportCountData) => {
  return reportCountData &&
    (reportCountData.b_IleggelseStoppetAvHgkSystemetCount ||
      reportCountData.b_IleggelseStoppetAvHgkSystemetCount === 0)
    ? `Ileggelser stoppet av fagsystemet (${reportCountData.b_IleggelseStoppetAvHgkSystemetCount})`
    : "Ileggelser stoppet av fagsystemet";
};

export const getIleggelserKlarTitle = (reportCountData) => {
  return reportCountData &&
    (reportCountData.c_IleggelseWillBeSentCount ||
      reportCountData.c_IleggelseWillBeSentCount === 0)
    ? `Ileggelser klare til å sendes (${reportCountData.c_IleggelseWillBeSentCount})`
    : "Ileggelser klare til å sendes";
};

export const getIleggelserBehandlesInnkrevingTitle = (reportCountData) => {
  return reportCountData &&
    (reportCountData.d_IleggelseInIneQueueCount ||
      reportCountData.d_IleggelseInIneQueueCount === 0)
    ? `Ileggelser under behandling hos Innkreving (${reportCountData.d_IleggelseInIneQueueCount})`
    : "Ileggelser under behandling hos Innkreving";
};

export const getIleggelserFeiletTitle = (reportCountData) => {
  return reportCountData &&
    (reportCountData.f_IleggelseHasBeenRejectedCount ||
      reportCountData.f_IleggelseHasBeenRejectedCount === 0)
    ? `Ileggelser feilet hos Innkreving (${reportCountData.f_IleggelseHasBeenRejectedCount})`
    : "Ileggelser feilet hos Innkreving";
};

export const getIleggelserVellykketTitle = (reportCountData) => {
  return reportCountData &&
    (reportCountData.e_IleggelseHasBeenAcceptedCount ||
      reportCountData.e_IleggelseHasBeenAcceptedCount === 0)
    ? `Ileggelser som er behandlet (${reportCountData.e_IleggelseHasBeenAcceptedCount})`
    : "Ileggelser som er behandlet";
};

export const getIleggelserVenterHeaders = {
  "Ilegg.nr.": { cellKey: "ileggelsesnummer" },
  Dato: { cellKey: "dato" },
  Ileggelsestype: { cellKey: "ileggelse_type" },
  Kjennemerke: { cellKey: "kjennemerke" },
  Nasjon: { cellKey: "nasjon" },
  "Overt.kode": {},
};

export const getIleggelserStoppetHeaders = {
  "Ilegg.nr.": { cellKey: "ileggelsesnummer" },
  Dato: { cellKey: "dato" },
  Ileggelsestype: { cellKey: "ileggelse_type" },
  Kjennemerke: { cellKey: "kjennemerke" },
  Nasjon: { cellKey: "nasjon" },
  "Overt.kode": {},
  Feil: {},
};

export const getIleggelserKlarHeaders = {
  "Ilegg.nr.": { cellKey: "ileggelsesnummer" },
  Dato: { cellKey: "dato" },
  Ileggelsestype: { cellKey: "ileggelse_type" },
  Kjennemerke: { cellKey: "kjennemerke" },
  Nasjon: { cellKey: "nasjon" },
  "Overt.kode": {},
};

export const getIleggelserBehandlesInnkrevingHeaders = {
  "Ilegg.nr.": { cellKey: "ileggelsesnummer" },
  Dato: { cellKey: "dato" },
  Ileggelsestype: { cellKey: "ileggelse_type" },
  Kjennemerke: { cellKey: "kjennemerke" },
  Nasjon: { cellKey: "nasjon" },
  "Overt.kode": {},
  "Sent INE dato": { cellKey: "sent_to_ine" },
};

export const getIleggelserFeiletHeaders = {
  "Ilegg.nr.": { cellKey: "ileggelsesnummer" },
  Dato: { cellKey: "dato" },
  Ileggelsestype: { cellKey: "ileggelse_type" },
  Kjennemerke: { cellKey: "kjennemerke" },
  Nasjon: { cellKey: "nasjon" },
  "Overt.kode": {},
  "Behandlet INE dato": { cellKey: "received_from_ine" },
  Feil: {},
};

export const getIleggelserVellykketHeaders = {
  "Ilegg.nr.": { cellKey: "ileggelsesnummer" },
  Dato: { cellKey: "dato" },
  Ileggelsestype: { cellKey: "ileggelse_type" },
  Kjennemerke: { cellKey: "kjennemerke" },
  Nasjon: { cellKey: "nasjon" },
  "Overt.kode": {},
  "Behandlet INE dato": { cellKey: "received_from_ine" },
};
