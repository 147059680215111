import React from "react";
import { createSelector } from "reselect";
import IleggelseOvertredelseskoder from "../components/Common/IleggelseOvertredelseskoder";
import IleggelseInternkoder from "../components/Common/IleggelseInternkoder";
import { toNorwegianDatetime } from "../utils/datetimeUtils";
import { Badge } from "react-bootstrap";

const ileggelse = (state) => state.klageIleggelse;
const overtredelseskoder = (state) => state.overtredelseskoderIleggelser;
const internkoder = (state) => state.internkoderIleggelser;

export default createSelector(
  [ileggelse, overtredelseskoder, internkoder],
  (ileggelse, overtredelseskoder, internkoder) => {
    let klokkeslett = "";
    if (ileggelse.ileggelsesdatoFra) {
      klokkeslett =
        toNorwegianDatetime(ileggelse.ileggelsesdatoFra).format("HH:mm") +
        " - ";
    }
    if (ileggelse.ileggelsesdatoTil) {
      klokkeslett =
        klokkeslett +
        toNorwegianDatetime(ileggelse.ileggelsesdatoTil).format("HH:mm");
    }
    let underskiltKlokkeslett = "";
    if (ileggelse.skiltFraTid) {
      underskiltKlokkeslett =
        toNorwegianDatetime(ileggelse.skiltFraTid).format("HH:mm") + " - ";
    }
    if (ileggelse.skiltTilTid) {
      underskiltKlokkeslett =
        underskiltKlokkeslett +
        toNorwegianDatetime(ileggelse.skiltTilTid).format("HH:mm");
    }

    let gatenummer = "";
    if (ileggelse.gatenummer) {
      gatenummer = ileggelse.gatenummer;
    }
    if (ileggelse.oppgang) {
      gatenummer = gatenummer + " " + ileggelse.oppgang;
    }

    let vedGatenummer = "";
    if (ileggelse.vedGatenummer) {
      vedGatenummer = ileggelse.vedGatenummer;
    }
    if (ileggelse.vedOppgang) {
      vedGatenummer = vedGatenummer + " " + ileggelse.vedOppgang;
    }

    const ileggelseOvertredelseskoder = (
      <IleggelseOvertredelseskoder
        ileggelse={ileggelse}
        overtredelseskoder={overtredelseskoder}
      />
    );

    const ileggelseInternkoder = (
      <IleggelseInternkoder ileggelse={ileggelse} internkoder={internkoder} />
    );
    const ileggelsesnummer = String(ileggelse.ileggelsesnummer);
    let makulertStatus = undefined;
    if (ileggelse.isMakulert) {
      makulertStatus = [
        {
          name: " ",
          value: (
            <React.Fragment>
              <Badge bsClass="badge-bym-red">Makulert</Badge>
            </React.Fragment>
          ),
          description: "",
        },
      ];
    }

    let details = [
      [
        {
          name: "Ileggelsesnummer",
          value: ileggelsesnummer,
          description: "",
        },
        {
          name: "Ileggelsesdato",
          value:
            ileggelse.ileggelsesdatoTil &&
            toNorwegianDatetime(ileggelse.ileggelsesdatoTil).format(
              "DD.MM.YYYY"
            ),
          description: "",
        },
        {
          name: "Klokkeslett",
          value: klokkeslett,
          description: "",
        },
        {
          name: "Tjenestenummer",
          value: String(ileggelse.tjenestenummer),
          description: "",
        },
      ],
      [
        {
          name: "Leveringstype",
          value:
            ileggelse.leveringstype && ileggelse.leveringstype.navn
              ? ileggelse.leveringstype.navn
              : ileggelse.leveringstype,
          description: "",
        },
        {
          name: "Ileggelsestype",
          value: ileggelse.type,
          description: "",
        },
        {
          name: "Kjennemerke",
          value: ileggelse.kjennemerke,
          description: "",
        },
        {
          name: "Nasjon",
          value: ileggelse.nasjon,
          description: "",
        },
      ],
      [
        {
          name: "Fabrikkmerke",
          value: ileggelse.fabrikkmerke || "",
          description: "",
        },
        {
          name: "Kjøretøyinfo",
          value: ileggelse.kjoretoyInfo,
          description: "",
        },
        {
          name: "Kjøretøytype",
          value: ileggelse.kjoretoyType || "",
          description: "",
        },
        { name: "", value: "", description: "" },
      ],

      [
        { name: "Gatenavn", value: ileggelse.gatenavn, description: "" },
        // { name: "Gatenummer", value: ileggelse.gatenummer, description: "" },
        {
          name: "Gatenummer",
          value: gatenummer,
          description: "",
        },
        { name: "Ved", value: ileggelse.vedGate, description: "" },
        { name: "Ved nr", value: vedGatenummer, description: "" },
        // {
        //   name: "Ved nr",
        //   value: `${ileggelse.vedGatenummer} ${ileggelse.vedOppgang}`,
        //   description: ""
        // }
      ],
      [
        {
          name: "Overtredelseskode",
          value: ileggelseOvertredelseskoder,
          description: "",
        },
        {
          name: "Internkode",
          value: ileggelseInternkoder,
          description: "",
        },
        { name: "Antall meter", value: ileggelse.antallMeter, description: "" },
        { name: "Beløp", value: String(ileggelse.belop), description: "" },
      ],
      [
        {
          name: "Underskilt klokkeslett",
          value: underskiltKlokkeslett,
          description: "",
        },
        {
          name: "Automatnummer",
          value: ileggelse.automatnummer,
          description: "",
        },
        { name: "", value: "", description: "" },
        { name: "", value: "", description: "" },
      ],
      // [
      //   {
      //     name: "Interkommentar",
      //     value: ileggelse.internkommentar,
      //     description: ""
      //   },

      //   { name: "", value: "", description: "" },
      //   { name: "", value: "", description: "" },
      //   {
      //     name: "Kommentar til fører/eier",
      //     value: ileggelse.kommentar,
      //     description: ""
      //   }
      // ]
    ];
    //add makulertstatus to the array at index 0
    if (makulertStatus !== undefined) {
      details.splice(0, 0, makulertStatus);
    }
    return details;
  }
);
