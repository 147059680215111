import {
  handleChangeAllActionType,
  handleChangeToInitStateActionType
} from "../../actions/handleChangeAction";

export const INITIAL_STATE = {
  aar: "",
  ileggelsestypeNavn: "",
  submitTimestamp: ""
};

export default function aarsrapportSubmittedFormReducer(
  state = {
    ...INITIAL_STATE
  },
  action
) {
  switch (action.type) {
    case handleChangeAllActionType("RAPPORT_AARSRAPPORT_SUBMITTED_FORM"):
      return { ...action.payload };
    case handleChangeToInitStateActionType(
      "RAPPORT_AARSRAPPORT_SUBMITTED_FORM"
    ):
      return {
        ...INITIAL_STATE
      };
    default:
      return state;
  }
}
