import React from "react";
import { Row, Col } from "react-bootstrap";
import LoadButton from "../LoadButton";

export const FormButtonsForCloseAndSubmit = ({
  closeText,
  submitText,
  onClose,
  onSubmit,
  showCloseSpinner,
  showSubmitSpinner,
}) => {
  return (
    <div className="FormButtonsForCloseAndSubmit">
      <Row>
        <Col sm={12}>
          <LoadButton
            isLoading={showCloseSpinner}
            buttonProps={{
              onClick: onClose,
              className: "btn-link",
              style: { height: 40, paddingRight: 17, paddingLeft: 0 },
              disabled: showCloseSpinner,
            }}
            spinnerProps={{ pColor: "#ffffff", sColor: "#4d4d4d" }}
          >
            {closeText && closeText !== "" ? closeText : "Tilbakestill"}
          </LoadButton>
          <LoadButton
            isLoading={showSubmitSpinner}
            buttonProps={{
              type: "submit",
              className: "btn-bym-action btn-bym-large",
              onClick: onSubmit,
              disabled: showSubmitSpinner,
            }}
            spinnerProps={{ pColor: "#ffffff", sColor: "#4d4d4d" }}
          >
            {submitText && submitText !== "" ? submitText : "Send"}
          </LoadButton>
        </Col>
      </Row>
    </div>
  );
};

export default FormButtonsForCloseAndSubmit;
