import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";

import TextInput from "../Common/Forms/TextInput";

export default class RettekoderForm extends React.Component {
  static propTypes = {
    kode: PropTypes.string,
    navn: PropTypes.string,
    validationError: PropTypes.object,
    handleChange: PropTypes.func,
  };

  render() {
    const { kode, navn, validationError, handleChange } = this.props;
    return (
      <form>
        <Row>
          <Col md={6}>
            <TextInput
              label="Kode"
              onChange={handleChange}
              value={kode}
              name="kode"
              validationError={validationError.kode}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <TextInput
              label="Navn"
              onChange={handleChange}
              value={navn}
              name="navn"
              validationError={validationError.navn}
            />
          </Col>
        </Row>
      </form>
    );
  }
}
