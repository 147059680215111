import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import NavigationBarContainer from "../NavigationBarContainer";

import FilterForm from "./FilterForm";
import List from "./List";
import PrintOut from "../../Common/PrintOut";

import createPaginationControl from "../../Common/createPaginationControl";

import { getElsparksykkelGebyr } from "../../../actions/elsparksykkelGebyrActions";

import { ileggelse } from "../../../constants/titles";
import sitePaths from "../../../constants/sitePaths";

const PaginationControl = createPaginationControl("elsparksykkelGebyr", {
  apiCallAction: getElsparksykkelGebyr,
  queriesForApiReducerName: "forms.elsparksykkelGebyrFilterForm",
});

const ElsparksykkelGebyrPage = () => {
  useEffect(() => {
    document.title = ileggelse.elsparksykkelGebyr;
  }, []);

  const elsparksykkelGebyr = useSelector((store) => store.elsparksykkelGebyr);
  const elsparksykkelGebyrFilterForm = useSelector((store) => {
    return store?.forms?.elsparksykkelGebyrFilterForm;
  });

  return (
    <div>
      <NavigationBarContainer />
      <FilterForm />
      <div style={{ marginTop: 30, paddingLeft: 50, paddingRight: 50 }}>
        <div className="pull-left">
          <PrintOut
            url={`${window.location.origin}${sitePaths.ileggelser.elsparksykkelGebyr.utskrift}`}
            dataToStoreInSessionStorage={{
              queryData: elsparksykkelGebyrFilterForm,
              paginationData: {
                page: elsparksykkelGebyr?.page,
                count: elsparksykkelGebyr?.count,
              },
            }}
          />
        </div>
        <div
          className="pull-right ettersendelse-list-page-total-ettersendelser"
          style={{ paddingTop: 20 }}
        >
          {`${elsparksykkelGebyr?.total} Elsparksykkelgebyr`}
        </div>
        <div className="clearfix" />
        <div style={{ marginTop: 20 }}>
          <List />
          <PaginationControl />
        </div>
      </div>
    </div>
  );
};

export default ElsparksykkelGebyrPage;
