import * as uregistrertekontrollerActions from "../actions/uregistrertekontrollerActions";

const INITIAL_STATE = [];

export default function uregistrertekontrollerReducer(
    state = INITIAL_STATE,
    action
) {
  switch (action.type) {
    case uregistrertekontrollerActions.GET_UREGISTRERTEKONTROLLER_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
