// import PropTypes from "prop-types";
import React from "react";
import SpinLoader from "../../SpinLoader";

import Gallery from "../../Gallery";

import { toNorwegianDatetime } from "../../../../utils/datetimeUtils";

import { getObservasjonIdFromMiljokontrollerBilder } from "../../../../utils/imageUtils";

import { authorized } from "../../../../utils/authorizationUtils";
import { bypatruljen, juridisk } from "../../../../constants/roles";

class BilderAndObservasjonsbilder extends React.Component {
  state = {
    isLoadingImages: false,
    isFetchingThumbnails: false,
    isFetchingLightboxes: false,
    miljogebyrThumbnailBilder: undefined,
    miljogebyrLightboxBilder: undefined,
  };

  isAuthorizedToPostAndPutBilder = () =>
    authorized([bypatruljen.saksbehandler, bypatruljen.saksbehandleradmin]);

  isAuthorizedForToastUi = () =>
    authorized([
      bypatruljen.saksbehandler,
      bypatruljen.saksbehandleradmin,
      juridisk.saksbehandler,
    ]);

  isAuthorizedToDeleteImage = () =>
    authorized([bypatruljen.saksbehandler, bypatruljen.saksbehandleradmin]);

  spinnerLoaderProps = {
    height: 42,
    width: 42,
    thickness: 3,
    pColor: "#ffffff",
    sColor: "#4d4d4d",
  };

  getDatoSortedBilderFromProps = (
    miljokontrollerBilder,
    miljogebyrBilder = undefined,
    bildeType = "thumbnail"
  ) => {
    const { miljogebyr } = this.props;

    let datoSortedBilder = [];
    let miljokontrollDatoSortedBilder = [];

    if (
      (miljogebyr && miljogebyr.miljoKontrollId && miljokontrollerBilder,
      miljokontrollerBilder[miljogebyr.miljoKontrollId])
    ) {
      miljokontrollDatoSortedBilder =
        miljokontrollerBilder[miljogebyr.miljoKontrollId];
      datoSortedBilder = miljokontrollDatoSortedBilder
        ? [...datoSortedBilder, ...miljokontrollDatoSortedBilder]
        : datoSortedBilder;
    }

    if (miljogebyr && miljogebyrBilder && bildeType === "thumbnail") {
      datoSortedBilder = miljogebyrBilder
        ? [
            ...datoSortedBilder,
            {
              dato: toNorwegianDatetime(miljogebyr.ileggelsesdatoTil).format(
                "DD.MM.YYYY"
              ),
              tidspan: toNorwegianDatetime(miljogebyr.ileggelsesdatoTil).format(
                "HH:mm"
              ),
              images: [...miljogebyrBilder],
            },
          ]
        : datoSortedBilder;
    } else if (miljogebyr && miljogebyrBilder && bildeType === "lightbox") {
      datoSortedBilder = miljogebyrBilder
        ? [
            ...datoSortedBilder,
            {
              dato: toNorwegianDatetime(miljogebyr.ileggelsesdatoTil).format(
                "DD.MM.YYYY"
              ),
              tidspan: toNorwegianDatetime(miljogebyr.ileggelsesdatoTil).format(
                "HH:mm"
              ),
              images: [...miljogebyrBilder],
            },
          ]
        : datoSortedBilder;
    }

    return datoSortedBilder;
  };

  componentDidMount = () => {
    const { miljogebyrId, getMiljogebyrBilder } = this.props;
    this.setState(() => ({
      isLoadingImages: true,
      datoSortedBilder: this.getDatoSortedBilderFromProps(
        this.props.miljokontrollerThumbnailBilder
      ),
      datoSortedLightboxBilder: this.getDatoSortedBilderFromProps(
        this.props.miljokontrollerLightboxBilder
      ),
    }));

    getMiljogebyrBilder(miljogebyrId, {
      s3BucketCategory: "miljogebyrer_thumbnail",
    })
      .then((miljogebyrThumbnailBilder) => {
        this.setState(() => ({
          isLoadingImages: false,
          miljogebyrThumbnailBilder: miljogebyrThumbnailBilder,
        }));
        this.setState(() => ({
          datoSortedBilder: this.getDatoSortedBilderFromProps(
            this.props.miljokontrollerThumbnailBilder,
            miljogebyrThumbnailBilder
          ),
        }));
      })
      .catch(() => {
        this.setState({ isLoadingImages: false });
      });

    getMiljogebyrBilder(miljogebyrId, {
      s3BucketCategory: "miljogebyrer_lightbox",
    })
      .then((miljogebyrLightboxBilder) => {
        this.setState(() => ({
          miljogebyrLightboxBilder: miljogebyrLightboxBilder,
        }));
        this.setState(() => ({
          datoSortedLightboxBilder: this.getDatoSortedBilderFromProps(
            this.props.miljokontrollerLightboxBilder,
            miljogebyrLightboxBilder,
            "lightbox"
          ),
        }));
      })
      .catch(() => {});
    // getMiljokontrollBilder is fetched from another component. So no need to fetch it here.
  };

  componentDidUpdate = (prevProps, prevState) => {
    const {
      miljogebyr,
      miljogebyrBilder,
      miljokontrollerThumbnailBilder,
      miljokontrollerLightboxBilder,
      getMiljogebyrBilder,
    } = this.props;

    const { miljogebyrThumbnailBilder, miljogebyrLightboxBilder } = this.state;

    let isUpdatingImages = false;
    if (
      prevProps.miljogebyr.bilder &&
      miljogebyr.bilder &&
      prevProps.miljogebyr.bilder.length !== miljogebyr.bilder.length &&
      isUpdatingImages === false
    ) {
      isUpdatingImages = true;
      this.setState(() => ({
        isLoadingImages: true,
      }));
      getMiljogebyrBilder(miljogebyr.id, {
        s3BucketCategory: "miljogebyrer_thumbnail",
      })
        .then((miljogebyrThumbnailBilderResult) => {
          this.setState(() => ({
            miljogebyrThumbnailBilder: miljogebyrThumbnailBilderResult,
            isLoadingImages: false,
          }));
          this.setState(() => ({
            datoSortedBilder: this.getDatoSortedBilderFromProps(
              miljokontrollerThumbnailBilder,
              miljogebyrThumbnailBilderResult
            ),
          }));
        })
        .catch(() => {
          this.setState({ isLoadingImages: false });
        });

      getMiljogebyrBilder(miljogebyr.id, {
        s3BucketCategory: "miljogebyrer_lightbox",
      })
        .then((miljogebyrLightboxBilderResult) => {
          this.setState(() => ({
            miljogebyrLightboxBilder: miljogebyrLightboxBilderResult,
          }));
          this.setState(() => ({
            datoSortedLightboxBilder: this.getDatoSortedBilderFromProps(
              miljokontrollerLightboxBilder,
              miljogebyrLightboxBilderResult,
              "lightbox"
            ),
          }));
        })
        .catch(() => {});
    }

    if (
      (JSON.stringify(prevProps.miljogebyrBilder) !==
        JSON.stringify(miljogebyrBilder) ||
        JSON.stringify(prevProps.miljokontrollerThumbnailBilder) !==
          JSON.stringify(miljokontrollerThumbnailBilder) ||
        JSON.stringify(prevProps.miljokontrollerLightboxBilder) !==
          JSON.stringify(miljokontrollerLightboxBilder) ||
        prevProps.miljogebyr.id !== miljogebyr.id) &&
      isUpdatingImages === false
    ) {
      this.setState(() => ({
        datoSortedBilder: this.getDatoSortedBilderFromProps(
          miljokontrollerThumbnailBilder,
          miljogebyrThumbnailBilder
        ),
        datoSortedLightboxBilder: this.getDatoSortedBilderFromProps(
          miljokontrollerLightboxBilder,
          miljogebyrLightboxBilder,
          "lightbox"
        ),
      }));
    }
  };

  updateDatoSortedBilder = (bildeId, bildeGoodQuality) => {
    const { datoSortedBilder } = this.state;

    const { index1, index2 } =
      this.getIndex1Index2ForBildeInDatoSortedBilder(bildeId);

    if ((index1 || index1 === 0) && (index2 || index2 === 0)) {
      let tempDatoSortedBilder = [...datoSortedBilder];

      tempDatoSortedBilder[index1].images[index2].isGoodQuality =
        bildeGoodQuality;

      this.setState(() => ({
        datoSortedBilder: tempDatoSortedBilder,
      }));

      return true;
    }

    return false;
  };

  isMiljogebyrBilde = (bildeId) => {
    const { miljogebyr } = this.props;

    return (
      miljogebyr &&
      miljogebyr.bilder &&
      miljogebyr.bilder.some((image) => image.id === bildeId)
    );
  };

  getIndex1Index2ForBildeInDatoSortedBilder = (bildeId) => {
    const { datoSortedBilder } = this.state;

    for (let index1 = 0; index1 < datoSortedBilder.length; index1++) {
      const datoBilder = datoSortedBilder[index1];
      if (!datoBilder || !datoBilder.images) {
        return { index1: null, index2: null };
      }
      for (let index2 = 0; index2 < datoBilder.images.length; index2++) {
        const bilde = datoBilder.images[index2];
        if (bilde.id === bildeId) {
          return { index1: index1, index2: index2 };
        }
      }
    }
    return { index1: null, index2: null };
  };

  handleCheckboxChange = (event) => {
    const {
      miljogebyr,
      putMiljogebyrerBilder,
      putMiljokontrollObservasjonBilde,
      getMiljogebyrerHistorikk,
    } = this.props;
    const { datoSortedBilder } = this.state;

    const bildeId = event.target.id;
    const isGoodQuality = event.target.checked;

    this.updateDatoSortedBilder(bildeId, isGoodQuality);

    if (this.isMiljogebyrBilde(bildeId)) {
      putMiljogebyrerBilder(miljogebyr.id, bildeId, isGoodQuality)
        .then(() => {
          getMiljogebyrerHistorikk(miljogebyr.id);
        })
        .catch(() => {
          this.updateDatoSortedBilder(bildeId, !isGoodQuality);
        });
    } else {
      const { index1, index2 } =
        this.getIndex1Index2ForBildeInDatoSortedBilder(bildeId);
      const observasjonId =
        (index1 || index1 === 0) && (index2 || index2 === 0)
          ? datoSortedBilder[index1].images[index2].observasjonId
          : null;
      putMiljokontrollObservasjonBilde(
        bildeId,
        miljogebyr.miljoKontrollId,
        observasjonId,
        { isGoodQuality }
      )
        .then(() => {
          getMiljogebyrerHistorikk(miljogebyr.id);
        })
        .catch(async () => {
          this.updateDatoSortedBilder(bildeId, !isGoodQuality);
        });
    }
  };

  handleDeleteImageClick = async (bildeId) => {
    const {
      miljogebyr,
      miljokontrollerThumbnailBilder,
      deleteMiljogebyrerBilder,
      deleteMiljokontrollObservasjonBilde,
      getMiljogebyr,
      getMiljokontrollBilder,
      getMiljogebyrerHistorikk,
    } = this.props;

    if (this.isMiljogebyrBilde(bildeId)) {
      await deleteMiljogebyrerBilder(miljogebyr.id, bildeId);
      await getMiljogebyr(miljogebyr.id);
    } else {
      await deleteMiljokontrollObservasjonBilde(
        bildeId,
        miljogebyr.miljoKontrollId,
        getObservasjonIdFromMiljokontrollerBilder(
          miljogebyr.miljoKontrollId,
          bildeId,
          miljokontrollerThumbnailBilder
        ),
        () => getMiljokontrollBilder(miljogebyr.miljoKontrollId)
      );
    }
    getMiljogebyrerHistorikk(miljogebyr.id);
  };

  getBildeUrl = (bildeId) => {
    const { miljogebyr } = this.props;
    const { datoSortedBilder } = this.state;

    const isMiljogebyrBilde = this.isMiljogebyrBilde(bildeId);

    if (isMiljogebyrBilde) {
      return `${window.location.pathname}/bilder/${bildeId}`;
    }
    const { index1: bildeIndex1, index2: bildeIndex2 } =
      this.getIndex1Index2ForBildeInDatoSortedBilder(bildeId);

    let bilde =
      bildeIndex1 || bildeIndex1 === 0 || bildeIndex2 || bildeIndex2 === 0
        ? datoSortedBilder[bildeIndex1].images[bildeIndex2]
        : null;
    if (bilde && bilde.orginalMiljoKontrollObservasjonBildeId) {
      const { index1: orginalBildeIndex1, index2: orginalBildeIndex2 } =
        this.getIndex1Index2ForBildeInDatoSortedBilder(
          bilde.orginalMiljoKontrollObservasjonBildeId
        );

      bilde =
        orginalBildeIndex1 ||
        orginalBildeIndex1 === 0 ||
        orginalBildeIndex2 ||
        orginalBildeIndex2 === 0
          ? datoSortedBilder[orginalBildeIndex1].images[orginalBildeIndex2]
          : null;
    }
    const observasjonId = bilde ? bilde.observasjonId : null;
    bildeId = bilde ? bilde.id : bildeId;

    return `${window.location.pathname}/tidskontroller/${miljogebyr.miljoKontrollId}/observasjoner/${observasjonId}/bilder/${bildeId}`;
  };

  getToastUiUrl = (bildeId) => {
    return `${this.getBildeUrl(bildeId)}/toastui`;
  };

  render() {
    const {
      BilderButtonsContainer,
      miljogebyr,
      checkboxText,
      isLoadingMiljokontrollBilder,
    } = this.props;
    const { datoSortedBilder, datoSortedLightboxBilder, isLoadingImages } =
      this.state;

    return (
      <section style={{ marginRight: 50, marginLeft: 50 }}>
        <div className="detalj-ileggelse-header-wrapper-no-margin">
          <h2>Bilder</h2>
        </div>
        {this.isAuthorizedToPostAndPutBilder() && BilderButtonsContainer && (
          <div className="no-print">
            <BilderButtonsContainer id={miljogebyr.id} />
          </div>
        )}
        <div style={{ marginTop: 10 }}>
          {(isLoadingImages || isLoadingMiljokontrollBilder) && (
            <div style={{ margin: "auto", width: 0 }}>
              <SpinLoader {...this.spinnerLoaderProps} />
            </div>
          )}
        </div>
        {!isLoadingImages &&
          !isLoadingMiljokontrollBilder &&
          datoSortedBilder &&
          datoSortedBilder.length > 0 &&
          datoSortedLightboxBilder &&
          datoSortedBilder.map((datoBilder, index) => (
            <div key={`${datoBilder.dato}${datoBilder.tidspan}`}>
              <h3
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                  marginTop: 20,
                  marginBottom: 20,
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
              >
                {datoBilder.dato} {datoBilder.tidspan}
              </h3>
              <Gallery
                handleDeleteImageClick={this.handleDeleteImageClick}
                checkboxText={
                  this.isAuthorizedToPostAndPutBilder() ? checkboxText : ""
                }
                handleCheckboxChange={
                  checkboxText && this.isAuthorizedToPostAndPutBilder()
                    ? this.handleCheckboxChange
                    : undefined
                }
                images={datoBilder.images}
                lightboxImages={datoSortedLightboxBilder[index]?.images}
                isAuthorizedForToastUi={this.isAuthorizedForToastUi()}
                getToastUiUrl={this.getToastUiUrl}
                getBildeUrl={this.getBildeUrl}
                isAuthorizedToDeleteImage={this.isAuthorizedToDeleteImage()}
              />
            </div>
          ))}
      </section>
    );
  }
}

export default BilderAndObservasjonsbilder;
