import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";

import FileInput from "../Common/Forms/FileInput";

const ForsinketMakuleringForm = ({
  forsinketMakuleringFileName = undefined,
  handleChange,
}) => {
  return (
    <form>
      <Row>
        <Col md={12}>
          <div style={{ display: "inline-block" }}>
            <FileInput
              name="forsinketMakuleringFile"
              buttonText="Legg til fil"
              handleFileInputChange={handleChange}
              invisibleFileInputProps={{
                accept: ".doc,.docx,application/msword",
              }}
            />
          </div>
          {forsinketMakuleringFileName && (
            <div style={{ display: "inline-block", paddingLeft: 10 }}>
              {forsinketMakuleringFileName}
            </div>
          )}
        </Col>
      </Row>
    </form>
  );
};

ForsinketMakuleringForm.propTypes = {
  forsinketMakuleringFile: PropTypes.shape({}),
  forsinketMakuleringFileName: PropTypes.string,
  validationError: PropTypes.object,
  handleChange: PropTypes.func,
  handleSlettForsinketMakuleringFile: PropTypes.func,
};

export default ForsinketMakuleringForm;
