import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";

import ModalGenericPostCancelContainer from "../../../Common/ModalGenericPostCancelContainer";

import { getIleggelse as getIleggelseAction } from "../../../../actions/ileggelserActions";
import { deleteIleggelseMakulering as deleteIleggelseMakuleringAction } from "../../../../actions/ileggelseMakuleringerActions";

import { getMostRecentObjectComparedOnParameter } from "../../../../utils/datetimeUtils";

class OpphevMakuleringContainer extends React.Component {
  static propTypes = {
    ileggelse: PropTypes.object,
    deleteIleggelseMakulering: PropTypes.func.isRequired,
    getIleggelse: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.handleApiCall = this.handleApiCall.bind(this);
  }

  async handleApiCall(ileggelseMakuleringId) {
    return this.props
      .deleteIleggelseMakulering(ileggelseMakuleringId)
      .then(() => {
        this.props.getIleggelse(this.props.ileggelse.id);
        return Promise.resolve();
      })
      .catch((error) => Promise.reject(error));
  }

  showOppehevMakulering = () => {
    if (!this.props.ileggelse.isMakulert) {
      return false;
    }
    if (!this.props.mostRecentMakulering) {
      return false;
    }
    if (
      this.props.ileggelse.id !== this.props.mostRecentMakulering.ileggelseId
    ) {
      return false;
    }
    return true;
  };

  render() {
    if (this.showOppehevMakulering()) {
      return (
        <ModalGenericPostCancelContainer
          id={this.props.mostRecentMakulering.id}
          submitButtonText="Opphev makulering"
          submittingButtonText="Opphever makulering..."
          successPostCallFlashMessage="Endring vellykket"
          errorPostCallFlashMessage="Endring feilet"
          handleApiCall={this.handleApiCall}
          modalTitle={`Opphev makulering for ileggelse  ${this.props.ileggelse.ileggelsesnummer}`}
          openModalComponentFunction={(props) => (
            <Button
              className="btn btn-bym-standard"
              style={{ marginLeft: 20 }}
              onClick={props.openModal}
            >
              Opphev makulering
            </Button>
          )}
        >
          <p>{`Vil du oppheve makulering av ileggelse ${this.props.ileggelse.ileggelsesnummer}?`}</p>
        </ModalGenericPostCancelContainer>
      );
    }
    return <span />;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ileggelse: state.ileggelse,
    mostRecentMakulering:
      state.ileggelseMakuleringer &&
      getMostRecentObjectComparedOnParameter(
        state.ileggelseMakuleringer,
        "makuleringDato"
      ),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    deleteIleggelseMakulering: (ileggelseMakuleringId) =>
      dispatch(deleteIleggelseMakuleringAction(ileggelseMakuleringId)),
    getIleggelse: (ileggelseId) => dispatch(getIleggelseAction(ileggelseId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OpphevMakuleringContainer);
