import React from "react";
import map from "lodash/map";
import fileIcon from "../../images/icon-file.png";
import imageIcon from "../../images/icon-image.png";
import includes from "lodash/includes";
import downloadIcon from "../../images/download-icon.png";
import { authorized } from "../../utils/authorizationUtils";
import { juridiskArray } from "../../constants/roles";
const extensions = [
  "PNG",
  "JPEG",
  "JPG",
  "SVG",
  "GIF",
  "DOC",
  "DOCX",
  "PDF",
  "XLS",
];

const renderFileFormatIcon = (file) => {
  if (file.format !== "" && includes(extensions, file.format.toUpperCase()))
    return imageIcon;
  return fileIcon;
};

const renderFileWithExtensions = (file) => {
  if (
    file.format !== "" &&
    file.navn !== "" &&
    !file.navn.toLowerCase().endsWith(file.format.toLowerCase())
  )
    return file.navn + "." + file.format.toLowerCase();
  return file.navn;
};

export const DokumentVedlegger = ({ dokument, download }) => {
  const isAuthorizedToDownload = authorized(juridiskArray);
  return (
    <div className="klageVedlegger">
      {map(dokument.filer, (i, index) => (
        <div key={index} className="vedleggerList">
          <table className="klageVedleggTable">
            <tbody>
              <tr>
                <td className="skiltIcon">
                  <img src={renderFileFormatIcon(i)} alt="folder" />
                </td>
                <td className="klageFileDiv">
                  <span className="klageFileName" role="button">
                    {renderFileWithExtensions(i)}
                  </span>
                  {/* <span className="klageFileDate">{i.opprettetDato}</span> */}
                </td>
                {isAuthorizedToDownload && (
                  <td>
                    <img
                      className="klagevedleggDownload"
                      src={downloadIcon}
                      onClick={(e) => download(e, dokument.dokumentNummer, i)}
                      alt="download"
                    />
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default DokumentVedlegger;
