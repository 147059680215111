import React from "react";
import PropTypes from "prop-types";

import "./Tooltip.css";

function Tooltip(props) {
  return (
    <span
      data-tooltip-position={props.tooltipPosition || "top"}
      data-tooltip={props.tooltip}
      tabIndex="0"
    >
      {React.Children.map(props.children, (child) => (
        <React.Fragment>{child}</React.Fragment>
      ))}
    </span>
  );
}

// Defining the PropTypes for the Tooltip component
Tooltip.propTypes = {
  children: PropTypes.any,
  tooltip: PropTypes.string,
  tooltipPosition: PropTypes.string,
};

// Exporting the Tooltip component as the default export
export default Tooltip;
