import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";
import TextInput from "../../../Common/Forms/TextInput";

export const EndreMiljokontrollModalForm = ({ payload, setPayload }) => {
  return (
    <form>
      <Row>
        <Col md={4}>
          <TextInput
            label="Gatenavn"
            value={payload.gatenavn}
            name="gatenavn"
            onChange={(event) =>
              setPayload({
                ...payload,
                [event.target.name]: event.target.value,
              })
            }
          />
        </Col>
        <Col md={2}>
          <TextInput
            label="Gatenummer"
            value={payload.gatenummer}
            name="gatenummer"
            onChange={(event) =>
              setPayload({
                ...payload,
                [event.target.name]: event.target.value,
              })
            }
          />
        </Col>
        <Col md={2}>
          <TextInput
            label="Oppgang"
            value={payload.oppgang}
            name="oppgang"
            onChange={(event) =>
              setPayload({
                ...payload,
                [event.target.name]: event.target.value,
              })
            }
          />
        </Col>
        <Col md={4}>
          <TextInput
            label="Bydel"
            value={payload.bydel}
            name="bydel"
            onChange={(event) =>
              setPayload({
                ...payload,
                [event.target.name]: event.target.value,
              })
            }
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <TextInput
            label="Ved gate"
            value={payload.vedGate}
            name="vedGate"
            onChange={(event) =>
              setPayload({
                ...payload,
                [event.target.name]: event.target.value,
              })
            }
          />
        </Col>
        <Col md={2}>
          <TextInput
            label="Ved gatenummer"
            value={payload.vedGatenummer}
            name="vedGatenummer"
            onChange={(event) =>
              setPayload({
                ...payload,
                [event.target.name]: event.target.value,
              })
            }
          />
        </Col>
        <Col md={2}>
          <TextInput
            label="Ved oppgang"
            value={payload.vedOppgang}
            name="vedOppgang"
            onChange={(event) =>
              setPayload({
                ...payload,
                [event.target.name]: event.target.value,
              })
            }
          />
        </Col>
      </Row>
    </form>
  );
};

EndreMiljokontrollModalForm.propTypes = {
  payload: PropTypes.object,
  setPayload: PropTypes.func.isRequired,
};

export default EndreMiljokontrollModalForm;
