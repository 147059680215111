import {
  handleChangeAllActionType,
  handleChangeToInitStateActionType
} from "../../actions/handleChangeAction";

export const INITIAL_STATE = {
  fraDato: "",
  tilDato: "",
  submitTimestamp: "",
  kjennemerke: ""
};

export default function ventilstillingerSubmittedFormReducer(
  state = {
    ...INITIAL_STATE
  },
  action
) {
  switch (action.type) {
    case handleChangeAllActionType("RAPPORT_VENTILSTILLING_SUBMITTED_FORM"):
      return { ...action.payload };
    case handleChangeToInitStateActionType(
      "RAPPORT_VENTILSTILLING_SUBMITTED_FORM"
    ):
      return {
        ...INITIAL_STATE
      };
    default:
      return state;
  }
}
