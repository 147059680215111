import * as ettersendelserActions from "../actions/ettersendelserActions";

const INITIAL_STATE = [];

export default function ettersendelserReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ettersendelserActions.GET_ETTERSENDELSER_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
