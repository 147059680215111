import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Checkbox from "../../Common/Forms/Checkbox";
import Table from "../../Common/SortableTableContainer";
import { HeaderCell } from "../../Common/Tables/index";
import { toNorwegianDatetime } from "../../../utils/datetimeUtils";
import sitePaths from "../../../constants/sitePaths";

import { ReactComponent as IconImageWhite } from "../../../images/icon-image-white.svg";
import { ReactComponent as IconImage } from "../../../images/icon-image.svg";

class List extends React.Component {
  static propTypes = {
    checkedMiljogebyrTilGodkjenningSkrivUt: PropTypes.object,
    miljogebyrTilBehandling: PropTypes.arrayOf(PropTypes.shape({})),
    PaginationControl: PropTypes.object.isRequired,
    overtredelseskoderMiljogebyr: PropTypes.arrayOf(PropTypes.object),
    handleSendTilSvarUtCheckboxChange: PropTypes.func.isRequired,
    getOvertredelseskoderMiljogebyr: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { getOvertredelseskoderMiljogebyr } = this.props;
    getOvertredelseskoderMiljogebyr();
  }

  renderRow = (miljogebyr) => {
    if (miljogebyr) {
      let bild =
        miljogebyr.antallBilder > 1 ? (
          miljogebyr.isMakulert ? (
            <IconImageWhite />
          ) : (
            <IconImage />
          )
        ) : (
          <span />
        );
      return (
        <tr
          key={`ileggelserTableBodyTr${miljogebyr.id}`}
          style={
            miljogebyr.isMakulert
              ? { backgroundColor: "#d32316", color: "white" }
              : {}
          }
        >
          <td>
            {miljogebyr.isTilEttersendelse &&
              !miljogebyr.isMakulert &&
              !miljogebyr.isBetalt &&
              miljogebyr.ettersendelse !== null && (
                <Checkbox
                  id={`checkbox${miljogebyr.id}MiljogebyrTilGodkjenningSkrivUt`}
                  name={miljogebyr.ettersendelse.id}
                  checkedValue={true}
                  uncheckedValue={false}
                  currentValue={
                    this.props.checkedMiljogebyrTilGodkjenningSkrivUt &&
                    this.props.checkedMiljogebyrTilGodkjenningSkrivUt[
                      miljogebyr.ettersendelse.id
                    ]
                  }
                  handleChange={this.props.handleSendTilSvarUtCheckboxChange}
                />
              )}
          </td>
          <td>
            {miljogebyr.isMakulert && (
              <Link
                to={`${sitePaths.miljogebyrer.tilBehandling.shortUrl}/${miljogebyr.id}`}
                style={{ textDecoration: "none" }}
              >
                <span className="link-makulert">
                  {miljogebyr.ileggelsesnummer}
                </span>
              </Link>
            )}
            {!miljogebyr.isMakulert && (
              <Link
                to={`${sitePaths.miljogebyrer.tilBehandling.shortUrl}/${miljogebyr.id}`}
                className="underline"
              >
                <span>{miljogebyr.ileggelsesnummer}</span>
              </Link>
            )}
          </td>
          <td>
            {miljogebyr.ileggelsesdatoTil &&
              toNorwegianDatetime(miljogebyr.ileggelsesdatoTil).format(
                "DD.MM.YYYY"
              )}
          </td>
          <td>
            {miljogebyr.ileggelsesdatoFra &&
              toNorwegianDatetime(miljogebyr.ileggelsesdatoFra).format("HH:mm")}
            {miljogebyr.ileggelsesdatoTil &&
              ` - ${toNorwegianDatetime(miljogebyr.ileggelsesdatoTil).format(
                "HH:mm"
              )}`}
          </td>
          <td>{miljogebyr.betjentNummer}</td>
          <td>
            {miljogebyr.paragrafer &&
              miljogebyr.paragrafer.map((p) => (
                <span key={p}>
                  {p}
                  <br />
                </span>
              ))}
          </td>
          <td>{miljogebyr.leveringstype}</td>
          <td>{miljogebyr.mottaker}</td>
          <td>{miljogebyr.gatenavn}</td>
          <td>
            {(miljogebyr.gatenummer || "") + " " + (miljogebyr.oppgang || "")}
          </td>
          <td>{miljogebyr.vedGate}</td>
          <td>{miljogebyr.vedGatenummer}</td>
          <td>{miljogebyr.gnr}</td>
          <td>{miljogebyr.bnr}</td>
          <td>{bild}</td>
        </tr>
      );
    }
  };

  render() {
    const { miljogebyrTilBehandling, PaginationControl } = this.props;
    return (
      <div style={{ paddingRight: 50, paddingLeft: 50 }}>
        <div className="ileggelse-list-page-content-container">
          <Table
            id="ileggelse-liste"
            storeKey="ileggelser"
            items={miljogebyrTilBehandling}
            renderRow={this.renderRow}
          >
            <HeaderCell>
              <Checkbox
                id="checkboxAllMiljogebyrTilGodkjenningSkrivUt"
                name="checkboxAllMiljogebyrTilGodkjenningSkrivUt"
                checkedValue={true}
                uncheckedValue={false}
                currentValue={
                  this.props.checkedMiljogebyrTilGodkjenningSkrivUt &&
                  this.props.checkedMiljogebyrTilGodkjenningSkrivUt
                    .checkboxAllMiljogebyrTilGodkjenningSkrivUt
                }
                handleChange={this.props.handleSendTilSvarUtCheckboxChange}
              />
            </HeaderCell>
            <HeaderCell>Ilegg.nr.</HeaderCell>
            <HeaderCell>Ilegg.dato</HeaderCell>
            <HeaderCell>Klokkeslett</HeaderCell>
            <HeaderCell>Tjenestenr.</HeaderCell>
            <HeaderCell>Overt.kode</HeaderCell>
            <HeaderCell>Leveringstype</HeaderCell>
            <HeaderCell>Mottaker</HeaderCell>
            <HeaderCell>Gatenavn</HeaderCell>
            <HeaderCell>Gatenr.</HeaderCell>
            <HeaderCell>Ved</HeaderCell>
            <HeaderCell>Ved nr.</HeaderCell>
            <HeaderCell>GNR</HeaderCell>
            <HeaderCell>BNR</HeaderCell>
            <HeaderCell>Bilde</HeaderCell>
          </Table>
        </div>
        <PaginationControl />
      </div>
    );
  }
}

export default List;
