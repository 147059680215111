import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "../../../../utils/useQuery";
import SpinLoader from "../../../Common/SpinLoader";
import { getSingleUbetalteIleggelser } from "../../../../actions/rapporter/ubetalteIleggelserActions";
import {
  formatDateTimeHavingDateTime,
  toNorwegianDatetime,
} from "../../../../utils/datetimeUtils";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import rapporterSitePaths from "../../../../constants/SitePaths/rapporterSitePaths";

const spinnerLoaderProps = {
  height: 16,
  width: 16,
  thickness: 3,
  pColor: "#4d4d4d",
  sColor: "#f2f8fc",
};

const UbetalteIleggelserDetaljFilterForm = ({
  kjoretoyId,
  ileggelseType,
  oppdatertTid,
  sort,
  setOppdatertTid,
  handleSetShowTableSpinner,
}) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const query = useQuery();
  const dispatch = useDispatch();

  const handleSetSpinners = (value) => {
    setShowSpinner(value);
    handleSetShowTableSpinner(value);
  };

  const handleOppdater = async () => {
    setOppdatertTid(new Date());
    handleSetSpinners(true);
    dispatch(
      getSingleUbetalteIleggelser({
        kjoretoyId: kjoretoyId,
        ileggelseType: query.get("ileggelseType"),
        sort: sort.cellKey,
        sortOrder: sort.orders,
      })
    )
      .then(() => {
        handleSetSpinners(false);
      })
      .catch(() => {
        handleSetSpinners(false);
      });
  };

  const ubetalteIleggelserSingleKjennemerke = useSelector(
    (state) => state.rapporter.ubetalteIleggelserSingleKjennemerke
  );

  const kjennemerke =
    ubetalteIleggelserSingleKjennemerke &&
    ubetalteIleggelserSingleKjennemerke?.length > 0
      ? ubetalteIleggelserSingleKjennemerke[0]?.kjennemerke
      : "Ukjent";

  return (
    <div className="filter-container">
      <div className="searchHistorikkOverskrift">
        Ubetalte ileggelser for kjennemerke {kjennemerke}
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ width: "30%", paddingTop: 10 }}>
          <Link
            to={{
              pathname: rapporterSitePaths.ubetalteIleggelser.oversikt,
              search: `?ileggelseType=${ileggelseType}`,
            }}
          >
            {`< Oversikt over ubetalte ileggelser`}
          </Link>
        </div>
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          <div style={{ paddingTop: 10, paddingRight: 16 }}>
            {`Sist oppdatert ${formatDateTimeHavingDateTime(
              toNorwegianDatetime(oppdatertTid)
            )}`}
          </div>
          <div>
            <Button
              className="btn btn-bym-medium btn-bym-action"
              onClick={handleOppdater}
            >
              <div style={{ display: "inline-block" }}>Oppdater</div>
              <div style={{ display: "inline-block", paddingLeft: 16 }}>
                {showSpinner && <SpinLoader {...spinnerLoaderProps} />}
              </div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UbetalteIleggelserDetaljFilterForm;
