import * as axiosUtils from "../utils/axiosUtils";
import {
  GET_VEDTAKSKODER_URL,
  POST_VEDTAKSKODER_URL,
  PUT_VEDTAKSKODER_URL,
  GET_SINGLE_VEDTAKSKODE_URL,
  ILEGGELSETYPE_TO_VEDTAK_URL,
} from "../constants/api";

export const actionType = "VEDTAKSKODER";
const metaData = {
  entitet: "Vedtakskode",
  notify: true,
};
export function getVedtakskoder() {
  return axiosUtils.GetAll(GET_VEDTAKSKODER_URL, actionType, "vedtakskoder");
}
export function getVedtakskodeById(id) {
  const url = GET_SINGLE_VEDTAKSKODE_URL(id);
  return axiosUtils.GetOne(url);
}

export function postVedtakskoder(vedtakkode) {
  return axiosUtils.Post(POST_VEDTAKSKODER_URL, vedtakkode, null, metaData);
}

export function putVedtakskoder(id, payload) {
  const url = PUT_VEDTAKSKODER_URL(id);
  return axiosUtils.Put(url, payload, null, metaData);
}

export function addVedtakskodeIleggelseType(vedtaksId, ileggelsetypeId) {
  const url = ILEGGELSETYPE_TO_VEDTAK_URL(vedtaksId, ileggelsetypeId);
  return axiosUtils.Post(url);
}

export function deleteVedtakskodeIleggelseType(vedtaksId, ileggelsetypeId) {
  const url = ILEGGELSETYPE_TO_VEDTAK_URL(vedtaksId, ileggelsetypeId);
  return axiosUtils.Delete(url);
}
