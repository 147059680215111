import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import KopiContainer from "../../Common/Ileggelser/Detalj/KopiContainer";
import PrintOut from "../../Common/PrintOut";
import MakulerModalContainer from "../../Common/MakulerModalContainer";
import ForsinketMakuleringContainer from "./ButtonPanelButtons/ForsinketMakuleringContainer";
import OpphevMakuleringContainer from "./ButtonPanelButtons/OpphevMakuleringContainer";
import OpphevForsinketMakuleringContainer from "./ButtonPanelButtons/OpphevForsinketMakuerlingContainer";
import SendTilIneContainer from "./ButtonPanelButtons/SendTilIneContainer";
import SendTilGodkjenningContainer from "./ButtonPanelButtons/SendTilGodkjenningContainer";
import NyEttersendelseContainer from "./ButtonPanelButtons/NyEttersendelseContainer";
import EndreIleggelseContainer from "./ButtonPanelButtons/EndreIleggelseContainer";

import OpenMakulerModalStandardButton from "../../Common/IleggelseClient/OpenMakulerModalStandardButton";

import { postIleggelserMakulering as postIleggelserMakuleringAction } from "../../../actions/ileggelseMakuleringerActions";
import {
  getIleggelse as getIleggelseAction,
  getIleggelseHistorikk as getIleggelseHistorikkAction,
} from "../../../actions/ileggelserActions";
import { getIleggelseMakuleringer as getIleggelseMakuleringerAction } from "../../../actions/ileggelseMakuleringerActions";

import { getMostRecentObjectComparedOnParameter } from "../../../utils/datetimeUtils";
import { authorized } from "../../../utils/authorizationUtils";
import { bypatruljen } from "../../../constants/roles";

class ButtonsContainer extends React.Component {
  static propTypes = {
    ileggelseId: PropTypes.string.isRequired,
    ileggelse: PropTypes.object,
    ileggelseEttersendelser: PropTypes.array,
    postIleggelserMakulering: PropTypes.func.isRequired,
    getIleggelse: PropTypes.func.isRequired,
    getIleggelseMakuleringer: PropTypes.func.isRequired,
  };

  authorizedSaksbehandlerAndSaksbehandleradmin = authorized([
    bypatruljen.saksbehandler,
    bypatruljen.saksbehandleradmin,
  ]);

  authorizedMakuler = this.authorizedSaksbehandlerAndSaksbehandleradmin;
  authorizedForsinketMakulering =
    this.authorizedSaksbehandlerAndSaksbehandleradmin;
  authorizedOppehevMakulering =
    this.authorizedSaksbehandlerAndSaksbehandleradmin;
  authorizedOpphevForsinketMakulering =
    this.authorizedSaksbehandlerAndSaksbehandleradmin;
  authorizedSendTilIne = this.authorizedSaksbehandlerAndSaksbehandleradmin;
  authorizedSendTilGodkjenning =
    this.authorizedSaksbehandlerAndSaksbehandleradmin;
  authorizedGodkjennSkrivUt = this.authorizedSaksbehandlerAndSaksbehandleradmin;
  authorizedNyEttersendelse = this.authorizedSaksbehandlerAndSaksbehandleradmin;
  authorizedAvbrytEttersendelse =
    this.authorizedSaksbehandlerAndSaksbehandleradmin;
  authorizedEndreIleggelse = this.authorizedSaksbehandlerAndSaksbehandleradmin;

  constructor(props) {
    super(props);
    this.state = {
      latestEttersendelse: null,
      ileggelseEttersendelser: null,
    };
    this.postIleggelserMakulering = this.postIleggelserMakulering.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.ileggelseEttersendelser.length > 0 &&
      JSON.stringify(nextProps.ileggelseEttersendelser) !==
        JSON.stringify(prevState.ileggelseEttersendelser)
    ) {
      let latestEttersendelse = getMostRecentObjectComparedOnParameter(
        nextProps.ileggelseEttersendelser,
        "ettersendelseDato"
      );
      return {
        latestEttersendelse,
        ileggelseEttersendelser: nextProps.ileggelseEttersendelser,
      };
    }
    return {
      ileggelseEttersendelser: nextProps.ileggelseEttersendelser,
    };
  }
  reloadHistorikk = async () => {
    await this.props.getIleggelseHistorikk(this.props.ileggelseId);
  };

  postIleggelserMakulering(ileggelseId, kommentar) {
    return this.props
      .postIleggelserMakulering(ileggelseId, kommentar)
      .then(() => {
        this.props.getIleggelse(ileggelseId);
        this.props.getIleggelseMakuleringer(ileggelseId);
        this.reloadHistorikk();
      })
      .catch(() => Promise.reject(true));
  }

  showMakuler = () =>
    !this.props.ileggelse.isMakulert &&
    !this.props.ileggelse.isBetalingsfristUtlopt &&
    this.props.ileggelse.forsinketMakuleringStatus &&
    !this.props.ileggelse.forsinketMakuleringStatus.createPossible &&
    !this.props.ileggelse.forsinketMakuleringStatus.deletePossible &&
    !this.props.ileggelse.isSendtKemner; // Skal stå isSendtKemner tils backend er endret til isSendtIne

  render() {
    return (
      <section
        className="detalj-ileggelse-section-margin no-print"
        style={{ height: 40 }}
      >
        {this.props.ileggelse &&
          this.props.ileggelse.id === this.props.ileggelseId && (
            <React.Fragment>
              <div className="pull-left">
                <KopiContainer />
                <PrintOut />
              </div>
              <div className="pull-right">
                {this.authorizedMakuler && this.showMakuler() && (
                  <MakulerModalContainer
                    id={this.props.ileggelse.id}
                    titleType="ileggelse"
                    ileggelsesnummer={this.props.ileggelse.ileggelsesnummer}
                    openMakulerModal={OpenMakulerModalStandardButton}
                    postMakulering={this.postIleggelserMakulering}
                  />
                )}
                {this.authorizedForsinketMakulering && (
                  <ForsinketMakuleringContainer
                    ileggelse={this.props.ileggelse}
                    reloadHistorikk={this.reloadHistorikk}
                  />
                )}
                {this.authorizedOppehevMakulering && (
                  <OpphevMakuleringContainer
                    reloadHistorikk={this.reloadHistorikk}
                  />
                )}
                {this.authorizedOpphevForsinketMakulering && (
                  <OpphevForsinketMakuleringContainer
                    ileggelse={this.props.ileggelse}
                    reloadHistorikk={this.reloadHistorikk}
                  />
                )}
                {this.authorizedSendTilIne && <SendTilIneContainer />}
                {this.authorizedSendTilGodkjenning && (
                  <SendTilGodkjenningContainer
                    latestEttersendelseId={
                      this.state.latestEttersendelse &&
                      this.state.latestEttersendelse.id
                    }
                    reloadHistorikk={this.reloadHistorikk}
                  />
                )}
                {this.authorizedNyEttersendelse && (
                  <NyEttersendelseContainer
                    reloadHistorikk={this.reloadHistorikk}
                  />
                )}
                {this.authorizedEndreIleggelse && <EndreIleggelseContainer />}
              </div>
            </React.Fragment>
          )}
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ileggelse: state.ileggelse,
    ileggelseEttersendelser: state.ileggelseEttersendelser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    postIleggelserMakulering: (ileggelseId, kommentar) =>
      dispatch(postIleggelserMakuleringAction(ileggelseId, kommentar)),
    getIleggelse: (ileggelseId) => dispatch(getIleggelseAction(ileggelseId)),
    getIleggelseMakuleringer: (ileggelseId) =>
      dispatch(getIleggelseMakuleringerAction(ileggelseId)),
    getIleggelseHistorikk: (ileggelseId) =>
      dispatch(getIleggelseHistorikkAction(ileggelseId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ButtonsContainer);
