import React, { Component } from "react";
import { PropTypes } from "prop-types";
import Table from "../Common/Tables/BaseTable";
import { map } from "lodash";
import { Link } from "react-router-dom";
import { toNorwegianDatetime } from "../../utils/datetimeUtils";

class InnsynIMiljogebyererList extends Component {
  static propTypes = {
    body: PropTypes.object,
  };

  joinOvertredelser = (i) => {
    return [i.overtredelse1, i.overtredelse2, i.overtredelse3]
      .filter(Boolean)
      .join(" / ");
  };
  render() {
    const header = [
      "Ilegg.nr.",
      "Ilegg.dato",
      "Klokkeslett",
      "Overt.kode",
      "Gatenavn",
      "Gatenr.",
    ];
    const miljogebyer = this.props.body ? this.props.body.items : [];
    return (
      <Table headerRow={header} defaultStyle>
        <tbody>
          {map(miljogebyer, (i, index) => {
            return (
              <tr key={index}>
                <td>
                  <Link to={i.id}>{i.ileggelsesnummer}</Link>
                </td>
                <td>
                  {i.ileggelsesdatoFra &&
                    toNorwegianDatetime(i.ileggelsesdatoFra).format(
                      "DD.MM.YYYY"
                    )}
                </td>
                <td>
                  {i.ileggelsesdatoFra &&
                    toNorwegianDatetime(i.ileggelsesdatoFra).format(
                      "HH:mm"
                    )}{" "}
                  {i.ileggelsesdatoTil &&
                    ` - ${toNorwegianDatetime(i.ileggelsesdatoTil).format(
                      "HH:mm"
                    )}`}
                </td>
                <td>{this.joinOvertredelser(i)}</td>
                <td>{i.gatenavn}</td>
                <td>{i.gatenummer}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }
}

export default InnsynIMiljogebyererList;
