// eslint-disable-next-line
import {
  handleChangeActionType,
  handleChangeAllActionType,
  handleChangeToInitStateActionType
} from "../../actions/handleChangeAction";
import _ from "lodash";

export const INITIAL_STATE = {
  fraDato: "",
  tilDato: "",
  selectedIleggelsestyper: [],
  selectedSort: "antallGebyrer_desc",
  fraDatoValidationErrorText: null,
  fraTidValidationErrorText: null,
  tilDatoValidationErrorText: null,
  tilTidValidationErrorText: null
};

export default function klageRapportFilterFormReducer(
  state = {
    ...INITIAL_STATE,
    validationError: { ...INITIAL_STATE.validationError }
  },
  action
) {
  switch (action.type) {
    case handleChangeActionType("KLAGERAPPORT_FILTER_FORM"):
      _.set(state, action.variableName, action.payload);
      return { ...state };
    case handleChangeAllActionType("KLAGERAPPORT_FILTER_FORM"):
      return { ...action.payload };
    case handleChangeToInitStateActionType("KLAGERAPPORT_FILTER_FORM"):
      return {
        ...INITIAL_STATE,
        validationError: { ...INITIAL_STATE.validationError }
      };
    default:
      return state;
  }
}
