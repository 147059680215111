import { connect } from "react-redux";
import InnsynMotorvognUtskrift from "./InnsynMotorvognUtskrift";
import {
  GET_SINGLE_MOTORVOGN_SUCCESS,
  getSingleMotorvognByQuery as getSingleMotorvognByQueryAction,
} from "../../actions/motorvognActions";

const mapStateToProps = (state) => ({ motorvogn: state.innsynMotorvogn });

const mapDispatchToProps = (dispatch) => ({
  getSingleMotorvognByQuery: (params) =>
    dispatch(
      getSingleMotorvognByQueryAction(params, GET_SINGLE_MOTORVOGN_SUCCESS)
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InnsynMotorvognUtskrift);
