import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Klageinformasjon from "../../Detalj/Klageinformasjon";

import { getMiljogebyrKlager } from "../../../../actions/miljogebyrerActions";

class KlageinformasjonContainer extends React.Component {
  static propTypes = {
    miljogebyrId: PropTypes.string,
    miljogebyrKlageinformasjon: PropTypes.arrayOf(PropTypes.object),
    getMiljogebyrKlageinformasjon: PropTypes.func,
  };

  componentDidMount() {
    if (this.props.miljogebyrId) {
      this.props.getMiljogebyrKlager(this.props.miljogebyrId);
    }
  }

  render() {
    const { miljogebyrKlager } = this.props;
    return <Klageinformasjon klager={miljogebyrKlager} />;
  }
}

const mapStateToProps = (state) => {
  return {
    miljogebyrKlager: state.miljogebyrKlager,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getMiljogebyrKlager }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KlageinformasjonContainer);
