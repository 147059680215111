import React, { Component } from "react";
import PropTypes from "prop-types";
import EditableTextInput from "../Common/Forms/EditableFieldJira";

export default class KlageVedtakNyRad extends Component {
  static propTypes = {
    nyVedtakIleggelsenummer: PropTypes.string,
    onSubmitNyVedtak: PropTypes.func,
    onRemoveNyVedtakRad: PropTypes.func,
  };
  render() {
    const { nyVedtakIleggelsenummer, onSubmitNyVedtak, onRemoveNyVedtakRad } =
      this.props;
    return (
      <tr key="nyklagevedtaRad">
        <td style={{ width: 250 }}>
          <div className="ileggelsesnummer label-14px">
            <EditableTextInput
              isNumber
              name="ileggelsesnummer"
              value={nyVedtakIleggelsenummer}
              placeholder="Ileggelsesnummer"
              onSubmit={onSubmitNyVedtak}
            />
          </div>
        </td>
        <td>-</td>
        <td>
          <div className="vetaksdato label-14px">-</div>
        </td>
        <td>
          <div className="gebyrdato label-14px">-</div>
        </td>
        <td>
          <div className="gebyrdato label-14px">-</div>
        </td>
        <td style={{ width: 200 }}>-</td>
        <td style={{ width: 200 }}>
          <div className="belop label-14px">-</div>
        </td>
        <td>
          <button
            className="btn btn-link red pull-right"
            onClick={onRemoveNyVedtakRad}
          >
            Slett rad
          </button>
        </td>
      </tr>
    );
  }
}
