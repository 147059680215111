import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";

import { HeaderCell } from "../../Common/Tables";
import Table from "../../Common/SortableTableContainer";
import Checkbox from "../../Common/Forms/Checkbox";
import EditableFieldJira from "../../Common/Forms/EditableFieldJiraForTable";
import MakulerModalContainer from "../../Common/MakulerModalContainer";
import OpenMakulerModal from "../../Common/IleggelseClient/OpenMakulerModal";

import { putIleggelseEttersendelser as putIleggelseEttersendelserActions } from "../../../actions/ileggelseEttersendelserActions";
import { postIleggelserMakulering as postIleggelserMakuleringAction } from "../../../actions/ileggelseMakuleringerActions";
import { getEttersendelserTilGodkjenning as getEttersendelserTilGodkjenningAction } from "../../../actions/ettersendelserTilGodkjenningActions";
import sitePaths from "../../../constants/sitePaths";

class ListContainer extends Component {
  static propTypes = {
    checkedEttersendelserTilGodkjenning: PropTypes.object,
    handleCheckboxChange: PropTypes.func.isRequired,
    putIleggelseEttersendelser: PropTypes.func.isRequired,
    postIleggelserMakulering: PropTypes.func.isRequired,
  };

  putIleggelseEttersendelserHandler(ettersendelseId, name, value) {
    let ileggelseWithEttersendelseId = this.props.ettersendelserTilGodkjenning
      .map((etg) => etg.ettersendelse)
      .filter((etg) => etg.id === ettersendelseId);
    // TODO throw when ileggelse is empty
    // TODO convert array to object
    let data = {
      navn: ileggelseWithEttersendelseId[0].navn,
      gate: ileggelseWithEttersendelseId[0].gate,
      postnummer: ileggelseWithEttersendelseId[0].postnummer,
      poststed: ileggelseWithEttersendelseId[0].poststed,
    };
    this.props
      .putIleggelseEttersendelser(ettersendelseId, {
        ...data,
        [name]: value,
      })
      .then((response) =>
        this.props.getEttersendelserTilGodkjenning(
          this.props.getEttersendelserTilGodkjenningFilter()
        )
      )
      .catch();
  }

  postIleggelserMakulering(ileggelseId, kommentar) {
    return this.props
      .postIleggelserMakulering(ileggelseId, kommentar)
      .then(() =>
        this.props.getEttersendelserTilGodkjenning(
          this.props.getEttersendelserTilGodkjenningFilter()
        )
      )
      .catch(() => Promise.reject(true));
  }

  renderRow(ettersendelseTilGodkjenning) {
    if (
      ettersendelseTilGodkjenning &&
      ettersendelseTilGodkjenning.ettersendelse
    ) {
      let ettersendelseId =
        ettersendelseTilGodkjenning &&
        ettersendelseTilGodkjenning.ettersendelse &&
        ettersendelseTilGodkjenning.ettersendelse.id;
      return (
        <tr
          key={`ettersendelserTilGodkjenningTableBodyTr${ettersendelseTilGodkjenning.id}`}
        >
          <td>
            <Checkbox
              id={`checkbox${ettersendelseId}EttersendelserTilGodkjenning`}
              name={ettersendelseId}
              checkedValue={true}
              uncheckedValue={false}
              currentValue={
                this.props.checkedEttersendelserTilGodkjenning[ettersendelseId]
              }
              handleChange={this.props.handleCheckboxChange}
            />
          </td>
          <td>
            <Link
              to={`${sitePaths.ileggelser.ettersendelserTilGodkjenning.shortUrl}/${ettersendelseTilGodkjenning.id}`}
              className="underline"
            >
              {ettersendelseTilGodkjenning.ileggelsesnummer}
            </Link>
          </td>
          <td>
            {ettersendelseTilGodkjenning.ileggelsesdatoTil &&
              moment(ettersendelseTilGodkjenning.ileggelsesdatoTil).format(
                "DD.MM.YYYY"
              )}
          </td>
          <td>{ettersendelseTilGodkjenning.kjennemerke}</td>
          <td>{ettersendelseTilGodkjenning.nasjon}</td>
          <td style={{ paddingTop: 5, paddingBottom: 3 }}>
            <EditableFieldJira
              inputId="navn"
              objectId={ettersendelseTilGodkjenning.ettersendelse.id}
              name="navn"
              value={
                (ettersendelseTilGodkjenning.ettersendelse &&
                  ettersendelseTilGodkjenning.ettersendelse.navn) ||
                ""
              }
              onSubmit={this.putIleggelseEttersendelserHandler.bind(this)}
            />
          </td>
          <td style={{ paddingTop: 5, paddingBottom: 3 }}>
            <EditableFieldJira
              inputId="gate"
              objectId={ettersendelseTilGodkjenning.ettersendelse.id}
              name="gate"
              value={
                (ettersendelseTilGodkjenning.ettersendelse &&
                  ettersendelseTilGodkjenning.ettersendelse.gate) ||
                ""
              }
              onSubmit={this.putIleggelseEttersendelserHandler.bind(this)}
            />
          </td>
          <td style={{ paddingTop: 5, paddingBottom: 3 }}>
            <EditableFieldJira
              inputId="postnummer"
              objectId={ettersendelseTilGodkjenning.ettersendelse.id}
              name="postnummer"
              value={
                (ettersendelseTilGodkjenning.ettersendelse &&
                  ettersendelseTilGodkjenning.ettersendelse.postnummer) ||
                ""
              }
              onSubmit={this.putIleggelseEttersendelserHandler.bind(this)}
            />
          </td>
          <td style={{ paddingTop: 5, paddingBottom: 3 }}>
            <EditableFieldJira
              inputId="poststed"
              objectId={ettersendelseTilGodkjenning.ettersendelse.id}
              name="poststed"
              value={
                (ettersendelseTilGodkjenning.ettersendelse &&
                  ettersendelseTilGodkjenning.ettersendelse.poststed) ||
                ""
              }
              onSubmit={this.putIleggelseEttersendelserHandler.bind(this)}
            />
          </td>
          <td>
            <MakulerModalContainer
              id={ettersendelseTilGodkjenning.id}
              ileggelsesnummer={ettersendelseTilGodkjenning.ileggelsesnummer}
              titleType="ileggelse"
              openMakulerModal={OpenMakulerModal}
              postMakulering={this.postIleggelserMakulering.bind(this)}
            />
          </td>
        </tr>
      );
    }
    return;
  }

  render() {
    const { ettersendelserTilGodkjenning } = this.props;
    return (
      <Table
        id="ettersendelseTilGodkjenning-liste"
        className="bym-ettersendelser-til-godkjenning-table"
        storeKey="ileggelser"
        items={ettersendelserTilGodkjenning}
        renderRow={this.renderRow.bind(this)}
      >
        <HeaderCell>
          <Checkbox
            id="checkboxAllEttersendelserTilGodkjenning"
            name="checkboxAllEttersendelserTilGodkjenning"
            checkedValue={true}
            uncheckedValue={false}
            currentValue={
              this.props.checkedEttersendelserTilGodkjenning
                .checkboxAllEttersendelserTilGodkjenning
            }
            handleChange={this.props.handleCheckboxChange}
          />
        </HeaderCell>
        <HeaderCell>Ilegg.nr.</HeaderCell>
        <HeaderCell>Ilegg.dato</HeaderCell>
        <HeaderCell>Kj.merke</HeaderCell>
        <HeaderCell>Nasjonalitet</HeaderCell>
        <HeaderCell>Navn</HeaderCell>
        <HeaderCell>Adresse</HeaderCell>
        <HeaderCell>Postnr.</HeaderCell>
        <HeaderCell>Poststed</HeaderCell>
        <HeaderCell />
      </Table>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ettersendelserTilGodkjenning:
      state.ettersendelserTilGodkjenning &&
      state.ettersendelserTilGodkjenning.items,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    putIleggelseEttersendelser: (ettersendelseId, data) =>
      dispatch(putIleggelseEttersendelserActions(ettersendelseId, data)),
    postIleggelserMakulering: (ileggelseId, kommentar) =>
      dispatch(postIleggelserMakuleringAction(ileggelseId, kommentar)),
    getEttersendelserTilGodkjenning: (params) =>
      dispatch(getEttersendelserTilGodkjenningAction(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListContainer);
