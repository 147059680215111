import axios from "axios";
import { GET_ILEGGELSE_SERVICE_VERSION_URL } from "../constants/api";

export const getVersion = () => {
  return async () =>
    axios
      .get(GET_ILEGGELSE_SERVICE_VERSION_URL)
      .then(response => response.data)
      .catch();
};
