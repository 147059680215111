import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";

import ModalGenericPostCancelContainer from "../../../Common/ModalGenericPostCancelContainer";
import EndreIleggelseModalForm from "./EndreIleggelseModalForm";

import {
  putKjoretoy,
  putBillett,
  putStedsbeskrivelse,
} from "../../../../actions/commonActions";
import {
  getIleggelse as getIleggelseAction,
  putIleggelser as putIleggelserAction,
  getIleggelseHistorikk as getIleggelseHistorikkAction,
} from "../../../../actions/ileggelserActions";

import { getIleggelseleveringstyper as getIleggelseleveringstyperAction } from "../../../../actions/ileggelseleveringstyperActions";
import { getOvertredelserkoderIleggelser as getOvertredelserkoderIleggelserAction } from "../../../../actions/overtredelseskoderIleggelserActions";
import { getInternkoderIleggelser as getInternkoderIleggelserAction } from "../../../../actions/internkoderIleggelserActions";
import {
  getIleggelseEttersendelser as getIleggelseEttersendelserAction,
  postIleggelseEttersendelser as postIleggelseEttersendelserAction,
} from "../../../../actions/ileggelseEttersendelserActions";
import {
  GET_ILEGGELSE_SINGLE_MOTORVOGN_SUCCESS,
  getSingleMotorvognByQuery as getSingleMotorvognByQueryAction,
} from "../../../../actions/motorvognActions";

import { getSelectOptionsEnhanced } from "../../../../selectors/selectOptionsSelector";
import { getOvertredelseskoderIleggelseAsDropDownListSearchFriendly } from "../../../../selectors/ileggelseSelector";

import { dateTimeFromTimePicker } from "../../../../utils/datetimeUtils";
import { stateModified } from "../../../../utils/stateUtils";

import {
  ettersendelsesrapportMinLength,
  ettersendelsesrapportMaxLength,
} from "../../../../constants/globals";
import { commentHasToBeBetween } from "../../../../constants/ErrorMessages";

class EndreIleggelseContainer extends React.Component {
  static propTypes = {
    ileggelse: PropTypes.object,
    getIleggelse: PropTypes.func.isRequired,
    putIleggelser: PropTypes.func.isRequired,
    getIleggelseleveringstyper: PropTypes.func.isRequired,
    getOvertredelserkoderIleggelser: PropTypes.func.isRequired,
    getInternkoderIleggelser: PropTypes.func.isRequired,
  };
  get initialState() {
    const { ileggelse } = this.props;
    return {
      leveringstype: ileggelse.leveringstype
        ? ileggelse.leveringstype.id
        : null,
      overtredelse1: ileggelse.overtredelse1
        ? ileggelse.overtredelse1.id
        : null,
      overtredelse2: ileggelse.overtredelse2
        ? ileggelse.overtredelse2.id
        : null,
      overtredelse3: ileggelse.overtredelse3
        ? ileggelse.overtredelse3.id
        : null,
      internkode1: ileggelse.internkode1 ? ileggelse.internkode1.id : null,
      internkode2: ileggelse.internkode2 ? ileggelse.internkode2.id : null,
      ettersendelsesrapport: null,
      showEttersendelsesrapportTextArea: false,
      ettersendelsesrapportValidationErrorText: null,
      kjennemerke: (ileggelse.kjoretoy && ileggelse.kjoretoy.kjennemerke) || "",
      kjoretoyType:
        (ileggelse.kjoretoy && ileggelse.kjoretoy.kjoretoyType) || "",
      fabrikkmerke:
        (ileggelse.kjoretoy && ileggelse.kjoretoy.fabrikkmerke) || "",
      annetFabrikkMerke:
        (ileggelse.kjoretoy && ileggelse.kjoretoy.modellType) || "-",
      gateNavn: ileggelse.gatenavn || "",
      gateNummer: ileggelse.gatenummer || "",
      oppgang: ileggelse.oppgang || "",
      bydel: ileggelse.bydel || "",
      vedGateNummer: ileggelse.vedGatenummer || "",
      vedGate: ileggelse.vedGate || "",
      antallMeter: ileggelse.antallMeter || "",
      belop: ileggelse.belop || "",
      skiltFraTid: ileggelse.skiltFraTid,
      skiltTilTid: ileggelse.skiltTilTid,
      billettNummer: ileggelse.billettNummer || "",
      internkommentar: ileggelse.internkommentar || "",
      kommentarTilMottaker: ileggelse.kommentar || "",
      kjoretoy: {},
      stedModified: false,
      ileggelseModified: false,
      ettersendelsesrapportModified: false,
      motorvognModified: false,
      billettModified: false,
      betaltKode: ileggelse && ileggelse.betaltKode,
      betaltDato: ileggelse && ileggelse.betaltDato,
      sendtTilKemnerDato: ileggelse && ileggelse.sendtTilKemnerDato,
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      ...this.initialState,
    };
  }

  rules = {
    belop: "required;isDigit",
  };

  componentDidMount() {
    this.props.getIleggelseleveringstyper();
    this.props.getOvertredelserkoderIleggelser();
    this.props.getInternkoderIleggelser();
  }

  componentDidUpdate(prevProps, prevState) {
    const { ileggelse } = this.props;
    const { showEttersendelsesrapportTextArea, leveringstype } = this.state;
    if (
      !showEttersendelsesrapportTextArea &&
      prevState.leveringstype !== leveringstype &&
      leveringstype === 3 &&
      ileggelse &&
      ileggelse.leveringstype &&
      ileggelse.leveringstype.id !== leveringstype
    ) {
      this.setState({ showEttersendelsesrapportTextArea: true });
    } else if (
      showEttersendelsesrapportTextArea &&
      ileggelse &&
      ileggelse.leveringstype &&
      ileggelse.leveringstype.id === leveringstype &&
      (prevProps.ileggelse.leveringstype.id !== ileggelse.leveringstype.id ||
        prevState.leveringstype !== leveringstype)
    ) {
      this.setState({
        showEttersendelsesrapportTextArea: false,
        ettersendelsesrapport: this.initialState.ettersendelsesrapport,
        ettersendelsesrapportValidationErrorText:
          this.initialState.ettersendelsesrapportValidationErrorText,
        ettersendelsesrapportModified:
          this.initialState.ettersendelsesrapportModified,
      });
    }
  }

  showEndreIleggelseModalForm = () => {
    return (
      this.state.leveringstype &&
      this.state.overtredelse1 &&
      this.props.leveringstyper &&
      this.props.overtredelseskoder &&
      this.props.internkoder
    );
  };

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
    this.areInputFieldsValidChange(name, value);
    this.checkStateIsModified(name, value);
  };

  areInputFieldsValidChange = (name, value) => {
    const { ettersendelsesrapportValidationErrorText } = this.state;

    if (
      name === "ettersendelsesrapport" &&
      ettersendelsesrapportValidationErrorText &&
      value &&
      value.length >= ettersendelsesrapportMinLength &&
      value.length <= ettersendelsesrapportMaxLength
    ) {
      this.setState({
        ettersendelsesrapportValidationErrorText:
          this.initialState.ettersendelsesrapport,
      });
    }
  };

  checkStateIsModified = (name, val) => {
    if (includes(this.stedsBeskrivelseProps(), name)) {
      stateModified(name, val, this.initialState, this.state, "stedModified");
    } else if (includes(this.ileggelseProps(), name)) {
      stateModified(
        name,
        val,
        this.initialState,
        this.state,
        "ileggelseModified"
      );
    } else if (
      name === "ettersendelsesrapport" &&
      this.initialState &&
      val !== this.initialState.ettersendelsesrapport
    ) {
      stateModified(
        name,
        val,
        this.initialState,
        this.state,
        "ettersendelsesrapportModified"
      );
    } else if (name === "billettNummer" && val && val !== "") {
      stateModified(
        name,
        val,
        this.initialState,
        this.state,
        "billettModified"
      );
    }
  };

  setPropsToInitialStates = () => this.setState({ ...this.initialState });

  ileggelseProps = () => {
    return [
      "skiltFraTid",
      "skiltTilTid",
      "antallMeter",
      "leveringstype",
      "overtredelse1",
      "overtredelse2",
      "overtredelse3",
      "internkode1",
      "internkode2",
      "internkode3",
      "kommentarTilMottaker",
      "internkommentar",
      "belop",
    ];
  };
  stedsBeskrivelseProps = () => {
    return [
      "bydel",
      "gateNavn",
      "gateNummer",
      "oppgang",
      "vedGate",
      "vedGateNummer",
    ];
  };

  generateKjoretoyPayload = (motorvogn) => {
    if (!motorvogn && !motorvogn.kjennemerke) return null;
    let payload = Object.assign({
      kjennemerke: motorvogn.kjennemerke,
      kjoretoyGruppe: motorvogn.typeBeskrivelse,
      modell: motorvogn.modell,
      fabrikkmerke: motorvogn.merke,
      modellType: motorvogn.typeVerdi,
      chassisNummer: motorvogn.understellsnummer,
      fargekode: motorvogn.fargeKode,
      farge: motorvogn.farge,
      totalvekt: motorvogn.tillattTotalvekt,
      euronorm: motorvogn.euroklasse,
      kjoretoyType: motorvogn.typeNavn,

      // Alltid Norge N hvis man finner et kjennemerke i motorvognskallet. Det er kun norske motorvogner.
      nasjon: "Norge",
      nasjonKode: "N",
    });
    this.setState({
      kjoretoy: payload,
      kjoretoyType: payload.kjoretoyType,
      fabrikkmerke: payload.fabrikkmerke,
      annetFabrikkMerke: payload.modellType,
    });
    return payload;
  };

  hentFraMotorvogn = () => {
    const { kjennemerke } = this.state;
    if (!kjennemerke || kjennemerke === "") return null;
    this.props.getSingleMotorvognByQuery({ kjennemerke }).then((response) => {
      if (response.data.result.kjennemerke) {
        this.generateKjoretoyPayload(response.data.result);
        this.setState({ motorvognModified: true });
      }
    });
  };

  handleApiCall = async (ileggelseId) => {
    if (!this.areInputFieldsValidSubmit()) {
      return Promise.reject("Valideringsfeil");
    }
    const { stedsBeskrivelseId, billettId, kjoretoy } = this.props.ileggelse;
    let {
      stedModified,
      ileggelseModified,
      ettersendelsesrapportModified,
      motorvognModified,
      billettModified,
    } = this.state;
    let skiltFraTid = this.state.skiltFraTid ? dateTimeFromTimePicker(this.state.skiltFraTid) : null;
    let skiltTilTid = this.state.skiltTilTid ? dateTimeFromTimePicker(this.state.skiltTilTid) : null;
    let putError = null;
    if (ileggelseModified && !putError) {
      let putIleggelserData = await this.props.putIleggelser(ileggelseId, {
        leveringstype: this.state.leveringstype,
        overtredelse1: this.state.overtredelse1,
        overtredelse2: this.state.overtredelse2,
        overtredelse3: this.state.overtredelse3,
        internkode1: this.state.internkode1,
        internkode2: this.state.internkode2,
        antallMeter: this.state.antallMeter,
        belop: this.state.belop && Number(this.state.belop),
        internkommentar: this.state.internkommentar,
        kommentarTilMottaker: this.state.kommentarTilMottaker,
        skiltFraTid,
        skiltTilTid,
      });
      putError = putIleggelserData.resolved ? putError : putIleggelserData.data;
    }
    if (ettersendelsesrapportModified && !putError) {
      await this.props
        .postIleggelseEttersendelser(
          this.props.ileggelse.id,
          this.state.ettersendelsesrapport
        )
        .then(() => {
          putError = null;
          this.props.getIleggelseEttersendelser(this.props.ileggelse.id);
        })
        .catch((error) => {
          putError = error;
        });
    }
    if (motorvognModified && !putError) {
      const kjoretoyState = this.state.kjoretoy;
      const kjoretoyId = kjoretoy && kjoretoy.id;
      if (!isEmpty(kjoretoyState)) {
        let putKjoretoyData = await this.props.putKjoretoy(kjoretoyId, {
          ...kjoretoyState,
          totalvekt:
            kjoretoyState.totalvekt && kjoretoyState.totalvekt.toString(),
        });
        putError = putKjoretoyData.resolved ? putError : putKjoretoyData.data;
      }
    }
    if (stedModified && !putError) {
      let putStedsbeskrivelseData = await this.props.putStedsbeskrivelse(
        stedsBeskrivelseId,
        this.state
      );
      putError = putStedsbeskrivelseData.resolved
        ? putError
        : putStedsbeskrivelseData.data;
    }
    if (billettModified && !putError) {
      let putBillettData = await this.props.putBillett(
        billettId,
        this.state.billettNummer
      );
      putError = putBillettData.resolved ? putError : putBillettData.data;
    }
    if (
      !putError &&
      (ileggelseModified ||
        ettersendelsesrapportModified ||
        motorvognModified ||
        stedModified ||
        billettModified)
    ) {
      this.props.getIleggelse(ileggelseId);
      await this.props.getIleggelseHistorikk(ileggelseId);
      this.setState({
        stedModified: false,
        ileggelseModified: false,
        ettersendelsesrapportModified: false,
        motorvognModified: false,
        billettModified: false,
      });
      return Promise.resolve(true);
    }
    return Promise.reject(putError);
  };

  areInputFieldsValidSubmit = () => {
    const { showEttersendelsesrapportTextArea, ettersendelsesrapport } =
      this.state;

    if (
      showEttersendelsesrapportTextArea &&
      (!ettersendelsesrapport ||
        ettersendelsesrapport.length < ettersendelsesrapportMinLength ||
        ettersendelsesrapport.length > ettersendelsesrapportMaxLength)
    ) {
      var ettersendelsesrapportValidationErrorText = commentHasToBeBetween(
        ettersendelsesrapportMinLength,
        ettersendelsesrapportMaxLength
      );
      this.setState(() => ({ ettersendelsesrapportValidationErrorText }));
      return false;
    }
    return true;
  };

  render() {
    if (
      !this.props.ileggelse.isMakulert &&
      this.props.ileggelse.forsinketMakuleringStatus &&
      !this.props.ileggelse.forsinketMakuleringStatus.deletePossible
    ) {
      return (
        <ModalGenericPostCancelContainer
          className="ileggelseDetailPutModal"
          id={this.props.ileggelse.id}
          modalTitle={`Endre ileggelse ${this.props.ileggelse.ileggelsesnummer}`}
          submitButtonText="Lagre endring"
          submittingButtonText="Lagrer endring..."
          successPostCallFlashMessage="Endring fullført"
          errorPostCallFlashMessage="Endring feilet"
          handleApiCall={this.handleApiCall}
          closeModal={this.setPropsToInitialStates}
          openModalComponentFunction={(props) => (
            <Button
              className="btn btn-bym-standard"
              style={{ marginLeft: 20 }}
              onClick={props.openModal}
            >
              Endre ileggelse
            </Button>
          )}
        >
          {" "}
          {this.showEndreIleggelseModalForm() && (
            <EndreIleggelseModalForm
              {...this.state}
              warningText={
                this.props.ileggelse.isSendtKemner // Skal stå isSendtKemner tils backend er endret til isSendtIne
                  ? "Denne ileggelsen er allerede sendt til INE"
                  : ""
              }
              showEttersendelsesrapportTextArea={
                this.state.showEttersendelsesrapportTextArea
              }
              ileggelse={this.props.ileggelse}
              ileggelseleveringstyper={this.props.leveringstyper}
              overtredelseskoder={this.props.overtredelseskoder}
              internkoder={this.props.internkoder}
              handleChange={this.handleChange}
              hentFraMotorvogn={this.hentFraMotorvogn}
            />
          )}
        </ModalGenericPostCancelContainer>
      );
    } else {
      return <span />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    ileggelse: state.ileggelse,
    leveringstyper: getSelectOptionsEnhanced(
      state,
      "ileggelseleveringstyper",
      "navn",
      "id"
    ),
    overtredelseskoder:
      getOvertredelseskoderIleggelseAsDropDownListSearchFriendly(state),
    internkoder: getSelectOptionsEnhanced(
      state,
      "internkoderIleggelser",
      "beskrivelse",
      "id"
    ),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getIleggelse: (ileggelseId) => dispatch(getIleggelseAction(ileggelseId)),
    putIleggelser: (ileggelseId, params) =>
      dispatch(putIleggelserAction(ileggelseId, params)),
    getIleggelseleveringstyper: () =>
      dispatch(getIleggelseleveringstyperAction()),
    getOvertredelserkoderIleggelser: () =>
      dispatch(getOvertredelserkoderIleggelserAction()),
    getInternkoderIleggelser: () => dispatch(getInternkoderIleggelserAction()),
    getSingleMotorvognByQuery: (params) =>
      dispatch(
        getSingleMotorvognByQueryAction(
          params,
          GET_ILEGGELSE_SINGLE_MOTORVOGN_SUCCESS
        )
      ),
    putKjoretoy: (id, params) => dispatch(putKjoretoy(id, params)),
    putBillett: (id, params) => dispatch(putBillett(id, params)),
    putStedsbeskrivelse: (id, params) =>
      dispatch(putStedsbeskrivelse(id, params)),
    getIleggelseEttersendelser: (ileggelseId) =>
      dispatch(getIleggelseEttersendelserAction(ileggelseId)),
    postIleggelseEttersendelser: (ileggelseId, ettersendelsesrapport) =>
      dispatch(
        postIleggelseEttersendelserAction(ileggelseId, {
          kommentar: ettersendelsesrapport,
        })
      ),
    getIleggelseHistorikk: (ileggelseId) => {
      dispatch(getIleggelseHistorikkAction(ileggelseId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EndreIleggelseContainer);
