import * as api from "../constants/api";
import * as axiosUtils from "../utils/axiosUtils";

export const actionType = "INTERNKODER_MILJOGEBYR";
const metaData = { entitet: "Internkode miljogebyr", notify: true };

export function getInternkoderMiljogebyr() {
  return axiosUtils.GetAll(api.GET_INTERNKODER_MILJOGEBYR_URL, actionType);
}

export function getSingleInternkoderMiljogebyr(id) {
  return axiosUtils.GetAll(
    api.GET_SINGLE_INTERNKODER_MILJOGEBYR_URL(id),
    actionType
  );
}

export function postInternkoderMiljogebyr(payload) {
  return axiosUtils.Post(
    api.POST_INTERNKODER_MILJOGEBYR_URL,
    payload,
    actionType,
    metaData
  );
}

export function putInternkoderMiljogebyr(id, payload) {
  return axiosUtils.Put(
    api.PUT_INTERNKODER_MILJOGEBYR_URL(id),
    payload,
    actionType,
    metaData
  );
}
