import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import RadiobuttonGroupV3 from "../../Common/Forms/RadioButtonGroupV3";

const Folgebrev = ({
  miljogebyrId,
  miljogebyr,
  folgebrev,
  getFolgebrev,
  getMiljogebyr,
  putMiljogebyrer,
  getMiljogebyrerHistorikk,
}) => {
  const [valgtFolgebrev, setValgtFolgebrev] = useState();
  const [
    valgtFolgebrevSetToInitStateOfMiljogebyrFolgebrevId,
    setValgtFolgebrevSetToInitStateOfMiljogebyrFolgebrevId,
  ] = useState(false);

  useEffect(() => {
    const fetchFolgebrev = async () => {
      await getFolgebrev({ onlyActive: true });
    };
    fetchFolgebrev();
  }, [getFolgebrev]);

  useEffect(() => {
    if (
      miljogebyrId === miljogebyr.id &&
      !valgtFolgebrevSetToInitStateOfMiljogebyrFolgebrevId
    ) {
      if (miljogebyr.folgebrevId === null) {
        setValgtFolgebrev(1);
      } else {
        setValgtFolgebrev(miljogebyr.folgebrevId);
      }
      setValgtFolgebrevSetToInitStateOfMiljogebyrFolgebrevId(true);
    }
  }, [
    miljogebyrId,
    miljogebyr.id,
    miljogebyr.folgebrevId,
    valgtFolgebrevSetToInitStateOfMiljogebyrFolgebrevId,
    setValgtFolgebrev,
    setValgtFolgebrevSetToInitStateOfMiljogebyrFolgebrevId,
  ]);

  const prevValgtFolgebrevRef = useRef();
  useEffect(() => {
    prevValgtFolgebrevRef.current = valgtFolgebrev;
  });
  const prevValgtFolgebrev = prevValgtFolgebrevRef.current;

  useEffect(() => {
    const updateMiljogebyrer = async (miljogebyrId, props) => {
      await putMiljogebyrer(miljogebyrId, props);
      getMiljogebyr(miljogebyrId);
      getMiljogebyrerHistorikk(miljogebyrId);
    };

    if (
      valgtFolgebrev &&
      valgtFolgebrev !== miljogebyr.folgebrevId &&
      valgtFolgebrev !== prevValgtFolgebrev &&
      !(valgtFolgebrev === 1 && prevValgtFolgebrev === undefined)
    ) {
      updateMiljogebyrer(miljogebyr.id, {
        mottaker: miljogebyr.mottaker,
        fodsnrOrOrgnr: miljogebyr.mottakerId,
        mottakertypeId: miljogebyr.mottakertype,
        mottakerGate: miljogebyr.mottakerGate,
        mottakerGateNummer: miljogebyr.mottakerGateNummer,
        mottakerPostnummer: miljogebyr.mottakerPostnummer,
        mottakerPoststed: miljogebyr.mottakerPostSted,
        gnr: miljogebyr.gnr,
        bnr: miljogebyr.bnr,
        leveringstype: miljogebyr.leveringstype && miljogebyr.leveringstype.id,
        overtredelse1: miljogebyr.overtredelse1 && miljogebyr.overtredelse1.id,
        overtredelse2: miljogebyr.overtredelse2
          ? miljogebyr.overtredelse2.id
          : null,
        overtredelse3: miljogebyr.overtredelse3
          ? miljogebyr.overtredelse3.id
          : null,
        internkode1: miljogebyr.internkode1 && miljogebyr.internkode1.id,
        internkode2: miljogebyr.internkode2 ? miljogebyr.internkode2.id : null,
        internkode3: miljogebyr.internkode3 ? miljogebyr.internkode3.id : null,
        kommentarTilMottaker: miljogebyr.kommentar,
        internkommentar: miljogebyr.internkommentar,
        belop: miljogebyr.belop,
        folgebrevId: valgtFolgebrev,
      });
    }
  }, [
    valgtFolgebrev,
    prevValgtFolgebrev,
    miljogebyr,
    getMiljogebyr,
    putMiljogebyrer,
    getMiljogebyrerHistorikk,
  ]);

  return (
    <section>
      <div className="detalj-ileggelse-header-wrapper">
        <h2>Følgebrev</h2>
      </div>
      <div style={{ paddingRight: 50, paddingLeft: 50 }}>
        <RadiobuttonGroupV3
          id="folgebrev"
          radioButtonGroupName="folgebrev"
          radioButtonValueName="id"
          radioButtonTextName="navn"
          items={folgebrev}
          onChange={(e) => setValgtFolgebrev(Number(e.target.value))}
          selectedValue={valgtFolgebrev}
        />
      </div>
    </section>
  );
};

Folgebrev.propTypes = {
  miljogebyrId: PropTypes.string.isRequired,
  miljogebyr: PropTypes.object,
  folgebrev: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, navn: PropTypes.string })
  ),
  getFolgebrev: PropTypes.func.isRequired,
  getMiljogebyr: PropTypes.func.isRequired,
  putMiljogebyrer: PropTypes.func.isRequired,
  getMiljogebyrerHistorikk: PropTypes.func.isRequired,
};

export default Folgebrev;
