import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { some } from "lodash";
import axios from "axios";

import FilterForm from "./FilterForm";

import { getMiljoleveringstyper as getMiljoleveringstyperAction } from "../../../actions/miljoleveringstyperActions";
import {
  handleChange as handleChangeAction,
  handleChangeToInitState as handleChangeToInitStateAction,
} from "../../../actions/handleChangeAction";
import { getMiljogebyrer as getMiljogebyrerAction } from "../../../actions/miljogebyrerActions";
import { getOvertredelseskoderMiljogebyr as getOvertredelseskoderMiljogebyrAction } from "../../../actions/overtredelseskoderMiljogebyrActions";
import { getVedtakskoder } from "../../../actions/vedtakskoderAction";
import { getKlageKoder } from "../../../actions/klagekoderAction";
import { getBybetjentGrupper as getBybetjentGrupperAction } from "../../../actions/bybetjentgrupperAction";

import { INITIAL_STATE as miljogebyrerOversiktFilterFormInitState } from "../../../reducers/forms/miljogebyrerOversiktFilterFormReducer";

import { getSelectOptionsEnhanced } from "../../../selectors/selectOptionsSelector";
import * as inputValidation from "../../../utils/inputValidationUtils";
import { handleFromToDateTime } from "../../../utils/fromToDateTimeUtils";
import { IsValidated } from "../../../utils/validationUtils";

import { miljogebyrStatuser } from "../../../constants/globals";
import {
  setPage as setPageAction,
  setSort as setSortAction,
} from "../../../actions/advancedListActions";
import { replaceWhitespacesWithEmptyStrings } from "../../../utils/sanitizationUtils";

import { authorized } from "../../../utils/authorizationUtils";
import { stedfortrederDriftsleder } from "../../../constants/roles";

class FilterContainer extends React.Component {
  static propTypes = {
    miljogebyrer: PropTypes.object,
    miljogebyrerOversiktFilterForm: PropTypes.object,
    overtredelseskoderMiljogebyr: PropTypes.array,
    // ileggelsestyper: PropTypes.array,
    miljoleveringstyper: PropTypes.array,
    getBybetjentGrupper: PropTypes.func,
    vedtakskoder: PropTypes.array,
    klagekoder: PropTypes.array,
    handleChange: PropTypes.func,
    getMiljogebyrer: PropTypes.func,
    getVedtakskoder: PropTypes.func,
    getKlageKoder: PropTypes.func,
    getOvertredelseskoderMiljogebyr: PropTypes.func,
    setPage: PropTypes.func.isRequired,
    setSort: PropTypes.func.isRequired,
  };

  state = {
    showSokSpinner: false,
  };
  axiosCancelTokenSource = null;

  componentDidMount() {
    this.props.getOvertredelseskoderMiljogebyr();
    this.props.getMiljoleveringstyper();
    //this.props.getBybetjentGrupper();  // dette krever også endring av tilganger i md-filen

    // for juridisk
    if (!this.authorizedstedfortrederDriftsleder) {
      this.props.getKlageKoder();
      this.props.getVedtakskoder();
    }
  }

  rules = {
    ileggelsesnummer: "isPositiveInteger",
    tjenestenummer: "isPositiveInteger",
  };

  authorizedstedfortrederDriftsleder = authorized([stedfortrederDriftsleder]);

  getPropsAsFakeState = () => {
    return {
      ileggelsesnummer:
        this.props.miljogebyrerOversiktFilterForm.ileggelsesnummer,
      tjenestenummer: this.props.miljogebyrerOversiktFilterForm.tjenestenummer,
      saksnummer: this.props.miljogebyrerOversiktFilterForm.saksnummer,
      validationError: {
        ileggelsesnummer:
          this.props.miljogebyrerOversiktFilterForm.validationError
            .ileggelsesnummer,
        tjenestenummer:
          this.props.miljogebyrerOversiktFilterForm.validationError
            .tjenestenummer,
        // saksnummer: this.props.miljogebyrerOversiktFilterForm.validationError
        //   .saksnummer
      },
    };
  };

  handleChange = (e) => {
    const { miljogebyrerOversiktFilterForm } = this.props;
    if (
      e.target.name === "fraDato" ||
      e.target.name === "tilDato" ||
      e.target.name === "fraTid" ||
      e.target.name === "tilTid"
    ) {
      handleFromToDateTime(
        e,
        miljogebyrerOversiktFilterForm,
        this.props.handleChange
      );
      return;
    }

    if (
      e.target.name === "fraIneOverforingsdato" &&
      typeof e.target.value === "object"
    ) {
      this.props.handleChange(
        "fraIneOverforingsdato",
        e.target.value.startOf("day")
      );
    }

    if (
      e.target.name === "tilIneOverforingsdato" &&
      typeof e.target.value === "object"
    ) {
      this.props.handleChange(
        "tilIneOverforingsdato",
        e.target.value.endOf("day")
      );
    }

    if (e.target.name === "selectedStatuser") {
      miljogebyrStatuser.forEach((miljogebyrStatus) => {
        if (some(e.target.value, miljogebyrStatus)) {
          this.props.handleChange(miljogebyrStatus.id, true);
        } else {
          this.props.handleChange(miljogebyrStatus.id, null);
        }
      });
    }

    this.props.handleChange(e.target.name, e.target.value);
  };

  handleBlur = (e) => {
    const { handleChange } = this.props;

    if (
      e.target.name === "ileggelsesnummer" ||
      e.target.name === "tjenestenummer" ||
      e.target.name === "saksnummer"
    ) {
      handleChange(
        e.target.name,
        replaceWhitespacesWithEmptyStrings(e.target.value)
      );
    }
  };

  handleChangeToInitState = () => {
    this.axiosCancelTokenSource && this.axiosCancelTokenSource.cancel();
    this.props.handleChangeToInitState();
  };

  isValid() {
    let propsAsFakeState = this.getPropsAsFakeState();
    const { isValid, validationError } = IsValidated(
      propsAsFakeState,
      this.rules
    );

    this.props.handleChange(
      "validationError.ileggelsesnummer",
      validationError.ileggelsesnummer
    );
    this.props.handleChange(
      "validationError.tjenestenummer",
      validationError.tjenestenummer
    );

    const fraDateTimeTilDateTimeValid =
      inputValidation.fraDateTimeTilDateTimeValidation(
        this.props.miljogebyrerOversiktFilterForm,
        this.props.handleChange
      );

    const fraOgTilIneOverforingsdatoValidationErrors =
      inputValidation.getDateTimeValidationError({
        fraDato:
          this.props.miljogebyrerOversiktFilterForm.fraIneOverforingsdato,
        tilDato:
          this.props.miljogebyrerOversiktFilterForm.tilIneOverforingsdato,
      });
    this.props.handleChange(
      "fraIneOverforingsdatoValidationErrorText",
      fraOgTilIneOverforingsdatoValidationErrors.fraDatoValidationErrorText
    );
    this.props.handleChange(
      "tilIneOverforingsdatoValidationErrorText",
      fraOgTilIneOverforingsdatoValidationErrors.tilDatoValidationErrorText
    );
    let fraOgTilOverforingsdatoIneValid = true;
    if (
      fraOgTilIneOverforingsdatoValidationErrors.fraDatoValidationErrorText ||
      fraOgTilIneOverforingsdatoValidationErrors.tilDatoValidationErrorText
    ) {
      fraOgTilOverforingsdatoIneValid = false;
    }

    return (
      isValid && fraDateTimeTilDateTimeValid && fraOgTilOverforingsdatoIneValid
    );
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { setPage, setSort } = this.props;
    if (this.isValid()) {
      this.setState({ showSokSpinner: true });
      this.axiosCancelTokenSource = axios.CancelToken.source();

      const page = 1;
      this.props
        .getMiljogebyrer(
          FilterContainer.getMiljogebyrerFilter(
            this.props.miljogebyrerOversiktFilterForm
          ),
          page,
          this.props.miljogebyrer.count,
          this.axiosCancelTokenSource.token
        )
        .then(() => {
          this.setState(() => ({ showSokSpinner: false }));
          setPage(page);
          setSort({ cellKey: "ileggelsesdatoTil", orders: "desc" });
        })
        .catch(() => this.setState(() => ({ showSokSpinner: false })));
    }
  };

  static getMiljogebyrerFilter(filterform) {
    let { selectedStatuser, ...filter } = filterform;

    filter = {
      ...filter,
      fraDato: filter.fraDato && filter.fraDato.toISOString(),
      tilDato: filter.tilDato && filter.tilDato.toISOString(),
    };

    miljogebyrStatuser.forEach(
      (status) =>
        (filter[status.id] = miljogebyrerOversiktFilterFormInitState[status])
    );
    selectedStatuser.forEach((selected) => (filter[selected.id] = true));
    delete filter.validationError;
    return filter;
  }

  componentWillUnmount() {
    this.axiosCancelTokenSource && this.axiosCancelTokenSource.cancel();
  }

  render() {
    const { showSokSpinner } = this.state;

    return (
      <div className="ileggelse-filter-container">
        <h1>Søk etter miljøgebyr</h1>
        <FilterForm
          showSokSpinner={showSokSpinner}
          miljogebyrFilterState={this.props.miljogebyrerOversiktFilterForm}
          overtredelseskoderMiljogebyr={this.props.overtredelseskoderMiljogebyr}
          miljogebyrStatuser={miljogebyrStatuser}
          miljoleveringstyper={this.props.miljoleveringstyper}
          betjentgrupper={this.props.betjentgrupper}
          vedtakskoder={this.props.vedtakskoder}
          klagekoder={this.props.klagekoder}
          handleChange={this.handleChange}
          handleBlur={this.handleBlur}
          handleChangeToInitState={this.handleChangeToInitState}
          handleSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    miljogebyrerOversiktFilterForm: state.forms.miljogebyrerOversiktFilterForm,
    overtredelseskoderMiljogebyr: state.overtredelseskoderMiljogebyr,
    miljoleveringstyper: getSelectOptionsEnhanced(
      state,
      "miljoleveringstyper",
      "navn",
      "id"
    ),
    betjentgrupper: getSelectOptionsEnhanced(
      state,
      "betjentgrupper",
      "navn",
      "id",
      {
        text: "Velg",
        value: "",
      }
    ),
    miljogebyrer: state.miljogebyrer,
    klagekoder: state.klagekoder,
    vedtakskoder: state.vedtakskoder,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMiljoleveringstyper: () => dispatch(getMiljoleveringstyperAction()),
    getBybetjentGrupper: () => dispatch(getBybetjentGrupperAction()),

    getOvertredelseskoderMiljogebyr: () =>
      dispatch(getOvertredelseskoderMiljogebyrAction()),
    handleChange: (variableName, value) =>
      dispatch(
        handleChangeAction(
          "MILJOGEBYRER_OVERSIKT_FILTER_FORM",
          variableName,
          value
        )
      ),

    handleChangeToInitState: () =>
      dispatch(
        handleChangeToInitStateAction("MILJOGEBYRER_OVERSIKT_FILTER_FORM")
      ),
    getMiljogebyrer: (miljogebyrerOversiktFilter, page, count, source) =>
      dispatch(
        getMiljogebyrerAction(miljogebyrerOversiktFilter, page, count, source)
      ),
    getVedtakskoder: () => dispatch(getVedtakskoder()),
    getKlageKoder: () => dispatch(getKlageKoder()),
    setPage: (page) => dispatch(setPageAction("miljogebyrer", page)),
    setSort: (sort) => dispatch(setSortAction("miljogebyrer", sort)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterContainer);
