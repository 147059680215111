import { connect } from "react-redux";

import ImageEditorPage from "../../Detalj/ImageEditorPage";

import {
  getIleggelseBildeBase64,
  postIleggelserBilder,
} from "../../../../actions/ileggelserActions";

const mapDispatchToProps = (dispatch, ownProps) => {
  const ileggelseId = ownProps.computedMatch.params.id;
  const bildeId = ownProps.computedMatch.params.bildeId;

  return {
    getBilde: () => dispatch(getIleggelseBildeBase64(ileggelseId, bildeId)),
    postIleggelserBilder: (unUsed, file) =>
      dispatch(postIleggelserBilder(ileggelseId, file, true, true)),
  };
};

export default connect(null, mapDispatchToProps)(ImageEditorPage);
