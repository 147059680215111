import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import CloseButton from "../../Common/CloseButton";
import PrintOut from "../../Common/PrintOut";
import VentilstillingsdetailBilderContainer from "./VentilstillingsdetailBilderContainer";

import { toNorwegianDatetime } from "../../../utils/datetimeUtils";

import sitePaths from "../../../constants/sitePaths";
import { useTilbakeknapp } from "../../Common/Detalj/DetaljPageHeader";

const ventilstillingsdetailId = "ventilstillingsdetail";

const style = {
  propName: {
    fontSize: 12,
    marginBottom: 8,
  },
  propValue: {
    fontSize: 16,
    marginTop: 8,
    marginBottom: 23,
  },
  closeButton: {
    marginTop: 20,
  },
  printButton: {
    marginTop: 13,
  },
};

const Ventilstillingsdetail = ({ ventilstilling }) => {
  const handleCloseDetails = useTilbakeknapp();

  let dato =
    ventilstilling.dato &&
    toNorwegianDatetime(ventilstilling.dato).format("DD.MM.YYYY HH:mm");

  let planlagtOppfolgingDato =
    ventilstilling.planlagtOppfolgingDato &&
    toNorwegianDatetime(ventilstilling.planlagtOppfolgingDato).format(
      "DD.MM.YYYY HH:mm"
    );

  let oppfolgingDato =
    ventilstilling.oppfolgningDato &&
    toNorwegianDatetime(ventilstilling.oppfolgningDato).format(
      "DD.MM.YYYY HH:mm"
    );

  return (
    <div style={{ padding: "20px 50px" }}>
      <Row>
        <Col md={6}>
          <h1>Ventilstillingskontoll</h1>
          <div
            className="label"
            style={{ fontSize: 16 }}
          >{`${dato} for kjennemerke ${ventilstilling.kjennemerke}`}</div>
        </Col>
        <Col md={6}>
          <div className="pull-right" style={style.closeButton}>
            <CloseButton
              text="Lukk Ventilstillingskontroll"
              customHandleClick={handleCloseDetails}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div style={style.printButton}>
            <PrintOut
              url={`${window.location.origin}${sitePaths.rapporter.ventilstillinger.shortUrl}/${ventilstilling.id}/utskrift`}
              dataToStoreInSessionStorage={ventilstilling.id}
            />
          </div>
        </Col>
      </Row>
      <div id={ventilstillingsdetailId}>
        <Row style={{ marginTop: 20 }}>
          <Col md={6}>
            <div
              className="fake-label border-bottom"
              style={{ fontSize: 16, fontWeight: "bold", width: "100%" }}
            >
              Generelt
            </div>
            <table style={{ width: "100%", marginTop: 20 }}>
              <tbody>
                <tr>
                  <td>
                    <div style={style.propName}>Dato</div>
                    <div style={style.propValue}>{dato}</div>
                  </td>
                  <td>
                    <div style={style.propName}>Status</div>
                    <div style={style.propValue}>{ventilstilling.status}</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style={style.propName}>Ileggelsesnummer</div>
                    <div style={style.propValue}>
                      <Link
                        to={sitePaths.rapporter.ventilstillinger.detalj.ileggelse.detalj.shortUrl.getUrl(
                          ventilstilling.id,
                          ventilstilling.ileggelseId
                        )}
                        style={{ textDecoration: "none" }}
                      >
                        <span>{ventilstilling.ileggelsesnummer}</span>
                      </Link>
                    </div>
                  </td>
                  <td>
                    <div style={style.propName}>Planlagt oppfølgning</div>
                    <div style={style.propValue}>{planlagtOppfolgingDato}</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style={style.propName}>Oppfølgning</div>
                    <div style={style.propValue}>{oppfolgingDato}</div>
                  </td>
                  <td>
                    <div style={style.propName}>Tjenestenummer</div>
                    <div style={style.propValue}>
                      {ventilstilling.tjenesteNummer}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col md={6}>
            <div
              className="fake-label border-bottom"
              style={{ fontSize: 16, fontWeight: "bold", width: "100%" }}
            >
              Adresse
            </div>
            <table style={{ width: "100%", marginTop: 20 }}>
              <tbody>
                <tr>
                  <td>
                    <div style={style.propName}>Gate</div>
                    <div style={style.propValue}>{ventilstilling.gatenavn}</div>
                  </td>
                  <td>
                    <div style={style.propName}>Husnummer</div>
                    <div style={style.propValue}>
                      {ventilstilling.gatenummer}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style={style.propName}>Ved nr.</div>
                    <div style={style.propValue}>
                      {ventilstilling.vedGatenummer}
                    </div>
                  </td>
                  <td>
                    <div style={style.propName}>Ved</div>
                    <div style={style.propValue}>{ventilstilling.vedGate}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Col md={6}>
            <div
              className="fake-label border-bottom"
              style={{ fontSize: 16, fontWeight: "bold", width: "100%" }}
            >
              Kjøretøy
            </div>
            <table style={{ width: "100%", marginTop: 20 }}>
              <tbody>
                <tr>
                  <td>
                    <div style={style.propName}>Kjennemerke</div>
                    <div style={style.propValue}>
                      {ventilstilling.kjennemerke}
                    </div>
                  </td>
                  <td>
                    <div style={style.propName}>Kjøretøytype</div>
                    <div style={style.propValue}>
                      {ventilstilling.kjoretoyType}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style={style.propName}>Fabrikkmerke</div>
                    <div style={style.propValue}>
                      {ventilstilling.fabrikkmerke}
                    </div>
                  </td>
                  <td>
                    <div style={style.propName}>Annen informasjon</div>
                    <div style={style.propValue}>
                      {ventilstilling.annetinformasjon}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col md={6}>
            <div
              className="fake-label border-bottom"
              style={{ fontSize: 16, fontWeight: "bold", width: "100%" }}
            >
              Ventilstillinger
            </div>
            <table style={{ width: "100%", marginTop: 20 }}>
              <tbody>
                <tr>
                  <td>
                    <div style={style.propName}>VF</div>
                    <div style={style.propValue}>{ventilstilling.ventilVf}</div>
                  </td>
                  <td>
                    <div style={style.propName}>HF</div>
                    <div style={style.propValue}>{ventilstilling.ventilHf}</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style={style.propName}>VB</div>
                    <div style={style.propValue}>{ventilstilling.ventilVb}</div>
                  </td>
                  <td>
                    <div style={style.propName}>HB</div>
                    <div style={style.propValue}>{ventilstilling.ventilHb}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </div>
      <VentilstillingsdetailBilderContainer
        ventilstillingId={ventilstilling && ventilstilling.id}
      />
    </div>
  );
};
Ventilstillingsdetail.propTypes = {
  ventilstilling: PropTypes.object,
};
export default Ventilstillingsdetail;
