import React from "react";
import PropTypes from "prop-types";
import Tooltip from "./Tooltip";
import { get } from "lodash";

const IleggelserInternkoder = ({ ileggelse, internkoder }) => (
  <React.Fragment>
    {ileggelse.internkode1 && (
      <Tooltip
        tooltip={
          internkoder &&
          get(
            internkoder.find((ok) => ok.id === ileggelse.internkode1),
            ["beskrivelse"]
          )
        }
      >
        {ileggelse.internkode1}
      </Tooltip>
    )}
    {ileggelse.internkode2 && (
      <React.Fragment>
        {"/"}
        <Tooltip
          tooltip={
            internkoder &&
            get(
              internkoder.find((ok) => ok.id === ileggelse.internkode2),
              ["beskrivelse"]
            )
          }
        >
          {ileggelse.internkode2}
        </Tooltip>
      </React.Fragment>
    )}

    {ileggelse.internkode3 && (
      <React.Fragment>
        {"/"}
        <Tooltip
          tooltip={
            internkoder &&
            get(
              internkoder.find((ok) => ok.id === ileggelse.internkode3),
              ["beskrivelse"]
            )
          }
        >
          {ileggelse.internkode3}
        </Tooltip>
      </React.Fragment>
    )}
  </React.Fragment>
);

IleggelserInternkoder.propTypes = {
  ileggelse: PropTypes.shape({}).isRequired,
  internkoder: PropTypes.arrayOf(PropTypes.shape({})),
};

export default IleggelserInternkoder;
