// import moment from "moment";
// eslint-disable-next-line
import {
  handleChangeActionType,
  handleChangeToInitStateActionType,
} from "../../actions/handleChangeAction";

const SKFTLOGG_FILTER_FORM = "SKIFTLOGG_FILTER_FORM";

export const INITIAL_STATE = {
  fraDato: "",
  tilDato: "",
  selectedTeams: [],
  selectedTjenestenummer: [],

  fraDatoValidationErrorText: null,
  tilDatoValidationErrorText: null,
};

export default function SkiftloggFilerFormReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case handleChangeActionType(SKFTLOGG_FILTER_FORM):
      return { ...state, [action.variableName]: action.payload };
    case handleChangeToInitStateActionType(SKFTLOGG_FILTER_FORM):
      return INITIAL_STATE;
    default:
      return state;
  }
}
