import { connect } from "react-redux";
import ListTotal from "../../../components/Miljogebyr/TilBehandling/ListTotal";

const mapStateToProps = state => {
  return {
    total: state.miljogebyrTilBehandling.total
  };
};

export default connect(mapStateToProps)(ListTotal);
