import React, { useState } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";

import Collapsible from "../Common/Collapsible";
import HeaderCell from "../Common/Tables/HeaderCell";
import TableCustomStyled from "../Common/Tables/TableCustomStyled";

import { formatDateTimeHavingNorwegianDateTime } from "../../utils/datetimeUtils";

const renderRow = (
  ileggelse,
  inkludFail,
  inkludSentIneDato,
  inkludBehandletIneDato
) => {
  if (!ileggelse) {
    return null;
  }
  const ileggelsesnummer = ileggelse.ileggelsesnummer
    ? ileggelse.ileggelsesnummer
    : "";
  const dato = ileggelse.dato
    ? formatDateTimeHavingNorwegianDateTime(ileggelse.dato)
    : "";
  const ileggelse_type = ileggelse.ileggelse_type
    ? ileggelse.ileggelse_type
    : "";
  const kjennemerke = ileggelse.kjennemerke ? ileggelse.kjennemerke : "";
  const nasjon = ileggelse.nasjon ? ileggelse.nasjon : "";
  const overtredelse = ileggelse.overtredelse ? ileggelse.overtredelse : "";
  const sent_to_ine = ileggelse.sent_to_ine
    ? formatDateTimeHavingNorwegianDateTime(ileggelse.sent_to_ine)
    : "";
  const received_from_ine = ileggelse.received_from_ine
    ? formatDateTimeHavingNorwegianDateTime(ileggelse.received_from_ine)
    : "";
  const status_beskrivelse = ileggelse.status_beskrivelse
    ? ileggelse.status_beskrivelse
    : "";

  return (
    <>
      <tr>
        <td>
          <Link to={`${window.location.pathname}/${ileggelse.id}`}>
            {ileggelsesnummer}
          </Link>
        </td>
        <td>{dato}</td>
        <td>{ileggelse_type}</td>
        <td>{kjennemerke}</td>
        <td>{nasjon}</td>
        <td>{overtredelse}</td>
        {inkludSentIneDato && <td>{sent_to_ine}</td>}
        {inkludBehandletIneDato && <td>{received_from_ine}</td>}
        {inkludFail && <td>{status_beskrivelse}</td>}
      </tr>
    </>
  );
};

const InnkrevingIleggelserData = ({
  id,
  title,
  headers,
  ileggelser,
  isLoading,
  inkludFail = false,
  inkludSentIneDato = false,
  inkludBehandletIneDato = false,
}) => {
  const [selectedSort, setSelectedSort] = useState({
    cellKey: "dato",
    orders: "desc",
  });

  const handleSort = (value) => {
    setSelectedSort(value);
  };

  const getSortedIleggelser = () => {
    const cellKey = selectedSort.cellKey;
    const orders = selectedSort.orders;
    return _.orderBy(ileggelser, [cellKey], [orders]);
  };

  return (
    <Collapsible id={id} key={id} title={title} isLoading={isLoading}>
      <div style={{ display: "block", overflowX: "auto", width: "100%" }}>
        <TableCustomStyled
          className="bym-table table"
          renderRow={(ileggelse) =>
            renderRow(
              ileggelse,
              inkludFail,
              inkludSentIneDato,
              inkludBehandletIneDato
            )
          }
          items={getSortedIleggelser()}
        >
          {headers &&
            Object.keys(headers).map((header) => {
              if (headers[header].cellKey) {
                return (
                  <HeaderCell
                    key={header + "headerForKlarTilInekjoring"}
                    cellKey={headers[header].cellKey}
                    sort={selectedSort}
                    onSort={handleSort}
                  >
                    {header}
                  </HeaderCell>
                );
              }
              return (
                <HeaderCell key={header + "Ileggelser"}>{header}</HeaderCell>
              );
            })}
        </TableCustomStyled>
      </div>
    </Collapsible>
  );
};

export default InnkrevingIleggelserData;
