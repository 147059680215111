import React, { Component } from "react";
import PropTypes from "prop-types";
import TextInput from "../Common/Forms/TextInput";
import FormButtonsForCloseAndSubmit from "../Common/Forms/FormButtonsForCloseAndSubmit";
import DropDown from "../Common/Forms/DropDown";
import DropDownListSearch from "../Common/Forms/DropdownListSearchable";
import DatetimePicker from "../Common/Forms/DatetimePickerEnhanced";

export default class IleggelserFilterForm extends Component {
  static propTypes = {
    showSokSpinner: PropTypes.bool,
    InnsynIleggelserFilterFormState: PropTypes.object,
    handleChange: PropTypes.func,
    onSubmit: PropTypes.func,
    handleChangeToInitState: PropTypes.func,
    ileggelsestyper: PropTypes.array,
  };

  getOvertredelseskoderIleggelserKode(id) {
    for (
      let index = 0;
      index < this.props.overtredelseskoderIleggelser.length;
      index++
    ) {
      const overtredelseskoder = this.props.overtredelseskoderIleggelser[index];
      if (id === overtredelseskoder.id) {
        return overtredelseskoder.beskrivelse;
      }
    }
  }

  render() {
    const {
      showSokSpinner,
      InnsynIleggelserFilterFormState,
      handleChange,
      handleBlur,
      onSubmit,
      handleChangeToInitState,
    } = this.props;
    return (
      <form className="SearchInnsynForm">
        <div className="SearchFilterOverskrift">Søk etter ileggelser</div>
        <div className="filter-flexbox-container innsyn-ileggelsesok-filter-flexbox-item">
          <TextInput
            id="ileggelsesnummer"
            name="ileggelsesnummer"
            label="Ileggelsesnummer"
            value={InnsynIleggelserFilterFormState.ileggelsesnummer}
            onBlur={handleBlur}
            onChange={handleChange}
            validationError={
              InnsynIleggelserFilterFormState.validationError.ileggelsesnummer
            }
          />
          <DatetimePicker
            id="fraDato"
            name="fraDato"
            locale="nb"
            label="Dato fra"
            placeholder="Velg"
            onChange={handleChange}
            value={InnsynIleggelserFilterFormState.fraDato}
            validationError={
              InnsynIleggelserFilterFormState.fraDatoValidationErrorText
            }
            timeFormat={false}
          />
          <DatetimePicker
            id="fraTid"
            name="fraTid"
            locale="nb"
            label="Tid fra"
            placeholder="Velg"
            onChange={handleChange}
            value={InnsynIleggelserFilterFormState.fraTid}
            validationError={
              InnsynIleggelserFilterFormState.fraTidValidationErrorText
            }
            dateFormat={false}
          />
          <DatetimePicker
            id="tilDato"
            name="tilDato"
            locale="nb"
            label="Dato til"
            placeholder="Velg"
            onChange={handleChange}
            value={InnsynIleggelserFilterFormState.tilDato}
            validationError={
              InnsynIleggelserFilterFormState.tilDatoValidationErrorText
            }
            timeFormat={false}
          />
          <DatetimePicker
            id="tilTid"
            name="tilTid"
            locale="nb"
            label="Tid til"
            placeholder="Velg"
            onChange={handleChange}
            value={InnsynIleggelserFilterFormState.tilTid}
            validationError={
              InnsynIleggelserFilterFormState.tilTidValidationErrorText
            }
            dateFormat={false}
          />
        </div>
        <div
          className="filter-flexbox-container innsyn-ileggelsesok-filter-flexbox-item"
          style={{ marginTop: 15 }}
        >
          <TextInput
            id="gatenavn"
            name="gatenavn"
            label="Gatenavn"
            value={InnsynIleggelserFilterFormState.gatenavn}
            onChange={handleChange}
          />
          <TextInput
            id="kjennemerke"
            name="kjennemerke"
            label="Kjennemerke"
            value={InnsynIleggelserFilterFormState.kjennemerke}
            onChange={handleChange}
          />
          <DropDownListSearch
            label="Overtredelseskode"
            name="overtredelse"
            title={
              this.props.InnsynIleggelserFilterFormState.overtredelse
                ? this.getOvertredelseskoderIleggelserKode(
                    this.props.InnsynIleggelserFilterFormState.overtredelse
                  )
                : "Velg"
            }
            placeholder="Søk etter kode"
            items={
              this.props.overtredelseskoderIleggelser &&
              [{ id: "", navn: "Velg", beskrivelse: "" }].concat(
                this.props.overtredelseskoderIleggelser.map((ok) => {
                  return {
                    id: ok.id,
                    navn: ok.id.toString(),
                    beskrivelse: ok.beskrivelse,
                  };
                })
              )
            }
            selected={this.props.InnsynIleggelserFilterFormState.overtredelse}
            handleChange={handleChange}
          />
          <DropDown
            id="type"
            name="type"
            label="Ileggelsestype"
            title="Velg"
            selected={InnsynIleggelserFilterFormState.type}
            items={this.props.ileggelsestyper}
            onChange={handleChange}
          />
        </div>
        <div style={{ marginTop: 5 }}>
          <FormButtonsForCloseAndSubmit
            showSubmitSpinner={showSokSpinner}
            submitText="Søk"
            onSubmit={onSubmit}
            onClose={handleChangeToInitState}
          />
        </div>
      </form>
    );
  }
}
