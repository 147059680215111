import { useEffect } from "react";
import { Form, FormGroup } from "react-bootstrap";

import PageAnonymous from "../Layout/PageAnonymous";

import { getUser } from "../../utils/userUtils";

import * as title from "../../constants/titles";

import "./Profile.css";


const ProfilePage = () => {
  useEffect(() => {
    document.title = title.profil;
  }, []);

  return (
    <PageAnonymous title="Din profil">
        <form>
          <h3>
            <div className="border-bottom">Personalia</div>
          </h3>
          <Form.Group>
            <Form.Label>E-postadresse</Form.Label>
            <Form.Control
              disabled={true}
              className="profile-input"
              type="email"
              value={getUser()?.profile?.email}
              placeholder=""
            />
          </Form.Group>
          <FormGroup>
            <Form.Label>Rolle</Form.Label>
            <Form.Control
              disabled={true}
              className="profile-input"
              type="text"
              value={getUser()?.profile?.params?.Roles}
              placeholder=""
            />
          </FormGroup>
          <div style={{ height: "70px" }} />
      </form>
    </PageAnonymous>
  );
}

export default ProfilePage;
