import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import PaginationControl from "./PaginationControl";

import {
  setPageCount as setPageCountAction,
  setPageCountWithConstantQueriesForApiCallObject as setPageCountWithConstantQueriesForApiCallObjectAction,
  setPageCountWithQueriesForApiReducerName as setPageCountWithQueriesForApiReducerNameAction,
  setPage as setPageAction,
  setPageWithConstantQueriesForApiCallObject as setPageWithConstantQueriesForApiCallObjectAction,
  setPageWithQueriesForApiReducerName as setPageWithQueriesForApiReducerNameAction,
} from "../../actions/advancedListActions";
import { getStateWithStringPath } from "../../utils/stateUtils";

export default function createPaginationControl(storeKey, apiObject) {
  const mapStateToProps = (state) => {
    const advList = getStateWithStringPath(state, storeKey);
    return {
      page: advList.page,
      count: advList.count,
      total: advList.total,
      sort: advList.sort,
    };
  };

  const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
      {
        setPageCountAction,
        setPageCountWithConstantQueriesForApiCallObjectAction,
        setPageCountWithQueriesForApiReducerNameAction,
        setPageWithConstantQueriesForApiCallObjectAction,
        setPageWithQueriesForApiReducerNameAction,
        setPageAction,
      },
      dispatch
    );
  };

  const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
      ...ownProps,
      ...stateProps,
      onChange: (value) => {
        const parameters =
          stateProps.sort && stateProps.sort.cellKey && stateProps.sort.orders
            ? [storeKey, apiObject, value, stateProps.sort]
            : [storeKey, apiObject, value];

        if (apiObject && apiObject.constantQueriesForApiCallObject) {
          return dispatchProps.setPageCountWithConstantQueriesForApiCallObjectAction(
            ...parameters
          );
        }
        if (apiObject && apiObject.queriesForApiReducerName) {
          return dispatchProps.setPageCountWithQueriesForApiReducerNameAction(
            ...parameters
          );
        }
        return dispatchProps.setPageCountAction(storeKey, value);
      },
      onNext: () => {
        const parameters =
          stateProps.sort && stateProps.sort.cellKey && stateProps.sort.orders
            ? [storeKey, apiObject, stateProps.page + 1, stateProps.sort]
            : [storeKey, apiObject, stateProps.page + 1];
        if (apiObject && apiObject.constantQueriesForApiCallObject) {
          return dispatchProps.setPageWithConstantQueriesForApiCallObjectAction(
            ...parameters
          );
        }
        if (apiObject && apiObject.queriesForApiReducerName) {
          return dispatchProps.setPageWithQueriesForApiReducerNameAction(
            ...parameters
          );
        }
        return dispatchProps.setPageAction(storeKey, stateProps.page + 1);
      },
      onPrevious: () => {
        const parameters =
          stateProps.sort && stateProps.sort.cellKey && stateProps.sort.orders
            ? [storeKey, apiObject, stateProps.page - 1, stateProps.sort]
            : [storeKey, apiObject, stateProps.page - 1];
        if (apiObject && apiObject.constantQueriesForApiCallObject) {
          return dispatchProps.setPageWithConstantQueriesForApiCallObjectAction(
            ...parameters
          );
        }
        if (apiObject && apiObject.queriesForApiReducerName) {
          return dispatchProps.setPageWithQueriesForApiReducerNameAction(
            ...parameters
          );
        }
        return dispatchProps.setPageAction(
          storeKey,
          apiObject,
          stateProps.page - 1
        );
      },
    };
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(PaginationControl);
}
