import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import SpinLoader from "../../SpinLoader";
import { getMiljokontrollBildeBase64 } from "../../../../actions/miljokontrollerActions";

const spinnerLoaderProps = {
  height: 42,
  width: 42,
  thickness: 3,
  pColor: "#ffffff",
  sColor: "#4d4d4d",
};

const TidskontrollOrginalbilde = (props) => {
  const [bilde, setBilde] = useState(undefined);
  const [showSpinner, setShowSpinner] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setShowSpinner(true);
    dispatch(
      getMiljokontrollBildeBase64(
        props.computedMatch.params.miljokontrollId,
        props.computedMatch.params.observasjonId,
        props.computedMatch.params.bildeId
      )
    )
      .then((result) => {
        setShowSpinner(false);
        setBilde(result);
      })
      .catch(() => setShowSpinner(false));
  }, [
    dispatch,
    props.computedMatch.params.miljokontrollId,
    props.computedMatch.params.observasjonId,
    props.computedMatch.params.bildeId,
  ]);

  return (
    <div>
      {showSpinner && (
        <div style={{ margin: "auto", paddingTop: 50, width: 0 }}>
          <SpinLoader {...spinnerLoaderProps} />
        </div>
      )}
      {!showSpinner && (
        <img src={bilde?.src} alt="Tidskontroll - orginalbilde" />
      )}
    </div>
  );
};

export default TidskontrollOrginalbilde;
