import React from "react";

export const SendTilIne = ({ putIleggelserSettKlarTilIne }) => (
  <button
    className="btn btn-bym-standard"
    style={{ marginLeft: 20 }}
    onClick={putIleggelserSettKlarTilIne}
  >
    Send til INE
  </button>
);

export default SendTilIne;
