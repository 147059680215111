import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import Table from "../../Common/SortableTableContainer";
import { HeaderCell } from "../../Common/Tables/index";
import { formatDateTimeHavingNorwegianDateTime } from "../../../utils/datetimeUtils";

import { getUregistrertekontroller as getUregistrertekontrollerAction } from "../../../actions/uregistrertekontrollerActions";
import createPaginationControl from "../../Common/createPaginationControl";

import { setSort as setSortAction } from "../../../actions/advancedListActions";

import sitePaths from "../../../constants/sitePaths";
import { useDispatch } from "react-redux";
import { INNTAUING_WEB_BASE_URL } from "../../../constants/api/config";

const renderStatus = (status, inntauingId) => {
  if (status === "Inntauet") {
    return (
      <a
        href={`${INNTAUING_WEB_BASE_URL}/alleinntauinger?sok=${inntauingId}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {status}
      </a>
    );
  }
  return status;
};

const renderRow = (uregistrertekontroll) => {
  if (uregistrertekontroll) {
    return (
      <tr
        key={`ileggelserTableBodyTr${uregistrertekontroll.id}`}
        style={
          uregistrertekontroll.deaktivertDato
            ? { backgroundColor: "#d32316", color: "white" }
            : {}
        }
      >
        <td>
          {formatDateTimeHavingNorwegianDateTime(
            uregistrertekontroll.registrert
          )}
        </td>
        <td>
          {formatDateTimeHavingNorwegianDateTime(
            uregistrertekontroll.borteDato ??
              uregistrertekontroll.inntauingDato ??
              uregistrertekontroll.lukketDato ??
              ""
          )}
        </td>
        <td>
          {renderStatus(
            uregistrertekontroll.status,
            uregistrertekontroll.giantLeapInntauingId
          )}
        </td>
        <td>
          <Link
            to={`${sitePaths.uregistrerte.kontroller.detalj.shortUrl.getUrl(
              uregistrertekontroll.id
            )}`}
            className="underline"
          >
            <span>{uregistrertekontroll.nummer}</span>
          </Link>
        </td>
        <td>{uregistrertekontroll.adresse}</td>
        <td>{uregistrertekontroll.fabrikkMerke}</td>
        <td>{uregistrertekontroll.kjoretoyType}</td>
        <td>{uregistrertekontroll.farge}</td>
        <td>{uregistrertekontroll.betjentnummer}</td>
      </tr>
    );
  }
};

const PaginationControl = createPaginationControl("uregistrertekontroller", {
  apiCallAction: getUregistrertekontrollerAction,
  queriesForApiReducerName: "forms.uregistrertekontrollerFilterForm",
});

const UregistrerteKontrollerList = ({
  uregistrertekontroller,
  uregistrertekontrollerFilterForm,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!uregistrertekontroller.sort) {
      dispatch(
        setSortAction("uregistrertekontroller", {
          cellKey: "registrert",
          orders: "asc",
        })
      );
    }
  }, [dispatch, uregistrertekontroller.sort]);

  const onSort = (sort) => {
    dispatch(
      getUregistrertekontrollerAction(
        {
          status: uregistrertekontrollerFilterForm.status,
          adresse: uregistrertekontrollerFilterForm.adresse,
          fraDato: uregistrertekontrollerFilterForm.fraDato,
          tilDato: uregistrertekontrollerFilterForm.tilDato,
          kjoretoyMerke: uregistrertekontrollerFilterForm.kjoretoyMerke,
          kjoretoyType: uregistrertekontrollerFilterForm.kjoretoyType,
        },
        1,
        uregistrertekontroller?.count,
        sort
      )
    );

    dispatch(setSortAction("uregistrertekontroller", sort));
  };

  const getHeaders = (sort) => {
    return (
      <>
        <HeaderCell cellKey="registrert" sort={sort} onSort={onSort}>
          Registrert
        </HeaderCell>
        <HeaderCell cellKey="oppdatert" sort={sort} onSort={onSort}>
          Oppdatert
        </HeaderCell>
        <HeaderCell>Status</HeaderCell>
        <HeaderCell>Id</HeaderCell>
        <HeaderCell cellKey="adresse" sort={sort} onSort={onSort}>
          Adresse
        </HeaderCell>
        <HeaderCell cellKey="fabrikkMerke" sort={sort} onSort={onSort}>
          Fabrikkmerke
        </HeaderCell>
        <HeaderCell cellKey="kjoretoyType" sort={sort} onSort={onSort}>
          Kjøretøystype
        </HeaderCell>
        <HeaderCell cellKey="farge" sort={sort} onSort={onSort}>
          Farge
        </HeaderCell>
        <HeaderCell cellKey="betjentnummer" sort={sort} onSort={onSort}>
          Tjenestenummer
        </HeaderCell>
      </>
    );
  };

  return (
    <div style={{ marginTop: 20, paddingRight: 50, paddingLeft: 50 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingBottom: 22,
          fontWeight: "bold",
          fontSize: 16,
        }}
      >
        {uregistrertekontroller?.total} uregistrerte kontroller
      </div>
      <div className="ileggelse-list-page-content-container">
        <Table
          id="ileggelse-liste"
          storeKey="ileggelser"
          items={uregistrertekontroller?.items ?? []}
          renderRow={renderRow}
        >
          {getHeaders(uregistrertekontroller?.sort)}
        </Table>
      </div>
      <PaginationControl sortKey="uregistrertekontroller" />
    </div>
  );
};

export default UregistrerteKontrollerList;
