import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import packageJson from "../../../package.json";
import getEnvironmentVariable from "../../constants/environments";

const VersionPage = (props) => {
  const [ileggelseServiceVersion, setIleggelseServiceVersion] = useState(null);

  const getVersion = props.getVersion;
  const [/*gitAuthor,*/ gitDate, gitMessage, gitBranch, gitCommit] = [
    // "REACT_APP_GIT_AUTHOR",
    "REACT_APP_GIT_DATE",
    "REACT_APP_GIT_MESSAGE",
    "REACT_APP_GIT_BRANCH",
    "REACT_APP_GIT_COMMIT_HASH",
  ].map((v) => {
    try {
      return getEnvironmentVariable(v);
    } catch {
      return "";
    }
  });

  useEffect(() => {
    async function fetchData() {
      let data = await getVersion();
      setIleggelseServiceVersion(data);
    }
    fetchData();
  }, [getVersion]);

  return (
    <div style={{ paddingTop: 20, paddingRight: 50, paddingLeft: 50 }}>
      <div>
        ileggelse-client:
        {/* Git author: {gitAuthor}<br/> */}
        <pre>
          Version: {packageJson.version}
          <br />
          Git date: {gitDate}
          <br />
          Git message: {gitMessage}
          <br />
          Git branch: {gitBranch}
          <br />
          Git commit: {gitCommit}
          <br />
        </pre>
      </div>
      <div>
        ileggelse-service:
        <br />
        <pre>{ileggelseServiceVersion}</pre>
      </div>
    </div>
  );
};

VersionPage.propTypes = {
  getVersion: PropTypes.func.isRequired,
};

export default VersionPage;
