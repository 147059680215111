import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import orderBy from "lodash/orderBy";

import TableCustomStyled from "../../Common/Tables/TableCustomStyled";
import HeaderCell from "../../Common/Tables/HeaderCell";
import AktorerModal from "./UpdateModals/AktorerModal";

import { getAktorer as getAktorerAction } from "../../../actions/aktorerActions";
import { getKjoretoyMerker as getKjoretoyMerkerAction } from "../../../actions/kjoretoyMerkerActions";
import { formatDateTimeHavingNorwegianDateTime } from "../../../utils/datetimeUtils";
import * as title from "../../../constants/titles";

const renderRow = (aktor, kjoretoyMerker, authorizedToCreateAndEdit) => (
  <tr key={`aktorerTableBodyTr${aktor.id.toString()}`}>
    <td>{aktor.kjoretoyMerke.merke}</td>
    <td>{aktor.kjoretoyMerke.kode}</td>
    <td>{aktor.orgNummer}</td>
    <td>{aktor.adresse1}</td>
    <td>{aktor.adresse2}</td>
    <td>{aktor.postnummer}</td>
    <td>{aktor.poststed}</td>
    <td>{formatDateTimeHavingNorwegianDateTime(aktor.aktivertDato)}</td>
    <td>{formatDateTimeHavingNorwegianDateTime(aktor.deaktivertDato)}</td>
    <td>
      {authorizedToCreateAndEdit && (
        <AktorerModal aktor={aktor} kjoretoyMerker={kjoretoyMerker} />
      )}
    </td>
  </tr>
);

const headers = {
  Kjøretøymerke: { cellKey: "kjoretoyMerke.merke" },
  "Kjøretøymerke kode": { cellKey: "kjoretoyMerke.kode" },
  Organisasjonsnummer: { cellKey: "orgNummer" },
  "Adresselinje 1": {},
  "Adresselinje 2": {},
  Postnummer: {},
  Poststed: {},
  "Aktivert Dato": {},
  "Deaktivert Dato": {},
  "": {},
};

const AktorerList = ({
  authorizedToCreateAndEdit,
  aktorer,
  kjoretoyMerker,
  getAktorer,
  getKjoretoyMerker,
}) => {
  const [selectedSort, setSelectedSort] = useState({
    cellKey: "kjoretoyMerke.merke",
    orders: "asc",
  });

  useEffect(() => {
    document.title = title.grunndataAktorer;
  }, []);

  useEffect(() => {
    getAktorer();
    getKjoretoyMerker();
  }, [getAktorer, getKjoretoyMerker]);

  const handleSort = (value) => {
    setSelectedSort(value);
  };

  const getSortedAktorer = () => {
    const cellKey = selectedSort.cellKey;
    const orders = selectedSort.orders;
    if (cellKey && orders && cellKey === "dato") {
      return orderBy(aktorer, [cellKey, "klokken"], [orders, orders]);
    }
    if (cellKey && orders && cellKey !== "dato") {
      return orderBy(aktorer, [cellKey], [orders]);
    }
    return aktorer;
  };

  return (
    <TableCustomStyled
      id="aktor-liste"
      className="bym-table ineCommonTable table table-wrapper-horizontal-scroll"
      renderRow={(aktorer) =>
        renderRow(aktorer, kjoretoyMerker, authorizedToCreateAndEdit)
      }
      items={getSortedAktorer()}
    >
      {headers &&
        Object.keys(headers).map((header) => {
          if (headers[header].cellKey) {
            return (
              <HeaderCell
                key={header + "HeaderAktorer"}
                cellKey={headers[header].cellKey}
                sort={selectedSort}
                onSort={handleSort}
              >
                {header}
              </HeaderCell>
            );
          }
          return <HeaderCell key={header + "HeaderAktor"}>{header}</HeaderCell>;
        })}
    </TableCustomStyled>
  );
};

const getFilteredAktorer = (aktorer, aktorerAktivStatus) => {
  if (aktorerAktivStatus) {
    return aktorer.filter((a) => a.isAktiv);
  } else if (aktorerAktivStatus === false) {
    return aktorer.filter((a) => !a.isAktiv);
  }
  return aktorer;
};

const mapStateToProps = (state, ownProps) => {
  return {
    authorizedToCreateAndEdit: ownProps.authorizedToCreateAndEdit,
    aktorer: getFilteredAktorer(
      state.aktorer,
      state.forms.grunndataFilterForm.aktorerAktivStatus
    ),
    kjoretoyMerker:
      state.kjoretoyMerker &&
      state.kjoretoyMerker.map((km) => ({ id: km.id, navn: km.merke })),
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAktorer: () => dispatch(getAktorerAction()),
  getKjoretoyMerker: () => dispatch(getKjoretoyMerkerAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AktorerList);
