import React, { useEffect } from "react";
import NavigationBar from "../../Common/NavigationBar";

import { uregistrerte as uregistrerteTitles } from "../../../constants/titles";
import UregistrerteKontrollerFilter from "./UregistrerteKontrollerFilter";
import UregistrerteKontrollerListContainer from "./UregistrerteKontrollerListContainer";

import { authorized } from "../../../utils/authorizationUtils";

import paths from "../../../constants/sitePaths";
import {
  bypatruljen,
  juridisk,
  stedfortrederDriftsleder,
} from "../../../constants/roles";

const UregistrerteKontroller = () => {
  useEffect(() => {
    document.title = uregistrerteTitles.kontroller;
  }, []);

  const getNavigationItems = () => {
    if (
      authorized([
        ...Object.values(bypatruljen),
        ...Object.values(juridisk),
        stedfortrederDriftsleder,
      ])
    ) {
      return [
        {
          navn: "Kontroller",
          path: paths.uregistrerte.kontroller.shortUrl,
        },
      ];
    }
  };

  return (
    <>
      <NavigationBar navigationItems={getNavigationItems()} />
      <UregistrerteKontrollerFilter />
      <UregistrerteKontrollerListContainer />
    </>
  );
};

export default UregistrerteKontroller;
