import axios from "../services/axios";
import * as api from "../constants/api";
import * as handle from "../utils/actionUtils";

export const GET_MILJOLEVERINGSTYPER_SUCCESS =
  "GET_MILJOLEVERINGSTYPER_SUCCESS";
export const GET_MILJOLEVERINGSTYPER_FAILURE =
  "GET_MILJOLEVERINGSTYPER_FAILURE";

export function getMiljoleveringstyper() {
  return async (dispatch, getState) => {
    return axios
      .get(api.GET_MILJOLEVERINGSTYPER_URL)
      .then(handle.success(dispatch, GET_MILJOLEVERINGSTYPER_SUCCESS))
      .catch(handle.error(dispatch, GET_MILJOLEVERINGSTYPER_FAILURE));
  };
}
