import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import "./NavigationBar.css";

class NavigationBar extends React.Component {
  static props = {
    navigationItems: PropTypes.arrayOf(PropTypes.string),
  };
  render() {
    return (
      <ul className="navigation-bar no-print" style={{ marginBottom: 0 }}>
        {this.props.navigationItems &&
          this.props.navigationItems.map((item, index) => {
            return (
              <li key={`navigationBarItem${index}`}>
                <Link
                  to={item.path}
                  className={
                    item.path === window.location.pathname
                      ? "active"
                      : undefined
                  }
                >
                  {item.navn}
                </Link>
              </li>
            );
          })}
      </ul>
    );
  }
}

export default NavigationBar;
