import { CreateNewReducer } from "../utils/reducerUtils";
import { KLAGE_MILJOKONTROLL_THUMBNAIL_IMAGE } from "../actions/klager/klageAction";

const INITIAL_STATE = [];

export const klageMiljoKontrollThumbnailImageReducer = (
  state = INITIAL_STATE,
  action
) => CreateNewReducer(state, action, KLAGE_MILJOKONTROLL_THUMBNAIL_IMAGE);

export default klageMiljoKontrollThumbnailImageReducer;
