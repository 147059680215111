import React, { useEffect, useState } from "react";
import NavigationBarContainer from "../../NavigationBarContainer";
import UbetalteIleggelserDetaljFilterForm from "./UbetalteIleggelserDetaljFilterForm";

import { rapporter } from "../../../../constants/titles";
import UbetalteIleggelserDetaljList from "./UbetalteIleggelserDetaljList";
import { useDispatch } from "react-redux";
import { useQuery } from "../../../../utils/useQuery";
import { getSingleUbetalteIleggelser } from "../../../../actions/rapporter/ubetalteIleggelserActions";

const UbetalteIleggelserDetaljPage = (props) => {
  const { computedMatch } = props;
  const [showTableSpinner, setShowTableSpinner] = useState(false);
  const [oppdatertTid, setOppdatertTid] = useState(new Date());
  const [sort, setSort] = useState({
    cellKey: "dato",
    orders: "desc",
  });
  const query = useQuery();
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = rapporter.ubetalteIleggelserDetalj;

    setShowTableSpinner(true);

    dispatch(
      getSingleUbetalteIleggelser({
        kjoretoyId: computedMatch?.params?.kjoretoyId,
        ileggelseType: query.get("ileggelseType"),
        sort: sort.cellKey,
        sortOrder: sort.orders,
      })
    )
      .then(() => {
        setShowTableSpinner(false);
      })
      .catch(() => {
        setShowTableSpinner(false);
      });
  }, []);

  const handleSetShowTableSpinner = (setShowTableSpinnerValue) => {
    setShowTableSpinner(setShowTableSpinnerValue);
  };

  return (
    <React.Fragment>
      <NavigationBarContainer />
      <UbetalteIleggelserDetaljFilterForm
        kjoretoyId={computedMatch?.params?.kjoretoyId}
        ileggelseType={query.get("ileggelseType")}
        oppdatertTid={oppdatertTid}
        sort={sort}
        setOppdatertTid={setOppdatertTid}
        handleSetShowTableSpinner={handleSetShowTableSpinner}
      />
      <div className="rapport-table">
        <UbetalteIleggelserDetaljList
          kjoretoyId={computedMatch?.params?.kjoretoyId}
          ileggelseType={query.get("ileggelseType")}
          sort={sort}
          setSort={setSort}
          showSpinner={showTableSpinner}
          setOppdatertTid={setOppdatertTid}
          handleSetShowTableSpinner={handleSetShowTableSpinner}
        />
      </div>
    </React.Fragment>
  );
};

export default UbetalteIleggelserDetaljPage;
