import { connect } from "react-redux";

import AArsrapportExcel from "./AArsrapportExcel";

const mapStateToProps = (state) => ({
  aarsrapport: state.rapporter.aarsrapport,
  aarsrapportSubmittedForm: state.submittedForms.aarsrapportSubmittedForm,
});

export default connect(mapStateToProps)(AArsrapportExcel);
