import React, { useEffect } from "react";
import PropTypes from "prop-types";
import TuiImageEditor from "tui-image-editor";
import "tui-image-editor/dist/tui-image-editor.css";
import "./ImageEditor.css";

const icona = require("tui-image-editor/dist/svg/icon-a.svg");
const iconb = require("tui-image-editor/dist/svg/icon-b.svg");
const iconc = require("tui-image-editor/dist/svg/icon-c.svg");
const icond = require("tui-image-editor/dist/svg/icon-d.svg");

const ImageEditor = (props) => {
  const { setImageEditorRef, usageStatistics, imageSrc } = props;
  const imageEditorRef = React.createRef();

  const style = {
    cornerSize: 20,
    rotatingPointOffset: 100,
  };

  useEffect(() => {
    let blackTheme = {
      "menu.normalIcon.path": icond,
      "menu.activeIcon.path": iconb,
      "menu.disabledIcon.path": icona,
      "menu.hoverIcon.path": iconc,
    };
    const selectionStyle = {
      cornerSize: style?.cornerSize,
      rotatingPointOffset: style?.rotatingPointOffset,
    };
    const imageEditorInstance = new TuiImageEditor(
      document.querySelector("#tui-image-editor"),
      {
        selectionStyle,
        usageStatistics,
        includeUI: {
          loadImage: {
            path: imageSrc.src,
            name: imageSrc.navn,
          },
          menu: ["crop", "draw", "shape", "icon", "text"],
          initMenu: "shape",
          uiSize: {
            width: "100%",
            height: "1000px",
          },
          menuBarPosition: "right",
          theme: blackTheme,
        },
      }
    );
    setImageEditorRef && setImageEditorRef(imageEditorInstance);
  }, [
    setImageEditorRef,
    usageStatistics,
    style.cornerSize,
    style.rotatingPointOffset,
    imageSrc.navn,
    imageSrc.src,
  ]);

  return <div id="tui-image-editor" ref={imageEditorRef} />;
};

ImageEditor.propTypes = {
  setImageEditorRef: PropTypes.func,
};

export default ImageEditor;
