import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

export default class OpenModalComponent extends React.Component {
  static propTypes = {
    openModal: PropTypes.func,
  };

  state = {
    innerWidth: window.innerWidth,
  };

  componentDidMount() {
    window.addEventListener("resize", this.resizeEventListener);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeEventListener);
  }

  resizeEventListener = () => {
    this.setState(() => ({
      innerWidth: window.innerWidth,
    }));
  };

  getButtonClassNames = () =>
    this.state.innerWidth < 700
      ? "btn-bym-standard"
      : "btn-bym-standard pull-right";

  getButtonStyle = () =>
    this.state.innerWidth < 700 ? {} : { paddingRight: 20 };

  render() {
    return (
      <Button
        className={this.getButtonClassNames()}
        style={this.getButtonStyle()}
        onClick={this.props.openModal}
      >
        <FontAwesomeIcon
          icon={faPlus}
          style={{ color: "#ffa500", paddingTop: 3, paddingRight: 10 }}
          size="lg"
          fixedWidth
        />
        Legg til ny
      </Button>
    );
  }
}
