import React from "react";
import { connect } from "react-redux";
import { getElsparksykkelGebyr } from "../../../actions/elsparksykkelGebyrActions";
import { handleChangeAll as handleChangeAllAction } from "../../../actions/handleChangeAction";
import * as title from "../../../constants/titles";
import {
    getELSIleggelseTableRows,
    getIleggelseQueryParameters
} from "../../../selectors/ileggelserSelector";
import Utskrift from "../Utskrift";

class UtskriftContainerELS extends React.Component {
  async componentDidMount() {
    const { handleChangeAll, getELSIleggelser } = this.props;
    document.title = title.ileggelse.ileggelsesoversikt;
    const sessionStoragePrintData = JSON.parse(
      window.sessionStorage.getItem("printData")
    );
    handleChangeAll(sessionStoragePrintData.queryData);
    await getELSIleggelser(
      sessionStoragePrintData.queryData,
      sessionStoragePrintData.paginationData.page,
      sessionStoragePrintData.paginationData.count
    );

    setTimeout(() => {
      window.print();
      window.close();
    }, 1000);
  }

  render() {
    const { ileggelseTableHeaders, ileggelseTableRows, queryParameters } =
      this.props;

    return (
      <Utskrift
        title="ELS - Ileggelsesoversikt"
        ileggelseTableHeaders={ileggelseTableHeaders}
        ileggelseTableRows={ileggelseTableRows}
        queryParameters={queryParameters}
      />
    );
  }

  componentWillUnmount() {
    window.sessionStorage.removeItem("printData");
  }
}

const mapStateToProps = (state) => {
  let ileggelseTableHeaders = [
    "Ilegg.nr.",
    "Ilegg.dato",
    "Klokkeslett",
    "Tjenestenr.",
    "Overt.kode",
    "Kj.merke",
    "Nasjon",
    "Gatenavn",
    "Gatenr.",
    "Bilde",
  ];
  
  return {
    ileggelseTableHeaders,
    ileggelseTableRows: getELSIleggelseTableRows(state),
    queryParameters: getIleggelseQueryParameters(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getELSIleggelser: (params, page, count) =>
      dispatch(getElsparksykkelGebyr(params, page, count)),
    handleChangeAll: (payload) =>
      dispatch(
        handleChangeAllAction("ELSPARKSYKKEL_GEBYR_FILTER_FORM", payload)
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UtskriftContainerELS);
