import axios from "../services/axios";
import * as api from "../constants/api";
import * as handle from "../utils/actionUtils";
import { setTotal as setTotalAction } from "./advancedListActions";

export const GET_ETTERSENDELSER_TIL_GODKJENNING_SUCCESS =
  "GET_ETTERSENDELSER_TIL_GODKJENNING_SUCCESS";
export const GET_ETTERSENDELSER_TIL_GODKJENNING_FAILURE =
  "GET_ETTERSENDELSER_TIL_GODKJENNING_FAILURE";

export function getEttersendelserTilGodkjenning(params) {
  return async (dispatch) => {
    return axios
      .get(api.GET_ILEGGELSER_URL, {
        params: { ...params, orderBy: "ascending" },
      })
      .then((response) => {
        dispatch(
          setTotalAction(
            "ettersendelsertilgodkjenning",
            JSON.parse(response.headers["x-pagination"]).Total
          )
        );
        dispatch({
          type: GET_ETTERSENDELSER_TIL_GODKJENNING_SUCCESS,
          payload: response.data.result,
        });
        return Promise.resolve(response.data.result);
      })
      .catch((error) => {
        handle.error(dispatch, GET_ETTERSENDELSER_TIL_GODKJENNING_FAILURE);
        return Promise.reject();
      });
  };
}
