import { createSelector } from "reselect";

const ettersendelserTilGodkjenningList = (state) => state.ettersendelserTilGodkjenning;

export const getEttersendelserTilGodkjenningList = createSelector(
  ettersendelserTilGodkjenningList,
  ettersendelserTilGodkjenningList => {
    return ettersendelserTilGodkjenningList.items;
  }
);

export default getEttersendelserTilGodkjenningList;