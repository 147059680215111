import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import map from "lodash/map";

import Table from "../../Common/Tables/BaseTable";
import KjoretoyTyperModal from "./UpdateModals/KjoretoyTyperModal";
import { getKjoretoyTyper } from "../../../actions/kjoretoyTyperActions";
import * as title from "../../../constants/titles";

class KjoretoyTyperList extends Component {
  static propTypes = {
    authorizedToCreateAndEdit: PropTypes.bool.isRequired,
    kjoretoyTyper: PropTypes.arrayOf(
      PropTypes.shape({
        kode: PropTypes.string,
        navn: PropTypes.string,
      })
    ),
  };

  constructor(props) {
    super(props);
    this.renderRow = this.renderRow.bind(this);
  }
  componentDidMount() {
    document.title = title.grunndataKjoretoyTyper;
    this.props.getKjoretoyTyper();
  }

  renderRow() {
    return map(this.props.kjoretoyTyper, (kjoretoyType) => (
      <tr key={`kjoretoyTyperTableBodyTr${kjoretoyType.id.toString()}`}>
        <td>{kjoretoyType.kode}</td>
        <td>{kjoretoyType.navn}</td>
        <td>
          {this.props.authorizedToCreateAndEdit && (
            <KjoretoyTyperModal kjoretoyType={kjoretoyType} />
          )}
        </td>
      </tr>
    ));
  }

  render() {
    const headerRow = ["Kode", "Navn", ""];
    return (
      <div className="table-wrapper-horizontal-scroll">
        <Table
          id="kjoretoyType-liste"
          headerRow={headerRow}
          defaultStyle
          striped
        >
          <tbody>{this.renderRow()}</tbody>
        </Table>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    kjoretoyTyper: state.kjoretoyTyper,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getKjoretoyTyper }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(KjoretoyTyperList);
