import React from "react";
import PropTypes from "prop-types";

import IleggelserAArsrapportList from "./AArsrapportList";
import MiljogebyrAArsrapportList from "./AArsrapportList";

const AArsrapportLists = ({ aarsrapport }) => (
  <div className="table-wrapper-horizontal-scroll" style={{ maxWidth: 750 }}>
    {aarsrapport.ileggelser && aarsrapport.ileggelser.length > 0 && (
      <>
        <IleggelserAArsrapportList
          heading="Ileggelser"
          aarsrapport={aarsrapport.ileggelser}
        />
      </>
    )}
    {aarsrapport.miljogebyr && aarsrapport.miljogebyr.length > 0 && (
      <>
        <MiljogebyrAArsrapportList
          heading="Miljøgebyr"
          aarsrapport={aarsrapport.miljogebyr}
        />
      </>
    )}
  </div>
);

AArsrapportLists.propTypes = {
  aarsrapport: PropTypes.shape({
    ileggelser: PropTypes.arrayOf(PropTypes.object),
    miljogebyr: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

export default AArsrapportLists;
