import React from "react";
import { Switch } from "react-router-dom";

import AuthorizedRoutes from "../components/Routes/AuthorizedRoutes";
import LinkerPage from "../components/Linker/LinkerPage";

import sitePath from "../constants/sitePaths";
import { bypatruljen, juridisk, innsyn } from "../constants/roles";

const Lenker = () => (
  <Switch>
    <AuthorizedRoutes
      exact
      path={sitePath.linker}
      roles={[
        innsyn.seksjonssjefVaktsentralen,
        innsyn.serviceavdelingVaktsentralen,
        innsyn.vaktsentralen,
        innsyn.serviceavdeling,
        ...Object.values(bypatruljen),
        ...Object.values(juridisk),
      ]}
      component={LinkerPage}
    />
  </Switch>
);

export default Lenker;
