import { GET_ILEGGELSE_MAKULERINGER_SUCCESS } from "../actions/ileggelseMakuleringerActions";

const INITIAL_STATE = [];

export default function ileggelseMakuleringerReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case GET_ILEGGELSE_MAKULERINGER_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
