import * as ileggelseRettelserActions from "../actions/ileggelseRettelserActions";

const INITIAL_STATE = [];

export default function ileggelseRettelserReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case ileggelseRettelserActions.GET_ILEGGELSE_RETTELSER_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
