import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Tabs from "../Common/Tabs";
import AddIleggelseModalContainer from "./AddIleggelseModalContainer";
import IleggeseDetailTabsContainer from "./IleggelseDetailTabsContainer";
import { authorized } from "../../utils/authorizationUtils";
import { juridiskArray } from "../../constants/roles";
import {
  getIleggelseDetailByNummer,
  deleteKlageIleggelse,
  getKlageIleggelser,
  getAllBilderInBase64,
  getAllMiljoKontrollBilderInBase64,
} from "../../actions/klager/klageAction";
import { getIleggelseRettelser } from "../../actions/ileggelseRettelserActions";
import { getMiljoRettelser } from "../../actions/miljoRettelserActions";
import { filter, map, first, size } from "lodash";

export class IleggelseNumberTabsContainer extends Component {
  static propTypes = {
    klageId: PropTypes.string,
    klagedetail: PropTypes.object,
    manglerInfo: PropTypes.bool,
  };

  state = {
    ileggelsetype: "",
    selectedTab: 0,
    ileggelsenummer: "",
    isThumbAndLightboxImg: true, // isThumbAndLightboxImg is true for all miljøgebyr with miljøkontroll. isThumbAndLightboxImg is false for all miljøgebyr created before the first miljøkontroll and for old (old = ca 08.04.2022) ileggelser
  };

  componentDidMount = async () => {
    const klageIleggelser = await this.props.getKlageIleggelser(
      this.props.klageId
    );
    await this.fetchFirstIleggelseDetailTab(klageIleggelser);
  };

  componentDidUpdate = (prevProps) => {
    const { klageIleggelse, getAllMiljoKontrollBilderInBase64 } = this.props;

    if (
      klageIleggelse.id &&
      prevProps.klageIleggelse.id !== klageIleggelse.id &&
      klageIleggelse.miljoKontrollId
    ) {
      getAllMiljoKontrollBilderInBase64(
        klageIleggelse.miljoKontrollId,
        "thumbnail"
      ).catch(() => {
        getAllMiljoKontrollBilderInBase64(klageIleggelse.miljoKontrollId);
      });
      getAllMiljoKontrollBilderInBase64(
        klageIleggelse.miljoKontrollId,
        "lightbox"
      );
    }
  };

  fetchKlageIleggelserAndVedtaker = async () => {
    await this.props.getKlageIleggelser(this.props.klageId);
    await this.props.getKlageVedtaker(this.props.klageId);
  };

  fetchFirstIleggelseDetailTab = async (klageIleggelser) => {
    const { klageIleggelse, getAllMiljoKontrollBilderInBase64 } = this.props;

    const ileggelseNumbers = this.filterIleggelsesnummers(klageIleggelser);
    if (ileggelseNumbers && size(ileggelseNumbers) > 0) {
      const firstIleggelsenummer = first(ileggelseNumbers);
      this.getIleggelseDetails(firstIleggelsenummer, klageIleggelser);
      this.setState({ selectedTab: 0 });
      this.getIleggelseTilbakeMeldinger(firstIleggelsenummer, klageIleggelser);
      this.getImages(firstIleggelsenummer, klageIleggelser);
    }

    if (klageIleggelse && klageIleggelse.miljoKontrollId) {
      getAllMiljoKontrollBilderInBase64(
        klageIleggelse.miljoKontrollId,
        "thumbnail"
      ).catch(() => {
        getAllMiljoKontrollBilderInBase64(klageIleggelse.miljoKontrollId);
      });
      getAllMiljoKontrollBilderInBase64(
        klageIleggelse.miljoKontrollId,
        "lightbox"
      );
    }
  };

  getIleggelseDetails = (ileggelsesnummer, klageIleggelser = undefined) => {
    if (ileggelsesnummer) {
      const ileggelsetype = this.getTypeOfIleggelse(
        ileggelsesnummer,
        klageIleggelser
      );
      this.props.getIleggelseDetailByNummer(ileggelsesnummer, ileggelsetype);
    }
  };

  getTypeOfIleggelse = (ileggelsesnummer, klageIleggelserParam = undefined) => {
    const { klageIleggelser } = this.props;
    const ileggelse = klageIleggelserParam
      ? first(
          filter(
            klageIleggelserParam,
            (i) => i.ileggelsesnummer === ileggelsesnummer
          )
        )
      : first(
          filter(
            klageIleggelser,
            (i) => i.ileggelsesnummer === ileggelsesnummer
          )
        );
    const type = ileggelse.ileggelsetype.kode;
    this.setState({ ileggelsetype: type });

    return type;
  };

  filterIleggelsesnummers = (klageIleggelserParam = undefined) => {
    if (klageIleggelserParam) {
      return map(klageIleggelserParam, "ileggelsesnummer");
    }

    const { klageIleggelser } = this.props;
    return map(klageIleggelser, "ileggelsesnummer");
  };

  getIleggelseTilbakeMeldinger = async (
    ileggelsesnummer,
    klageIleggelserParam = undefined
  ) => {
    const { klageIleggelser } = this.props;
    const klageIleggelserVal = klageIleggelserParam || klageIleggelser;
    const ileggelse = first(
      filter(klageIleggelserVal, (i) => i.ileggelsesnummer === ileggelsesnummer)
    );
    if (!ileggelse) return null;
    const ileggelseId = ileggelse.ileggelseId;
    const ileggelsetype = ileggelse.ileggelsetype.kode;
    this.setState({ ileggelsetype: ileggelsetype });
    ileggelsetype && ileggelsetype !== "M"
      ? this.props.getIleggelseRettelser(ileggelseId)
      : this.props.getMiljoRettelser(ileggelseId);
  };

  getImages = async (ileggelsesnummer, klageIleggelserParam = undefined) => {
    const { klageIleggelser } = this.props;
    const klageIleggelserVal = klageIleggelserParam || klageIleggelser;
    const ileggelse = first(
      filter(klageIleggelserVal, (i) => i.ileggelsesnummer === ileggelsesnummer)
    );
    if (!ileggelse) return null;
    const ileggelseId = ileggelse.ileggelseId;
    const ileggelsetype = ileggelse.ileggelsetype.kode;
    this.setState(() => ({ isThumbAndLightboxImg: true }));
    this.props
      .getAllBilderInBase64(ileggelseId, ileggelsetype, "thumbnail")
      .catch(() => {
        this.setState(() => ({ isThumbAndLightboxImg: false }));
        this.props.getAllBilderInBase64(ileggelseId, ileggelsetype);
      });
    this.props
      .getAllBilderInBase64(ileggelseId, ileggelsetype, "lightbox")
      .catch(() => {});
  };

  onAddIleggelse = async (ileggelsenummer) => {
    this.setState({ selectedTab: 0 });
    await this.getIleggelseTilbakeMeldinger(ileggelsenummer);
    await this.getImages(ileggelsenummer);
  };

  onClickTitle = (e, index, ileggelsesnummer) => {
    this.setState({ selectedTab: index, ileggelsesnummer: ileggelsesnummer });
    this.getIleggelseDetails(ileggelsesnummer);
    this.getIleggelseTilbakeMeldinger(ileggelsesnummer);
    this.getImages(ileggelsesnummer);
  };

  onRemveKlageIleggelse = async (e, ileggelsesnummer) => {
    const { klageId, klageIleggelser } = this.props;
    const ileggelse = first(
      filter(klageIleggelser, (i) => i.ileggelsesnummer === ileggelsesnummer)
    );
    await this.props.deleteKlageIleggelse(klageId, ileggelse.ileggelseId);
    await this.props
      .getKlageIleggelser(this.props.klageId)
      .then((klageIleggelser) => {
        this.fetchFirstIleggelseDetailTab(klageIleggelser);
      });
  };

  render() {
    const { manglerInfo } = this.props;
    const { isThumbAndLightboxImg, selectedTab, ileggelsetype } = this.state;
    const ileggelseNummerList = this.filterIleggelsesnummers();
    const onlyForJurisk = authorized(juridiskArray);

    return (
      <div className="klageIleggelserTabs">
        {onlyForJurisk && !manglerInfo && (
          <AddIleggelseModalContainer
            onAddIleggelse={this.onAddIleggelse}
            manglerInfo={manglerInfo}
          />
        )}
        <Tabs
          titles={ileggelseNummerList}
          titleHasCloseIcon
          onCloseIconClick={this.onRemveKlageIleggelse}
          onClickTitle={this.onClickTitle}
          selectedTab={selectedTab}
          authorizedToRemove={onlyForJurisk}
        >
          {ileggelseNummerList.length > 0 &&
            map(ileggelseNummerList, (i) => (
              <IleggeseDetailTabsContainer
                klageIleggelse={this.props.klageIleggelse}
                key={i}
                ileggelsesType={ileggelsetype}
                isThumbAndLightboxImg={isThumbAndLightboxImg}
              />
            ))}
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  klageId:
    state.klageDetail && state.klageDetail.id ? state.klageDetail.id : "",
  klageIleggelse: state.klageIleggelse,
  klageIleggelser: state.klageIleggelser,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getIleggelseDetailByNummer,
      deleteKlageIleggelse,
      getKlageIleggelser,
      getIleggelseRettelser,
      getMiljoRettelser,
      getAllBilderInBase64,
      getAllMiljoKontrollBilderInBase64,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IleggelseNumberTabsContainer);
