import { createSelector } from "reselect";
import _ from "lodash";
import { toNorwegianDatetime } from "../utils/datetimeUtils";

import { INITIAL_STATE as initMiljogebyrQueryParameters } from "../reducers/forms/miljogebyrerOversiktFilterFormReducer";
import {
  makulertStatus,
  miljogebyrStatuserDictionary,
} from "../constants/globals";

const miljogebyrList = (state) => state.miljogebyrer;

export const getMiljogebyrList = createSelector(
  miljogebyrList,
  (miljogebyrList) => {
    return miljogebyrList.items;
  }
);

const miljogebyrerItems = (state) =>
  state.miljogebyrer && state.miljogebyrer.items;

const overtredelseskoderMiljogebyr = (state) =>
  state.overtredelseskoderMiljogebyr;

const getOvertredelseParagrafer = (
  overtredelser,
  overtredelseskoderMiljogebyr
) => {
  const paragrafer = [];
  overtredelser.forEach((overtredelse) => {
    if (overtredelse) {
      const overtredelsesKode = _.find(
        overtredelseskoderMiljogebyr,
        (overtredelsesKode) => overtredelsesKode.id === overtredelse
      );
      if (overtredelsesKode) {
        paragrafer.push(overtredelsesKode.paragraf);
      }
    }
  });
  return paragrafer;
};

const showImageIcon = (ileggelse) => {
  if (ileggelse.bilder && ileggelse.bilder.length > 1) return "ShowImageIcon";
  return "";
};

export const getMiljogebyrTableRows = createSelector(
  [miljogebyrerItems, overtredelseskoderMiljogebyr],
  (miljogebyrerItems, overtredelseskoderMiljogebyr) => {
    return miljogebyrerItems.map((miljogebyr) => {
      const ileggelsesdatoTil =
        miljogebyr.ileggelsesdatoTil &&
        toNorwegianDatetime(miljogebyr.ileggelsesdatoTil).format("DD.MM.YYYY");
      const ileggelsesdatoFraTid =
        miljogebyr.ileggelsesdatoFra &&
        toNorwegianDatetime(miljogebyr.ileggelsesdatoFra).format("HH:mm");
      const ileggelsesdatoTilTid =
        miljogebyr.ileggelsesdatoTil &&
        ` - ${toNorwegianDatetime(miljogebyr.ileggelsesdatoTil).format(
          "HH:mm"
        )}`;
      const klokkeslett = ileggelsesdatoFraTid + ileggelsesdatoTilTid;

      let data = [
        miljogebyr?.ileggelsesnummer.toString(),
        ileggelsesdatoTil,
        klokkeslett,
        miljogebyr?.betjentNummer.toString(),
        getOvertredelseParagrafer(
          [
            miljogebyr.overtredelse1,
            miljogebyr.overtredelse2,
            miljogebyr.overtredelse3,
          ],
          overtredelseskoderMiljogebyr
        ).toString(),
        miljogebyr.mottaker,
        miljogebyr.gatenavn,
        (miljogebyr.gatenummer || "") + " " + (miljogebyr.oppgang || ""),
        miljogebyr.vedGate,
        miljogebyr.vedGatenummer,
        miljogebyr.gnr,
        miljogebyr.bnr,
        showImageIcon(miljogebyr),
      ];

      return {
        metadata: { isMakulert: miljogebyr.isMakulert },
        data,
      };
    });
  }
);

const miljogebyrQueryParameters = (state) =>
  state.forms.miljogebyrerOversiktFilterForm;

const miljoleveringstyper = (state) => state.miljoleveringstyper;

const getNavnById = (id, objectArray) => {
  const object = objectArray.filter((leveringstype) => leveringstype.id === id);
  return object.length > 0 && object[0].navn ? object[0].navn : "";
};

const getMiljogebyrQueryParameterStatusString = (miljogebyrQueryParameters) => {
  let statuser = "";
  statuser =
    initMiljogebyrQueryParameters.isTilEttersendelse !==
    miljogebyrQueryParameters.isTilEttersendelse
      ? `${statuser}Til ettersendelse, `
      : statuser;
  statuser =
    initMiljogebyrQueryParameters.isEttersendt !==
    miljogebyrQueryParameters.isEttersendt
      ? `${statuser}Ettersendt, `
      : statuser;
  statuser =
    initMiljogebyrQueryParameters.isKlarTilKemner !== // Skal stå som isKlarTilKemner tils endret på backend
    miljogebyrQueryParameters.isKlarTilKemner
      ? `${statuser}Klar til INE, `
      : statuser;
  statuser =
    initMiljogebyrQueryParameters.isStoppetAvSystemet !==
    miljogebyrQueryParameters.isStoppetAvSystemet
      ? `${statuser}Stoppet av systemet, `
      : statuser;
  statuser =
    initMiljogebyrQueryParameters.isFeiletKemner !==
    miljogebyrQueryParameters.isFeiletKemner
      ? `${statuser}Feilet INE, `
      : statuser;
  statuser =
    initMiljogebyrQueryParameters.isSendtKemner !== // Skal stå isSendtKemner tils backend er endret til isSendtIne
    miljogebyrQueryParameters.isSendtKemner
      ? `${statuser}Sendt til INE, `
      : statuser;
  statuser =
    initMiljogebyrQueryParameters.isBetalt !==
    miljogebyrQueryParameters.isBetalt
      ? `${statuser}Betalt, `
      : statuser;
  statuser =
    initMiljogebyrQueryParameters.isMaKvalitetsikres !==
    miljogebyrQueryParameters.isMaKvalitetsikres
      ? `${statuser}Må kvalitetsikres, `
      : statuser;

  return statuser ? statuser.slice(0, statuser.length - 2) : statuser;
};

export const getIleggelseQueryParameters = createSelector(
  [
    miljogebyrQueryParameters,
    overtredelseskoderMiljogebyr,
    miljoleveringstyper,
  ],
  (
    miljogebyrQueryParameters,
    overtredelseskoderMiljogebyr,
    miljoleveringstyper
  ) => {
    let changedMiljogebyrQueryParameters = [];
    if (
      initMiljogebyrQueryParameters.ileggelsesnummer !==
      miljogebyrQueryParameters.ileggelsesnummer
    ) {
      changedMiljogebyrQueryParameters = [
        ...changedMiljogebyrQueryParameters,
        {
          key: "Ileggelsesnummer",
          value: miljogebyrQueryParameters.ileggelsesnummer,
        },
      ];
    }
    if (
      miljogebyrQueryParameters.fraDato &&
      miljogebyrQueryParameters.tilDato
    ) {
      changedMiljogebyrQueryParameters = [
        ...changedMiljogebyrQueryParameters,
        {
          key: "Dato",
          value: `${toNorwegianDatetime(
            miljogebyrQueryParameters.fraDato
          ).format("DD.MM.YYYY HH:mm")} - ${toNorwegianDatetime(
            miljogebyrQueryParameters.tilDato
          ).format("DD.MM.YYYY HH:mm")}`,
        },
      ];
    }
    if (
      initMiljogebyrQueryParameters.tjenestenummer !==
      miljogebyrQueryParameters.tjenestenummer
    ) {
      changedMiljogebyrQueryParameters = [
        ...changedMiljogebyrQueryParameters,
        {
          key: "Tjenestenummer",
          value: miljogebyrQueryParameters.tjenestenummer,
        },
      ];
    }
    if (
      initMiljogebyrQueryParameters.overtredelseskode !==
      miljogebyrQueryParameters.overtredelseskode
    ) {
      changedMiljogebyrQueryParameters = [
        ...changedMiljogebyrQueryParameters,
        {
          key: "Overtredelsekode",
          value: getOvertredelseParagrafer(
            [miljogebyrQueryParameters.overtredelseskode],
            overtredelseskoderMiljogebyr
          ).toString(),
        },
      ];
    }
    if (
      initMiljogebyrQueryParameters.mottaker !==
      miljogebyrQueryParameters.mottaker
    ) {
      changedMiljogebyrQueryParameters = [
        ...changedMiljogebyrQueryParameters,
        {
          key: "Mottaker",
          value: miljogebyrQueryParameters.mottaker,
        },
      ];
    }
    if (
      initMiljogebyrQueryParameters.gatenavn !==
      miljogebyrQueryParameters.gatenavn
    ) {
      changedMiljogebyrQueryParameters = [
        ...changedMiljogebyrQueryParameters,
        {
          key: "Gatenavn/Ved",
          value: miljogebyrQueryParameters.gatenavn,
        },
      ];
    }
    if (
      initMiljogebyrQueryParameters.gatenummer !==
      miljogebyrQueryParameters.gatenummer
    ) {
      changedMiljogebyrQueryParameters = [
        ...changedMiljogebyrQueryParameters,
        {
          key: "Gatenummer/Ved nr.",
          value: miljogebyrQueryParameters.gatenummer,
        },
      ];
    }
    if (
      initMiljogebyrQueryParameters.bydel !== miljogebyrQueryParameters.bydel
    ) {
      changedMiljogebyrQueryParameters = [
        ...changedMiljogebyrQueryParameters,
        {
          key: "Bydel",
          value: miljogebyrQueryParameters.bydel,
        },
      ];
    }
    if (
      initMiljogebyrQueryParameters.leveringstype !==
      miljogebyrQueryParameters.leveringstype
    ) {
      changedMiljogebyrQueryParameters = [
        ...changedMiljogebyrQueryParameters,
        {
          key: "Leveringstype",
          value: getNavnById(
            miljogebyrQueryParameters.leveringstype,
            miljoleveringstyper
          ),
        },
      ];
    }
    if (
      initMiljogebyrQueryParameters.isMakulert !==
      miljogebyrQueryParameters.isMakulert
    ) {
      changedMiljogebyrQueryParameters = [
        ...changedMiljogebyrQueryParameters,
        {
          key: "Makulert",
          value: getNavnById(
            miljogebyrQueryParameters.isMakulert,
            makulertStatus
          ),
        },
      ];
    }
    if (
      initMiljogebyrQueryParameters.isVenter !==
      miljogebyrQueryParameters.isVenter
    ) {
      changedMiljogebyrQueryParameters = [
        ...changedMiljogebyrQueryParameters,
        {
          key: "Venter",
          value: getNavnById(
            miljogebyrQueryParameters.isVenter,
            makulertStatus
          ),
        },
      ];
    }
    const statuser = getMiljogebyrQueryParameterStatusString(
      miljogebyrQueryParameters
    );
    if (statuser) {
      changedMiljogebyrQueryParameters = [
        ...changedMiljogebyrQueryParameters,
        {
          key: "Status",
          value: statuser,
        },
      ];
    }

    return changedMiljogebyrQueryParameters;
  }
);

const getCSVStatus = (miljogebyr) => {
  let status = "";

  if (miljogebyr.isTilEttersendelse)
    status += `${miljogebyrStatuserDictionary["isTilEttersendelse"]}, `;
  if (miljogebyr.isEttersendt)
    status += `${miljogebyrStatuserDictionary["isEttersendt"]}, `;
  if (miljogebyr.isKlarTilKemner)
    status += `${miljogebyrStatuserDictionary["isKlarTilIne"]}, `;
  if (miljogebyr.isStoppetAvSystemet)
    status += `${miljogebyrStatuserDictionary["isStoppetAvSystemet"]}, `;
  if (miljogebyr.isFeiletKemner)
    status += `${miljogebyrStatuserDictionary["isFeiletIne"]}, `;
  if (miljogebyr.isSendtKemner)
    status += `${miljogebyrStatuserDictionary["isSendtIne"]}, `;
  if (miljogebyr.isBetalt)
    status += `${miljogebyrStatuserDictionary["isBetalt"]}, `;
  if (miljogebyr.isMaKvalitetsikres)
    status += `${miljogebyrStatuserDictionary["isMaKvalitetsikres"]}, `;
  if (miljogebyr.isVenter)
    status += `${miljogebyrStatuserDictionary["isVenter"]}, `;
  if (miljogebyr.isMakulert)
    status += `${miljogebyrStatuserDictionary["isMakulert"]}, `;

  return status ? status.slice(0, status.length - 2) : "";
};

export const getMiljogebyrInExcelFormat = createSelector(
  [miljogebyrerItems, overtredelseskoderMiljogebyr],
  (miljogebyrerItems, overtredelseskoderMiljogebyr) => {
    const data = miljogebyrerItems.map((miljogebyr) => {
      const ileggelsesdatoTil =
        miljogebyr.ileggelsesdatoTil &&
        toNorwegianDatetime(miljogebyr.ileggelsesdatoTil).format("DD.MM.YYYY");

      let klokkeslett = "";
      if (miljogebyr.ileggelsesdatoFra) {
        klokkeslett = toNorwegianDatetime(miljogebyr.ileggelsesdatoFra).format(
          "HH:mm"
        );
      }
      if (miljogebyr.ileggelsesdatoTil) {
        klokkeslett += ` - ${toNorwegianDatetime(
          miljogebyr.ileggelsesdatoTil
        ).format("HH:mm")}`;
      }

      let overtredelseskodeParagrafer = "";
      [
        miljogebyr.overtredelse1,
        miljogebyr.overtredelse2,
        miljogebyr.overtredelse3,
      ].forEach((overtredelse, index) => {
        if (overtredelse) {
          const overtredelsesKode = _.find(
            overtredelseskoderMiljogebyr,
            (overtredelsesKode) => overtredelsesKode.id === overtredelse
          );
          if (overtredelsesKode && index === 0) {
            overtredelseskodeParagrafer = overtredelsesKode.paragraf;
          } else if (overtredelsesKode !== 0) {
            overtredelseskodeParagrafer += `, ${overtredelsesKode.paragraf}`;
          }
        }
      });

      const status = getCSVStatus(miljogebyr);

      return {
        "Ilegg.nr.": miljogebyr.ileggelsesnummer,
        "Ilegg.dato": ileggelsesdatoTil,
        Klokkeslett: klokkeslett,
        "Tjenestenr.": miljogebyr.betjentNummer,
        "Overt.kode": overtredelseskodeParagrafer,
        Mottaker: miljogebyr.mottaker,
        Gatenavn: miljogebyr.gatenavn,
        "Gatenr.":
          (miljogebyr.gatenummer || "") + " " + (miljogebyr.oppgang || ""),
        Ved: miljogebyr.vedGate,
        "Ved nr.": miljogebyr.vedGatenummer,
        GNR: miljogebyr.gnr,
        BNR: miljogebyr.bnr,
        Status: status,
      };
    });

    return [
      {
        name: "Miljøgebyr",
        data,
      },
    ];
  }
);

export default getMiljogebyrList;
