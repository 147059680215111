import moment from "moment";
import axios from "../services/axios";
import * as api from "../constants/api";
import * as handle from "../utils/actionUtils";

import { addFlashMessage } from "./FlashMessagesAction";

export const GET_ILEGGELSE_MAKULERINGER_SUCCESS =
  "GET_ILEGGELSE_MAKULERINGER_SUCCESS";
export const GET_ILEGGELSE_MAKULERINGER_FAILURE =
  "GET_ILEGGELSE_MAKULERINGER_FAILURE";

export function getIleggelseMakuleringer(ileggelseId) {
  return async (dispatch) => {
    return axios
      .get(api.GET_ILEGGELSE_MAKULERINGER_URL(ileggelseId))
      .then(handle.success(dispatch, GET_ILEGGELSE_MAKULERINGER_SUCCESS))
      .catch((error) => {
        dispatch(
          addFlashMessage({
            type: "error",
            message: error,
          })
        );
        return Promise.reject(true);
      });
  };
}

export const POST_ILEGGELSER_MAKULERING_SUCCESS =
  "POST_ILEGGELSER_MAKULERING_SUCCESS";
export const POST_ILEGGELSER_MAKULERING_FAILURE =
  "POST_ILEGGELSER_MAKULERING_FAILURE";

export function postIleggelserMakulering(ileggelseId, kommentar) {
  return (dispatch, getState) => {
    return axios
      .post(api.POST_ILEGGELSER_MAKULERING_URL(ileggelseId, kommentar), {
        kommentar,
        makulertDato: moment.utc(),
      })
      .then(() => {
        return Promise.resolve(true);
      })
      .catch((response) => {
        dispatch(
          addFlashMessage({
            type: "error",
            message: response,
          })
        );
        handle.error(dispatch, POST_ILEGGELSER_MAKULERING_FAILURE);
        return Promise.reject(true);
      });
  };
}

export const DELETE_ILEGGELSE_MAKULERINGER_SUCCESS =
  "DELETE_ILEGGELSE_MAKULERINGER_SUCCESS";
export const DELETE_ILEGGELSE_MAKULERINGER_FAILURE =
  "DELETE_ILEGGELSE_MAKULERINGER_FAILURE";

export function deleteIleggelseMakulering(ileggelseMakuleringId) {
  return (dispatch, getState) => {
    return axios
      .delete(api.DELETE_ILEGGELSE_MAKULERINGER_URL(ileggelseMakuleringId))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  };
}
