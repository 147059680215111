import {
  handleChangeActionType,
  handleChangeAllActionType,
  handleChangeToInitStateActionType,
} from "../../actions/handleChangeAction";
import _ from "lodash";

export const INITIAL_STATE = {
  ileggelseType: "V",
  nasjon: null,
};

export default function ubetalteIleggelserFilterFormReducer(
  state = {
    ...INITIAL_STATE,
  },
  action
) {
  switch (action.type) {
    case handleChangeActionType("RAPPORT_UBETALTE_ILEGGELSER_FILTER_FORM"):
      _.set(state, action.variableName, action.payload);
      return { ...state };
    case handleChangeAllActionType("RAPPORT_UBETALTE_ILEGGELSER_FILTER_FORM"):
      return { ...action.payload };
    case handleChangeToInitStateActionType(
      "RAPPORT_UBETALTE_ILEGGELSER_FILTER_FORM"
    ):
      return {
        ...INITIAL_STATE,
        validationError: { ...INITIAL_STATE.validationError },
      };
    default:
      return state;
  }
}
