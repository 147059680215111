import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

function hasError(error) {
  return error && error.length > 0;
}
const LabelControl = ({ name, label, text, id, rules, validationError }) => {
  return (
    <div
      className={classnames(
        "form-group",
        hasError(validationError) ? { "has-error": validationError } : ""
      )}
    >
      {label && (
        <label className="control-label" htmlFor={name}>
          {label}
        </label>
      )}
      <div
        className="text"
        style={{ height: "40px", paddingTop: "10px", verticalAlign: "middle" }}
      >
        {text}
      </div>
      {hasError(validationError) && (
        <span className="help-block">{validationError}</span>
      )}
    </div>
  );
};

LabelControl.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  validationError: PropTypes.string,
  index: PropTypes.number,
  rules: PropTypes.string
};

export default LabelControl;
