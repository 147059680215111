import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FilterForm from "./FilterForm";
import { getIleggelsestyper as getIleggelsestyperAction } from "../../../actions/ileggelsestyperActions";
import {
  handleChange as handleChangeAction,
  handleChangeToInitState as handleChangeToInitStateAction,
} from "../../../actions/handleChangeAction";
import { getEttersendelser as getEttersendelserAction } from "../../../actions/ettersendelserActions";
import { getBybetjentGrupper as getBybetjentGrupperAction } from "../../../actions/bybetjentgrupperAction";
import { setPage as setPageAction } from "../../../actions/advancedListActions";

import { getSelectOptions } from "../../../selectors/selectOptionsSelector";

import { handleFromToDateTime } from "../../../utils/fromToDateTimeUtils";
import * as inputValidation from "../../../utils/inputValidationUtils";
import { toIsoString } from "../../../utils/datetimeUtils";
import { IsValidated } from "../../../utils/validationUtils";

import "./EttersendelserFilterContainer.css";
import { replaceWhitespacesWithEmptyStrings } from "../../../utils/sanitizationUtils";

class EttersendelserFilterContainer extends React.Component {
  static propTypes = {
    ettersendelserFilterForm: PropTypes.object,
    ileggelsestyper: PropTypes.array,
    handleChange: PropTypes.func,
    getIleggelsestyper: PropTypes.func,
    getBybetjentGrupper: PropTypes.func,
    setPage: PropTypes.func.isRequired,
  };

  state = {
    showSokSpinner: false,
  };

  rules = {
    ileggelsesnummer: "isPositiveInteger",
    tjenestenummer: "isPositiveInteger",
  };

  componentDidMount() {
    this.props.getIleggelsestyper();
    this.props.getBybetjentGrupper();
  }

  getPropsAsFakeState = () => {
    return {
      ileggelsesnummer: this.props.ettersendelserFilterForm.ileggelsesnummer,
      tjenestenummer: this.props.ettersendelserFilterForm.tjenestenummer,
      validationError: {
        ileggelsesnummer:
          this.props.ettersendelserFilterForm.validationError.ileggelsesnummer,
        tjenestenummer:
          this.props.ettersendelserFilterForm.validationError.tjenestenummer,
      },
    };
  };

  handleChange = (e) => {
    const { ettersendelserFilterForm } = this.props;
    if (
      e.target.name === "fraDato" ||
      e.target.name === "tilDato" ||
      e.target.name === "fraTid" ||
      e.target.name === "tilTid"
    ) {
      handleFromToDateTime(
        e,
        ettersendelserFilterForm,
        this.props.handleChange
      );
      return;
    }

    this.props.handleChange(e.target.name, e.target.value);
  };

  handleBlur = (e) => {
    const { handleChange } = this.props;
    if (
      e.target.name === "ileggelsesnummer" ||
      e.target.name === "tjenestenummer"
    ) {
      handleChange(
        e.target.name,
        replaceWhitespacesWithEmptyStrings(e.target.value)
      );
    }
  };

  handleChangeToInitState = () => {
    this.props.handleChangeToInitState();
  };

  isValid() {
    let propsAsFakeState = this.getPropsAsFakeState();
    const { isValid, validationError } = IsValidated(
      propsAsFakeState,
      this.rules
    );
    this.props.handleChange(
      "validationError.ileggelsesnummer",
      validationError.ileggelsesnummer
    );
    this.props.handleChange(
      "validationError.tjenestenummer",
      validationError.tjenestenummer
    );

    const fraDateTimeTilDateTimeValid =
      inputValidation.fraDateTimeTilDateTimeValidation(
        this.props.ettersendelserFilterForm,
        this.props.handleChange
      );

    return isValid && fraDateTimeTilDateTimeValid;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { setPage } = this.props;
    if (this.isValid()) {
      let params = this.getEttersendelserFilter();
      this.setState(() => ({ showSokSpinner: true }));
      const page = 1;
      this.props
        .getEttersendelser(params, page, this.props.ettersendelser.count)
        .then(() => {
          this.setState(() => ({ showSokSpinner: false }));
          setPage(page);
        })
        .catch(() => this.setState(() => ({ showSokSpinner: false })));
    }
  };

  getEttersendelserFilter() {
    let { fraTid, tilTid, status, ...filter } =
      this.props.ettersendelserFilterForm;
    return (filter = {
      ...filter,
      fraDato: filter.fraDato && toIsoString(filter.fraDato),
      tilDato: filter.tilDato && toIsoString(filter.tilDato),
      isElsparksykkelGebyr: false,
    });
  }

  render() {
    const { ettersendelserFilterForm, ileggelsestyper } = this.props;
    const { showSokSpinner } = this.state;

    return (
      <div className="ettersendelse-filter-container">
        <h1>Søk etter ettersendelser</h1>
        <FilterForm
          showSokSpinner={showSokSpinner}
          ettersendelseFilterFormState={ettersendelserFilterForm}
          betjentgrupper={this.props.betjentgrupper}
          ileggelsestyper={ileggelsestyper}
          handleChange={this.handleChange}
          handleBlur={this.handleBlur}
          handleChangeToInitState={this.handleChangeToInitState}
          handleSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ettersendelserFilterForm: state.forms.ettersendelserFilterForm,
    ileggelsestyper: getSelectOptions(state, "ileggelsestyper", "navn", "id", {
      text: "Velg type",
      value: "",
    }),
    betjentgrupper: getSelectOptions(state, "betjentgrupper", "navn", "id", {
      text: "Velg",
      value: "",
    }),
    ettersendelser: state.ettersendelser,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getIleggelsestyper: () => dispatch(getIleggelsestyperAction()),
    handleChange: (variableName, value) =>
      dispatch(
        handleChangeAction("ETTERSENDELSER_FILTER_FORM", variableName, value)
      ),
    handleChangeToInitState: () =>
      dispatch(handleChangeToInitStateAction("ETTERSENDELSER_FILTER_FORM")),
    getEttersendelser: (ettersendelserFilterForm, page, count) =>
      dispatch(getEttersendelserAction(ettersendelserFilterForm, page, count)),
    getBybetjentGrupper: () => dispatch(getBybetjentGrupperAction()),
    setPage: (page) => dispatch(setPageAction("ettersendelser", page)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EttersendelserFilterContainer);
