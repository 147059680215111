import React, { Component } from "react";
import { connect } from "react-redux";
// import PropTypes from "prop-types";
import NavigationBar from "../Common/NavigationBar";
import { withRouter } from "react-router";

import { authorized } from "../../utils/authorizationUtils";

import {
  bypatruljenArray,
  juridiskArray,
  juridisk,
  bypatruljen,
  skiftlogg,
} from "../../constants/roles";
import sitePaths from "../../constants/sitePaths";

import "./rapporter.css";

export class NavigationBarContainer extends Component {
  authorizedVentilstilling = authorized([
    bypatruljen.saksbehandler,
    bypatruljen.saksbehandleradmin,
    bypatruljen.avdelingdirektor,
    bypatruljen.seksjonssjefStab,
    bypatruljen.seksjonssjef,
    bypatruljen.driftleder,
    bypatruljen.stedfortrederDriftsleder,
    ...juridiskArray,
  ]);

  authorizedKlager = authorized([
    juridisk.ledelse,
    bypatruljen.saksbehandler,
    bypatruljen.saksbehandleradmin,
    bypatruljen.avdelingdirektor,
    bypatruljen.seksjonssjefStab,
  ]);

  authorizedUferdigstilteKlagesaker = authorized([
    bypatruljen.saksbehandler,
    bypatruljen.saksbehandleradmin,
    bypatruljen.avdelingdirektor,
    ...juridiskArray,
  ]);

  authorizedSkiftlogg = authorized([skiftlogg, ...bypatruljenArray]);

  authorizedPiggdekkontroll = authorized([
    bypatruljen.saksbehandler,
    bypatruljen.saksbehandleradmin,
    bypatruljen.avdelingdirektor,
    bypatruljen.seksjonssjefStab,
    bypatruljen.seksjonssjef,
    bypatruljen.driftleder,
    bypatruljen.stedfortrederDriftsleder,
    ...juridiskArray,
  ]);

  authorizedAntallIleggelserPerIleggelsetype = authorized([
    bypatruljen.saksbehandler,
    bypatruljen.saksbehandleradmin,
    bypatruljen.avdelingdirektor,
    bypatruljen.seksjonssjefStab,
    bypatruljen.seksjonssjef,
    bypatruljen.driftleder,
    ...juridiskArray,
  ]);

  authorizedMakuleringer = authorized([
    bypatruljen.avdelingdirektor,
    bypatruljen.seksjonssjefStab,
    bypatruljen.saksbehandleradmin,
    bypatruljen.saksbehandler,
  ]);

  authorizedAArsrapport = authorized([
    bypatruljen.saksbehandler,
    bypatruljen.saksbehandleradmin,
    bypatruljen.avdelingdirektor,
    bypatruljen.seksjonssjefStab,
    bypatruljen.seksjonssjef,
    bypatruljen.driftleder,
    ...juridiskArray,
  ]);

  authorizedUbetalteIleggelser = authorized([bypatruljen.saksbehandleradmin]);

  getNavigationItems() {
    let navigationItems = [];
    if (this.authorizedVentilstilling) {
      navigationItems.push({
        navn: "Ventilstillingskontroll",
        path: sitePaths.rapporter.ventilstillinger.oversikt,
      });
    }
    if (this.authorizedUbetalteIleggelser) {
      navigationItems.push({
        navn: "Ubetalte Ileggelser",
        path: sitePaths.rapporter.ubetalteIleggelser.oversikt,
      });
    }
    if (this.authorizedSkiftlogg) {
      navigationItems.push({
        navn: "Skiftlogg",
        path: sitePaths.rapporter.skiftlogg.oversikt,
      });
    }
    if (this.authorizedPiggdekkontroll) {
      navigationItems.push({
        navn: "Piggdekkontroll",
        path: sitePaths.rapporter.piggdekkontroller.oversikt,
      });
    }
    if (this.authorizedKlager) {
      navigationItems.push({
        navn: "Klager",
        path: sitePaths.rapporter.klager.oversikt,
      });
    }
    if (this.authorizedUferdigstilteKlagesaker) {
      navigationItems.push({
        navn: "Uferdigstilte klagesaker",
        path: sitePaths.rapporter.uferdigstilteKlagesaker.oversikt,
      });
    }
    if (this.authorizedAntallIleggelserPerIleggelsetype) {
      navigationItems.push({
        navn: "Antall ileggelser per ileggelsetype",
        path: sitePaths.rapporter.antallIleggelserPerIleggelsetype.oversikt,
      });
    }
    if (this.authorizedMakuleringer) {
      navigationItems.push({
        navn: "Makuleringer",
        path: sitePaths.rapporter.makuleringer.oversikt,
      });
    }
    if (this.authorizedAArsrapport) {
      navigationItems.push({
        navn: "Årsrapport",
        path: sitePaths.rapporter.aarsrapport.oversikt,
      });
    }

    return navigationItems;
  }

  render() {
    return <NavigationBar navigationItems={this.getNavigationItems()} />;
  }
}

const mapStateToProps = (state) => ({
  login: state.login,
});

export default connect(
  mapStateToProps,
  null
)(withRouter(NavigationBarContainer));
