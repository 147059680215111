import React from "react";
import { PropTypes } from "prop-types";
import Table from "../../Common/Tables/BaseTable";

import { toNorwegianDatetime } from "../../../utils/datetimeUtils";

class PiggdekkontrollList extends React.Component {
  static propTypes = {
    piggdekkontroller: PropTypes.array,
  };
  render() {
    const { piggdekkontroller } = this.props;

    const header = [
      "Dato",
      "Hovedgate",
      "Fra gate",
      "Til gate",
      "Tjenestenummer",
      "Kjennemerke",
    ];

    return (
      <Table headerRow={header} striped>
        <tbody>
          {piggdekkontroller &&
            piggdekkontroller.map((piggdekkontroll, index) => {
              return (
                <tr key={index}>
                  <td>
                    {toNorwegianDatetime(piggdekkontroll.dato).format(
                      "DD.MM.YYYY"
                    )}
                  </td>
                  <td>{piggdekkontroll.hovedgateNavn}</td>
                  <td>{piggdekkontroll.fraGateNavn}</td>
                  <td>{piggdekkontroll.tilGateNavn}</td>
                  <td>{String(piggdekkontroll.tjenestenummer)}</td>
                  <td>{piggdekkontroll.kjennemerke}</td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    );
  }
}

export default PiggdekkontrollList;
