import * as api from "../constants/api";
import * as axiosUtils from "../utils/axiosUtils";

export const actionType = "KJORETOY_MERKER";
const metaData = { entitet: "Kjoretoymerke", notify: true };

export function getKjoretoyMerker() {
  return axiosUtils.GetAll(api.GET_KJORETOY_MERKER_URL, actionType);
}

export function postKjoretoyMerke(payload) {
  return axiosUtils.Post(
    api.POST_KJORETOY_MERKE_URL,
    payload,
    actionType,
    metaData
  );
}

export function putKjoretoyMerke(payload) {
  return axiosUtils.Put(
    api.PUT_KJORETOY_MERKE_URL(payload.id),
    payload,
    actionType,
    metaData
  );
}
