import * as miljogebyrTilBehandlingActions from "../actions/miljogebyrTilBehandlingActions";

const INITIAL_STATE = [];

export default function miljogebyrTilBehandlingReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case miljogebyrTilBehandlingActions.GET_MILJOGEBYR_TIL_BEHANDLING_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
