import React from "react";
import PropTypes from "prop-types";
import * as XLSX from "xlsx";

const Excel = ({ element, filename, sheets }) => {
  const onExport = () => {
    if (!sheets || sheets.length === 0) {
      return;
    }
    var workbook = XLSX.utils.book_new();

    for (let index = 0; index < sheets.length; index++) {
      const sheet = sheets[index];
      var worksheet = XLSX.utils.json_to_sheet(sheet.data);
      XLSX.utils.book_append_sheet(workbook, worksheet, sheet.name);
    }

    XLSX.writeFile(workbook, filename);
  };

  return <>{element ? element(onExport) : <></>}</>;
};

Excel.propTypes = {
  tableTitle: PropTypes.string,
  filename: PropTypes.string,
  element: PropTypes.func,
  sheet: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      data: PropTypes.arrayOf(PropTypes.object),
    })
  ),
};

export default Excel;
