const uregistrerteSitePaths = {
  shortUrl: "/uregistrerte",
  page: "/uregistrerte/:path",
  kontroller: {
    shortUrl: "/uregistrerte/kontroller",
    detalj: {
      shortUrl: {
        routeMatcher:
          "/uregistrerte/kontroller/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
        getUrl: (kontrollId) => `/uregistrerte/kontroller/${kontrollId}`,
      },
    },
  },
};

export default uregistrerteSitePaths;
