import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";

import List from "./List";
import createPaginationControl from "../../Common/createPaginationControl";
import EttersendelserFilterContainer from "./EttersendelserFilterContainer";
import NavigationBarContainer from "../NavigationBarContainer";
import Spinner from "../../Common/Spinner";
import PrintOut from "../../Common/PrintOut";

import { getEttersendelseList } from "../../../selectors/ettersendelserSelector";

import { getEttersendelser as getEttersendelserActions } from "../../../actions/ettersendelserActions";

import { putIleggelseEttersendelserTilGodkjenning as putIleggelseEttersendelserTilGodkjenningAction } from "../../../actions/ettersendelserActions";
import { postIleggelserMakulering as postIleggelserMakuleringAction } from "../../../actions/ileggelseMakuleringerActions";

import * as title from "../../../constants/titles";
import sitePaths from "../../../constants/sitePaths";

import "./EttersendelserPage.css";

const PaginationControl = createPaginationControl(
  "ettersendelser",
  {
    apiCallAction: getEttersendelserActions,
    queriesForApiReducerName: "forms.ettersendelserFilterForm",
  },
  getEttersendelseList
);

class EttersendelserPage extends Component {
  static propTypes = {
    ettersendelser: PropTypes.shape({
      items: PropTypes.array,
      total: PropTypes.number,
    }),
  };

  constructor(props) {
    super(props);
    this.state = {
      waitingForGetEttersendelser: false,
      checkedEttersendelser: {
        ..._.reduce(
          this.props.ettersendelser.items.filter((e) => e?.ettersendelse?.id !== "00000000-0000-0000-0000-000000000000").map((e) => {
            let key = (e.ettersendelse && e.ettersendelse.id) || e.id;
            let value = key === e.id ? undefined : false;
            return { [key]: value };
          }),
          _.extend
        ),
        checkboxAllEttersendelser: false,
      },
    };
  }

  componentDidMount() {
    document.title = title.ileggelse.ettersendelser;
    if (
      this.props.ettersendelserFilterForm &&
      this.props.ettersendelser.page &&
      this.props.ettersendelser.count
    ) {
      this.setState({ waitingForGetEttersendelser: true });
      this.props
        .getEttersendelser(
          this.getEttersendelserFilter(),
          this.props.ettersendelser.page,
          this.props.ettersendelser.count
        )
        .then(() => this.setState({ waitingForGetEttersendelser: false }))
        .catch(() => this.setState({ waitingForGetEttersendelser: false }));
    }
  }

  getEttersendelserFilter() {
    let { fraTid, tilTid, status, ...filter } =
      this.props.ettersendelserFilterForm;
    return (filter = {
      ...filter,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      JSON.stringify(nextProps.ettersendelser) !==
      JSON.stringify(this.props.ettersendelser)
    ) {
      this.setState({
        checkedEttersendelser: {
          ..._.reduce(
            nextProps.ettersendelser.items.filter((e) => e?.ettersendelse?.id !== "00000000-0000-0000-0000-000000000000").map((e) => {
              let key = (e.ettersendelse && e.ettersendelse.id) || e.id;
              let value = key === e.id ? undefined : false;
              return { [key]: value };
            }),
            _.extend
          ),
          checkboxAllEttersendelser: false,
        },
      });
    }
  }

  handleCheckboxChange(event) {
    if (event.target.name === "checkboxAllEttersendelser") {
      let keys = Object.keys(this.state.checkedEttersendelser);
      let checkedEttersendelserArray = keys.map((k) => {
        if (this.state.checkedEttersendelser[k] === undefined) {
          return { [k]: undefined };
        }
        return { [k]: event.target.checked };
      });
      this.setState({
        checkedEttersendelser: _.reduce(checkedEttersendelserArray, _.extend),
      });
    } else {
      this.setState({
        checkedEttersendelser: {
          ...this.state.checkedEttersendelser,
          [event.target.name]: event.target.checked,
        },
      });
    }
  }

  putIleggelseEttersendelserTilGodkjenningBatchJob() {
    let keys = Object.keys(this.state.checkedEttersendelser);
    let checkedIleggelseEttersendelserArray = keys.filter(
      (key) =>
        this.state.checkedEttersendelser[key] &&
        this.state.checkedEttersendelser[key] !== "checkboxAllEttersendelser"
    );
    let tilGodkjenningApiCallArray = checkedIleggelseEttersendelserArray.map(
      (ciea) =>
        this.props.putIleggelseEttersendelserTilGodkjenning(ciea, {
          tilGodkjenning: true,
        })
    );
    Promise.all(tilGodkjenningApiCallArray)
      .then((responseArray) => {
        this.props.getEttersendelser(
          this.getEttersendelserFilter(),
          this.props.ettersendelser.page,
          this.props.ettersendelser.count
        );
        // TODO create flash message
      })
      .catch((error) => {
        this.props.getEttersendelser(
          this.getEttersendelserFilter(),
          this.props.ettersendelser.page,
          this.props.ettersendelser.count
        );
        // TODO create flash message
      });
  }

  postIleggelserMakulering(ileggelseId, kommentar) {
    return this.props
      .postIleggelserMakulering(ileggelseId, kommentar)
      .then(() => {
        return this.props
          .getEttersendelser(
            this.getEttersendelserFilter(),
            this.props.ettersendelser.page,
            this.props.ettersendelser.count
          )
          .then(() => Promise.resolve(true))
          .catch(() => Promise.reject(true));
      })
      .catch(() => Promise.reject(true));
  }

  showSendTilGodkjenningButton = () => {
    let checkedIleggelseEttersendelserObjectArray =
      this.props.ettersendelser.items.filter((e) => {
        let checkedEttersendelser = [];
        Object.keys(this.state.checkedEttersendelser).forEach((key) => {
          if (this.state.checkedEttersendelser[key]) {
            checkedEttersendelser.push(key);
          }
        });
        let checkedEttersendelserKey =
          (e.ettersendelse && e.ettersendelse.id) || e.id;
        return checkedEttersendelser.some(
          (ce) => ce === checkedEttersendelserKey
        );
      });
    if (
      checkedIleggelseEttersendelserObjectArray &&
      checkedIleggelseEttersendelserObjectArray.length !== 0 &&
      checkedIleggelseEttersendelserObjectArray.every(
        (cieo) =>
          cieo.isTilEttersendelse &&
          !cieo.isMakulert &&
          !cieo.isBetalingsfristUtlopt &&
          !cieo.isBetalt &&
          !cieo.isVenter &&
          cieo?.ettersendelse?.id !== "00000000-0000-0000-0000-000000000000"
      )
    ) {
      return true;
    }
    return false;
  };

  render() {
    return (
      <>
        <NavigationBarContainer page="ettersendelser" />
        <EttersendelserFilterContainer />
        <div style={{ height: 28 }} />
        {(!this.state.waitingForGetEttersendelser ||
          this.props.ettersendelser.items.length !== 0) && (
          <React.Fragment>
            <div>
              <div className="pull-left" style={{ marginLeft: 50 }}>
                <PrintOut
                  url={`${window.location.origin}${sitePaths.ileggelser.ettersendelser.utskrift}`}
                  dataToStoreInSessionStorage={{
                    queryData: {
                      ...this.props.ettersendelserFilterForm,
                    },
                    paginationData: {
                      page: this.props.ettersendelser.page,
                      count: this.props.ettersendelser.count,
                    },
                  }}
                />
                {this.showSendTilGodkjenningButton() && (
                  <button
                    onClick={this.putIleggelseEttersendelserTilGodkjenningBatchJob.bind(
                      this
                    )}
                    style={{ marginLeft: 20 }}
                    className="btn-bym-action btn btn-default"
                    type="button"
                  >
                    Send til godkjenning
                  </button>
                )}
              </div>
              <div
                className="pull-right ettersendelse-list-page-total-ettersendelser"
                style={{ paddingTop: 20, paddingRight: 50 }}
              >
                {this.props.ettersendelser.total}{" "}
                {this.props.ettersendelser.total === 1
                  ? "Ettersendelse"
                  : "Ettersendelser"}
              </div>
            </div>
            <div className="clearfix" />
            <div style={{ height: 22 }} />
            <div style={{ paddingRight: 50, paddingLeft: 50 }}>
              <div className="ettersendelse-list-page-content-container">
                <List
                  checkedEttersendelser={this.state.checkedEttersendelser}
                  handleCheckboxChange={this.handleCheckboxChange.bind(this)}
                  ettersendelser={this.props.ettersendelser.items}
                  postIleggelserMakulering={this.postIleggelserMakulering.bind(
                    this
                  )}
                />
              </div>
              <PaginationControl storeKey="ettersendelser" />
            </div>
          </React.Fragment>
        )}
        <Spinner
          isLoading={
            this.state.waitingForGetEttersendelser &&
            this.props.ettersendelser.items.length === 0
          }
        />
        <div style={{ height: 50 }} />
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ettersendelser: state.ettersendelser,
    ettersendelserFilterForm: state.forms.ettersendelserFilterForm,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    putIleggelseEttersendelserTilGodkjenning: (
      ileggelseEttersendelseId,
      data
    ) =>
      dispatch(
        putIleggelseEttersendelserTilGodkjenningAction(
          ileggelseEttersendelseId,
          data
        )
      ),
    getEttersendelser: (params, page, count) =>
      dispatch(getEttersendelserActions(params, page, count)),
    postIleggelserMakulering: (ileggelseId, kommentar) =>
      dispatch(postIleggelserMakuleringAction(ileggelseId, kommentar)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EttersendelserPage);
