import * as axiosUtils from "../utils/axiosUtils";
import {
  GET_BYBETJENTER_URL,
  GET_BYBETJENTE_BY_Id_URL,
  POST_BYBETJENTER_URL,
  PUT_BYBETJENTER_URL,
  BETJENT_TEAM_URL,
  BETJENT_TEAM_HISTORIKK_URL,
} from "../constants/api";

export const actionType = "BYBETJENTER";
export const GET_BYBETJENTER_FAILURE = "GET_BYBETJENTER_FAILURE";
const metaData = { entitet: "Bybetjent", notify: true };

export const BYBETJENTTEAMHISTORIKK = "BYBETJENTTEAMHISTORIKK";

export function getBybetjenter(params) {
  return axiosUtils.GetAllWithParams(GET_BYBETJENTER_URL, params, actionType);
}
export function getBybetjentById(betjentId) {
  const url = GET_BYBETJENTE_BY_Id_URL(betjentId);
  return axiosUtils.GetOne(url, actionType);
}
export function postBybetjenter(payload) {
  return axiosUtils.Post(POST_BYBETJENTER_URL, payload, actionType, metaData);
}
export function putBybetjenter(payload) {
  const url = PUT_BYBETJENTER_URL(payload.id);
  return axiosUtils.Put(url, payload, actionType, metaData);
}

export function getBetjentTeamHistorikk(betjentId) {
  const url = BETJENT_TEAM_HISTORIKK_URL(betjentId);
  return axiosUtils.GetAll(url, BYBETJENTTEAMHISTORIKK);
}

export function addBetjentToTeam(betjentId, teamId) {
  const url = BETJENT_TEAM_URL(betjentId, teamId);
  return axiosUtils.Post(url);
}

export function deleteBetjentsTeam(betjentId, teamId) {
  const url = BETJENT_TEAM_URL(betjentId, teamId);
  return axiosUtils.Delete(url);
}
