import axios from "../services/axios";
import * as api from "../constants/api";
import * as handle from "../utils/actionUtils";

export const GET_MILJO_RETTELSER_SUCCESS = "GET_MILJO_RETTELSER_SUCCESS";
export const GET_MILJO_RETTELSER_FAILURE = "GET_MILJO_RETTELSER_FAILURE";

export function getMiljoRettelser(miljogebyrId) {
  return async (dispatch) => {
    return axios
      .get(api.GET_MILJO_RETTELSER_URL(miljogebyrId))
      .then(handle.success(dispatch, GET_MILJO_RETTELSER_SUCCESS))
      .catch(handle.error(dispatch, GET_MILJO_RETTELSER_FAILURE));
  };
}
