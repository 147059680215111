import React, { Component } from "react";
import PropTypes from "prop-types";
import { NavDropdown } from "react-bootstrap";
import { withAuth } from "react-oidc-context";

import UserIcon from "../Common/UserIcon";

import sitePaths from "../../constants/sitePaths";

class UserNavItem extends Component {
  static propTypes = {
    auth: PropTypes.object,
    history: PropTypes.object.isRequired,
  };

  onClickDropdownItem = (e, url) => {
    e.preventDefault();
    this.props.history.push(url);
  };

  handleLoggUt = async (e) => {
    e.preventDefault();

    const { auth, history } = this.props;    

    await auth.removeUser();
    await auth.signoutRedirect({ id_token_hint: auth.user?.id_token });
    history.push(sitePaths.login);
  }

  render() {
    const userIcon = <UserIcon large />;
    return (
      <NavDropdown
        id="meny-bruker-profil"
        className="meny-bruker-profil"
        title={userIcon}
        alignRight
      >
        <NavDropdown.Item
          href={sitePaths.profil}
          onClick={(e) => this.onClickDropdownItem(e, sitePaths.profil)}
        >
          Profil
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item href="/login" onClick={this.handleLoggUt}>
          Logg ut
        </NavDropdown.Item>
      </NavDropdown>
    );
  }
}

export default withAuth(UserNavItem);
