import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import Miljokontroll from "./Miljokontroll";
import {
  getMiljokontroll as getMiljokontrollAction,
  getMiljokontrollBilder as getMiljokontrollBilderActions,
} from "../../../../actions/miljokontrollerActions";

import { authorized } from "../../../../utils/authorizationUtils";
import { bypatruljen, juridisk } from "../../../../constants/roles";
import sitePaths from "../../../../constants/sitePaths";

const mapStateToProps = (state) => ({
  miljogebyr: state.miljogebyr,
  miljokontroll: state.miljokontroll,
  miljokontrollerThumbnailBilder: state.miljokontrollerThumbnailBilder,
});

const mapDispatchToProps = (dispatch) => ({
  getMiljokontroll: (miljokontrollId) =>
    dispatch(getMiljokontrollAction(miljokontrollId)),
  getMiljokontrollBilder: (miljokontrollId, params) =>
    dispatch(getMiljokontrollBilderActions(miljokontrollId, params)),
  miljokontrollToInitState: () =>
    dispatch({ type: "MILJOKONTROLL_TO_INIT_STATE" }),
});

const getMiljokontrollLinkUrl = (location, miljogebyrId, miljokontrollId) => {
  if (
    location.pathname.search(
      sitePaths.miljogebyrer.oversikt.detalj.shortUrl.matcher
    ) === 0
  ) {
    return sitePaths.miljogebyrer.oversikt.detalj.miljokontroll.shortUrl.getUrl(
      miljogebyrId,
      miljokontrollId
    );
  } else if (
    location.pathname.search(
      sitePaths.miljogebyrer.tilBehandling.detalj.shortUrl.matcher
    ) === 0
  ) {
    return sitePaths.miljogebyrer.tilBehandling.detalj.miljokontroll.shortUrl.getUrl(
      miljogebyrId,
      miljokontrollId
    );
  } else if (
    location.pathname.search(
      sitePaths.miljogebyrer.miljokontrolloversikt.miljogebyrer.detalj.shortUrl
        .matcher
    ) === 0
  ) {
    return sitePaths.miljogebyrer.miljokontrolloversikt.miljogebyrer.detalj.miljokontroll.detalj.shortUrl.getUrl(
      miljogebyrId,
      miljokontrollId
    );
  } else if (
    location.pathname.search(
      sitePaths.miljogebyrer.miljokontrolloversikt.detalj.miljogebyrer.detalj
        .shortUrl.matcher
    ) === 0
  ) {
    return sitePaths.miljogebyrer.miljokontrolloversikt.detalj.shortUrl.getUrl(
      miljokontrollId
    );
  } else if (
    location.pathname.search(
      sitePaths.klage.miljogebyrer.detalj.shortUrl.matcher
    ) === 0
  ) {
    return sitePaths.klage.miljogebyrer.detalj.miljokontroll.shortUrl.getUrl(
      miljogebyrId,
      miljokontrollId
    );
  }
  return null;
};

const gridTableRows = (
  miljogebyr,
  miljokontroll,
  miljokontrollerThumbnailBilder,
  location
) => {
  if (!miljokontroll) {
    return [];
  }

  let kontrollnummer = miljokontroll.nummer
    ? miljokontroll.nummer.toString()
    : "";

  if (
    kontrollnummer &&
    authorized([
      bypatruljen.saksbehandleradmin,
      bypatruljen.saksbehandler,
      bypatruljen.driftleder,
      juridisk.saksbehandler,
    ])
  ) {
    const to = getMiljokontrollLinkUrl(
      location,
      miljogebyr.id,
      miljokontroll.id
    );
    kontrollnummer = <Link to={to}>{kontrollnummer}</Link>;
  }

  const tjenestenummer = miljokontroll.tjenestenummer
    ? miljokontroll.tjenestenummer.toString()
    : "";
  const antallSammanhengendeDager =
    miljokontrollerThumbnailBilder &&
    miljokontrollerThumbnailBilder[miljokontroll.id]
      ? miljokontrollerThumbnailBilder[miljokontroll.id].length.toString()
      : "0";

  return [
    [
      {
        name: "Kontrollnummer",
        value: kontrollnummer,
        description: "",
      },
      {
        name: "Tjenestenummer",
        value: tjenestenummer,
        description: "",
      },
      {
        name: "Antall sammanhengende dager",
        value: antallSammanhengendeDager,
        description: "",
      },
      { name: "Kommentar", value: miljokontroll.kommentar, description: "" },
    ],
  ];
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  gridTableRows: gridTableRows(
    stateProps.miljogebyr,
    stateProps.miljokontroll,
    stateProps.miljokontrollerThumbnailBilder,
    ownProps.location
  ),
  ...dispatchProps,
  ...ownProps,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(Miljokontroll)
);
