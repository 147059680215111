import React from "react";
import PropTypes from "prop-types";
import "./PrintOut.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";

const PrintOut = ({ url, dataToStoreInSessionStorage }) => {
  const handleClick = () => {
    window.sessionStorage.setItem(
      "printData",
      JSON.stringify(dataToStoreInSessionStorage)
    );
    window.open(url, "", "");
  };

  return (
    <button
      className="btn btn-bym-medium btn-bym-standard"
      onClick={url ? handleClick : window.print}
    >
      <FontAwesomeIcon
        icon={faPrint}
        fixedWidth
        style={{ paddingRight: "5px" }}
      />
      <span className="printButtoText">Skriv ut</span>
    </button>
  );
};

PrintOut.propTypes = {
  url: PropTypes.string,
  dataToStoreInSessionStorage: PropTypes.any,
};

export default PrintOut;
