import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import KlagerapportFilterForm from "./KlagerapportFilterForm";
import { INITIAL_STATE as klagerapportFilterFormInitState } from "../../../reducers/forms/KlageRapportFilterFormReducer";
import { getSelectOptionsEnhanced } from "../../../selectors/selectOptionsSelector";

import { getIleggelsestyper } from "../../../actions/ileggelsestyperActions";
import {
  handleChange as handleChangeAction,
  handleChangeToInitState as handleChangeToInitStateAction,
} from "../../../actions/handleChangeAction";
import { handleFromToDateTime } from "../../../utils/fromToDateTimeUtils";

import {
  getKlageLedelseRapport,
  ResetKlageLedelseRapport,
} from "../../../actions/klager/klageAction";

import * as inputValidation from "../../../utils/inputValidationUtils";

class KlageRapporterFilterContainer extends React.Component {
  static propTypes = {
    klageRapportFilterFormState: PropTypes.shape({
      selectedIleggelsestyper: PropTypes.any,
      fraDato: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      tilDato: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    }),
    ileggelsestyper: PropTypes.array,
    sortOptions: PropTypes.array,
    handleChange: PropTypes.func,
    handleChangeToInitState: PropTypes.func,
    getIleggelsestyper: PropTypes.func,
    updateSelectedIleggelsestyperState: PropTypes.func,
  };
  static defaultProps = {
    sortOptions: [
      { id: "antallGebyrer_asc", navn: "Antall gebyr lav-høy" },
      { id: "antallGebyrer_desc", navn: "Antall gebyr høy-lav" },
      { id: "antallKlageSaker_asc", navn: "Antall sak lav-høy" },
      { id: "antallKlageSaker_desc", navn: "Antall sak høy-lav" },
    ],
  };

  state = {
    showSokSpinner: false,
    showTilbakestillSpinner: false,
    ShallGetklagerapportDueToHandleChangeToInitState: false,
  };
  axiosCancelTokenSource = null;

  componentDidMount() {
    this.props.getIleggelsestyper();
  }

  componentDidUpdate() {
    if (
      JSON.stringify(this.props.klageRapportFilterFormState) ===
        JSON.stringify(klagerapportFilterFormInitState) &&
      this.state.ShallGetklagerapportDueToHandleChangeToInitState === true
    ) {
      this.setState({
        ShallGetklagerapportDueToHandleChangeToInitState: false,
        showTilbakestillSpinner: true,
      });
      this.axiosCancelTokenSource = axios.CancelToken.source();
    }
  }

  handleChange = (e) => {
    const { klageRapportFilterFormState } = this.props;

    if (e.target.name === "fraDato" || e.target.name === "tilDato") {
      handleFromToDateTime(
        e,
        klageRapportFilterFormState,
        this.props.handleChange
      );
      return;
    }
    this.props.handleChange(e.target.name, e.target.value);
  };
  handleChangeToInitState = () => {
    this.props.handleChangeToInitState();
    this.props.ResetKlageLedelseRapport();
    this.setState({ shallGetVentilstillingDueToHandleChangeToInitState: true });
  };

  resetValidationErrorText() {
    this.props.handleChange("fraDatoValidationErrorText", null);
    this.props.handleChange("tilDatoValidationErrorText", null);
  }

  validate() {
    return inputValidation.fraDateTimeTilDateTimeValidation(
      this.props.klageRapportFilterFormState,
      this.props.handleChange
    );
  }

  handleSubmit = (e) => {
    const { klageRapportFilterFormState, updateSelectedIleggelsestyperState } =
      this.props;
    e.preventDefault();
    this.resetValidationErrorText();
    if (this.validate()) {
      let selectedIleggelsestyper =
        klageRapportFilterFormState.selectedIleggelsestyper &&
        klageRapportFilterFormState.selectedIleggelsestyper.map(
          (sit) => sit.navn
        );
      updateSelectedIleggelsestyperState(selectedIleggelsestyper);
      this.setState(() => ({ showSokSpinner: true }));
      const params = this.getFilters();
      this.props
        .getKlageLedelseRapport(params)
        .then(() => {
          this.setState(() => ({ showSokSpinner: false }));
        })
        .catch(() => this.setState(() => ({ showSokSpinner: false })));
    }
  };
  getFilters() {
    let { fraDato, tilDato, selectedIleggelsestyper } =
      this.props.klageRapportFilterFormState;

    let selectIleggelsetyper = selectedIleggelsestyper.map((x) => x.id);

    let filter = {
      fraDato: fraDato && fraDato.toISOString(),
      tilDato: tilDato && tilDato.toISOString(),
      ileggelsestype: selectIleggelsetyper,
      navn: "ledelse_statistikk",
    };
    return filter;
  }

  componentWillUnmount() {
    this.axiosCancelTokenSource && this.axiosCancelTokenSource.cancel();
  }

  render() {
    const { showSokSpinner } = this.state;
    const { ileggelsestyper, klageRapportFilterFormState, sortOptions } =
      this.props;

    return (
      <KlagerapportFilterForm
        showSokSpinner={showSokSpinner}
        klageRapportFilterFormState={klageRapportFilterFormState}
        handleChange={this.handleChange}
        handleChangeToInitState={this.handleChangeToInitState}
        handleSubmit={this.handleSubmit}
        ileggelsestyper={ileggelsestyper}
        sortOptions={sortOptions}
      />
    );
  }
}

const mapStateToProps = (state) => {
  let ileggelsestyper = getSelectOptionsEnhanced(
    state,
    "ileggelsestyper",
    "navn",
    "id"
  );
  return {
    klageRapportFilterFormState: state.forms.klageRapportFilterForm,
    ileggelsestyper: ileggelsestyper,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getIleggelsestyper: () => dispatch(getIleggelsestyper()),
    getKlageLedelseRapport: (params) =>
      dispatch(getKlageLedelseRapport(params)),
    ResetKlageLedelseRapport: () => dispatch(ResetKlageLedelseRapport()),
    handleChange: (variableName, value) =>
      dispatch(
        handleChangeAction("KLAGERAPPORT_FILTER_FORM", variableName, value)
      ),
    handleChangeToInitState: () =>
      dispatch(handleChangeToInitStateAction("KLAGERAPPORT_FILTER_FORM")),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KlageRapporterFilterContainer);
