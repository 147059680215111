import { connect } from "react-redux";

import MiljokontrollKommentarer from "./MiljokontrollKommentarer";

import { getMiljokontrollKommentarer } from "../../../../actions/miljokontrollerActions";

const mapStateToProps = (state) => {
  return {
    miljokontrollKommentarer: state.miljokontrollKommentarer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMiljokontrollKommentarer: (miljokontrollId) =>
    dispatch(getMiljokontrollKommentarer(miljokontrollId)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(MiljokontrollKommentarer);
