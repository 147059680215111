import React from "react";
import PropTypes from "prop-types";

import GridTable from "../../../Common/GridTable";
import DetaljPageHeader from "../../../Common/Detalj/DetaljPageHeader";
import MakulerMiljokontrollContainer from "./MakulerMiljokontrollContainer";
import OpphevMakuleringMiljokontrollContainer from "./OpphevMakuleringMiljokontrollContainer";
import EndreMiljokontrollContainer from "./EndreMiljokontrollContainer";
import MiljokontrollKommentarerContainer from "./MiljokontrollKommentarerContainer";
import BilderContainer from "./BilderContainer";

import { authorized } from "../../../../utils/authorizationUtils";
import { bypatruljen } from "../../../../constants/roles";
import { tidskontrollDetalj as tidskontrollDetaljTitle } from "../../../../constants/titles";
import Historikk from "./Historikk";

class MiljokontrollDetalj extends React.Component {
  static propTypes = {
    computedMatch: PropTypes.object,
    miljokontroll: PropTypes.object,
    miljokontrollGridTableRows: PropTypes.arrayOf(
      PropTypes.arrayOf(PropTypes.object)
    ),
    getMiljokontroll: PropTypes.func,
  };

  componentDidMount = () => {
    const { computedMatch, getMiljokontroll } = this.props;
    document.title = tidskontrollDetaljTitle("");
    window.scrollTo(0, 0);
    const id = computedMatch && computedMatch.params && computedMatch.params.id;
    getMiljokontroll(id);
  };

  componentDidUpdate = () => {
    const { miljokontroll } = this.props;
    document.title =
      miljokontroll && miljokontroll.nummer
        ? tidskontrollDetaljTitle(miljokontroll.nummer)
        : tidskontrollDetaljTitle("");
  };

  render() {
    const { computedMatch, miljokontroll, miljokontrollGridTableRows } =
      this.props;
    if (!miljokontroll || !miljokontrollGridTableRows) {
      return <></>;
    }

    const nummer = miljokontroll.nummer ? miljokontroll.nummer.toString() : "";
    const id = computedMatch && computedMatch.params && computedMatch.params.id;

    return (
      <>
        <DetaljPageHeader
          pageName="Tidskontroll"
          pageId={nummer}
          isMakulert={miljokontroll.deaktivertDato}
          makulertTekst="Lukket"
        />
        {authorized([
          bypatruljen.saksbehandler,
          bypatruljen.saksbehandleradmin,
        ]) &&
          miljokontroll &&
          miljokontroll.deaktivertDato &&
          !miljokontroll.miljogebyrId && (
            <section
              style={{
                float: "right",
                marginLeft: 50,
                marginTop: 0,
                marginRight: 50,
                marginBottom: 20,
              }}
            >
              <OpphevMakuleringMiljokontrollContainer />
            </section>
          )}
        {authorized([
          bypatruljen.saksbehandler,
          bypatruljen.saksbehandleradmin,
        ]) &&
          miljokontroll &&
          !miljokontroll.deaktivertDato && (
            <section
              style={{
                float: "right",
                marginLeft: 50,
                marginTop: 0,
                marginRight: 50,
                marginBottom: 20,
              }}
            >
              <MakulerMiljokontrollContainer />
              <EndreMiljokontrollContainer />
            </section>
          )}
        <section>
          <GridTable
            name="detaljMiljokontroll"
            rows={miljokontrollGridTableRows}
          />
          <div
            className="detalj-ileggelse-kommentarerer"
            style={{ marginTop: 15, marginLeft: 50 }}
          >
            <h5>Internkommentar</h5>
            <p>{miljokontroll.kommentar || "-"}</p>
          </div>
        </section>
        <section>
          <MiljokontrollKommentarerContainer miljokontrollId={id} />
        </section>
        <section
          style={{ paddingRight: 50, paddingBottom: 50, paddingLeft: 50 }}
        >
          <BilderContainer id={id} />
        </section>
        <section
          style={{ paddingRight: 50, paddingBottom: 50, paddingLeft: 50 }}
        >
          <Historikk miljoKontrollId={id} />
        </section>
      </>
    );
  }
}

export default MiljokontrollDetalj;
