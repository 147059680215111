import { connect } from "react-redux";

import BilderAndObservasjonsbilder from "./BilderAndObservasjonsbilder";

import {
  getMiljogebyr,
  getMiljogebyrBilder,
  putMiljogebyrerBilder,
  deleteMiljogebyrerBilder,
  getMiljogebyrerHistorikk,
} from "../../../../actions/miljogebyrerActions";
import {
  getMiljokontrollBilder,
  putMiljokontrollObservasjonBilde,
  deleteMiljokontrollObservasjonBilde,
} from "../../../../actions/miljokontrollerActions";

const mapStateToProps = (state, ownProps) => ({
  miljogebyr: state.miljogebyr,
  miljogebyrBilder: state.miljogebyrBilder[ownProps.miljogebyrId],
  miljokontrollerThumbnailBilder: state.miljokontrollerThumbnailBilder,
  miljokontrollerLightboxBilder: state.miljokontrollerLightboxBilder,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getMiljogebyr: (miljogebyrId) => dispatch(getMiljogebyr(miljogebyrId)),
  getMiljogebyrBilder: (miljogebyrId, params) =>
    dispatch(getMiljogebyrBilder(miljogebyrId, params)),
  putMiljogebyrerBilder: (miljogebyrId, bildeId, payload) =>
    dispatch(putMiljogebyrerBilder(miljogebyrId, bildeId, payload)),
  deleteMiljogebyrerBilder: (miljogebyrId, bildeId) =>
    dispatch(deleteMiljogebyrerBilder(miljogebyrId, bildeId)),
  deleteMiljokontrollObservasjonBilde: (
    bildeId,
    miljokontrollId,
    observasjonId,
    getAllAfterUpdateSuccess
  ) =>
    dispatch(
      deleteMiljokontrollObservasjonBilde(
        bildeId,
        miljokontrollId,
        observasjonId,
        getAllAfterUpdateSuccess
      )
    ),
  getMiljokontrollBilder: (miljokontrollId, params) =>
    dispatch(getMiljokontrollBilder(miljokontrollId, params)),
  putMiljokontrollObservasjonBilde: (
    bildeId,
    miljokontrollId,
    observasjonId,
    payload
  ) =>
    dispatch(
      putMiljokontrollObservasjonBilde(
        bildeId,
        miljokontrollId,
        observasjonId,
        payload
      )
    ),
  getMiljogebyrerHistorikk: (miljogebyrId) =>
    dispatch(getMiljogebyrerHistorikk(miljogebyrId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BilderAndObservasjonsbilder);
