import {
  handleChangeActionType,
  handleChangeToInitStateActionType,
} from "../../actions/handleChangeAction";

export const KLAGE_ILEGGELSER_FILTER_FORM = "KLAGE_ILEGGELSER_FILTER_FORM";

export const INITIAL_STATE = {
  saksnummer: "",
  kobleTilP360: false,
};

export default function KlageIleggelseFilerFormReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case handleChangeActionType(KLAGE_ILEGGELSER_FILTER_FORM):
      return { ...state, [action.variableName]: action.payload };
    case handleChangeToInitStateActionType(KLAGE_ILEGGELSER_FILTER_FORM):
      return INITIAL_STATE;
    default:
      return state;
  }
}
