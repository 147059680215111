import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Row } from "react-bootstrap";
import { toNorwegianDatetime } from "../../utils/datetimeUtils";

const PrintOutTable = ({
  title,
  queryParameters,
  tableHeaders,
  tableRows,
  renderQueryParameters,
  renderTableTd,
  getTdClassName,
}) => (
  <div className="print-container">
    <h1>{title}</h1>
    <section className="query-parameter-section">
      <Row>
        {renderQueryParameters(queryParameters)}
        <div
          className="pull-right printed-date-time"
          style={{ paddingRight: 20 }}
        >
          <div>Skrevet ut:</div>
          <div>
            {toNorwegianDatetime(moment().toISOString()).format(
              "DD.MM.YY HH.mm"
            )}
          </div>
        </div>
      </Row>
    </section>
    <section>
      <div className="pull-right number-of-table-rows-container">
        {tableRows.length === 0 || tableRows.length > 1 ? (
          <React.Fragment>
            <span className="number-of-table-rows">{tableRows.length}</span>{" "}
            ileggelser
          </React.Fragment>
        ) : (
          <React.Fragment>
            <span className="number-of-table-rows">{tableRows.length}</span>{" "}
            ileggelse
          </React.Fragment>
        )}
      </div>
      <div className="clearfix" />
      <table>
        <thead>
          <tr>
            {tableHeaders &&
              tableHeaders.map((tableHeader) => (
                <th key={tableHeader}>{tableHeader}</th>
              ))}
          </tr>
        </thead>
        <tbody>
          {tableRows &&
            tableRows.map((tableRow, index1) => (
              <tr key={`tableTbodyTr${index1}`}>
                {tableRow.data.map((tableTd, index2) => (
                  <td
                    className={getTdClassName(tableRow.metadata)}
                    key={`tableTbodyTr${index1}tableTbodyTrTd${index2}`}
                  >
                    {renderTableTd(tableTd)}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </section>
  </div>
);

PrintOutTable.propTypes = {
  queryParameters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]).isRequired,
    })
  ).isRequired,
  tableHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
  tableRows: PropTypes.arrayOf(
    PropTypes.shape({
      metadata: PropTypes.shape({}),
      data: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      ),
    })
  ).isRequired,
  renderQueryParameters: PropTypes.func.isRequired,
  renderTableTd: PropTypes.func,
  getTdClassName: PropTypes.func,
};

export default PrintOutTable;
