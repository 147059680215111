import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";

import VentilstillingFilterForm from "./VentilstillingFilterForm";

import { getVentilstillinger } from "../../../actions/ventilstillingerActions";
import {
  handleChange as handleChangeAction,
  handleChangeAll as handleChangeAllAction,
  handleChangeToInitState as handleChangeToInitStateAction,
} from "../../../actions/handleChangeAction";
import { INITIAL_STATE as VentilstillingFilterFormStateInitial } from "../../../reducers/forms/VentilstillingFilterFormReducer";
import { handleFromToDateTime } from "../../../utils/fromToDateTimeUtils";
import * as inputValidation from "../../../utils/inputValidationUtils";

import { toIsoString } from "../../../utils/datetimeUtils";

class VentilstillingFilterContainer extends Component {
  static propTypes = {
    VentilstillingFilterFormState: PropTypes.object,
    handleChange: PropTypes.func,
    getBybetjentGrupper: PropTypes.func,
    getVentilstillinger: PropTypes.func,
  };

  state = {
    error: "",
    shallGetVentilstillingDueToHandleChangeToInitState: false,
    showSokSpinner: false,
  };

  componentDidUpdate() {
    if (
      JSON.stringify(this.props.VentilstillingFilterFormState) ===
        JSON.stringify(VentilstillingFilterFormStateInitial) &&
      this.state.shallGetVentilstillingDueToHandleChangeToInitState === true
    ) {
      this.setState({
        shallGetVentilstillingDueToHandleChangeToInitState: false,
      });
    }
  }

  handleChange = (e) => {
    const { VentilstillingFilterFormState } = this.props;
    if (e.target.name === "fraDato" || e.target.name === "tilDato") {
      handleFromToDateTime(
        e,
        VentilstillingFilterFormState,
        this.props.handleChange
      );
      return;
    }
    this.props.handleChange(e.target.name, e.target.value);
  };

  handleChangeToInitState = () => {
    this.props.handleChangeToInitState();
    this.setState({ shallGetVentilstillingDueToHandleChangeToInitState: true });
  };

  resetValidationErrorText() {
    this.props.handleChange("fraDatoValidationErrorText", null);
    this.props.handleChange("tilDatoValidationErrorText", null);
  }

  validate() {
    return inputValidation.fraDateTimeTilDateTimeValidation(
      this.props.VentilstillingFilterFormState,
      this.props.handleChange
    );
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { VentilstillingFilterFormState, handleChangeAll } = this.props;
    this.resetValidationErrorText();
    if (this.validate()) {
      handleChangeAll("RAPPORT_VENTILSTILLING_SUBMITTED_FORM", {
        fraDato: VentilstillingFilterFormState.fraDato,
        tilDato: VentilstillingFilterFormState.tilDato,
        submitTimestamp: moment(),
        kjennemerke: VentilstillingFilterFormState.kjennemerke,
      });
      this.setState(() => ({ showSokSpinner: true }));
      const params = {
        fraDato: toIsoString(VentilstillingFilterFormState.fraDato),
        tilDato: toIsoString(VentilstillingFilterFormState.tilDato),
        kjennemerke: VentilstillingFilterFormState.kjennemerke,
      };
      this.props
        .getVentilstillinger(params)
        .then(() => this.setState(() => ({ showSokSpinner: false })))
        .catch(() => this.setState(() => ({ showSokSpinner: false })));
    }
  };

  render() {
    let { VentilstillingFilterFormState } = this.props;
    const { showSokSpinner } = this.state;
    return (
      <VentilstillingFilterForm
        showSokSpinner={showSokSpinner}
        VentilstillingFilterFormState={VentilstillingFilterFormState}
        handleChange={this.handleChange}
        handleChangeToInitState={this.handleChangeToInitState}
        handleSubmit={this.handleSubmit}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  ventilstillinger: state.ventilstillinger,
  VentilstillingFilterFormState: state.forms.VentilstillingFilterForm,
});

const mapDispatchToProps = (dispatch) => {
  return {
    handleChange: (variableName, value) =>
      dispatch(
        handleChangeAction("VENTILSTILLING_FILTER_FORM", variableName, value)
      ),
    handleChangeAll: (reducerName, payload) =>
      dispatch(handleChangeAllAction(reducerName, payload)),
    handleChangeToInitState: () =>
      dispatch(handleChangeToInitStateAction("VENTILSTILLING_FILTER_FORM")),
    getVentilstillinger: (filters) => dispatch(getVentilstillinger(filters)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VentilstillingFilterContainer);
