import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";

const FileInput = ({
  name,
  buttonText,
  handleFileInputChange,
  invisibleFileInputProps,
}) => {
  const invisibleFileInputRef = useRef(null);

  const handleInvisibleFileInputClick = (invisibleFileInputButton) => {
    invisibleFileInputButton.click();
  };

  return (
    <div>
      <div style={{ marginTop: 10 }}>
        <Button
          className="btn btn-bym-standard"
          onClick={() =>
            handleInvisibleFileInputClick(invisibleFileInputRef.current)
          }
        >
          {buttonText}
        </Button>
        <input
          name={name}
          type="file"
          multiple={!!invisibleFileInputProps.multiple}
          accept={invisibleFileInputProps.accept}
          style={{ display: "none" }}
          onChange={handleFileInputChange}
          ref={invisibleFileInputRef}
        />
      </div>
    </div>
  );
};

FileInput.propTypes = {
  name: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  handleFileInputChange: PropTypes.func,
  invisibleFileInputProps: PropTypes.shape({
    accept: PropTypes.string.isRequired,
    multiple: PropTypes.bool,
  }).isRequired,
};

export default FileInput;
