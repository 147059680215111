import { User } from "oidc-client-ts";
import { oidcConfig } from "../constants/api";

export const getUser = () => {
  const oidcStorage = localStorage.getItem(
    `oidc.user:${oidcConfig.authority}:${oidcConfig.client_id}`
  );

  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
};

export const getUsername = () => {
  const user = getUser();
  if (user) {
    return (
      user.profile.params[0].bym_id ?? user.profile.email ?? user.profile.name
    );
  }
  return undefined;
};
