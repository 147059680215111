import React from "react";
import PropTypes from "prop-types";
import "./Checkbox.css";

const Checkbox = ({ text, label, id, name, value, onChange, checked }) => {
  const handleChange = (e) => {
    const event = {
      target: { name: name, value: e.target.checked, type: e.target.type },
    };
    onChange(event);
  };

  return (
    <div className="checkbox-container">
      {label && <div id="checkboxTitle">{label}</div>}
      <input
        id={id}
        type="checkbox"
        name={name}
        value={value}
        checked={checked}
        onChange={handleChange}
      />
      <label htmlFor={id}>{text}</label>
    </div>
  );
};

Checkbox.propTypes = {
  text: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
  ]),
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
  ]),
};
export default Checkbox;
