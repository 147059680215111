import React, { Component } from "react";
import PropTypes from "prop-types";
import TextInput from "../Common/Forms/TextInput";
import FormButtonsForCloseAndSubmit from "../Common/Forms/FormButtonsForCloseAndSubmit";
import DropDownListSearch from "../Common/Forms/DropdownListSearchable";
import DatetimePicker from "../Common/Forms/DatetimePickerEnhanced";

export default class MiljogebyrFilterForm extends Component {
  static propTypes = {
    showSokSpinner: PropTypes.bool,
    InnsynMiljogebyrFilterFormState: PropTypes.object,
    handleChange: PropTypes.func,
    onSubmit: PropTypes.func,
    handleChangeToInitState: PropTypes.func,
    overtredelseskoderMiljogebyr: PropTypes.array,
  };

  getOvertredelseskoderMiljogebyr(id) {
    for (
      let index = 0;
      index < this.props.overtredelseskoderMiljogebyr.length;
      index++
    ) {
      const overtredelseskoder = this.props.overtredelseskoderMiljogebyr[index];
      if (id === overtredelseskoder.id) {
        return overtredelseskoder.paragraf;
      }
    }
  }
  render() {
    const {
      showSokSpinner,
      InnsynMiljogebyrFilterFormState,
      overtredelseskoderMiljogebyr,
      handleChange,
      handleBlur,
      onSubmit,
      handleChangeToInitState,
    } = this.props;
    return (
      <form className="SearchInnsynForm">
        <div className="SearchFilterOverskrift">Søk etter miljogebyrer</div>
        <div className="filter-flexbox-container innsyn-miljogebyrsok-filter-flexbox-item">
          <TextInput
            id="ileggelsesnummer"
            name="ileggelsesnummer"
            label="Ileggelsesnummer"
            value={InnsynMiljogebyrFilterFormState.ileggelsesnummer}
            onChange={handleChange}
            handleBlur={handleBlur}
            validationError={
              InnsynMiljogebyrFilterFormState.validationError.ileggelsesnummer
            }
          />
          <DatetimePicker
            id="fraDato"
            name="fraDato"
            locale="nb"
            label="Dato fra"
            placeholder="Velg"
            onChange={handleChange}
            value={InnsynMiljogebyrFilterFormState.fraDato}
            validationError={
              InnsynMiljogebyrFilterFormState.fraDatoValidationErrorText
            }
            timeFormat={false}
          />
          <DatetimePicker
            id="fraTid"
            name="fraTid"
            locale="nb"
            label="Tid fra"
            placeholder="Velg"
            onChange={handleChange}
            value={InnsynMiljogebyrFilterFormState.fraTid}
            validationError={
              InnsynMiljogebyrFilterFormState.fraTidValidationErrorText
            }
            dateFormat={false}
          />
          <DatetimePicker
            id="tilDato"
            name="tilDato"
            locale="nb"
            label="Dato til"
            placeholder="Velg"
            onChange={handleChange}
            value={InnsynMiljogebyrFilterFormState.tilDato}
            validationError={
              InnsynMiljogebyrFilterFormState.tilDatoValidationErrorText
            }
            timeFormat={false}
          />
          <DatetimePicker
            id="tilTid"
            name="tilTid"
            locale="nb"
            label="Tid til"
            placeholder="Velg"
            onChange={handleChange}
            value={InnsynMiljogebyrFilterFormState.tilTid}
            validationError={
              InnsynMiljogebyrFilterFormState.tilTidValidationErrorText
            }
            dateFormat={false}
          />
        </div>
        <div
          className="filter-flexbox-container innsyn-miljogebyrsok-filter-flexbox-item"
          style={{ marginTop: 15 }}
        >
          <TextInput
            id="gatenavn"
            name="gatenavn"
            label="Gatenavn/Ved"
            value={InnsynMiljogebyrFilterFormState.gatenavn}
            onChange={handleChange}
          />
          <TextInput
            id="gatenummer"
            name="gatenummer"
            label="Gatenummer/Ved nr."
            value={InnsynMiljogebyrFilterFormState.gatenummer}
            onChange={handleChange}
          />
          <DropDownListSearch
            label="Overtredelseskode"
            name="overtredelseskode"
            title={
              this.props.InnsynMiljogebyrFilterFormState.overtredelse
                ? this.getOvertredelseskoderMiljogebyr(
                    this.props.InnsynMiljogebyrFilterFormState.overtredelse
                  )
                : "Velg"
            }
            placeholder="Søk etter kode"
            items={
              overtredelseskoderMiljogebyr &&
              [{ id: "", navn: "Velg", beskrivelse: "" }].concat(
                overtredelseskoderMiljogebyr.map((ok) => {
                  return {
                    id: ok.id,
                    navn: ok.id.toString(),
                    beskrivelse: ok.paragraf + " - " + ok.kortTekst,
                  };
                })
              )
            }
            selected={
              this.props.InnsynMiljogebyrFilterFormState.overtredelseskode
            }
            handleChange={handleChange}
          />
        </div>
        <div style={{ marginTop: 5 }}>
          <FormButtonsForCloseAndSubmit
            showSubmitSpinner={showSokSpinner}
            submitText="Søk"
            onSubmit={onSubmit}
            onClose={handleChangeToInitState}
          />
        </div>
      </form>
    );
  }
}
