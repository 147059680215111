import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import FilterFormContainer from "./FilterFormCotnainer";
import PrintOut from "../../Common/PrintOut";
import ListTotalContainer from "./ListTotalContainer";
import ListContainer from "./ListContainer";
import LoadButton from "../../Common/LoadButton";
import { authorized } from "../../../utils/authorizationUtils";

import sitePaths from "../../../constants/sitePaths";
import { miljogebyr as miljogebyrTitles } from "../../../constants/titles";
import { bypatruljen } from "../../../constants/roles";

class TilBehandling extends React.Component {
  static propTypes = {
    miljogebyrTilBehandlingItems: PropTypes.arrayOf(PropTypes.object),
    miljogebyrTilBehandlingFilterForm: PropTypes.object,
    getMiljogebyrTilBehandling: PropTypes.func.isRequired,
    getAllMiljogebyrEttersendelsebreverPdf: PropTypes.func.isRequired,
  };

  state = {
    checkedMiljogebyrTilGodkjenningSkrivUt: {
      ..._.reduce(
        this.props.miljogebyrTilBehandlingItems
          .filter((m) => m.ettersendelse !== null)
          .map((m) => {
            return { [m.ettersendelse.id]: false };
          }),
        _.extend
      ),
      checkboxAllMiljogebyrTilGodkjenningSkrivUt: false,
    },
    showSendTilSvarUtSpinner: false,
    max: 1,
  };

  componentDidMount() {
    const { getMiljogebyrTilBehandling } = this.props;
    document.title = miljogebyrTitles.tilBehandling;
    getMiljogebyrTilBehandling();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      JSON.stringify(nextProps.miljogebyrTilBehandlingItems) !==
      JSON.stringify(this.props.miljogebyrTilBehandlingItems)
    ) {
      this.setState({
        checkedMiljogebyrTilGodkjenningSkrivUt: {
          ..._.reduce(
            nextProps.miljogebyrTilBehandlingItems
              .filter((m) => m.ettersendelse !== null)
              .map((m) => {
                return { [m.ettersendelse.id]: false };
              }),
            _.extend
          ),
          checkboxAllMiljogebyrTilGodkjenningSkrivUt: false,
        },
      });
    }
  }

  handleSendTilSvarUtCheckboxChange = (event) => {
    if (event.target.name === "checkboxAllMiljogebyrTilGodkjenningSkrivUt") {
      let keys = Object.keys(this.state.checkedMiljogebyrTilGodkjenningSkrivUt);
      let checkedMiljogebyrTilGodkjenningSkrivUtArray = keys.map((k) => {
        return { [k]: event.target.checked };
      });
      this.setState({
        checkedMiljogebyrTilGodkjenningSkrivUt: _.reduce(
          checkedMiljogebyrTilGodkjenningSkrivUtArray,
          _.extend
        ),
      });
    } else {
      this.setState({
        checkedMiljogebyrTilGodkjenningSkrivUt: {
          ...this.state.checkedMiljogebyrTilGodkjenningSkrivUt,
          [event.target.name]: event.target.checked,
        },
      });
    }
  };

  getEttersendelseId = (miljogebyrId) => {
    const { miljogebyrTilBehandlingItems } = this.props;
    const miljogebyrTilBehandling = miljogebyrTilBehandlingItems.find(
      (mtbi) => mtbi.id === miljogebyrId
    );

    if (miljogebyrTilBehandling && miljogebyrTilBehandling.ettersendelse) {
      return miljogebyrTilBehandling.ettersendelse.id;
    }
    return null;
  };

  checkedEttersendelserArray = () => {
    let keys = Object.keys(this.state.checkedMiljogebyrTilGodkjenningSkrivUt);
    let checkedMiljogebyrEttersendelserTilGodkjenningSkrivUtArray = [];
    for (let index = 0; index < keys.length; index++) {
      const ettersendelseId = keys[index];
      if (
        ettersendelseId !== "checkboxAllEttersendelserTilGodkjenning" &&
        this.state.checkedMiljogebyrTilGodkjenningSkrivUt[ettersendelseId]
      ) {
        checkedMiljogebyrEttersendelserTilGodkjenningSkrivUtArray.push(
          ettersendelseId
        );
      }
    }
    return checkedMiljogebyrEttersendelserTilGodkjenningSkrivUtArray;
  };
  postIleggelseEttersendelsesbrevBatchJob = () => {
    const { getAllMiljogebyrEttersendelsebreverPdf } = this.props;
    const { max } = this.state;
    const checkedEttersendelserArray = this.checkedEttersendelserArray();
    const slicedEttersendelseArray = checkedEttersendelserArray.slice(0, max);
    this.setState(() => ({ showSendTilSvarUtSpinner: true }));
    const params = {
      ettersendelseId: slicedEttersendelseArray,
    };
    getAllMiljogebyrEttersendelsebreverPdf(params)
      .then(() => {
        this.setState(() => ({ showSendTilSvarUtSpinner: false }));
        this.props.getMiljogebyrTilBehandling();
      })
      .catch(() => this.setState(() => ({ showSendTilSvarUtSpinner: false })));
  };

  showSendTilSvarUtButton = () => {
    const { miljogebyrTilBehandlingItems } = this.props;
    const { checkedMiljogebyrTilGodkjenningSkrivUt } = this.state;

    let checkedMiljogebyrTilGodkjenningObjectArray =
      miljogebyrTilBehandlingItems.filter((m) => {
        let checkedMiljogebyrTilGodkjenning = [];
        Object.keys(checkedMiljogebyrTilGodkjenningSkrivUt).forEach((key) => {
          if (checkedMiljogebyrTilGodkjenningSkrivUt[key]) {
            checkedMiljogebyrTilGodkjenning.push(key);
          }
        });
        return checkedMiljogebyrTilGodkjenning.some(
          (cmtg) => m.ettersendelse && cmtg === m.ettersendelse.id
        );
      });
    if (
      checkedMiljogebyrTilGodkjenningObjectArray &&
      checkedMiljogebyrTilGodkjenningObjectArray.length !== 0 &&
      checkedMiljogebyrTilGodkjenningObjectArray.every(
        (cmtgo) =>
          cmtgo.isTilEttersendelse && !cmtgo.isMakulert && !cmtgo.isBetalt
      )
    ) {
      return true;
    }
    return false;
  };

  render() {
    const {
      showSendTilSvarUtSpinner,
      checkedMiljogebyrTilGodkjenningSkrivUt,
      max,
    } = this.state;

    const array = this.checkedEttersendelserArray();
    const size = array.length;

    return (
      <div>
        <FilterFormContainer />
        <div style={{ height: 28 }} />
        <div className="pull-left" style={{ marginLeft: 50 }}>
          {this.showSendTilSvarUtButton() &&
          authorized([bypatruljen.saksbehandler, bypatruljen.saksbehandleradmin, bypatruljen.seksjonssjefStab]) && (
            <div style={{ display: "inline-block", marginRight: 10 }}>
              <LoadButton
                isLoading={showSendTilSvarUtSpinner}
                buttonProps={{
                  className: "btn btn-bym-standard btn-bym-medium",
                  onClick: this.postIleggelseEttersendelsesbrevBatchJob,
                  disabled: showSendTilSvarUtSpinner,
                }}
                spinnerProps={{ pColor: "#ffffff", sColor: "#4d4d4d" }}
              >
                Send til Svar ut
              </LoadButton>
            </div>
          )}
          <PrintOut
            url={`${window.location.origin}${sitePaths.miljogebyrer.tilBehandling.utskrift}`}
            dataToStoreInSessionStorage={{
              queryData: {
                ...this.props.miljogebyrTilBehandlingFilterForm,
              },
              paginationData: {
                page: this.props.page,
                count: this.props.count,
              },
            }}
          />
          <div className="red" style={{ marginTop: 10 }}>
            {size > max
              ? `Antall brev pr. kjøring er begrenset til ${max}`
              : ""}
          </div>
        </div>
        <ListTotalContainer />
        <div className="clearfix" />
        <div style={{ height: 22 }} />
        <ListContainer
          checkedMiljogebyrTilGodkjenningSkrivUt={
            checkedMiljogebyrTilGodkjenningSkrivUt
          }
          handleSendTilSvarUtCheckboxChange={
            this.handleSendTilSvarUtCheckboxChange
          }
        />
      </div>
    );
  }
}

export default TilBehandling;
