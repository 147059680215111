import { connect } from "react-redux";

import AntallIleggelserPerIleggelsetypeFilterForm from "./AntallIleggelserPerIleggelsetypeFilterForm";

import {
  handleChange as handleChangeAction,
  handleChangeAll as handleChangeAllAction,
  handleChangeToInitState as handleChangeToInitStateAction,
} from "../../../actions/handleChangeAction";
import { getAntallIleggelserPerIleggelsetype as getAntallIleggelserPerIleggelsetypeAction } from "../../../actions/rapporter/antallIleggelserPerIleggelsetypeActions";

const mapStateToProps = (state) => ({
  antallIleggelserPerIleggelsetypeFilterForm:
    state.forms.antallIleggelserPerIleggelsetypeFilterForm,
});

const mapDispatchToProps = (dispatch) => ({
  handleChange: (variableName, value) =>
    dispatch(
      handleChangeAction(
        "RAPPORT_ANTALL_ILEGGELSER_PER_ILEGGELSETYPE_FILTER_FORM",
        variableName,
        value
      )
    ),
  handleChangeAll: (reducerName, payload) =>
    dispatch(handleChangeAllAction(reducerName, payload)),
  handleChangeToInitState: (actionType) =>
    dispatch(handleChangeToInitStateAction(actionType)),
  getAntallIleggelserPerIleggelsetype: (params) =>
    dispatch(getAntallIleggelserPerIleggelsetypeAction(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AntallIleggelserPerIleggelsetypeFilterForm);
