import { CreateNewReducer } from "../utils/reducerUtils";
import { KLAGE_MILJOKONTROLL_LIGHTBOX_IMAGE } from "../actions/klager/klageAction";

const INITIAL_STATE = [];

export const klageMiljoKontrollLightboxImageReducer = (
  state = INITIAL_STATE,
  action
) => CreateNewReducer(state, action, KLAGE_MILJOKONTROLL_LIGHTBOX_IMAGE);

export default klageMiljoKontrollLightboxImageReducer;
