import { ILEGGELSE_SERVICE_BASE_URL } from "../config";

// - Uregistrerte kontroller
export const GET_UREGISTRERTEKONTROLLER_URL = `${ILEGGELSE_SERVICE_BASE_URL}/ureg_kontroller`;

export const GET_SINGLE_UREGISTRERTEKONTROLLER_URL = (id) =>
  `${GET_UREGISTRERTEKONTROLLER_URL}/${id}`;

export const GET_ALL_UREGISTRERTEKONTROLLER_OBSERVASJONSGRUPPER_BILDER_URL = (id) => 
  `${GET_UREGISTRERTEKONTROLLER_URL}/${id}/observasjonsgrupper/bilder`;
