import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";

import NavigationBarContainer from "../NavigationBarContainer";
import KlagerapportFilterContainer from "./KlagerapportFilterContainer";
import KlagerapportLedelseList from "./KlagerapportLedelse";
import { isEmpty, orderBy } from "lodash";

class KlagerapportPage extends Component {
  static propTypes = {
    klagerapportledelse: PropTypes.object,
  };

  initSelectedIleggelsestyper = this.props.selectedIleggelsestyper;

  state = {
    selectedIleggelsestyper: this.initSelectedIleggelsestyper,
  };

  updateSelectedIleggelsestyperState = (value) => {
    this.setState({ selectedIleggelsestyper: value });
  };

  sorter = (arr) => {
    const { klageRapportFilterFormState } = this.props;
    const selectedSort = klageRapportFilterFormState.selectedSort;
    const splitt = selectedSort.split("_");
    const key = splitt[0];
    const order = splitt[1];
    if (isEmpty(arr)) return [];
    return orderBy(arr, [key], [order]);
  };

  render() {
    const { klagerapport } = this.props;
    const { selectedIleggelsestyper } = this.state;

    const bydelerArr = this.sorter(klagerapport.stedDimensionList);
    const overtredelseKodeArr = this.sorter(
      klagerapport.overtredelseDimensionList
    );

    return (
      <Container fluid>
        <Row>
          <Col>
            {<NavigationBarContainer page="klager" />}
            <div className="rapportSearch">
              <KlagerapportFilterContainer
                updateSelectedIleggelsestyperState={
                  this.updateSelectedIleggelsestyperState
                }
              />
            </div>
            <div className="rapport-table">
              <KlagerapportLedelseList
                klagerapport={klagerapport}
                bydelerArr={bydelerArr}
                overtredelseKodeArr={overtredelseKodeArr}
                selectedIleggelsestyper={selectedIleggelsestyper}
              />
            </div>
          </Col>
          <div style={{ height: 28 }} />
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    klagerapport: state.klagerapport,
    klageRapportFilterFormState: state.forms.klageRapportFilterForm,
    selectedIleggelsestyper:
      state.forms.klageRapportFilterForm &&
      state.forms.klageRapportFilterForm.selectedIleggelsestyper &&
      state.forms.klageRapportFilterForm.selectedIleggelsestyper.map(
        (sit) => sit.navn
      ),
  };
};

export default connect(mapStateToProps, null)(KlagerapportPage);
