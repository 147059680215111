// Only works with positive integers as string or number
export const numberWithSpace = (number) => {
  var reg = /^\d+$/;
  if (
    (typeof number != "string" && typeof number != "number") ||
    !reg.test(number)
  ) {
    return "";
  }

  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const isCamelCase = (str) => {
  return /^[a-z]+(?:[A-Z][a-z]*)*$/.test(str);
}

export const camelCaseVariableNameToText = (variableName) => {
    if (!isCamelCase) {
      return undefined;
    }

    const words = variableName.split(/(?=[A-Z])/);
  
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    
    return capitalizedWords.join(' ');
}