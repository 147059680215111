import axios from "../services/axios";
import * as api from "../constants/api";
import { setTotal as setTotalAction } from "./advancedListActions";
import { addFlashMessage } from "./FlashMessagesAction";

import * as apiResponseUtils from "../utils/apiResponseUtils";
import { toIsoString } from "../utils/datetimeUtils";

export const GET_PIGGDEKKONTROLLER_SUCCESS = "GET_PIGGDEKKONTROLLER_SUCCESS";

export function getPiggdekkontroller(params, page, count) {
  return (dispatch) => {
    return axios
      .get(api.GET_PIGGDEKKONTROLLER_URL, {
        params: {
          ...params,
          fraDato: toIsoString(params.fraDato),
          tilDato: toIsoString(params.tilDato),
          page: page,
          pageSize: count,
        },
      })
      .then((response) => {
        const total = JSON.parse(response.headers["x-pagination"]).Total;
        dispatch(setTotalAction("piggdekkontroller", total));
        dispatch({
          type: GET_PIGGDEKKONTROLLER_SUCCESS,
          payload: response.data.result,
        });
        return Promise.resolve(true);
      })
      .catch((error) => {
        const errorMessage = apiResponseUtils.errorData(error);
        dispatch(addFlashMessage({ type: "error", text: errorMessage }));
        return Promise.reject(false);
      });
  };
}
