import React, { useEffect } from "react";

import NavigationBarContainer from "../NavigationBarContainer";
import AntallIleggelserPerIleggelsetypeFilterFormContainer from "./AntallIleggelserPerIleggelsetypeFilterFormContainer";
import AntallIleggelserPerIleggelsetypeExcel from "./AntallIleggelserPerIleggelsetypeExcel";
import AntallIleggelserPerIleggelsetypeList from "./AntallIleggelserPerIleggelsetypeList";
import PrintOut from "../../Common/PrintOut";

import * as title from "../../../constants/titles";

const AntallIleggelserPerIleggelsetype = ({
  antallIleggelserPerIleggelsetypeSubmittedForm,
  antallIleggelserPerIleggelsetypeRapport,
}) => {
  useEffect(() => {
    document.title = title.rapporter.antallIleggelserPerIleggelsetype;
  }, []);

  return (
    <div>
      <NavigationBarContainer page="antall-ileggelser-per-ileggelsetype" />
      <AntallIleggelserPerIleggelsetypeFilterFormContainer />
      <div className="rapport-buttons-container">
        <div className="no-print">
          <div style={{ display: "inline-block", marginRight: 10 }}>
            <PrintOut />
          </div>
          <div style={{ display: "inline-block" }}>
            <AntallIleggelserPerIleggelsetypeExcel
              antallIleggelserPerIleggelsetypeSubmittedForm={
                antallIleggelserPerIleggelsetypeSubmittedForm
              }
              antallIleggelserPerIleggelsetypeRapport={
                antallIleggelserPerIleggelsetypeRapport
              }
            />
          </div>
        </div>
      </div>
      <div className="rapport-table">
        <AntallIleggelserPerIleggelsetypeList
          antallIleggelserPerIleggelsetypeRapport={
            antallIleggelserPerIleggelsetypeRapport
          }
          antallIleggelserPerIleggelsetypeSubmittedForm={
            antallIleggelserPerIleggelsetypeSubmittedForm
          }
        />
      </div>
    </div>
  );
};

export default AntallIleggelserPerIleggelsetype;
