import axios from "../services/axios";
import * as api from "../constants/api";
import * as handle from "../utils/actionUtils";

export const GET_ILEGGELSE_RETTELSER_SUCCESS =
  "GET_ILEGGELSE_RETTELSER_SUCCESS";
export const GET_ILEGGELSE_RETTELSER_FAILURE =
  "GET_ILEGGELSE_RETTELSER_FAILURE";

export function getIleggelseRettelser(ileggelseId) {
  return async (dispatch) => {
    return axios
      .get(api.GET_ILEGGELSE_RETTELSER_URL(ileggelseId))
      .then(handle.success(dispatch, GET_ILEGGELSE_RETTELSER_SUCCESS))
      .catch(handle.error(dispatch, GET_ILEGGELSE_RETTELSER_FAILURE));
  };
}
