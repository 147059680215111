import { createSelector } from "reselect";

const ileggelseRettelser = state => state.ileggelseRettelser;
const bybetjenter = state => state.bybetjenter;

export const getIleggelseRettelser = createSelector(
  [ileggelseRettelser, bybetjenter],
  (ileggelseRettelser, bybetjenter) => {
    return ileggelseRettelser.map(ileggelseRettelse => {
      let bybetjent = bybetjenter.find(
        bybetjent => bybetjent.id === ileggelseRettelse.betjentId
      );
      return {
        ...ileggelseRettelse,
        tjenestenummer: bybetjent && bybetjent.nummer
      };
    });
  }
);

export default getIleggelseRettelser;
