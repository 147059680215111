import React from "react";
import Excel from "../../Common/Excel";

import { formatDateTimeHavingNorwegianDateTime } from "../../../utils/datetimeUtils";

const getExcelSheets = (aarsrapport, aarsrapportSubmittedForm) => {
  let submitTimestamp = "";
  if (aarsrapportSubmittedForm) {
    submitTimestamp = formatDateTimeHavingNorwegianDateTime(
      aarsrapportSubmittedForm.submitTimestamp
    );
  }

  let sheets = [
    {
      name: "Søkdata",
      data: [
        {
          År: aarsrapportSubmittedForm.aar,
          Ileggelsestyper:
            aarsrapportSubmittedForm.ileggelsestypeNavn &&
            aarsrapportSubmittedForm.ileggelsestypeNavn.toString(),
          "Timestamp for søk": submitTimestamp,
        },
      ],
    },
  ];

  if (
    aarsrapport &&
    aarsrapport.miljogebyr &&
    aarsrapport.miljogebyr.length > 0
  ) {
    sheets.unshift({
      name: "Årsrapport Miljøgebyr",
      data:
        aarsrapport.miljogebyr &&
        aarsrapport.miljogebyr.map((monthReport, index) => ({
          Måned: monthReport.maaned,
          "Total antall": monthReport.totalAntall,
          Klagesaker: monthReport.klagesaker,
          "Antall frafall": monthReport.antallFrafall,
          Klageprosent: monthReport.klageprosent,
          Frafallsprosent: monthReport.frafallsprosent,
        })),
    });
  }
  if (
    aarsrapport &&
    aarsrapport.ileggelser &&
    aarsrapport.ileggelser.length > 0
  ) {
    sheets.unshift({
      name: "Årsrapport Ileggelser",
      data:
        aarsrapport.ileggelser &&
        aarsrapport.ileggelser.map((monthReport, index) => ({
          Måned: monthReport.maaned,
          "Total antall": monthReport.totalAntall,
          Klagesaker: monthReport.klagesaker,
          "Antall frafall": monthReport.antallFrafall,
          Klageprosent: monthReport.klageprosent,
          Frafallsprosent: monthReport.frafallsprosent,
        })),
    });
  }

  return sheets;
};

const AArsrapportExcel = ({ aarsrapport, aarsrapportSubmittedForm }) => {
  return (
    <Excel
      element={(onClick) => (
        <button className="btn btn-bym-medium btn-bym-action" onClick={onClick}>
          Lag rapport
        </button>
      )}
      filename={`Årsrapport ${aarsrapportSubmittedForm.aar}.xlsx`}
      sheets={getExcelSheets(aarsrapport, aarsrapportSubmittedForm)}
    />
  );
};

export default AArsrapportExcel;
