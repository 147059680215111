import * as axiosUtils from "../../utils/axiosUtils";
import * as api from "../../constants/api";

export const actionTypeUbetalteIleggelserKjennemerkeGroups =
  "UBETALTE_ILEGGELSER_KJENNEMERKE_GROUPS";

export const maxRows = 500;

export const getUbetalteIleggelser = (params) => {
  return axiosUtils.GetAllWithParamsSerializer(
    api.GET_UREGISTRERTE_ILEGGELSER_KJENNEMERKE_GROUPS_URL,
    {
      ileggelseType: params.ileggelseType,
      nasjon: params.nasjon,
      sort: params.sort,
      sortOrder: params.sortOrder,
      minBelop: 6000,
      maxRows: maxRows,
    },
    actionTypeUbetalteIleggelserKjennemerkeGroups
  );
};

export const actionTypeUbetalteIleggelserSingleKjennemerke =
  "UBETALTE_ILEGGELSER_SINGLE_KJENNEMERKE";

export const getSingleUbetalteIleggelser = (params) => {
  return axiosUtils.GetAllWithParamsSerializer(
    api.GET_UREGISTRERTE_ILEGGELSER_SINGLE_KJENNEMERKE_URL,
    {
      kjoretoyId: params.kjoretoyId,
      ileggelseType:
        params.ileggelseType === "undefined" ? null : params.ileggelseType,
      sort: params.sort,
      sortOrder: params.sortOrder,
    },
    actionTypeUbetalteIleggelserSingleKjennemerke
  );
};
