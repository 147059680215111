import React from "react";
import PropTypes from "prop-types";

import Table from "../../../Common/Tables/Table";

import { toNorwegianDatetime } from "../../../../utils/datetimeUtils";

// Miljøkontroll har endret navn til tidskontroll
class MiljokontrollKommentarer extends React.Component {
  componentDidMount = () => {
    const { miljokontrollId, getMiljokontrollKommentarer } = this.props;

    getMiljokontrollKommentarer(miljokontrollId);
  };

  renderRow = (miljokontrollKommentar) => {
    if (!miljokontrollKommentar) {
      return null;
    }

    const dato =
      miljokontrollKommentar.datoMottatt &&
      toNorwegianDatetime(miljokontrollKommentar.datoMottatt).format(
        "DD.MM.YYYY HH:mm"
      );

    return (
      <tr>
        <td>{dato}</td>
        <td>{miljokontrollKommentar.kommentar}</td>
      </tr>
    );
  };

  render() {
    const { miljokontrollKommentarer } = this.props;
    return (
      <section>
        <div className="detalj-ileggelse-header-wrapper">
          <h2>Tidskontrollkommentarer</h2>
        </div>
        <div className="detalj-ileggelse-table-wrapper">
          <Table renderRow={this.renderRow} items={miljokontrollKommentarer}>
            <th>Dato</th>
            <th>Kommentar</th>
          </Table>
        </div>
      </section>
    );
  }
}

MiljokontrollKommentarer.propTypes = {
  miljokontrollId: PropTypes.string.isRequired,
  miljokontrollKommentarer: PropTypes.arrayOf(PropTypes.shape({})),
  getMiljokontrollKommentarer: PropTypes.func,
};

export default MiljokontrollKommentarer;
