import * as axiosUtils from "../utils/axiosUtils";
import * as api from "../constants/api";

export const actionType = "MILJOGEBYR_KOMMENTARER";

export const getAllMiljogebyrKommentarer = (miljogebyrId) => {
  const url = api.GET_ALL_MILJOGEBYR_KOMMENTARER_URL(miljogebyrId);
  return axiosUtils.GetAll(url, actionType);
};

export const getSingleMiljogebyrKommentar = (miljogebyrId, KommentarId) => {
  const url = api.GET_SINGLE_MILJOGEBYR_KOMMENTAR_URL(
    miljogebyrId,
    KommentarId
  );
  return axiosUtils.GetOne(url, actionType);
};

export const createMiljogebyrKommentar = (miljogebyrId, payload) => {
  const url = api.CREATE_MILJOGEBYR_KOMMENTAR_URL(miljogebyrId);
  return axiosUtils.Post(url, payload);
};

export const updateMiljogebyrKommentar = (
  miljogebyrId,
  KommentarId,
  payload
) => {
  const url = api.UPDATE_MILJOGEBYR_KOMMENTAR_URL(miljogebyrId, KommentarId);
  return axiosUtils.Put(url, payload, actionType);
};
