import {
  handleChangeActionType,
  handleChangeAllActionType,
  handleChangeToInitStateActionType,
} from "../../actions/handleChangeAction";
import _ from "lodash";

export const INITIAL_STATE = {
  aktorerAktivStatus: true,
  overtredelseskoderIleggelserAktivStatus: true,
  overtredelseskoderMiljogebyrAktivStatus: true,
  internkoderIleggelserAktivStatus: true,
  internkoderMiljogebyrAktivStatus: true,
  vedtakskoderAktivStatus: true,
};

export default function grunndataFilterFormReducer(
  state = {
    ...INITIAL_STATE,
  },
  action
) {
  switch (action.type) {
    case handleChangeActionType("GRUNNDATA_FILTER_FORM"):
      _.set(state, action.variableName, action.payload);
      return { ...state };
    case handleChangeAllActionType("GRUNNDATA_FILTER_FORM"):
      return { ...action.payload };
    case handleChangeToInitStateActionType("GRUNNDATA_FILTER_FORM"):
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
}
