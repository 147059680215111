import * as api from "../constants/api";
import axios from "../services/axios";
import * as axiosUtils from "../utils/axiosUtils";

import { popUpBlocked } from "../constants/ErrorMessages";
import { errorData } from "../utils/apiResponseUtils";
import { addFlashMessage } from "./FlashMessagesAction";

export function putForsinketMakuleringMal(payload) {
  let formData = new FormData();
  formData.append("ForsinketMakuleringFile", payload.forsinketMakuleringFile);

  return axiosUtils.Put(
    api.PUT_FORSINKET_MAKULERING_MAL_URL,
    formData,
    null,
    {
      getOne: null,
      entitet: "Forsinket_Makulering_Mal",
      mergeObj: null,
      notify: true,
    },
    { headers: { "content-type": "multipart/form-data" } }
  );
}

export function getForsinketMakuleringMalPreview() {
  return async (dispatch) => {
    return axios
      .get(api.GET_FORSINKET_MAKULERING_MAL_PREVIEW_URL)
      .then((response) => {
        var byteCharacters = window.atob(response.data.result.base64_PDF);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var blob = new Blob([byteArray], { type: "application/pdf" });
        let tabName = "E-Brev.pdf";
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE workaround
          window.navigator.msSaveOrOpenBlob(blob, tabName);
        } else {
          window.URL = window.URL || window.webkitURL;
          var blobUrl = window.URL.createObjectURL(blob);
          let pdfWindow = window.open("");
          if (
            !pdfWindow ||
            pdfWindow.closed ||
            typeof pdfWindow.closed === "undefined"
          ) {
            throw new Error(popUpBlocked);
          } else {
            pdfWindow.document.write(
              "<iframe width='100%' height='100%' src='" +
                blobUrl +
                "' frameborder='0' allowfullscreen></iframe>"
            );
            pdfWindow.document.title = tabName;
          }
        }
        return Promise.resolve(true);
      })
      .catch((error) => {
        let allowDangerouslySetInnerHtml = false;
        let errorMessage = "";
        if (!error.response && error.message) {
          allowDangerouslySetInnerHtml = true;
          errorMessage = error.message;
        } else {
          errorMessage = errorData(error);
        }
        dispatch(
          addFlashMessage({
            type: "error",
            text: errorMessage,
            allowDangerouslySetInnerHtml,
          })
        );
        Promise.reject(false);
      });
  };
}

export function getForsinketMakuleringMalDocx() {
  return async (dispatch) => {
    return axios
      .get(api.GET_FORSINKET_MAKULERING_MAL_DOCX_URL)
      .then((response) => {
        var byteCharacters = window.atob(response.data.result.base64);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });
        let tabName = "E-Brev.pdf";
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE workaround
          window.navigator.msSaveOrOpenBlob(blob, tabName);
        } else {
          var windowUrl = window.URL || window.webkitURL;
          var blobUrl = windowUrl.createObjectURL(blob);

          var a = document.createElement("a");
          a.style.display = "none";

          if (typeof a.download === "undefined") {
            window.location = blobUrl;
          } else {
            a.href = blobUrl;
            a.download = response.data.result.fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();
          }
        }
        return Promise.resolve(true);
      })
      .catch((error) => {
        let allowDangerouslySetInnerHtml = false;
        let errorMessage = "";
        if (!error.response && error.message) {
          allowDangerouslySetInnerHtml = true;
          errorMessage = error.message;
        } else {
          errorMessage = errorData(error);
        }
        dispatch(
          addFlashMessage({
            type: "error",
            text: errorMessage,
            allowDangerouslySetInnerHtml,
          })
        );
        Promise.reject(false);
      });
  };
}
