import moment from "moment";
import { toNorwegianDatetime } from "../utils/datetimeUtils";
import { createSelector } from "reselect";

const ileggelse = (state) => state.ileggelse;

export const getGridTableRowData = createSelector(ileggelse, (ileggelse) => {
  return [
    [
      {
        name: "Registrert i HGK",
        value: moment(toNorwegianDatetime(ileggelse.opprettetDato)).format(
          "DD.MM.YYYY HH:mm"
        ),
        description: "",
      },
      {
        name: "Opprettet i Pocket Control",
        value: moment(toNorwegianDatetime(ileggelse.ileggelsesdatoFra)).format(
          "DD.MM.YYYY HH:mm"
        ),
        description: "",
      },
    ],
  ];
});

export default getGridTableRowData;
