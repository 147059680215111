import { normalize } from "normalizr";

export function success(
  dispatch,
  type,
  schema = null,
  meta = {},
  firstItem = false
) {
  return response => {
    // Try get etag from header
    if (response.headers.etag) meta.etag = response.headers.etag;

    if (response.status === 304) {
      dispatch({ type: type, not_modified: true, meta: meta });
      return;
    }

    let result = response.data.result;
    if (firstItem) result = result.length > 0 ? result[0] : {};
    if (schema != null) {
      result = normalize(result, schema);
    }
    dispatch({ type: type, payload: result, meta: meta });
    return Promise.resolve(result);
  };
}

export function error(dispatch, type) {
  return error => {
    dispatch({ type: type, error: true, payload: { ...error } });
    return Promise.reject(error);
  };
}
