import axios from "../services/axios";

import { addFlashMessage } from "./FlashMessagesAction";
import { setTotal as setTotalAction } from "./advancedListActions";
import { errorData } from "../utils/axiosUtils";
import { toIsoString } from "../utils/datetimeUtils";

import * as api from "../constants/api";
import { MaVelgeFiltrering } from "../constants/ErrorMessages";

export const GET_ELSPARKSYKKELGEBYR_SUCCESS = "GET_ELSPARKSYKKELGEBYR_SUCCESS";
export const GET_ELSPARKSYKKELGEBYR_FAILURE = "GET_ELSPARKSYKKELGEBYR_FAILURE";

export function getElsparksykkelGebyr(params, page, count) {
  return async (dispatch) => {
    dispatch(setTotalAction("elsparksykkelgebyr", 0));
    dispatch({
      type: GET_ELSPARKSYKKELGEBYR_SUCCESS,
      payload: [],
    });

    if (
      !params.ileggelsesnummer &&
      !params.tjenestenummer &&
      !params.fraDato &&
      !params.tilDato &&
      !params.aktorId
    ) {
      dispatch(addFlashMessage({ type: "error", text: MaVelgeFiltrering }));

      return Promise.reject();
    }

    return axios
      .get(api.GET_ILEGGELSER_URL, {
        params: {
          aktorId: params.aktorId,
          ileggelsesnummer: params.ileggelsesnummer,
          tjenestenummer: params.tjenestenummer,
          fraDato: params.fraDato && toIsoString(params.fraDato),
          tilDato: params.tilDato && toIsoString(params.tilDato),
          isElsparksykkelGebyr: params.isElsparksykkelGebyr,
          isMakulert: params.isMakulert,
          include: params.include,

          orderBy: "descending",
          page: page,
          pageSize: count,
        },
        paramsSerializer: {
          indexes: null,
        },
      })
      .then((response) => {
        dispatch(
          setTotalAction(
            "elsparksykkelGebyr",
            JSON.parse(response.headers["x-pagination"]).Total
          )
        );
        dispatch({
          type: GET_ELSPARKSYKKELGEBYR_SUCCESS,
          payload: response.data.result,
        });
        return Promise.resolve(response);
      })
      .catch((error) => {
        let errorMessage = errorData(error);
        dispatch(addFlashMessage({ type: "error", text: errorMessage }));
        return Promise.reject(error);
      });
  };
}
