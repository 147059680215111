import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ModalGeneric from "../../../Common/ModalGeneric";
import UpdateOpenModal from "../../CreateModals/UpdateOpenModal";
import RettekoderForm from "../../RettekoderForm";

import { putRettekode } from "../../../../actions/rettekoderActions";
import {
  IsValidated,
  onChangeValidation,
} from "../../../../utils/validationUtils";

export class RettekoderModal extends React.Component {
  static propTypes = {
    rettekode: PropTypes.shape({
      id: PropTypes.string.isRequired,
      kode: PropTypes.string.isRequired,
      navn: PropTypes.string.isRequired,
    }),
  };
  get initState() {
    return {
      open: false,
      submitButtonText: "Lagre",
      id: this.props.rettekode.id,
      kode: this.props.rettekode.kode,
      navn: this.props.rettekode.navn,
      validationError: {},
    };
  }
  rules = {
    kode: "required;minLength=1;maxLength=50",
    navn: "required;minLength=1;maxLength=50",
  };
  state = this.initState;

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (JSON.stringify(nextProps) !== JSON.stringify(this.props)) {
      this.setState({
        kode: nextProps.rettekode.kode,
        navn: nextProps.rettekode.navn,
      });
    }
  };

  closeModal = () => {
    this.setState(this.initState);
  };

  openModal = () => {
    this.setState({ open: true });
  };

  isValid = () => {
    const { isValid, validationError } = IsValidated(this.state, this.rules);
    this.setState({ validationError });
    return isValid;
  };

  handlePut = () => {
    this.setState({ submitButtonText: "Lagrer..." });

    this.props
      .putRettekode({
        id: this.state.id,
        kode: this.state.kode,
        navn: this.state.navn,
      })
      .then(() => {
        this.closeModal();
      })
      .catch(() =>
        this.setState({ submitButtonText: this.initState.submitButtonText })
      );
  };

  saveAndClose = () => {
    if (this.isValid()) {
      this.handlePut();
    }
  };

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
    onChangeValidation(e, this.state, this.rules);
  };

  render() {
    const { submitButtonText } = this.state;
    return (
      <>
        <ModalGeneric
          modalTitle={"Rediger rettekode"}
          open={this.state.open}
          closeModal={this.closeModal}
          openModal={this.openModal}
          OpenModalComponent={UpdateOpenModal}
          submit={this.saveAndClose}
          submitButtonText={submitButtonText}
        >
          <RettekoderForm
            kode={this.state.kode}
            navn={this.state.navn}
            handleChange={this.handleChange}
            validationError={this.state.validationError}
            actionType="update"
          />
        </ModalGeneric>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(
    {
      putRettekode,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(RettekoderModal);
