import { createSelector } from "reselect";

const miljogebyr = state => state.miljogebyr;
const mottakertyper = state => state.mottakertyper;

export const getGridTableRowData = createSelector(
  [miljogebyr, mottakertyper],
  (miljogebyr, mottakertyper) => {
    let mottakertype =
      miljogebyr &&
      miljogebyr.mottakertype &&
      mottakertyper.find(
        mottakertype => mottakertype.id === miljogebyr.mottakertype
      );

    let adresse = "";
    if (miljogebyr.mottakerGate) {
      adresse = miljogebyr.mottakerGate + " ";
    }
    if (miljogebyr.mottakerGateNummer) {
      adresse = adresse + miljogebyr.mottakerGateNummer;
    }
    let untattOffentlighet = miljogebyr.untattOffentlighet ? "Ja" : "Nei";
    return [
      [
        {
          name: "Navn på mottaker",
          value: miljogebyr.mottaker,
          description: ""
        },
        {
          name: "Adresse",
          value: adresse,
          description: ""
        },
        { name: "", value: "", description: "" },
        { name: "", value: "", description: "" },
        {
          name: "Postnummer",
          value: miljogebyr.mottakerPostnummer,
          description: ""
        },
        {
          name: "Poststed",
          value: miljogebyr.mottakerPostSted,
          description: ""
        }
      ],
      [
        {
          name: "Gårdsnummer",
          value: miljogebyr && miljogebyr.gnr && `${miljogebyr.gnr}`,
          description: ""
        },
        {
          name: "Bruksnummer",
          value: miljogebyr && miljogebyr.bnr && `${miljogebyr.bnr}`,
          description: ""
        },
        {
          name: "Type mottaker",
          value: mottakertype && mottakertype.navn,
          description: ""
        },
        {
          name: "Fødselsdato/ org.nr.",
          value: miljogebyr.mottakerId,
          description: ""
        },
        {
          name: "Unntatt offentlighet",
          value: untattOffentlighet,
          description: ""
        },
        { name: "", value: "", description: "" }
      ]
    ];
  }
);

export default getGridTableRowData;
