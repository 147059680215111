import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BilderButtons from "../../Common/Detalj/BilderButtons";

import {
  getIleggelse,
  postIleggelserBilder,
  getIleggelseHistorikk,
} from "../../../actions/ileggelserActions";

class BilderButtonsContainer extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    getIleggelse: PropTypes.func.isRequired,
    postIleggelserBilder: PropTypes.func.isRequired,
    reloadHistorikk: PropTypes.func,
  };

  handleLeggTilBilderClick(invisibleFileInputRef) {
    invisibleFileInputRef.click();
  }

  handleFileInputChange(fileArray) {
    let postIleggelserBilderArray = [];
    for (let index = 0; index < fileArray.length; index++) {
      postIleggelserBilderArray.push(
        this.props.postIleggelserBilder(
          this.props.id,
          fileArray[index.toString()]
        )
      );
    }
    Promise.all(postIleggelserBilderArray)
      .then(() => {
        this.props.getIleggelse(this.props.id);
      })
      .then(() => this.props.getIleggelseHistorikk(this.props.id))
      .catch();
  }

  render() {
    return (
      <BilderButtons
        handleFileInputChange={this.handleFileInputChange.bind(this)}
        handleLeggTilBilderClick={this.handleLeggTilBilderClick}
      />
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(
    { getIleggelse, postIleggelserBilder, getIleggelseHistorikk },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(BilderButtonsContainer);
