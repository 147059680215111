import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import _ from "lodash";
import moment from "moment";

import { NavigationBarContainer } from "../NavigationBarContainer";
import UferdigstilteKlagesakerFilterForm from "./UferdigstilteKlagesakerFilterForm";
import UferdigstilteKlagesakerExcel from "./UferdigstilteKlagesakerExcel";
import InformationButton from "../../Common/InformationButton";
import Table from "../../Common/Tables/Table";
import HeaderCell from "../../Common/Tables/HeaderCell";
import Spinner from "../../Common/Spinner";

import { toNorwegianDatetime } from "../../../utils/datetimeUtils";
import * as title from "../../../constants/titles";

const getUferdigstilteKlagesaker = (
  getVedtaker,
  dispatchUferdigstilteKlagesaker,
  setIsLoading,
  saksbehandler,
  handleChangeAll
) =>
  Promise.all([
    getVedtaker({
      OpprettetDatoFra: moment().subtract(1, "week").startOf("day").format(),
      HasVedtaksDato: false,
    }),
    getVedtaker({
      OpprettetDatoFra: moment().subtract(4, "weeks").startOf("day").format(),
      HasVedtaksDato: true,
      KlagekodeId: 1,
      IsLatestVedtakInKlage: true,
    }),
    getVedtaker({
      OpprettetDatoFra: moment().subtract(8, "weeks").startOf("day").format(),
      HasVedtaksDato: true,
      KlagekodeId: 11,
      IsLatestVedtakInKlage: true,
    }),
  ])
    .then((responseArray) => {
      let uferdigstilteKlagesakerArray = [];
      for (let i = 0; i < responseArray.length; i++) {
        const response = responseArray[i];
        uferdigstilteKlagesakerArray = [
          ...uferdigstilteKlagesakerArray,
          ...response,
        ];
      }
      setIsLoading(false);

      handleChangeAll("RAPPORT_UFERDIGSTILTE_KLAGESAKER_SUBMITTED_FORM", {
        submitTimestamp: moment(),
        saksbehandler: saksbehandler,
      });
      dispatchUferdigstilteKlagesaker(uferdigstilteKlagesakerArray);
    })
    .catch(() => {
      setIsLoading(false);
      handleChangeAll("RAPPORT_UFERDIGSTILTE_KLAGESAKER_SUBMITTED_FORM", {
        submitTimestamp: "",
        saksbehandler: "",
      });
    });

const getItems = (uferdigstilteKlagesaker) =>
  uferdigstilteKlagesaker && uferdigstilteKlagesaker.items;

const getCellKey = (uferdigstilteKlagesaker) =>
  uferdigstilteKlagesaker &&
  uferdigstilteKlagesaker.sort &&
  uferdigstilteKlagesaker.sort.cellKey;

const getOrders = (uferdigstilteKlagesaker) =>
  uferdigstilteKlagesaker &&
  uferdigstilteKlagesaker.sort &&
  uferdigstilteKlagesaker.sort.orders;

const renderRow = (item) => {
  return (
    <tr>
      <td>{item && item.saksnummer}</td>
      <td>{item && item.vedtakskode}</td>
      <td>
        {item && item.vedtaksDato
          ? toNorwegianDatetime(item.vedtaksDato).format("DD.MM.YYYY")
          : ""}
      </td>
      <td>{item && item.ileggelsesnummer}</td>
      <td>{item && item.klagekode}</td>
      <td>{item && item.saksbehandler}</td>
    </tr>
  );
};

const UferdigstilteKlagesaker = ({
  getVedtaker,
  dispatchUferdigstilteKlagesaker,
  handleChangeAll,
  setSort,
  uferdigstilteKlagesaker,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [saksbehandler, setSaksbehandler] = useState("");
  const [showInformation, setShowInformation] = useState(false);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    document.title = title.rapporter.uferdigstilteKlagesaker;
    window.addEventListener("resize", resizeEventListener);

    return function cleanUp() {
      window.removeEventListener("resize", resizeEventListener);
    };
  }, []);

  const resizeEventListener = () => {
    setInnerWidth(window.innerWidth);
  };

  useEffect(() => {
    setSort({
      cellKey: "saksnummer",
      orders: "asc",
    });
    getUferdigstilteKlagesaker(
      getVedtaker,
      dispatchUferdigstilteKlagesaker,
      setIsLoading,
      saksbehandler,
      handleChangeAll
    );
  }, [
    setSort,
    getVedtaker,
    dispatchUferdigstilteKlagesaker,
    saksbehandler,
    handleChangeAll,
  ]);

  const uferdigstilteKlagesakerItems = getItems(uferdigstilteKlagesaker);
  const uferdigstilteKlagesakerCellKey = getCellKey(uferdigstilteKlagesaker);
  const uferdigstilteKlagesakerOrders = getOrders(uferdigstilteKlagesaker);

  useEffect(() => {
    if (
      uferdigstilteKlagesaker &&
      uferdigstilteKlagesaker.items &&
      uferdigstilteKlagesaker.sort &&
      uferdigstilteKlagesaker.sort.orders &&
      uferdigstilteKlagesaker.sort.cellKey
    ) {
      setItems(
        _.orderBy(
          [...uferdigstilteKlagesaker.items],
          [uferdigstilteKlagesaker.sort.cellKey],
          [uferdigstilteKlagesaker.sort.orders]
        ).filter(
          (item) =>
            item.saksbehandler &&
            typeof item.saksbehandler === "string" &&
            item.saksbehandler.includes(saksbehandler)
        )
      );
    } else if (uferdigstilteKlagesaker && uferdigstilteKlagesaker.items) {
      setItems(
        uferdigstilteKlagesaker.items.filter(
          (item) =>
            item.saksbehandler &&
            typeof item.saksbehandler === "string" &&
            item.saksbehandler.includes(saksbehandler)
        )
      );
    } else {
      setItems([]);
    }
  }, [
    uferdigstilteKlagesaker,
    uferdigstilteKlagesakerItems,
    uferdigstilteKlagesakerCellKey,
    uferdigstilteKlagesakerOrders,
    saksbehandler,
  ]);

  if (!isLoading) {
    let sort = uferdigstilteKlagesaker && uferdigstilteKlagesaker.sort;
    let numberOfItems = typeof items === "object" ? items.length : 0;
    return (
      <div>
        <NavigationBarContainer page="uferdigstilte-klagesaker" {...props} />
        <UferdigstilteKlagesakerFilterForm
          saksbehandler={saksbehandler}
          setSaksbehandler={setSaksbehandler}
        />
        <div className="rapport-buttons-container">
          <UferdigstilteKlagesakerExcel
            uferdigstilteKlagesakerSubmittedForm={
              props.uferdigstilteKlagesakerSubmittedForm
            }
            saksbehandler={saksbehandler}
            uferdigstilteKlagesaker={items}
          />
        </div>
        <div style={{ position: "relative" }}>
          {!showInformation && (
            <div
              className="pull-right ileggelse-list-page-total-ileggelser"
              style={
                innerWidth > 767
                  ? { position: "absolute", bottom: 10, right: 50 }
                  : {
                      position: "absolute",
                      bottom: 10,
                      right: 20,
                    }
              }
            >
              {numberOfItems}{" "}
              {innerWidth > 767 ? "uferdigstilte klagesaker" : "st"}
            </div>
          )}
          <h3
            style={
              innerWidth > 767
                ? {
                    display: "inline-block",
                    fontSize: 16,
                    fontWeight: "bold",
                    marginLeft: 50,
                  }
                : {
                    display: "inline-block",
                    fontSize: 16,
                    fontWeight: "bold",
                    marginLeft: 20,
                  }
            }
          >
            Uferdigstilte klagesaker{" "}
            <InformationButton
              onClick={() => setShowInformation(!showInformation)}
            />
          </h3>
          <div className="clearfix" />
        </div>
        {showInformation && (
          <div style={{ position: "relative" }}>
            <div
              className="pull-right ileggelse-list-page-total-ileggelser"
              style={
                innerWidth > 767
                  ? { position: "absolute", bottom: 0, right: 50 }
                  : { position: "absolute", bottom: 0, right: 20 }
              }
            >
              {numberOfItems}{" "}
              {innerWidth > 767 ? "uferdigstilte klagesaker" : "st"}
            </div>
            <div
              style={
                innerWidth > 767
                  ? { marginLeft: 50, paddingRight: 250 }
                  : { marginLeft: 20, paddingRight: 250 }
              }
            >
              <i>Følgende saker listes i denne rapporten:</i>
              <br />
              <ol>
                <i>
                  <li>
                    <b>Klagesaker uten vedtak:</b> Saker som har påbegynte
                    vedtak som er mer enn 7 dager gamle og som fortsatt ikke er
                    ferdigstilte
                  </li>
                  <li>
                    <b>
                      Ubehandlet klagesak hvor vi har sendt ut foreløpig svar:
                    </b>{" "}
                    Saker hvor siste vedtak gir klagekode 1 (klage mottatt) og
                    dette vedtaket ble satt for mer enn 4 uker siden
                  </li>
                  <li>
                    <b>Venter svar fra klagenemnda: </b> Saker hvor siste vedtak
                    gir klagekode 11 (sendt klagenemnda) og dette vedtaket ble
                    satt for mer enn 8 uker siden
                  </li>
                </i>
              </ol>
            </div>
          </div>
        )}
        <div className="rapport-table">
          <div
            className="table-wrapper-horizontal-scroll"
            style={{ marginTop: 10 }}
          >
            <Table renderRow={renderRow} items={items}>
              <HeaderCell cellKey="saksnummer" sort={sort} onSort={setSort}>
                Saksnummer
              </HeaderCell>
              <HeaderCell>Vedtakskode</HeaderCell>
              <HeaderCell cellKey="vedtaksDato" sort={sort} onSort={setSort}>
                Vedtaksdato
              </HeaderCell>
              <HeaderCell
                cellKey="ileggelsesnummer"
                sort={sort}
                onSort={setSort}
              >
                Ileggelsesnr.
              </HeaderCell>
              <HeaderCell cellKey="klagekode" sort={sort} onSort={setSort}>
                Klagekode
              </HeaderCell>
              <HeaderCell>Saksbehandler</HeaderCell>
            </Table>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <NavigationBarContainer page="uferdigstilte-klagesaker" {...props} />
      <Spinner isLoading />
    </div>
  );
};

UferdigstilteKlagesaker.propTypes = {
  uferdigstilteKlagesaker: PropTypes.shape({
    sort: PropTypes.shape({
      cellKey: PropTypes.string,
      orders: PropTypes.string,
    }),
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  uferdigstilteKlagesakerSubmittedForm: PropTypes.shape({
    submitTimestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    saksbehandler: PropTypes.string,
  }).isRequired,
  setSort: PropTypes.func.isRequired,
  getVedtaker: PropTypes.func.isRequired,
  dispatchUferdigstilteKlagesaker: PropTypes.func.isRequired,
};

export default withRouter(UferdigstilteKlagesaker);
