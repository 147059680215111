import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { some } from "lodash";

import FilterForm from "./FilterForm";

import { getIleggelseleveringstyper as getIleggelseleveringstyperAction } from "../../../actions/ileggelseleveringstyperActions";
import { getIleggelsestyper as getIleggelsestyperAction } from "../../../actions/ileggelsestyperActions";
import {
  handleChange as handleChangeAction,
  handleChangeToInitState as handleChangeToInitStateAction,
} from "../../../actions/handleChangeAction";
import { getIleggelser as getIleggelserAction } from "../../../actions/ileggelserActions";
import { getVedtakskoder } from "../../../actions/vedtakskoderAction";
import { getKlageKoder } from "../../../actions/klagekoderAction";
import { getBybetjentGrupper as getBybetjentGrupperAction } from "../../../actions/bybetjentgrupperAction";

import { getSelectOptionsEnhanced } from "../../../selectors/selectOptionsSelector";
import {
  getSelectOptionKlagekoder,
  getDropDownListSearchVedtakskoder,
} from "../../../selectors/ileggelserSelector";

import { handleFromToDateTime } from "../../../utils/fromToDateTimeUtils";
import * as inputValidation from "../../../utils/inputValidationUtils";
import { IsValidated } from "../../../utils/validationUtils";
import { ileggelseStatuser } from "../../../constants/globals";

import "./IleggelserOversiktFilterContainer.css";
import { setPage as setPageAction } from "../../../actions/advancedListActions";
import { replaceWhitespacesWithEmptyStrings } from "../../../utils/sanitizationUtils";

import { authorized } from "../../../utils/authorizationUtils";
import { stedfortrederDriftsleder } from "../../../constants/roles";

class FilterContainer extends React.Component {
  static propTypes = {
    ileggelserOversiktFilterForm: PropTypes.object,
    overtredelseskoderIleggelser: PropTypes.array,
    ileggelsestyper: PropTypes.array,
    klagekoder: PropTypes.array,
    vedtakskoder: PropTypes.array,
    ileggelseleveringstyper: PropTypes.array,
    handleChange: PropTypes.func,
    getIleggelsestyper: PropTypes.func,
    getVedtakskoder: PropTypes.func,
    getKlageKoder: PropTypes.func,
    getBybetjentGrupper: PropTypes.func,
    getIleggelseleveringstyper: PropTypes.func,
    setPage: PropTypes.func.isRequired,
  };

  state = {};
  axiosCancelTokenSource = null;

  constructor(props) {
    super(props);
    this.state = {
      showSokSpinner: false,
    };
  }

  componentDidMount() {
    this.props.getIleggelsestyper();
    this.props.getIleggelseleveringstyper();
    if (!this.authorizedInspektorStedfrotreder) {
      this.props.getVedtakskoder();
      this.props.getKlageKoder();
    }
    //this.props.getBybetjentGrupper();  // dette krever også endring av tilganger i md-filen
  }

  authorizedInspektorStedfrotreder = authorized([stedfortrederDriftsleder]);

  rules = {
    ileggelsesnummer: "isPositiveInteger",
    tjenestenummer: "isPositiveInteger",
  };

  getPropsAsFakeState = () => {
    return {
      ileggelsesnummer:
        this.props.ileggelserOversiktFilterForm.ileggelsesnummer,
      tjenestenummer: this.props.ileggelserOversiktFilterForm.tjenestenummer,
      saksnummer: this.props.ileggelserOversiktFilterForm.saksnummer,
      validationError: {
        ileggelsesnummer:
          this.props.ileggelserOversiktFilterForm.validationError
            .ileggelsesnummer,
        tjenestenummer:
          this.props.ileggelserOversiktFilterForm.validationError
            .tjenestenummer,
      },
    };
  };

  handleChange = (e) => {
    const { ileggelserOversiktFilterForm } = this.props;

    if (
      e.target.name === "fraDato" ||
      e.target.name === "tilDato" ||
      e.target.name === "fraTid" ||
      e.target.name === "tilTid"
    ) {
      handleFromToDateTime(
        e,
        ileggelserOversiktFilterForm,
        this.props.handleChange
      );
      return;
    }

    if (
      e.target.name === "fraIneOverforingsdato" &&
      typeof e.target.value === "object"
    ) {
      this.props.handleChange(
        "fraIneOverforingsdato",
        e.target.value.startOf("day")
      );
    }

    if (
      e.target.name === "tilIneOverforingsdato" &&
      typeof e.target.value === "object"
    ) {
      this.props.handleChange(
        "tilIneOverforingsdato",
        e.target.value.endOf("day")
      );
    }

    if (e.target.name === "selectedStatuser") {
      ileggelseStatuser.forEach((ileggelseStatus) => {
        if (some(e.target.value, ileggelseStatus)) {
          this.props.handleChange(ileggelseStatus.id, true);
        } else {
          this.props.handleChange(ileggelseStatus.id, null);
        }
      });
    }

    this.props.handleChange(e.target.name, e.target.value);
  };

  handleBlur = (e) => {
    const { handleChange } = this.props;

    if (
      e.target.name === "ileggelsesnummer" ||
      e.target.name === "tjenestenummer" ||
      e.target.name === "saksnummer"
    ) {
      handleChange(
        e.target.name,
        replaceWhitespacesWithEmptyStrings(e.target.value)
      );
    }
  };

  handleChangeToInitState = () => {
    this.axiosCancelTokenSource && this.axiosCancelTokenSource.cancel();
    this.props.handleChangeToInitState();
  };

  isValid() {
    let propsAsFakeState = this.getPropsAsFakeState();
    const { isValid, validationError } = IsValidated(
      propsAsFakeState,
      this.rules
    );
    this.props.handleChange(
      "validationError.ileggelsesnummer",
      validationError.ileggelsesnummer
    );
    this.props.handleChange(
      "validationError.tjenestenummer",
      validationError.tjenestenummer
    );

    const fraDateTimeTilDateTimeValid =
      inputValidation.fraDateTimeTilDateTimeValidation(
        this.props.ileggelserOversiktFilterForm,
        this.props.handleChange
      );

    const fraOgTilIneOverforingsdatoValidationErrors =
      inputValidation.getDateTimeValidationError({
        fraDato: this.props.ileggelserOversiktFilterForm.fraIneOverforingsdato,
        tilDato: this.props.ileggelserOversiktFilterForm.tilIneOverforingsdato,
      });
    this.props.handleChange(
      "fraIneOverforingsdatoValidationErrorText",
      fraOgTilIneOverforingsdatoValidationErrors.fraDatoValidationErrorText
    );
    this.props.handleChange(
      "tilIneOverforingsdatoValidationErrorText",
      fraOgTilIneOverforingsdatoValidationErrors.tilDatoValidationErrorText
    );
    let fraOgTilOverforingsdatoIneValid = true;
    if (
      fraOgTilIneOverforingsdatoValidationErrors.fraDatoValidationErrorText ||
      fraOgTilIneOverforingsdatoValidationErrors.tilDatoValidationErrorText
    ) {
      fraOgTilOverforingsdatoIneValid = false;
    }

    return (
      isValid && fraDateTimeTilDateTimeValid && fraOgTilOverforingsdatoIneValid
    );
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { setPage } = this.props;
    if (this.isValid()) {
      this.setState(() => ({ showSokSpinner: true }));
      const page = 1;
      this.axiosCancelTokenSource = axios.CancelToken.source();
      this.props
        .getIleggelser(
          this.getIleggelserFilter(),
          page,
          this.props.ileggelser.count,
          this.axiosCancelTokenSource.token
        )
        .then(() => {
          this.setState(() => ({ showSokSpinner: false }));
          setPage(page);
        })
        .catch(() => this.setState(() => ({ showSokSpinner: false })));
    }
  };

  getIleggelserFilter() {
    let {
      fraTid,
      tilTid,
      fraDatoValidationErrorText,
      fraTidValidationErrorText,
      tilDatoValidationErrorText,
      tilTidValidationErrorText,
      status,
      ...filter
    } = this.props.ileggelserOversiktFilterForm;

    filter = {
      ...filter,
      fraDato: filter.fraDato && filter.fraDato.toISOString(),
      tilDato: filter.tilDato && filter.tilDato.toISOString(),
    };

    return filter;
  }

  componentWillUnmount() {
    this.axiosCancelTokenSource && this.axiosCancelTokenSource.cancel();
  }

  render() {
    const { showSokSpinner } = this.state;
    return (
      <div className="ileggelse-filter-container">
        <h1>Søk etter ileggelser</h1>
        <FilterForm
          showSokSpinner={showSokSpinner}
          ileggelseFilterFormState={this.props.ileggelserOversiktFilterForm}
          overtredelseskoderIleggelser={this.props.overtredelseskoderIleggelser}
          ileggelsestyper={this.props.ileggelsestyper}
          ileggelseleveringstyper={this.props.ileggelseleveringstyper}
          betjentgrupper={this.props.betjentgrupper}
          ileggelsesstatuser={ileggelseStatuser}
          klagekoder={this.props.klagekoder}
          vedtakskoder={this.props.vedtakskoder}
          handleChange={this.handleChange}
          handleBlur={this.handleBlur}
          handleChangeToInitState={this.handleChangeToInitState}
          handleSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let ileggelsestyper = getSelectOptionsEnhanced(
    state,
    "ileggelsestyper",
    "navn",
    "id"
  );
  ileggelsestyper = ileggelsestyper
    ? ileggelsestyper.filter(
        (ileggelsestyp) => ileggelsestyp.navn !== "Miljøgebyr"
      )
    : [];
  return {
    ileggelserOversiktFilterForm: state.forms.ileggelserOversiktFilterForm,
    overtredelseskoderIleggelser: state.overtredelseskoderIleggelser,
    ileggelsestyper: ileggelsestyper,
    ileggelseleveringstyper: getSelectOptionsEnhanced(
      state,
      "ileggelseleveringstyper",
      "navn",
      "id"
    ),
    betjentgrupper: getSelectOptionsEnhanced(
      state,
      "betjentgrupper",
      "navn",
      "id",
      {
        text: "Velg",
        value: "",
      }
    ),
    vedtakskoder: getDropDownListSearchVedtakskoder(state),
    klagekoder: getSelectOptionKlagekoder(state),
    ileggelser: state.ileggelser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getIleggelsestyper: () => dispatch(getIleggelsestyperAction()),
    getBybetjentGrupper: () => dispatch(getBybetjentGrupperAction()),
    getIleggelseleveringstyper: () =>
      dispatch(getIleggelseleveringstyperAction()),
    handleChange: (variableName, value) =>
      dispatch(
        handleChangeAction(
          "ILEGGELSER_OVERSIKT_FILTER_FORM",
          variableName,
          value
        )
      ),
    handleChangeToInitState: () =>
      dispatch(
        handleChangeToInitStateAction("ILEGGELSER_OVERSIKT_FILTER_FORM")
      ),
    getIleggelser: (ileggelserOversiktFilterForm, page, count, source) =>
      dispatch(
        getIleggelserAction(ileggelserOversiktFilterForm, page, count, source)
      ),
    getVedtakskoder: () => dispatch(getVedtakskoder()),
    getKlageKoder: () => dispatch(getKlageKoder()),
    setPage: (page) => dispatch(setPageAction("ileggelser", page)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterContainer);
