import * as api from "../constants/api";
import * as axiosUtils from "../utils/axiosUtils";

export const actionType = "INTERNKODER_ILEGGELSER";
const metaData = { entitet: "Internkode ileggelse", notify: true };

export function getInternkoderIleggelser() {
  return axiosUtils.GetAll(api.GET_INTERNKODER_ILEGGELSER_URL, actionType);
}

export function getSingleInternkodeIleggelse(id) {
  return axiosUtils.GetAll(
    api.GET_SINGLE_INTERNKODER_ILEGGELSER_URL(id),
    actionType
  );
}

export function postInternkoderIleggelser(payload) {
  return axiosUtils.Post(
    api.POST_INTERNKODER_ILEGGELSER_URL,
    payload,
    actionType,
    metaData
  );
}

export function putInternkoderIleggelser(id, payload) {
  return axiosUtils.Put(
    api.PUT_INTERNKODER_ILEGGELSER_URL(id),
    payload,
    actionType,
    metaData
  );
}
