import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Bilder from "../../Common/Detalj/Bilder";

import { getVentilstillingBilder as getVentilstillingBilderAction } from "../../../actions/ventilstillingerActions";

class VentilstillingsdetailBilderContainer extends React.Component {
  static propTypes = {
    ventilstillingId: PropTypes.string.isRequired,
    bilder: PropTypes.arrayOf(PropTypes.object),
    getVentilstillingBilder: PropTypes.func,
  };

  componentDidMount = () => {
    const { ventilstillingId, getVentilstillingBilder } = this.props;
    getVentilstillingBilder(ventilstillingId);
  };

  render() {
    const { ventilstillingId, images } = this.props;

    return <Bilder id={ventilstillingId} imageArray={images} />;
  }
}

const mapStateToProps = (state, ownProps) => ({
  images:
    state.ventilstillingerBilder &&
    state.ventilstillingerBilder[ownProps.ventilstillingId],
});

const mapDispatchToProps = (dispatch) => ({
  getVentilstillingBilder: (ventilstillingId) =>
    dispatch(getVentilstillingBilderAction(ventilstillingId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VentilstillingsdetailBilderContainer);
