import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";

import Gallery from "../Common/Gallery";

import { authorized } from "../../utils/authorizationUtils";
import { bypatruljen, juridisk } from "../../constants/roles";
import { ileggelsestyper } from "../../constants/globals";

const isAuthorizedForToastUi = () =>
  authorized([
    bypatruljen.saksbehandler,
    bypatruljen.saksbehandleradmin,
    juridisk.saksbehandler,
  ]);

const getBildeUrl = (bildeId, ileggelseId, ileggelsestypeId) => {
  return ileggelsestypeId === ileggelsestyper.miljogebyr
    ? `klage/miljogebyrer/${ileggelseId}/bilder/${bildeId}`
    : `klage/ileggelser/${ileggelseId}/bilder/${bildeId}`;
};

const getToastUiUrl = (bildeId, ileggelseId, ileggelsestypeId) => {
  return `${getBildeUrl(bildeId, ileggelseId, ileggelsestypeId)}/toastui`;
};

const BilderContainer = ({
  ileggelseId,
  ileggelsestypeId,
  thumbnailImages,
  lightboxImages,
}) => {
  if (isEmpty(thumbnailImages)) return null;
  return (
    <section className="detalj-ileggelse-section-margin">
      <div style={{ marginTop: 10 }}>
        <Gallery
          images={thumbnailImages}
          lightboxImages={lightboxImages}
          isAuthorizedForToastUi={isAuthorizedForToastUi()}
          getToastUiUrl={(bildeId) =>
            getToastUiUrl(bildeId, ileggelseId, ileggelsestypeId)
          }
          getBildeUrl={(bildeId) =>
            getBildeUrl(bildeId, ileggelseId, ileggelsestypeId)
          }
        />
      </div>
    </section>
  );
};

BilderContainer.propTypes = {
  isThumbAndLightboxImg: PropTypes.bool.isRequired,
  ileggelseId: PropTypes.string,
  ileggelsestypeId: PropTypes.number,
  thumbnailImages: PropTypes.arrayOf(PropTypes.object),
  lightboxImages: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = (state, ownProps) => {
  return {
    ileggelseId: state.klageIleggelse && state.klageIleggelse.id,
    ileggelsestypeId:
      state.klageIleggelse && state.klageIleggelse.ileggelsestypeId,
    thumbnailImages: ownProps.isThumbAndLightboxImg
      ? state.klageIleggelseThumbnailImage
      : state.klageIleggelseImage,
    lightboxImages: state.klageIleggelseLightboxImage,
  };
};

export default connect(mapStateToProps, null)(BilderContainer);
