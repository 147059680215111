import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import L from "leaflet";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import customIcon from "../../../images/marker-white.png";

import "./map.css";

const geocoder = new window.google.maps.Geocoder();
const osm_base_tileLayer_url =
  "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
const google_base_tileLayer_url =
  "https://maps.googleapis.com/maps/vt?pb=!1m5!1m4!1i{z}!2i{x}!3i{y}!4i256!2m3!1e0!2sm!3i349018013!3m9!2sen-US!3sUS!5e18!12m1!1e47!12m3!1e37!2m1!1ssmartmaps!4e0";

const style = {
  margin: "3px 13px 3px 3px",
  height: "100%",
  width: "100%",
  position: "absolute",
};

const MapView = ({ lat = 0, lng = 0, baseTile = "osm" }) => {
  const osloLat = 59.9139;
  const osloLong = 10.7522;

  const [address, setAddress] = useState("");
  const [baseTileUrl, setBaseTileUrl] = useState("");
  const [zoom, setZoom] = useState(13);
  const [position, setPosition] = useState([osloLat, osloLong]);

  const validatePosition = useCallback(() => {
    if (lat === null || lng === null) return false;
    return lat !== 0 && lng !== 0;
  }, [lat, lng]);

  const setMapView = useCallback(() => {
    if (!validatePosition()) {
      setPosition([osloLat, osloLong]);
    } else {
      setPosition([lat, lng]);
      setZoom(18);
    }
  }, [lat, lng, validatePosition]);

  const getAddress = useCallback(() => {
    if (!validatePosition()) return null;

    const location = { lat: lat, lng: lng };
    geocoder.geocode({ location: location }, (results, status) => {
      if (status === "OK") {
        const result = results[0];
        const formattedAddress = result.formatted_address;
        let address = formattedAddress
          .replace(", Norway", "")
          .replace(", Norge", "");
        setAddress(address);
      }
    });
  }, [lat, lng, validatePosition]);

  const chooseBaseTile = useCallback(() => {
    if (baseTile && baseTile === "google")
      setBaseTileUrl(google_base_tileLayer_url);
    else if (baseTile && baseTile === "osm")
      setBaseTileUrl(osm_base_tileLayer_url);
  }, [baseTile]);

  useEffect(() => {
    setMapView();
  }, [setMapView]);
  useEffect(() => {
    getAddress();
  }, [getAddress]);
  useEffect(() => {
    chooseBaseTile();
  }, [chooseBaseTile]);

  const markerIcon = L.icon({
    iconUrl: customIcon,
    iconSize: [24, 30],
    iconAnchor: [12, 30],
    popupAnchor: [0, -26],
  });

  return (
    <div className="mapContainer">
      <MapContainer
        key={Math.random()}
        center={position}
        zoom={zoom}
        style={style}
        scrollWheelZoom={false}
      >
        <TileLayer
          url={baseTileUrl}
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        {validatePosition() && (
          <Marker position={position} icon={markerIcon}>
            <Popup>
              <span>{address}</span>
            </Popup>
          </Marker>
        )}
      </MapContainer>
      <br />
    </div>
  );
};

MapView.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number,
  baseTile: PropTypes.string,
};

export default MapView;
