import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  handleChange as handleChangeAction,
  handleChangeToInitState as handleChangeToInitStateAction,
} from "../../actions/handleChangeAction";
import { setPage as setPageAction } from "../../actions/advancedListActions";

import { getMiljogebyrer as getMiljogebyrerAction } from "../../actions/miljogebyrerActions";
import { getOvertredelseskoderMiljogebyr } from "../../actions/overtredelseskoderMiljogebyrActions";
import { handleFromToDateTime } from "../../utils/fromToDateTimeUtils";
import * as inputValidation from "../../utils/inputValidationUtils";
import map from "lodash/map";

import { INITIAL_STATE as InnsynMiljogebyererFilterFormStateInitial } from "../../reducers/forms/InnsynMiljogebyrerFilterFormReducer";
import InnsynMiljogebyrFilterForm from "./InnsynMiljogebyrFilterForm";
import { replaceWhitespacesWithEmptyStrings } from "../../utils/sanitizationUtils";
import { IsValidated } from "../../utils/validationUtils";

class InnsynMiljogebyerFilterContainer extends Component {
  static propTypes = {
    InnsynMiljogebyrerFilterFormState: PropTypes.object,
    handleChange: PropTypes.func,
    getMiljogebyrer: PropTypes.func,
    overtredelseskoderMiljogebyr: PropTypes.array,
    setPage: PropTypes.func.isRequired,
  };

  state = {
    shallGetIleggelserDueToHandleChangeToInitState: false,
    showSokSpinner: false,
  };

  componentDidMount() {
    this.props.getOvertredelseskoderMiljogebyr();
  }

  componentDidUpdate() {
    if (
      JSON.stringify(this.props.InnsynMiljogebyrerFilterFormState) ===
        JSON.stringify(InnsynMiljogebyererFilterFormStateInitial) &&
      this.state.shallGetIleggelserDueToHandleChangeToInitState === true
    ) {
      this.setState({ shallGetIleggelserDueToHandleChangeToInitState: false });
    }
  }

  rules = {
    ileggelsesnummer: "isPositiveInteger",
  };

  handleOnChange = (e) => {
    const { InnsynMiljogebyrerFilterFormState } = this.props;
    if (
      e.target.name === "fraDato" ||
      e.target.name === "tilDato" ||
      e.target.name === "fraTid" ||
      e.target.name === "tilTid"
    ) {
      handleFromToDateTime(
        e,
        InnsynMiljogebyrerFilterFormState,
        this.props.handleChange
      );
      return;
    }

    this.props.handleChange(e.target.name, e.target.value);
  };

  handleOnChangeToInitState = () => {
    this.props.handleChangeToInitState();
    this.setState({ shallGetIleggelserDueToHandleChangeToInitState: true });
  };

  resetValidationErrorText() {
    this.props.handleChange("fraDatoValidationErrorText", null);
    this.props.handleChange("fraTidValidationErrorText", null);
    this.props.handleChange("tilDatoValidationErrorText", null);
    this.props.handleChange("tilTidValidationErrorText", null);
  }

  validate() {
    const { InnsynMiljogebyrerFilterFormState, handleChange } = this.props;

    const { isValid, validationError } = IsValidated(
      { ...InnsynMiljogebyrerFilterFormState },
      this.rules
    );
    handleChange(
      "validationError.ileggelsesnummer",
      validationError.ileggelsesnummer
    );

    const fraDateTimeTilDateTimeValid =
      inputValidation.fraDateTimeTilDateTimeValidation(
        InnsynMiljogebyrerFilterFormState,
        handleChange
      );

    return isValid && fraDateTimeTilDateTimeValid;
  }

  checkFiltersValue = () => {
    const filters = this.props.InnsynMiljogebyrerFilterFormState;
    var hasValue = false;
    map(filters, (v, k) => {
      if (v !== null && v !== "") hasValue = true;
    });
    return hasValue;
  };

  getFilters() {
    let {
      fraTid,
      tilTid,
      fraDatoValidationErrorText,
      fraTidValidationErrorText,
      tilDatoValidationErrorText,
      tilTidValidationErrorText,
      status,
      ...filter
    } = this.props.InnsynMiljogebyrerFilterFormState;

    filter = {
      ...filter,
      fraDato: filter.fraDato && filter.fraDato.toISOString(),
      tilDato: filter.tilDato && filter.tilDato.toISOString(),
    };

    return filter;
  }

  getMiljogebyrerByFilters = () => {
    if (
      this.checkFiltersValue() &&
      this.props.miljogebyrer.page &&
      this.props.miljogebyrer.count
    ) {
      this.setState(() => ({ showSokSpinner: true }));
      const page = 1;
      this.props
        .getMiljogebyrer(this.getFilters(), page, this.props.miljogebyrer.count)
        .then(() => {
          this.setState(() => ({ showSokSpinner: false }));
          this.props.setPage(page);
        })
        .catch(() => this.setState(() => ({ showSokSpinner: false })));
    }
  };

  handleBlur = (e) => {
    const { handleChange } = this.props;
    if (e.target.name === "ileggelsesnummer") {
      handleChange(
        e.target.name,
        replaceWhitespacesWithEmptyStrings(e.target.value)
      );
    }
  };

  handleOnSubmit = (e) => {
    e.preventDefault();
    this.resetValidationErrorText();
    if (this.validate()) {
      this.getMiljogebyrerByFilters();
    }
  };

  render() {
    let { InnsynMiljogebyrerFilterFormState, overtredelseskoderMiljogebyr } =
      this.props;
    const { showSokSpinner } = this.state;
    return (
      <InnsynMiljogebyrFilterForm
        showSokSpinner={showSokSpinner}
        InnsynMiljogebyrFilterFormState={InnsynMiljogebyrerFilterFormState}
        handleChange={this.handleOnChange}
        handleChangeToInitState={this.handleOnChangeToInitState}
        handleBlur={this.handleBlur}
        onSubmit={this.handleOnSubmit}
        overtredelseskoderMiljogebyr={overtredelseskoderMiljogebyr}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  miljogebyrer: state.miljogebyrer,
  InnsynMiljogebyrerFilterFormState:
    state.forms.InnsynMiljogebyrerFilterFormState,
  overtredelseskoderMiljogebyr: state.overtredelseskoderMiljogebyr,
});

const mapDispatchToProps = (dispatch) => {
  return {
    handleChange: (variableName, value) =>
      dispatch(
        handleChangeAction(
          "INNSYN_MILJOGEBYRER_FILTER_FORM",
          variableName,
          value
        )
      ),
    handleChangeToInitState: () =>
      dispatch(
        handleChangeToInitStateAction("INNSYN_MILJOGEBYRER_FILTER_FORM")
      ),
    getOvertredelseskoderMiljogebyr: () =>
      dispatch(getOvertredelseskoderMiljogebyr()),
    getMiljogebyrer: (InnsynMiljogebyrerFilter, page, count) =>
      dispatch(getMiljogebyrerAction(InnsynMiljogebyrerFilter, page, count)),
    setPage: (page) => dispatch(setPageAction("miljogebyrer", page)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InnsynMiljogebyerFilterContainer);
