import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import Table from "../../Common/SortableTableContainer";
import { HeaderCell } from "../../Common/Tables";

import { toNorwegianDatetime } from "../../../utils/datetimeUtils";
import { ileggelseClientSpecificUtils } from "../../../utils/ileggelseClientSpecificUtils";

import sitePaths from "../../../constants/sitePaths";
import { text } from "../../../constants/text";

import { ReactComponent as IconImage } from "../../../images/icon-image.svg";
import { ReactComponent as IconImageWhite } from "../../../images/icon-image-white.svg";

const List = () => {
  const elsparksykkelGebyr = useSelector(
    (store) => store.elsparksykkelGebyr?.items
  );

  const rows = (singleElsparksykkelGebyr) => {
    let bilde = <span />;
    if (singleElsparksykkelGebyr.antallBilder > 1) {
      bilde = singleElsparksykkelGebyr.isMakulert ? (
        <IconImageWhite />
      ) : (
        <IconImage />
      );
    }

    const isMakulertStyle = singleElsparksykkelGebyr.isMakulert
      ? { backgroundColor: "rgb(211, 35, 22)", color: "white" }
      : {};
    const linkMakulertStyle = singleElsparksykkelGebyr.isMakulert
      ? "link-makulert"
      : "underline";

    return (
      <>
        <tr
          key={`${singleElsparksykkelGebyr.ileggelsesnummer}`}
          style={isMakulertStyle}
        >
          <td>
            <Link
              to={`${sitePaths.ileggelser.elsparksykkelGebyr.shortUrl}/${singleElsparksykkelGebyr.id}`}
              className={linkMakulertStyle}
            >
              {singleElsparksykkelGebyr.ileggelsesnummer?.toString()}
            </Link>
          </td>
          <td>
            {toNorwegianDatetime(
              singleElsparksykkelGebyr.ileggelsesdatoTil
            ).format("DD.MM.YYYY")}
          </td>
          <td>
            {toNorwegianDatetime(
              singleElsparksykkelGebyr.ileggelsesdatoFra
            ).format("HH:mm")}{" "}
            -{" "}
            {toNorwegianDatetime(
              singleElsparksykkelGebyr.ileggelsesdatoTil
            ).format("HH:mm")}
          </td>
          <td>{singleElsparksykkelGebyr.tjenestenummer}</td>
          <td>
            {ileggelseClientSpecificUtils.getOvertredelserString(
              singleElsparksykkelGebyr.overtredelse1,
              singleElsparksykkelGebyr.overtredelse2,
              singleElsparksykkelGebyr.overtredelse3
            )}
          </td>
          <td>{singleElsparksykkelGebyr.kjennemerke}</td>
          <td>{singleElsparksykkelGebyr.nasjon}</td>
          <td>{singleElsparksykkelGebyr.gatenavn}</td>
          <td>{singleElsparksykkelGebyr.gatenummer}</td>
          <td>{singleElsparksykkelGebyr.fabrikkmerke}</td>
          <td>{bilde}</td>
        </tr>
        <tr style={isMakulertStyle}>
          <td
            colSpan="7"
            style={{
              maxWidth: 700,
              wordWrap: "break-word",
              overflowWrap: "break-word",
            }}
          >
            <strong>
              {text.ettersendelseOversikt.ettersendelserRapport}:{" "}
            </strong>
            {singleElsparksykkelGebyr.ettersendelse
              ? singleElsparksykkelGebyr.ettersendelse.kommentar
              : ""}
          </td>
          <td
            colSpan="5"
            style={{
              maxWidth: 500,
              wordWrap: "break-word",
              overflowWrap: "break-word",
            }}
          >
            <strong>{text.ettersendelseOversikt.tilbakemelding}: </strong>
            {singleElsparksykkelGebyr.rettelser &&
              singleElsparksykkelGebyr.rettelser.map((rettelse, index) => {
                if (
                  index === 0 &&
                  singleElsparksykkelGebyr.rettelser.length === 1
                ) {
                  return rettelse.kommentar;
                }
                return (
                  <React.Fragment
                    key={`elsparksykkelGebyr.rettelse.kommentar${index}`}
                  >
                    <br />
                    {`${++index}. ${rettelse.kommentar}`}
                  </React.Fragment>
                );
              })}
          </td>
        </tr>
      </>
    );
  };

  return (
    <div className="ettersendelse-list-page-content-container">
      <Table
        id="ettersendelse-liste"
        className="bym-ettersendelser-table"
        storeKey="elsparksykkelGebyr"
        items={elsparksykkelGebyr}
        renderRow={rows}
      >
        <HeaderCell>{text.ettersendelseOversikt.ileggelseNr}</HeaderCell>
        <HeaderCell>{text.ettersendelseOversikt.ileggelsedato}</HeaderCell>
        <HeaderCell>{text.ettersendelseOversikt.klokkeslett}</HeaderCell>
        <HeaderCell>{text.ettersendelseOversikt.tjenesteNr}</HeaderCell>
        <HeaderCell>{text.ettersendelseOversikt.overtredelsekode}</HeaderCell>
        <HeaderCell>{text.ettersendelseOversikt.kjennemerke}</HeaderCell>
        <HeaderCell>{text.ettersendelseOversikt.nasjon}</HeaderCell>
        <HeaderCell>{text.ettersendelseOversikt.gatenavn}</HeaderCell>
        <HeaderCell>{text.ettersendelseOversikt.gatenr}</HeaderCell>
        <HeaderCell>Aktør</HeaderCell>
        <HeaderCell>{text.ettersendelseOversikt.bilde}</HeaderCell>
      </Table>
    </div>
  );
};

export default List;
