// eslint-disable-next-line
import { tz } from "moment-timezone";
// import moment from "moment";
import {
  handleChangeActionType,
  handleChangeAllActionType,
  handleChangeToInitStateActionType,
} from "../../actions/handleChangeAction";
import _ from "lodash";

export const INITIAL_STATE = {
  ileggelsesnummer: "",
  fraDato: "", //moment().startOf("day"), // fraTid will be added to this
  tilDato: "", //moment().endOf("day"), // tilTid will be added to this
  kjennemerke: "",
  tjenestenummer: "",
  gatenavn: "",
  gatenummer: "",
  overtredelse: "",
  internkode: "",
  leveringstypeId: undefined,
  type: undefined,
  saksnummer: "",
  vedtakskode: "",
  klagekode: "",
  bydel: "",
  fraIneOverforingsdato: "",
  tilIneOverforingsdato: "",
  // Statuser
  isVenter: 0,
  isTilEttersendelse: null,
  isEttersendelseTilGodkjenning: null,
  isEttersendt: null,
  isMakulert: false,
  isKlarTilKemner: null, // Skal stå som isKlarTilKemner tils endret på backend
  isStoppetAvSystemet: null,
  isFeiletKemner: null,
  isSendtKemner: null, // Skal stå isSendtKemner tils backend er endret til isSendtIne
  isBetalt: null,
  teamId: null,
  // Parameters which are none existing in backend
  fraTid: "",
  tilTid: "",
  selectedStatuser: [],
  fraDatoValidationErrorText: null,
  fraTidValidationErrorText: null,
  tilDatoValidationErrorText: null,
  tilTidValidationErrorText: null,
  fraIneOverforingsdatoValidationErrorText: null,
  tilIneOverforingsdatoValidationErrorText: null,
  validationError: {
    ileggelsesnummer: null,
    tjenestenummer: null,
    saksnummer: null,
  },
};

export default function ileggelserOversiktFilterFormReducer(
  state = {
    ...INITIAL_STATE,
    validationError: { ...INITIAL_STATE.validationError },
  },
  action
) {
  switch (action.type) {
    case handleChangeActionType("ILEGGELSER_OVERSIKT_FILTER_FORM"):
      _.set(state, action.variableName, action.payload);
      return { ...state };
    case handleChangeAllActionType("ILEGGELSER_OVERSIKT_FILTER_FORM"):
      return { ...action.payload };
    case handleChangeToInitStateActionType("ILEGGELSER_OVERSIKT_FILTER_FORM"):
      return {
        ...INITIAL_STATE,
        validationError: { ...INITIAL_STATE.validationError },
      };
    default:
      return state;
  }
}
