import React from "react";
import { Switch, Redirect } from "react-router-dom";

import AuthorizedRoutes from "../components/Routes/AuthorizedRoutes";

import OversiktContainer from "../components/Ileggelse/Oversikt/OversiktPage";
import OversiktUtskriftContainer from "../components/Ileggelse/Oversikt/UtskriftContainer";
import EttersendelserPage from "../components/Ileggelse/Ettersendelse/EttersendelserPage";
import EttersendelserUtskriftContainer from "../components/Ileggelse/Ettersendelse/UtskriftContainer";
import EttersendelserTilGodkjenningPage from "../components/Ileggelse/EttersendelserTilGodkjenning/EttersendelserTilGodkjenningPage";
import ElsparksykkelGebyrPage from "../components/Ileggelse/ElsparksykkelGebyr/ElsparksykkelGebyrPage";

import IleggelseDetaljPage from "../components/Ileggelse/Detalj/IleggelseDetaljPage";
import SvarUtBrevHistorikk from "../components/Common/Detalj/SvarUtBrevhistorikk";
import IleggelseImageEditorPageContainer from "../components/Common/Ileggelser/Detalj/ImageEditorPageContainer";

import sitePath from "../constants/sitePaths";
import { bypatruljen, juridisk } from "../constants/roles";
import IleggelseOrginalbilde from "../components/Common/Ileggelser/Detalj/IleggelseOrginalbilde";
import UtskriftContainerELS from "../components/Ileggelse/ElsparksykkelGebyr/UtskriftContainerELS";

const Ileggelser = () => (
  <Switch>
    <AuthorizedRoutes
      exact
      path={sitePath.ileggelser.oversikt.shortUrl}
      roles={[...Object.values(bypatruljen), ...Object.values(juridisk)]}
      component={OversiktContainer}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.ileggelser.oversikt.utskrift}
      roles={[...Object.values(bypatruljen), ...Object.values(juridisk)]}
      component={OversiktUtskriftContainer}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.ileggelser.ettersendelser.shortUrl}
      roles={[
        bypatruljen.saksbehandler,
        bypatruljen.saksbehandleradmin,
        bypatruljen.avdelingdirektor,
        bypatruljen.seksjonssjefStab,
      ]}
      component={EttersendelserPage}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.ileggelser.ettersendelser.utskrift}
      roles={[
        bypatruljen.saksbehandler,
        bypatruljen.saksbehandleradmin,
        bypatruljen.avdelingdirektor,
        bypatruljen.seksjonssjefStab,
      ]}
      component={EttersendelserUtskriftContainer}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.ileggelser.ettersendelserTilGodkjenning.shortUrl}
      roles={[bypatruljen.saksbehandler, bypatruljen.saksbehandleradmin]}
      component={EttersendelserTilGodkjenningPage}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.ileggelser.elsparksykkelGebyr.shortUrl}
      roles={[
        bypatruljen.saksbehandler,
        bypatruljen.saksbehandleradmin,
        bypatruljen.avdelingdirektor,
        bypatruljen.seksjonssjefStab,
        bypatruljen.seksjonssjef,
        bypatruljen.driftleder,
        juridisk.ledelse,
        juridisk.saksbehandler,
      ]}
      component={ElsparksykkelGebyrPage}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.ileggelser.elsparksykkelGebyr.utskrift}
      roles={[...Object.values(bypatruljen), ...Object.values(juridisk)]}
      component={UtskriftContainerELS}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.ileggelser.oversikt.detalj.shortUrl.routeMatcher}
      roles={[...Object.values(bypatruljen), ...Object.values(juridisk)]}
      component={IleggelseDetaljPage}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.ileggelser.oversikt.detalj.svarUtHistorikk}
      roles={[
        bypatruljen.saksbehandler,
        bypatruljen.saksbehandleradmin,
        juridisk.ledelse,
        juridisk.saksbehandler,
      ]}
      component={SvarUtBrevHistorikk}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.ileggelser.oversikt.detalj.toastUi}
      roles={[
        bypatruljen.saksbehandleradmin,
        bypatruljen.saksbehandler,
        juridisk.saksbehandler,
      ]}
      component={IleggelseImageEditorPageContainer}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.ileggelser.oversikt.detalj.bilde}
      roles={[...Object.values(bypatruljen), ...Object.values(juridisk)]}
      component={IleggelseOrginalbilde}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.ileggelser.ettersendelser.detalj.shortUrl.routeMatcher}
      roles={[...Object.values(bypatruljen), ...Object.values(juridisk)]}
      component={IleggelseDetaljPage}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.ileggelser.ettersendelser.detalj.svarUtHistorikk}
      roles={[bypatruljen.saksbehandler, bypatruljen.saksbehandleradmin]}
      component={SvarUtBrevHistorikk}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.ileggelser.ettersendelser.detalj.toastUi}
      roles={[
        bypatruljen.saksbehandleradmin,
        bypatruljen.saksbehandler,
        juridisk.saksbehandler,
      ]}
      component={IleggelseImageEditorPageContainer}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.ileggelser.ettersendelser.detalj.bilde}
      roles={[...Object.values(bypatruljen), ...Object.values(juridisk)]}
      component={IleggelseOrginalbilde}
    />
    <AuthorizedRoutes
      exact
      path={
        sitePath.ileggelser.ettersendelserTilGodkjenning.detalj.shortUrl
          .routeMatcher
      }
      roles={[...Object.values(bypatruljen), ...Object.values(juridisk)]}
      component={IleggelseDetaljPage}
    />
    <AuthorizedRoutes
      exact
      path={
        sitePath.ileggelser.ettersendelserTilGodkjenning.detalj.svarUtHistorikk
      }
      roles={[bypatruljen.saksbehandler, bypatruljen.saksbehandleradmin]}
      component={SvarUtBrevHistorikk}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.ileggelser.ettersendelserTilGodkjenning.detalj.toastUi}
      roles={[
        bypatruljen.saksbehandleradmin,
        bypatruljen.saksbehandler,
        juridisk.saksbehandler,
      ]}
      component={IleggelseImageEditorPageContainer}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.ileggelser.ettersendelserTilGodkjenning.detalj.bilde}
      roles={[...Object.values(bypatruljen), ...Object.values(juridisk)]}
      component={IleggelseOrginalbilde}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.ileggelser.elsparksykkelGebyr.detalj.shortUrl.routeMatcher}
      roles={[
        bypatruljen.saksbehandler,
        bypatruljen.saksbehandleradmin,
        bypatruljen.avdelingdirektor,
        bypatruljen.seksjonssjefStab,
        bypatruljen.seksjonssjef,
        bypatruljen.driftleder,
        juridisk.ledelse,
        juridisk.saksbehandler,
      ]}
      component={IleggelseDetaljPage}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.ileggelser.elsparksykkelGebyr.detalj.svarUtHistorikk}
      roles={[
        bypatruljen.saksbehandler,
        bypatruljen.saksbehandleradmin,
        juridisk.ledelse,
        juridisk.saksbehandler,
      ]}
      component={SvarUtBrevHistorikk}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.ileggelser.elsparksykkelGebyr.detalj.toastUi}
      roles={[
        bypatruljen.saksbehandleradmin,
        bypatruljen.saksbehandler,
        juridisk.saksbehandler,
      ]}
      component={IleggelseImageEditorPageContainer}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.ileggelser.elsparksykkelGebyr.detalj.bilde}
      roles={[
        bypatruljen.saksbehandler,
        bypatruljen.saksbehandleradmin,
        bypatruljen.avdelingdirektor,
        bypatruljen.seksjonssjefStab,
        bypatruljen.seksjonssjef,
        bypatruljen.driftleder,
        juridisk.ledelse,
        juridisk.saksbehandler,
      ]}
      component={IleggelseOrginalbilde}
    />
    <Redirect
      path={sitePath.ileggelser.shortUrl}
      to={sitePath.ileggelser.oversikt.shortUrl}
    />
  </Switch>
);

export default Ileggelser;
