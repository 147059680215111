import { handleChangeAllActionType } from "../../actions/handleChangeAction";

export const INITIAL_STATE = {
  submitTimestamp: "",
  saksbehandler: ""
};

export default function uferdigstilteKlagesakerSubmittedFormReducer(
  state = {
    ...INITIAL_STATE
  },
  action
) {
  switch (action.type) {
    case handleChangeAllActionType(
      "RAPPORT_UFERDIGSTILTE_KLAGESAKER_SUBMITTED_FORM"
    ):
      return { ...action.payload };
    default:
      return state;
  }
}
