import moment from "moment";
import moment_timezone from "moment-timezone";
// import { isString } from "util";
//import dateFormat from "dateformat"
import sortBy from "lodash/sortBy";

import { months } from "../constants/globals";

export function daylables() {
  return ["Søn", "Man", "Tir", "Ons", "Tor", "Fre", "Lør"];
}

export function monthlabels() {
  return months;
}

export function getMonth(index) {
  const value = parseInt(index, 10);
  if (value < 0 || index > 11) {
    return null;
  }
  return months[index];
}

export function toIsoString(datetime) {
  const momentObj = moment(datetime);
  if (momentObj.isValid()) return moment(datetime).toISOString();
  return "";
}

export function isValidDateTime(datetime) {
  if (datetime === undefined || datetime === null) return false;
  const momentObject = moment(datetime);
  return momentObject.isValid();
}

export function toNorwegianDateFormat(datetime) {
  if (Date.parse(datetime)) {
    return moment(datetime).format("DD.MM.YYYY");
  }
  return "";
}

export function toNorwegianTimeFormat(datetime) {
  if (isValid(datetime)) {
    return moment(datetime).format("HH:mm");
  }
  return "";
}

export function toNorwegianDatetime(isoString) {
  // req: isoString should be Z with Noon (12:00) as time-part
  // 1) Parse ISO String using same culture as used in datepicker
  //const datetime = moment(isoString,moment.ISO_8601);
  const datetime = moment(isoString);
  // 2) Get local Year, Month and Date
  const year = datetime.year();
  const month = datetime.month();
  const day = datetime.date();
  const hour = datetime.hour();
  const minute = datetime.minute();

  // 3) Create new date using Oslo as timezone
  return moment_timezone.tz({ year, month, day, hour, minute }, "Europe/Oslo");
}

// Convert from Norwegian date to local date
export function fromNorwegianDate(isoString) {
  // 1) Find year, month, and day in Norwegian
  const datetime = moment_timezone.tz(isoString, "Europe/Oslo");
  const year = datetime.year();
  const month = datetime.month();
  const day = datetime.date();

  // 2) Convert to local date, and ignore time
  return moment({
    year: year,
    month: month,
    day: day,
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
}

export function mergeDateTime(date, time) {
  date.hours(time.hours()).minutes(time.minutes());
}

export function isValid(datetime) {
  return Date.parse(datetime);
}

export function getMostRecentObjectComparedOnParameter(
  objectArray,
  parameterName
) {
  let mostRecent;
  for (let index = 0; index < objectArray.length; index++) {
    if (index === 0) {
      mostRecent = { ...objectArray[index] };
    }
    const element = objectArray[index];
    if (
      Date.parse(element[parameterName]) > Date.parse(mostRecent[parameterName])
    ) {
      mostRecent = { ...element };
    }
  }
  return mostRecent;
}

export function sortObjectsByDate(
  objectArray,
  parameterName,
  sortFlag = "asc"
) {
  var sortedByDatoAsending = sortBy(
    objectArray,
    function compare(a) {
      return new moment(a[parameterName]);
    },
    [sortFlag]
  );
  return sortedByDatoAsending;
}

export function StartDateIsLongerThanEndDate(fraDato, tilDato) {
  return Date.parse(fraDato) > Date.parse(tilDato);
}

export function datetimeIsDeactivated(isoString) {
  if (isoString === null || isoString === "") return false;
  else if (Date.parse(isoString)) {
    const current = new Date().toISOString();
    return toNorwegianDatetime(isoString) < toNorwegianDatetime(current);
  }
}

export function GyldighetsDatorange(fromDate, toDate) {
  if (fromDate && toDate)
    return (
      moment(fromDate).format("DD.MM.YYYY") +
      "-" +
      moment(toDate).format("DD.MM.YYYY")
    );
}

export function FormatToMonthAndYear(month, year) {
  if (month && year) {
    const optionsDate = { year: "numeric", month: "long" };
    const date = new Date(year, month);
    var d = date.toLocaleString("nb-NO", optionsDate);
    return d.substr(0, 1).toString().toUpperCase() + d.substring(1);
    // return dateFormat(d, "mmmyyyy")
  }
}

export function formatDateTimeHavingDateTime(datetime) {
  if (isValid(datetime)) {
    return moment(datetime).format("DD.MM.YYYY [kl.] HH.mm");
  }
  return "";
}

export function formatDateHavingNorwegianDateTime(datetime) {
  if (isValid(datetime))
    return moment_timezone.tz(datetime, "Europe/Oslo").format("DD.MM.YYYY");
  return "";
}

export function formatTimeHavingNorwegianDateTime(datetime) {
  if (isValid(datetime))
    return moment_timezone.tz(datetime, "Europe/Oslo").format("HH:mm");
  return "";
}

export function formatDateTimeHavingNorwegianDateTime(datetime) {
  if (isValid(datetime))
    return moment_timezone
      .tz(datetime, "Europe/Oslo")
      .format("DD.MM.YYYY [kl.] HH:mm");
  return "";
}

export function dateTimeFromTimePicker(tid) {
  let now = moment();
  if (tid && typeof tid !== "string" && isValidDateTime(tid)) {
    return toIsoString(
      moment(tid).year(now.year()).month(now.month()).day(now.day())
    );
  }
  return tid;
}
