import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import GridTable from "../../Common/GridTable";

import { getGridTableRowData } from "../../../selectors/miljogebyrSystemloggerSelector";

class SystemloggContainer extends React.Component {
  static propTypes = {
    gridTableRowData: PropTypes.array,
  };

  render() {
    const { gridTableRowData } = this.props;
    return (
      <section>
        <div className="detalj-ileggelse-header-wrapper">
          <h2>Systemlogg</h2>
        </div>
        <div>
          <GridTable name="detaljSystemlogg" rows={gridTableRowData} />
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    gridTableRowData: getGridTableRowData(state),
  };
};

export default connect(mapStateToProps)(SystemloggContainer);
