import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";

import { getMiljoMakuleringer as getMiljoMakuleringerAction } from "../../../actions/miljoMakuleringerActions";

import { getMostRecentObjectComparedOnParameter } from "../../../utils/datetimeUtils";

class MakuleringsarsakContainer extends React.Component {
  static propTypes = {
    miljogebyr: PropTypes.object.isRequired,
    mostRecentMakulering: PropTypes.object,
    getMiljoMakuleringer: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.getMiljoMakuleringer(this.props.miljogebyr.id);
  }

  render() {
    return (
      <section className="detalj-miljogebyr-section-margin">
        <div style={{ marginTop: 17 }}>
          <h5>Makuleringsårsak</h5>
          <Row>
            <Col md={6}>
              <p style={{ fontSize: 16 }}>
                {(this.props.miljogebyr.isMakulert &&
                  this.props.mostRecentMakulering &&
                  this.props.miljogebyr.id ===
                    this.props.mostRecentMakulering.miljogebyrId &&
                  this.props.mostRecentMakulering.kommentar) ||
                  "-"}
              </p>
            </Col>
          </Row>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    miljogebyr: state.miljogebyr,
    mostRecentMakulering:
      state.miljoMakuleringer &&
      getMostRecentObjectComparedOnParameter(
        state.miljoMakuleringer,
        "makuleringDato"
      ),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getMiljoMakuleringer: (miljogebyrId) =>
      dispatch(getMiljoMakuleringerAction(miljogebyrId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MakuleringsarsakContainer);
