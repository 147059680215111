import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import VedtakerList from "./VedtakerList";
import FerdigBehanldeVedtakerContainer from "./FerdigBehandleVedtakerContainer";

import { getVedtakskoder } from "../../actions/vedtakskoderAction";
import { getP360Sak } from "../../actions/p360Action";
import { getIleggelseDetailByNummer } from "../../actions/klager/klageAction";
import {
  getKlageVedtaker,
  addKlageVedtak,
  deleteKlageVedtak,
  updateKlageVedtak,
  UpdateVedtakerFerdigBehandlet,
} from "../../actions/klager/vedtakAction";
import {
  handleChangeAll as handleChangeAllAction,
  handleChange as handleChangeAction,
} from "../../actions/handleChangeAction";
import { getVedtakskoderVmByIleggelsetyper } from "../../selectors/vedtakSelector";
import { authorized } from "../../utils/authorizationUtils";
import { onChangeValidation } from "../../utils/validationUtils";
import { juridiskArray } from "../../constants/roles";
import { overtredelsekodeForEditereBelop } from "../../constants/globals";

export class VedtakerContainer extends Component {
  static propTypes = {
    klageId: PropTypes.string,
    vedtaker: PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.arrayOf(PropTypes.shape({})),
    ]),
    vedtakskoder: PropTypes.shape({}),
    klageVedtakerForm: PropTypes.shape({}),
    ileggelser: PropTypes.array,
    handleChangeAllVedtakAction: PropTypes.func.isRequired,
    handleChangeVedtakerAction: PropTypes.func.isRequired,
    manglerInfo: PropTypes.bool,
  };

  state = {
    ileggelseType: "",
    nyRadModus: false,
    showNyttDokumentSpinner: false,
  };

  rules = {
    vedtakskode: "required",
  };

  componentDidMount = async () => {
    const {
      klageId,
      getVedtakskoder,
      getKlageVedtaker,
      handleChangeAllVedtakAction,
    } = this.props;
    getVedtakskoder();
    if (klageId) {
      const klagevedtaker = await getKlageVedtaker(klageId);
      const klageVedtakerForm = klagevedtaker.reduce((object, klagevedtak) => {
        object[klagevedtak.id] = {
          vedtakskode: klagevedtak.vedtakskodeId,
          nyttBelop: klagevedtak.nyttBelop,
          klagekode: klagevedtak.klagekode,
          validationError: {},
        };
        return object;
      }, {});
      handleChangeAllVedtakAction(klageVedtakerForm);

      if (this.getHasIkkeFerdigbehandletVedtaker()) {
        window.onbeforeunload = () =>
          "Det finnes vedtak som ikke er ferdigbehandlet";
      } else {
        window.onbeforeunload = null;
      }
    }
  };

  componentDidUpdate = async (prevProps) => {
    const { vedtaker, handleChangeAllVedtakAction } = this.props;
    if (JSON.stringify(prevProps.vedtaker) !== JSON.stringify(vedtaker)) {
      const klageVedtakerForm = vedtaker.reduce((object, klagevedtak) => {
        object[klagevedtak.id] = {
          vedtakskode: klagevedtak.vedtakskodeId,
          nyttBelop: klagevedtak.nyttBelop,
          klagekode: klagevedtak.klagekode,
          validationError: {},
        };
        return object;
      }, {});

      handleChangeAllVedtakAction(klageVedtakerForm);

      if (this.getHasIkkeFerdigbehandletVedtaker()) {
        window.onbeforeunload = () =>
          "Det finnes vedtak som ikke er ferdigbehandlet";
      } else {
        window.onbeforeunload = null;
      }
    }
  };

  componentWillUnmount = () => {
    window.onbeforeunload = null;
  };

  getHasIkkeFerdigbehandletVedtaker = () => {
    const { vedtaker } = this.props;

    var vedtakerArray = Object.values(vedtaker);
    var hasIkkeFerdigbehandletVedtaker = false;
    for (const vedtak of vedtakerArray) {
      if (vedtak.vedtaksDato === null) {
        hasIkkeFerdigbehandletVedtaker = true;
        break;
      }
    }

    return hasIkkeFerdigbehandletVedtaker;
  };

  onChangeVedtakskode = async (e, vedtak) => {
    const { klageVedtakerForm, handleChangeVedtakerAction } = this.props;

    const klagevedtak = klageVedtakerForm[vedtak.id];
    onChangeValidation(e, klagevedtak, this.rules);
    handleChangeVedtakerAction(vedtak.id, {
      ...klagevedtak,
      [e.target.name]: e.target.value,
      oldVedtakskode: klagevedtak.vedtakskode,
      validationError: {},
    });
    getKlageVedtaker(this.props.klageId);
  };

  onConfirmKlagevedtakUpdate = async (vedtak) => {
    const { klageVedtakerForm, updateKlageVedtak, getKlageVedtaker } =
      this.props;
    const klagevedtak = klageVedtakerForm[vedtak.id];
    const payload = {
      vedtakskodeId: klagevedtak.vedtakskode,
      nyttBelop: klagevedtak.nyttBelop && Number(klagevedtak.nyttBelop),
    };
    const result = await updateKlageVedtak(
      this.props.klageId,
      vedtak.id,
      payload
    );
    getKlageVedtaker(this.props.klageId);
    return result ? true : false;
  };

  onCancelVedtakskode = (vedtak) => {
    const { klageVedtakerForm, handleChangeVedtakerAction } = this.props;
    const klagevedtak = klageVedtakerForm[vedtak.id];
    handleChangeVedtakerAction(vedtak.id, {
      ...klagevedtak,
      vedtakskode: klagevedtak.oldVedtakskode,
      oldVedtakskode: null,
      validationError: {},
    });
  };

  onSubmitNyttBelop = async (e, vedtak) => {
    const { klageVedtakerForm, handleChangeVedtakerAction } = this.props;
    const klagevedtak = klageVedtakerForm[vedtak.id];
    const value = e.target.value;
    handleChangeVedtakerAction(vedtak.id, {
      ...klagevedtak,
      [e.target.name]: value,
      oldNyttBelop: klagevedtak.nyttBelop,
      validationError: {},
    });
  };

  onCancelNyttBelop = async (vedtak) => {
    const { klageVedtakerForm, handleChangeVedtakerAction } = this.props;
    const klagevedtak = klageVedtakerForm[vedtak.id];
    handleChangeVedtakerAction(vedtak.id, {
      ...klagevedtak,
      nyttBelop: klagevedtak.oldNyttBelop,
      oldNyttBelop: null,
      validationError: {},
    });
  };

  onRemoveVedtak = async (e, vedtak) => {
    await this.props.deleteKlageVedtak(this.props.klageId, vedtak.id);
    await this.props.getKlageVedtaker(this.props.klageId);
  };

  onAddNyVedtakRad = () => {
    this.setState({ nyRadModus: true });
  };

  onRemoveNyVedtakRad = () => {
    this.setState({ nyRadModus: false });
  };

  onSubmitNyVedtak = async (e) => {
    const { handleChangeVedtakerAction } = this.props;
    const ileggelsesnummer = e.target.value;
    const ileggelse = await this.props.getIleggelseDetailByNummer(
      ileggelsesnummer
    );
    if (ileggelse && ileggelse.ileggelsesnummer) {
      const klagevedtak = await this.props
        .addKlageVedtak(this.props.klageId, ileggelse)
        .catch(() => {
          this.setState({ nyRadModus: false });
        });
      if (klagevedtak && klagevedtak.id) {
        const klagevedtaker = await this.props.getKlageVedtaker(
          this.props.klageId
        );
        const vedtak = klagevedtaker.find((kv) => kv.id === klagevedtak.id);
        handleChangeVedtakerAction(klagevedtak.id, {
          vedtakskode: vedtak.vedtakskodeId,
          nyttBelop: vedtak.nyttBelop,
          validationError: {},
        });
      }
      this.setState({ nyRadModus: false });
    }
  };

  render() {
    const onlyForJuridisk = authorized(juridiskArray);
    const tableHeader = [
      "Ilegg.nr.",
      "Vedtak",
      // "Klagekode",
      "Vedtaksdato",
      "Ileggelsestype",
      "Gebyrdato",
      "Beløp",
      "Nytt beløp",
      "",
    ];
    const { sak, klageVedtakerForm, vedtakskoder, manglerInfo, vedtaker } =
      this.props;
    const { nyRadModus } = this.state;
    const editNyttBelopKlageKoder = [14, 15, 16, 17, 43, 45];
    return (
      <div className="klageVedtak">
        <VedtakerList
          sak={sak}
          manglerInfo={manglerInfo}
          nyRadModus={nyRadModus}
          tableHeader={tableHeader}
          klageVedtakerForm={klageVedtakerForm}
          onChangeVedtakskode={this.onChangeVedtakskode}
          onCancelVedtakskode={this.onCancelVedtakskode}
          onSubmitIleggesesnummer={this.onSubmitIleggesesnummer}
          onSubmitNyttBelop={this.onSubmitNyttBelop}
          onConfirmKlagevedtakUpdate={this.onConfirmKlagevedtakUpdate}
          onCancelNyttBelop={this.onCancelNyttBelop}
          onAdd={this.onAddNyVedtakRad}
          onRemove={this.onRemoveVedtak}
          vedtaker={this.props.vedtaker}
          vedtakskoder={vedtakskoder}
          overtredelsekodeForEditereBelop={overtredelsekodeForEditereBelop}
          onSubmitNyVedtak={this.onSubmitNyVedtak}
          showFerdigbehandleVedtakSpinner={
            this.state.showFerdigbehandleVedtakSpinner
          }
          showNyttDokumentSpinner={this.state.showNyttDokumentSpinner}
          onRemoveNyVedtakRad={this.onRemoveNyVedtakRad}
          FerdigBehandleVedtaker={this.FerdigBehandleVedtaker}
          editNyttBelopKlageKoder={editNyttBelopKlageKoder}
        />
        {onlyForJuridisk && vedtaker.length > 0 && !manglerInfo && (
          <React.Fragment>
            <FerdigBehanldeVedtakerContainer />
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sak: state.p360Sak,
  klageId:
    state.klageDetail && state.klageDetail.id ? state.klageDetail.id : "",
  vedtaker: state.vedtaker,
  vedtakskoder: getVedtakskoderVmByIleggelsetyper(state),
  klageVedtakerForm: state.forms && state.forms.klageVedtakerForm,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getP360Sak,
      getIleggelseDetailByNummer,
      getVedtakskoder,
      getKlageVedtaker,
      addKlageVedtak,
      deleteKlageVedtak,
      updateKlageVedtak,
      UpdateVedtakerFerdigBehandlet,
      handleChangeAllVedtakAction: (payload) =>
        handleChangeAllAction("KLAGE_VEDTAKER_FORM", payload),
      handleChangeVedtakerAction: (variableName, payload) =>
        handleChangeAction("KLAGE_VEDTAKER_FORM", variableName, payload),
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(VedtakerContainer);
