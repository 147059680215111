import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Tab, Tabs } from "react-bootstrap";

import { text } from "../../../constants/text";

import DetaljPageHeader from "../../Common/Detalj/DetaljPageHeader";
import ButtonsContainer from "./ButtonsContainer";
import IleggelseGridOgKommentarContainer from "../../Common/Ileggelser/Detalj/IleggelseGridOgKommentarContainer";
import TilbakemeldingerFraBybetjenterContainer from "../../Common/Ileggelser/Detalj/TilbakemeldingerFraBybetjenterContainer";
import BilderContainer from "../../Common/Ileggelser/Detalj/BilderContainer";
import BilderButtonsContainer from "./BilderButtonsContainer";
import Ettersendelser from "../../Common/Ileggelser/Detalj/Ettersendelser";
import NyeEttersendelser from "../../Common/Ileggelser/Detalj/NyeEttersendelser";
import KlageinformasjonContainer from "../../Common/Ileggelser/Detalj/KlageinformasjonContainer";
import SystemloggContainer from "./SystemloggContainer";
import MakuleringsarsakContainers from "./MakuleringsarsakContainer";
import HistorikkOgKommentarerContainer from "./HistorikkOgKommentarerContainer";
import Map from "../../Common/Maps/MapView";
import Spinner from "../../Common/Spinner";

import { getIleggelse } from "../../../actions/ileggelserActions";
import { getIleggelseEttersendelser } from "../../../actions/ileggelseEttersendelserActions";
import { getBybetjenter } from "../../../actions/bybetjenterActions";

import * as title from "../../../constants/titles";

import "./IleggelseDetaljPage.css";
import { withRouter } from "react-router-dom";

class IleggelseDetaljPage extends Component {
  static propTypes = {
    computedMatch: PropTypes.object,
    ileggelse: PropTypes.object,
    getIleggelse: PropTypes.func.isRequired,
    getIleggelseEttersendelser: PropTypes.func.isRequired,
    getBybetjenter: PropTypes.func.isRequired,
  };

  setElementsToNoPrint = () => {
    const tabsMiljogebyrKartElement = document.getElementById(
      "tabs-ileggelse-kart"
    );
    if (!tabsMiljogebyrKartElement) {
      return;
    }
    const navTabsElement = tabsMiljogebyrKartElement.getElementsByTagName("ul");
    if (navTabsElement && navTabsElement.length > 0) {
      navTabsElement[0].setAttribute("class", "nav nav-tabs no-print");
    }
  };

  componentDidMount() {
    const { getIleggelse, getIleggelseEttersendelser } = this.props;
    window.scrollTo(0, 0);
    document.title = title.ileggelseDetalj();
    const id = this.props.computedMatch.params.id;
    getIleggelse(id)
      .then((result) => {
        document.title = title.ileggelseDetalj(result.ileggelsesnummer);
      })
      .catch((error) => (document.title = title.ileggelseDetalj()));
    getIleggelseEttersendelser(id);
    getBybetjenter(id);
  }

  componentDidUpdate = () => {
    this.setElementsToNoPrint();
  };

  render() {
    const { ileggelse, computedMatch } = this.props;

    if (ileggelse && ileggelse.id === computedMatch.params.id) {
      return (
        <div className="ileggelse-content-wrapper">
          <DetaljPageHeader
            pageName={text.ileggelseGebyr.pageName}
            pageId={String(ileggelse.ileggelsesnummer)}
            isMakulert={ileggelse.isMakulert}
            computedMatch={computedMatch}
          />
          <Tabs
            id="tabs-ileggelse-kart"
            className="detailjTab"
            onSelect={() => {
              this.forceUpdate(); // For tab 2 to update.
            }}
          >
            <Tab eventKey={1} title="Ileggelse">
              <div className="ileggelse-tab-content-wrapper">
                <ButtonsContainer
                  ileggelseId={computedMatch.params.id}
                  ileggelsesnummer={ileggelse.ileggelsesnummer}
                />
                <IleggelseGridOgKommentarContainer />
                <TilbakemeldingerFraBybetjenterContainer
                  ileggelseId={computedMatch.params.id}
                />
                <BilderContainer
                  ileggelseId={computedMatch.params.id}
                  BilderButtonsContainer={BilderButtonsContainer}
                  checkbox
                />
                <Ettersendelser />
                <NyeEttersendelser />
                <KlageinformasjonContainer
                  ileggelseId={computedMatch.params.id}
                />
                <SystemloggContainer ileggelseId={computedMatch.params.id} />
                <MakuleringsarsakContainers />
                <HistorikkOgKommentarerContainer
                  ileggelseId={computedMatch.params.id}
                />
              </div>
            </Tab>
            <Tab eventKey={2} title="Kart">
              <div className="ileggelse-tab-content-wrapper">
                <Map lat={ileggelse.latitude} lng={ileggelse.longitude} />
              </div>
            </Tab>
          </Tabs>
        </div>
      );
    }
    return (
      <div>
        <Spinner isLoading />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ileggelse: state.ileggelse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getIleggelse, getIleggelseEttersendelser, getBybetjenter },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(IleggelseDetaljPage));
