import { actionType } from "../actions/overtredelseskoderMiljogebyrActions";
import { CreateNewReducer } from "../utils/reducerUtils";

const INITIAL_STATE = [];

export const overtredelseskoderMiljogebyrReducer = (
  state = INITIAL_STATE,
  action
) => CreateNewReducer(state, action, actionType);

export default overtredelseskoderMiljogebyrReducer;
