import axios from "../services/axios";
import * as api from "../constants/api";
import * as handle from "../utils/actionUtils";

export const GET_ILEGGELSELEVERINGSTYPER_SUCCESS =
  "GET_ILEGGELSE_LEVERINGSTYPER_SUCCESS";
export const GET_ILEGGELSELEVERINGSTYPER__FAILURE =
  "GET_ILEGGELSE_LEVERINGSTYPER__FAILURE";

export function getIleggelseleveringstyper() {
  return (dispatch, getState) => {
    return axios
      .get(api.GET_ILEGGELSELEVERINGSTYPER_URL)
      .then(handle.success(dispatch, GET_ILEGGELSELEVERINGSTYPER_SUCCESS))
      .catch(handle.error(dispatch, GET_ILEGGELSELEVERINGSTYPER__FAILURE));
  };
}
