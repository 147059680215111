import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import axios from "axios";
import { saveAs } from "file-saver";
import ImageEditor from "../ImageEditor";
import { dataURLToImage, imageToCanvas } from "../../../utils/imageUtils";

const ImageEditorPage = ({
  computedMatch,
  history,
  getBilde,
  postIleggelserBilder,
}) => {
  const ileggelseId = computedMatch.params.id;
  const [bilde, setBilde] = useState(undefined);
  const [isGetBildeCalled, setIsGetBildeCalled] = useState(false);
  const [imageEditorRef, setImageEditorRef] = useState();
  const [isButtonEventListenersSet, setIsButtonEventListenerSet] =
    useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const source = axios.CancelToken.source();

    if (!isGetBildeCalled) {
      setIsGetBildeCalled(true);
      getBilde().then((result) => {
        setBilde(result);
      });
    }

    return () => {
      source.cancel("componentWillUnmount triggered");
    };
  }, [isGetBildeCalled, getBilde]);

  useEffect(() => {
    let headerButtonsElements = document.getElementsByClassName(
      "tui-image-editor-header-buttons"
    );
    let loadButtonElements = document.getElementsByClassName(
      "tui-image-editor-load-btn"
    );
    let downloadButtonElements = document.getElementsByClassName(
      "tui-image-editor-download-btn"
    );
    if (
      bilde &&
      headerButtonsElements.length > 0 &&
      loadButtonElements.length > 1 &&
      downloadButtonElements.length > 1
    ) {
      loadButtonElements[1].parentElement.remove();

      let tilbakeButtonElement = document.createElement("button");
      tilbakeButtonElement.setAttribute("id", "tilbakeButton");
      tilbakeButtonElement.innerText = "Tilbake";
      headerButtonsElements[0].prepend(tilbakeButtonElement);
      document
        .querySelector("#tilbakeButton")
        .addEventListener("click", (e) => handleTilbakeButtonClick(e, history));

      let lastNedButtonElement = document.createElement("button");
      lastNedButtonElement.setAttribute("id", "lastNedButton");
      lastNedButtonElement.setAttribute(
        "class",
        "tui-image-editor-download-btn"
      );
      lastNedButtonElement.setAttribute(
        "style",
        "background-color: #fdba3b;border: 1px solid #fdba3b;color: #fff;font-family: 'Noto Sans', sans-serif;font-size: 12px"
      );
      lastNedButtonElement.innerText = "Last ned";
      downloadButtonElements[1].parentElement.replaceChild(
        lastNedButtonElement,
        downloadButtonElements[1]
      );

      let lastOppButtonElement = document.createElement("button");
      lastOppButtonElement.setAttribute("id", "lastOppButton");
      lastOppButtonElement.setAttribute(
        "class",
        "tui-image-editor-download-btn"
      );
      lastOppButtonElement.setAttribute(
        "style",
        "background-color: #fdba3b;border: 1px solid #fdba3b;color: #fff;font-family: 'Noto Sans', sans-serif;font-size: 12px"
      );
      lastOppButtonElement.innerText = "Last opp";
      headerButtonsElements[0].append(lastOppButtonElement);

      return () => {
        let tilbakeButtonElement = document.querySelector("#tilbakeButton");
        if (tilbakeButtonElement) {
          tilbakeButtonElement.removeEventListener("click", (e) =>
            handleTilbakeButtonClick(e, history)
          );
        }
      };
    }
    return () => {};
  }, [bilde, history]); // bilde is set only one time

  useEffect(() => {
    let lastNedButtonElement = document.querySelector("#lastNedButton");
    let lastOppButtonElement = document.querySelector("#lastOppButton");
    if (
      !isButtonEventListenersSet &&
      imageEditorRef &&
      bilde &&
      bilde.src &&
      lastNedButtonElement &&
      lastOppButtonElement &&
      postIleggelserBilder
    ) {
      setIsButtonEventListenerSet(true);
      lastNedButtonElement.addEventListener("click", (e) =>
        handleLastNedButtonClick(e, imageEditorRef)
      );
      lastOppButtonElement.addEventListener("click", (e) => {
        handleLastOppButtonClick(
          e,
          imageEditorRef,
          lastOppButtonElement,
          ileggelseId,
          postIleggelserBilder
        );
      });
      return () => {
        lastNedButtonElement.removeEventListener("click", (e) =>
          handleLastNedButtonClick(e, imageEditorRef)
        );
        lastOppButtonElement.removeEventListener("click", (e) =>
          handleLastOppButtonClick(
            e,
            imageEditorRef,
            lastOppButtonElement,
            ileggelseId,
            postIleggelserBilder
          )
        );
      };
    }
    return () => {};
  }, [
    isButtonEventListenersSet,
    imageEditorRef,
    bilde,
    postIleggelserBilder,
    ileggelseId,
  ]); // bilde is set only one time

  return (
    <React.Fragment>
      {bilde && (
        <ImageEditor
          setImageEditorRef={setImageEditorRef}
          imageSrc={bilde}
          usageStatistics={false}
        />
      )}
      {!bilde && null}
    </React.Fragment>
  );
};

ImageEditorPage.propTypes = {
  history: PropTypes.object.isRequired,
  computedMatch: PropTypes.object.isRequired,
  getBilde: PropTypes.func.isRequired,
  postIleggelserBilder: PropTypes.func.isRequired,
};

const handleTilbakeButtonClick = (e, history) => {
  history.goBack();
};

const handleLastNedButtonClick = (e, imageEditorRef) => {
  var supportingFileAPI = !!(
    window.File &&
    window.FileList &&
    window.FileReader
  );
  var imageName = imageEditorRef.getImageName();
  var dataURLPng = imageEditorRef.toDataURL();

  if (supportingFileAPI) {
    dataURLToImage(dataURLPng, (image) => {
      const canvas = imageToCanvas(image);
      canvas.toBlob(async (blob) => {
        let type = blob.type.split("/")[1];
        if (imageName.split(".").pop() !== type) {
          imageName += "." + type;
        }
        // Library: FileSaver - saveAs
        saveAs(blob, imageName); // eslint-disable-line
      }, "image/jpeg");
    });
  } else {
    alert("This browser needs a file-server");
    let w = window.open();
    w.document.body.innerHTML = "<img src=" + dataURLPng + ">";
  }
};

const handleLastOppButtonClick = async (
  e,
  imageEditorRef,
  lastOppButtonElement,
  ileggelseId,
  postIleggelserBilder
) => {
  lastOppButtonElement.disabled = true;
  lastOppButtonElement.innerText = "Laster opp...";

  const dataURLPng = imageEditorRef.toDataURL();
  dataURLToImage(dataURLPng, (image) => {
    const canvas = imageToCanvas(image);
    canvas.toBlob(async (blob) => {
      await postIleggelserBilder(ileggelseId, blob);

      lastOppButtonElement.disabled = false;
      lastOppButtonElement.innerText = "Last opp";
    }, "image/jpeg");
  });
};

export default withRouter(ImageEditorPage);
