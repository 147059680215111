export const MaVelgeFiltrering = "Må velge filtrering";
export const TimeIsNotValid = "Tid er ikke gyldig";
export const DateFromIsRequired = "Dato fra må velges.";
export const DateFromIsNotValid = "Dato fra er ikke gyldig.";
export const TimeFromIsNotValid = "Tid fra er ikke gyldig.";
export const DateToIsRequired = "Dato til må velges.";
export const DateToIsNotValid = "Dato til er ikke gyldig.";
export const TimeToIsNotValid = "Tid til er ikke gyldig.";
export const FraDatoAndTilDato = "Dato fra må være før Dato til.";
export const commentsIsRequired = "Kommentar må fylles ut.";
export const commentHasToBeBetween = (min, max) =>
  `Kommentar må være mellom ${min} og ${max} tegn.`;
export const DatoIsRequired = "Dato må fylles ut.";
export const DatoenErIkkeGyldig = "Datoen er ikke gyldig";
export const AArIsRequired = "År må velges.";
export const mustBeAPositiveInteger = "Må være et positivt heltall.";
export const mustBeAnInteger = "Må være et heltall.";
export const IleggelsestypeIsRequired = "Ileggelsestype må velges.";
export const UserHasNoAccessToHGK =
  "Din bruker har ikke tilgang til HGK, vennligst kontakt Digital Utvikling hos Bymiljøetaten for å få tilgang.";
export const searchMeldingError = "Ditt søk ga ingen treff.";
export const NetworkError =
  "Feil ved nettverkstilkobling til servicen. Vennligst kontakt Servicedesk.";
export const NotHandledRRessponseErrorsFromService =
  "En feil oppstod. Vennligst kontakt Servicedesk.";
export const popUpBlocked =
  "Det ser ut som din nettleser blokkerer popups, for å skrive ut må du tillate popups. Veiledning finner du <a target='_blank' href='https://support.google.com/chrome/answer/95472?co=GENIE.Platform%3DDesktop&hl=no'>her</a>";
export const ikkeGyldigSaksnummer =
  "Dette er ikke et gyldig saksnummer. Eksempel 22/5000.";
