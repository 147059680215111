import React from "react";
import styled, { keyframes } from "styled-components";

/**
 * Spinner component set to replace
 * https://www.npmjs.com/package/react-loaders-spinners
 */

const spin = keyframes`
 from {
   transform: rotate(0deg);
 }
 to {
   transform: rotate(360deg)
 }
 `;

const Spinner = styled.div`
   border-radius: 50%;
   animation: ${spin} 1s linear infinite;
   width: ${(props) => props.width}px;
   height: ${(props) => props.height}px;
   border ${(props) => props.thickness}px solid  ${(props) => props.sColor};
   border-top: ${(props) => props.thickness}px solid ${(props) => props.pColor};
 `;

const SpinLoader = (props) => {
  return (
    <Spinner
      width={props.width}
      height={props.height}
      thickness={props.thickness}
      sColor={props.sColor}
      pColor={props.pColor}
    ></Spinner>
  );
};

SpinLoader.defaultProps = {
  width: 50,
  height: 50,
  thickness: 10,
  sColor: "#f3f3f3",
  pColor: "#555",
};

export default SpinLoader;
