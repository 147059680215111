import React from "react";
import { Switch, Redirect } from "react-router-dom";

import AuthorizedRoutes from "../components/Routes/AuthorizedRoutes";

import InnsynIleggelsePage from "../components/Innsyn/InnsynIleggelsePage";
import InnsynIleggelseDetaljPage from "../components/Innsyn/InnsynIleggelseDetaljPage";
import InnsynMiljogebyrPage from "../components/Innsyn/InnsynMiljogebyrPage";
import InnsynMiljogebyrDetaljPage from "../components/Innsyn/InnsynMiljogebyrDetaljPage";
import InnsynMotorvognPage from "../components/Innsyn/InnsynMotorvognPage";
import InnsynMotorvognUtskriftPage from "../components/Innsyn/InnsynMotorvognUtskriftPage";

import { getRedirectPath } from "../utils/pathnameUtils";
import sitePath from "../constants/sitePaths";
import { bypatruljen, juridisk, innsyn } from "../constants/roles";
import IleggelseOrginalbilde from "../components/Common/Ileggelser/Detalj/IleggelseOrginalbilde";
import MiljogebyrOrginalbilde from "../components/Common/Miljogebyr/Detalj/MiljogebyrOrginalbilde";
import TidskontrollOrginalbilde from "../components/Common/Miljogebyr/Detalj/TidskontrollOrginalbilde";

const Innsyn = () => {
  return (
    <Switch>
      <AuthorizedRoutes
        exact
        path={sitePath.innsyn.ileggelser.oversikt}
        roles={[
          innsyn.serviceavdelingVaktsentralen,
          innsyn.vaktsentralen,
          innsyn.serviceavdeling,
          innsyn.kemnerkontoret,
          innsyn.seksjonssjefVaktsentralen,
        ]}
        component={InnsynIleggelsePage}
      />
      <AuthorizedRoutes
        exact
        path={sitePath.innsyn.ileggelser.detalj.shortUrl}
        roles={[
          innsyn.serviceavdelingVaktsentralen,
          innsyn.vaktsentralen,
          innsyn.serviceavdeling,
          innsyn.kemnerkontoret,
          innsyn.seksjonssjefVaktsentralen,
        ]}
        component={InnsynIleggelseDetaljPage}
      />
      <AuthorizedRoutes
        exact
        path={sitePath.innsyn.ileggelser.detalj.bilde}
        roles={[
          innsyn.serviceavdelingVaktsentralen,
          innsyn.vaktsentralen,
          innsyn.serviceavdeling,
          innsyn.kemnerkontoret,
          innsyn.seksjonssjefVaktsentralen,
        ]}
        component={IleggelseOrginalbilde}
      />
      <Redirect
        path={sitePath.innsyn.ileggelser.shortUrl}
        to={sitePath.innsyn.ileggelser.oversikt}
      />
      <AuthorizedRoutes
        exact
        path={sitePath.innsyn.miljogebyrer.oversikt}
        roles={[
          innsyn.serviceavdelingVaktsentralen,
          innsyn.vaktsentralen,
          innsyn.serviceavdeling,
          innsyn.kemnerkontoret,
          innsyn.seksjonssjefVaktsentralen,
        ]}
        component={InnsynMiljogebyrPage}
      />
      <AuthorizedRoutes
        exact
        path={sitePath.innsyn.miljogebyrer.detalj.shortUrl}
        roles={[
          innsyn.serviceavdelingVaktsentralen,
          innsyn.vaktsentralen,
          innsyn.serviceavdeling,
          innsyn.kemnerkontoret,
          innsyn.seksjonssjefVaktsentralen,
        ]}
        component={InnsynMiljogebyrDetaljPage}
      />
      <AuthorizedRoutes
        exact
        path={sitePath.innsyn.miljogebyrer.detalj.bilde}
        roles={[
          innsyn.serviceavdelingVaktsentralen,
          innsyn.vaktsentralen,
          innsyn.serviceavdeling,
          innsyn.kemnerkontoret,
          innsyn.seksjonssjefVaktsentralen,
        ]}
        component={MiljogebyrOrginalbilde}
      />
      <AuthorizedRoutes
        exact
        path={sitePath.innsyn.miljogebyrer.detalj.miljokontroll.bilde}
        roles={[
          innsyn.serviceavdelingVaktsentralen,
          innsyn.vaktsentralen,
          innsyn.serviceavdeling,
          innsyn.kemnerkontoret,
          innsyn.seksjonssjefVaktsentralen,
        ]}
        component={TidskontrollOrginalbilde}
      />
      <Redirect
        path={sitePath.innsyn.miljogebyrer.shortUrl}
        to={sitePath.innsyn.miljogebyrer.oversikt}
      />
      <AuthorizedRoutes
        exact
        path={sitePath.innsyn.motorvogn.shortUrl}
        roles={[
          ...Object.values(bypatruljen),
          ...Object.values(juridisk),
          innsyn.serviceavdelingVaktsentralen,
          innsyn.vaktsentralen,
          innsyn.serviceavdeling,
          innsyn.kemnerkontoret,
          innsyn.seksjonssjefVaktsentralen,
        ]}
        component={InnsynMotorvognPage}
      />
      <AuthorizedRoutes
        exact
        path={sitePath.innsyn.motorvogn.utskrift}
        roles={[
          ...Object.values(bypatruljen),
          ...Object.values(juridisk),
          innsyn.serviceavdelingVaktsentralen,
          innsyn.vaktsentralen,
          innsyn.serviceavdeling,
          innsyn.kemnerkontoret,
          innsyn.seksjonssjefVaktsentralen,
        ]}
        component={InnsynMotorvognUtskriftPage}
      />
      <Redirect
        path={sitePath.innsyn.shortUrl}
        to={getRedirectPath(sitePath.innsyn.shortUrl)}
      />
    </Switch>
  );
};

export default Innsyn;
