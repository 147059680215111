import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import map from "lodash/map";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Table from "../../Common/Tables/BaseTable";
import OvertredelseskoderIleggelserUpdateModal from "./UpdateModals/OvertredelseskoderIleggelseModal";

import { getOvertredelserkoderIleggelser } from "../../../actions/overtredelseskoderIleggelserActions";
import getOvertredelseskoderIleggelserList from "../../../selectors/overtredelseskoderIleggelserSelector";

import { sanitizeHtml } from "../../../utils/sanitizationUtils";
import { authorized } from "../../../utils/authorizationUtils";

import * as title from "../../../constants/titles";
import { juridiskArray } from "../../../constants/roles";

import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";

class OvertredelseskoderIleggelseList extends Component {
  static propTypes = {
    authorizedToCreateAndEdit: PropTypes.bool.isRequired,
    getAllAction: PropTypes.func,
    overtredelseskoderIleggelser: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        kode: PropTypes.string,
        beskrivelse: PropTypes.string,
        forklaring: PropTypes.string,
        overtredelseGruppe: PropTypes.shape({
          id: PropTypes.number,
          navn: PropTypes.string,
          ileggelsestypeId: PropTypes.number,
        }),
        belop: PropTypes.number,
        aktiv: PropTypes.shape({
          aktivBool: PropTypes.bool,
          aktivString: PropTypes.string,
        }),
      })
    ),
  };

  constructor(props) {
    super(props);
    this.renderRow = this.renderRow.bind(this);

    this.state = {
      valgtOvertredelseId: undefined,
      updateModalIsOpen: false,
    };
  }
  componentDidMount() {
    document.title = title.overtredelseskoderIleggelser;
    this.props.getOvertredelserkoderIleggelser();
  }
  renderInternkoder = (internkoder) => {
    if (internkoder && internkoder.length > 0) {
      const arr = internkoder.map((i) => i.kode);
      return arr.map((item) => (
        <div key={item}>
          {item}
          <br />
        </div>
      ));
    }
    return "";
  };

  getKreverAvstandTekst = (kreverAvstand) => {
    if (kreverAvstand) return "JA";
    else if (kreverAvstand === false) return "VALGFRITT";
    else return "NEI";
  };

  toggleDetailRow = (overtredelseId) => {
    const { valgtOvertredelseId, updateModalIsOpen } = this.state;

    if (valgtOvertredelseId === overtredelseId && !updateModalIsOpen) {
      this.setState({ valgtOvertredelseId: undefined });
    } else if (valgtOvertredelseId !== overtredelseId && !updateModalIsOpen) {
      this.setState({ valgtOvertredelseId: overtredelseId });
    }
  };

  getCollapsibleArrow = (overtredelseskodeId) => {
    if (this.state.valgtOvertredelseId === overtredelseskodeId) {
      return (
        <FontAwesomeIcon
          icon={faCaretDown}
          size="lg"
          fixedWidth
          style={{ cursor: "pointer" }}
          onClick={() => this.toggleDetailRow(overtredelseskodeId)}
        />
      );
    } else {
      return (
        <FontAwesomeIcon
          icon={faCaretRight}
          size="lg"
          fixedWidth
          style={{ cursor: "pointer" }}
          onClick={() => this.toggleDetailRow(overtredelseskodeId)}
        />
      );
    }
  };

  updateModalIsOpenCallback = (isOpen) => {
    this.setState({ updateModalIsOpen: isOpen });
  };

  renderRow = () => {
    return map(
      this.props.overtredelseskoderIleggelser,
      (overtredelseskoderIleggelse, index) => {
        const rowClassName =
          index % 2 === 0
            ? { backgroundColor: "#ffffff", color: "#4d4d4d" }
            : { backgroundColor: "#f3f3f3", color: "#4d4d4d" };

        const sanitizedForklaring = overtredelseskoderIleggelse.forklaring
          ? sanitizeHtml(
              overtredelseskoderIleggelse.forklaring?.replaceAll("\n", "<br>"),
              ["br"]
            )
          : "";

        return (
          <React.Fragment
            key={`overtredelseskoderIleggelserTableBodyTr${overtredelseskoderIleggelse.id.toString()}`}
          >
            <tr
              onClick={() =>
                this.toggleDetailRow(overtredelseskoderIleggelse.id)
              }
              aria-controls="Toggles vis detalj"
              style={{ ...rowClassName, cursor: "pointer" }}
              tabIndex={0}
            >
              <td>
                {this.getCollapsibleArrow(overtredelseskoderIleggelse.id)}
              </td>
              <td>{overtredelseskoderIleggelse.id.toString()}</td>
              <td>{overtredelseskoderIleggelse.beskrivelse}</td>
              <td>{overtredelseskoderIleggelse.overtredelseGruppe.navn}</td>
              <td>{overtredelseskoderIleggelse.belop}</td>
              <td>
                {this.getKreverAvstandTekst(
                  overtredelseskoderIleggelse.kreverAvstand
                )}
              </td>
              <td>{overtredelseskoderIleggelse.aktiv.aktivString}</td>
              <td>
                {(this.props.authorizedToCreateAndEdit ||
                  authorized(juridiskArray)) && (
                  <OvertredelseskoderIleggelserUpdateModal
                    isOpenCallback={this.updateModalIsOpenCallback}
                    overtredelsekoderIleggelse={overtredelseskoderIleggelse}
                  />
                )}
              </td>
            </tr>
            {this.state.valgtOvertredelseId ===
              overtredelseskoderIleggelse.id && (
              <tr>
                <td colSpan={2} style={{ ...rowClassName, border: 0 }} />
                <td colSpan={1} style={{ ...rowClassName, border: 0 }}>
                  <div style={{ display: "flex", columnGap: 10 }}>
                    <div style={{ fontWeight: 700, whiteSpace: "nowrap" }}>
                      Forklaring for innbyggere:
                    </div>
                    <div
                      dangerouslySetInnerHTML={{ __html: sanitizedForklaring }}
                    ></div>
                  </div>
                </td>
                <td colSpan={5} style={{ ...rowClassName, border: 0 }} />
              </tr>
            )}
          </React.Fragment>
        );
      }
    );
  };

  render() {
    const headerRow = [
      "",
      "ID",
      "Forklaring",
      "Gruppe",
      "Beløp",
      "Krever meter",
      "Status",
      "",
    ];
    return (
      <Table
        id="overtredelseskoderIleggelse-liste"
        className="table-wrapper-horizontal-scroll-white-space-normal"
        headerRow={headerRow}
        defaultStyle
        stripedHeaderStyle
      >
        <tbody>{this.renderRow()}</tbody>
      </Table>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    overtredelseskoderIleggelser:
      state.overtredelseskoderIleggelser &&
      getOvertredelseskoderIleggelserList(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getOvertredelserkoderIleggelser }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OvertredelseskoderIleggelseList);
