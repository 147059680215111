import { map, includes, some } from "lodash";
import { filter as baseFilter } from "lodash";
import { last as baseLast } from "lodash";
import { sortBy as baseSortBy } from "lodash";
import { orderBy as basOrderBy } from "lodash";

export const contains = (arr, pattern) => includes(arr, pattern);

export const containsObject = (arr, obj) => {
  const result = [];
  map(arr, i => {
    if (some(arr, obj)) result.push(i);
  });
  return result;
};

export const filter = (arr, key, value) => {
  return baseFilter(arr, i => {
    return i.key === value;
  });
};

export const lastItem = (array, key = "id") => {
  if (array.length > 0) {
    const sortedArray = baseSortBy(array, [key]);
    const lastItem = baseLast(sortedArray, key);
    return lastItem;
  }
};

export const lastItemId = (array, key = "id") => {
  const lastItemInArray = lastItem(array, key);
  return lastItemInArray && lastItemInArray[key];
};

export const orderBy = (arr, key = "id", order = "desc") => {
  if (arr.length === 0) return arr;
  return basOrderBy(arr, [key], [order]);
};
