import React from "react";
import PropTypes from "prop-types";
import LoadButton from "../../Common/LoadButton";
import DatetimePicker from "../../Common/Forms/DatetimePickerEnhanced";
import TextInput from "../../Common/Forms/TextInput";

export default class FilterForm extends React.Component {
  static propTypes = {
    showSokSpinner: PropTypes.bool,
    ettersendelseFilterFormState: PropTypes.shape({
      ileggelsesnummer: PropTypes.string,
      fraDato: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      tilDato: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      fraTid: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      tilTid: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      kjennemerke: PropTypes.string,
      tjenestenummer: PropTypes.string,
      gatenavn: PropTypes.string,
      internkode: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      status: PropTypes.PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      fraDatoValidationErrorText: PropTypes.string,
      fraTidValidationErrorText: PropTypes.string,
      tilDatoValidationErrorText: PropTypes.string,
      tilTidValidationErrorText: PropTypes.string,
      validationError: PropTypes.shape({
        ileggelsesnummer: PropTypes.string,
        tjenestenummer: PropTypes.string,
      }),
    }),
    betjentgrupper: PropTypes.array,
    ileggelsestyper: PropTypes.array,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    handleChangeToInitState: PropTypes.func,
    handleSubmit: PropTypes.func,
  };

  render() {
    const {
      showSokSpinner,
      ettersendelseFilterFormState,
      handleChange,
      handleBlur,
      handleSubmit,
    } = this.props;
    return (
      <form id="ettersendelse-filter" onSubmit={handleSubmit}>
        <div className="filter-flexbox-container ettersendelse-filter-flexbox-item">
          <TextInput
            label="Ileggelsesnummer"
            name="ileggelsesnummer"
            onChange={handleChange}
            onBlur={handleBlur}
            value={ettersendelseFilterFormState.ileggelsesnummer}
            validationError={
              ettersendelseFilterFormState.validationError.ileggelsesnummer
            }
          />
          <DatetimePicker
            id="fraDato"
            name="fraDato"
            locale="nb"
            label="Dato fra"
            onChange={handleChange}
            value={ettersendelseFilterFormState.fraDato}
            validationError={
              ettersendelseFilterFormState.fraDatoValidationErrorText
            }
            timeFormat={false}
          />
          <DatetimePicker
            id="fraTid"
            name="fraTid"
            locale="nb"
            label="Tid fra"
            onChange={handleChange}
            value={ettersendelseFilterFormState.fraTid}
            validationError={
              ettersendelseFilterFormState.fraTidValidationErrorText
            }
            dateFormat={false}
          />
          <DatetimePicker
            id="tilDato"
            name="tilDato"
            locale="nb"
            label="Dato til"
            onChange={handleChange}
            value={ettersendelseFilterFormState.tilDato}
            validationError={
              ettersendelseFilterFormState.tilDatoValidationErrorText
            }
            timeFormat={false}
          />
          <DatetimePicker
            id="tilTid"
            name="tilTid"
            locale="nb"
            label="Tid til"
            onChange={handleChange}
            value={ettersendelseFilterFormState.tilTid}
            validationError={
              ettersendelseFilterFormState.tilTidValidationErrorText
            }
            dateFormat={false}
          />
          <TextInput
            label="Tjenestenummer"
            name="tjenestenummer"
            onChange={handleChange}
            onBlur={handleBlur}
            value={ettersendelseFilterFormState.tjenestenummer}
            validationError={
              ettersendelseFilterFormState.validationError.tjenestenummer
            }
          />
        </div>
        <div style={{ height: 20 }} />
        <div>
          <LoadButton
            buttonProps={{
              onClick: this.props.handleChangeToInitState,
              className: "btn-link",
              style: { height: 40, paddingRight: 17, paddingLeft: 0 },
            }}
            spinnerProps={{ pColor: "#ffffff", sColor: "#4d4d4d" }}
          >
            Tilbakestill
          </LoadButton>
          <LoadButton
            isLoading={showSokSpinner}
            buttonProps={{
              type: "submit",
              className: "btn-bym-action btn-bym-large",
              disabled: showSokSpinner,
            }}
            spinnerProps={{ pColor: "#ffffff", sColor: "#4d4d4d" }}
          >
            Søk
          </LoadButton>
        </div>
      </form>
    );
  }
}
