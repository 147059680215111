export const getOvertredelserString = (
  overtredelse1,
  overtredelse2,
  overtredelse3
) => {
  let overtredelser = "";
  if (overtredelse1 && overtredelse2 && overtredelse3) {
    overtredelser = `${overtredelse1}/${overtredelse2}/${overtredelse3}`;
  } else if (overtredelse1 && overtredelse2) {
    overtredelser = `${overtredelse1}/${overtredelse2}`;
  } else if (overtredelse1) {
    overtredelser = `${overtredelse1}`;
  }
  return overtredelser;
};

export const ileggelseClientSpecificUtils = { getOvertredelserString };

export default ileggelseClientSpecificUtils;
