// import moment from "moment";
// eslint-disable-next-line
import { tz } from "moment-timezone";
import _ from "lodash";
import {
  handleChangeActionType,
  handleChangeToInitStateActionType,
} from "../../actions/handleChangeAction";

export const INITIAL_STATE = {
  // input
  ileggelsesnummer: "",
  fraDato: "",
  fraTid: "",
  tilDato: "",
  tilTid: "",
  kjennemerke: "",
  tjenestenummer: "",
  gatenavn: "",
  internkode: "",
  type: "",
  teamId: "",

  // Statuser
  isVenter: false,
  isTilEttersendelse: true,
  isEttersendelseTilGodkjenning: null,
  isEttersendt: null,
  isMakulert: false,
  isKlarTilKemner: null, // Skal stå som isKlarTilKemner tils endret på backend
  isStoppetAvSystemet: null,
  isFeiletKemner: null,
  isSendtKemner: null, // Skal stå isSendtKemner tils backend er endret til isSendtIne
  isBetalt: null,
  isElsparksykkelGebyr: false,

  include: ["ettersendelse", "rettelse"],

  fraDatoValidationErrorText: null,
  fraTidValidationErrorText: null,
  tilDatoValidationErrorText: null,
  tilTidValidationErrorText: null,
  validationError: {
    ileggelsesnummer: null,
    tjenestenummer: null,
  },
};

export default function ettersendelserFilterFormReducer(
  state = {
    ...INITIAL_STATE,
    validationError: { ...INITIAL_STATE.validationError },
  },
  action
) {
  switch (action.type) {
    case handleChangeActionType("ETTERSENDELSER_FILTER_FORM"):
      _.set(state, action.variableName, action.payload);
      return { ...state };
    case handleChangeToInitStateActionType("ETTERSENDELSER_FILTER_FORM"):
      return {
        ...INITIAL_STATE,
        validationError: { ...INITIAL_STATE.validationError },
      };
    default:
      return state;
  }
}
