import React from "react";
import PropTypes from "prop-types";

import GridTable from "../Common/GridTable";

import {
  toNorwegianDatetime,
  formatDateTimeHavingNorwegianDateTime,
} from "../../utils/datetimeUtils";

const getMotorvognInfo = (motorvogn) => {
  const rows = [
    [
      {
        name: "Registreringsnummer",
        value: motorvogn.kjennemerke,
      },
      {
        name: "Understellsnummer",
        value: motorvogn.understellsnummer,
      },
    ],
    [
      {
        name: "Registrert",
        value:
          motorvogn.registreringsStatus === "REGISTRERT"
            ? formatDateTimeHavingNorwegianDateTime(
                motorvogn.registreringsStatusDato
              )
            : "-",
      },
      {
        name: "Avregistrert",
        value:
          motorvogn.registreringsStatus === "AVREGISTRERT"
            ? formatDateTimeHavingNorwegianDateTime(
                motorvogn.registreringsStatusDato
              )
            : "-",
      },
    ],
    [
      {
        name: "Modell",
        value: motorvogn.modell,
      },
      {
        name: "Årsmodell",
        value:
          motorvogn.forsteGangRegistrertDato &&
          toNorwegianDatetime(motorvogn.forsteGangRegistrertDato).year(),
      },
      {
        name: "Bilmerke",
        value: motorvogn.merke,
      },
      {
        name: "Bilmerkekode",
        value: motorvogn.merkeKode,
      },
      {
        name: "Kjøretøy type",
        value: motorvogn.typeNavn,
      },
      {
        name: "Farge",
        value: motorvogn.farge,
      },
    ],
    [
      {
        name: "Totalvekt",
        value:
          motorvogn.tillattTotalvekt && motorvogn.tillattTotalvekt.toString(),
      },
      {
        name: "Euroklasse",
        value: motorvogn.euroklasse,
      },
    ],
    [
      {
        name: "Eier",
        value: motorvogn.eierPersonNavn || motorvogn.eierOrgNavn,
      },
      {
        name: "Adresse",
        value: motorvogn.eierAdresse,
      },
      {
        name: "Postnummer",
        value: motorvogn.eierPostNr,
      },
      {
        name: "Poststed",
        value: motorvogn.eierPostSted,
      },
    ],
  ];
  const medeierRow = [
    {
      name: "Medeier",
      value: motorvogn.medEierPersonNavn || motorvogn.medEierOrgNavn,
    },
    {
      name: "Adresse for medeier",
      value: motorvogn.medEierAdresse,
    },
    {
      name: "Postnummer for medeier",
      value: motorvogn.medEierPostNr,
    },
    {
      name: "Poststed for medeier",
      value: motorvogn.medEierPostSted,
    },
  ];

  if (motorvogn.medEierPersonNavn || motorvogn.medEierOrgNavn) {
    return [...rows, medeierRow];
  }
  return rows;
};

export const InnsynMotorvognInfo = ({ motorvogn }) => {
  return (
    <div style={{ paddingBottom: 40 }}>
      <div
        style={{
          height: 60,
          backgroundColor: "#f3f3f3",
          paddingTop: 18,
          paddingLeft: 20,
        }}
      >
        <span
          style={{
            fontSize: 19,
          }}
        >
          {motorvogn.kjennemerke}
        </span>
      </div>
      <GridTable
        name={motorvogn.kjennemerke}
        oneColoredGridTable
        rows={getMotorvognInfo(motorvogn)}
      />
    </div>
  );
};

InnsynMotorvognInfo.propTypes = {
  motorvogn: PropTypes.object,
};

export default InnsynMotorvognInfo;
