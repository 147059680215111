import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import LoadButton from "../../Common/LoadButton";
import ListContainer from "./ListContainer";
import createPaginationControl from "../../Common/createPaginationControl";
import NavigationBarContainer from "../NavigationBarContainer";
import Spinner from "../../Common/Spinner";

import { getEttersendelserTilGodkjenningList } from "../../../selectors/ettersendelserTilGodkjenningSelector";
import { getEttersendelserTilGodkjenning as getEttersendelserTilGodkjenningAction } from "../../../actions/ettersendelserTilGodkjenningActions";
import { putIleggelseEttersendelser as putIleggelseEttersendelserActions } from "../../../actions/ileggelseEttersendelserActions";
import { getAllIleggelseEttersendelserbreverPdf } from "../../../actions/ileggelseEttersendelsebrevActions";
import { getSingleMotorvognByQuery as getSingleMotorvognByQueryAction } from "../../../actions/motorvognActions";

import * as title from "../../../constants/titles";

import "./EttersendelserTilGodkjenningPage.css";

const PaginationControl = createPaginationControl(
  "ettersendelserTilGodkjenning",
  {
    apiCallAction: getEttersendelserTilGodkjenningAction,
    queriesForApiReducerName: "forms.ettersendelserTilGodkjenningFilterForm",
  },
  getEttersendelserTilGodkjenningList
);

class EttersendelserTilGodkjenningContainer extends Component {
  static propTypes = {
    ettersendelserTilGodkjenning: PropTypes.shape({
      items: PropTypes.array,
      total: PropTypes.number,
    }),
    getEttersendelserTilGodkjenning: PropTypes.func.isRequired,
    putIleggelseEttersendelser: PropTypes.func.isRequired,
    getSingleMotorvognByQuery: PropTypes.func.isRequired,
  };

  state = {
    showGodkjennOgSkrivUtSpinner: false,
    waitingForGetEttersendelserTilGodkjenning: false,
    checkedEttersendelserTilGodkjenning: {
      ..._.reduce(
        this.props.ettersendelserTilGodkjenning.items.map((e) => {
          return { [e.ettersendelse.id]: false };
        }),
        _.extend
      ),
      checkboxAllEttersendelserTilGodkjenning: false,
    },
    max: 10,
  };

  componentDidMount() {
    document.title = title.ileggelse.ettersendelserTilGodkjenning;
    if (
      this.props.ettersendelserTilGodkjenning.page &&
      this.props.ettersendelserTilGodkjenning.count
    ) {
      this.setState({ waitingForGetEttersendelserTilGodkjenning: true });
      this.props
        .getEttersendelserTilGodkjenning(
          this.getEttersendelserTilGodkjenningFilter()
        )
        .then((ettersendelserTilGodkjenning) => {
          this.setState({ waitingForGetEttersendelserTilGodkjenning: false });
          let ettersendelserTilGodkjenningMissingMotorvognInfo =
            ettersendelserTilGodkjenning.filter(
              (etg) =>
                etg.ettersendelse &&
                (!etg.ettersendelse.navn ||
                  !etg.ettersendelse.gate ||
                  !etg.ettersendelse.postnummer ||
                  !etg.ettersendelse.poststed)
            );
          Promise.all(
            ettersendelserTilGodkjenningMissingMotorvognInfo.map((etgmmi) => {
              if (etgmmi.nasjon === "Norge") {
                return this.props.getSingleMotorvognByQuery({
                  kjennemerke: etgmmi.kjennemerke,
                  dato: etgmmi.ileggelsesdatoTil,
                });
              }
              return null;
            })
          )
            .then((responseArray) => {
              let putIleggelseEttersendelserDataArray = [];
              for (let i = 0; i < responseArray.length; i++) {
                let motorvogn =
                  responseArray[i] && responseArray[i].data.result;
                if (!motorvogn || !motorvogn.kjennemerke) {
                  continue;
                }
                let ettersendelseTilGodkjenningMissingMotorvognInfoArray =
                  ettersendelserTilGodkjenningMissingMotorvognInfo.filter(
                    (etg) =>
                      etg.kjennemerke.replace(" ", "") ===
                      motorvogn.kjennemerke.replace(" ", "")
                  );
                if (
                  ettersendelseTilGodkjenningMissingMotorvognInfoArray.length <
                  1
                ) {
                  continue;
                }
                for (
                  let j = 0;
                  j <
                  ettersendelseTilGodkjenningMissingMotorvognInfoArray.length;
                  j++
                ) {
                  let ettersendelseTilGodkjenningMissingMotorvognInfo =
                    ettersendelseTilGodkjenningMissingMotorvognInfoArray[j];
                  let data = {};
                  data = {
                    ...data,
                    navn:
                      ettersendelseTilGodkjenningMissingMotorvognInfo
                        .ettersendelse.navn ||
                      motorvogn.eierOrgNavn ||
                      motorvogn.eierPersonNavn,
                  };
                  data = {
                    ...data,
                    gate:
                      ettersendelseTilGodkjenningMissingMotorvognInfo
                        .ettersendelse.gate || motorvogn.eierAdresse,
                  };
                  data = {
                    ...data,
                    postnummer:
                      ettersendelseTilGodkjenningMissingMotorvognInfo
                        .ettersendelse.postnummer || motorvogn.eierPostNr,
                  };
                  data = {
                    ...data,
                    poststed:
                      ettersendelseTilGodkjenningMissingMotorvognInfo
                        .ettersendelse.poststed || motorvogn.eierPostSted,
                  };
                  let ettersendelseId =
                    ettersendelseTilGodkjenningMissingMotorvognInfo
                      .ettersendelse.id;
                  putIleggelseEttersendelserDataArray.push({
                    ettersendelseId,
                    data: { ...data },
                  });
                }
              }
              if (ettersendelserTilGodkjenningMissingMotorvognInfo.length > 0) {
                this.putIleggelseEttersendelserBatchJob(
                  putIleggelseEttersendelserDataArray
                )
                  .then(() =>
                    this.props.getEttersendelserTilGodkjenning(
                      this.getEttersendelserTilGodkjenningFilter()
                    )
                  )
                  .catch();
              }
            })
            .catch();
        })
        .catch((error) =>
          this.setState({ waitingForGetEttersendelserTilGodkjenning: false })
        );
    }
  }

  putIleggelseEttersendelserBatchJob = (putIleggelseEttersendelserDataArray) =>
    Promise.all(
      putIleggelseEttersendelserDataArray.map((pied) =>
        this.props.putIleggelseEttersendelser(pied.ettersendelseId, pied.data)
      )
    )
      .then(() => Promise.resolve)
      .catch(() => Promise.reject);

  getEttersendelserTilGodkjenningFilter() {
    return {
      isEttersendelseTilGodkjenning: true,
      isMakulert: false,
      page: this.props.ettersendelserTilGodkjenning.page,
      pageSize: this.props.ettersendelserTilGodkjenning.count,
      include: "ettersendelse",
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      JSON.stringify(nextProps.ettersendelserTilGodkjenning) !==
      JSON.stringify(this.props.ettersendelserTilGodkjenning)
    ) {
      this.setState({
        checkedEttersendelserTilGodkjenning: {
          ..._.reduce(
            nextProps.ettersendelserTilGodkjenning.items.map((e) => {
              return { [e.ettersendelse && e.ettersendelse.id]: false };
            }),
            _.extend
          ),
          checkboxAllEttersendelserTilGodkjenning: false,
        },
      });
    }
  }

  handleCheckboxChange(event) {
    if (event.target.name === "checkboxAllEttersendelserTilGodkjenning") {
      let keys = Object.keys(this.state.checkedEttersendelserTilGodkjenning);
      let checkedEttersendelserTilGodkjenningArray = keys.map((k) => {
        return { [k]: event.target.checked };
      });
      this.setState({
        checkedEttersendelserTilGodkjenning: _.reduce(
          checkedEttersendelserTilGodkjenningArray,
          _.extend
        ),
      });
    } else {
      this.setState({
        checkedEttersendelserTilGodkjenning: {
          ...this.state.checkedEttersendelserTilGodkjenning,
          [event.target.name]: event.target.checked,
        },
      });
    }
  }

  checkedEttersendelserArray = () => {
    let keys = Object.keys(this.state.checkedEttersendelserTilGodkjenning);
    let checkedEttersendelserTilGodkjenningArray = [];
    for (let index = 0; index < keys.length; index++) {
      const element = keys[index];
      element !== "checkboxAllEttersendelserTilGodkjenning" &&
        this.state.checkedEttersendelserTilGodkjenning[element] &&
        checkedEttersendelserTilGodkjenningArray.push(element);
    }
    return checkedEttersendelserTilGodkjenningArray;
  };
  postIleggelseEttersendelsesbrevBatchJob = () => {
    const { max } = this.state;
    const checkedEttersendelserArray = this.checkedEttersendelserArray();
    const slicedEttersendelseArray = checkedEttersendelserArray.slice(0, max);
    this.setState(() => ({ showGodkjennOgSkrivUtSpinner: true }));
    const params = {
      ettersendelseId: slicedEttersendelseArray,
    };
    this.props
      .getAllIleggelseEttersendelserbreverPdf(params)
      .then(() => {
        this.setState(() => ({ showGodkjennOgSkrivUtSpinner: false }));
        this.props.getEttersendelserTilGodkjenning(
          this.getEttersendelserTilGodkjenningFilter()
        );
      })
      .catch(() =>
        this.setState(() => ({ showGodkjennOgSkrivUtSpinner: false }))
      );
  };

  showGodkjennSkrivUtButton = () => {
    let checkedIleggelseEttersendelserTilGodkjenningObjectArray =
      this.props.ettersendelserTilGodkjenning.items.filter((e) => {
        let checkedEttersendelserTilGodkjenning = [];
        Object.keys(this.state.checkedEttersendelserTilGodkjenning).forEach(
          (key) => {
            if (this.state.checkedEttersendelserTilGodkjenning[key]) {
              checkedEttersendelserTilGodkjenning.push(key);
            }
          }
        );
        return checkedEttersendelserTilGodkjenning.some(
          (cetg) => cetg === e.ettersendelse.id
        );
      });
    if (
      checkedIleggelseEttersendelserTilGodkjenningObjectArray &&
      checkedIleggelseEttersendelserTilGodkjenningObjectArray.length !== 0 &&
      checkedIleggelseEttersendelserTilGodkjenningObjectArray.every(
        (cietgo) =>
          cietgo.isEttersendelseTilGodkjenning &&
          !cietgo.ileggelseisMakulert &&
          !cietgo.isBetalingsfristUtlopt &&
          !cietgo.isBetalt &&
          !cietgo.isVenter
      )
    ) {
      return true;
    }
    return false;
  };

  render() {
    const { showGodkjennOgSkrivUtSpinner, max } = this.state;
    const array = this.checkedEttersendelserArray();
    const size = array.length;
    return (
      <>
        <NavigationBarContainer page="ettersendelser-til-godkjenning" />
        {(!this.state.waitingForGetEttersendelserTilGodkjenning ||
          this.props.ettersendelserTilGodkjenning.items.length !== 0) && (
          <React.Fragment>
            {this.showGodkjennSkrivUtButton() && (
              <div
                className="pull-left"
                style={{
                  paddingTop: 12,
                  paddingBottom: 12,
                  paddingLeft: 50,
                }}
              >
                <LoadButton
                  isLoading={showGodkjennOgSkrivUtSpinner}
                  buttonProps={{
                    className: "btn btn-bym-standard btn-bym-medium",
                    onClick: this.postIleggelseEttersendelsesbrevBatchJob,
                    disabled: showGodkjennOgSkrivUtSpinner,
                  }}
                  spinnerProps={{ pColor: "#ffffff", sColor: "#4d4d4d" }}
                >
                  Send til Svar ut
                </LoadButton>
                <div className="red" style={{ marginTop: 10 }}>
                  {size > max
                    ? `Antall brev pr. kjøring er begrenset til ${max}`
                    : ""}
                </div>
              </div>
            )}
            <div
              className="pull-right ileggelse-list-page-total-ileggelser"
              style={{
                paddingTop: 12,
                paddingBottom: 12,
                paddingRight: 50,
              }}
            >
              {this.props.ettersendelserTilGodkjenning.total} ileggelser
            </div>
            <div className="clearfix" />
            <div className="ileggelse-list-page-content-container">
              <ListContainer
                checkedEttersendelserTilGodkjenning={
                  this.state.checkedEttersendelserTilGodkjenning
                }
                handleCheckboxChange={this.handleCheckboxChange.bind(this)}
                getEttersendelserTilGodkjenningFilter={this.getEttersendelserTilGodkjenningFilter.bind(
                  this
                )}
              />
            </div>
            <PaginationControl storeKey="ettersendelserTilGodkjenning" />
          </React.Fragment>
        )}
        <Spinner
          isLoading={
            this.state.waitingForGetEttersendelserTilGodkjenning &&
            this.props.ettersendelserTilGodkjenning.items.length === 0
          }
        />
        <div style={{ height: 50 }} />
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ettersendelserTilGodkjenning: state.ettersendelserTilGodkjenning,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    putIleggelseEttersendelser: (ettersendelseId, data) =>
      dispatch(putIleggelseEttersendelserActions(ettersendelseId, data)),
    getEttersendelserTilGodkjenning: (params) =>
      dispatch(getEttersendelserTilGodkjenningAction(params)),
    getAllIleggelseEttersendelserbreverPdf: (params) =>
      dispatch(getAllIleggelseEttersendelserbreverPdf(params)),
    getSingleMotorvognByQuery: (params, actionType) =>
      dispatch(getSingleMotorvognByQueryAction(params, actionType)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EttersendelserTilGodkjenningContainer);
