import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const InformationButton = ({ onClick }) => {
  return (
    <button
      type="button"
      className="no-print"
      style={{ padding: 0, border: 0, backgroundColor: "rgb(255,255,255,1)" }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faInfoCircle} />
    </button>
  );
};

InformationButton.propTypes = {
  onClick: PropTypes.func,
};

export default InformationButton;
