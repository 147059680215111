import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ModalGeneric from "../../Common/ModalGeneric";
import OpenModalComponent from "./OpenModalComponent";
import OvertredelseskoderIleggelserCreateModalContent from "../OvertredelseskoderIleggelseForm";

import {
  postOvertredelseskoderIleggelser,
  getOvertredelserkoderIleggelser,
} from "../../../actions/overtredelseskoderIleggelserActions";
import {
  IsValidated,
  onChangeValidation,
} from "../../../utils/validationUtils";

class OvertredelseskoderIleggelser extends React.Component {
  static propTypes = {
    overtredelseskoderIleggelser: PropTypes.array,
    putOvertredelseskoderIleggelser: PropTypes.func,
    internkoderIleggelser: PropTypes.array,
    overtredelsegrupper: PropTypes.array,
  };
  get initState() {
    return {
      open: false,
      submitButtonText: "Lagre",
      id: "",
      beskrivelse: "",
      forklaring: "",
      aktivertDato: "",
      deaktivertDato: "",
      kreverAvstand: null,
      kreverAutomatinfo: null,
      kreverBilettnummer: null,
      kreverUtlopstidBillett: null,
      overtredelseGruppeId: 0,
      belop: "",
      aktiv: false,
      overtredelseInternkoder: [],
      validationError: {},

      openDeaktiverConfirm: false,
      deaktiveringConfirmed: false,
    };
  }

  rules = {
    id: "required;isDigit;minValue=1",
    beskrivelse: "required",
    overtredelseGruppeId: "required",
    belop: "required;isDigit",
    internkode: "required",
    aktivertDato: "isDato;isLessThan=deaktivertDato",
    deaktivertDato: "isDato;isLargerThan=aktivertDato",
    kreverAvstand: "required",
    kreverAutomatinfo: "required",
    kreverBilettnummer: "required",
    kreverUtlopstidBillett: "required",
  };
  state = this.initState;

  componentDidUpdate = (prevProps, prevState) => {
    const { deaktiveringConfirmed } = this.state;
    if (
      deaktiveringConfirmed === true &&
      prevState.deaktiveringConfirmed !== deaktiveringConfirmed
    ) {
      this.saveAndClose();
    }
  };

  closeModal = () => {
    this.setState(this.initState);
  };

  openModal = () => {
    this.setState({ open: true });
  };

  isValid = () => {
    const { isValid, validationError } = IsValidated(this.state, this.rules);
    this.setState({ validationError });
    return isValid;
  };

  saveAndClose = () => {
    const { deaktiveringConfirmed, deaktivertDato } = this.state;

    if (this.isValid() && (deaktiveringConfirmed || !deaktivertDato)) {
      this.setState({ submitButtonText: "Lagrer..." });

      const aktivertDato = this.state.aktivertDato
        ? this.state.aktivertDato
        : null;
      const deaktivertDato = this.state.deaktivertDato
        ? this.state.deaktivertDato
        : null;

      this.props
        .postOvertredelseskoderIleggelser({
          id: this.state.id && Number(this.state.id),
          beskrivelse: this.state.beskrivelse,
          forklaring: this.state.forklaring,
          overtredelseGruppeId: this.state.overtredelseGruppeId,
          belop: this.state.belop && Number(this.state.belop),
          aktivertDato,
          deaktivertDato,
          kreverAvstand: this.state.kreverAvstand,
          kreverAutomatinfo: this.state.kreverAutomatinfo,
          kreverBilettnummer: this.state.kreverBilettnummer,
          kreverUtlopstidBillett: this.state.kreverUtlopstidBillett,
        })
        .then(() => {
          this.closeModal();
        })
        .catch(() =>
          this.setState({ submitButtonText: this.state.submitButtonText })
        );
    } else if (this.isValid() && deaktivertDato) {
      this.setState({ open: false, openDeaktiverConfirm: true });
    }
  };

  handleChangeGroupButtons = (e) => {
    const { name, value } = e.target;
    let val = null;
    if (value === "Kreves") val = true;
    else if (value === "Valgfritt") val = false;
    this.setState({ [name]: val });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    onChangeValidation(e, this.state, this.rules);
  };

  render() {
    const { overtredelsegrupper, internkoderIleggelser } = this.props;
    const { submitButtonText, openDeaktiverConfirm } = this.state;

    return (
      <>
        <ModalGeneric
          modalTitle="Legg til ny overtredelseskode for ileggelser"
          open={this.state.open}
          closeModal={this.closeModal}
          openModal={this.openModal}
          OpenModalComponent={OpenModalComponent}
          submit={this.saveAndClose}
          submitButtonText={submitButtonText}
        >
          <OvertredelseskoderIleggelserCreateModalContent
            id={this.state.id}
            internkoder={internkoderIleggelser}
            overtredelseInternkoder={this.state.overtredelseInternkoder}
            beskrivelse={this.state.beskrivelse}
            forklaring={this.state.forklaring}
            aktivertDato={this.state.aktivertDato}
            deaktivertDato={this.state.deaktivertDato}
            overtredelseGruppeId={this.state.overtredelseGruppeId}
            overtredelseGrupper={overtredelsegrupper}
            belop={this.state.belop}
            handleChange={this.handleChange}
            handleChangeGroupButtons={this.handleChangeGroupButtons}
            kreverAvstand={this.state.kreverAvstand}
            kreverBilettnummer={this.state.kreverBilettnummer}
            kreverAutomatinfo={this.state.kreverAutomatinfo}
            kreverUtlopstidBillett={this.state.kreverUtlopstidBillett}
            validationError={this.state.validationError}
          />
        </ModalGeneric>
        <ModalGeneric
          modalTitle="Deaktiver overtredelseskode"
          open={openDeaktiverConfirm}
          closeModal={() =>
            this.setState({ openDeaktiverConfirm: false, open: true })
          }
          submit={() => this.setState({ deaktiveringConfirmed: true })}
          submitButtonText={submitButtonText}
        >
          <p>Vil du deaktivere overtredelseskode {this.state.id}?</p>
        </ModalGeneric>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    overtredelseskoderIleggelser: state.overtredelseskoderIleggelser,
    overtredelsegrupper: state.overtredelsegrupper,
    internkoderIleggelser: state.internkoderIleggelser,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(
    { postOvertredelseskoderIleggelser, getOvertredelserkoderIleggelser },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OvertredelseskoderIleggelser);
