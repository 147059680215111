import React from "react";
import { Row, Col } from "react-bootstrap";

export const KlageTitleAndBeslutningsLinker = ({
  sak,
  klageId,
  manglerInfo,
}) => {
  return (
    <React.Fragment>
      <Row className="saksnumerAndTilteRow">
        {klageId && (
          <Col md={6}>
            <div
              className="fake-label"
              style={{ fontSize: 19, fontWeight: "bold" }}
            >
              Sak {sak && sak.sakNummer ? sak.sakNummer : ""}
            </div>
            <div className="fake-label" style={{ fontSize: 16 }}>
              {sak && sak.tittel ? sak.tittel : ""}
            </div>
          </Col>
        )}
      </Row>
      {manglerInfo && (
        <Row className="saksnumerAndTilteRow">
          <Col md={6}>
            <div className="label-24px-bold">
              <div className="text-danger">
                Klageinfo er ufullstendig. Oppdater informasjonen i arkivet for
                å kunne fortsette saksbehandlingen av denne saken.
              </div>
            </div>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

export default KlageTitleAndBeslutningsLinker;
