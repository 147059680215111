import * as axiosUtils from "../utils/axiosUtils";
import * as api from "../constants/api";

export const actionType = "OVERTREDELSESKODER_ILEGGELSER";
const metaData = {
  entitet: "Overtredelsekode",
  notify: true,
};
export function getOvertredelserkoderIleggelser() {
  return axiosUtils.GetAllWithParams(
    api.GET_OVERTREDELSESKODER_ILEGGELSER_URL,
    {
      onlyActive: false,
    },
    actionType
  );
}

export function getSingleOvertredelserkoderIleggelser(id) {
  return axiosUtils.GetOne(
    api.GET_SINGLE_OVERTREDELSESKODER_ILEGGELSER_URL(id),
    actionType
  );
}

export function postOvertredelseskoderIleggelser(payload) {
  return axiosUtils.Post(
    api.POST_OVERTREDELSESKODER_ILEGGELSER_URL,
    payload,
    actionType,
    metaData
  );
}
export function putOvertredelseskoderIleggelser(id, payload) {
  return axiosUtils.Put(
    api.PUT_OVERTREDELSESKODER_ILEGGELSER_URL(id),
    payload,
    actionType,
    metaData
  );
}
