import { connect } from "react-redux";

import ExportToExcel from "./ExportToExcel";

const mapStateToProps = (state) => ({
  grunndataFilterForm: state.forms.grunndataFilterForm,
  aktorer: state.aktorer,
  bybetjenter: state.bybetjenter,
  farger: state.farger,
  kjoretoyMerker: state.kjoretoyMerker,
  kjoretoyTyper: state.kjoretoyTyper,
  internkoderIleggelser: state.internkoderIleggelser,
  internkoderMiljogebyr: state.internkoderMiljogebyr,
  nasjoner: state.nasjoner,
  overtredelseskoderIleggelser: state.overtredelseskoderIleggelser,
  overtredelseskoderMiljogebyr: state.overtredelseskoderMiljogebyr,
  rettekoder: state.rettekoder,
  vedtakskoder: state.vedtakskoder,
});

export default connect(mapStateToProps, null)(ExportToExcel);
