const innkrevingSitePaths = {
  shortUrl: "/innkreving",
  page: "/innkreving/:path(ileggelser|miljogebyr|logg)/:id?",
  detalj:
    "/innkreving/ileggelser/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
  ileggelser: {
    shortUrl: "/innkreving/ileggelser",
    page: "/innkreving/ileggelser/:id(venter|stoppet|klar|behandles-innkreving|feilet|vellykket)",
    venter: {
      shortUrl: "/innkreving/ileggelser/venter",
      detalj: {
        shortUrl: {
          routeMatcher:
            "/innkreving/ileggelser/venter/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
          getUrl: (ileggelseId) =>
            `/innkreving/ileggelser/venter/${ileggelseId}`,
        },
        svarUtHistorikk:
          "/innkreving/ileggelser/venter/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/svar-ut/:svarUtId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/historikk",
      },
    },
    stoppet: {
      shortUrl: "/innkreving/ileggelser/stoppet",
      detalj: {
        shortUrl: {
          routeMatcher:
            "/innkreving/ileggelser/stoppet/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
          getUrl: (ileggelseId) =>
            `/innkreving/ileggelser/stoppet/${ileggelseId}`,
        },
        svarUtHistorikk:
          "/innkreving/ileggelser/stoppet/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/svar-ut/:svarUtId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/historikk",
      },
    },
    klar: {
      shortUrl: "/innkreving/ileggelser/klar",
      detalj: {
        shortUrl: {
          routeMatcher:
            "/innkreving/ileggelser/klar/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
          getUrl: (ileggelseId) => `/innkreving/ileggelser/klar/${ileggelseId}`,
        },
        svarUtHistorikk:
          "/innkreving/ileggelser/klar/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/svar-ut/:svarUtId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/historikk",
      },
    },
    behandlesInnkreving: {
      shortUrl: "/innkreving/ileggelser/behandles-innkreving",
      detalj: {
        shortUrl: {
          routeMatcher:
            "/innkreving/ileggelser/behandles-innkreving/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
          getUrl: (ileggelseId) =>
            `/innkreving/ileggelser/behandles-innkreving/${ileggelseId}`,
        },
        svarUtHistorikk:
          "/innkreving/ileggelser/behandles-innkreving/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/svar-ut/:svarUtId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/historikk",
      },
    },
    feilet: {
      shortUrl: "/innkreving/ileggelser/feilet",
      detalj: {
        shortUrl: {
          routeMatcher:
            "/innkreving/ileggelser/feilet/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
          getUrl: (ileggelseId) =>
            `/innkreving/ileggelser/feilet/${ileggelseId}`,
        },
        svarUtHistorikk:
          "/innkreving/ileggelser/feilet/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/svar-ut/:svarUtId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/historikk",
      },
    },
    vellykket: {
      shortUrl: "/innkreving/ileggelser/vellykket",
      detalj: {
        shortUrl: {
          routeMatcher:
            "/innkreving/ileggelser/vellykket/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
          getUrl: (ileggelseId) =>
            `/innkreving/ileggelser/vellykket/${ileggelseId}`,
        },
        svarUtHistorikk:
          "/innkreving/ileggelser/vellykket/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/svar-ut/:svarUtId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/historikk",
      },
    },
    default: "/innkreving/ileggelser/stoppet",
  },
  miljogebyr: {
    shortUrl: "/innkreving/miljogebyr/",
    page: "/innkreving/miljogebyr/:id(venter|stoppet|klar|behandles-innkreving|feilet|vellykket)",
    venter: {
      shortUrl: "/innkreving/miljogebyr/venter",
      detalj: {
        shortUrl: {
          routeMatcher:
            "/innkreving/miljogebyr/venter/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
          getUrl: (ileggelseId) =>
            `/innkreving/miljogebyr/venter/${ileggelseId}`,
        },
        svarUtHistorikk:
          "/innkreving/miljogebyr/venter/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/svar-ut/:svarUtId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/historikk",
      },
    },
    stoppet: {
      shortUrl: "/innkreving/miljogebyr/stoppet",
      detalj: {
        shortUrl: {
          routeMatcher:
            "/innkreving/miljogebyr/stoppet/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
          getUrl: (ileggelseId) =>
            `/innkreving/miljogebyr/stoppet/${ileggelseId}`,
        },
        svarUtHistorikk:
          "/innkreving/miljogebyr/stoppet/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/svar-ut/:svarUtId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/historikk",
      },
    },
    klar: {
      shortUrl: "/innkreving/miljogebyr/klar",
      detalj: {
        shortUrl: {
          routeMatcher:
            "/innkreving/miljogebyr/klar/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
          getUrl: (ileggelseId) => `/innkreving/miljogebyr/klar/${ileggelseId}`,
        },
        svarUtHistorikk:
          "/innkreving/miljogebyr/klar/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/svar-ut/:svarUtId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/historikk",
      },
    },
    behandlesInnkreving: {
      shortUrl: "/innkreving/miljogebyr/behandles-innkreving",
      detalj: {
        shortUrl: {
          routeMatcher:
            "/innkreving/miljogebyr/behandles-innkreving/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
          getUrl: (ileggelseId) =>
            `/innkreving/miljogebyr/behandles-innkreving/${ileggelseId}`,
        },
        svarUtHistorikk:
          "/innkreving/miljogebyr/behandles-innkreving/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/svar-ut/:svarUtId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/historikk",
      },
    },
    feilet: {
      shortUrl: "/innkreving/miljogebyr/feilet",
      detalj: {
        shortUrl: {
          routeMatcher:
            "/innkreving/miljogebyr/feilet/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
          getUrl: (ileggelseId) =>
            `/innkreving/miljogebyr/feilet/${ileggelseId}`,
        },
        svarUtHistorikk:
          "/innkreving/miljogebyr/feilet/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/svar-ut/:svarUtId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/historikk",
      },
    },
    vellykket: {
      shortUrl: "/innkreving/miljogebyr/vellykket",
      detalj: {
        shortUrl: {
          routeMatcher:
            "/innkreving/miljogebyr/vellykket/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
          getUrl: (ileggelseId) =>
            `/innkreving/miljogebyr/vellykket/${ileggelseId}`,
        },
        svarUtHistorikk:
          "/innkreving/miljogebyr/vellykket/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/svar-ut/:svarUtId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/historikk",
      },
    },
    default: "/innkreving/miljogebyr/stoppet",
  },
  default: "/innkreving/ileggelser/stoppet",
};

export default innkrevingSitePaths;
