// stateData = {fraDato, tilDato, fraTid, tilTid}
export const handleFromToDateTime = (e, stateData, handleChange) => {
  let name = e.target.name;
  let value = e.target.value;

  if (
    e.target.name === "fraDato" &&
    typeof e.target.value === "object" &&
    typeof stateData.fraTid === "object"
  ) {
    value = e.target.value
      .hour(parseInt(stateData.fraTid.format("HH"), 10))
      .minute(parseInt(stateData.fraTid.format("mm"), 10));
    handleChange(name, value);
    return;
  }
  if (
    e.target.name === "fraDato" &&
    typeof e.target.value === "object" &&
    typeof stateData.fraTid !== "object"
  ) {
    value = e.target.value.startOf("day");
    handleChange(name, value);
    return;
  }
  if (
    e.target.name === "fraTid" &&
    typeof e.target.value === "object" &&
    typeof stateData.fraDato === "object"
  ) {
    name = "fraDato";
    value =
      typeof stateData.fraDato === "object"
        ? stateData.fraDato
            .hour(parseInt(e.target.value.format("HH"), 10))
            .minute(parseInt(e.target.value.format("mm"), 10))
        : stateData.fraDato;
  }
  if (
    e.target.name === "fraTid" &&
    typeof e.target.value !== "object" &&
    typeof stateData.fraDato === "object"
  ) {
    name = "fraDato";
    value = stateData.fraDato.startOf("day");
    handleChange(name, value);
  }

  if (
    e.target.name === "tilDato" &&
    typeof e.target.value === "object" &&
    typeof stateData.tilTid === "object"
  ) {
    value = e.target.value
      .hour(parseInt(stateData.tilTid.format("HH"), 10))
      .minute(parseInt(stateData.tilTid.format("mm"), 10));
    handleChange(name, value);
    return;
  }
  if (
    e.target.name === "tilDato" &&
    typeof e.target.value === "object" &&
    typeof stateData.tilTid !== "object"
  ) {
    value = e.target.value.endOf("day");
    handleChange(name, value);
    return;
  }
  if (
    e.target.name === "tilTid" &&
    typeof e.target.value === "object" &&
    typeof stateData.tilDato === "object"
  ) {
    name = "tilDato";
    value = stateData.tilDato
      .hour(parseInt(e.target.value.format("HH"), 10))
      .minute(parseInt(e.target.value.format("mm"), 10));
    handleChange(name, value);
  }
  if (
    e.target.name === "tilTid" &&
    typeof e.target.value !== "object" &&
    typeof stateData.tilDato === "object"
  ) {
    name = "tilDato";
    value = stateData.tilDato.endOf("day");
    handleChange(name, value);
  }

  handleChange(e.target.name, e.target.value);
};

export const mergeDateAndTimeForFromAndTo = (
  fraDato,
  fraTid,
  tilDato,
  tilTid
) => {
  const newFraDato = mergeDateAndTimeForFrom(fraDato, fraTid);
  const newTilDato = mergeDateAndTimeForTo(tilDato, tilTid);
  return { fraDato: newFraDato, tilDato: newTilDato };
};

const mergeDateAndTimeForFrom = (fraDato, fraTid) => {
  if (typeof fraDato !== "object") {
    return "";
  }
  let newFraDato = fraDato;
  if (typeof fraTid === "object") {
    return newFraDato
      .hour(parseInt(fraTid.format("HH"), 10))
      .minute(parseInt(fraTid.format("mm"), 10));
  }
  return newFraDato.startOf("day");
};

const mergeDateAndTimeForTo = (tilDato, tilTid) => {
  if (typeof tilDato !== "object") {
    return "";
  }
  let newTilDato = tilDato;
  if (typeof tilTid === "object") {
    return newTilDato
      .hour(parseInt(tilTid.format("HH"), 10))
      .minute(parseInt(tilTid.format("mm"), 10));
  }
  return newTilDato.endOf("day");
};
