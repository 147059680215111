import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import Tabs from "../Common/Tabs";
import GridTable from "../Common/GridTable";
import TilbakemeldingerFraBybetjenter from "./TilbakeMeldingerFraBybetjent";
import BilderAndObservasjonsbilderContainer from "./BilderAndObservasjonsbilderContainer";
import DetaljKommentarer from "../Common/Detalj/DetaljKommentarer";
import BilderContainer from "./BilderContainer";

import getGridDataForKlageIleggelseDetail from "../../selectors/klageIeggelseDetailSelector";
import getGridDataForKlageMiljogebyrDetail from "../../selectors/klageMiljogebyrDetailSelector";
import sitePaths from "../../constants/sitePaths";

import { getOvertredelserkoderIleggelser } from "../../actions/overtredelseskoderIleggelserActions";
import { getOvertredelseskoderMiljogebyr } from "../../actions/overtredelseskoderMiljogebyrActions";
import { getInternkoderIleggelser } from "../../actions/internkoderIleggelserActions";
import { getInternkoderMiljogebyr } from "../../actions/internkoderMiljogebyrActions";
import { getMottakertyper } from "../../actions/mottakertyperActions";

export class IleggelseDetailTabsContainer extends PureComponent {
  static propTypes = {
    isThumbAndLightboxImg: PropTypes.bool,
    klageIleggelse: PropTypes.object,
    ileggelsesType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    getOvertredelserkoderIleggelser: PropTypes.func.isRequired,
    getOvertredelseskoderMiljogebyr: PropTypes.func.isRequired,
    getInternkoderIleggelser: PropTypes.func.isRequired,
    getInternkoderMiljogebyr: PropTypes.func.isRequired,
  };

  state = {
    selectedTab: 0,
    ileggelsesType: "",
  };

  componentDidMount() {
    const {
      getOvertredelserkoderIleggelser,
      getOvertredelseskoderMiljogebyr,
      getInternkoderIleggelser,
      getInternkoderMiljogebyr,
      getMottakertyper,
    } = this.props;
    getOvertredelserkoderIleggelser();
    getOvertredelseskoderMiljogebyr();
    getInternkoderIleggelser();
    getInternkoderMiljogebyr();
    getMottakertyper();
  }

  isMiljogebyr = () => {
    return this.props.ileggelsesType === "M";
  };

  getGridData() {
    const {
      gridDataForKlageIleggelseDetail,
      gridDataForKlageMiljogebyrDetail,
    } = this.props;
    return this.isMiljogebyr()
      ? gridDataForKlageMiljogebyrDetail
      : gridDataForKlageIleggelseDetail;
  }

  tilbakemeldinger = () => {
    const { miljoRettelser, ileggelseRettelser } = this.props;
    return this.isMiljogebyr() ? miljoRettelser : ileggelseRettelser;
  };

  onSelectTab = (tabIndex) => {
    this.setState({ selectedTab: tabIndex });
    const { klageIleggelse } = this.props;

    if (tabIndex === "2") {
      const detaljUrl = this.isMiljogebyr()
        ? sitePaths.klage.miljogebyrer.shortUrl + "/" + klageIleggelse.id
        : sitePaths.klage.ileggelser.shortUrl + "/" + klageIleggelse.id;
      this.props.history.push(detaljUrl);
    }
  };

  render() {
    const { isThumbAndLightboxImg, klageIleggelse, key } = this.props;
    const klageDetailTabsTitle = ["Detaljer", "Bilder", "Se flere detaljer"];
    let tilbakemeldinger = this.tilbakemeldinger();
    return (
      <div key={key} className="klageIleggelseDetaljer">
        <Tabs
          titles={klageDetailTabsTitle}
          bsClass="noboarderStyle"
          selectedTab={this.state.selectedTab}
          onSelectTab={this.onSelectTab}
        >
          <div key="Detaljer">
            <GridTable
              name="klageDetailGridTable"
              rows={this.getGridData()}
              oneColoredGridTable
            />
            <DetaljKommentarer
              internkommentarFraBybetjent={klageIleggelse.internkommentar}
              kommentarTilMottager={klageIleggelse.kommentar}
            />
            <div style={{ marginTop: 20 }} />
            <TilbakemeldingerFraBybetjenter
              tilbakemeldinger={tilbakemeldinger}
            />
          </div>
          <div key="Bilder">
            {klageIleggelse && !klageIleggelse.miljoKontrollId && (
              <BilderContainer isThumbAndLightboxImg={isThumbAndLightboxImg} />
            )}
            {klageIleggelse && klageIleggelse.miljoKontrollId && (
              <BilderAndObservasjonsbilderContainer />
            )}
          </div>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  klageIleggelse: state.klageIleggelse,
  gridDataForKlageIleggelseDetail: getGridDataForKlageIleggelseDetail(state),
  gridDataForKlageMiljogebyrDetail: getGridDataForKlageMiljogebyrDetail(state),
  ileggelseRettelser: state.ileggelseRettelser,
  miljoRettelser: state.miljoRettelser,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getOvertredelserkoderIleggelser,
      getOvertredelseskoderMiljogebyr,
      getInternkoderIleggelser,
      getInternkoderMiljogebyr,
      getMottakertyper,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(IleggelseDetailTabsContainer)
);
