import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import NavigationBarContainer from "../NavigationBarContainer";
import SkiftloggFilterContainer from "./SkiftloggFilterContainer";
import SkiftloggExcel from "./SkiftloggExcel";
import SkiftloggList from "./SkiftloggList";
import * as title from "../../../constants/titles";

class SkiftloggPage extends Component {
  static defaultProps = {
    skiftlogger: {},
  };

  static propTypes = {
    skiftlogger: PropTypes.object,
    getSkiftRapport: PropTypes.func,
    SkiftloggFilterFormState: PropTypes.object,
  };

  componentDidMount() {
    document.title = title.rapporter.skiftlogg;
  }

  render() {
    const { skiftlogger, skiftloggSubmittedForm } = this.props;
    return (
      <Container fluid>
        <Row>
          <Col>
            <NavigationBarContainer page="skiftlogg" />
            <div className="rapportSearch">
              <SkiftloggFilterContainer />
            </div>
            <div className="rapport-buttons-container">
              <SkiftloggExcel
                skiftloggSubmittedForm={skiftloggSubmittedForm}
                skiftlogger={skiftlogger}
              />
              <div className="pull-right" style={{ paddingTop: 20 }}>
                <b>{skiftlogger.list ? skiftlogger.list.length : 0} Rader</b>
              </div>
            </div>
            <div className="clearfix" />
            <div className="rapport-table">
              <SkiftloggList body={skiftlogger} />
            </div>
          </Col>
          <div style={{ height: 30 }} />
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  skiftlogger: state.skiftlogger,
  SkiftloggFilterFormState: state.forms.SkiftloggFilterForm,
  skiftloggSubmittedForm: state.submittedForms.skiftloggSubmittedForm,
});

export default connect(mapStateToProps, null)(SkiftloggPage);
