import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";

import KlageInfoDetail from "./KlageInfoDetail";
import Dokumenter from "./Dokumenter";
import Spinner from "../Common/Spinner";
import { getP360SakFil, P360KrysseDokumenter } from "../../actions/p360Action";
import { OpenBase64InNewTab } from "../../utils/fileUtils";
import klageUtils from "../../utils/klageUtils";

export class KlageInfoContainer extends Component {
  state = { isLoading: false };

  download = (e, dokumentnummer, file) => {
    //const saknummer = this.props.sak && this.props.sak.sakNummer;
    const saksnummerObject =
      klageUtils.getSakDokumentNummerObject(dokumentnummer);
    if (dokumentnummer !== "" && saksnummerObject && file && file.id !== "") {
      this.setState({ isLoading: true });
      this.props
        .getP360SakFil(saksnummerObject, file.id)
        .then((dokument) => {
          if (!isEmpty(dokument)) {
            const base64 = dokument.base64data;
            const filenavn = dokument.navn;
            const fileformat = dokument.format;
            base64 !== "" &&
              OpenBase64InNewTab(base64, filenavn, null, fileformat);
          }
          this.setState({ isLoading: false });
        })
        .catch(() => this.setState({ isLoading: false }));
    }
  };

  render() {
    const { sak } = this.props;
    const { isLoading } = this.state;
    if (isEmpty(sak)) return null;
    return (
      <div className="klageDetailTabs">
        <div className="overskrift">KlageInfo</div>
        <div style={{ marginTop: 20 }}>
          <KlageInfoDetail sak={sak} />
          <Dokumenter
            sak={sak}
            download={this.download}
            onKryssDokumenter={this.props.P360KrysseDokumenter}
          />
          <Spinner isLoading={isLoading} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  klageImages: state.klageeImage,
  sak: state.p360Sak,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getP360SakFil, P360KrysseDokumenter }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(KlageInfoContainer);
