import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { map } from "lodash";

import ModalGeneric from "../../Common/ModalGeneric";
import OpenModalComponent from "./OpenModalComponent";
import VedtakskoderForm from "../VedtakskoderForm";

import {
  postVedtakskoder,
  getVedtakskoder,
  addVedtakskodeIleggelseType,
} from "../../../actions/vedtakskoderAction";
import {
  IsValidated,
  onChangeValidation,
} from "../../../utils/validationUtils";

export class VedtakskodeModal extends React.Component {
  get initState() {
    return {
      open: false,
      id: "",
      kode: "",
      navn: "",
      medhold: "",
      deaktivertDato: "",
      tilkemner: false,
      ileggelsestype: [],
      klageKodeId: 0,
      validationError: {},

      openDeaktiverConfirm: false,
      deaktiveringConfirmed: false,
    };
  }
  rules = {
    id: "required;isDigit;minValue=1",
    kode: "required;minLength=2;maxLength=50",
    navn: "required;minLength=2;maxLength=50",
    klageKodeId: "required",
    deaktivertDato: "isDato",
  };
  state = this.initState;

  componentDidUpdate = (prevProps, prevState) => {
    const { deaktiveringConfirmed } = this.state;
    if (
      deaktiveringConfirmed === true &&
      prevState.deaktiveringConfirmed !== deaktiveringConfirmed
    ) {
      this.saveAndClose();
    }
  };

  closeModal = () => {
    this.setState(this.initState);
  };

  openModal = () => {
    this.setState({ open: true });
  };

  isValid = () => {
    const { isValid, validationError } = IsValidated(this.state, this.rules);
    this.setState({ validationError });
    return isValid;
  };

  saveAndClose = async () => {
    const { deaktiveringConfirmed, deaktivertDato } = this.state;

    if (this.isValid() && (deaktiveringConfirmed || !deaktivertDato)) {
      this.setState({ submitButtonText: "Lagrer..." });

      const deaktivertDato = this.state.deaktivertDato
        ? this.state.deaktivertDato
        : null;

      const payload = {
        id: this.state.id && Number(this.state.id),
        kode: this.state.kode,
        navn: this.state.navn,
        deaktivertDato,
        klageKodeId: this.state.klageKodeId && Number(this.state.klageKodeId),
      };

      await this.props.postVedtakskoder(payload);
      await Promise.all(
        map(this.state.ileggelsestype, async (ileggelseTypeId) => {
          await this.props.addVedtakskodeIleggelseType(
            this.state.id,
            ileggelseTypeId
          );
        })
      );
      await this.props.getVedtakskoder();
      this.closeModal();
    } else if (this.isValid() && deaktivertDato) {
      this.setState({ open: false, openDeaktiverConfirm: true });
    }
  };

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
    onChangeValidation(e, this.state, this.rules);
  };

  render() {
    const { submitButtonText, openDeaktiverConfirm } = this.state;

    return (
      <>
        <ModalGeneric
          modalTitle="Legg til ny vedtakskode"
          open={this.state.open}
          closeModal={this.closeModal}
          openModal={this.openModal}
          OpenModalComponent={OpenModalComponent}
          submit={this.saveAndClose}
          submitButtonText="Lagre"
        >
          <VedtakskoderForm
            id={this.state.id}
            kode={this.state.kode}
            navn={this.state.navn}
            deaktivertDato={this.state.deaktivertDato}
            tilkemner={this.state.tilkemner}
            klageKodeId={this.state.klageKodeId}
            klagekoder={this.props.klagekoder}
            ileggelsestyper={this.props.ileggelsestyper}
            ileggelsestype={this.state.ileggelsestype}
            handleChange={this.handleChange}
            onChangeOneIleggelsetype={this.onChangeOneIleggelsetype}
            validationError={this.state.validationError}
          />
        </ModalGeneric>
        <ModalGeneric
          modalTitle="Deaktiver vedtakskode"
          open={openDeaktiverConfirm}
          closeModal={() =>
            this.setState({ openDeaktiverConfirm: false, open: true })
          }
          submit={() => this.setState({ deaktiveringConfirmed: true })}
          submitButtonText={submitButtonText}
        >
          <p>Vil du deaktivere vedtakskode {this.state.id}?</p>
        </ModalGeneric>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ileggelsestyper: state.ileggelsestyper,
    vedtakskoder: state.vedtakskoder,
    klagekoder: state.klagekoder,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(
    {
      postVedtakskoder,
      getVedtakskoder,
      addVedtakskodeIleggelseType,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(VedtakskodeModal);
