import React from "react";
import PropTypes from "prop-types";

import { Button } from "react-bootstrap";

import ModalGenericConfirmCancelContainer from "../../../Common/ModalGenericConfirmCancelContainer";

import "./MakulerMiljokontroll.css";

// Miljøkontroll har bytt navn til tidskontroll
const MakulerMiljokontroll = ({ miljokontroll, putMiljokontrollAvslutt }) => {
  return (
    <ModalGenericConfirmCancelContainer
      id={"makuler-miljokontroll-modal-generic-confirm-cancel-container"}
      className="makuler-miljokontroll"
      modalTitle={`Makuler tidskontroll ${miljokontroll.nummer}`}
      submitButtonText="Makuler"
      submittingButtonText="Makulerer..."
      handleConfirmClick={() => putMiljokontrollAvslutt()}
      // handleCancelAndCloseClick={}
      openModalComponentFunction={(endreModalProps) => (
        <Button
          className="btn btn-bym-standard"
          style={{ marginLeft: 20 }}
          onClick={endreModalProps.openModal}
        >
          Makuler
        </Button>
      )}
    >
      Vil du makulere tidskontroll {miljokontroll.nummer}?
    </ModalGenericConfirmCancelContainer>
  );
};

MakulerMiljokontroll.propTypes = {
  miljokontroll: PropTypes.object.isRequired,
  putMiljokontrollAvslutt: PropTypes.func.isRequired,
};

export default MakulerMiljokontroll;
