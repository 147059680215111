import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import map from "lodash/map";
import difference from "lodash/difference";
import ModalGeneric from "../../../Common/ModalGeneric";
import UpdateOpenModal from "../../CreateModals/UpdateOpenModal";
import BybetjentCreateModalContent from "../../BybetjentForm";

import {
  putBybetjenter,
  getBybetjentById,
  deleteBetjentsTeam,
  addBetjentToTeam,
  getBybetjenter,
} from "../../../../actions/bybetjenterActions";

import {
  IsValidated,
  onChangeValidation,
} from "../../../../utils/validationUtils";
import { toIsoString } from "../../../../utils/datetimeUtils";

class Bybetjent extends React.Component {
  static propTypes = {
    teamList: PropTypes.array,
    seksjoner: PropTypes.array,
    roller: PropTypes.array,
    getBybetjentById: PropTypes.func,
    deleteBetjentsTeam: PropTypes.func,
    addBetjentToTeam: PropTypes.func,
    putBybetjenter: PropTypes.func,
  };

  get initState() {
    return {
      open: false,
      submitButtonText: "Lagre",
      betjent: {},
      nummer: this.props.nummer,
      pulsNummer: this.props.pulsNummer,
      currentTeam: this.props.team,
      id: this.props.id,
      modifiedTeam: this.props.team,
      navn: this.props.navn,
      mobil: this.props.mobil,
      rolle: this.props.rolle,
      seksjon: this.props.seksjon,
      epost: this.props.epost,
      aktivertDato: this.props.aktivertDato,
      deaktivertDato: this.props.deaktivertDato,
      validationError: {},

      openDeaktiverConfirm: false,
      deaktiveringConfirmed: false,
    };
  }

  state = this.initState;
  rules = {
    navn: "required;minLength=3",
    nummer: "required;isDigit",
    pulsNummer: "required;isDigit",
    mobil: "isDigit;length=8",
    aktivertDato: "isDato;isLessThan=deaktivertDato",
    deaktivertDato: "isDato;isLargerThan=aktivertDato",
    epost: "isEmail",
    rolle: "required",
    seksjon: "required",
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (
      JSON.stringify(nextProps) === JSON.stringify(this.props) ||
      JSON.stringify(nextProps.team) === JSON.stringify(this.props.team)
    ) {
      this.setState({
        id: nextProps.id,
        nummer: nextProps.nummer,
        pulsNummer: nextProps.pulsNummer,
        modifiedTeam: nextProps.team,
        navn: nextProps.navn,
        mobil: nextProps.mobil,
        epost: nextProps.epost,
        rolle: nextProps.rolle,
        seksjon: nextProps.seksjon,
        aktivertDato: nextProps.aktivertDato,
        deaktivertDato: nextProps.deaktivertDato,
      });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { deaktiveringConfirmed } = this.state;
    if (
      deaktiveringConfirmed === true &&
      prevState.deaktiveringConfirmed !== deaktiveringConfirmed
    ) {
      this.saveAndClose();
    }
  };

  closeModal = () => {
    this.setState(this.initState);
  };

  openModal = () => {
    this.setState({ open: true });
  };

  isValid = () => {
    const { isValid, validationError } = IsValidated(this.state, this.rules);
    this.setState({ validationError });
    return isValid;
  };

  generateTeamPayload = (state) => {
    const { currentTeam, modifiedTeam } = state;
    const currentTeamIds = currentTeam.map((i) => i.id);
    const modifiedTeamIds = modifiedTeam.map((i) => i.id);
    const teamIdsToDelete = difference(currentTeamIds, modifiedTeamIds);
    const teamIdsToAdd = difference(modifiedTeamIds, currentTeamIds);
    return Object.assign(
      {},
      {
        id: state.id,
        nummer: state.nummer,
        pulsNummer: state.pulsNummer,
        navn: state.navn,
        teamIdsToDelete: teamIdsToDelete,
        teamIdsToAdd: teamIdsToAdd,
        seksjon: state.seksjon,
        rolle: state.rolle,
        mobil: state.mobil,
        epost: state.epost,
        aktivertDato: state.aktivertDato ? toIsoString(state.aktivertDato) : null, //SAK-80: dates set to null if empty instead of empty string
        deaktivertDato: state.deaktivertDato ?  toIsoString(state.deaktivertDato) : null,
      }
    );
  };

  saveAndClose = async () => {
    const { deaktiveringConfirmed, deaktivertDato } = this.state;

    if (this.isValid() && (deaktiveringConfirmed || !deaktivertDato)) {
      const payload = this.generateTeamPayload(this.state);
      await this.props.putBybetjenter(payload);
      await this.updateBetjentTeam(payload);
      await this.props.getBybetjenter();
      this.closeModal();
    } else if (this.isValid() && deaktivertDato) {
      this.setState({ open: false, openDeaktiverConfirm: true });
    }
  };

  updateBetjentTeam = async (payload) => {
    const { id, teamIdsToAdd, teamIdsToDelete } = payload;
    if (id !== "" && teamIdsToAdd.length > 0) {
      await Promise.all(
        map(teamIdsToAdd, async (item) => {
          await this.props.addBetjentToTeam(id, item);
        })
      );
    }
    if (id !== "" && teamIdsToDelete.length > 0) {
      await Promise.all(
        map(teamIdsToDelete, async (item) => {
          await this.props.deleteBetjentsTeam(id, item);
        })
      );
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "team") {
      this.setState({ modifiedTeam: value });
    } else this.parseNumberAndSetState(name, value);
    onChangeValidation(e, this.state, this.rules);
  };

  //SAK-80: parsing numbers and not defined dates is set as null
  parseNumberAndSetState = (name, value) => {
    if (name === "nummer" || name === "pulsNummer") {
      this.setState({ [name]: parseInt(value) });
    } else {
      this.setState({ [name]: value });
    }
  };

  betjentroller = () => {
    return map(this.props.roller, (i) =>
      Object.assign([], { id: i.kode, navn: i.beskrivelse })
    );
  };

  render() {
    const { teamList, seksjoner } = this.props;
    const { openDeaktiverConfirm } = this.state;
    let roller = this.betjentroller();
    return (
      <React.Fragment>
        <ModalGeneric
          modalTitle="Rediger bypatrulje ansatt"
          open={this.state.open}
          closeModal={this.closeModal}
          openModal={this.openModal}
          OpenModalComponent={UpdateOpenModal}
          submit={this.saveAndClose}
          submitButtonText={this.state.submitButtonText}
        >
          <BybetjentCreateModalContent
            id={this.props.id}
            nummer={this.state.nummer}
            pulsNummer={this.state.pulsNummer}
            teamList={teamList}
            team={this.state.modifiedTeam}
            navn={this.state.navn}
            mobil={this.state.mobil}
            epost={this.state.epost}
            roller={roller}
            rolle={this.state.rolle}
            seksjon={this.state.seksjon}
            seksjoner={seksjoner}
            aktivertDato={this.state.aktivertDato}
            deaktivertDato={this.state.deaktivertDato}
            validationError={this.state.validationError}
            handleChange={this.handleChange}
            actionType="update"
          />
        </ModalGeneric>
        <ModalGeneric
          modalTitle="Deaktiver bruker"
          open={openDeaktiverConfirm}
          closeModal={() =>
            this.setState({ openDeaktiverConfirm: false, open: true })
          }
          submit={() => this.setState({ deaktiveringConfirmed: true })}
          submitButtonText={this.state.submitButtonText}
        >
          <p>Vil du deaktivere betjent {this.state.navn}?</p>
        </ModalGeneric>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    teamList: state.betjentgrupper,
    roller: state.betjentroller,
    seksjoner: state.bybetjenterseksjoner,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(
    {
      putBybetjenter,
      getBybetjentById,
      deleteBetjentsTeam,
      addBetjentToTeam,
      getBybetjenter,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Bybetjent);
