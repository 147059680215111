import React from "react";
import PropTypes from "prop-types";

import AktorerModal from "./AktorerModal";
import BybetjentCreateModal from "./Bybetjent";
import FargerModal from "./FargerModal";
import KjoretoyMerkerModal from "./KjoretoyMerkerModal";
import KjoretoyTyperModal from "./KjoretoyTyperModal";
import InternkoderModal from "./InternkoderModal";
import NasjonerModal from "./NasjonerModal";
import OvertredelseskoderIleggelser from "./OvertredelseskoderIleggelseModal";
import OvertredelseskoderMiljogebyr from "./OvertredelseskoderMiljogebyrModal";
import RettekoderModal from "./RettekoderModal";
import VedtakskoderModal from "./VedtakskoderModal";

export default class CreateModals extends React.Component {
  static propTypes = {
    createModalToRender: PropTypes.string,
  };

  render() {
    return (
      <div>
        {this.props.createModalToRender === "Aktører" && <AktorerModal />}
        {this.props.createModalToRender === "Bypatruljen" && (
          <BybetjentCreateModal />
        )}
        {this.props.createModalToRender === "Farger" && <FargerModal />}
        {this.props.createModalToRender === "Internkoder ileggelser" && (
          <InternkoderModal modalToRender="ileggelser" />
        )}
        {this.props.createModalToRender === "Internkoder miljøgebyr" && (
          <InternkoderModal modalToRender="miljøgebyr" />
        )}
        {this.props.createModalToRender === "Kjøretøytyper" && (
          <KjoretoyTyperModal />
        )}
        {this.props.createModalToRender === "Kjøretøymerker" && (
          <KjoretoyMerkerModal />
        )}
        {this.props.createModalToRender === "Nasjoner" && <NasjonerModal />}
        {this.props.createModalToRender === "Overtredelseskoder ileggelser" && (
          <OvertredelseskoderIleggelser />
        )}
        {this.props.createModalToRender === "Overtredelseskoder miljøgebyr" && (
          <OvertredelseskoderMiljogebyr />
        )}
        {this.props.createModalToRender === "Rettekoder" && <RettekoderModal />}
        {this.props.createModalToRender === "Vedtakskoder" && (
          <VedtakskoderModal modalToRender="vedtakskoder" />
        )}
      </div>
    );
  }
}
