import EttersendelserCommon from "../../Detalj/Ettersendelser";
import { useDispatch, useSelector } from "react-redux";

import { getMiljoEttersendelserEttersendelsebrevPdf } from "../../../../actions/miljoEttersendelsebrevActions";

const Ettersendelser = () => {
  const ettersendelser = useSelector((store) =>
    getEttersendelserFraBetjenter(
      store.miljogebyrEttersendelser,
      store.bybetjenter
    )
  );

  const dispatch = useDispatch();

  const handleGetIleggelseEttersendelserEttersendelsebrevPdf = (
    miljogebyrEttersendelseBrevId
  ) => {
    dispatch(
      getMiljoEttersendelserEttersendelsebrevPdf(miljogebyrEttersendelseBrevId)
    );
  };
  return (
    <EttersendelserCommon
      ettersendelser={ettersendelser}
      getEttersendelserEttersendelsebrevPdf={
        handleGetIleggelseEttersendelserEttersendelsebrevPdf
      }
    />
  );
};

const getEttersendelserFraBetjenter = (ettersendelser, bybetjenter) => {
  const objectOfBybetjenter = Object.assign(
    {},
    ...bybetjenter.map((bybetjent) => ({ [bybetjent.id]: { ...bybetjent } }))
  );

  return (
    ettersendelser &&
    ettersendelser
      .filter((ettersendelse) => ettersendelse.betjentId)
      .map((ettersendelseFraBetjent) => {
        const bybetjent =
          objectOfBybetjenter[ettersendelseFraBetjent.betjentId];
        return {
          ...ettersendelseFraBetjent,
          tjenestenummer: bybetjent && bybetjent.nummer,
        };
      })
      .sort((a, b) => {
        if (a.ettersendelseDato > b.ettersendelseDato) {
          return -1;
        } else if (a.ettersendelseDato < b.ettersendelseDato) {
          return 1;
        } else {
          return 0;
        }
      })
  );
};

export default Ettersendelser;
