import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import FormButtonsForCloseAndSubmit from "../../Common/Forms/FormButtonsForCloseAndSubmit";
import DatetimePicker from "../../Common/Forms/DatetimePickerEnhanced";
import DropDownListMultiCheckboxOptions from "../../Common/Forms/DropDownListMultiCheckboxOptions";

import { authorized } from "../../../utils/authorizationUtils";

import { bypatruljenArray } from "../../../constants/roles";

export default class SkiftloggFilterForm extends Component {
  static propTypes = {
    showSokSpinner: PropTypes.bool,
    skiftloggFilterFormState: PropTypes.shape({
      fraDato: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      tilDato: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      teamId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      tjenestenummer: PropTypes.string,
    }),
    bybetjenter: PropTypes.arrayOf(PropTypes.object),
    teams: PropTypes.array,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    handleChangeToInitState: PropTypes.func,
    error: PropTypes.string,
  };

  flexboxClassName = authorized(bypatruljenArray)
    ? "skiftlogg-filter-flexbox-for-bypatruljen"
    : "skiftlogg-filter-flexbox";

  render() {
    const {
      teams,
      bybetjenter,
      showSokSpinner,
      skiftloggFilterFormState,
      handleChange,
      handleSubmit,
      handleChangeToInitState,
      error,
    } = this.props;
    return (
      <div className="SearchHistorikkForm">
        <div className="searchHistorikkOverskrift">Søk i tabellen</div>
        <div className={`filter-flexbox-container ${this.flexboxClassName}`}>
          <DatetimePicker
            id="fraDato"
            name="fraDato"
            locale="nb"
            label="Dato fra"
            placeHolder="Velg dato"
            onChange={handleChange}
            value={skiftloggFilterFormState.fraDato}
            validationError={
              skiftloggFilterFormState.fraDatoValidationErrorText
            }
            timeFormat={false}
          />
          <DatetimePicker
            id="tilDato"
            name="tilDato"
            locale="nb"
            label="Dato til"
            placeHolder="Velg dato"
            onChange={handleChange}
            value={skiftloggFilterFormState.tilDato}
            validationError={
              skiftloggFilterFormState.tilDatoValidationErrorText
            }
            timeFormat={false}
          />
          <DropDownListMultiCheckboxOptions
            id="teams"
            label="Team"
            name="selectedTeams"
            items={teams}
            selected={this.props.skiftloggFilterFormState.selectedTeams}
            title={
              this.props.skiftloggFilterFormState.selectedTeams.length > 0
                ? `${this.props.skiftloggFilterFormState.selectedTeams.length} valg`
                : "Velg"
            }
            onChange={handleChange}
          />
          {authorized(bypatruljenArray) && (
            <DropDownListMultiCheckboxOptions
              id="tjenestenummer"
              label="Tjenestenummer"
              name="selectedTjenestenummer"
              items={bybetjenter}
              selected={
                this.props.skiftloggFilterFormState.selectedTjenestenummer
              }
              title={
                this.props.skiftloggFilterFormState.selectedTjenestenummer
                  .length > 0
                  ? `${this.props.skiftloggFilterFormState.selectedTjenestenummer.length} valg`
                  : "Velg"
              }
              isSearchable={true}
              onChange={handleChange}
            />
          )}
        </div>
        <Row>
          <Col sm={12}>{error}</Col>
        </Row>
        <div style={{ height: 20 }} />
        <Row>
          <Col sm={12}>
            <FormButtonsForCloseAndSubmit
              submitText="Søk"
              onClose={handleChangeToInitState}
              onSubmit={handleSubmit}
              showSubmitSpinner={showSokSpinner}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
