import axios from "../services/axios";
import * as api from "../constants/api";
import { addFlashMessage } from "./FlashMessagesAction";

export function postIleggelserForsinketMakulering(ileggelseId, kommentar) {
  return async dispatch => {
    return axios
      .post(api.POST_ILEGGELSER_FORSINKET_MAKULERING(ileggelseId), {
        kommentar
      })
      .then(() => {
        dispatch(
          addFlashMessage({
            type: "success",
            text: "Forsinket makulering vellykket"
          })
        );
        return Promise.resolve(true);
      })
      .catch(response => {
        dispatch(
          addFlashMessage({
            type: "error",
            message: response
          })
        );
        return Promise.reject(true);
      });
  };
}

export function deleteIleggelserForsinketMakulering(ileggelseId) {
  return async dispatch => {
    return axios
      .delete(api.DELETE_ILEGGELSER_FORSINKET_MAKULERING(ileggelseId))
      .then(() => {
        dispatch(
          addFlashMessage({
            type: "success",
            text: "Oppheving av forsinket makulering vellykket"
          })
        );
        return Promise.resolve(true);
      })
      .catch(response => {
        dispatch(
          addFlashMessage({
            type: "error",
            message: response
          })
        );
        return Promise.reject(true);
      });
  };
}
