import React, { useState } from "react";
import { useHistory } from "react-router";

import { Nav } from "react-bootstrap";

const InnkrevingStatusNavbar = ({ items }) => {
  const [selectedStatus, setSelectedStatus] = useState(
    document.location.pathname
  );

  const history = useHistory();

  const handleClickNavLink = (e, path, title) => {
    e.preventDefault();
    setSelectedStatus(path);
    document.title = title;
    history.push(path);
  };

  return (
    <>
      <div>
        <Nav variant="tabs" className="ine-statuskoder-menue">
          {items.map((item) => {
            return (
              <Nav.Link
                href={item.path}
                onClick={(e) => handleClickNavLink(e, item.path, item.title)}
                style={
                  selectedStatus === item.path
                    ? {
                        backgroundColor: "#fff",
                        borderColor: "#dee2e6 #dee2e6 #fff",
                      }
                    : {}
                }
                key={item.path}
              >
                {item.name}
              </Nav.Link>
            );
          })}
        </Nav>
      </div>
    </>
  );
};

export default InnkrevingStatusNavbar;
