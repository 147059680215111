const rapporterSitePaths = {
  shortUrl: "/rapporter",
  ventilstillinger: {
    shortUrl: "/rapporter/ventilstillinger",
    oversikt: "/rapporter/ventilstillinger/oversikt",
    detalj: {
      bilde:
        "/rapporter/ventilstillinger/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/bilder/:bildeId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
      shortUrl: {
        routeMatcher:
          "/rapporter/ventilstillinger/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
        getUrl: (ventilstillingId) =>
          `/rapporter/ventilstillinger/${ventilstillingId}`,
      },
      utskrift:
        "/rapporter/ventilstillinger/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/utskrift",

      ileggelse: {
        detalj: {
          shortUrl: {
            routeMatcher:
              "/rapporter/ventilstillinger/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/ileggelse/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
            getUrl: (ventilstillingId, ileggelseId) =>
              `/rapporter/ventilstillinger/${ventilstillingId}/ileggelse/${ileggelseId}`,
          },
          toastUi:
            "/rapporter/ventilstillinger/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/ileggelse/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/bilder/:bildeId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/toastui",
          bilde:
            "/rapporter/ventilstillinger/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/ileggelse/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/bilder/:bildeId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
          svarUtHistorikk:
            "/rapporter/ventilstillinger/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/ileggelse/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/svar-ut/:svarUtId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/historikk",
        },
      },
    },
    ileggelse: {
      detalj: {
        shortUrl: {
          routeMatcher:
            "/rapporter/ventilstillinger/ileggelse/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
          getUrl: (ileggelseId) =>
            `/rapporter/ventilstillinger/ileggelse/${ileggelseId}`,
        },
        toastUi:
          "/rapporter/ventilstillinger/ileggelse/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/bilder/:bildeId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/toastui",
        bilde:
          "/rapporter/ventilstillinger/ileggelse/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/bilder/:bildeId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
        svarUtHistorikk:
          "/rapporter/ventilstillinger/ileggelse/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/svar-ut/:svarUtId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/historikk",
      },
    },
  },
  skiftlogg: {
    shortUrl: "/rapporter/skiftlogg",
    oversikt: "/rapporter/skiftlogg/oversikt",
  },
  piggdekkontroller: {
    shortUrl: "/rapporter/piggdekkontroller",
    oversikt: "/rapporter/piggdekkontroller/oversikt",
  },
  klager: {
    shortUrl: "/rapporter/klager",
    oversikt: "/rapporter/klager/oversikt",
  },
  ubetalteIleggelser: {
    shortUrl: "/rapporter/ubetalte-ileggelser",
    oversikt: "/rapporter/ubetalte-ileggelser/oversikt",
    detalj: {
      shortUrl: {
        matcher:
          "/rapporter/ubetalte-ileggelser/([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})(\\?.*)?$",
        routeMatcher:
          "/rapporter/ubetalte-ileggelser/:kjoretoyId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
        getUrl: (kjoretoyId) => `/rapporter/ubetalte-ileggelser/${kjoretoyId}`,
      },
      ileggelser: {
        shortUrl: {
          matcher:
            "/rapporter/ubetalte-ileggelser/([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/ileggelser/([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})(\\?.*)?$",
          routeMatcher:
            "/rapporter/ubetalte-ileggelser/:kjoretoyId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/ileggelser/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
          getUrl: (kjoretoyId, ileggelseId) =>
            `/rapporter/ubetalte-ileggelser/${kjoretoyId}/ileggelser/${ileggelseId}`,
        },
      },
    },
  },
  uferdigstilteKlagesaker: {
    shortUrl: "/rapporter/uferdigstilte-klagesaker",
    oversikt: "/rapporter/uferdigstilte-klagesaker/oversikt",
  },
  juridiskSaksbehandler: {
    shortUrl: "/rapporter/saksbehandler",
    oversikt: "/rapporter/saksbehandler/oversikt",
  },
  antallIleggelserPerIleggelsetype: {
    shortUrl: "/rapporter/antall-ileggelser-per-ileggelsetype",
    oversikt: "/rapporter/antall-ileggelser-per-ileggelsetype/oversikt",
  },
  makuleringer: {
    shortUrl: "/rapporter/makuleringer",
    oversikt: "/rapporter/makuleringer/oversikt",
  },
  aarsrapport: {
    shortUrl: "/rapporter/aarsrapport",
    oversikt: "/rapporter/aarsrapport/oversikt",
  },
};

export default rapporterSitePaths;
