import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

import ModalGeneric from "../../../Common/ModalGeneric";
import UpdateOpenModal from "../../CreateModals/UpdateOpenModal";
import AktorerForm from "../../AktorerForm";

import { putAktor } from "../../../../actions/aktorerActions";
import {
  IsValidated,
  onChangeValidation,
} from "../../../../utils/validationUtils";

const rules = {
  kjoretoyMerkeId:
    "required;hasFormat=[A-Fa-f0-9]{8}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{12},XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX",
  orgNummer: "required;isNumber;length=9",
  adresse1: "required;minLength=1;maxLength=40",
  adresse2: "minLength=0;maxLength=40",
  poststed: "required;minLength=1;maxLength=40",
  postnummer: "required;minLength=1;maxLength=4",
  aktivertDato: "isDato;isLessThan=deaktivertDato",
  deaktivertDato: "isDato;isLargerThan=aktivertDato",
};

const closeModal = (
  aktor,
  setOpen,
  setSubmitButtonText,
  setOrgNummer,
  setKjoretoyMerkeId,
  setAktivertDato,
  setDeaktivertDato,
  setAdresse1,
  setAdresse2,
  setPoststed,
  setPostnummer,
  setValidationError
) => {
  setOpen(false);
  setSubmitButtonText("Lagre");
  setOrgNummer(aktor.orgNummer.toString());
  setKjoretoyMerkeId(aktor.kjoretoyMerke.id);
  setAktivertDato(aktor.aktivertDato);
  setDeaktivertDato(aktor.deaktivertDato);
  setAdresse1(aktor.adresse1);
  setAdresse2(aktor.adresse2);
  setPoststed(aktor.poststed);
  setPostnummer(aktor.postnummer);
  setValidationError({});
};

const isValid = (state, setValidationError) => {
  const { isValid, validationError } = IsValidated(state, rules);
  setValidationError({ ...validationError });
  return isValid;
};

const handlePut = (
  state,
  setOpen,
  setSubmitButtonText,
  kjoretoyMerker,
  putAktor
) => {
  setSubmitButtonText("Lagrer...");
  const deaktivertDato = getAktivertDatoAsMomentObject(state.deaktivertDato);
  putAktor({
    id: state.id,
    navn: getNavn(state.kjoretoyMerkeId, kjoretoyMerker),
    orgNummer: parseInt(state.orgNummer), //SAk-80: is treated as int in backend, but is kept as string due to to validation of length
    kjoretoyMerkeId: state.kjoretoyMerkeId,
    aktivertDato: state.aktivertDato,
    deaktivertDato: deaktivertDato && deaktivertDato.endOf("day"),
    adresse1: state.adresse1,
    adresse2: state.adresse2,
    poststed: state.poststed,
    postnummer: state.postnummer,
  })
    .then(() => {
      setOpen(false);
      setSubmitButtonText("Lagre");
    })
    .catch(() => setSubmitButtonText("Lagre"));
};

const saveAndClose = (
  e,
  state,
  setOpen,
  setSubmitButtonText,
  setValidationError,
  kjoretoyMerker,
  putAktor
) => {
  e.preventDefault();
  if (isValid(state, setValidationError)) {
    handlePut(state, setOpen, setSubmitButtonText, kjoretoyMerker, putAktor);
  }
};

const handleChange = (
  e,
  validationError,
  setOrgNummer,
  setKjoretoyMerkeId,
  setAktivertDato,
  setDeaktivertDato,
  setAdresse1,
  setAdresse2,
  setPoststed,
  setPostnummer,
  setValidationError
) => {
  const name = e.target.name;
  const value = e.target.value;
  switch (name) {
    case "orgNummer":
      setOrgNummer(value);
      break;
    case "kjoretoyMerkeId":
      setKjoretoyMerkeId(value);
      break;
    case "aktivertDato":
      setAktivertDato(value);
      break;
    case "deaktivertDato":
      setDeaktivertDato(value);
      break;
    case "adresse1":
      setAdresse1(value);
      break;
    case "adresse2":
      setAdresse2(value);
      break;
    case "poststed":
      setPoststed(value);
      break;
    case "postnummer":
      setPostnummer(value);
      break;
    default:
      break;
  }
  let tempValidationError = { validationError: { ...validationError } };
  onChangeValidation(e, tempValidationError, rules); // onChangeValidation mutates tempValidationError
  setValidationError(tempValidationError["validationError"]);
};

const getAktivertDatoAsMomentObject = (deaktivertDato) => {
  // If it is already a moment object
  if (typeof deaktivertDato === "object") {
    return deaktivertDato;
  }
  return moment(deaktivertDato);
};

const getNavn = (kjoretoyMerkeId, kjoretoyMerker) => {
  const filteredKjoretoyMerker = kjoretoyMerker.filter(
    (km) => km.id === kjoretoyMerkeId
  );
  if (filteredKjoretoyMerker.length === 1) {
    return filteredKjoretoyMerker[0].navn;
  }
  return null;
};

const AktorerModal = ({ aktor, kjoretoyMerker, putAktor }) => {
  const [open, setOpen] = useState(false);
  const [submitButtonText, setSubmitButtonText] = useState("Lagre");
  const [orgNummer, setOrgNummer] = useState(aktor.orgNummer.toString());
  const [kjoretoyMerkeId, setKjoretoyMerkeId] = useState(
    aktor.kjoretoyMerke.id
  );
  const [aktivertDato, setAktivertDato] = useState(aktor.aktivertDato);
  const [deaktivertDato, setDeaktivertDato] = useState(aktor.deaktivertDato);
  const [adresse1, setAdresse1] = useState(aktor.adresse1);
  const [adresse2, setAdresse2] = useState(aktor.adresse2);
  const [poststed, setPoststed] = useState(aktor.poststed);
  const [postnummer, setPostnummer] = useState(aktor.postnummer);
  const [validationError, setValidationError] = useState({});

  const prevOrgNummerRef = useRef();
  const prevKjoretoyMerkeId = useRef();
  const prevAktivertDato = useRef();
  const prevDeaktivertDato = useRef();
  const prevAdresse1 = useRef();
  const prevAdresse2 = useRef();
  const prevPoststed = useRef();
  const prevPostnummer = useRef();
  useEffect(() => {
    prevOrgNummerRef.current = aktor.orgNummer;
    prevKjoretoyMerkeId.current = aktor.kjoretoyMerke.id;
    prevAktivertDato.current = aktor.aktivertDato;
    prevDeaktivertDato.current = aktor.deaktivertDato;
    prevAdresse1.current = aktor.adresse1;
    prevAdresse2.current = aktor.adresse2;
    prevPoststed.current = aktor.poststed;
    prevPostnummer.current = aktor.postnummer;
  });

  useEffect(() => {
    if (prevOrgNummerRef.current !== aktor.orgNummer) {
      setOrgNummer(aktor.orgNummer.toString());
    }
    if (prevKjoretoyMerkeId.current !== aktor.kjoretoyMerke.id) {
      setKjoretoyMerkeId(aktor.kjoretoyMerke.id);
    }
    if (prevAktivertDato.current !== aktor.aktivertDato) {
      setKjoretoyMerkeId(aktor.aktivertDato);
    }
    if (prevDeaktivertDato.current !== aktor.deaktivertDato) {
      setKjoretoyMerkeId(getAktivertDatoAsMomentObject(aktor.aktivertDato));
    }
    if (prevAdresse1.current !== aktor.adresse1) {
      setAdresse1(aktor.adresse1);
    }
    if (prevAdresse2.current !== aktor.adresse2) {
      setAdresse2(aktor.adresse2);
    }
    if (prevPoststed.current !== aktor.poststed) {
      setPoststed(aktor.poststed);
    }
    if (prevPostnummer.current !== aktor.postnummer) {
      setPostnummer(aktor.postnummer);
    }
  }, [aktor]);

  return (
    <>
      <ModalGeneric
        modalTitle={"Rediger aktør"}
        open={open}
        closeModal={() =>
          closeModal(
            aktor,
            setOpen,
            setSubmitButtonText,
            setOrgNummer,
            setKjoretoyMerkeId,
            setAktivertDato,
            setDeaktivertDato,
            setAdresse1,
            setAdresse2,
            setPoststed,
            setPostnummer,
            setValidationError
          )
        }
        openModal={() => setOpen(true)}
        OpenModalComponent={UpdateOpenModal}
        submit={(e) =>
          saveAndClose(
            e,
            {
              id: aktor.id,
              orgNummer,
              kjoretoyMerkeId,
              aktivertDato,
              deaktivertDato,
              adresse1,
              adresse2,
              poststed,
              postnummer,
              validationError,
            },
            setOpen,
            setSubmitButtonText,
            setValidationError,
            kjoretoyMerker,
            putAktor
          )
        }
        isForm
        submitButtonText={submitButtonText}
      >
        <AktorerForm
          orgNummer={orgNummer}
          kjoretoyMerkeId={kjoretoyMerkeId}
          kjoretoyMerker={kjoretoyMerker}
          aktivertDato={aktivertDato}
          deaktivertDato={deaktivertDato}
          adresse1={adresse1}
          adresse2={adresse2}
          poststed={poststed}
          postnummer={postnummer}
          handleChange={(e) =>
            handleChange(
              e,
              validationError,
              setOrgNummer,
              setKjoretoyMerkeId,
              setAktivertDato,
              setDeaktivertDato,
              setAdresse1,
              setAdresse2,
              setPoststed,
              setPostnummer,
              setValidationError
            )
          }
          validationError={validationError}
          actionType="update"
        />
      </ModalGeneric>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      putAktor,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(AktorerModal);
