const miljogebyrerSitePaths = {
  shortUrl: "/miljogebyrer",
  page: "/miljogebyrer/:path",
  oversikt: {
    shortUrl: "/miljogebyrer/oversikt",
    utskrift: "/miljogebyrer/oversikt/utskrift",
    detalj: {
      shortUrl: {
        matcher:
          "/miljogebyrer/oversikt/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$",
        routeMatcher:
          "/miljogebyrer/oversikt/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
        getUrl: (miljogebyrId) => `/miljogebyrer/oversikt/${miljogebyrId}`,
      },
      utskrift:
        "/miljogebyrer/oversikt/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/utskrift",
      toastUi:
        "/miljogebyrer/oversikt/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/bilder/:bildeId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/toastui",
      bilde:
        "/miljogebyrer/oversikt/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/bilder/:bildeId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
      svarUtHistorikk:
        "/miljogebyrer/oversikt/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/svar-ut/:svarUtId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/historikk",
      miljokontroll: {
        shortUrl: {
          matcher:
            "/miljogebyrer/oversikt/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/tidskontroller/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$",
          routeMatcher:
            "/miljogebyrer/oversikt/:miljogebyrId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/tidskontroller/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
          getUrl: (miljogebyrId, miljokontrollId) =>
            `/miljogebyrer/oversikt/${miljogebyrId}/tidskontroller/${miljokontrollId}`,
        },
        toastUi:
          "/miljogebyrer/oversikt/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/tidskontroller/:miljokontrollId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/observasjoner/:observasjonId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/bilder/:bildeId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/toastui",
        bilde:
          "/miljogebyrer/oversikt/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/tidskontroller/:miljokontrollId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/observasjoner/:observasjonId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/bilder/:bildeId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
      },
    },
  },
  tilBehandling: {
    shortUrl: "/miljogebyrer/til-behandling",
    utskrift: "/miljogebyrer/til-behandling/utskrift",
    detalj: {
      shortUrl: {
        matcher:
          "/miljogebyrer/til-behandling/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$",
        routeMatcher:
          "/miljogebyrer/til-behandling/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
        getUrl: (miljogebyrId) =>
          `/miljogebyrer/til-behandling/${miljogebyrId}`,
      },
      utskrift:
        "/miljogebyrer/til-behandling/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/utskrift",
      toastUi:
        "/miljogebyrer/til-behandling/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/bilder/:bildeId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/toastui",
      bilde:
        "/miljogebyrer/til-behandling/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/bilder/:bildeId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
      svarUtHistorikk:
        "/miljogebyrer/til-behandling/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/svar-ut/:svarUtId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/historikk",
      miljokontroll: {
        shortUrl: {
          matcher:
            "/miljogebyrer/til-behandling/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/tidskontroller/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$",
          routeMatcher:
            "/miljogebyrer/til-behandling/:miljogebyrId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/tidskontroller/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
          getUrl: (miljogebyrId, miljokontrollId) =>
            `/miljogebyrer/til-behandling/${miljogebyrId}/tidskontroller/${miljokontrollId}`,
        },
        toastUi:
          "/miljogebyrer/til-behandling/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/tidskontroller/:miljokontrollId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/observasjoner/:observasjonId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/bilder/:bildeId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/toastui",
        bilde:
          "/miljogebyrer/til-behandling/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/tidskontroller/:miljokontrollId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/observasjoner/:observasjonId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/bilder/:bildeId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
      },
    },
  },
  miljokontrolloversikt: {
    shortUrl: "/miljogebyrer/tidskontrolloversikt",
    miljogebyrer: {
      detalj: {
        shortUrl: {
          matcher:
            "/miljogebyrer/tidskontrolloversikt/miljogebyrer/([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})$",
          routerMatcher:
            "/miljogebyrer/tidskontrolloversikt/miljogebyrer/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
          getUrl: (miljogebyrId) =>
            `/miljogebyrer/tidskontrolloversikt/miljogebyrer/${miljogebyrId}`,
        },
        toastUi:
          "/miljogebyrer/tidskontrolloversikt/miljogebyrer/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/bilder/:bildeId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/toastui",
        bilde:
          "/miljogebyrer/tidskontrolloversikt/miljogebyrer/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/bilder/:bildeId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
        svarUtHistorikk:
          "/miljogebyrer/tidskontrolloversikt/miljogebyrer/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/svar-ut/:svarUtId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/historikk",
        miljokontroll: {
          detalj: {
            shortUrl: {
              matcher:
                "/miljogebyrer/tidskontrolloversikt/miljogebyrer/([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/tidskontroller/([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})$",
              routerMatcher:
                "/miljogebyrer/tidskontrolloversikt/miljogebyrer/:miljogebyrId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/tidskontroller/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
              getUrl: (miljogebyrId, miljokontrollId) =>
                `/miljogebyrer/tidskontrolloversikt/miljogebyrer/${miljogebyrId}/tidskontroller/${miljokontrollId}`,
            },
            toastUi:
              "/miljogebyrer/tidskontrolloversikt/miljogebyrer/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/tidskontroller/:miljokontrollId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/observasjoner/:observasjonId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/bilder/:bildeId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/toastui",
            bilde:
              "/miljogebyrer/tidskontrolloversikt/miljogebyrer/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/tidskontroller/:miljokontrollId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/observasjoner/:observasjonId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/bilder/:bildeId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
          },
        },
      },
    },
    detalj: {
      shortUrl: {
        routerMatcher:
          "/miljogebyrer/tidskontrolloversikt/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
        getUrl: (miljokontrollId) =>
          `/miljogebyrer/tidskontrolloversikt/${miljokontrollId}`,
      },
      // toastUi Should actually be inside miljogebyr -> miljokontroll -> detalj if one would follow the same pattern as before
      toastUi:
        "/miljogebyrer/tidskontrolloversikt/:miljokontrollId0([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/miljogebyrer/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/tidskontroller/:miljokontrollId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/observasjoner/:observasjonId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/bilder/:bildeId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/toastui",
      bilde:
        "/miljogebyrer/tidskontrolloversikt/:miljokontrollId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/observasjoner/:observasjonId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/bilder/:bildeId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
      miljogebyrer: {
        detalj: {
          shortUrl: {
            matcher:
              "/miljogebyrer/tidskontrolloversikt/([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/miljogebyrer/([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})$",
            routeMatcher:
              "/miljogebyrer/tidskontrolloversikt/:miljokontrollId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/miljogebyrer/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
            getUrl: (miljokontrollId, miljogebyrId) =>
              `/miljogebyrer/tidskontrolloversikt/${miljokontrollId}/miljogebyrer/${miljogebyrId}`,
          },
          toastUi:
            "/miljogebyrer/tidskontrolloversikt/:miljokontrollId0([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/miljogebyrer/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/bilder/:bildeId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/toastui",
          miljogebyrBilde:
            "/miljogebyrer/tidskontrolloversikt/:miljokontrollId0([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/miljogebyrer/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/bilder/:bildeId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
          tidskontrollBilde:
            "/miljogebyrer/tidskontrolloversikt/:miljokontrollId0([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/miljogebyrer/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/tidskontroller/:miljokontrollId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/observasjoner/:observasjonId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/bilder/:bildeId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
          svarUtHistorikk:
            "/miljogebyrer/tidskontrolloversikt/:miljokontrollId0([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/miljogebyrer/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/svar-ut/:svarUtId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/historikk",
        },
      },
    },
  },
};

export default miljogebyrerSitePaths;
