import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { map } from "lodash";
import { Link } from "react-router-dom";

import Table from "../../Common/Tables/BaseTable";

import { toNorwegianDatetime } from "../../../utils/datetimeUtils";
import sitePaths from "../../../constants/sitePaths";

class VentilstillingList extends Component {
  static propTypes = {
    ventilstillinger: PropTypes.array,
    id: PropTypes.string,
  };

  header = [
    "Dato",
    "Hovedgate",
    "Tjenestenr.",
    "Kjennemerke",
    "Ileggelse",
    "Lukket",
  ];

  render() {
    const { id, ventilstillinger } = this.props;

    return (
      <div className="table-wrapper-horizontal-scroll">
        <Table headerRow={this.header} id={id} striped>
          <tbody>
            {map(ventilstillinger, (ventilstilling, index) => {
              return (
                <tr key={index}>
                  <td>
                    <Link to={ventilstilling.id}>
                      {toNorwegianDatetime(ventilstilling.dato).format(
                        "DD.MM.YYYY"
                      )}
                    </Link>
                  </td>
                  <td>
                    {`${
                      ventilstilling.gatenavn ? ventilstilling.gatenavn : ""
                    } ${
                      ventilstilling.gatenummer ? ventilstilling.gatenummer : ""
                    }`}
                  </td>
                  <td>{ventilstilling.tjenesteNummer}</td>
                  <td>{ventilstilling.kjennemerke}</td>
                  <td>
                    {ventilstilling.ileggelsesnummer && (
                      <Link
                        to={sitePaths.rapporter.ventilstillinger.ileggelse.detalj.shortUrl.getUrl(
                          ventilstilling.ileggelseId
                        )}
                        style={{ textDecoration: "none" }}
                      >
                        <span>{ventilstilling.ileggelsesnummer}</span>
                      </Link>
                    )}
                  </td>
                  <td>{ventilstilling.isLukket ? "Lukket" : "Aktiv"}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default VentilstillingList;
