import * as api from "../constants/api";
import * as axiosUtils from "../utils/axiosUtils";

export const actionType = "KJORETOY_TYPER";
const metaData = { entitet: "Kjoretoytype", notify: true };

export function getKjoretoyTyper() {
  return axiosUtils.GetAll(api.GET_KJORETOY_TYPER_URL, actionType);
}

export function postKjoretoyType(payload) {
  return axiosUtils.Post(
    api.POST_KJORETOY_TYPE_URL,
    payload,
    actionType,
    metaData
  );
}

export function putKjoretoyType(payload) {
  return axiosUtils.Put(
    api.PUT_KJORETOY_TYPE_URL(payload.id),
    payload,
    actionType,
    metaData
  );
}
