import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import isEmpty from "lodash/isEmpty";
import { UpdateVedtakerFerdigBehandlet } from "../../actions/klager/vedtakAction";
import { handleChange as handleChangeAction } from "../../actions/handleChangeAction";
import { IsValidated } from "../../utils/validationUtils";

class FerdigBehandlerVedtakerContainer extends Component {
  state = {
    showFerdigbehandleVedtakSpinner: false,
  };
  rules = {
    vedtakskode: "required",
  };

  isValid = () => {
    const { klageVedtakerForm, handleChangeVedtakerAction } = this.props;
    let isValidArray = [];
    for (const [key, value] of Object.entries({ ...klageVedtakerForm })) {
      const { isValid, validationError } = IsValidated(value, this.rules);
      isValidArray.push(isValid);
      handleChangeVedtakerAction(key, {
        vedtakskode: value.vedtakskode,
        belop: value.belop,
        validationError,
      });
    }
    return isValidArray.every((isValid) => isValid === true);
  };

  FerdigBehandleVedtaker = async () => {
    const { klageId } = this.props;
    if (this.isValid()) {
      this.setState({ showFerdigbehandleVedtakSpinner: true });
      await this.props.UpdateVedtakerFerdigBehandlet(klageId);
      this.setState({ showFerdigbehandleVedtakSpinner: false });
    }
  };
  ButtonIsDesabled = () => {
    const { vedtaker } = this.props;
    return (
      !isEmpty(vedtaker) &&
      vedtaker.every((vedtak) => vedtak.vedtaksDato != null)
    );
  };
  render() {
    return (
      <button
        className="btn btn-bym-positive btn-bym-standard"
        onClick={this.FerdigBehandleVedtaker}
        disabled={this.ButtonIsDesabled()}
      >
        Ferdigbehandle vedtak
      </button>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    klageId:
      state.klageDetail && state.klageDetail.id ? state.klageDetail.id : "",
    vedtaker: state.vedtaker,
    klageVedtakerForm: state.forms && state.forms.klageVedtakerForm,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(
    {
      UpdateVedtakerFerdigBehandlet,
      handleChangeVedtakerAction: (variableName, payload) =>
        handleChangeAction("KLAGE_VEDTAKER_FORM", variableName, payload),
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FerdigBehandlerVedtakerContainer);
