import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import DetaljKommentarer from "../../Detalj/DetaljKommentarer";
import GridTable from "../../GridTable";

import { getOvertredelseskoderMiljogebyr as getOvertredelseskoderMiljogebyrAction } from "../../../../actions/overtredelseskoderMiljogebyrActions";

import { getGridTableRowData } from "../../../../selectors/miljogebyrSelector";

class MiljogebyrGridOgKommentarContainer extends React.Component {
  static propTypes = {
    miljogebyr: PropTypes.object,
    gridTableRowData: PropTypes.array,
    getOvertredelseskoderMiljogebyr: PropTypes.func.isRequired,
  };

  componentDidMount = () => {
    const { getOvertredelseskoderMiljogebyr } = this.props;
    getOvertredelseskoderMiljogebyr();
  };

  render() {
    return (
      <section>
        <GridTable name="detaljMiljogebyr" rows={this.props.gridTableRowData} />
        <div style={{ paddingLeft: 50, paddingRight: 50 }}>
          <DetaljKommentarer
            internkommentarFraBybetjent={this.props.miljogebyr.internkommentar}
            kommentarTilMottager={this.props.miljogebyr.kommentar}
          />
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    gridTableRowData: getGridTableRowData(state),
    miljogebyr: state.miljogebyr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOvertredelseskoderMiljogebyr: () =>
      dispatch(getOvertredelseskoderMiljogebyrAction()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MiljogebyrGridOgKommentarContainer);
