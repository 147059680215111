import React from "react";
import Table from "../../../Common/Tables/Table";
import { useDispatch, useSelector } from "react-redux";
import rapporterSitePaths from "../../../../constants/SitePaths/rapporterSitePaths";
import { Link } from "react-router-dom";
import {
  toNorwegianDateFormat,
  toNorwegianDatetime,
} from "../../../../utils/datetimeUtils";
import { HeaderCell } from "../../../Common/Tables";
import { getSingleUbetalteIleggelser } from "../../../../actions/rapporter/ubetalteIleggelserActions";
import { useQuery } from "../../../../utils/useQuery";

const UbetalteIleggelserDetaljList = ({
  kjoretoyId,
  ileggelseType,
  showSpinner,
  sort,
  handleSetShowTableSpinner,
  setSort,
  setOppdatertTid,
}) => {
  const query = useQuery();
  const dispatch = useDispatch();

  const ubetalteIleggelserSingleKjennemerke = useSelector(
    (state) => state.rapporter.ubetalteIleggelserSingleKjennemerke
  );

  const onSort = async (sort) => {
    setSort(sort);
    setOppdatertTid(new Date());
    handleSetShowTableSpinner(true);
    await dispatch(
      getSingleUbetalteIleggelser({
        kjoretoyId: kjoretoyId,
        ileggelseType: query.get("ileggelseType"),
        sort: sort.cellKey,
        sortOrder: sort.orders,
      })
    )
      .then(() => {
        handleSetShowTableSpinner(false);
      })
      .catch(() => {
        handleSetShowTableSpinner(false);
      });
  };

  const getTableHeaders = (sort) => (
    <React.Fragment>
      <HeaderCell cellKey="ileggelsesnummer" sort={sort} onSort={onSort}>
        Ileggnr.
      </HeaderCell>
      <HeaderCell cellKey="dato" sort={sort} onSort={onSort}>
        Ilegg.dato
      </HeaderCell>
      <HeaderCell>Klokkeslett</HeaderCell>
      <HeaderCell cellKey="kjennemerke" sort={sort} onSort={onSort}>
        Kj.merke
      </HeaderCell>
      <HeaderCell cellKey="eierNavn" sort={sort} onSort={onSort}>
        Eier
      </HeaderCell>
      <HeaderCell cellKey="overtedelseKode" sort={sort} onSort={onSort}>
        Overt.kode
      </HeaderCell>
      <HeaderCell cellKey="gate" sort={sort} onSort={onSort}>
        Gatenavn
      </HeaderCell>
      <HeaderCell cellKey="vedGate" sort={sort} onSort={onSort}>
        Ved
      </HeaderCell>
      <HeaderCell cellKey="klagekode" sort={sort} onSort={onSort}>
        Klagekode
      </HeaderCell>
      <HeaderCell cellKey="ileggelsesType" sort={sort} onSort={onSort}>
        Type
      </HeaderCell>
    </React.Fragment>
  );

  const renderRow = (ubetaltIleggelseInfo) => {
    return (
      <tr key={`ubetaltIleggelseInfo-${ubetaltIleggelseInfo.id}`}>
        <td>
          <Link
            to={{
              pathname:
                rapporterSitePaths.ubetalteIleggelser.detalj.ileggelser.shortUrl.getUrl(
                  kjoretoyId,
                  ubetaltIleggelseInfo.id
                ),
              search: `?ileggelseType=${ileggelseType}`,
            }}
            className="underline"
          >
            {ubetaltIleggelseInfo.ileggelsesnummer}
          </Link>
        </td>
        <td>
          {toNorwegianDateFormat(
            toNorwegianDatetime(ubetaltIleggelseInfo.dato)
          )}
        </td>
        <td>{ubetaltIleggelseInfo.klokke}</td>
        <td>{ubetaltIleggelseInfo.kjennemerke}</td>
        <td>{ubetaltIleggelseInfo.eierNavn}</td>
        <td>{ubetaltIleggelseInfo.overtredelseKode}</td>
        <td>{ubetaltIleggelseInfo.gate}</td>
        <td>{ubetaltIleggelseInfo.vedGate}</td>
        <td>{ubetaltIleggelseInfo.klageKode}</td>
        <td>{ubetaltIleggelseInfo.ileggelsesType}</td>
      </tr>
    );
  };

  return (
    <div>
      <h3 style={{ fontSize: 16, fontWeight: "bold" }}>Ubetalte ileggelser</h3>
      <Table
        id="ubetalte-ileggelser-table"
        items={ubetalteIleggelserSingleKjennemerke ?? []}
        renderRow={renderRow}
        showSpinner={showSpinner}
      >
        {getTableHeaders(sort)}
      </Table>
    </div>
  );
};

export default UbetalteIleggelserDetaljList;
