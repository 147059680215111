import axios from "../services/axios";
import * as api from "../constants/api";
import * as handle from "../utils/actionUtils";

export const GET_ILEGGELSESTYPER_SUCCESS = "GET_ILEGGELSESTYPER_SUCCESS";
export const GET_ILEGGELSESTYPER_FAILURE = "GET_ILEGGELSESTYPER_FAILURE";

export function getIleggelsestyper() {
  return (dispatch, getState) => {
    return axios
      .get(api.GET_ILEGGELSESTYPER_URL)
      .then(handle.success(dispatch, GET_ILEGGELSESTYPER_SUCCESS))
      .catch(handle.error(dispatch, GET_ILEGGELSESTYPER_FAILURE));
  };
}
