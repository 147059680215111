import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import NavigationBarContainer from "./NavigationBarContainer";
import InnsynMotorvognFilterContainer from "./InnsynMotorvognFilterContainer";
import PrintOut from "../Common/PrintOut";
import InnsynMotorvognInfo from "./InnsynMotorvognInfo";

import sitePaths from "../../constants/sitePaths";

class InnsynMotorvognPage extends React.Component {
  static propTypes = {
    innsynMotorvognFilterForm: PropTypes.object,
    innsynMotorvogn: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      waitingForGetSingleMotorvognByQuery: false,
    };
  }

  waitingForGetSingleMotorvognByQuery = (waitingForGetSingleMotorvognByQuery) =>
    this.setState({ waitingForGetSingleMotorvognByQuery });

  render() {
    const { innsynMotorvogn, innsynMotorvognFilterForm } = this.props;

    return (
      <React.Fragment>
        <NavigationBarContainer page="motorvogn" />
        <InnsynMotorvognFilterContainer
          waitingForGetSingleMotorvognByQuery={
            this.waitingForGetSingleMotorvognByQuery
          }
        />
        {innsynMotorvogn && innsynMotorvogn.kjennemerke && (
          <div style={{ paddingRight: 50, paddingLeft: 50 }}>
            <div
              style={{
                display: "inline-block",
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              <PrintOut
                url={`${window.location.origin}${sitePaths.innsyn.motorvogn.utskrift}`}
                dataToStoreInSessionStorage={innsynMotorvognFilterForm}
              />
            </div>
            <InnsynMotorvognInfo motorvogn={innsynMotorvogn} />
          </div>
        )}
        {innsynMotorvogn &&
          !innsynMotorvogn.kjennemerke &&
          innsynMotorvogn.hasBeenSubmitted && (
            <div style={{ marginTop: 20, paddingLeft: 50 }}>
              Ingen motorvogn finnes for ditt søk.
            </div>
          )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    innsynMotorvogn: state.innsynMotorvogn,
    innsynMotorvognFilterForm: state.forms.innsynMotorvognFilterForm,
  };
};

export default connect(mapStateToProps)(InnsynMotorvognPage);
