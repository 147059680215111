import React from "react";
import { useAuth } from "react-oidc-context";
import { Redirect, useLocation } from "react-router-dom";
import { authorized, getUserRoles } from "../../utils/authorizationUtils";

import sitePaths from "../../constants/sitePaths";

export default function AuthorizedRoutes(props) {
  const { roles, component: Comp, ...rest } = props;

  const auth = useAuth();
  const location = useLocation();

  if (auth.isLoading) {
    return <div>Laster inn side...</div>;
  }

  if (auth.isAuthenticated) {
    if (authorized(roles)) {
      return <Comp {...rest} />;
    } else {
      return <UnAuthorized roles={roles} />;
    }
  }

  return (
    <Redirect
      to={{
        pathname: sitePaths.login,
        state: { from: location },
      }}
    />
  );
}

const UnAuthorized = ({ roles }) => {
  const userRoles = getUserRoles();
  return (
    <div
      style={{
        margin: "10px 30px",
        display: "flex",
        flexDirection: "column",
        gap: "5px",
      }}
    >
      <h1>Du mangler tilgang til denne siden</h1>
      <p>
        {userRoles.length > 1 ? "Dine roller er: " : "Din rolle er: "}
        <b>{userRoles.join(", ")}</b>
      </p>
      <p>
        {"For å få tilgang til siden trenger du en av følgende roller: "}
        <b>{roles.join(", ")}</b>
      </p>
    </div>
  );
};
