import React from "react";
import PropTypes from "prop-types";
import Table from "../Tables/Table";
import {
  toNorwegianDatetime,
  toNorwegianDateFormat,
} from "../../../utils/datetimeUtils";

const Klageinformasjon = ({ klager }) => {
  const renderRow = (klage) => (
    <tr>
      <td>{klage.klageKode}</td>
      <td>
        {toNorwegianDateFormat(toNorwegianDatetime(klage.registrertDato))}
      </td>
      <td>{klage.saksnummer}</td>
      <td>{klage.saksbehandler}</td>
      <td>{toNorwegianDateFormat(toNorwegianDatetime(klage.overfortDato))}</td>
      <td>{klage.nyttBelop}</td>
      <td>{klage.vedtakskode}</td>
    </tr>
  );

  return (
    <section>
      <div className="detalj-ileggelse-header-wrapper">
        <h2>Klageinformasjon</h2>
      </div>
      <div className="detalj-ileggelse-table-wrapper">
        <Table renderRow={renderRow} items={klager}>
          <th>Klagekode</th>
          <th>Klage registrert dato</th>
          <th>Saksnummer klage</th>
          <th>Saksbehandler</th>
          <th>Dato-klage overført INE</th>
          <th>Nytt beløp</th>
          <th>Vedtakskode</th>
        </Table>
      </div>
    </section>
  );
};

Klageinformasjon.propTypes = {
  klager: PropTypes.arrayOf(PropTypes.shape({})),
};

export default Klageinformasjon;
