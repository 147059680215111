import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";

import TextInput from "../Common/Forms/TextInput";

export default class KjoretoyMerkerForm extends React.Component {
  static propTypes = {
    kode: PropTypes.string,
    merke: PropTypes.string,
    validationError: PropTypes.object,
    handleChange: PropTypes.func,
  };

  render() {
    const { kode, merke, validationError, handleChange } = this.props;
    return (
      <form>
        <Row>
          <Col md={6}>
            <TextInput
              label="Kode"
              onChange={handleChange}
              value={kode}
              name="kode"
              validationError={validationError.kode}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <TextInput
              label="Merke"
              onChange={handleChange}
              value={merke}
              name="merke"
              validationError={validationError.merke}
            />
          </Col>
        </Row>
      </form>
    );
  }
}
