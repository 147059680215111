import React from "react";
import PropTypes from "prop-types";

import Table from "../Tables/Table";
import RenderRowEttersendelse from "./RenderRowEttersendelse";

import { authorized } from "../../../utils/authorizationUtils";

import { bypatruljen, juridisk } from "../../../constants/roles";

export const Ettersendelser = ({
  nyEttersendelse,
  hideIfEmpty,
  ettersendelser,
  getEttersendelserEttersendelsebrevPdf,
}) => {
  const isAuthorizedEttersendelsebrev = authorized([
    bypatruljen.saksbehandler,
    bypatruljen.saksbehandleradmin,
    juridisk.saksbehandler,
    juridisk.ledelse,
  ]);

  const renderTableRow = (ettersendelse) => {
    return (
      <RenderRowEttersendelse
        ettersendelse={ettersendelse}
        authorized={{
          isAuthorizedEttersendelsebrev,
        }}
        getEttersendelserEttersendelsebrevPdf={
          getEttersendelserEttersendelsebrevPdf
        }
      />
    );
  };

  if (hideIfEmpty && (!ettersendelser || ettersendelser.length === 0)) {
    return null;
  } else {
    return (
      <section>
        <div className="detalj-ileggelse-header-wrapper">
          <h2>{nyEttersendelse ? "Ny ettersendelse" : "Ettersendelse"}</h2>
        </div>
        <div className="detalj-ileggelse-table-wrapper">
          <Table
            cols={() => cols(isAuthorizedEttersendelsebrev)}
            renderRow={renderTableRow}
            items={ettersendelser}
          >
            <th>
              {nyEttersendelse
                ? "Grunn for ny ettersendelse"
                : "Ettersendelsesrapport fra betjent"}
            </th>
            <th>Tjenestenummer</th>
            <th>Rapporttidspunkt</th>
            <th>Saksnummer</th>
            <th>Saksbehandler</th>
            <th>Ettersendelsesdato</th>
            {isAuthorizedEttersendelsebrev && <th>Svar ut referanse</th>}
            {isAuthorizedEttersendelsebrev && <th>Svar ut status tid</th>}
            {isAuthorizedEttersendelsebrev && <th>Svar ut status</th>}
            {isAuthorizedEttersendelsebrev && <th>Ettersendelsebrev</th>}
          </Table>
        </div>
      </section>
    );
  }
};

Ettersendelser.propTypes = {
  hideIfEmpty: PropTypes.bool,
  ettersendelser: PropTypes.arrayOf(PropTypes.shape({})),
  getEttersendelserEttersendelsebrevPdf: PropTypes.func,
};

const cols = (isAuthorizedEttersendelsebrev) => {
  if (isAuthorizedEttersendelsebrev) {
    return (
      <React.Fragment>
        <col width="30%" />
        <col width="7.714%" />
        <col width="7.714%" />
        <col width="7.714%" />
        <col width="7.714%" />
        <col width="7.714%" />
        <col width="7.714%" />
        <col width="16%" />
        <col width="7.714%" />
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <col width="50%" />
      <col width="12.5%" />
      <col width="12.5%" />
      <col width="12.5%" />
      <col width="12.5%" />
    </React.Fragment>
  );
};

export default Ettersendelser;
