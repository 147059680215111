import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";

import createPaginationControl from "../../Common/createPaginationControl";
import PiggdekkontrollerFilterContainer from "./PiggdekkontrollFilterContainer";
import PiggdekkontrollerExcel from "./PiggdekkontrollExcel";
import PiggdekkontrollList from "./PiggdekkontrollList";
import NavigationBarContainer from "../NavigationBarContainer";

import { getPiggdekkontrollerList } from "../../../selectors/piggdekkontrollerSelector";

import { getPiggdekkontroller as getPiggdekkontrollerAction } from "../../../actions/piggdekkontrollerActions";

import * as title from "../../../constants/titles";

const PaginationControl = createPaginationControl(
  "piggdekkontroller",
  {
    apiCallAction: getPiggdekkontrollerAction,
    queriesForApiReducerName: "forms.piggdekkontrollFilterForm",
  },
  getPiggdekkontrollerList
);

class PiggdekkontrollerContainer extends React.Component {
  static propTypes = {
    piggdekkontroller: PropTypes.shape({
      items: PropTypes.array,
    }),
    getPiggdekkontroller: PropTypes.func.isRequired,
  };

  componentDidMount() {
    document.title = title.rapporter.piggdekkontroll;
  }

  render() {
    const { piggdekkontroller, piggdekkontrollerSubmittedForm } = this.props;
    return (
      <Container fluid>
        <Row>
          <Col>
            <NavigationBarContainer page="piggdekkontroll" />
            <div className="rapportSearch">
              <PiggdekkontrollerFilterContainer />
            </div>
            <div
              className="rapport-buttons-container"
              style={{ marginBottom: 15 }}
            >
              <PiggdekkontrollerExcel
                piggdekkontroller={piggdekkontroller.items}
                piggdekkontrollerSubmittedForm={piggdekkontrollerSubmittedForm}
              />
            </div>
            <div className="rapport-table">
              <div className="table-wrapper-horizontal-scroll">
                <PiggdekkontrollList
                  piggdekkontroller={piggdekkontroller.items}
                />
              </div>
              <PaginationControl storeKey="piggdekkontroller" />
            </div>
          </Col>
          <div style={{ height: 28 }} />
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    piggdekkontroller: state.piggdekkontroller,
    piggdekkontrollFilterForm: state.forms.piggdekkontrollFilterForm,
    piggdekkontrollerSubmittedForm:
      state.submittedForms.piggdekkontrollerSubmittedForm,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPiggdekkontroller: (params, page, count) => {
      dispatch(getPiggdekkontrollerAction(params, page, count));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PiggdekkontrollerContainer);
