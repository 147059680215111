import * as miljogebyrerActions from "../actions/miljogebyrerActions";

const INITIAL_STATE = {};

export default function miljogebyrReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case miljogebyrerActions.GET_MILJOGEBYR_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
