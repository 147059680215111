import { GET_MILJOGEBYRER_HISTORIKK_SUCCESS } from "../actions/miljogebyrerActions";

const INITIAL_STATE = [];

export default function miljogebyrHistorikkReducer(
  state = [...INITIAL_STATE],
  action
) {
  switch (action.type) {
    case GET_MILJOGEBYRER_HISTORIKK_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
