import axios from "../services/axios";
import * as api from "../constants/api";
import * as handle from "../utils/actionUtils";
import { addFlashMessage } from "./FlashMessagesAction";
import * as apiResponseUtils from "../utils/apiResponseUtils";

export const GET_ILEGGELSE_ETTERSENDELSER_SUCCESS =
  "GET_ILEGGELSE_ETTERSENDELSER_SUCCESS";
export const GET_ILEGGELSE_ETTERSENDELSER_FAILURE =
  "GET_ILEGGELSE_ETTERSENDELSER_FAILURE";

export function getIleggelseEttersendelser(ileggelseId) {
  return async (dispatch) => {
    return axios
      .get(api.GET_ILEGGELSE_ETTERSENDELSER(ileggelseId))
      .then(handle.success(dispatch, GET_ILEGGELSE_ETTERSENDELSER_SUCCESS))
      .catch(handle.error(dispatch, GET_ILEGGELSE_ETTERSENDELSER_FAILURE));
  };
}

export function putIleggelseEttersendelser(ettersendelseId, data) {
  return async (dispatch) => {
    return axios
      .put(api.PUT_ILEGGELSE_ETTERSENDELSER(ettersendelseId), data)
      .then((response) => {
        dispatch(addFlashMessage({ type: "success", text: "Oppdatert" }));
        return Promise.resolve(response);
      })
      .catch((error) => {
        let errorMessage = apiResponseUtils.errorData(error);
        dispatch(addFlashMessage({ type: "error", text: errorMessage }));
        return Promise.reject(error);
      });
  };
}

export function postIleggelseEttersendelser(ileggelseId, params) {
  return async () => {
    return axios
      .post(api.POST_ILEGGELSE_ETTERSENDELSER(ileggelseId), params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  };
}

export function postIleggelseEttersendelsesbrev(ettersendelseId) {
  return async (dispatch) => {
    return axios
      .post(api.POST_ILEGGELSE_ETTERSENDELSESBREV(ettersendelseId))
      .then((response) => Promise.resolve(response))
      .catch((error) => {
        let errorMessage = apiResponseUtils.errorData(error);
        dispatch(addFlashMessage({ type: "error", text: errorMessage }));
        return Promise.reject(error);
      });
  };
}
