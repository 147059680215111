import { actionTypeUbetalteIleggelserSingleKjennemerke } from "../../actions/rapporter/ubetalteIleggelserActions";
import { CreateNewReducer } from "../../utils/reducerUtils";

const initialState = [];
export const ubetalteIleggelserSingleKjennemerkeReducer = (
  state = [...initialState],
  action
) =>
  CreateNewReducer(
    state,
    action,
    actionTypeUbetalteIleggelserSingleKjennemerke
  );

export default ubetalteIleggelserSingleKjennemerkeReducer;
