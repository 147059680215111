import axios from "../services/axios";
import * as axiosUtils from "../utils/axiosUtils";

import { addFlashMessage } from "./FlashMessagesAction";

import * as api from "../constants/api";
import { errorData } from "../utils/apiResponseUtils";

export const actionTypeVentilstillinger = "VENTILSTILLINGER";

export const getVentilstillinger = (ventilstillingerFormState) => {
  return axiosUtils.GetAllWithParams(
    api.GET_VENTILSTILLINGER_URL,
    ventilstillingerFormState,
    actionTypeVentilstillinger,
    "ventillstillinger"
  );
};

export const actionTypeVentilstilling = "VENTILSTILLING";

export const getVentilstilling = (id) =>
  axiosUtils.GetAll(api.GET_VENTILSTILLING_URL(id), actionTypeVentilstilling);

export const GET_VENTILSTILLING_BILDER_SUCCESS =
  "GET_VENTILSTILLING_BILDER_SUCCESS";

export const getVentilstillingBilde = (ventilstillingId, bildeId) => {
  return async (dispatch) => {
    return axios
      .get(api.GET_VENTILSTILLING_BILDE_URL(ventilstillingId, bildeId))
      .then(async (response) => {
        return response.data.result;
      })
      .catch((error) => {
        const errorMessage = errorData(error);
        dispatch(
          addFlashMessage({
            type: "error",
            message: errorMessage,
          })
        );

        return Promise.reject(false);
      });
  };
};

export function getVentilstillingBilder(ventilstillingId) {
  return async (dispatch) => {
    const url = api.GET_VENTILSTILLINGER_BILDER_BASE_URL(ventilstillingId);
    return axios
      .get(url)
      .then((response) => {
        const result = response.data.result;
        // const dateSortedImages = getDatoSortedImages(result);

        dispatch({
          type: GET_VENTILSTILLING_BILDER_SUCCESS,
          payload: {
            ventilstillingId,
            // bilder: dateSortedImages,
            bilder: result,
          },
        });
        return Promise.resolve(result);
      })
      .catch((error) => {
        dispatch(
          addFlashMessage({
            type: "error",
            message: error,
          })
        );
        return Promise.reject(false);
      });
  };
}
