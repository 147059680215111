import React from "react";
import PropTypes from "prop-types";

import DropDown from "../Common/Forms/DropDown";

import sitePaths from "../../constants/sitePaths";
import { aktivStatuser } from "../../constants/globals";

const handleStatusChange = (event, handleChange) => {
  const name = event.target.name;
  const value = event.target.value;
  handleChange(name, value);
};

const getDropdownAktivStatusData = (pathneme, grunndataFilterForm) => {
  switch (pathneme) {
    case sitePaths.grunndata.aktorer:
      return {
        name: "aktorerAktivStatus",
        selected: grunndataFilterForm.aktorerAktivStatus,
      };
    case sitePaths.grunndata.overtredelseskoderIleggelser:
      return {
        name: "overtredelseskoderIleggelserAktivStatus",
        selected: grunndataFilterForm.overtredelseskoderIleggelserAktivStatus,
      };
    case sitePaths.grunndata.overtredelseskoderMiljogebyr:
      return {
        name: "overtredelseskoderMiljogebyrAktivStatus",
        selected: grunndataFilterForm.overtredelseskoderMiljogebyrAktivStatus,
      };
    case sitePaths.grunndata.internkoderIleggelser:
      return {
        name: "internkoderIleggelserAktivStatus",
        selected: grunndataFilterForm.internkoderIleggelserAktivStatus,
      };
    case sitePaths.grunndata.internkoderMiljogebyr:
      return {
        name: "internkoderMiljogebyrAktivStatus",
        selected: grunndataFilterForm.internkoderMiljogebyrAktivStatus,
      };
    case sitePaths.grunndata.vedtakskoder:
      return {
        name: "vedtakskoderAktivStatus",
        selected: grunndataFilterForm.vedtakskoderAktivStatus,
      };
    default:
      return { name: undefined, selected: undefined };
  }
};

const getStatusDropDown = (pathname, grunndataFilterForm, handleChange) => (
  <div style={{ maxWidth: 240 }}>
    <DropDown
      label="Status"
      name={getDropdownAktivStatusData(pathname, grunndataFilterForm).name}
      items={aktivStatuser}
      selected={
        getDropdownAktivStatusData(pathname, grunndataFilterForm).selected
      }
      onChange={(event) => handleStatusChange(event, handleChange)}
    />
  </div>
);

const GrunndataFilter = ({ location, grunndataFilterForm, handleChange }) => {
  if (
    location &&
    (location.pathname === sitePaths.grunndata.aktorer ||
      location.pathname === sitePaths.grunndata.overtredelseskoderIleggelser ||
      location.pathname === sitePaths.grunndata.overtredelseskoderMiljogebyr ||
      location.pathname === sitePaths.grunndata.internkoderIleggelser ||
      location.pathname === sitePaths.grunndata.internkoderMiljogebyr ||
      location.pathname === sitePaths.grunndata.vedtakskoder)
  ) {
    return (
      <form className="filter-container">
        {getStatusDropDown(
          location.pathname,
          grunndataFilterForm,
          handleChange
        )}
      </form>
    );
  }
  return null;
};

GrunndataFilter.propTypes = {
  location: PropTypes.object,
  grunndataFilterForm: PropTypes.object,
  handleChange: PropTypes.func,
};

export default GrunndataFilter;
