import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./TextInput.css";

function hasError(error) {
  return error && error.length > 0;
}
const TextInput = ({
  name,
  value,
  valueFront,
  label,
  id,
  validationError,
  type,
  onChange,
  onRemove,
  index,
  onKeyPress,
  onBlur,
  placeholder,
  disabled,
  autofocus,
  autocomplete,
  rules,
  datalist,
}) => {
  return (
    <div
      className={classnames(
        "form-group",
        validationError ? { "has-error": validationError } : ""
      )}
    >
      {label && (
        <label className="control-label" htmlFor={name}>
          {label}
        </label>
      )}
      <div className={valueFront ? "input-group" : ""}>
        <input
          className="form-control"
          onChange={onChange}
          onKeyPress={onKeyPress}
          onBlur={onBlur}
          value={value}
          type={type}
          name={name}
          placeholder={placeholder}
          id={id}
          disabled={disabled}
          autoFocus={autofocus}
          autoComplete={autocomplete ? "on" : "off"}
          rules={rules}
          list={datalist && datalist.id ? datalist.id : undefined}
        />
        {datalist && datalist.id && datalist.datalist && (
          <datalist id={datalist.id}>
            {datalist.datalist.map((item) => (
              <option key={item}>{item}</option>
            ))}
          </datalist>
        )}
        {valueFront && (
          <span id="valueFront" className="btn btn-link">
            {valueFront}
          </span>
        )}
        {index !== undefined && index !== 0 && (
          <span className="btn btn-link" onClick={(e) => onRemove(e, index)}>
            <i className="intervallRemovesign fa fa-times-circle" />
          </span>
        )}
      </div>
      {hasError(validationError) && (
        <span className="validation-error">{validationError}</span>
      )}
    </div>
  );
};

TextInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  placeHolder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valueFront: PropTypes.any,
  label: PropTypes.string,
  validationError: PropTypes.string,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  autofocus: PropTypes.bool,
  onRemove: PropTypes.func,
  index: PropTypes.number,
  rules: PropTypes.string, //semicolone seprated ruls
  datalist: PropTypes.shape({
    id: PropTypes.string.isRequired,
    datalist: PropTypes.arrayOf(PropTypes.string),
  }),
};

TextInput.defaultProps = {
  type: "text",
};
export default TextInput;
