import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import TextInput from "../../Common/Forms/TextInput";
import DatetimePicker from "../../Common/Forms/DatetimePickerEnhanced";
import DropDown from "../../Common/Forms/DropDown";
import FormButtonsForCloseAndSubmit from "../../Common/Forms/FormButtonsForCloseAndSubmit";

import { getAllGater as getAllGaterAction } from "../../../actions/gateAdresseActions";
import { getKjoretoyMerker as getKjoretoyMerkerAction } from "../../../actions/kjoretoyMerkerActions";
import { getKjoretoyTyper as getKjoretoyTyperAction } from "../../../actions/kjoretoyTyperActions";
import { getUregistrertekontroller as getUregistrertekontrollerAction } from "../../../actions/uregistrertekontrollerActions";
import {
  handleChangeAll as handleChangeAllAction,
  handleChangeToInitState as handleChangeToInitStateAction,
} from "../../../actions/handleChangeAction";

import { handleFromToDateTime } from "../../../utils/fromToDateTimeUtils";
import { INITIAL_STATE as initStateUregistrerteKjoretoyFilterForm } from "../../../reducers/forms/uregistrertekontrollerFilterFormReducer";

const UregistrerteKontrollerFilter = () => {
  const uregistrertekontrollerFilterForm = useSelector(
    (store) => store.forms.uregistrertekontrollerFilterForm
  );
  const uregistrertekontroller = useSelector(
    (store) => store.uregistrertekontroller
  );

  const [status, setStatus] = useState(uregistrertekontrollerFilterForm.status);
  const [adresse, setAdresse] = useState(
    uregistrertekontrollerFilterForm.adresse
  );
  const [fraDato, setFraDato] = useState(
    uregistrertekontrollerFilterForm.fraDato
  );
  const [tilDato, setTilDato] = useState(
    uregistrertekontrollerFilterForm.tilDato
  );
  const [fraInntauetDato, setFraInntauetDato] = useState(
    uregistrertekontrollerFilterForm.fraInntauetDato
  );
  const [tilInntauetDato, setTilInntauetDato] = useState(
    uregistrertekontrollerFilterForm.tilInntauetDato
  );
  const [kjoretoyMerke, setKjoretoyMerke] = useState(
    uregistrertekontrollerFilterForm.kjoretoyMerke
  );
  const [kjoretoyType, setKjoretoyType] = useState(
    uregistrertekontrollerFilterForm.kjoretoyType
  );

  const [gatenavn, setGatenavn] = useState([]);
  const [kjoretoyMerkerMerke, setKjoretoyMerkerMerke] = useState([]);
  const [kjoretoyTyperNavn, setKjoretoyTyperNavn] = useState([]);
  const [showSpinnerState, setShowSpinnerState] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllGaterAction()).then((gater) => {
      if (gater && gater.length > 0) {
        const gatenavnFraBackend = gater.map((g) => g.navn);
        setGatenavn(gatenavnFraBackend);
      }
    });

    dispatch(getKjoretoyMerkerAction()).then((kjoretoyMerker) => {
      if (kjoretoyMerker && kjoretoyMerker.length > 0) {
        const kjoretoyMerkerMerkeFraBackend = kjoretoyMerker.map(
          (km) => km.merke
        );
        setKjoretoyMerkerMerke(kjoretoyMerkerMerkeFraBackend);
      }
    });

    dispatch(getKjoretoyTyperAction()).then((kjoretoyTyper) => {
      if (kjoretoyTyper && kjoretoyTyper.length > 0) {
        const kjoretoyTyperNavnFraBackend = kjoretoyTyper.map((kt) => kt.navn);
        setKjoretoyTyperNavn(kjoretoyTyperNavnFraBackend);
      }
    });
  }, [dispatch]);

  const handleChange = (e) => {
    switch (e.target.name) {
      case "status":
        setStatus(e.target.value);
        break;
      case "adresse":
        setAdresse(e.target.value);
        break;
      case "fraDato":
        handleFromToDateTime(e, {}, (_, value) => setFraDato(value));
        setFraDato(e.target.value);
        break;
      case "tilDato":
        handleFromToDateTime(e, {}, (_, value) => setTilDato(value));
        setTilDato(e.target.value);
        break;
      case "fraInntauetDato":
        handleFromToDateTime(
          { target: { name: "fraDato", value: e.target.value } },
          {},
          (_, value) => setFraInntauetDato(value)
        );
        setFraInntauetDato(e.target.value);
        break;
      case "tilInntauetDato":
        handleFromToDateTime(
          { target: { name: "tilDato", value: e.target.value } },
          {},
          (_, value) => setTilInntauetDato(value)
        );
        setTilInntauetDato(e.target.value);
        break;
      case "kjoretoyMerke":
        setKjoretoyMerke(e.target.value);
        break;
      case "kjoretoyType":
        setKjoretoyType(e.target.value);
        break;

      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowSpinnerState(true);
    dispatch(
      getUregistrertekontrollerAction(
        {
          status,
          adresse,
          fraDato,
          tilDato,
          fraInntauetDato,
          tilInntauetDato,
          kjoretoyMerke,
          kjoretoyType,
        },
        1,
        uregistrertekontroller?.count,
        uregistrertekontroller?.sort
      )
    )
      .then(() => {
        handleChangeAll({ status, adresse, fraDato, tilDato });
        setShowSpinnerState(false);
      })
      .catch((_) => {
        setShowSpinnerState(false);
      });
  };

  const handleChangeAll = (payload) => {
    dispatch(
      handleChangeAllAction("UREGISTRERTE_KONTROLLER_FILTER_FORM", payload)
    );
  };

  const setComponentStateToInitValues = () => {
    setStatus(initStateUregistrerteKjoretoyFilterForm.status);
    setAdresse(initStateUregistrerteKjoretoyFilterForm.adresse);
    setFraDato(initStateUregistrerteKjoretoyFilterForm.fraDato);
    setTilDato(initStateUregistrerteKjoretoyFilterForm.tilDato);
    setAdresse(initStateUregistrerteKjoretoyFilterForm.kjoretoyMerke);
    setAdresse(initStateUregistrerteKjoretoyFilterForm.kjoretoyType);
  };

  const handleChangeToInitState = () => {
    dispatch(
      handleChangeToInitStateAction("UREGISTRERTE_KONTROLLER_FILTER_FORM")
    );
    setComponentStateToInitValues();
  };

  return (
    <section>
      <div className="ettersendelse-filter-container">
        <h1>Uregistrerte kjøretøy</h1>
        <form onSubmit={handleSubmit}>
          <div className="filter-flexbox-container ettersendelse-filter-flexbox-item">
            <DropDown
              label="Status"
              name="status"
              items={[
                { id: "Aktiv", navn: "Aktiv" },
                { id: "Borte", navn: "Borte" },
                { id: "Inntauet", navn: "Inntauet" },
                { id: "Inntauing", navn: "Inntauing" },
              ]}
              selected={status}
              title="Velg"
              onChange={handleChange}
            />
            <TextInput
              label="Adresse"
              type="text"
              datalist={{ id: "gater", datalist: gatenavn }}
              name="adresse"
              onChange={handleChange}
              value={adresse}
            />
            <DatetimePicker
              id="fraDato"
              name="fraDato"
              locale="nb"
              label="Dato fra"
              onChange={handleChange}
              value={fraDato}
              timeFormat={false}
            />
            <DatetimePicker
              id="tilDato"
              name="tilDato"
              locale="nb"
              label="Dato til"
              onChange={handleChange}
              value={tilDato}
              timeFormat={false}
            />
            <DatetimePicker
              id="fraInntauetDato"
              name="fraInntauetDato"
              locale="nb"
              label="Inntauet dato fra"
              onChange={handleChange}
              value={fraInntauetDato}
              timeFormat={false}
            />
            <DatetimePicker
              id="tilInntauetDato"
              name="tilInntauetDato"
              locale="nb"
              label="Inntauet dato til"
              onChange={handleChange}
              value={tilInntauetDato}
              timeFormat={false}
            />
          </div>
          <div className="filter-flexbox-container ettersendelse-filter-flexbox-item">
            <TextInput
              label="Kjøretøystype"
              type="text"
              datalist={{ id: "kjoretoystyper", datalist: kjoretoyTyperNavn }}
              name="kjoretoyType"
              onChange={handleChange}
              value={kjoretoyType}
            />
            <TextInput
              label="Fabrikkmerke"
              type="text"
              datalist={{
                id: "kjoretoysmerker",
                datalist: kjoretoyMerkerMerke,
              }}
              name="kjoretoyMerke"
              onChange={handleChange}
              value={kjoretoyMerke}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <FormButtonsForCloseAndSubmit
              submitText="Søk"
              onClose={handleChangeToInitState}
              showSubmitSpinner={showSpinnerState}
            />
          </div>
        </form>
      </div>
    </section>
  );
};

export default UregistrerteKontrollerFilter;
