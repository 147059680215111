import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ModalGeneric from "../../Common/ModalGeneric";
import AktorerForm from "../AktorerForm";
import OpenModalComponent from "./OpenModalComponent";

import { postAktor, getAktorer } from "../../../actions/aktorerActions";
import {
  IsValidated,
  onChangeValidation,
} from "../../../utils/validationUtils";

class AktorerModal extends React.Component {
  static propTypes = {
    kjoretoyMerker: PropTypes.arrayOf(PropTypes.object),
    postAktor: PropTypes.func,
    getAktorer: PropTypes.func,
    modalToRender: PropTypes.string,
  };
  get initState() {
    return {
      open: false,
      submitButtonText: "Legg til ny",
      orgNummer: "",
      kjoretoyMerkeId: "",
      aktivertDato: null,
      deaktivertDato: null,
      adresse1: "",
      adresse2: "",
      poststed: "",
      postnummer: "",
      validationError: {},
    };
  }
  rules = {
    kjoretoyMerkeId:
      "required;hasFormat=[A-Fa-f0-9]{8}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{12},XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX",
    orgNummer: "required;isNumber;length=9",
    adresse1: "required;minLength=1;maxLength=40",
    adresse2: "minLength=0;maxLength=40",
    poststed: "required;minLength=1;maxLength=40",
    postnummer: "required;minLength=1;maxLength=4",
    aktivertDato: "isDato;isLessThan=deaktivertDato",
    deaktivertDato: "isDato;isLargerThan=aktivertDato",
  };
  state = this.initState;

  closeModal = () => {
    this.setState(this.initState);
  };

  openModal = () => {
    this.setState({ open: true });
  };

  getKjoretoyMerke = (kjoretoyMerkeId) => {
    const filteredKjoretoyMerker = this.props.kjoretoyMerker.filter(
      (km) => km.id === kjoretoyMerkeId
    );
    if (filteredKjoretoyMerker.length === 1) {
      return filteredKjoretoyMerker[0].navn;
    }
    return null;
  };

  handlePost = async () => {
    this.setState({ submitButtonText: "Legger til..." });

    const payload = {
      navn: this.getKjoretoyMerke(this.state.kjoretoyMerkeId),
      orgNummer: parseInt(this.state.orgNummer), //SAK-80: orgNummer stored as int in backend, but is kept as string due to validation
      kjoretoyMerkeId: this.state.kjoretoyMerkeId,
      aktivertDato: this.state.aktivertDato,
      deaktivertDato:
        this.state.deaktivertDato && this.state.deaktivertDato.endOf("day"),
      adresse1: this.state.adresse1,
      adresse2: this.state.adresse2,
      poststed: this.state.poststed,
      postnummer: this.state.postnummer,
    };
    this.props
      .postAktor(payload)
      .then(() => {
        this.closeModal();
      })
      .catch(() => this.setState({ submitButtonText: "Legg til ny" }));
  };

  isValid = () => {
    const { isValid, validationError } = IsValidated(this.state, this.rules);
    this.setState({ validationError });
    return isValid;
  };

  saveAndClose = (e) => {
    if (this.isValid()) {
      this.handlePost();
    }
  };

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
    onChangeValidation(e, this.state, this.rules);
  };

  render() {
    const { submitButtonText } = this.state;

    const modalTilte = "Legg til ny aktør";

    return (
      <>
        <ModalGeneric
          modalTitle={modalTilte}
          open={this.state.open}
          closeModal={this.closeModal}
          openModal={this.openModal}
          OpenModalComponent={OpenModalComponent}
          submit={this.saveAndClose}
          submitButtonText={submitButtonText}
        >
          <AktorerForm
            kjoretoyMerkeId={this.state.kjoretoyMerkeId}
            kjoretoyMerker={this.props.kjoretoyMerker}
            orgNummer={this.state.orgNummer}
            adresse1={this.state.adresse1}
            adresse2={this.state.adresse2}
            poststed={this.state.poststed}
            postnummer={this.state.postnummer}
            aktivertDato={this.state.aktivertDato}
            deaktivertDato={this.state.deaktivertDato}
            validationError={this.state.validationError}
            handleChange={this.handleChange}
          />
        </ModalGeneric>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  kjoretoyMerker:
    state.kjoretoyMerker &&
    state.kjoretoyMerker.map((km) => ({ id: km.id, navn: km.merke })), // Fetched in AktorerList
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(
    {
      postAktor,
      getAktorer,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AktorerModal);
