import { connect } from "react-redux";
import moment from "moment-timezone";

import AArsrapportFilterForm from "./AArsrapportFilterForm";

import { getIleggelsestyper as getIleggelsestyperAction } from "../../../actions/ileggelsestyperActions";
import {
  handleChange as handleChangeAction,
  handleChangeAll as handleChangeAllAction,
  handleChangeToInitState as handleChangeToInitStateAction,
} from "../../../actions/handleChangeAction";
import { getAArsrapport } from "../../../actions/rapporter/aarsrapportActions";

import {
  AArIsRequired,
  IleggelsestypeIsRequired,
} from "../../../constants/ErrorMessages";

const getAArValidationErrorText = (aar) => {
  if (aar) {
    return null;
  }
  return AArIsRequired;
};

const getIleggelsestyperValidationErrorText = (ileggelsestyper) => {
  if (ileggelsestyper && ileggelsestyper.length > 0) {
    return null;
  }
  return IleggelsestypeIsRequired;
};

const mapStateToProps = (state) => ({
  ileggelsestyper: state.ileggelsestyper,
  aarsrapportFilterForm: state.forms.aarsrapportFilterForm,
});

const mapDispatchToProps = (dispatch) => ({
  getIleggelsestyper: () => dispatch(getIleggelsestyperAction()),
  handleChange: (variableName, value) =>
    dispatch(
      handleChangeAction("RAPPORT_AARSRAPPORT_FILTER_FORM", variableName, value)
    ),
  handleChangeAll: (actionType, payload) => {
    dispatch(handleChangeAllAction(actionType, payload));
  },
  handleChangeToInitState: () =>
    dispatch(handleChangeToInitStateAction("RAPPORT_AARSRAPPORT_FILTER_FORM")),
  handleSubmit: (params) => dispatch(getAArsrapport(params)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  handleChangeIleggelsestype: (event) =>
    dispatchProps.handleChange(event.target.name, event.target.value),
  handleChangeAAr: (event) => {
    dispatchProps.handleChange(event.target.name, event.target.value);
  },
  handleSubmit: (event, aar, ileggelsestyper) => {
    event.preventDefault();

    const aarValidationErrorText = getAArValidationErrorText(aar);
    const ileggelsestyperValidationErrorText =
      getIleggelsestyperValidationErrorText(ileggelsestyper);
    if (aarValidationErrorText) {
      dispatchProps.handleChange(
        "aarValidationErrorText",
        aarValidationErrorText
      );
    }
    if (ileggelsestyperValidationErrorText) {
      dispatchProps.handleChange(
        "ileggelsestyperValidationErrorText",
        ileggelsestyperValidationErrorText
      );
    }
    if (aarValidationErrorText || ileggelsestyperValidationErrorText) {
      return Promise.reject();
    }

    dispatchProps.handleChange("aarValidationErrorText", "");
    dispatchProps.handleChange("ileggelsestyperValidationErrorText", "");

    const fraDato = moment().tz("Europe/Oslo").set("year", aar).startOf("year");
    const tilDato = moment().tz("Europe/Oslo").set("year", aar).endOf("year");
    const ileggelsestypeIds = ileggelsestyper.map(
      (ileggelsestype) => ileggelsestype.id
    );
    const ileggelsestypeNavn = ileggelsestyper.map(
      (ileggelsestype) => ileggelsestype.navn
    );
    dispatchProps.handleChangeAll("RAPPORT_AARSRAPPORT_SUBMITTED_FORM", {
      aar: stateProps.aarsrapportFilterForm.aar,
      ileggelsestypeNavn: ileggelsestypeNavn,
      submitTimestamp: moment(),
    });
    return dispatchProps.handleSubmit({
      fraDato,
      tilDato,
      ileggelsestypeIds,
    });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(AArsrapportFilterForm);
