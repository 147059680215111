import React, { Component } from "react";
import PropTypes from "prop-types";

import Table from "../../Common/SortableTableContainer";
import Checkbox from "../../Common/Forms/Checkbox";
import MakulerModalContainer from "../../Common/MakulerModalContainer";
import { Link } from "react-router-dom";
import OpenMakulerModal from "../../Common/IleggelseClient/OpenMakulerModal";
import { text } from "../../../constants/text";
import { HeaderCell } from "../../Common/Tables";

import { toNorwegianDatetime } from "../../../utils/datetimeUtils";
import { ileggelseClientSpecificUtils } from "../../../utils/ileggelseClientSpecificUtils";
import sitePaths from "../../../constants/sitePaths";

import { ReactComponent as IconImage } from "../../../images/icon-image.svg";

class List extends Component {
  static propTypes = {
    ettersendelser: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        ileggelsesnummer: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        ileggelsesdato: PropTypes.string,
        klokkeslettTil: PropTypes.string,
        tjenestenummer: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        internkode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        kjennemerke: PropTypes.string,
        overtredelse: PropTypes.string,
        nasjon: PropTypes.string,
        gatenavn: PropTypes.string,
        gatenummer: PropTypes.string,
        type: PropTypes.string,
        status: PropTypes.string,
        bilder: PropTypes.array,
      })
    ),
    checkedEttersendelser: PropTypes.object,
    handleCheckboxChange: PropTypes.func,
    postIleggelserMakulering: PropTypes.func,
  };

  renderRow(ettersendelse) {
    if (ettersendelse) {
      const bilde = ettersendelse.antallBilder > 1 ? <IconImage /> : <span />;
      const ileggelsenummer = ettersendelse.ileggelsesnummer.toString();
      return (
        <React.Fragment key={`${ettersendelse.id}`}>
          <tr>
            <td>
              {ettersendelse.ettersendelse.id !== "00000000-0000-0000-0000-000000000000" && (
                <Checkbox
                  id={`checkbox${
                    (ettersendelse.ettersendelse &&
                      ettersendelse.ettersendelse.id) ||
                    ettersendelse.id
                  }Ettersendelser`}
                  name={
                    (ettersendelse.ettersendelse &&
                      ettersendelse.ettersendelse.id) ||
                    ettersendelse.id
                  }
                  checkedValue={true}
                  uncheckedValue={false}
                  currentValue={
                    this.props.checkedEttersendelser[
                      (ettersendelse.ettersendelse &&
                        ettersendelse.ettersendelse.id) ||
                        ettersendelse.id
                    ]
                  }
                  handleChange={this.props.handleCheckboxChange}
                />
              )}
            </td>
            <td>
              <Link
                to={`${sitePaths.ileggelser.ettersendelser.shortUrl}/${ettersendelse.id}`}
                className="underline"
              >
                {ileggelsenummer}
              </Link>
            </td>
            <td>
              {toNorwegianDatetime(ettersendelse.ileggelsesdatoTil).format(
                "DD.MM.YYYY"
              )}
            </td>
            <td>
              {toNorwegianDatetime(ettersendelse.ileggelsesdatoFra).format(
                "HH:mm"
              )}{" "}
              -{" "}
              {toNorwegianDatetime(ettersendelse.ileggelsesdatoTil).format(
                "HH:mm"
              )}
            </td>
            <td>{ettersendelse.tjenestenummer}</td>
            <td>
              {ileggelseClientSpecificUtils.getOvertredelserString(
                ettersendelse.overtredelse1,
                ettersendelse.overtredelse2,
                ettersendelse.overtredelse3
              )}
            </td>
            <td>{ettersendelse.kjennemerke}</td>
            <td>{ettersendelse.nasjon}</td>
            <td>{ettersendelse.gatenavn}</td>
            <td>{ettersendelse.gatenummer}</td>
            <td>{bilde}</td>
            <td>
              <MakulerModalContainer
                id={ettersendelse.id}
                ileggelsesnummer={ettersendelse.ileggelsesnummer}
                titleType="ileggelse"
                openMakulerModal={OpenMakulerModal}
                postMakulering={this.props.postIleggelserMakulering}
              />
            </td>
          </tr>
          <tr>
            <td />
            <td
              colSpan="7"
              style={{
                maxWidth: 700,
                wordWrap: "break-word",
                overflowWrap: "break-word",
              }}
            >
              <strong>
                {text.ettersendelseOversikt.ettersendelserRapport}:{" "}
              </strong>
              {ettersendelse.ettersendelse
                ? ettersendelse.ettersendelse.kommentar
                : ""}
            </td>
            <td
              colSpan="5"
              style={{
                maxWidth: 500,
                wordWrap: "break-word",
                overflowWrap: "break-word",
              }}
            >
              <strong>{text.ettersendelseOversikt.tilbakemelding}: </strong>
              {ettersendelse.rettelser &&
                ettersendelse.rettelser.map((rettelse, index) => {
                  if (index === 0 && ettersendelse.rettelser.length === 1) {
                    return rettelse.kommentar;
                  }
                  return (
                    <React.Fragment
                      key={`ettersendelse.rettelse.kommentar${index}`}
                    >
                      <br />
                      {`${++index}. ${rettelse.kommentar}`}
                    </React.Fragment>
                  );
                })}
            </td>
          </tr>
        </React.Fragment>
      );
    }
  }

  render() {
    const { ettersendelser } = this.props;
    return (
      <Table
        id="ettersendelse-liste"
        className="bym-ettersendelser-table"
        storeKey="ettersendelser"
        items={ettersendelser}
        renderRow={this.renderRow.bind(this)}
      >
        <HeaderCell>
          <Checkbox
            id="checkboxAllEttersendelser"
            name="checkboxAllEttersendelser"
            checkedValue={true}
            uncheckedValue={false}
            currentValue={
              this.props.checkedEttersendelser.checkboxAllEttersendelser
            }
            handleChange={this.props.handleCheckboxChange}
          />
        </HeaderCell>
        <HeaderCell>{text.ettersendelseOversikt.ileggelseNr}</HeaderCell>
        <HeaderCell>{text.ettersendelseOversikt.ileggelsedato}</HeaderCell>
        <HeaderCell>{text.ettersendelseOversikt.klokkeslett}</HeaderCell>
        <HeaderCell>{text.ettersendelseOversikt.tjenesteNr}</HeaderCell>
        {/* <HeaderCell>{text.ettersendelseOversikt.team}</HeaderCell> */}
        <HeaderCell>{text.ettersendelseOversikt.overtredelsekode}</HeaderCell>
        <HeaderCell>{text.ettersendelseOversikt.kjennemerke}</HeaderCell>
        <HeaderCell>{text.ettersendelseOversikt.nasjon}</HeaderCell>
        <HeaderCell>{text.ettersendelseOversikt.gatenavn}</HeaderCell>
        <HeaderCell>{text.ettersendelseOversikt.gatenr}</HeaderCell>
        <HeaderCell>{text.ettersendelseOversikt.bilde}</HeaderCell>
        <HeaderCell />
      </Table>
    );
  }
}

export default List;
