import { Redirect, useHistory } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { Button } from "react-bootstrap";

export const RedirectPage = () => {
  const history = useHistory();
  const auth = useAuth();

  const userState = auth.user?.state;

  const handleLoggUt = async (e) => {
    await auth.removeUser();
    history.push("/login");
  };

  if (auth.error) {
    return (
      <div style={{ padding: 50 }}>
        <div style={{ marginBottom: 20 }}>
          {auth.error.name + ": " + auth.error.message}
        </div>
        <div style={{ marginTop: 20 }}>
          <Button onClick={handleLoggUt}>Gå til logg inn</Button>
        </div>
      </div>
    );
  }

  if (auth.isLoading) {
    return <div>Logger inn...</div>;
  }

  if (auth.isAuthenticated) {
    return (
      <Redirect
        to={
          userState
            ? userState.from.pathname +
              userState.from.search +
              userState.from.hash
            : "/"
        }
      />
    );
  }

  if (!auth.isAuthenticated && !auth.isLoading) {
    return <Redirect to="/login" />;
  }

  return <></>;
};

export default RedirectPage;
