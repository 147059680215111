import * as miljokontrollerActions from "../actions/miljokontrollerActions";

const INITIAL_STATE = [];

export default function miljoKontrollerReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case miljokontrollerActions.GET_MILJOKONTROLLER_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
