import React from "react";
import PropTypes from "prop-types";

import { Button } from "react-bootstrap";

import ModalGenericConfirmCancelContainer from "../../../Common/ModalGenericConfirmCancelContainer";

// Miljøkontroll har bytt navn til tidskontroll
const OpphevMakuleringMiljokontroll = ({
  miljokontroll,
  putMiljokontrollAvslutt,
}) => {
  return (
    <ModalGenericConfirmCancelContainer
      id={"makuler-miljokontroll-modal-generic-confirm-cancel-container"}
      className="makuler-miljokontroll"
      modalTitle={`Opphev makulering av tidskontroll ${miljokontroll.nummer}`}
      submitButtonText="Opphev Makulering"
      submittingButtonText="Opphever Makulerering..."
      handleConfirmClick={() => putMiljokontrollAvslutt()}
      // handleCancelAndCloseClick={}
      openModalComponentFunction={(endreModalProps) => (
        <Button
          className="btn btn-bym-standard"
          style={{ marginLeft: 20 }}
          onClick={endreModalProps.openModal}
        >
          Opphev Makulering
        </Button>
      )}
    >
      Vil du oppheve makulering av kontroll {miljokontroll.nummer}?
    </ModalGenericConfirmCancelContainer>
  );
};

OpphevMakuleringMiljokontroll.propTypes = {
  miljokontroll: PropTypes.object.isRequired,
  putMiljokontrollAvslutt: PropTypes.func.isRequired,
};

export default OpphevMakuleringMiljokontroll;
