import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import map from "lodash/map";

import Table from "../../Common/Tables/BaseTable";
import KjoretoyMerkerModal from "./UpdateModals/KjoretoyMerkerModal";
import { getKjoretoyMerker } from "../../../actions/kjoretoyMerkerActions";
import * as title from "../../../constants/titles";

class KjoretoyMerkerList extends Component {
  static propTypes = {
    authorizedToCreateAndEdit: PropTypes.bool.isRequired,
    kjoretoyMerker: PropTypes.arrayOf(
      PropTypes.shape({
        kode: PropTypes.string,
        merke: PropTypes.string,
      })
    ),
  };

  constructor(props) {
    super(props);
    this.renderRow = this.renderRow.bind(this);
  }
  componentDidMount() {
    document.title = title.grunndataKjoretoyMerker;
    this.props.getKjoretoyMerker();
  }

  renderRow() {
    return map(this.props.kjoretoyMerker, (kjoretoyMerke) => (
      <tr key={`kjoretoyMerkerTableBodyTr${kjoretoyMerke.id.toString()}`}>
        <td>{kjoretoyMerke.kode}</td>
        <td>{kjoretoyMerke.merke}</td>
        <td>
          {this.props.authorizedToCreateAndEdit && (
            <KjoretoyMerkerModal kjoretoyMerke={kjoretoyMerke} />
          )}
        </td>
      </tr>
    ));
  }

  render() {
    const headerRow = ["Kode", "Merke", ""];
    return (
      <div className="table-wrapper-horizontal-scroll">
        <Table
          id="kjoretoyMerke-liste"
          headerRow={headerRow}
          defaultStyle
          striped
        >
          <tbody>{this.renderRow()}</tbody>
        </Table>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    kjoretoyMerker: state.kjoretoyMerker,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getKjoretoyMerker }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(KjoretoyMerkerList);
