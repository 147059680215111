import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";

export default function TextareaModalContent(props) {
  return (
    <form>
      <p>{props.paragraphText}</p>
      <Form.Group>
        <Form.Label>{props.textareaLabel}</Form.Label>
        <Form.Control
          name="textarea"
          as="textarea"
          rows={3}
          placeholder={props.textareaPlaceholder}
          onChange={props.handleChange}
          isInvalid={!!props.kommentarValidationError}
        ></Form.Control>
        {props.kommentarValidationError && (
          <span className="validation-error">{props.validationErrorText}</span>
        )}
      </Form.Group>
    </form>
  );
}

TextareaModalContent.propTypes = {
  paragrapghText: PropTypes.string,
  kommentarValidationError: PropTypes.bool,
  textareaLabel: PropTypes.string,
  textareaPlaceholder: PropTypes.string,
  validationErrorText: PropTypes.string,

  handleChange: PropTypes.func.isRequired,
};
