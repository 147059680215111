import React from "react";
import { Switch, Redirect } from "react-router-dom";

import AuthorizedRoutes from "../components/Routes/AuthorizedRoutes";

import InnkrevingPage from "../components/Innkreving/InnkrevingPage";
import IleggelseDetaljPage from "../components/Ileggelse/Detalj/IleggelseDetaljPage";
import MiljogebyrDetaljPage from "../components/Miljogebyr/Detalj/MiljogebyrDetaljPage";
import SvarUtBrevHistorikk from "../components/Common/Detalj/SvarUtBrevhistorikk";

import sitePaths from "../constants/sitePaths";
import { bypatruljen } from "../constants/roles";

const Innkreving = () => (
  <Switch>
    <AuthorizedRoutes
      exact
      path={sitePaths.innkreving.ileggelser.venter.detalj.shortUrl.routeMatcher}
      roles={[bypatruljen.saksbehandler, bypatruljen.saksbehandleradmin]}
      component={IleggelseDetaljPage}
    />
    <AuthorizedRoutes
      exact
      path={sitePaths.innkreving.ileggelser.venter.detalj.svarUtHistorikk}
      roles={[bypatruljen.saksbehandler, bypatruljen.saksbehandleradmin]}
      component={SvarUtBrevHistorikk}
    />
    <AuthorizedRoutes
      exact
      path={
        sitePaths.innkreving.ileggelser.stoppet.detalj.shortUrl.routeMatcher
      }
      roles={[bypatruljen.saksbehandler, bypatruljen.saksbehandleradmin]}
      component={IleggelseDetaljPage}
    />
    <AuthorizedRoutes
      exact
      path={sitePaths.innkreving.ileggelser.stoppet.detalj.svarUtHistorikk}
      roles={[bypatruljen.saksbehandler, bypatruljen.saksbehandleradmin]}
      component={SvarUtBrevHistorikk}
    />
    <AuthorizedRoutes
      exact
      path={sitePaths.innkreving.ileggelser.klar.detalj.shortUrl.routeMatcher}
      roles={[bypatruljen.saksbehandler, bypatruljen.saksbehandleradmin]}
      component={IleggelseDetaljPage}
    />
    <AuthorizedRoutes
      exact
      path={sitePaths.innkreving.ileggelser.klar.detalj.svarUtHistorikk}
      roles={[bypatruljen.saksbehandler, bypatruljen.saksbehandleradmin]}
      component={SvarUtBrevHistorikk}
    />
    <AuthorizedRoutes
      exact
      path={
        sitePaths.innkreving.ileggelser.behandlesInnkreving.detalj.shortUrl
          .routeMatcher
      }
      roles={[bypatruljen.saksbehandler, bypatruljen.saksbehandleradmin]}
      component={IleggelseDetaljPage}
    />
    <AuthorizedRoutes
      exact
      path={
        sitePaths.innkreving.ileggelser.behandlesInnkreving.detalj
          .svarUtHistorikk
      }
      roles={[bypatruljen.saksbehandler, bypatruljen.saksbehandleradmin]}
      component={SvarUtBrevHistorikk}
    />
    <AuthorizedRoutes
      exact
      path={sitePaths.innkreving.ileggelser.feilet.detalj.shortUrl.routeMatcher}
      roles={[bypatruljen.saksbehandler, bypatruljen.saksbehandleradmin]}
      component={IleggelseDetaljPage}
    />
    <AuthorizedRoutes
      exact
      path={sitePaths.innkreving.ileggelser.feilet.detalj.svarUtHistorikk}
      roles={[bypatruljen.saksbehandler, bypatruljen.saksbehandleradmin]}
      component={SvarUtBrevHistorikk}
    />
    <AuthorizedRoutes
      exact
      path={
        sitePaths.innkreving.ileggelser.vellykket.detalj.shortUrl.routeMatcher
      }
      roles={[bypatruljen.saksbehandler, bypatruljen.saksbehandleradmin]}
      component={IleggelseDetaljPage}
    />
    <AuthorizedRoutes
      exact
      path={sitePaths.innkreving.ileggelser.vellykket.detalj.svarUtHistorikk}
      roles={[bypatruljen.saksbehandler, bypatruljen.saksbehandleradmin]}
      component={SvarUtBrevHistorikk}
    />
    <AuthorizedRoutes
      exact
      path={sitePaths.innkreving.miljogebyr.venter.detalj.shortUrl.routeMatcher}
      roles={[bypatruljen.saksbehandler, bypatruljen.saksbehandleradmin]}
      component={MiljogebyrDetaljPage}
    />
    <AuthorizedRoutes
      exact
      path={sitePaths.innkreving.miljogebyr.venter.detalj.svarUtHistorikk}
      roles={[bypatruljen.saksbehandler, bypatruljen.saksbehandleradmin]}
      component={SvarUtBrevHistorikk}
    />
    <AuthorizedRoutes
      exact
      path={
        sitePaths.innkreving.miljogebyr.stoppet.detalj.shortUrl.routeMatcher
      }
      roles={[bypatruljen.saksbehandler, bypatruljen.saksbehandleradmin]}
      component={MiljogebyrDetaljPage}
    />
    <AuthorizedRoutes
      exact
      path={sitePaths.innkreving.miljogebyr.stoppet.detalj.svarUtHistorikk}
      roles={[bypatruljen.saksbehandler, bypatruljen.saksbehandleradmin]}
      component={SvarUtBrevHistorikk}
    />
    <AuthorizedRoutes
      exact
      path={sitePaths.innkreving.miljogebyr.klar.detalj.shortUrl.routeMatcher}
      roles={[bypatruljen.saksbehandler, bypatruljen.saksbehandleradmin]}
      component={MiljogebyrDetaljPage}
    />
    <AuthorizedRoutes
      exact
      path={sitePaths.innkreving.miljogebyr.klar.detalj.svarUtHistorikk}
      roles={[bypatruljen.saksbehandler, bypatruljen.saksbehandleradmin]}
      component={SvarUtBrevHistorikk}
    />
    <AuthorizedRoutes
      exact
      path={
        sitePaths.innkreving.miljogebyr.behandlesInnkreving.detalj.shortUrl
          .routeMatcher
      }
      roles={[bypatruljen.saksbehandler, bypatruljen.saksbehandleradmin]}
      component={MiljogebyrDetaljPage}
    />
    <AuthorizedRoutes
      exact
      path={
        sitePaths.innkreving.miljogebyr.behandlesInnkreving.detalj
          .svarUtHistorikk
      }
      roles={[bypatruljen.saksbehandler, bypatruljen.saksbehandleradmin]}
      component={SvarUtBrevHistorikk}
    />
    <AuthorizedRoutes
      exact
      path={sitePaths.innkreving.miljogebyr.feilet.detalj.shortUrl.routeMatcher}
      roles={[bypatruljen.saksbehandler, bypatruljen.saksbehandleradmin]}
      component={MiljogebyrDetaljPage}
    />
    <AuthorizedRoutes
      exact
      path={sitePaths.innkreving.miljogebyr.feilet.detalj.svarUtHistorikk}
      roles={[bypatruljen.saksbehandler, bypatruljen.saksbehandleradmin]}
      component={SvarUtBrevHistorikk}
    />
    <AuthorizedRoutes
      exact
      path={
        sitePaths.innkreving.miljogebyr.vellykket.detalj.shortUrl.routeMatcher
      }
      roles={[bypatruljen.saksbehandler, bypatruljen.saksbehandleradmin]}
      component={MiljogebyrDetaljPage}
    />
    <AuthorizedRoutes
      exact
      path={sitePaths.innkreving.miljogebyr.vellykket.detalj.svarUtHistorikk}
      roles={[bypatruljen.saksbehandler, bypatruljen.saksbehandleradmin]}
      component={SvarUtBrevHistorikk}
    />

    <AuthorizedRoutes
      exact
      path={sitePaths.innkreving.page}
      roles={[bypatruljen.saksbehandler, bypatruljen.saksbehandleradmin]}
      component={InnkrevingPage}
    />
    <Redirect
      path={sitePaths.innkreving.ileggelser.shortUrl}
      to={sitePaths.innkreving.ileggelser.default}
    />
    <Redirect
      path={sitePaths.innkreving.miljogebyr.shortUrl}
      to={sitePaths.innkreving.miljogebyr.default}
    />
    <Redirect
      path={sitePaths.innkreving.shortUrl}
      to={sitePaths.innkreving.default}
    />
  </Switch>
);

export default Innkreving;
