import React, { Component } from "react";
import { Table as BSTable } from "react-bootstrap";
import PropTypes from "prop-types";

import "./Table.css";

class TableCustomStyled extends Component {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    cols: PropTypes.func,
    items: PropTypes.array,
    renderRow: PropTypes.func.isRequired,
    sort: PropTypes.shape({
      cellKey: PropTypes.string.isRequired,
      orders: PropTypes.string,
    }),
    onSort: PropTypes.func,
    children: PropTypes.arrayOf(PropTypes.element),
  };

  render() {
    const {
      id,
      className,
      style,
      cols,
      items,
      renderRow,
      children,
      sort,
      onSort,
    } = this.props;

    let headers = children;

    if (sort || onSort) {
      headers = React.Children.map(children, (child) =>
        React.cloneElement(child, { sort, onSort })
      );
    }
    return (
      <BSTable className={className} style={{ style }}>
        {cols && <colgroup>{cols()}</colgroup>}
        <thead>
          <tr>{headers}</tr>
        </thead>
        <tbody>
          {items &&
            items.map((item, index) => {
              return (
                <React.Fragment key={`${id}${index}`}>
                  {renderRow(item)}
                </React.Fragment>
              );
            })}
        </tbody>
      </BSTable>
    );
  }
}

export default TableCustomStyled;
