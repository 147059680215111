import { ILEGGELSE_SERVICE_BASE_URL } from "../config";

// - Overtredelseskoder ileggelser
export const GET_OVERTREDELSESKODER_ILEGGELSER_URL = `${ILEGGELSE_SERVICE_BASE_URL}/ileggelseovertredelser`;
export const POST_OVERTREDELSESKODER_ILEGGELSER_URL = `${ILEGGELSE_SERVICE_BASE_URL}/ileggelseovertredelser`;
export const PUT_OVERTREDELSESKODER_ILEGGELSER_URL = (id) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/ileggelseovertredelser/${id}`;
export const GET_SINGLE_OVERTREDELSESKODER_ILEGGELSER_URL = (id) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/ileggelseovertredelser/${id}`;

// - Overtredelseskoder miljøgebyr
export const GET_OVERTREDELSESKODER_MILJOGEBYR_URL = `${ILEGGELSE_SERVICE_BASE_URL}/miljoovertredelser`;
export const POST_OVERTREDELSESKODER_MILJOGEBYR_URL = `${ILEGGELSE_SERVICE_BASE_URL}/miljoovertredelser`;
export const PUT_OVERTREDELSESKODER_MILJOGEBYR_URL = (id) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/miljoovertredelser/${id}`;
export const GET_SINGLE_OVERTREDELSESKODER_MILJOGEBYR_URL = (id) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/miljoovertredelser/${id}`;

// - Overtredelsegrupper
export const GET_OVERTREDELSEGRUPPER_URL = `${ILEGGELSE_SERVICE_BASE_URL}/overtredelsegrupper`;
export const POST_OVERTREDELSESKODER_ILEGGELSER_GRUPPER_URL = `${ILEGGELSE_SERVICE_BASE_URL}/ileggelseovertredelseGrupper`;
export const PUT_OVERTREDELSESKODER_ILEGGELSER_GRUPPER_URL = (id) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/ileggelseovertredelseGrupper/${id}`;
