const innsynSitePaths = {
  shortUrl: "/innsyn",
  ileggelser: {
    shortUrl: "/innsyn/ileggelser",
    oversikt: "/innsyn/ileggelser/oversikt",
    detalj: {
      shortUrl:
        "/innsyn/ileggelser/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
      bilde:
        "/innsyn/ileggelser/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/bilder/:bildeId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
    },
  },
  miljogebyrer: {
    shortUrl: "/innsyn/miljogebyrer",
    oversikt: "/innsyn/miljogebyrer/oversikt",
    detalj: {
      shortUrl:
        "/innsyn/miljogebyrer/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
      bilde:
        "/innsyn/miljogebyrer/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/bilder/:bildeId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
      miljokontroll: {
        bilde:
          "/innsyn/miljogebyrer/:miljogebyrId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/tidskontroller/:miljokontrollId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/observasjoner/:observasjonId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/bilder/:bildeId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
      },
    },
  },
  inntauing: "/innsyn/inntauing",
  motorvogn: {
    shortUrl: "/innsyn/motorvogn",
    utskrift: "/innsyn/motorvogn/utskrift",
  },
};

export default innsynSitePaths;
