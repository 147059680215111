import * as ettersendelserTilGodkjenningActions from "../actions/ettersendelserTilGodkjenningActions";

const INITIAL_STATE = [];

export default function ettersendelserTilGodkjenningReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case ettersendelserTilGodkjenningActions.GET_ETTERSENDELSER_TIL_GODKJENNING_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
