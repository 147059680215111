import React from "react";
import { Switch } from "react-router-dom";

import AuthorizedRoutes from "../components/Routes/AuthorizedRoutes";

import KlagePage from "../components/Klage/KlagePage";
import IleggelseDetaljPage from "../components/Ileggelse/Detalj/IleggelseDetaljPage";
import IleggelseImageEditorPageContainer from "../components/Common/Ileggelser/Detalj/ImageEditorPageContainer";
import MiljogebyrDetaljPage from "../components/Miljogebyr/Detalj/MiljogebyrDetaljPage";
import MiljokontrollDetaljPage from "../components/Miljogebyr/Miljokontroll/Detalj/MiljokontrollDetaljPage";
import MiljogebyrImageEditorPageContainer from "../components/Common/Miljogebyr/Detalj/ImageEditorPageContainer";
import MiljogebyrOrginalbilde from "../components/Common/Miljogebyr/Detalj/MiljogebyrOrginalbilde";
import TidskontrollOrginalbilde from "../components/Common/Miljogebyr/Detalj/TidskontrollOrginalbilde";
import IleggelseOrginalbilde from "../components/Common/Ileggelser/Detalj/IleggelseOrginalbilde";

import sitePath from "../constants/sitePaths";
import { bypatruljen, juridisk } from "../constants/roles";

const Klage = () => (
  <Switch>
    <AuthorizedRoutes
      exact
      path={sitePath.klage.default}
      roles={[bypatruljen.saksbehandleradmin, ...Object.values(juridisk)]}
      component={KlagePage}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.klage.ileggelser.detalj.shortUrl}
      roles={[...Object.values(bypatruljen), ...Object.values(juridisk)]}
      component={IleggelseDetaljPage}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.klage.ileggelser.detalj.toastUi}
      roles={[
        bypatruljen.saksbehandleradmin,
        bypatruljen.saksbehandler,
        juridisk.saksbehandler,
      ]}
      component={IleggelseImageEditorPageContainer}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.klage.ileggelser.detalj.bilde}
      roles={[...Object.values(bypatruljen), ...Object.values(juridisk)]}
      component={IleggelseOrginalbilde}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.klage.miljogebyrer.detalj.shortUrl.routeMatcher}
      roles={[...Object.values(bypatruljen), ...Object.values(juridisk)]}
      component={MiljogebyrDetaljPage}
    />
    <AuthorizedRoutes
      exact
      path={
        sitePath.klage.miljogebyrer.detalj.miljokontroll.shortUrl.routeMatcher
      }
      roles={[
        bypatruljen.saksbehandleradmin,
        bypatruljen.saksbehandler,
        juridisk.saksbehandler,
      ]}
      component={MiljokontrollDetaljPage}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.klage.miljogebyrer.detalj.toastUi}
      roles={[
        bypatruljen.saksbehandleradmin,
        bypatruljen.saksbehandler,
        juridisk.saksbehandler,
      ]}
      component={MiljogebyrImageEditorPageContainer}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.klage.miljogebyrer.detalj.miljokontroll.toastUi}
      roles={[
        bypatruljen.saksbehandleradmin,
        bypatruljen.saksbehandler,
        juridisk.saksbehandler,
      ]}
      component={MiljogebyrImageEditorPageContainer}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.klage.miljogebyrer.detalj.bilde}
      roles={[...Object.values(bypatruljen), ...Object.values(juridisk)]}
      component={MiljogebyrOrginalbilde}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.klage.miljogebyrer.detalj.miljokontroll.bilde}
      roles={[...Object.values(bypatruljen), ...Object.values(juridisk)]}
      component={TidskontrollOrginalbilde}
    />
  </Switch>
);

export default Klage;
