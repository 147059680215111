import React, { Component } from "react";
import LinkerView from "./LinkerView";
import { Col, Container, Row } from "react-bootstrap";
import * as Linker from "../../constants/Links";
import * as title from "../../constants/titles";

export default class LinkerPage extends Component {
  componentDidMount() {
    document.title = title.linker;
  }
  render() {
    return (
      <Container fluid className="main-container">
        <Row>
          <Col>
            <LinkerView Linker={Linker} header="" />
          </Col>
        </Row>
      </Container>
    );
  }
}
