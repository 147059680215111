// import moment from "moment";
// eslint-disable-next-line
import {
  handleChangeActionType,
  handleChangeToInitStateActionType
} from "../../actions/handleChangeAction";
const INNSYN_MILJOGEBYRER_FILTER_FORM = "INNSYN_MILJOGEBYRER_FILTER_FORM";

export const INITIAL_STATE = {
  ileggelsesnummer: "",
  fraDato: "",
  fraTid: "",
  tilDato: "",
  tilTid: "",
  gatenavn: "",
  gatenummer: "",
  overtredelseskode: "",

  validationError: {}
};

export default function InnsynMiljogebyrerFilterFormReducer(
  state = {
    ...INITIAL_STATE,
    validationError: { ...INITIAL_STATE.validationError }
  },
  action
) {
  switch (action.type) {
    case handleChangeActionType(INNSYN_MILJOGEBYRER_FILTER_FORM):
      return { ...state, [action.variableName]: action.payload };
    case handleChangeToInitStateActionType(INNSYN_MILJOGEBYRER_FILTER_FORM):
      return {
        ...INITIAL_STATE,
        validationError: { ...INITIAL_STATE.validationError }
      };
    default:
      return state;
  }
}
