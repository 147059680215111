import React, { useState, useEffect } from "react";

import { HeaderCell } from "../../Common/Tables/index";
import Table from "../../Common/Tables/Table";
import InformationButton from "../../Common/InformationButton";

import { formatDateTimeHavingNorwegianDateTime } from "../../../utils/datetimeUtils";

const renderRow = (makulering) => {
  return (
    <tr>
      <td>
        {makulering.ileggelsesdatoTil
          ? formatDateTimeHavingNorwegianDateTime(makulering.ileggelsesdatoTil)
          : ""}
      </td>
      <td>{makulering.ileggelsesnummer}</td>
      <td>{makulering.tjenestenummer}</td>
      <td>
        {makulering.ileggelsestypeNavn} ({makulering.ileggelsestypeKode})
      </td>
      <td style={{ maxWidth: 300 }}>{makulering.kommentar}</td>
      <td>{makulering.makulertAvBetjentTjenestenummerOrOsloFellseIdent}</td>
      <td>
        {makulering.makuleringOpprettetDato
          ? formatDateTimeHavingNorwegianDateTime(
              makulering.makuleringOpprettetDato
            )
          : ""}
      </td>
      <td style={{ maxWidth: 100 }}>
        {makulering.makulertEtter30MinKommentar}
      </td>
    </tr>
  );
};

const getTableHeaders = (sort, onSort) => (
  <>
    <HeaderCell cellKey="ileggelsesdatoTil" sort={sort} onSort={onSort}>
      Ileggelsesdato
    </HeaderCell>
    <HeaderCell>Ileggelsesnummer</HeaderCell>
    <HeaderCell>Tjenestenummer</HeaderCell>
    <HeaderCell cellKey="ileggelsestypeKode" sort={sort} onSort={onSort}>
      Ileggelsetype
    </HeaderCell>
    <HeaderCell>Makuleringsgrunn</HeaderCell>
    <HeaderCell
      cellKey="makulertAvBetjentTjenestenummerOrOsloFellseIdent"
      sort={sort}
      onSort={onSort}
    >
      Makulert av
    </HeaderCell>
    <HeaderCell>Makuleringsdato</HeaderCell>
    <HeaderCell
      cellKey="makulertEtter30MinKommentar"
      sort={sort}
      onSort={onSort}
    >
      Makuleringsgrunn (etter 30 min)
    </HeaderCell>
  </>
);

const MakuleringerList = ({ makuleringerRapport, sort, onSort }) => {
  const [showInformation, setShowInformation] = useState(false);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", resizeEventListener);

    return function cleanUp() {
      window.removeEventListener("resize", resizeEventListener);
    };
  }, []);

  const resizeEventListener = () => {
    setInnerWidth(window.innerWidth);
  };

  return (
    <>
      <h3
        style={
          innerWidth > 767
            ? { fontSize: 16, fontWeight: "bold" }
            : {
                fontSize: 16,
                fontWeight: "bold",
                marginRight: 20,
                marginLeft: 20,
              }
        }
      >
        Makuleringer{" "}
        <InformationButton
          onClick={() => setShowInformation(!showInformation)}
        />
      </h3>
      {showInformation && (
        <div
          style={innerWidth > 767 ? {} : { marginRight: 20, marginLeft: 20 }}
        >
          <i>
            <b>Ileggelsesdato:</b> Datoen ileggelsen ble utstedt av bybetjenten
          </i>
          <br />
          <i>
            <b>Ileggelsesnummer:</b> Ileggelsens ileggelsesnummer
          </i>
          <br />
          <i>
            <b>Tjenestenummer:</b> Betjenten som opprettet ileggelsen
          </i>
          <br />
          <i>
            <b>Makuleringsgrunn:</b> Kommentar fra person som makulerte
            ileggelsen
          </i>
          <br />
          <i>
            <b>Makulert av:</b> Dersom ileggelsen er makulert av en betjent
            vises tjenestenummeret. Dersom ileggelsen er makulert fra klient
            vises Oslo fellesidentifisering til brukeren som makulerte
            ileggelsen
          </i>
          <br />
          <i>
            <b>Makuleringsdato:</b> Tidspunktet for makuleringen
          </i>
          <br />
          <i>
            <b>Makuleringsgrunn (etter 30 min):</b> Dersom en ileggelse er
            makulert etter 30 minutter etter den blitt opprettet må betjenten
            gje en grunn for dette
          </i>
        </div>
      )}
      <div className="table-wrapper-horizontal-scroll-white-space-normal">
        <Table items={makuleringerRapport} renderRow={renderRow}>
          {getTableHeaders(sort, onSort)}
        </Table>
      </div>
    </>
  );
};

export default MakuleringerList;
