import { connect } from "react-redux";

import AntallIleggelserPerIleggelsetype from "./AntallIleggelserPerIleggelsetype";

const mapStateToProps = (state) => ({
  antallIleggelserPerIleggelsetypeSubmittedForm:
    state.submittedForms.antallIleggelserPerIleggelsetypeSubmittedForm,
  antallIleggelserPerIleggelsetypeRapport:
    state.rapporter.antallIleggelserPerIleggelsetypeRapport,
});

export default connect(mapStateToProps)(AntallIleggelserPerIleggelsetype);
