const grunndataSitePaths = {
  shortUrl: "/grunndata",
  page: "/grunndata(|/|/aktorer|/bypatruljen|/farger|/forsinket-makulering|/kjoretoymerker|/kjoretoytyper|/internkoder/ileggelser|/internkoder/miljogebyr|/nasjoner|/overtredelseskoder/ileggelser|/overtredelseskoder/miljogebyr|/rettekoder|/vedtakskoder)",
  aktorer: "/grunndata/aktorer",
  bypatruljen: "/grunndata/bypatruljen",
  farger: "/grunndata/farger",
  forsinketMakulering: "/grunndata/forsinket-makulering",
  kjoretoyMerker: "/grunndata/kjoretoymerker",
  kjoretoyTyper: "/grunndata/kjoretoytyper",
  overtredelseskoderIleggelser: "/grunndata/overtredelseskoder/ileggelser",
  overtredelseskoderMiljogebyr: "/grunndata/overtredelseskoder/miljogebyr",
  internkoderIleggelser: "/grunndata/internkoder/ileggelser",
  internkoderMiljogebyr: "/grunndata/internkoder/miljogebyr",
  nasjoner: "/grunndata/nasjoner",
  rettekoder: "/grunndata/rettekoder",
  vedtakskoder: "/grunndata/vedtakskoder",
};

export default grunndataSitePaths;
