import { handleChangeAllActionType } from "../../actions/handleChangeAction";

export const INITIAL_STATE = {
  submitTimestamp: "",
  fraDato: "",
  tilDato: "",
  teams: "",
  tjenestenummer: "",
};

export default function skiftloggSubmittedFormReducer(
  state = {
    ...INITIAL_STATE,
  },
  action
) {
  switch (action.type) {
    case handleChangeAllActionType("RAPPORT_SKIFTLOGG_SUBMITTED_FORM"):
      return { ...action.payload };
    default:
      return state;
  }
}
