import React from "react";
import { createSelector } from "reselect";
import _ from "lodash";

import Tooltip from "../components/Common/Tooltip";

import { toNorwegianDatetime } from "../utils/datetimeUtils";

import { betaltkodeDescriptions } from "../constants/globals";

const miljogebyr = (state) => state.miljogebyr;
const overtredelseskoderMiljogebyr = (state) =>
  state.overtredelseskoderMiljogebyr;

export const getGridTableRowData = createSelector(
  miljogebyr,
  overtredelseskoderMiljogebyr,
  (miljogebyr, overtredelseskoderMiljogebyr) => {
    let statusArray = [];
    if (miljogebyr.isVenter) statusArray.push("Venter");
    if (miljogebyr.isTilEttersendelse) statusArray.push("TilEttersendelse");
    if (miljogebyr.isEttersendelseTilGodkjenning)
      statusArray.push("EttersendelseTilGodkjenning");
    if (miljogebyr.isEttersendt) statusArray.push("Ettersendt");
    if (miljogebyr.isMakulert) statusArray.push("Makulert");
    if (miljogebyr.isKlarTilKemner) statusArray.push("KlarTilIne");
    if (miljogebyr.isStoppetAvSystemet) statusArray.push("StoppetAvSystemet");
    if (miljogebyr.isFeiletKemner) statusArray.push("FeiletIne");
    if (miljogebyr.isSendtKemner) statusArray.push("SendtIne");
    if (miljogebyr.isBetalt) statusArray.push("Har betalingskode");
    if (miljogebyr.isMaKvalitetsikres) statusArray.push("Må kvalitetsikres");

    let statuserElement = "";
    if (statusArray.length > 0) {
      statuserElement = (
        <React.Fragment>
          {statusArray.map((status, index) => {
            return (
              <React.Fragment key={`reactFragmentStatus${index}`}>
                <span className="badge-bym-gray" key={`statusBadge${index}`}>
                  {status}
                </span>
                &nbsp;
              </React.Fragment>
            );
          })}
        </React.Fragment>
      );
    }

    const getParagrafForOvertredelsesKode = (overtredelse) => {
      return overtredelse && overtredelseskoderMiljogebyr.length > 0
        ? _.get(
            overtredelseskoderMiljogebyr.find(
              (kode) => kode.id === overtredelse.id
            ),
            ["paragraf"]
          )
        : "";
    };

    let overtredelser = "";
    overtredelser = (
      <React.Fragment>
        {miljogebyr.overtredelse1 && (
          <Tooltip
            tooltip={miljogebyr.overtredelse1.beskrivelse}
            tooltipPosition="right"
          >
            {getParagrafForOvertredelsesKode(miljogebyr.overtredelse1)}
          </Tooltip>
        )}
        {miljogebyr.overtredelse2 && (
          <React.Fragment>
            {"/"}
            <Tooltip
              tooltip={miljogebyr.overtredelse2.beskrivelse}
              tooltipPosition="right"
            >
              {getParagrafForOvertredelsesKode(miljogebyr.overtredelse2)}
            </Tooltip>
          </React.Fragment>
        )}
        {miljogebyr.overtredelse3 && (
          <React.Fragment>
            {miljogebyr.overtredelse2 ? "/" : "//"}
            <Tooltip
              tooltip={miljogebyr.overtredelse3.beskrivelse}
              tooltipPosition="right"
            >
              {getParagrafForOvertredelsesKode(miljogebyr.overtredelse3)}
            </Tooltip>
          </React.Fragment>
        )}
      </React.Fragment>
    );

    let internkoder = "";
    internkoder = (
      <React.Fragment>
        {miljogebyr.internkode1 && (
          <Tooltip tooltip={miljogebyr.internkode1.beskrivelse}>
            {miljogebyr.internkode1.id}
          </Tooltip>
        )}
        {miljogebyr.internkode2 && (
          <React.Fragment>
            {"/"}
            <Tooltip tooltip={miljogebyr.internkode2.beskrivelse}>
              {miljogebyr.internkode2.id}
            </Tooltip>
          </React.Fragment>
        )}
        {miljogebyr.internkode3 && (
          <React.Fragment>
            {"/"}
            <Tooltip tooltip={miljogebyr.internkode3.beskrivelse}>
              {miljogebyr.internkode3.id}
            </Tooltip>
          </React.Fragment>
        )}
      </React.Fragment>
    );

    let klokkeslett = "-";
    if (miljogebyr.ileggelsesdatoFra) {
      klokkeslett =
        toNorwegianDatetime(miljogebyr.ileggelsesdatoFra).format("HH:mm") +
        " - ";
    }
    if (miljogebyr.ileggelsesdatoTil) {
      klokkeslett =
        klokkeslett +
        toNorwegianDatetime(miljogebyr.ileggelsesdatoTil).format("HH:mm");
    }

    let tjenestenummer = {
      name: "Tjenestenummer",
      value: `${miljogebyr.betjentNummer}`,
      description: "",
    };

    let gatenummer = "";
    if (miljogebyr.gatenummer) {
      gatenummer = miljogebyr.gatenummer;
    }
    if (miljogebyr.oppgang) {
      gatenummer = gatenummer + " " + miljogebyr.oppgang;
    }

    let vedGatenummer = "";
    if (miljogebyr.vedGatenummer) {
      vedGatenummer = miljogebyr.vedGatenummer;
    }
    // let vedOppgang = "";
    if (miljogebyr.vedOppgang) {
      vedGatenummer = vedGatenummer + " " + miljogebyr.vedOppgang;
    }

    let betaltDato = "-";
    if (miljogebyr.betaltDato) {
      betaltDato = toNorwegianDatetime(miljogebyr.betaltDato).format(
        "DD.MM.YYYY"
      );
      betaltDato = betaltDato === "01.01.2000" ? "Ingen betaling" : betaltDato;
    }

    return [
      [
        {
          name: "Ileggelsesnummer",
          value: String(miljogebyr.ileggelsesnummer),
          description: "",
        },
        {
          name: "Ileggelsesdato",
          value:
            miljogebyr.ileggelsesdatoTil &&
            toNorwegianDatetime(miljogebyr.ileggelsesdatoTil).format(
              "DD.MM.YYYY"
            ),
          description: "",
        },
        {
          name: "Klokkeslett",
          value: klokkeslett,
          description: "",
        },
        tjenestenummer,
        {
          name: "Ileggelsestype",
          value: "Miljøgebyr",
          description: "",
        },
        { name: "Status", value: statuserElement, description: "" },
      ],
      [
        { name: "Gatenavn", value: miljogebyr.gatenavn, description: "" },
        {
          name: "Gatenummer",
          value: gatenummer || "",
          description: "",
        },
        { name: "Bydel", value: String(miljogebyr.bydel), description: "" },
        { name: "", value: "", description: "" },
        { name: "", value: "", description: "" },
        { name: "", value: "", description: "" },
      ],
      [
        { name: "Ved gate", value: miljogebyr.vedGate, description: "" },
        {
          name: "Ved gatenummer",
          value: vedGatenummer,
          description: "",
        },
        // { name: "Ved oppgang", value: vedOppgang, description: "" },

        {
          name: "Latitude",
          value:
            miljogebyr.latitude || miljogebyr.latitude === 0
              ? String(miljogebyr.latitude)
              : "-",
          description: "",
        },
        {
          name: "Longitude",
          value:
            miljogebyr.longitude || miljogebyr.longitude === 0
              ? String(miljogebyr.longitude)
              : "-",
          description: "",
        },
        { name: "Usikkerhet", value: "", description: "" },
      ],
      [
        {
          name: "Overtredelseskode",
          value: overtredelser,
          description: "",
        },
        {
          name: "Internkode",
          value: internkoder,
          description: "",
        },
        {
          name: "Beløp",
          value:
            miljogebyr.belop || miljogebyr.belop === 0
              ? String(miljogebyr.belop)
              : "-",
          description: "",
        },
        { name: "", value: "", description: "" },
        { name: "Observasjon", value: "", description: "" },
        {
          name: "Leveringstype",
          value: (
            <Tooltip
              tooltip={
                miljogebyr &&
                miljogebyr.leveringstype &&
                miljogebyr.leveringstype.beskrivelse
              }
            >
              {miljogebyr &&
                miljogebyr.leveringstype &&
                miljogebyr.leveringstype.id}
            </Tooltip>
          ),
          description: "",
        },
      ],
      [
        {
          name: "Betaltkode",
          value:
            miljogebyr &&
            betaltkodeDescriptions[miljogebyr.betaltKode] &&
            betaltkodeDescriptions[miljogebyr.betaltKode].description ? (
              <Tooltip
                tooltip={
                  betaltkodeDescriptions[miljogebyr.betaltKode].description
                }
                tooltipPosition="right"
              >
                {miljogebyr.betaltKode ? miljogebyr.betaltKode : "-"}
              </Tooltip>
            ) : (
              miljogebyr && miljogebyr.betaltKode
            ),
          description: "",
        },
        {
          name: "Overført betalingsdato",
          value: betaltDato,
          description: "",
        },
        {
          name: "Inedato - ileggelse",
          value:
            miljogebyr &&
            miljogebyr.sendtTilKemnerDato &&
            toNorwegianDatetime(miljogebyr.sendtTilKemnerDato).format(
              "DD.MM.YYYY"
            ),
          description: "",
        },
        { name: "", value: "", description: "" },
        { name: "", value: "", description: "" },
        { name: "", value: "", description: "" },
      ],
    ];
  }
);

export const getOvertredelseskoderMiljogebyrAsDropDownListSearchFriendly =
  createSelector(
    overtredelseskoderMiljogebyr,
    (overtredelseskoderMiljogebyr) => {
      return overtredelseskoderMiljogebyr.map((overtredelseskode) => {
        return {
          id: overtredelseskode.id,
          navn: overtredelseskode.paragraf,
          beskrivelse: overtredelseskode.kortTekst,
        };
      });
    }
  );

export default getGridTableRowData;
