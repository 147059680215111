import { WebStorageStateStore } from "oidc-client-ts";
import { getHostFromEnv } from "../config";

export const oidcConfig = {
  authority: getHostFromEnv("ONELOGIN_AUTHORITY"),
  client_id: getHostFromEnv("ONELOGIN_CLIENT_ID"),
  redirect_uri: getHostFromEnv("ONELOGIN_REDIRECT_URI"),
  silent_redirect_uri: getHostFromEnv("ONELOGIN_SILENT_REDIRECT_URI"),
  post_logout_redirect_uri: getHostFromEnv("ONELOGIN_POST_LOGOUT_REDIRECT_URI"),
  resource: getHostFromEnv("ONELOGIN_RESOURCE_URI"),
  automaticSilentRenew: true,
  scope: "openid groups params",
  userStore: new WebStorageStateStore({
    store: localStorage,
  }),
  response_type: "code",
};
