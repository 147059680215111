import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DetaljKommentarer from "../../../Common/Detalj/DetaljKommentarer";
import GridTable from "../../../Common/GridTable";

import { getGridTableRowData } from "../../../../selectors/ileggelseSelector";

class IleggelseGridOgKommentarContainer extends React.Component {
  static propTypes = {
    ileggelse: PropTypes.object,
    gridTableRowData: PropTypes.array,
  };

  render() {
    return (
      <section>
        <GridTable name="detaljIleggelse" rows={this.props.gridTableRowData} />
        <div style={{ paddingLeft: 50, paddingRight: 50 }}>
          <DetaljKommentarer
            internkommentarFraBybetjent={this.props.ileggelse.internkommentar}
            kommentarTilMottager={this.props.ileggelse.kommentar}
          />
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    gridTableRowData: getGridTableRowData(state),
    ileggelse: state.ileggelse,
  };
};

export default connect(
  mapStateToProps,
  null
)(IleggelseGridOgKommentarContainer);
