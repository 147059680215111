import { CreateNewReducer } from "../utils/reducerUtils";
import { KLAGE_ILEGGELSE_LIGHTBOX_IMAGE } from "../actions/klager/klageAction";

const INITIAL_STATE = [];

export const klageIleggelseLightboxImageReducer = (
  state = INITIAL_STATE,
  action
) => CreateNewReducer(state, action, KLAGE_ILEGGELSE_LIGHTBOX_IMAGE);

export default klageIleggelseLightboxImageReducer;
