import * as ileggelserActions from "../actions/ileggelserActions";

const INITIAL_STATE = {};

export default function ileggelseReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ileggelserActions.GET_ILEGGELSE_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
