import React, { useEffect } from "react";

import GridTable from "../../GridTable";

// Miljøkontroll har endret navn til tidskontroll
const Miljokontroll = ({
  miljoKontrollId,
  toggleIsLoadingMiljokontrollBilder,
  gridTableRows,
  getMiljokontroll,
  getMiljokontrollBilder,
  miljokontrollToInitState,
}) => {
  useEffect(() => {
    if (miljoKontrollId && getMiljokontroll) {
      getMiljokontroll(miljoKontrollId);
    } else if (
      !miljoKontrollId &&
      getMiljokontroll &&
      miljokontrollToInitState
    ) {
      miljokontrollToInitState();
    }
  }, [miljoKontrollId, getMiljokontroll, miljokontrollToInitState]);

  useEffect(() => {
    if (miljoKontrollId && getMiljokontrollBilder) {
      toggleIsLoadingMiljokontrollBilder();
      let isLoadingLightboxBilder = true;
      let isLoadingThumbnailBilder = true;
      getMiljokontrollBilder(miljoKontrollId, {
        s3BucketCategory: "miljogebyrer_lightbox",
      })
        .then(() => {
          isLoadingLightboxBilder = false;
          if (!isLoadingLightboxBilder && !isLoadingThumbnailBilder) {
            toggleIsLoadingMiljokontrollBilder();
          }
        })
        .catch(() => {
          isLoadingLightboxBilder = false;
          if (!isLoadingLightboxBilder && !isLoadingThumbnailBilder) {
            toggleIsLoadingMiljokontrollBilder();
          }
        });

      getMiljokontrollBilder(miljoKontrollId, {
        s3BucketCategory: "miljogebyrer_thumbnail",
      })
        .then(() => {
          isLoadingThumbnailBilder = false;
          if (!isLoadingLightboxBilder && !isLoadingThumbnailBilder) {
            toggleIsLoadingMiljokontrollBilder();
          }
        })
        .catch(() => {
          isLoadingThumbnailBilder = false;
          if (!isLoadingLightboxBilder && !isLoadingThumbnailBilder) {
            toggleIsLoadingMiljokontrollBilder();
          }
        });
    }
  }, [
    miljoKontrollId,
    toggleIsLoadingMiljokontrollBilder,
    getMiljokontrollBilder,
  ]);

  return (
    <section>
      <div className="detalj-ileggelse-header-wrapper">
        <h2>Tidskontroll</h2>
      </div>
      {gridTableRows && (
        <div>
          <GridTable
            name="miljokontroll"
            rows={gridTableRows}
            oneColoredGridTable
          />
        </div>
      )}
    </section>
  );
};

export default Miljokontroll;
