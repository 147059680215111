import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";

export default function OpenMakulerModal(props) {
  return (
    <Button
      className="btn btn-link"
      style={{
        fontWeight: "normal",
        fontSize: 14,
        padding: 0,
        color: "#d32316"
      }}
      onClick={props.openModal}
    >
      Makuler
    </Button>
  );
}

OpenMakulerModal.propTypes = {
  openModal: PropTypes.func
};
