import React from "react";
import PropTypes from "prop-types";
import TextArea from "../Forms/TextArea";
import { text } from "../../../constants/text";

export default function MakulerModalContent({
  ileggelsesnummer,
  kommentarValidationText,
  handleChange,
}) {
  return (
    <form>
      <p>{text.makulerModal.askUser(ileggelsesnummer)}</p>
      <TextArea
        label={text.makulerModal.inputLabel}
        name="kommentar"
        onChange={handleChange}
        validationError={kommentarValidationText}
        placeHolder={text.makulerModal.inputPlaceHolder}
      />
    </form>
  );
}

MakulerModalContent.propTypes = {
  ileggelsesnummer: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  kommentar: PropTypes.string,
  kommentarValidationText: PropTypes.string,

  handleChange: PropTypes.func,
};
