import * as api from "../constants/api";
import * as axiosUtils from "../utils/axiosUtils";

export const actionType = "NASJONER";
const metaData = { entitet: "Nasjon", notify: true };

export function getNasjoner() {
  return axiosUtils.GetAll(api.GET_NASJONER_URL, actionType);
}

export function postNasjon(payload) {
  return axiosUtils.Post(api.POST_NASJON_URL, payload, actionType, metaData);
}

export function putNasjon(payload) {
  return axiosUtils.Put(
    api.PUT_NASJON_URL(payload.id),
    payload,
    actionType,
    metaData
  );
}
