import React, { useState } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";

import Collapsible from "../Common/Collapsible";
import HeaderCell from "../Common/Tables/HeaderCell";
import TableCustomStyled from "../Common/Tables/TableCustomStyled";

import { formatDateTimeHavingNorwegianDateTime } from "../../utils/datetimeUtils";

const renderRow = (
  miljogebyr,
  inkludFail,
  inkludSentIneDato,
  inkludBehandletIneDato
) => {
  if (!miljogebyr) {
    return null;
  }
  const ileggelsesnummer = miljogebyr.ileggelsesnummer
    ? miljogebyr.ileggelsesnummer
    : "";
  const dato = miljogebyr.dato
    ? formatDateTimeHavingNorwegianDateTime(miljogebyr.dato)
    : "";
  const navn = miljogebyr.navn ? miljogebyr.navn : "";
  const gate = miljogebyr.gate ? miljogebyr.gate : "";
  const gate_nummer = miljogebyr.gate_nummer ? miljogebyr.gate_nummer : "";
  const gateOgGateadresse = `${gate} ${gate_nummer}`.trim();
  const postnummer = miljogebyr.postnummer ? miljogebyr.postnummer : "";
  const poststed = miljogebyr.poststed ? miljogebyr.poststed : "";
  const overtredelse = miljogebyr.overtredelse ? miljogebyr.overtredelse : "";
  const belop = miljogebyr.belop ? miljogebyr.belop : "";
  const sent_to_ine = miljogebyr.sent_to_ine
    ? formatDateTimeHavingNorwegianDateTime(miljogebyr.sent_to_ine)
    : "";
  const received_from_ine = miljogebyr.received_from_ine
    ? formatDateTimeHavingNorwegianDateTime(miljogebyr.received_from_ine)
    : "";
  const status_beskrivelse = miljogebyr.status_beskrivelse
    ? miljogebyr.status_beskrivelse
    : "";

  return (
    <>
      <tr>
        <td>
          <Link to={`${window.location.pathname}/${miljogebyr.id}`}>
            {ileggelsesnummer}
          </Link>
        </td>
        <td>{dato}</td>
        <td>{navn}</td>
        <td>{gateOgGateadresse}</td>
        <td>{postnummer}</td>
        <td>{poststed}</td>
        <td>{overtredelse}</td>
        <td>{belop}</td>
        {inkludSentIneDato && <td>{sent_to_ine}</td>}
        {inkludBehandletIneDato && <td>{received_from_ine}</td>}
        {inkludFail && <td>{status_beskrivelse}</td>}
      </tr>
    </>
  );
};

const InnkrevingMiljogebyrData = ({
  id,
  title,
  headers,
  miljogebyr,
  isLoading,
  inkludFail = false,
  inkludSentIneDato = false,
  inkludBehandletIneDato = false,
}) => {
  const [selectedSort, setSelectedSort] = useState({
    cellKey: "dato",
    orders: "desc",
  });

  const handleSort = (value) => {
    setSelectedSort(value);
  };

  const getSortedMiljogebyr = () => {
    const cellKey = selectedSort.cellKey;
    const orders = selectedSort.orders;
    return _.orderBy(miljogebyr, [cellKey], [orders]);
  };

  return (
    <Collapsible id={id} key={id} title={title} isLoading={isLoading}>
      <div style={{ display: "block", overflowX: "auto", width: "100%" }}>
        <TableCustomStyled
          className="bym-table table"
          renderRow={(miljogebyr) =>
            renderRow(
              miljogebyr,
              inkludFail,
              inkludSentIneDato,
              inkludBehandletIneDato
            )
          }
          items={getSortedMiljogebyr()}
        >
          {headers &&
            Object.keys(headers).map((header) => {
              if (headers[header].cellKey) {
                return (
                  <HeaderCell
                    key={header + "headerForKlarTilInekjoring"}
                    cellKey={headers[header].cellKey}
                    sort={selectedSort}
                    onSort={handleSort}
                  >
                    {header}
                  </HeaderCell>
                );
              }
              return (
                <HeaderCell key={header + "Miljogebyr"}>{header}</HeaderCell>
              );
            })}
        </TableCustomStyled>
      </div>
    </Collapsible>
  );
};

export default InnkrevingMiljogebyrData;
