import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table as BsTable } from "react-bootstrap";
import _ from "lodash";

class Table extends Component {
  static propTypes = {
    children: PropTypes.any,
    id: PropTypes.string,
    className: PropTypes.string,
    headerRow: PropTypes.array,
    defaultStyle: PropTypes.bool,
    striped: PropTypes.bool,
    stripedHeaderStyle: PropTypes.bool,
    trStyle: PropTypes.object,
    tdStyle: PropTypes.arrayOf(
      PropTypes.shape({ headerName: PropTypes.string, style: PropTypes.object })
    ),
  };

  componentDidMount() {
    const { defaultStyle, striped, stripedHeaderStyle } = this.props;
    if (defaultStyle) {
      require("./baseTable.css");
    }
    if (striped) {
      require("./baseTableStripped.css");
    }
    else if (stripedHeaderStyle) {
      require("./baseTableStripedHeaderStyle.css")
    }
  }
  
  renderHeader = () => {
    const { headerRow, trStyle, tdStyle } = this.props;
    if (headerRow) {
      return (
        <thead id="GenericTableTopHeader">
          <tr style={trStyle}>
            {_.map(headerRow, (i, index) => {
              const style =
                tdStyle && tdStyle.headerName === i ? tdStyle.style : {};
              return (
                <td key={index} style={style}>
                  <span id="headerText">{i}</span>
                </td>
              );
            })}
          </tr>
        </thead>
      );
    }
  };
  
  render() {
    const { id, className, striped, stripedHeaderStyle, children } = this.props;
    const tableStriped = striped ? "table-striped" : "";
    const tableStripedHeaderStyle = stripedHeaderStyle ? "table-striped-header-style" : "";

    return (
      <BsTable
        id={id}
        className={`table BaseTable ${tableStriped} ${tableStripedHeaderStyle} ${className}`}
      >
        {this.renderHeader()}
        {children}
      </BsTable>
    );
  }
}

export default Table;
