import React, { Component } from "react";
import PropTypes from "prop-types";
import { Image } from "react-bootstrap";

import "./PaginationControl.css";
import iconLeft from "../../images/pil-venstre.svg";
import iconRight from "../../images/pil-høyre.svg";

class PaginationControl extends Component {
  static defaultProps = {
    id: "boldedStyle",
  };
  static propTypes = {
    page: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    onNext: PropTypes.func,
    onPrevious: PropTypes.func,
    onChange: PropTypes.func,
    id: PropTypes.string,
  };

  state = {
    innerWidth: window.innerWidth,
  };

  componentDidMount = () => {
    window.addEventListener("resize", this.resizeEventListener);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.resizeEventListener);
  };

  resizeEventListener = () => {
    this.setState(() => ({ innerWidth: window.innerWidth }));
  };

  get firstItem() {
    return this.props.total === 0
      ? 0
      : this.props.count * (this.props.page - 1) + 1;
  }

  get lastItem() {
    return Math.min(this.props.count * this.props.page, this.props.total);
  }

  get canGoBack() {
    return this.props.page > 1;
  }

  get canGoForward() {
    return this.props.page * this.props.count < this.props.total;
  }

  handleChange = (e) => {
    if (this.props.onChange) {
      this.props.onChange(Number(e.target.value));
    }
  };

  render() {
    const { id, count, total, onNext, onPrevious } = this.props;
    const { innerWidth } = this.state;

    if (innerWidth > 767) {
      return (
        <div className="clearfix pagination-control-container">
          <div id={id} className="pagination-control pull-right">
            <div style={{ display: "inline-block" }}>
              <span>Rader per side: </span>
              <select value={count} onChange={this.handleChange}>
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={100}>100</option>
                <option value={200}>200</option>
                <option value={500}>500</option>
              </select>
              <div className="pc-space-small pc-page-number">
                {this.firstItem}-{this.lastItem} av {total}
              </div>
            </div>
            <div style={{ display: "inline-block" }}>
              <button
                className="pc-space-large"
                onClick={onPrevious}
                disabled={!this.canGoBack}
              >
                <Image src={iconLeft} />
              </button>
              <button
                className="pc-space-small"
                onClick={onNext}
                disabled={!this.canGoForward}
              >
                <Image src={iconRight} />
              </button>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="clearfix">
        <div id={id} style={{ marginTop: 10 }}>
          <div className="pull-right">
            <div className="pull-right" style={{ whiteSpace: "nowrap" }}>
              <div className="pc-space-small pc-page-number">
                {this.firstItem}-{this.lastItem} av {total}
              </div>
              <button
                className="pc-space-large"
                onClick={onPrevious}
                disabled={!this.canGoBack}
              >
                <Image src={iconLeft} />
              </button>
              <button
                className="pc-space-small"
                onClick={onNext}
                disabled={!this.canGoForward}
              >
                <Image src={iconRight} />
              </button>
            </div>
          </div>
          <div className="clearfix" />
        </div>
      </div>
    );
  }
}

export default PaginationControl;
