import * as miljogebyrerActions from "../actions/miljogebyrerActions";

const INITIAL_STATE = [];

export default function miljogebyrKlagerReducer(
  state = [...INITIAL_STATE],
  action
) {
  switch (action.type) {
    case miljogebyrerActions.GET_MILJOGEBYRER_MILJOGEBYRKLAGER_SUCCESS:
      return [...action.payload];
    default:
      return state;
  }
}
