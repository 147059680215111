import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Button } from "react-bootstrap";

import ModalGenericConfirmCancelContainer from "../../../Common/ModalGenericConfirmCancelContainer";
import EndreMiljokontrollModalForm from "./EndreMiljokontrollModalForm";

const getPayloadParametersFromMiljokontroll = (miljokontroll) => ({
  gatenavn: miljokontroll.gatenavn,
  gatenummer: miljokontroll.gatenummer,
  oppgang: miljokontroll.oppgang,
  bydel: miljokontroll.bydel,
  vedGate: miljokontroll.vedGate,
  vedGatenummer: miljokontroll.vedGatenummer,
  vedOppgang: miljokontroll.vedOppgang,
});

// Miljøkontroll har bytt navn til tidskontroll
const EndreMiljokontroll = ({ miljokontroll, putStedbeskrivelse }) => {
  const [payload, setPayload] = useState(
    getPayloadParametersFromMiljokontroll(miljokontroll)
  );

  const miljokontrollString = JSON.stringify(miljokontroll);
  useEffect(() => {
    setPayload(getPayloadParametersFromMiljokontroll(miljokontroll));
  }, [miljokontrollString, miljokontroll]);

  return (
    <ModalGenericConfirmCancelContainer
      id={"endre-miljokontroll-modal-generic-confirm-cancel-container"}
      className="miljogebyrDetailPutModal"
      modalTitle={`Endre tidskontroll  ${miljokontroll.nummer}`}
      submitButtonText="Lagre endring"
      submittingButtonText="Lagrer endring..."
      handleConfirmClick={() => putStedbeskrivelse(payload)}
      handleCancelAndCloseClick={() =>
        setPayload(getPayloadParametersFromMiljokontroll(miljokontroll))
      }
      openModalComponentFunction={(endreModalProps) => (
        <Button
          className="btn btn-bym-standard"
          style={{ marginLeft: 20 }}
          onClick={endreModalProps.openModal}
        >
          Endre tidskontroll
        </Button>
      )}
    >
      <EndreMiljokontrollModalForm payload={payload} setPayload={setPayload} />
    </ModalGenericConfirmCancelContainer>
  );
};

EndreMiljokontroll.propTypes = {
  miljokontroll: PropTypes.object.isRequired,
  putStedbeskrivelse: PropTypes.func.isRequired,
};

export default EndreMiljokontroll;
