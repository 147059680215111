import * as motorvognActions from "../actions/motorvognActions";

const INITIAL_STATE = {};

export default function innsynMotorvognReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case motorvognActions.GET_SINGLE_MOTORVOGN_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
