import React from "react";
import PropTypes from "prop-types";
import TextInput from "../../Common/Forms/TextInput";
import DropDown from "../../Common/Forms/DropDown";
import DatetimePicker from "../../Common/Forms/DatetimePickerEnhanced";
import DropDownListSearch from "../../Common/Forms/DropdownListSearchable";
import FormButtonsForCloseAndSubmit from "../../Common/Forms/FormButtonsForCloseAndSubmit";

import { mergeDateAndTimeForFromAndTo } from "../../../utils/fromToDateTimeUtils";
import * as inputValidation from "../../../utils/inputValidationUtils";
import { IsValidated } from "../../../utils/validationUtils";
import { replaceWhitespacesWithEmptyStrings } from "../../../utils/sanitizationUtils";

class FilterForm extends React.Component {
  static propTypes = {
    overtredelseskoder: PropTypes.array.isRequired,
    getMiljogebyrTilBehandling: PropTypes.func.isRequired,
    getOvertredelseskoderMiljogebyr: PropTypes.func.isRequired,
    handleChangeAll: PropTypes.func.isRequired,
    handleChangeToInitState: PropTypes.func.isRequired,
  };

  inputStatesInitState = {
    ileggelsesnummer: "",
    fraDato: "",
    fraTid: "",
    tilDato: "",
    tilTid: "",
    tjenestenummer: "",
    mottaker: "",
    gatenavn: "", // gatenavn/ved
    gatenummer: "", // gatenummer/ved nr
    bydel: "",
    overtredelseskode: "",
    leveringstype: "",
  };

  validationErrorInitState = {};

  state = {
    showSokSpinner: false,
    showTilbakestillSpinner: false,
    inputStates: this.inputStatesInitState,
    validationError: this.validationErrorInitState,
  };

  rules = {
    ileggelsesnummer: "isPositiveInteger",
    tjenestenummer: "isPositiveInteger",
  };

  componentDidMount() {
    const { getOvertredelseskoderMiljogebyr, getMiljoleveringstyper } =
      this.props;
    getOvertredelseskoderMiljogebyr();
    getMiljoleveringstyper();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(this.state.inputStates) ===
        JSON.stringify(this.inputStatesInitState) &&
      JSON.stringify(prevState.inputStates) !==
        JSON.stringify(this.state.inputStates) &&
      prevState.setToInitState === false &&
      this.state.setToInitState === true
    ) {
      this.setState(() => ({ setToInitState: false }));
      this.getMiljogebyrTilBehandlingAfterSetToInitState();
    }
  }

  getMiljogebyrTilBehandlingAfterSetToInitState = () => {
    const { getMiljogebyrTilBehandling } = this.props;
    const { inputStates } = this.state;
    const fraDatoAndTilDatoQueryData = mergeDateAndTimeForFromAndTo(
      inputStates.fraDato,
      inputStates.fraTid,
      inputStates.tilDato,
      inputStates.tilTid
    );
    if (this.isValid()) {
      this.setState({ showTilbakestillSpinner: true });
      getMiljogebyrTilBehandling({
        ...inputStates,
        fraDato: fraDatoAndTilDatoQueryData.fraDato,
        tilDato: fraDatoAndTilDatoQueryData.tilDato,
      })
        .then(() => {
          this.setState({ showTilbakestillSpinner: false });
        })
        .catch(() => this.setState({ showTilbakestillSpinner: false }));
    }
  };

  getOvertredelseskodeParagraf = (id) => {
    const { overtredelseskoder } = this.props;
    let matchingOvertredelseskode = undefined;
    if (overtredelseskoder) {
      matchingOvertredelseskode = overtredelseskoder.find(
        (overtredelseskode) => overtredelseskode.id === id
      );
    }
    if (matchingOvertredelseskode) {
      return matchingOvertredelseskode.paragraf;
    }
    return null;
  };

  handleChange = (e) => {
    const { inputStates } = this.state;
    const name = e.target.name;
    const value = e.target.value;

    this.setState((prevState) => ({
      inputStates: { ...inputStates, [name]: value },
    }));
  };

  handleBlur = (e) => {
    if (
      e.target.name === "ileggelsesnummer" ||
      e.target.name === "tjenestenummer"
    ) {
      const name = e.target.name;
      const value = replaceWhitespacesWithEmptyStrings(e.target.value);
      this.setState((prevState) => ({
        ...prevState,
        inputStates: {
          ...prevState.inputStates,
          [name]: replaceWhitespacesWithEmptyStrings(value),
        },
      }));
    }
  };

  handleChangeToInitState = () => {
    this.setState({
      inputStates: this.inputStatesInitState,
      validationError: this.validationErrorInitState,
      setToInitState: false,
    });
  };

  isValid = () => {
    const { inputStates } = this.state;

    let fakeState = {
      ...inputStates,
      validationError: {},
    };
    const { isValid, validationError } = IsValidated(fakeState, this.rules);
    this.setState(() => ({
      validationError: { ...validationError },
    }));
    return (
      inputValidation.fraDateTimeTilDateTimeValidation(
        inputStates,
        (name, value) =>
          this.setState((state) => ({
            ...state,
            validationError: { ...state.validationError, [name]: value },
          }))
      ) && isValid
    );
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { getMiljogebyrTilBehandling, handleChangeAll } = this.props;
    const { inputStates } = this.state;
    const fraDatoAndTilDatoQueryData = mergeDateAndTimeForFromAndTo(
      inputStates.fraDato,
      inputStates.fraTid,
      inputStates.tilDato,
      inputStates.tilTid
    );
    if (this.isValid()) {
      this.setState({ showSokSpinner: true });
      getMiljogebyrTilBehandling({
        ...inputStates,
        fraDato: fraDatoAndTilDatoQueryData.fraDato,
        tilDato: fraDatoAndTilDatoQueryData.tilDato,
      })
        .then(() => {
          handleChangeAll({
            ...inputStates,
            fraDato: fraDatoAndTilDatoQueryData.fraDato,
            tilDato: fraDatoAndTilDatoQueryData.tilDato,
          });
          this.setState({ showSokSpinner: false });
        })
        .catch(() => this.setState({ showSokSpinner: false }));
    }
  };

  render() {
    const { overtredelseskoder, leveringstyper } = this.props;
    const {
      inputStates,
      validationError,
      showSokSpinner,
      showTilbakestillSpinner,
    } = this.state;
    return (
      <form className="ileggelse-filter-container" onSubmit={this.handleSubmit}>
        <h1>Søk etter miljøgebyr</h1>
        <div className="filter-flexbox-container ettersendelse-filter-flexbox-item">
          <TextInput
            label="Ileggelsesnummer"
            name="ileggelsesnummer"
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            value={inputStates.ileggelsesnummer}
            validationError={validationError.ileggelsesnummer}
          />
          <DatetimePicker
            id="fraDato"
            name="fraDato"
            locale="nb"
            label="Dato fra"
            placeholder="Velg"
            onChange={this.handleChange}
            value={inputStates.fraDato}
            timeFormat={false}
            validationError={validationError.fraDatoValidationErrorText}
          />
          <DatetimePicker
            id="fraTid"
            name="fraTid"
            locale="nb"
            label="Tid fra"
            placeholder="Velg"
            onChange={this.handleChange}
            value={inputStates.fraTid}
            dateFormat={false}
            validationError={validationError.fraTidValidationErrorText}
          />
          <DatetimePicker
            id="tilDato"
            name="tilDato"
            locale="nb"
            label="Dato til"
            placeholder="Velg"
            onChange={this.handleChange}
            value={inputStates.tilDato}
            timeFormat={false}
            validationError={validationError.tilDatoValidationErrorText}
          />
          <DatetimePicker
            id="tilTid"
            name="tilTid"
            locale="nb"
            label="Tid til"
            placeholder="Velg"
            onChange={this.handleChange}
            value={inputStates.tilTid}
            dateFormat={false}
            validationError={validationError.tilTilValidationErrorText}
          />
          <TextInput
            label="Tjenestenummer"
            name="tjenestenummer"
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            value={inputStates.tjenestenummer}
            type=""
            validationError={validationError.tjenestenummer}
          />
        </div>
        <div className="filter-flexbox-container ettersendelse-filter-flexbox-item">
          <TextInput
            label="Mottaker"
            name="mottaker"
            onChange={this.handleChange}
            value={inputStates.mottaker}
          />
          <TextInput
            label="Gatenavn/Ved"
            name="gatenavn"
            onChange={this.handleChange}
            value={inputStates.gatenavn}
          />
          <TextInput
            label="Gatenummer/Ved nr."
            name="gatenummer"
            onChange={this.handleChange}
            value={inputStates.gatenummer}
          />
          <TextInput
            label="Bydel"
            name="bydel"
            onChange={this.handleChange}
            value={inputStates.bydel}
          />
          <DropDownListSearch
            label="Overtredelseskode"
            name="overtredelseskode"
            title={
              this.getOvertredelseskodeParagraf(
                inputStates.overtredelseskode
              ) || "Velg"
            }
            items={overtredelseskoder}
            selected={inputStates.overtredelseskode}
            handleChange={this.handleChange}
          />
          <div>
            <label htmlFor="leveringstypeId">Leveringstype</label>
            <DropDown
              id="leveringstype"
              name="leveringstype"
              title="Velg"
              selected={inputStates.leveringstype}
              items={leveringstyper}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div style={{ marginTop: 10 }}>
          <FormButtonsForCloseAndSubmit
            submitText="Søk"
            onClose={this.handleChangeToInitState}
            onSubmit={this.handleSubmit}
            showCloseSpinner={showTilbakestillSpinner}
            showSubmitSpinner={showSokSpinner}
          />
        </div>
      </form>
    );
  }
}

export default FilterForm;
