import * as axiosUtils from "../utils/axiosUtils";

import axios from "../services/axios";
import * as api from "../constants/api";

import { addFlashMessage } from "./FlashMessagesAction";

import { toIsoString } from "../utils/datetimeUtils";

export const GET_SINGLE_MOTORVOGN_SUCCESS = "GET_MOTORVOGN_SUCCESS";

export const GET_ILEGGELSE_SINGLE_MOTORVOGN_SUCCESS =
  "GET_ILEGGELSE_SINGLE_MOTORVOGN_SUCCESS";

export function getSingleMotorvognByQuery(params, actionType = null) {
  return async (dispatch) => {
    return axios
      .get(api.GET_SINGLE_MOTORVOGN_BY_KJENNEMERKE_URL, {
        params: {
          kjennemerke: params.kjennemerke,
          dato: params.dato ? toIsoString(params.dato) : "",
          understellsnummer: params.understellsnummer,
        },
      })
      .then((response) => {
        if (actionType === GET_SINGLE_MOTORVOGN_SUCCESS) {
          dispatch({
            payload: { ...response.data.result, hasBeenSubmitted: true },
            type: actionType,
          });
        } else if (
          actionType === GET_ILEGGELSE_SINGLE_MOTORVOGN_SUCCESS &&
          response.data.result &&
          !response.data.result.kjennemerke
        ) {
          dispatch(
            addFlashMessage({
              type: "error",
              text: "Ingen motorvogn finnes for ditt søk.",
            })
          );
        }
        return Promise.resolve(response);
      })
      .catch((error) => {
        axiosUtils.errorMessage(dispatch, error);
        Promise.reject(error);
      });
  };
}
