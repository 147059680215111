import React, { useState } from "react";
import _ from "lodash";

import Collapsible from "../Common/Collapsible";
import HeaderCell from "../Common/Tables/HeaderCell";
import TableCustomStyled from "../Common/Tables/TableCustomStyled";

import { formatDateTimeHavingNorwegianDateTime } from "../../utils/datetimeUtils";

const renderRow = (
  vedtak,
  inkludFail,
  inkludSentIneDato,
  inkludBehandletIneDato
) => {
  if (!vedtak) {
    return null;
  }

  const saksnummer = vedtak.saksnummer ? vedtak.saksnummer : "";
  const saksbehandler = vedtak.saksbehandler ? vedtak.saksbehandler : "";
  const vedtaksdato = vedtak.vedtaksdato ? vedtak.vedtaksdato : "";
  const ileggelsesnummer = vedtak.ileggelsesnummer
    ? vedtak.ileggelsesnummer
    : "";
  const ileggelsestype = vedtak.ileggelsestype ? vedtak.ileggelsestype : "";
  const klagekode = vedtak.klagekode ? vedtak.klagekode : "";
  const navn = vedtak.navn ? vedtak.navn : "";
  const gateadresse = vedtak.gateadresse ? vedtak.gateadresse : "";
  const postnummer = vedtak.postnummer ? vedtak.postnummer : "";
  const poststed = vedtak.poststed ? vedtak.poststed : "";
  const nasjon = vedtak.nasjon ? vedtak.nasjon : "";
  const sent_to_ine = vedtak.sent_to_ine
    ? formatDateTimeHavingNorwegianDateTime(vedtak.sent_to_ine)
    : "";
  const received_from_ine = vedtak.received_from_ine
    ? formatDateTimeHavingNorwegianDateTime(vedtak.received_from_ine)
    : "";
  const status_beskrivelse = vedtak.status_beskrivelse
    ? vedtak.status_beskrivelse
    : "";
  return (
    <>
      <tr>
        <td>{saksnummer}</td>
        <td>{saksbehandler}</td>
        <td>{vedtaksdato}</td>
        <td>{ileggelsesnummer}</td>
        <td>{ileggelsestype}</td>
        <td>{klagekode}</td>
        <td>{navn}</td>
        <td>{gateadresse}</td>
        <td>{postnummer}</td>
        <td>{poststed}</td>
        <td>{nasjon}</td>
        {inkludSentIneDato && <td>{sent_to_ine}</td>}
        {inkludBehandletIneDato && <td>{received_from_ine}</td>}
        {inkludFail && <td>{status_beskrivelse}</td>}
      </tr>
    </>
  );
};

const InnkrevingIleggelserVedtakData = ({
  id,
  title,
  headers,
  vedtak,
  isLoading,
  inkludFail = false,
  inkludSentIneDato = false,
  inkludBehandletIneDato = false,
}) => {
  const [selectedSort, setSelectedSort] = useState({
    cellKey: "vedtaksdato",
    orders: "desc",
  });

  const handleSort = (value) => {
    setSelectedSort(value);
  };

  const getSortedVedtak = () => {
    const cellKey = selectedSort.cellKey;
    const orders = selectedSort.orders;
    if (cellKey && orders) {
      return _.orderBy(vedtak, [cellKey], [orders]);
    }
    return vedtak;
  };

  return (
    <Collapsible id={id} key={id} title={title} isLoading={isLoading}>
      <div style={{ display: "block", overflowX: "auto", width: "100%" }}>
        <TableCustomStyled
          className="bym-table table"
          renderRow={(vedtak) =>
            renderRow(
              vedtak,
              inkludFail,
              inkludSentIneDato,
              inkludBehandletIneDato
            )
          }
          items={getSortedVedtak()}
        >
          {headers &&
            Object.keys(headers).map((header) => {
              if (headers[header].cellKey) {
                return (
                  <HeaderCell
                    key={header + "headerForKlarTilInekjoring"}
                    cellKey={headers[header].cellKey}
                    sort={selectedSort}
                    onSort={handleSort}
                  >
                    {header}
                  </HeaderCell>
                );
              }
              return <HeaderCell key={header + "Vedtak"}>{header}</HeaderCell>;
            })}
        </TableCustomStyled>
      </div>
    </Collapsible>
  );
};

export default InnkrevingIleggelserVedtakData;
