import * as ileggelserActions from "../actions/ileggelserActions";

const INITIAL_STATE = {};

export default function ileggelseBilderReducer(
  state = { ...INITIAL_STATE },
  action
) {
  switch (action.type) {
    case ileggelserActions.GET_ILEGGELSE_BILDER_SUCCESS:
      return { ...state, [action.payload.ileggelseId]: action.payload.bilder };
    default:
      return state;
  }
}
