import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import { map, includes } from "lodash";

import {
  toNorwegianDatetime,
  toNorwegianTimeFormat,
} from "../../../utils/datetimeUtils";

import "./Historikk.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserAlt } from "@fortawesome/free-solid-svg-icons";

function Historikk(props) {
  let { historikkArray } = props;

  return (
    <div>
      <div>
        {historikkArray.length === 0 && (
          <React.Fragment>Ingen historisk ennå.</React.Fragment>
        )}
        {historikkArray.length > 0 && renderHistorikk(historikkArray)}
      </div>
    </div>
  );
}

Historikk.propTypes = {
  historikkArray: PropTypes.array,
};
Historikk.defaultProps = {};

function formatDateTime(dt) {
  return toNorwegianDatetime(dt).format("DD.MM.YYYY HH:mm");
}

function renderHistorikk(historikkArray) {
  return (
    historikkArray &&
    historikkArray.map((historikkObject, index) => {
      return (
        <div key={`historikk${index}`} className="historikk-list-row">
          <FontAwesomeIcon icon={faUserAlt} size="lg" />
          <div className="historikk-user-name">
            {historikkObject.historikkBrukerNavn || ""}
          </div>
          <div className="historikk-tidspunkt">
            {formatDateTime(historikkObject.historikkTidspunkt)}
          </div>
          <Row className="historikk-headers">
            <Col xs={4}>Felt</Col>
            <Col xs={4}>Opprinnelig verdi</Col>
            <Col xs={4}>Ny verdi</Col>
          </Row>
          {extractChangedFields(historikkObject) &&
            extractChangedFields(historikkObject).map(
              (historikkObject, index) => {
                return (
                  historikkObject && (
                    <div className="historikk-field-row" key={index}>
                      {historikkObject}
                    </div>
                  )
                );
              }
            )}
        </div>
      );
    })
  );
}

function extractChangedFields(historikkObject) {
  return (
    historikkObject &&
    historikkObject.felter &&
    map(historikkObject.felter, (valuesObject, key) =>
      renderRow(key, valuesObject)
    )
  );
}
function renderValues(feltName, value) {
  if (value && value !== "") {
    if (includes(feltName, "FraTid") || includes(feltName, "TilTid"))
      return toNorwegianTimeFormat(toNorwegianDatetime(value));
    else if (includes(feltName, "Dato") || includes("UtlopsTid"))
      return formatDateTime(value);
  }
  return value;
}

function renderRow(feltNavn, values) {
  return (
    <Row>
      <Col xs={4}>{feltNavn}</Col>
      <Col xs={4}>{renderValues(feltNavn, values.previous)}</Col>
      <Col xs={4}>{renderValues(feltNavn, values.current)}</Col>
    </Row>
  );
}

export default Historikk;
