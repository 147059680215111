import sitePaths from "../constants/sitePaths";

const getSvarUtHistorikkCloseUrl = (id, history) => {
  // pathnameArray[0] is empty string
  const pathnameArray = window.location.pathname.split("/");
  if (
    pathnameArray.length > 3 &&
    pathnameArray[1] === "ileggelser" &&
    pathnameArray[2] === "oversikt"
  ) {
    history.push(sitePaths.ileggelser.oversikt.detalj.shortUrl.getUrl(id));
  } else if (
    pathnameArray.length > 3 &&
    pathnameArray[1] === "ileggelser" &&
    pathnameArray[2] === "ettersendelser"
  ) {
    history.push(
      sitePaths.ileggelser.ettersendelser.detalj.shortUrl.getUrl(id)
    );
  } else if (
    pathnameArray.length > 3 &&
    pathnameArray[1] === "ileggelser" &&
    pathnameArray[2] === "ettersendelser-til-godkjenning"
  ) {
    history.push(
      sitePaths.ileggelser.ettersendelserTilGodkjenning.detalj.shortUrl.getUrl(
        id
      )
    );
  } else if (
    pathnameArray[1] === "ileggelser" &&
    pathnameArray[2] === "elsparksykkel-gebyr"
  ) {
    history.push(
      sitePaths.ileggelser.elsparksykkelGebyr.detalj.shortUrl.getUrl(id)
    );
  } else if (
    pathnameArray.length > 3 &&
    pathnameArray[1] === "miljogebyrer" &&
    pathnameArray[2] === "oversikt"
  ) {
    history.push(sitePaths.miljogebyrer.oversikt.detalj.shortUrl.getUrl(id));
  } else if (
    pathnameArray.length > 3 &&
    pathnameArray[1] === "miljogebyrer" &&
    pathnameArray[2] === "til-behandling"
  ) {
    history.push(
      sitePaths.miljogebyrer.tilBehandling.detalj.shortUrl.getUrl(id)
    );
  } else if (
    pathnameArray.length > 5 &&
    pathnameArray[1] === "miljogebyrer" &&
    pathnameArray[2] === "miljokontrolloversikt" &&
    pathnameArray[3] === "miljogebyrer"
  ) {
    history.push(
      sitePaths.miljogebyrer.miljokontrolloversikt.miljogebyrer.detalj.shortUrl.getUrl(
        id
      )
    );
  } else if (
    pathnameArray.length > 5 &&
    pathnameArray[1] === "miljogebyrer" &&
    pathnameArray[2] === "miljokontrolloversikt" &&
    pathnameArray[4] === "miljogebyrer"
  ) {
    history.push(
      sitePaths.miljogebyrer.miljokontrolloversikt.detalj.miljogebyrer.detalj.shortUrl.getUrl(
        pathnameArray[3],
        id
      )
    );
  } else if (
    pathnameArray.length > 4 &&
    pathnameArray[1] === "innkreving" &&
    pathnameArray[2] === "ileggelser" &&
    pathnameArray[3] === "venter"
  ) {
    history.push(
      sitePaths.innkreving.ileggelser.venter.detalj.shortUrl.getUrl(id)
    );
  } else if (
    pathnameArray.length > 4 &&
    pathnameArray[1] === "innkreving" &&
    pathnameArray[2] === "ileggelser" &&
    pathnameArray[3] === "stoppet"
  ) {
    history.push(
      sitePaths.innkreving.ileggelser.stoppet.detalj.shortUrl.getUrl(id)
    );
  } else if (
    pathnameArray.length > 4 &&
    pathnameArray[1] === "innkreving" &&
    pathnameArray[2] === "ileggelser" &&
    pathnameArray[3] === "klar"
  ) {
    history.push(
      sitePaths.innkreving.ileggelser.klar.detalj.shortUrl.getUrl(id)
    );
  } else if (
    pathnameArray.length > 4 &&
    pathnameArray[1] === "innkreving" &&
    pathnameArray[2] === "ileggelser" &&
    pathnameArray[3] === "behandles-innkreving"
  ) {
    history.push(
      sitePaths.innkreving.ileggelser.behandlesInnkreving.detalj.shortUrl.getUrl(
        id
      )
    );
  } else if (
    pathnameArray.length > 4 &&
    pathnameArray[1] === "innkreving" &&
    pathnameArray[2] === "ileggelser" &&
    pathnameArray[3] === "klar"
  ) {
    history.push(
      sitePaths.innkreving.ileggelser.feilet.detalj.shortUrl.getUrl(id)
    );
  } else if (
    pathnameArray.length > 4 &&
    pathnameArray[1] === "innkreving" &&
    pathnameArray[2] === "ileggelser" &&
    pathnameArray[3] === "feilet"
  ) {
    history.push(
      sitePaths.innkreving.ileggelser.feilet.detalj.shortUrl.getUrl(id)
    );
  } else if (
    pathnameArray.length > 4 &&
    pathnameArray[1] === "innkreving" &&
    pathnameArray[2] === "ileggelser" &&
    pathnameArray[3] === "vellykket"
  ) {
    history.push(
      sitePaths.innkreving.ileggelser.vellykket.detalj.shortUrl.getUrl(id)
    );
  } else if (
    pathnameArray.length > 4 &&
    pathnameArray[1] === "innkreving" &&
    pathnameArray[2] === "miljogebyr" &&
    pathnameArray[3] === "venter"
  ) {
    history.push(
      sitePaths.innkreving.miljogebyr.venter.detalj.shortUrl.getUrl(id)
    );
  } else if (
    pathnameArray.length > 4 &&
    pathnameArray[1] === "innkreving" &&
    pathnameArray[2] === "miljogebyr" &&
    pathnameArray[3] === "stoppet"
  ) {
    history.push(
      sitePaths.innkreving.miljogebyr.stoppet.detalj.shortUrl.getUrl(id)
    );
  } else if (
    pathnameArray.length > 4 &&
    pathnameArray[1] === "innkreving" &&
    pathnameArray[2] === "miljogebyr" &&
    pathnameArray[3] === "klar"
  ) {
    history.push(
      sitePaths.innkreving.miljogebyr.klar.detalj.shortUrl.getUrl(id)
    );
  } else if (
    pathnameArray.length > 4 &&
    pathnameArray[1] === "innkreving" &&
    pathnameArray[2] === "miljogebyr" &&
    pathnameArray[3] === "behandles-innkreving"
  ) {
    history.push(
      sitePaths.innkreving.miljogebyr.behandlesInnkreving.detalj.shortUrl.getUrl(
        id
      )
    );
  } else if (
    pathnameArray.length > 4 &&
    pathnameArray[1] === "innkreving" &&
    pathnameArray[2] === "miljogebyr" &&
    pathnameArray[3] === "feilet"
  ) {
    history.push(
      sitePaths.innkreving.miljogebyr.feilet.detalj.shortUrl.getUrl(id)
    );
  } else if (
    pathnameArray.length > 4 &&
    pathnameArray[1] === "innkreving" &&
    pathnameArray[2] === "miljogebyr" &&
    pathnameArray[3] === "vellykket"
  ) {
    history.push(
      sitePaths.innkreving.miljogebyr.vellykket.detalj.shortUrl.getUrl(id)
    );
  }
};

export default getSvarUtHistorikkCloseUrl;
