import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import CloseButton from "../CloseButton";

import sitePaths from "../../../constants/sitePaths";
import { text } from "../../../constants/text";
import { useQuery } from "../../../utils/useQuery";

export const useTilbakeknapp = (computedMatch) => {
  const query = useQuery();
  const history = useHistory();

  const handleClick = () => {
    // pathnameArray[0] is empty string
    const pathnameArray = window.location.pathname.split("/");
    if (
      pathnameArray.length > 3 &&
      pathnameArray[1] === "ileggelser" &&
      pathnameArray[2] === "oversikt"
    ) {
      history.push(sitePaths.ileggelser.oversikt.shortUrl);
    } else if (
      pathnameArray.length > 3 &&
      pathnameArray[1] === "ileggelser" &&
      pathnameArray[2] === "ettersendelser"
    ) {
      history.push(sitePaths.ileggelser.ettersendelser.shortUrl);
    } else if (
      pathnameArray.length > 3 &&
      pathnameArray[1] === "ileggelser" &&
      pathnameArray[2] === "ettersendelser-til-godkjenning"
    ) {
      history.push(sitePaths.ileggelser.ettersendelserTilGodkjenning.shortUrl);
    } else if (
      pathnameArray.length > 3 &&
      pathnameArray[1] === "ileggelser" &&
      pathnameArray[2] === "elsparksykkel-gebyr"
    ) {
      history.push(sitePaths.ileggelser.elsparksykkelGebyr.shortUrl);
    } else if (
      window.location.pathname.search(
        sitePaths.rapporter.ubetalteIleggelser.detalj.ileggelser.shortUrl
          .matcher
      ) === 0
    ) {
      history.push({
        pathname: sitePaths.rapporter.ubetalteIleggelser.detalj.shortUrl.getUrl(
          computedMatch?.params?.kjoretoyId
        ),
        search: `?ileggelseType=${query.get("ileggelseType")}`,
      });
    } else if (
      pathnameArray.length > 5 &&
      pathnameArray[1] === "miljogebyrer" &&
      pathnameArray[2] === "oversikt" &&
      pathnameArray[4] === "tidskontroller"
    ) {
      history.push(
        sitePaths.miljogebyrer.oversikt.detalj.shortUrl.getUrl(pathnameArray[3])
      );
    } else if (
      pathnameArray.length > 3 &&
      pathnameArray[1] === "miljogebyrer" &&
      pathnameArray[2] === "oversikt"
    ) {
      history.push(sitePaths.miljogebyrer.oversikt.shortUrl);
    } else if (
      pathnameArray.length > 5 &&
      pathnameArray[1] === "miljogebyrer" &&
      pathnameArray[2] === "til-behandling" &&
      pathnameArray[4] === "tidskontroller"
    ) {
      history.push(
        sitePaths.miljogebyrer.tilBehandling.detalj.shortUrl.getUrl(
          pathnameArray[3]
        )
      );
    } else if (
      pathnameArray.length > 3 &&
      pathnameArray[1] === "miljogebyrer" &&
      pathnameArray[2] === "til-behandling"
    ) {
      history.push(sitePaths.miljogebyrer.tilBehandling.shortUrl);
    } else if (
      pathnameArray.length > 6 &&
      pathnameArray[1] === "miljogebyrer" &&
      pathnameArray[2] === "tidskontrolloversikt" &&
      pathnameArray[3] === "miljogebyrer" &&
      pathnameArray[5] === "tidskontroller"
    ) {
      history.push(
        sitePaths.miljogebyrer.miljokontrolloversikt.miljogebyrer.detalj.shortUrl.getUrl(
          pathnameArray[4]
        )
      );
    } else if (
      pathnameArray.length > 5 &&
      pathnameArray[1] === "miljogebyrer" &&
      pathnameArray[2] === "tidskontrolloversikt" &&
      pathnameArray[4] === "miljogebyrer"
    ) {
      history.push(
        sitePaths.miljogebyrer.miljokontrolloversikt.detalj.shortUrl.getUrl(
          pathnameArray[3]
        )
      );
    } else if (
      pathnameArray.length > 3 &&
      pathnameArray[1] === "miljogebyrer" &&
      pathnameArray[2] === "tidskontrolloversikt"
    ) {
      history.push(sitePaths.miljogebyrer.miljokontrolloversikt.shortUrl);
    } else if (
      pathnameArray.length > 3 &&
      pathnameArray[1] === "innsyn" &&
      pathnameArray[2] === "ileggelser"
    ) {
      history.push(sitePaths.innsyn.ileggelser.oversikt);
    } else if (
      pathnameArray.length > 3 &&
      pathnameArray[1] === "innsyn" &&
      pathnameArray[2] === "miljogebyrer"
    ) {
      history.push(sitePaths.innsyn.miljogebyrer.oversikt);
    } else if (
      pathnameArray.length > 5 &&
      pathnameArray[1] === "klage" &&
      pathnameArray[2] === "miljogebyrer" &&
      pathnameArray[4] === "tidskontroller"
    ) {
      history.push(
        sitePaths.klage.miljogebyrer.detalj.shortUrl.getUrl(pathnameArray[3])
      );
    } else if (pathnameArray.length > 2 && pathnameArray[1] === "klage") {
      history.push(sitePaths.klage.shortUrl);
    } else if (
      pathnameArray.length > 4 &&
      pathnameArray[1] === "innkreving" &&
      pathnameArray[2] === "ileggelser" &&
      pathnameArray[3] === "venter"
    ) {
      history.push(sitePaths.innkreving.ileggelser.venter.shortUrl);
    } else if (
      pathnameArray.length > 4 &&
      pathnameArray[1] === "innkreving" &&
      pathnameArray[2] === "ileggelser" &&
      pathnameArray[3] === "stoppet"
    ) {
      history.push(sitePaths.innkreving.ileggelser.stoppet.shortUrl);
    } else if (
      pathnameArray.length > 4 &&
      pathnameArray[1] === "innkreving" &&
      pathnameArray[2] === "ileggelser" &&
      pathnameArray[3] === "klar"
    ) {
      history.push(sitePaths.innkreving.ileggelser.klar.shortUrl);
    } else if (
      pathnameArray.length > 4 &&
      pathnameArray[1] === "innkreving" &&
      pathnameArray[2] === "ileggelser" &&
      pathnameArray[3] === "behandles-innkreving"
    ) {
      history.push(
        sitePaths.innkreving.ileggelser.behandlesInnkreving.shortUrl
      );
    } else if (
      pathnameArray.length > 4 &&
      pathnameArray[1] === "innkreving" &&
      pathnameArray[2] === "ileggelser" &&
      pathnameArray[3] === "klar"
    ) {
      history.push(sitePaths.innkreving.ileggelser.feilet.shortUrl);
    } else if (
      pathnameArray.length > 4 &&
      pathnameArray[1] === "innkreving" &&
      pathnameArray[2] === "ileggelser" &&
      pathnameArray[3] === "feilet"
    ) {
      history.push(sitePaths.innkreving.ileggelser.feilet.shortUrl);
    } else if (
      pathnameArray.length > 4 &&
      pathnameArray[1] === "innkreving" &&
      pathnameArray[2] === "ileggelser" &&
      pathnameArray[3] === "vellykket"
    ) {
      history.push(sitePaths.innkreving.ileggelser.vellykket.shortUrl);
    } else if (
      pathnameArray.length > 4 &&
      pathnameArray[1] === "innkreving" &&
      pathnameArray[2] === "miljogebyr" &&
      pathnameArray[3] === "venter"
    ) {
      history.push(sitePaths.innkreving.miljogebyr.venter.shortUrl);
    } else if (
      pathnameArray.length > 4 &&
      pathnameArray[1] === "innkreving" &&
      pathnameArray[2] === "miljogebyr" &&
      pathnameArray[3] === "stoppet"
    ) {
      history.push(sitePaths.innkreving.miljogebyr.stoppet.shortUrl);
    } else if (
      pathnameArray.length > 4 &&
      pathnameArray[1] === "innkreving" &&
      pathnameArray[2] === "miljogebyr" &&
      pathnameArray[3] === "klar"
    ) {
      history.push(sitePaths.innkreving.miljogebyr.klar.shortUrl);
    } else if (
      pathnameArray.length > 4 &&
      pathnameArray[1] === "innkreving" &&
      pathnameArray[2] === "miljogebyr" &&
      pathnameArray[3] === "behandles-innkreving"
    ) {
      history.push(
        sitePaths.innkreving.miljogebyr.behandlesInnkreving.shortUrl
      );
    } else if (
      pathnameArray.length > 4 &&
      pathnameArray[1] === "innkreving" &&
      pathnameArray[2] === "miljogebyr" &&
      pathnameArray[3] === "feilet"
    ) {
      history.push(sitePaths.innkreving.miljogebyr.feilet.shortUrl);
    } else if (
      pathnameArray.length > 4 &&
      pathnameArray[1] === "innkreving" &&
      pathnameArray[2] === "miljogebyr" &&
      pathnameArray[3] === "vellykket"
    ) {
      history.push(sitePaths.innkreving.miljogebyr.vellykket.shortUrl);
    } else if (
      isIleggelseUnderVentilstillingDetailPage() &&
      pathnameArray[-1] === "toastui"
    ) {
      history.push(
        sitePaths.rapporter.ventilstillinger.detalj.shortUrl.getUrl(
          pathnameArray[3]
        )
      );
    } else if (
      isIleggelseUnderVentilstillingDetailPage() &&
      pathnameArray[-1] === "historikk"
    ) {
      history.push(
        sitePaths.rapporter.ventilstillinger.detalj.shortUrl.getUrl(
          pathnameArray[3]
        )
      );
    } else if (
      isIleggelseUnderVentilstillingDetailPage() &&
      pathnameArray[-3] === "bilder"
    ) {
      history.push(
        sitePaths.rapporter.ventilstillinger.detalj.shortUrl.getUrl(
          pathnameArray[3]
        )
      );
    } else if (isIleggelseUnderVentilstillingDetailPage()) {
      history.push(
        sitePaths.rapporter.ventilstillinger.detalj.shortUrl.getUrl(
          pathnameArray[3]
        )
      );
    } else if (
      isIleggelseUnderVentilstillingOverviewPage() &&
      pathnameArray[-1] === "toastui"
    ) {
      history.push(sitePaths.rapporter.ventilstillinger.shortUrl);
    } else if (
      isIleggelseUnderVentilstillingOverviewPage() &&
      pathnameArray[-1] === "historikk"
    ) {
      history.push(sitePaths.rapporter.ventilstillinger.shortUrl);
    } else if (
      isIleggelseUnderVentilstillingOverviewPage() &&
      pathnameArray[-3] === "bilder"
    ) {
      history.push(sitePaths.rapporter.ventilstillinger.shortUrl);
    } else if (isIleggelseUnderVentilstillingOverviewPage()) {
      history.push(sitePaths.rapporter.ventilstillinger.shortUrl);
    } else if (isVentilstillingDetailPage()) {
      history.push(sitePaths.rapporter.ventilstillinger.oversikt);
    } else {
      console.error("Ingen definert tilbakeknapp for denne siden");
    }
  };

  return handleClick;
};

const styles = {
  topHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 50,
    paddingRight: 50,
    marginBottom: 41,
  },
  badgeMakulert: { fontSize: 28 },
};

const DetaljPageHeader = ({
  pageName,
  pageId,
  isMakulert,
  makulertTekst,
  computedMatch,
  customCloseButtonText,
  customHeader,
}) => {
  const handleClickTilbakeknapp = useTilbakeknapp(computedMatch);

  const badgeText = makulertTekst ? makulertTekst : "Makulert";

  return (
    <div style={styles.topHeader}>
      {customHeader ? (
        customHeader
      ) : (
        <h1>
          {pageName} <strong>{pageId}</strong>
          {isMakulert && (
            <span style={styles.badgeMakulert}>
              &nbsp;
              <span className="badge-bym-red">{badgeText}</span>
            </span>
          )}
        </h1>
      )}
      <span className="no-print">
        <CloseButton
          customHandleClick={handleClickTilbakeknapp}
          text={
            customCloseButtonText ?? text.detailPage.closeButtonText(pageName)
          }
        />
      </span>
    </div>
  );
};

DetaljPageHeader.propTypes = {
  pageName: PropTypes.string.isRequired,
  pageId: PropTypes.string.isRequired,
};

export default DetaljPageHeader;

const REGEX_GUID =
  "[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}";

const isIleggelseUnderVentilstillingOverviewPage = () => {
  return (
    window.location.pathname.search(
      new RegExp(`^/rapporter/ventilstillinger/ileggelse/${REGEX_GUID}$`)
    ) !== -1
  );
};

const isIleggelseUnderVentilstillingDetailPage = () => {
  return (
    window.location.pathname.search(
      new RegExp(
        `^/rapporter/ventilstillinger/${REGEX_GUID}/ileggelse/${REGEX_GUID}$`
      )
    ) !== -1
  );
};

const isVentilstillingDetailPage = () => {
  return (
    window.location.pathname.search(
      new RegExp(`^/rapporter/ventilstillinger/${REGEX_GUID}$`)
    ) !== -1
  );
};
