import { ILEGGELSE_SERVICE_BASE_URL } from "../config";

// - Miljogebyrer
export const GET_MILJOGEBYRER_URL = `${ILEGGELSE_SERVICE_BASE_URL}/miljogebyrer`;
export const GET_MILJOGEBYR_KOPI_URL = (miljogebyrId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/miljogebyrer/${miljogebyrId}/kopi`;
export const GET_MILJOGEBYR_URL = (miljogebyrId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/miljogebyrer/${miljogebyrId}`;
export const PUT_MILJOGEBYRER_URL = (miljogebyrId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/miljogebyrer/${miljogebyrId}`;
export const GET_MILJOGEBYRER_BILDER_BASE_URL = (miljogebyrId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/miljogebyrer/${miljogebyrId}/bilder`;
export const GET_MILJOGEBYR_BILDE_BASE64_URL = (miljogebyrId, bildeId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/miljogebyrer/${miljogebyrId}/bilder/${bildeId}/base64`;
export const POST_MILJOGEBYRER_BILDER_URL = (miljogebyrId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/miljogebyrer/${miljogebyrId}/bilder`;
export const PUT_MILJOGEBYRER_BILDER_URL = (miljogebyrId, bildeId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/miljogebyrer/${miljogebyrId}/bilder/${bildeId}`;
export const DELETE_MILJOGEBYRER_BILDER_URL = (miljogebyrId, bildeId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/miljogebyrer/${miljogebyrId}/bilder/${bildeId}`;
export const PUT_MILJOGEBYR_IS_KVALITETSIKRET_URL = (miljogebyrId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/miljogebyrer/${miljogebyrId}/isKvalitetsikret`;
export const GET_MILJO_MAKULERINGER_URL = (miljogebyrId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/miljogebyrer/${miljogebyrId}/miljoMakuleringer`;
export const POST_MILJO_MAKULERING_URL = (miljogebyrId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/miljogebyrer/${miljogebyrId}/miljoMakuleringer`;
export const GET_MILJOGEBYRER_HISTORIKK_URL = (miljogebyrId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/miljogebyrer/${miljogebyrId}/historikk`;
export const GET_MILJOGEBYRER_MILJOGEBYRKLAGER = (miljogebyrId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/miljogebyrer/${miljogebyrId}/MiljogebyrKlager`;
export const PUT_MILJOGEBYRER_SETT_KLAR_TIL_INE_URL = (miljogebyrId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/miljogebyrer/${miljogebyrId}/settklartilkemner`;

// MiljoMakuleringer
export const DELETE_MILJO_MAKULERINGER_URL = (miljoMakuleringId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/miljoMakuleringer/${miljoMakuleringId}`;

// - MiljoEttersendelser
export const GET_MILJOGEBYR_ETTERSENDELSER_URL = (miljogebyrId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/miljogebyrer/${miljogebyrId}/miljoEttersendelser`;
export const POST_MILJOGEBYR_ETTERSENDELSER_URL = (miljogebyrId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/miljogebyrer/${miljogebyrId}/miljoEttersendelser`;

export const PUT_MILJOGEBYR_ETTERSENDELSER_URL = (miljogebyrId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/miljogebyrer/${miljogebyrId}/miljoEttersendelser`;

export const PUT_MILJOGEBYR_TIL_ETTERSENDELSE_URL = (miljogebyrId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/miljogebyrer/${miljogebyrId}/isTilEttersendelse`;

export const POST_MILJOGEBYR_ETTERSENDELSEBREV_URL = (
  miljogebyrEttersendelseId
) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/miljoEttersendelser/${miljogebyrEttersendelseId}/miljoEttersendelseBrev`;

// miljogebyrKommentarer
export const CREATE_MILJOGEBYR_KOMMENTAR_URL = (miljogebyrId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/miljogebyrer/${miljogebyrId}/kommentarer`;

export const GET_ALL_MILJOGEBYR_KOMMENTARER_URL = (miljogebyrId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/miljogebyrer/${miljogebyrId}/kommentarer`;

export const GET_SINGLE_MILJOGEBYR_KOMMENTAR_URL = (
  miljogebyrId,
  kommentarId
) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/miljogebyrer/${miljogebyrId}/kommentarer/${kommentarId}`;

export const UPDATE_MILJOGEBYR_KOMMENTAR_URL = (miljogebyrId, kommentarId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/miljogebyrer/${miljogebyrId}/kommentarer/${kommentarId}`;

// - Miljoettersendelser ettersendelsebrev
export const GET_MILJOGEBYRETTERSENDELSEBREV_ETTERSENDELSEBREVPDF_URL = (
  miljoEttersendelseBrevId
) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/miljoGebyrEttersendelseBrev/${miljoEttersendelseBrevId}/EttersendelseBrevPdf`;

export const GET_ALL_MILJO_ETTERSENDELSEBREVER_PDF_URL = `${ILEGGELSE_SERVICE_BASE_URL}/miljoettersendelsebrever`;

// - Miljogebyr rettelser
export const GET_MILJO_RETTELSER_URL = (miljogebyrId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/miljogebyrer/${miljogebyrId}/miljoRettelser`;

// - Miljoleveringstyper
export const GET_MILJOLEVERINGSTYPER_URL = `${ILEGGELSE_SERVICE_BASE_URL}/miljoleveringstyper`;

// - Miljokontroller
export const GET_MILJOKONTROLLER_URL = `${ILEGGELSE_SERVICE_BASE_URL}/miljokontroller`;

export const GET_MILJOKONTROLL_URL = (miljokontrollId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/miljokontroller/${miljokontrollId}`;

export const PUT_MILJOKONTROLL_AVSLUTT_URL = (miljokontrollId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/miljokontroller/${miljokontrollId}/avslutt`;

export const GET_MILJOKONTRLL_OBSERVASJON_BILDE_BASE64_URL = (
  miljokontrollId,
  observasjonId,
  bildeId
) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/miljokontroller/${miljokontrollId}/observasjoner/${observasjonId}/bilder/${bildeId}/base64`;

export const GET_MILJOKONTROLLER_BILDER_BASE_URL = (miljokontrollId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/miljokontroller/${miljokontrollId}/bilder`;

export const POST_MILJOKONTROLL_OBSERVASJON_BILDE_URL = (
  miljokontrollId,
  observasjonId
) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/miljokontroller/${miljokontrollId}/observasjoner/${observasjonId}/bilder`;

export const PUT_MILJOKONTROLL_OBSERVASJON_BILDE_URL = (
  bildeId,
  miljokontrollId,
  observasjonId
) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/miljokontroller/${miljokontrollId}/observasjoner/${observasjonId}/bilder/${bildeId}`;

export const GET_MILJOKONTROLL_KOMMENTARER_BASE_URL = (miljokontrollId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/miljokontroller/${miljokontrollId}/kommentarer`;

export const DELETE_MILJOKONTROLL_OBSERVASJON_BILDE_URL = (
  bildeId,
  miljokontrollId,
  observasjonId
) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/miljokontroller/${miljokontrollId}/observasjoner/${observasjonId}/bilder/${bildeId}`;

export const GET_MILJOKONTROLL_HISTORIKK = (miljokontrollId) =>
`${ILEGGELSE_SERVICE_BASE_URL}/miljokontroller/${miljokontrollId}/historikk`;

// - Internkoder miljøgebyr
export const GET_INTERNKODER_MILJOGEBYR_URL = `${ILEGGELSE_SERVICE_BASE_URL}/miljointernkoder`;
export const POST_INTERNKODER_MILJOGEBYR_URL = `${ILEGGELSE_SERVICE_BASE_URL}/miljointernkoder`;
export const PUT_INTERNKODER_MILJOGEBYR_URL = (id) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/miljointernkoder/${id}`;

export const GET_SINGLE_INTERNKODER_MILJOGEBYR_URL = (id) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/miljointernkoder/${id}`;
