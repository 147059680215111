import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Modal from "../../../Common/ModalGeneric";
import TextArea from "../../../Common/Forms/TextArea";

import {
  getIleggelse as getIleggelseAction,
  getIleggelseKlager as getIleggelseKlagerAction,
} from "../../../../actions/ileggelserActions";
import { postIleggelserForsinketMakulering as postIleggelserForsinketMakuleringAction } from "../../../../actions/ileggelseForsinketMakuleringerActions";

import { required } from "../../../../utils/inputValidationUtils";

class ForsinketMakuleringContainer extends React.Component {
  static propTypes = {
    ileggelse: PropTypes.object,
    postIleggelserForsinketMakulering: PropTypes.func.isRequired,
    getIleggelse: PropTypes.func.isRequired,
    getIleggelseKlager: PropTypes.func.isRequired,
    reloadHistorikk: PropTypes.func.isRequired,
  };

  state = {
    open: false,
    disabled: false,
    showSubmitSpinner: false,
    kommentar: "",
    kommentarValidationText: null,
  };

  IsValid = () => {
    let kommentarValidationText = required("Kommentar", this.state.kommentar);

    this.setState({
      kommentarValidationText: kommentarValidationText,
    });

    return !kommentarValidationText;
  };

  handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;

    this.setState(() => {
      return { [name]: value };
    });
  };

  handleApiCall = async (event) => {
    event.preventDefault();

    const {
      ileggelse,
      postIleggelserForsinketMakulering,
      getIleggelse,
      getIleggelseKlager,
      reloadHistorikk,
    } = this.props;
    const { kommentar } = this.state;

    if (this.IsValid()) {
      this.setState({ showSubmitSpinner: true });
      postIleggelserForsinketMakulering(ileggelse.id, kommentar)
        .then(() => {
          getIleggelse(ileggelse.id);
          getIleggelseKlager(ileggelse.id);
          reloadHistorikk();
          this.closeModal();
        })
        .catch(() => {
          this.setState({ showSubmitSpinner: false });
        });
    }
  };
  openModal = () => {
    this.setState({ open: true });
  };

  closeModal = () => {
    this.setState({
      open: false,
      kommentarValidationText: null,
      showSubmitSpinner: false,
    });
  };

  showForsinketMakulering = () => {
    const { ileggelse } = this.props;
    if (
      ileggelse &&
      ileggelse.forsinketMakuleringStatus &&
      ileggelse.forsinketMakuleringStatus.createPossible
    ) {
      return true;
    }
    return false;
  };

  render() {
    const { ileggelse } = this.props;
    const { open, disabled, showSubmitSpinner, kommentarValidationText } =
      this.state;

    const ileggelseId = ileggelse && ileggelse.id;

    if (this.showForsinketMakulering()) {
      return (
        <span style={{ marginLeft: 20 }}>
          <Modal
            id={ileggelseId}
            modalTitle={`Forsinket makulering av ileggelse ${ileggelse.ileggelsesnummer}`}
            open={open}
            closeModal={this.closeModal}
            openModal={this.openModal}
            popUpButtonText="Forsinket makulering"
            isButtonLink
            submit={this.handleApiCall}
            disabled={disabled}
            submitButtonText="Send vedtak"
            showSubmitSpinner={showSubmitSpinner}
            popUpButtonType="button"
          >
            <React.Fragment>
              <p>
                Vil du gjøre en forsinket makulering av ileggelse{" "}
                {ileggelse.ileggelsesnummer}?
              </p>
              <TextArea
                label="Forsinket makuleringsårsak"
                name="kommentar"
                onChange={this.handleChange}
                validationError={kommentarValidationText}
              />
            </React.Fragment>
          </Modal>
        </span>
      );
    }
    return <span />;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ileggelse: state.ileggelse,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    postIleggelserForsinketMakulering: (ileggelseId, kommentar) =>
      dispatch(postIleggelserForsinketMakuleringAction(ileggelseId, kommentar)),
    getIleggelse: (ileggelseId) => dispatch(getIleggelseAction(ileggelseId)),
    getIleggelseKlager: (ileggelseId) =>
      dispatch(getIleggelseKlagerAction(ileggelseId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForsinketMakuleringContainer);
