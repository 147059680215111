import { orderBy } from "lodash";
import axios from "../services/axios";
import {
  formatDateHavingNorwegianDateTime,
  formatTimeHavingNorwegianDateTime,
} from "../utils/datetimeUtils";

const getImagesKopiDict = (imagesKopi) => {
  let imageKopiDict = {};
  imagesKopi.forEach((imageKopi) => {
    if (imageKopi.orginalMiljoKontrollObservasjonBildeId) {
      const imageKopierForOrginalMiljoKontrollObservasjonBildeId =
        imageKopiDict[imageKopi.orginalMiljoKontrollObservasjonBildeId]
          ? [
              ...imageKopiDict[
                imageKopi.orginalMiljoKontrollObservasjonBildeId
              ],
              imageKopi,
            ]
          : [imageKopi];
      imageKopiDict = {
        ...imageKopiDict,
        [imageKopi.orginalMiljoKontrollObservasjonBildeId]:
          imageKopierForOrginalMiljoKontrollObservasjonBildeId,
      };
    }
  });

  return imageKopiDict;
};

export const getDatoSortedImages = (images) => {
  if (images && images.length > 0) {
    images = orderBy(images, ["mottattDato"], ["asc"]);
    const imagesOrginal = images.filter(
      (image) => !image.orginalMiljoKontrollObservasjonBildeId
    );
    const imagesKopi = images.filter(
      (image) => image.orginalMiljoKontrollObservasjonBildeId
    );
    const imagesKopiDict = getImagesKopiDict(imagesKopi);

    let datoSortedImageObject = {};
    imagesOrginal.forEach((image) => {
      if (image.orginalMiljoKontrollObservasjonBildeId) {
        return;
      }

      let alreadyExistingImagesOrginalForDato = datoSortedImageObject[
        formatDateHavingNorwegianDateTime(image.mottattDato)
      ]
        ? [
            ...datoSortedImageObject[
              formatDateHavingNorwegianDateTime(image.mottattDato)
            ],
          ]
        : [];
      const imageKopier = imagesKopiDict[image.id];
      const imagesToInsert =
        imageKopier && imageKopier.length > 0
          ? [image, ...imageKopier]
          : [image];
      datoSortedImageObject = {
        ...datoSortedImageObject,
        [formatDateHavingNorwegianDateTime(image.mottattDato)]: [
          ...alreadyExistingImagesOrginalForDato,
          ...imagesToInsert,
        ],
      };
    });

    const datoSortedImageObjectKeys = Object.keys(datoSortedImageObject);
    const datoSortedImageObjectValues = Object.values(datoSortedImageObject);
    let datoSortedImages = [];
    for (let i = 0; i < datoSortedImageObjectValues.length; i++) {
      const dato = datoSortedImageObjectKeys[i];
      const images = datoSortedImageObjectValues[i];
      let earliestTime = "";
      let latestTime = "";
      for (let j = 0; j < images.length; j++) {
        const image = images[j];
        if (
          !image.orginalMiljoKontrollObservasjonBildeId &&
          (!earliestTime ||
            formatTimeHavingNorwegianDateTime(image.mottattDato) < earliestTime)
        ) {
          earliestTime = formatTimeHavingNorwegianDateTime(image.mottattDato);
        }
        if (
          !image.orginalMiljoKontrollObservasjonBildeId &&
          (!latestTime ||
            formatTimeHavingNorwegianDateTime(image.mottattDato) > latestTime)
        ) {
          latestTime = formatTimeHavingNorwegianDateTime(image.mottattDato);
        }
      }

      datoSortedImages = [
        ...datoSortedImages,
        {
          dato,
          tidspan: `${earliestTime} - ${latestTime}`,
          images,
        },
      ];
    }

    return datoSortedImages;
  }
  return [];
};

/**
 * Get a reusable/cached object url for a base64 data url (i.e. data:image/png;base64,...)
 *
 * @export
 * @param {*} dataUrl
 * @returns
 */
export async function getBase64ObjectURL(dataUrl) {
  const response = await axios.get(dataUrl, { responseType: "blob" });
  let blob = response.data;

  return URL.createObjectURL(blob);
}

/**
 * Get a base64 data url for a previously created object url
 *
 * @export
 * @param {*} objectUrl
 * @returns
 */
export async function getBase64DataURL(objectUrl) {
  const response = await axios.get(objectUrl, { responseType: "blob" });
  let blob = response.data;

  return await new Promise((resolve, reject) => {
    let fileReader = new FileReader();
    fileReader.addEventListener("load", (event) =>
      resolve(event.target.result)
    );
    fileReader.addEventListener("error", () => reject(fileReader.error));
    fileReader.readAsDataURL(blob);
  });
}

export const dataURLToImage = (dataURL, imageOnloadCallback) => {
  let image = new Image();
  image.src = dataURL;
  image.onload = () => {
    imageOnloadCallback(image);
  };
};

export const imageToCanvas = (image) => {
  var canvas = document.createElement("canvas");
  canvas.width = image.naturalWidth;
  canvas.height = image.naturalHeight;
  canvas.getContext("2d").drawImage(image, 0, 0);

  return canvas;
};

export const getObservasjonIdFromMiljokontrollerBilder = (
  miljokontrollId,
  bildeId,
  miljokontrollerBilder
) => {
  const observasjoner = miljokontrollerBilder[miljokontrollId];
  for (let index = 0; index < observasjoner.length; index++) {
    const observasjon = observasjoner[index];
    for (let index = 0; index < observasjon.images.length; index++) {
      const image = observasjon.images[index];
      if (image.id === bildeId) {
        return image.observasjonId;
      }
    }
  }

  return undefined;
};
