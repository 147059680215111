import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Bilder from "../../Detalj/Bilder";
import {
  getIleggelse,
  getIleggelseBilder,
  getIleggelseHistorikk,
  putIleggelserBilder,
  deleteIleggelserBilder,
} from "../../../../actions/ileggelserActions";
import { authorized } from "../../../../utils/authorizationUtils";
import { bypatruljen, juridisk } from "../../../../constants/roles";

class BildContainer extends React.Component {
  static propTypes = {
    ileggelseId: PropTypes.string.isRequired,
    BilderButtonsContainer: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.object,
    ]),
    checkbox: PropTypes.bool,
    bilder: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string, isGoodQuality: PropTypes.bool })
    ),
    thumbnailBilder: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string, isGoodQuality: PropTypes.bool })
    ),
    lightboxBilder: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string, isGoodQuality: PropTypes.bool })
    ),
  };

  state = {
    bilder: [],
    thumbnailBilder: [],
    lightboxBilder: [],
  };

  isAuthorizedToPostAndPutBilder = () =>
    authorized([bypatruljen.saksbehandler, bypatruljen.saksbehandleradmin]);

  isAuthorizedForToastUi = () =>
    authorized([
      bypatruljen.saksbehandler,
      bypatruljen.saksbehandleradmin,
      juridisk.saksbehandler,
    ]);

  isAuthorizedToDeleteImage = () =>
    authorized([
      bypatruljen.saksbehandler,
      bypatruljen.saksbehandleradmin,
      juridisk.saksbehandler,
    ]);

  async componentDidMount() {
    this.props
      .getIleggelseBilder(
        this.props.ileggelseId,
        null,
        {
          s3BucketCategory: "ileggelser_thumbnail",
        },
        false
      )
      .then((thumbnailBilder) => {
        this.setState(() => ({
          thumbnailBilder: thumbnailBilder,
        }));
      })
      .catch(() => {
        // Før brukte vi ikke thumbnails utan kun orginalbilde. Så dette er et førsøk på en fallback.
        this.props.getIleggelseBilder(this.props.ileggelseId).then((bilder) => {
          this.setState(() => ({ bilder: bilder }));
        });
      });

    this.props
      .getIleggelseBilder(
        this.props.ileggelseId,
        null,
        {
          s3BucketCategory: "ileggelser_lightbox",
        },
        false
      )
      .then((lightboxBilder) => {
        this.setState(() => ({
          lightboxBilder: lightboxBilder,
        }));
      });
  }

  async componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.bilder) !== JSON.stringify(this.props.bilder)
    ) {
      this.props
        .getIleggelseBilder(
          this.props.ileggelseId,
          null,
          {
            s3BucketCategory: "ileggelser_thumbnail",
          },
          false
        )
        .then((thumbnailBilder) => {
          this.setState(() => ({
            thumbnailBilder: thumbnailBilder,
          }));
        })
        .catch(() => {
          // Før brukte vi ikke thumbnails utan kun orginalbilde. Så dette er et førsøk på en fallback.
          this.props
            .getIleggelseBilder(this.props.ileggelseId)
            .then((bilder) => {
              this.setState(() => ({ bilder: bilder }));
            });
        });

      this.props
        .getIleggelseBilder(
          this.props.ileggelseId,
          null,
          {
            s3BucketCategory: "ileggelser_lightbox",
          },
          false
        )
        .then((lightboxBilder) => {
          this.setState(() => ({
            lightboxBilder: lightboxBilder,
          }));
        });
    }
  }

  handleDeleteImageClick = async (bildeId) => {
    const {
      ileggelseId,
      deleteIleggelserBilder,
      getIleggelseHistorikk,
      getIleggelse,
    } = this.props;
    await deleteIleggelserBilder(ileggelseId, bildeId);
    getIleggelseHistorikk(ileggelseId);
    await getIleggelse(ileggelseId);
  };

  handleCheckboxChange = (event) => {
    const { ileggelseId, getIleggelseHistorikk } = this.props;
    const { thumbnailBilder } = this.state;

    const bildeId = event.target.id;
    const bildeGoodQuality = event.target.checked;

    if (thumbnailBilder.length > 0) {
      this.setState((state) => ({
        thumbnailBilder: state.thumbnailBilder.map((bild) =>
          bild.id === bildeId
            ? { ...bild, isGoodQuality: bildeGoodQuality }
            : bild
        ),
      }));
    } else {
      this.setState((state) => ({
        bilder: state.bilder.map((bild) =>
          bild.id === bildeId
            ? { ...bild, isGoodQuality: bildeGoodQuality }
            : bild
        ),
      }));
    }
    // this.setState((state) => ({
    //   bilder: state.bilder.map((bild) =>
    //     bild.id === bildeId
    //       ? { ...bild, isGoodQuality: bildeGoodQuality }
    //       : bild
    //   ),
    // }));

    this.props
      .putIleggelserBilder(
        this.props.ileggelse.id,
        event.target.id,
        event.target.checked
      )
      .then(() => {
        getIleggelseHistorikk(ileggelseId);
      })
      .catch(() => {
        if (thumbnailBilder.length > 0) {
          this.setState((state) => ({
            thumbnailBilder: state.thumbnailBilder.map((bild) =>
              bild.id === bildeId
                ? { ...bild, isGoodQuality: bildeGoodQuality }
                : bild
            ),
          }));
        } else {
          this.setState((state) => ({
            bilder: state.bilder.map((bild) =>
              bild.id === bildeId
                ? { ...bild, isGoodQuality: bildeGoodQuality }
                : bild
            ),
          }));
        }
      });
  };

  isLoadingImages = () =>
    (this.state.thumbnailBilder &&
      this.state.thumbnailBilder.length > 0 &&
      this.state.lightboxBilder &&
      this.props.ileggelse &&
      this.props.ileggelse.bilder &&
      this.state.thumbnailBilder.length !==
        this.props.ileggelse.bilder.length &&
      this.state.lightboxBilder.length !==
        this.props.ileggelse.bilder.length) ||
    (this.state.bilder &&
      this.state.bilder.length > 0 &&
      this.props.ileggelse &&
      this.state.bilder.length !== this.props.ileggelse.bilder.length);

  render() {
    const { thumbnailBilder, lightboxBilder, bilder } = this.state;

    const imageArray = thumbnailBilder.length > 0 ? thumbnailBilder : bilder;
    const lightboxImages = lightboxBilder.length > 0 ? lightboxBilder : bilder;

    if (this.props.checkbox && this.isAuthorizedToPostAndPutBilder()) {
      return (
        <section className="detalj-ileggelse-section-margin">
          <Bilder
            id={this.props.ileggelseId}
            bilderButtonsContainer={
              this.isAuthorizedToPostAndPutBilder()
                ? this.props.BilderButtonsContainer
                : null
            }
            isLoadingImages={this.isLoadingImages()}
            imageArray={imageArray}
            lightboxImages={lightboxImages}
            handleDeleteImageClick={this.handleDeleteImageClick}
            handleCheckboxChange={this.handleCheckboxChange}
            checkboxText={"Til ettersendelse"}
            isAuthorizedForToastUi={this.isAuthorizedForToastUi()}
            isAuthorizedToDeleteImage={this.isAuthorizedToDeleteImage()}
          />
        </section>
      );
    } else {
      return (
        <section className="detalj-ileggelse-section-margin">
          <Bilder
            id={this.props.ileggelseId}
            bilderButtonsContainer={
              this.isAuthorizedToPostAndPutBilder()
                ? this.props.BilderButtonsContainer
                : null
            }
            isLoadingImages={this.isLoadingImages()}
            handleDeleteImageClick={this.handleDeleteImageClick}
            imageArray={imageArray}
            lightboxImages={lightboxImages}
            isAuthorizedForToastUi={this.isAuthorizedForToastUi()}
          />
        </section>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    ileggelse: state.ileggelse,
    bilder:
      state.ileggelse &&
      state.ileggelse.bilder &&
      state.ileggelse.bilder.map((bild) => {
        return { id: bild.id, isGoodQuality: bild.isGoodQuality };
      }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getIleggelse,
      getIleggelseBilder,
      getIleggelseHistorikk,
      putIleggelserBilder,
      deleteIleggelserBilder,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BildContainer);
