import { connect } from "react-redux";
import TilBehandling from "./TilBehandling";
import { getMiljogebyrTilBehandling as getMiljogebyrTilBehandlingAction } from "../../../actions/miljogebyrTilBehandlingActions";
import { getAllMiljogebyrEttersendelsebreverPdf } from "../../../actions/miljoEttersendelsebrevActions";

const mapStateToProps = (state) => {
  return {
    miljogebyrTilBehandling: state.miljogebyrTilBehandling,
    miljogebyrTilBehandlingFilterForm:
      state.forms.miljogebyrTilBehandlingFilterForm,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMiljogebyrTilBehandling: (params, page, count) =>
      dispatch(getMiljogebyrTilBehandlingAction(params, page, count)),
    getAllMiljogebyrEttersendelsebreverPdf: (miljogebyrId) =>
      dispatch(getAllMiljogebyrEttersendelsebreverPdf(miljogebyrId)),
  };
};

const mergeProps = (stateProps, dispatchProps) => {
  let page =
    stateProps.miljogebyrTilBehandling &&
    stateProps.miljogebyrTilBehandling.page;
  let count =
    stateProps.miljogebyrTilBehandling &&
    stateProps.miljogebyrTilBehandling.count;
  return {
    page,
    count,
    miljogebyrTilBehandlingItems:
      stateProps.miljogebyrTilBehandling &&
      stateProps.miljogebyrTilBehandling.items,
    miljogebyrTilBehandlingFilterForm:
      stateProps.miljogebyrTilBehandlingFilterForm,
    getMiljogebyrTilBehandling: () =>
      dispatchProps.getMiljogebyrTilBehandling({}, page, count),
    getAllMiljogebyrEttersendelsebreverPdf: (ettersendelseId) =>
      dispatchProps.getAllMiljogebyrEttersendelsebreverPdf(ettersendelseId),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(TilBehandling);
