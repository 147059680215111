import { connect } from "react-redux";

import MiljokontrollOversiktFilter from "./MiljokontrollOversiktFilter";

import {
  handleChangeAll as handleChangeAllAction,
  handleChangeToInitState as handleChangeToInitStateAction,
} from "../../../actions/handleChangeAction";
import { getMiljokontroller as getMiljokontrollerAction } from "../../../actions/miljokontrollerActions";
import { getOvertredelseskoderMiljogebyr as getOvertredelseskoderMiljogebyrAction } from "../../../actions/overtredelseskoderMiljogebyrActions";

import { getOvertredelseskoder } from "../../../selectors/miljogebyrTilBehandlingSelector";

import { mergeDateAndTimeForFromAndTo } from "../../../utils/fromToDateTimeUtils";
import * as inputValidation from "../../../utils/inputValidationUtils";
import { replaceWhitespacesWithEmptyStrings } from "../../../utils/sanitizationUtils";

const mapStateToProps = (state) => ({
  miljokontrollOversiktFilterForm: state.forms.miljokontrollOversiktFilterForm,
  overtredelseskoder: getOvertredelseskoder(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleChangeAll: (payload) =>
    dispatch(
      handleChangeAllAction("MILJOKONTROLL_OVERSIKT_FILTER_FORM", payload)
    ),
  handleChangeToInitState: () =>
    dispatch(
      handleChangeToInitStateAction("MILJOKONTROLL_OVERSIKT_FILTER_FORM")
    ),
  getOvertredelseskoderMiljogebyr: () =>
    dispatch(getOvertredelseskoderMiljogebyrAction()),
  getMiljokontroller: (params) => dispatch(getMiljokontrollerAction(params)),
});

const handleBlur = (e, filterState, setFilterState) => {
  const name = e.target.name;
  const value = replaceWhitespacesWithEmptyStrings(e.target.value);
  setFilterState({ ...filterState, [name]: value });
};

const isValid = (
  filterState,
  validationErrorState,
  setValidationErrorState
) => {
  const dateTimeValidationError =
    inputValidation.getDateTimeValidationError(filterState);
  setValidationErrorState({
    ...validationErrorState,
    ...dateTimeValidationError,
  });
  let isValid = Object.values(dateTimeValidationError).every((value) => !value);
  return isValid;
};

const handleSubmit = (
  e,
  filterState,
  validationErrorState,
  setValidationErrorState,
  setShowSpinnerState,
  handleChangeAll,
  getMiljokontroller
) => {
  e.preventDefault();

  const fraDatoAndTilDatoQueryData = mergeDateAndTimeForFromAndTo(
    filterState.fraDato,
    filterState.fraTid,
    filterState.tilDato,
    filterState.tilTid
  );
  if (isValid(filterState, validationErrorState, setValidationErrorState)) {
    setShowSpinnerState(true);
    getMiljokontroller({
      ...filterState,
      fraDato: fraDatoAndTilDatoQueryData.fraDato,
      tilDato: fraDatoAndTilDatoQueryData.tilDato,
    })
      .then(() => {
        handleChangeAll({
          ...filterState,
        });
        setShowSpinnerState(false);
      })
      .catch(() => setShowSpinnerState(false));
  }
};

const getOvertredelseskodeParagraf = (id, overtredelseskoder) => {
  let matchingOvertredelseskode = undefined;
  if (overtredelseskoder) {
    matchingOvertredelseskode = overtredelseskoder.find(
      (overtredelseskode) => overtredelseskode.id === id
    );
  }
  if (matchingOvertredelseskode) {
    return matchingOvertredelseskode.paragraf;
  }
  return null;
};

const mergeProps = (stateProps, dispatchProps) => ({
  miljokontrollOversiktFilterForm: stateProps.miljokontrollOversiktFilterForm,
  overtredelseskoder: stateProps.overtredelseskoder,
  getOvertredelseskodeParagraf: (id) =>
    getOvertredelseskodeParagraf(id, stateProps.overtredelseskoder),
  handleBlur: (e, filterState, setFilterState) =>
    handleBlur(e, filterState, setFilterState),
  handleSubmit: (
    e,
    filterState,
    validationErrorState,
    setValidationErrorState,
    setShowSpinnerState
  ) => {
    handleSubmit(
      e,
      filterState,
      validationErrorState,
      setValidationErrorState,
      setShowSpinnerState,
      dispatchProps.handleChangeAll,
      dispatchProps.getMiljokontroller
    );
  },
  handleChangeToInitState: () => dispatchProps.handleChangeToInitState(),
  getOvertredelseskoderMiljogebyr: () =>
    dispatchProps.getOvertredelseskoderMiljogebyr(),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(MiljokontrollOversiktFilter);
