import React, { PureComponent } from "react";
import { Tab, Tabs as BsTabs } from "react-bootstrap";
import PropTypes from "prop-types";
import { map, filter } from "lodash";
import "./Tabs.css";
import closeIcon from "../../images/close-icon.png";

export default class Tabs extends PureComponent {
  static propTypes = {
    id: PropTypes.string,
    titles: PropTypes.array,
    defaultKey: PropTypes.number,
    selectedTab: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    bsClass: PropTypes.string,
    onClickCustomButton: PropTypes.func,
    onClickTitle: PropTypes.func,
    titleHasCloseIcon: PropTypes.bool,
    onCloseIconClick: PropTypes.func,
    onSelectTab: PropTypes.func,
    authorizedToRemove: PropTypes.bool,
  };
  getChildByTitleName = (titleName) => {
    return filter(this.props.children, (child) => {
      return child.key === titleName.toString();
    });
  };

  renderTitleWithCloseIcon = (index, title) => {
    const { titles, onCloseIconClick, onClickTitle, authorizedToRemove } =
      this.props;
    return (
      <span className="tabTitle">
        <span
          onClick={(e) => onClickTitle(e, index, titles[index])}
          role="button"
        >
          {title}
        </span>
        {authorizedToRemove && (
          <span
            style={{ marginLeft: 5, float: "right" }}
            role="button"
            onClick={(e) => onCloseIconClick(e, titles[index])}
          >
            <img src={closeIcon} alt="closeTab" />
          </span>
        )}
      </span>
    );
  };
  render() {
    const { id, titles, bsClass, titleHasCloseIcon, selectedTab, onSelectTab } =
      this.props;
    if (titles.length === 0) return null;
    return (
      <BsTabs
        activeKey={selectedTab ? selectedTab : 0}
        onSelect={onSelectTab}
        className={bsClass}
        id={id ? id : "GenerictTabsDiv"}
      >
        {!titleHasCloseIcon &&
          map(titles, (t, index) => (
            <Tab
              key={index}
              eventKey={index}
              title={
                <span className="tabTitle" role="button">
                  {t}
                </span>
              }
            >
              {this.getChildByTitleName(titles[index])}
            </Tab>
          ))}
        {titleHasCloseIcon &&
          map(titles, (t, index) => (
            <Tab
              key={index}
              eventKey={index}
              title={this.renderTitleWithCloseIcon(index, t)}
            >
              {this.getChildByTitleName(titles[index])}
            </Tab>
          ))}
      </BsTabs>
    );
  }
}
