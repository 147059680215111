import React from "react";
import PropTypes from "prop-types";
import { Row, Col, FormGroup, FormLabel, FormControl } from "react-bootstrap";
import TextInput from "../../Common/Forms/TextInput";
import DropDown from "../../Common/Forms/DropDown";
import DropDownListSearch from "../../Common/Forms/DropdownListSearchable";
import TextArea from "../../Common/Forms/TextArea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

export const EndreMiljogebyrModalForm = (props) => {
  return (
    <form>
      {props.warningText && (
        <Row>
          <Col xl={12}>
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              style={{ color: "#C12618" }}
              size="lg"
            />
            <p style={{ color: "#C12618" }}>{props.warningText}</p>
          </Col>
        </Row>
      )}
      <Row>
        <Col xl={4}>
          <TextInput
            label="Gatenavn"
            value={props.gateNavn}
            name="gateNavn"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
          />
        </Col>
        <Col xl={2}>
          <TextInput
            label="Gatenummer"
            value={props.gateNummer}
            name="gateNummer"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
          />
        </Col>
        <Col xl={2}>
          <TextInput
            label="Oppgang"
            value={props.oppgang}
            name="oppgang"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
          />
        </Col>
        <Col xl={4}>
          <TextInput
            label="Bydel"
            value={props.bydel}
            name="bydel"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
          />
        </Col>
      </Row>
      <Row>
        <Col xl={4}>
          <TextInput
            label="Ved gate"
            value={props.vedGate}
            name="vedGate"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
          />
        </Col>
        <Col xl={2}>
          <TextInput
            label="Ved gatenummer"
            value={props.vedGateNummer}
            name="vedGateNummer"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
          />
        </Col>
        <Col xl={2}>
          <TextInput
            label="Ved oppgang"
            value={props.vedOppgang}
            name="vedOppgang"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
          />
        </Col>
      </Row>
      <Row>
        <Col xl={6}>
          <Row>
            <Col xl={4}>
              <DropDownListSearch
                label="Overtredelseskode"
                name="overtredelse1"
                placeholder="Søk etter kode"
                items={props.overtredelseskoder}
                selected={props.overtredelse1}
                handleChange={props.handleChange}
              />
            </Col>
            <Col xl={4}>
              <label htmlFor="overtredelse2" className="visuallyhidden">
                Overtredelse 2
              </label>
              <DropDownListSearch
                name="overtredelse2"
                placeholder="Søk etter kode"
                title={"Velg kode"}
                items={[{ id: "", navn: "Velg kode" }].concat(
                  props.overtredelseskoder
                )}
                selected={props.overtredelse2}
                handleChange={props.handleChange}
              />
            </Col>
            <Col xl={4}>
              <label htmlFor="overtredelse3" className="visuallyhidden">
                Overtredelse 3
              </label>
              <DropDownListSearch
                name="overtredelse3"
                placeholder="Søk etter kode"
                title={"Velg kode"}
                items={[{ id: "", navn: "Velg kode" }].concat(
                  props.overtredelseskoder
                )}
                selected={props.overtredelse3}
                handleChange={props.handleChange}
              />
            </Col>
          </Row>
        </Col>
        <Col xl={6}>
          <Row>
            <Col xl={4}>
              <DropDown
                id="internkode1"
                name="internkode1"
                label="Internkode"
                title="Velg internkode"
                selected={props.internkode1}
                items={props.internkoder}
                onChange={props.handleChange}
              />
            </Col>
            <Col xl={4}>
              <label htmlFor="internkode2" className="visuallyhidden">
                Internkode2
              </label>
              <DropDown
                id="internkode2"
                name="internkode2"
                title="Velg internkode"
                selected={props.internkode2}
                items={props.internkoder}
                onChange={props.handleChange}
              />
            </Col>
            <Col xl={4}>
              <label htmlFor="internkode3" className="visuallyhidden">
                Internkode3
              </label>
              <DropDown
                id="internkode3"
                name="internkode3"
                title="Velg internkode"
                selected={props.internkode3}
                items={props.internkoder}
                onChange={props.handleChange}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xl={4}>
          <label htmlFor="leveringstype">Leveringstype</label>
          <DropDown
            id="leveringstype"
            name="leveringstype"
            selected={props.leveringstype}
            items={props.leveringstyper}
            onChange={props.handleChange}
          />
        </Col>
      </Row>
      {props.showEttersendelsesrapportTextArea && (
        <Row>
          <Col xl={12}>
            <FormGroup
              validationState={
                props.ettersendelsesrapportValidationErrorText ? "error" : null
              }
            >
              <FormLabel>Ettersendelsesrapport</FormLabel>
              <FormControl
                name="ettersendelsesrapport"
                componentClass="textarea"
                onChange={props.handleChange}
              />
              {props.ettersendelsesrapportValidationErrorText && (
                <span className="validation-error">
                  {props.ettersendelsesrapportValidationErrorText}
                </span>
              )}
            </FormGroup>
          </Col>
        </Row>
      )}
      <Row>
        <Col xl={2}>
          <TextInput
            label="Beløp"
            value={props.belop}
            name="belop"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            type="number"
          />
        </Col>
      </Row>
      <Row>
        <Col xl={12}>
          <TextInput
            label="Navn på mottaker"
            value={props.mottaker}
            name="mottaker"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
          />
        </Col>
      </Row>
      <Row>
        <Col xl={4}>
          <TextInput
            label="Mottaker gatenavn"
            value={props.mottakerGate}
            name="mottakerGate"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
          />
        </Col>
        <Col xl={4}>
          <TextInput
            label="Mottaker gatenummer"
            value={props.mottakerGateNummer}
            name="mottakerGateNummer"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
          />
        </Col>
        <Col xl={2}>
          <TextInput
            label="Postnummer"
            value={props.mottakerPostnummer}
            name="mottakerPostnummer"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
          />
        </Col>
        <Col xl={2}>
          <TextInput
            label="Poststed"
            value={props.mottakerPoststed}
            name="mottakerPoststed"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
          />
        </Col>
      </Row>
      <Row>
        <Col xl={2}>
          <TextInput
            label="Gårdsnummer"
            value={props.gnr}
            name="gnr"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            type="number"
          />
        </Col>
        <Col xl={2}>
          <TextInput
            label="Bruksnummer"
            value={props.bnr}
            name="bnr"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            type="number"
          />
        </Col>
      </Row>
      <Row>
        <Col xl={4}>
          <label htmlFor="mottakerTypeId">Type mottaker</label>
          <DropDown
            id="mottakertypeId"
            name="mottakertypeId"
            selected={props.mottakertypeId}
            items={props.mottakertyper}
            onChange={props.handleChange}
          />
        </Col>
        <Col xl={4}>
          <TextInput
            label="Fødselsdato/org. nr"
            value={props.fodsnrOrOrgnr}
            name="fodsnrOrOrgnr"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
          />
        </Col>
      </Row>
      <Row>
        <Col xl={6}>
          <TextArea
            name="internkommentar"
            label="Internkommentar"
            value={props.internkommentar}
            onChange={props.handleChange}
          />
        </Col>
        <Col xl={6}>
          <TextArea
            name="kommentarTilMottaker"
            label="Kommentar til mottaker"
            value={props.kommentarTilMottaker}
            onChange={props.handleChange}
          />
        </Col>
      </Row>
    </form>
  );
};

EndreMiljogebyrModalForm.propTypes = {
  warningText: PropTypes.string,
  gatenavn: PropTypes.string,
  gatenummer: PropTypes.string,
  bydel: PropTypes.string,
  vedGate: PropTypes.string,
  vedGatenummer: PropTypes.string,
  vedOppgang: PropTypes.string,
  internkommentar: PropTypes.string,
  kommentarTilMottaker: PropTypes.string,
  mottaker: PropTypes.string,
  fodelsdatoOrgNr: PropTypes.string,
  mottakertypeId: PropTypes.number,
  mottakerGate: PropTypes.string,
  mottakerGateNummer: PropTypes.string,
  mottakerPostnummer: PropTypes.string,
  mottakerPoststed: PropTypes.string,
  gnr: PropTypes.number,
  bnr: PropTypes.number,
  belop: PropTypes.number,
  leveringstype: PropTypes.number.isRequired,
  overtredelse1: PropTypes.number.isRequired,
  overtredelse2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  overtredelse3: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  internkode1: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  internkode2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  leveringstyper: PropTypes.array,
  overtredelseskoder: PropTypes.array,
  showEttersendelsesrapportTextArea: PropTypes.bool,
  ettersendelsesrapport: PropTypes.string,
  ettersendelsesrapportValidationErrorText: PropTypes.string,
  internkoder: PropTypes.array,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
};

export default EndreMiljogebyrModalForm;
