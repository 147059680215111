export const getMiljogebyrVenterTitle = (reportCountData) => {
  return reportCountData &&
    (reportCountData.a_MiljoGebyrInTimeQuarantineCount ||
      reportCountData.a_MiljoGebyrInTimeQuarantineCount === 0)
    ? `Miljøgebyr som venter på å bli sendt (${reportCountData.a_MiljoGebyrInTimeQuarantineCount})`
    : "Miljøgebyr som venter på å bli sendt";
};

export const getMiljogebyrStoppetTitle = (reportCountData) => {
  return reportCountData &&
    (reportCountData.b_MiljoGebyrStoppetAvHgkSystemetCount ||
      reportCountData.b_MiljoGebyrStoppetAvHgkSystemetCount === 0)
    ? `Miljøgebyr stoppet av fagsystemet (${reportCountData.b_MiljoGebyrStoppetAvHgkSystemetCount})`
    : "Miljøgebyr stoppet av fagsystemet";
};

export const getMiljogebyrKlarTitle = (reportCountData) => {
  return reportCountData &&
    (reportCountData.c_MiljoGebyrWillBeSentCount ||
      reportCountData.c_MiljoGebyrWillBeSentCount === 0)
    ? `Miljøgebyr klare til å sendes (${reportCountData.c_MiljoGebyrWillBeSentCount})`
    : "Miljøgebyr klare til å sendes";
};

export const getMiljogebyrBehandlesInnkrevingTitle = (reportCountData) => {
  return reportCountData &&
    (reportCountData.d_MiljoGebyrInIneQueueCount ||
      reportCountData.d_MiljoGebyrInIneQueueCount === 0)
    ? `Miljøgebyr under behandling hos Innkreving (${reportCountData.d_MiljoGebyrInIneQueueCount})`
    : "Miljøgebyr under behandling hos Innkreving";
};

export const getMiljogebyrFeiletTitle = (reportCountData) => {
  return reportCountData &&
    (reportCountData.f_MiljoGebyrHasBeenRejectedCount ||
      reportCountData.f_MiljoGebyrHasBeenRejectedCount === 0)
    ? `Miljøgebyr feilet hos Innkreving (${reportCountData.f_MiljoGebyrHasBeenRejectedCount})`
    : "Miljøgebyr feilet hos Innkreving";
};

export const getMiljogebyrVellykketTitle = (reportCountData) => {
  return reportCountData &&
    (reportCountData.e_MiljoGebyrHasBeenAcceptedCount ||
      reportCountData.e_MiljoGebyrHasBeenAcceptedCount === 0)
    ? `Miljøgebyr som er behandlet (${reportCountData.e_MiljoGebyrHasBeenAcceptedCount})`
    : "Miljøgebyr som er behandlet";
};

export const getMiljogebyrVenterHeaders = {
  "Ilegg.nr.": { cellKey: "ileggelsesnummer" },
  Dato: { cellKey: "dato" },
  Navn: {},
  Adresse: {},
  "Postnr.": {},
  Poststed: {},
  "Overt.kode": {},
  Beløp: {},
};

export const getMiljogebyrStoppetHeaders = {
  "Ilegg.nr.": { cellKey: "ileggelsesnummer" },
  Dato: { cellKey: "dato" },
  Navn: {},
  Adresse: {},
  "Postnr.": {},
  Poststed: {},
  "Overt.kode": {},
  Beløp: {},
  Feil: {},
};

export const getMiljogebyrKlarHeaders = getMiljogebyrVenterHeaders;

export const getMiljogebyrBehandlesInnkrevingHeaders = {
  "Ilegg.nr.": { cellKey: "ileggelsesnummer" },
  Dato: { cellKey: "dato" },
  Navn: {},
  Adresse: {},
  "Postnr.": {},
  Poststed: {},
  "Overt.kode": {},
  Beløp: {},
  "Sent INE dato": { cellKey: "sent_to_ine" },
};

export const getMiljogebyrFeiletHeaders = {
  "Ilegg.nr.": { cellKey: "ileggelsesnummer" },
  Dato: { cellKey: "dato" },
  Navn: {},
  Adresse: {},
  "Postnr.": {},
  Poststed: {},
  "Overt.kode": {},
  Beløp: {},
  "Behandlet INE dato": { cellKey: "received_from_ine" },
  Feil: {},
};

export const getMiljogebyrVellykketHeaders = {
  "Ilegg.nr.": { cellKey: "ileggelsesnummer" },
  Dato: { cellKey: "dato" },
  Navn: {},
  Adresse: {},
  "Postnr.": {},
  Poststed: {},
  "Overt.kode": {},
  Beløp: {},
  "Behandlet INE dato": { cellKey: "received_from_ine" },
};
