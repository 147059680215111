import React, { Component } from "react";
import PropTypes from "prop-types";
import map from "lodash/map";

import Table from "../../Common/Tables/BaseTable";
import InternkoderModal from "./UpdateModals/InternkoderModal";

import * as title from "../../../constants/titles";

class InternkoderList extends Component {
  static propTypes = {
    authorizedToCreateAndEdit: PropTypes.bool.isRequired,
    internkoder: PropTypes.array,
    modalToRender: PropTypes.string,
  };

  componentDidMount() {
    if (this.props.modalToRender === "ileggelser")
      document.title = title.grunndataInternkoderIleggelser;
    if (this.props.modalToRender === "miljøgebyr")
      document.title = title.grunndataInternkoderMiljøgebyr;
  }

  renderRow = () => {
    return map(this.props.internkoder, (internkode) => (
      <tr key={`internkoderIleggelserTableBodyTr${internkode.id}`}>
        <td>{internkode.id}</td>
        <td>{internkode.beskrivelse}</td>
        <td>{internkode.aktiv === true ? "Aktiv" : "Deaktivert"}</td>
        <td style={{ width: 50 }}>
          {this.props.authorizedToCreateAndEdit && (
            <InternkoderModal
              id={internkode.id}
              modalToRender={this.props.modalToRender}
              beskrivelse={internkode.beskrivelse}
              aktivertDato={internkode.aktivertDato}
              deaktivertDato={internkode.deaktivertDato}
            />
          )}
        </td>
      </tr>
    ));
  };

  render() {
    const headerRow = ["ID", "Forklaring", "Status", ""];
    return (
      <div className="table-wrapper-horizontal-scroll">
        <Table
          id="internkoderIleggelse-liste"
          defaultStyle
          striped
          headerRow={headerRow}
        >
          <tbody>{this.renderRow()}</tbody>
        </Table>
      </div>
    );
  }
}

export default InternkoderList;
