import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import createPaginationControl from "../../Common/createPaginationControl";
import List from "./List";
import { getMiljogebyrTilBehandling as getMiljogebyrTilBehandlingAction } from "../../../actions/miljogebyrTilBehandlingActions";
import { getOvertredelseskoderMiljogebyr as getOvertredelseskoderMiljogebyrAction } from "../../../actions/overtredelseskoderMiljogebyrActions";
import { getMiljogebyrTilBehandlingItems } from "../../../selectors/miljogebyrTilBehandlingSelector";

const PaginationControl = createPaginationControl(
  "miljogebyrTilBehandling",
  {
    apiCallAction: getMiljogebyrTilBehandlingAction,
    queriesForApiReducerName: "forms.miljogebyrTilBehandlingFilterForm",
  },
  getMiljogebyrTilBehandlingItems
);

class ListContainer extends React.Component {
  static propTypes = {
    checkedMiljogebyrTilGodkjenningSkrivUt: PropTypes.object,
    handleSendTilSvarUtCheckboxChange: PropTypes.func.isRequired,
  };

  render() {
    return <List {...this.props} PaginationControl={PaginationControl} />;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    miljogebyrTilBehandling: state.miljogebyrTilBehandling,
    miljogebyrTilBehandlingItems: getMiljogebyrTilBehandlingItems(state),
    checkedMiljogebyrTilGodkjenningSkrivUt:
      ownProps.checkedMiljogebyrTilGodkjenningSkrivUt,
    handleSendTilSvarUtCheckboxChange:
      ownProps.handleSendTilSvarUtCheckboxChange,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMiljogebyrTilBehandling: () =>
      dispatch(getMiljogebyrTilBehandlingAction()),
    getOvertredelseskoderMiljogebyr: () =>
      dispatch(getOvertredelseskoderMiljogebyrAction()),
  };
};

const mergeProps = (stateProps, dispatchProps) => {
  return {
    ...stateProps,
    miljogebyrTilBehandling: stateProps.miljogebyrTilBehandlingItems,
    ...dispatchProps,
    getMiljogebyrTilBehandling: () =>
      dispatchProps.getMiljogebyrTilBehandling(
        stateProps.miljogebyrTilBehandlingFilterForm,
        stateProps.miljogebyrTilBehandling.page,
        stateProps.miljogebyrTilBehandling.count
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ListContainer);
