import { createSelector } from "reselect";

const miljoRettelser = state => state.miljoRettelser;
const bybetjenter = state => state.bybetjenter;

export const getMiljoRettelser = createSelector(
  [miljoRettelser, bybetjenter],
  (miljoRettelser, bybetjenter) => {
    return miljoRettelser.map(miljoRettelse => {
      let bybetjent = bybetjenter.find(
        bybetjent => bybetjent.id === miljoRettelse.betjentId
      );
      return {
        ...miljoRettelse,
        tjenestenummer: bybetjent && bybetjent.nummer
      };
    });
  }
);

export default getMiljoRettelser;
