import React from "react";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import PrintOutTable from "../Common/PrintOutTable";

import { ReactComponent as IconImage } from "../../images/icon-image.svg";

const Utskrift = ({
  title,
  queryParameters,
  ileggelseTableHeaders,
  ileggelseTableRows,
}) => {
  const renderQueryParameters = (queryParameters) => {
    if (queryParameters.length === 0) return <span />;
    let cols =
      queryParameters.length < 7 ? (
        <Col xs={4}>
          {queryParameters.map((queryParameter) => (
            <div key={queryParameter.key}>
              {queryParameter.key}:{" "}
              {queryParameter.value && queryParameter.value.toString()}
            </div>
          ))}
        </Col>
      ) : (
        <React.Fragment>
          <Col xs={4}>
            {queryParameters.slice(0, 6).map((queryParameter) => (
              <div key={queryParameter.key}>
                {queryParameter.key}:{" "}
                {queryParameter.value && queryParameter.value.toString()}
              </div>
            ))}
          </Col>
          <Col xs={4}>
            {queryParameters
              .slice(6, queryParameters.length)
              .map((queryParameter) => (
                <div>
                  {queryParameter.key}:{" "}
                  {queryParameter.value && queryParameter.value.toString()}
                </div>
              ))}
          </Col>
        </React.Fragment>
      );
    return cols;
  };

  const renderIleggelseTableTd = (ileggelseTableTd) => {
    if (ileggelseTableTd === "ShowImageIcon") {
      return <IconImage />;
    }
    return ileggelseTableTd;
  };

  const getTdClassName = (tableRowMetadata) =>
    tableRowMetadata.isMakulert ? "print-out-table-row-makulert" : "";

  return (
    <PrintOutTable
      title={title}
      queryParameters={queryParameters}
      tableHeaders={ileggelseTableHeaders}
      tableRows={ileggelseTableRows}
      renderQueryParameters={renderQueryParameters}
      renderTableTd={renderIleggelseTableTd}
      getTdClassName={getTdClassName}
    />
  );
};

Utskrift.propTypes = {
  title: PropTypes.string.isRequired,
  queryParameters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]).isRequired,
    })
  ).isRequired,
  ileggelseTableHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
  ileggelseTableRows: PropTypes.arrayOf(
    PropTypes.shape({
      metadata: PropTypes.shape({}),
      data: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      ),
    })
  ).isRequired,
};

export default Utskrift;
