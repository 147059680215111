import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import map from "lodash/map";

import Modal from "../../Common/ModalGeneric";
import Table from "../../Common/Tables/BaseTable";
import { getBetjentTeamHistorikk } from "../../../actions/bybetjenterActions";
import {
  toNorwegianDatetime,
  toNorwegianDateFormat,
} from "../../../utils/datetimeUtils";

export class BypatruljeTeamHistorikk extends Component {
  static propTypes = {
    bybetjentId: PropTypes.string,
    teamHistorikk: PropTypes.array,
    tjenestenummer: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  getTeamHistorikk() {
    const bybetjentId = this.props.bybetjentId;
    if (bybetjentId) {
      this.props.getBetjentTeamHistorikk(bybetjentId);
    }
  }

  get initState() {
    return {
      open: false,
    };
  }
  state = this.initState;
  openModal = () => {
    this.setState({ open: true });
    this.getTeamHistorikk();
  };

  closeModal = () => {
    this.setState(this.initState);
  };

  render() {
    const { teamHistorikk, tjenestenummer } = this.props;
    const headerRow = ["Dato fra", "Dato til", "Team"];
    return (
      <Modal
        modalTitle={`Teamhistorikk for tjenestenummer ${tjenestenummer}`}
        open={this.state.open}
        closeModal={this.closeModal}
        openModal={this.openModal}
        submit={this.saveAndClose}
        isButtonLink
        popUpButtonText={tjenestenummer}
        hasSubmitButton={false}
        closeButtonText="Lukk"
      >
        <Table headerRow={headerRow} striped>
          <tbody>
            {map(teamHistorikk, (i, index) => {
              return (
                <tr key={index}>
                  <td>
                    {toNorwegianDateFormat(toNorwegianDatetime(i.datoFra))}
                  </td>
                  <td>
                    {toNorwegianDateFormat(toNorwegianDatetime(i.datoTil))}
                  </td>
                  <td>{i.team}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  teamHistorikk: state.betjentTeamHistorikk,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getBetjentTeamHistorikk }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BypatruljeTeamHistorikk);
