const klageSitePaths = {
  shortUrl: "/klage",
  page: "/klage/:path",
  toastUi:
    "/klage/bilder/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/toastui",
  miljogebyrer: {
    shortUrl: "/klage/miljogebyrer",
    detalj: {
      shortUrl: {
        matcher:
          "/klage/miljogebyrer/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$",
        routeMatcher:
          "/klage/miljogebyrer/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
        getUrl: (miljogebyrId) => `/klage/miljogebyrer/${miljogebyrId}`,
      },
      utskrift:
        "/klage/miljogebyrer/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/utskrift",
      toastUi:
        "/klage/miljogebyrer/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/bilder/:bildeId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/toastui",
      bilde:
        "/klage/miljogebyrer/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/bilder/:bildeId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
      miljokontroll: {
        shortUrl: {
          matcher:
            "/klage/miljogebyrer/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/tidskontroller/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$",
          routeMatcher:
            "/klage/miljogebyrer/:miljogebyrId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/tidskontroller/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
          getUrl: (miljogebyrId, miljokontrollId) =>
            `/klage/miljogebyrer/${miljogebyrId}/tidskontroller/${miljokontrollId}`,
        },
        toastUi:
          "/klage/miljogebyrer/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/tidskontroller/:miljokontrollId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/observasjoner/:observasjonId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/bilder/:bildeId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/toastui",
        bilde:
          "/klage/miljogebyrer/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/tidskontroller/:miljokontrollId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/observasjoner/:observasjonId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/bilder/:bildeId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
      },
    },
  },
  ileggelser: {
    shortUrl: "/klage/ileggelser",
    detalj: {
      shortUrl:
        "/klage/ileggelser/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
      utskrift:
        "/klage/ileggelser/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/utskrift",
      toastUi:
        "/klage/ileggelser/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/bilder/:bildeId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/toastui",
      bilde:
        "/klage/ileggelser/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})/bilder/:bildeId([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
    },
  },
  default: "/klage",
};

export default klageSitePaths;
