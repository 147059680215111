import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import TextInput from "../Common/Forms/TextInput";
import FormButtonsForCloseAndSubmit from "../Common/Forms/FormButtonsForCloseAndSubmit";
import Checkbox from "../Common/Forms/CheckBoxEnhanced";

export default class IleggelserFilterForm extends Component {
  static propTypes = {
    showSokSpinner: PropTypes.bool,
    KlageIleggelseFilterFormState: PropTypes.shape({
      saksnummer: PropTypes.string,
    }),
    handleChange: PropTypes.func,
    onSubmit: PropTypes.func,
    handleChangeToInitState: PropTypes.func,
  };

  render() {
    const {
      showSokSpinner,
      KlageIleggelseFilterFormState,
      handleChange,
      handleBlur,
      onSubmit,
      handleChangeToInitState,
    } = this.props;
    return (
      <form onSubmit={onSubmit}>
        <div className="SearchKlageForm">
          <div className="SearchFilterOverskrift">Søk etter klagesaker</div>
          <Row>
            <Col sm={3}>
              <TextInput
                id="saksnummer"
                name="saksnummer"
                label="Saksnummer"
                placeholder=""
                value={KlageIleggelseFilterFormState.saksnummer}
                onChange={handleChange}
                onBlur={handleBlur}
                autofocus
              />
            </Col>
            <Col xs={12} className="d-block d-sm-none">
              <Checkbox
                id="kobleTilP360"
                text="Finn gammel P360-sak og endre til nytt Acos-nummer"
                name="kobleTilP360"
                value="kobleTilP360"
                checked={KlageIleggelseFilterFormState.kobleTilP360}
                onChange={handleChange}
              />
            </Col>
            <Col sm={9} style={{ marginTop: 25 }}>
              <FormButtonsForCloseAndSubmit
                submitText="Søk"
                onClose={handleChangeToInitState}
                showSubmitSpinner={showSokSpinner}
              />
            </Col>
          </Row>
          <Row className="d-none d-sm-block">
            <Col sm={12}>
              <Checkbox
                id="kobleTilP360"
                text="Finn gammel P360-sak og endre til nytt Acos-nummer"
                name="kobleTilP360"
                value="kobleTilP360"
                checked={KlageIleggelseFilterFormState.kobleTilP360}
                onChange={handleChange}
              />
            </Col>
          </Row>
        </div>
      </form>
    );
  }
}
