import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ModalGeneric from "../../Common/ModalGeneric";
import OpenModalComponent from "./OpenModalComponent";

import FargerForm from "../FargerForm";

import { postFarge, getFarger } from "../../../actions/fargerActions";
import {
  IsValidated,
  onChangeValidation,
} from "../../../utils/validationUtils";

class FargerModal extends React.Component {
  static propTypes = {
    postFarge: PropTypes.func,
    modalToRender: PropTypes.string,
  };
  get initState() {
    return {
      open: false,
      submitButtonText: "Legg til ny",
      kode: "",
      navn: "",
      validationError: {},
    };
  }
  rules = {
    kode: "required;minLength=1;maxLength=50",
    navn: "required;minLength=3;maxLength=50",
  };
  state = this.initState;

  closeModal = () => {
    this.setState(this.initState);
  };

  openModal = () => {
    this.setState({ open: true });
  };

  handlePost = async () => {
    this.setState({ submitButtonText: "Legger til..." });

    const payload = {
      kode: this.state.kode,
      navn: this.state.navn,
    };
    this.props
      .postFarge(payload)
      .then(() => {
        this.closeModal();
      })
      .catch(() => this.setState({ submitButtonText: "Legg til ny" }));
  };

  isValid = () => {
    const { isValid, validationError } = IsValidated(this.state, this.rules);
    this.setState({ validationError });
    return isValid;
  };

  saveAndClose = (e) => {
    if (this.isValid()) {
      this.handlePost();
    }
  };

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
    onChangeValidation(e, this.state, this.rules);
  };

  render() {
    const { submitButtonText } = this.state;

    const modalTilte = "Legg til ny farge";

    return (
      <>
        <ModalGeneric
          modalTitle={modalTilte}
          open={this.state.open}
          closeModal={this.closeModal}
          openModal={this.openModal}
          OpenModalComponent={OpenModalComponent}
          submit={this.saveAndClose}
          submitButtonText={submitButtonText}
        >
          <FargerForm
            kode={this.state.kode}
            navn={this.state.navn}
            validationError={this.state.validationError}
            handleChange={this.handleChange}
          />
        </ModalGeneric>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(
    {
      postFarge,
      getFarger,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(FargerModal);
