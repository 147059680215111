import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import createPaginationControl from "../Common/createPaginationControl";
import NavigationBarContainer from "./NavigationBarContainer";
import InnsynMiljogebyrFilterContainer from "./InnsynMiljogebyrFilterContainer";
import InnsynMiljogebyererList from "./InnsynMiljogebyererList";

import { getMiljogebyrList } from "../../selectors/miljogebyrerSelector";

import { getMiljogebyrer } from "../../actions/miljogebyrerActions";

import "./innsyn.css";

const PaginationControl = createPaginationControl(
  "miljogebyrer",
  {
    apiCallAction: getMiljogebyrer,
    queriesForApiReducerName: "forms.InnsynMiljogebyrerFilterFormState",
  },
  getMiljogebyrList
);

class InnsynMiljogebyrPage extends Component {
  static propTypes = {
    miljogebyer: PropTypes.object,
  };
  render() {
    return (
      <Container fluid>
        <Row>
          <Col>
            <NavigationBarContainer page="miljøgebyr" />
            <div className="SearchFormDiv">
              <InnsynMiljogebyrFilterContainer />
            </div>
            <div
              className="pull-right ileggelse-list-page-total-ileggelser"
              style={{ height: 50, marginTop: 20, paddingRight: 50 }}
            >
              {this.props.miljogebyrer && this.props.miljogebyrer.total}{" "}
              miljøgebyrer
            </div>
            <div /*className="resutlTableDiv"*/>
              <div className="tableContainer">
                <InnsynMiljogebyererList body={this.props.miljogebyrer} />
                <PaginationControl storeKey="miljogebyrer" />
                <div style={{ height: 50 }} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  miljogebyrer: state.miljogebyrer,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getMiljogebyrer }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InnsynMiljogebyrPage);
