import React from "react";
import { Row, Col } from "react-bootstrap";

import TextInput from "../Common/Forms/TextInput";
import DropDown from "../Common/Forms/DropDown";
import DatetimePicker from "../Common/Forms/DatetimePickerEnhanced";
// import Datepicker from "../../Common/Forms/Datepicker";

const AktorerForm = ({
  kjoretoyMerkeId,
  kjoretoyMerker,
  orgNummer,
  adresse1,
  adresse2,
  poststed,
  postnummer,
  aktivertDato,
  deaktivertDato,
  validationError,
  handleChange,
}) => {
  return (
    <>
      {" "}
      <Row>
        <Col md={6}>
          <DropDown
            id="kjoretoyMerke"
            label="Kjøretøymerke"
            items={kjoretoyMerker}
            name="kjoretoyMerkeId"
            selected={kjoretoyMerkeId}
            title="Velg"
            onChange={handleChange}
            validationError={validationError.kjoretoyMerkeId}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <TextInput
            label="Organisasjonsnummer"
            onChange={handleChange}
            value={orgNummer}
            name="orgNummer"
            validationError={validationError.orgNummer}
            type="number"
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <TextInput
            label="Adresselinje 1"
            onChange={handleChange}
            value={adresse1}
            name="adresse1"
            validationError={validationError.adresse1}
          />
        </Col>
        <Col md={6}>
          <TextInput
            label="Adresselinje 2"
            onChange={handleChange}
            value={adresse2}
            name="adresse2"
            validationError={validationError.adresse2}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <TextInput
            label="Postnummer"
            onChange={handleChange}
            value={postnummer}
            name="postnummer"
            validationError={validationError.postnummer}
          />
        </Col>
        <Col md={6}>
          <TextInput
            label="Poststed"
            onChange={handleChange}
            value={poststed}
            name="poststed"
            validationError={validationError.poststed}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <DatetimePicker
            label="Aktivert dato"
            id="aktivertDato"
            name="aktivertDato"
            onChange={handleChange}
            locale="nb"
            timeFormat={false}
            value={aktivertDato}
            placeHolder="Velg"
            validationError={validationError.aktivertDato}
          />
        </Col>
        <Col md={6}>
          <DatetimePicker
            label="Deaktivert dato"
            id="deaktivertDato"
            name="deaktivertDato"
            onChange={handleChange}
            locale="nb"
            timeFormat={false}
            value={deaktivertDato}
            placeHolder="Velg"
            validationError={validationError.deaktivertDato}
          />
        </Col>
      </Row>
    </>
  );
};

export default AktorerForm;
