import React from "react";
import { createSelector } from "reselect";

import Tooltip from "../components/Common/Tooltip";

import { toNorwegianDatetime } from "../utils/datetimeUtils";

import { betaltkodeDescriptions } from "../constants/globals";

const ileggelse = (state) => state.ileggelse;

export const getGridTableRowData = createSelector(ileggelse, (ileggelse) => {
  let statusArray = [];

  if (ileggelse.isVenter) statusArray.push("Venter");
  if (ileggelse.isTilEttersendelse) statusArray.push("Til ettersendelse");
  if (ileggelse.isEttersendelseTilGodkjenning)
    statusArray.push("Ettersendelse til godkjenning");
  if (ileggelse.isEttersendt) statusArray.push("Ettersendt");
  if (ileggelse.isMakulert) statusArray.push("Makulert");
  if (ileggelse.isKlarTilKemner) statusArray.push("Klar til INE"); // Skal stå som isKlarTilKemner tils endret på backend
  if (ileggelse.isStoppetAvSystemet) statusArray.push("Stoppet av systemet");
  if (ileggelse.isFeiletKemner) statusArray.push("Feilet INE");
  if (ileggelse.isSendtKemner) statusArray.push("Sendt til INE");
  if (ileggelse.isBetalt) statusArray.push("Har betalingskode");

  let statuserElement = "";
  if (statusArray.length > 0) {
    statuserElement = (
      <React.Fragment>
        {statusArray.map((status, index) => {
          return (
            <React.Fragment key={`reactFragmentStatus${index}`}>
              <span className="badge-bym-gray" key={`statusBadge${index}`}>
                {status}
              </span>
              &nbsp;
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  }

  let overtredelser = "";
  overtredelser = (
    <React.Fragment>
      {ileggelse.overtredelse1 && (
        <Tooltip
          tooltip={ileggelse.overtredelse1.beskrivelse}
          tooltipPosition="right"
        >
          {ileggelse.overtredelse1.id}
        </Tooltip>
      )}
      {ileggelse.overtredelse2 && (
        <React.Fragment>
          {"/"}
          <Tooltip
            tooltip={ileggelse.overtredelse2.beskrivelse}
            tooltipPosition="right"
          >
            {ileggelse.overtredelse2.id}
          </Tooltip>
        </React.Fragment>
      )}
      {ileggelse.overtredelse3 && (
        <React.Fragment>
          {ileggelse.overtredelse2 ? "/" : "//"}
          <Tooltip
            tooltip={ileggelse.overtredelse3.beskrivelse}
            tooltipPosition="right"
          >
            {ileggelse.overtredelse3.id}
          </Tooltip>
        </React.Fragment>
      )}
    </React.Fragment>
  );

  let internkoder = "";
  internkoder = (
    <React.Fragment>
      {ileggelse.internkode1 && (
        <Tooltip tooltip={ileggelse.internkode1.beskrivelse}>
          {ileggelse.internkode1.id}
        </Tooltip>
      )}
      {ileggelse.internkode2 && (
        <React.Fragment>
          {"/"}
          <Tooltip tooltip={ileggelse.internkode2.beskrivelse}>
            {ileggelse.internkode2.id}
          </Tooltip>
        </React.Fragment>
      )}
    </React.Fragment>
  );

  let klokkeslett = "-";
  if (ileggelse.ileggelsesdatoFra) {
    klokkeslett =
      toNorwegianDatetime(ileggelse.ileggelsesdatoFra).format("HH:mm") + " - ";
  }
  if (ileggelse.ileggelsesdatoTil) {
    klokkeslett =
      klokkeslett +
      toNorwegianDatetime(ileggelse.ileggelsesdatoTil).format("HH:mm");
  }

  let tjenestenummer = {
    name: "Tjenestenummer",
    value: String(ileggelse.tjenestenummer),
    description: "",
  };

  let gatenummer = "";
  if (ileggelse.gatenummer) {
    gatenummer = ileggelse.gatenummer;
  }
  if (ileggelse.oppgang) {
    gatenummer = gatenummer + " " + ileggelse.oppgang;
  }

  let vedGatenummer = "";
  if (ileggelse.vedGatenummer) {
    vedGatenummer = ileggelse.vedGatenummer;
  }
  if (ileggelse.vedOppgang) {
    vedGatenummer = vedGatenummer + " " + ileggelse.vedOppgang;
  }

  let underskiltKlokkeslett = "";
  if (ileggelse.skiltFraTid) {
    underskiltKlokkeslett =
      toNorwegianDatetime(ileggelse.skiltFraTid).format("HH:mm") + " - ";
  }
  if (ileggelse.skiltTilTid) {
    underskiltKlokkeslett =
      underskiltKlokkeslett +
      toNorwegianDatetime(ileggelse.skiltTilTid).format("HH:mm");
  }

  let betaltDato = "-";
  if (ileggelse.betaltDato) {
    betaltDato = toNorwegianDatetime(ileggelse.betaltDato).format("DD.MM.YYYY");
    betaltDato = betaltDato === "01.01.2000" ? "Ingen betaling" : betaltDato;
  }

  return [
    [
      {
        name: "Ileggelsesnummer",
        value: String(ileggelse.ileggelsesnummer),
        description: "",
      },
      {
        name: "Ileggelsesdato",
        value:
          ileggelse.ileggelsesdatoTil &&
          toNorwegianDatetime(ileggelse.ileggelsesdatoTil).format("DD.MM.YYYY"),
        description: "",
      },
      {
        name: "Klokkeslett",
        value: klokkeslett,
        description: "",
      },
      tjenestenummer,
      {
        name: "Ileggelsestype",
        value:
          ileggelse &&
          ileggelse.ileggelsestype &&
          ileggelse.ileggelsestype.navn,
        description: "",
      },
      { name: "Status", value: statuserElement, description: "" },
    ],
    [
      {
        name: "Kjennemerke",
        value: ileggelse.kjoretoy && ileggelse.kjoretoy.kjennemerke,
        description: "",
      },
      {
        name: "Nasjon",
        value: ileggelse.kjoretoy && ileggelse.kjoretoy.nasjon,
        description: "",
      },
      {
        name: "Fabrikkmerke",
        value: (ileggelse.kjoretoy && ileggelse.kjoretoy.fabrikkmerke) || "",
        description: "",
      },
      {
        name: "Kjøretøy info",
        value: (ileggelse.kjoretoy && ileggelse.kjoretoy.modell) || "",
        description: "",
      },
      {
        name: "Annet fabrikkmerke",
        value: (ileggelse.kjoretoy && ileggelse.kjoretoy.modellType) || "",
        description: "",
      },
      {
        name: "Kjøretøy type",
        value: (ileggelse.kjoretoy && ileggelse.kjoretoy.kjoretoyType) || "",
        description: "",
      },
    ],
    [
      { name: "", value: "", description: "" },
      { name: "", value: "", description: "" },
      { name: "", value: "", description: "" },
      { name: "", value: "", description: "" },
      {
        name: "Euroklasse",
        value: (ileggelse.kjoretoy && ileggelse.kjoretoy.euronorm) || "",
        description: "",
      },
      {
        name: "Totalvekt",
        value: (ileggelse.kjoretoy && ileggelse.kjoretoy.totalvekt) || "",
        description: "",
      },
    ],
    [
      { name: "Gatenavn", value: ileggelse.gatenavn, description: "" },
      {
        name: "Gatenummer",
        value: gatenummer,
        description: "",
      },
      { name: "Bydel", value: ileggelse.bydel || "", description: "" },
      { name: "", value: "", description: "" },
      { name: "", value: "", description: "" },
      { name: "", value: "", description: "" },
    ],
    [
      { name: "Ved", value: ileggelse.vedGate, description: "" },
      {
        name: "Ved nr",
        value: vedGatenummer,
        description: "",
      },
      {
        name: "Latitude",
        value:
          typeof ileggelse.latitude === "number"
            ? ileggelse.latitude.toString()
            : "",
        description: "",
      },
      {
        name: "Longitude",
        value:
          typeof ileggelse.longitude === "number"
            ? ileggelse.longitude.toString()
            : "",
        description: "",
      },
      { name: "Usikkerhet", value: "", description: "" },
      { name: "", value: "", description: "" },
    ],
    [
      {
        name: "Overtredelseskode",
        value: overtredelser,
        description: "",
      },
      {
        name: "Internkode",
        value: internkoder,
        description: "",
      },
      { name: "Antall meter", value: ileggelse.antallMeter, description: "" },
      {
        name: "Beløp",
        value:
          ileggelse.belop || ileggelse.belop === 0
            ? String(ileggelse.belop)
            : "-",
        description: "",
      },
      { name: "", value: "", description: "" },
      {
        name: "Leveringstype",
        value: (
          <Tooltip
            tooltip={
              ileggelse &&
              ileggelse.leveringstype &&
              ileggelse.leveringstype.navn
            }
          >
            {ileggelse && ileggelse.leveringstype && ileggelse.leveringstype.id}
          </Tooltip>
        ),
        description: "",
      },
    ],
    [
      {
        name: "Underskilt klokkeslett",
        value: underskiltKlokkeslett,
        description: "",
      },
      {
        name: "Automatnummer",
        value: ileggelse.automatnummer,
        description: "",
      },
      {
        name: "Billettnummer",
        value: ileggelse.billettNummer,
        description: "",
      },
      {
        name: "Utløpstid billett",
        value:
          ileggelse.utlopstid &&
          toNorwegianDatetime(ileggelse.utlopstid).format(
            "DD.MM.YYYY [kl.] HH:mm"
          ),
        description: "",
      },
      { name: "Observasjon", value: "", description: "" },
      { name: "Vedtaksnummer", value: "", description: "" },
    ],
    [
      {
        name: "Betaltkode",
        value:
          ileggelse &&
          betaltkodeDescriptions[ileggelse.betaltKode] &&
          betaltkodeDescriptions[ileggelse.betaltKode].description ? (
            <Tooltip
              tooltip={betaltkodeDescriptions[ileggelse.betaltKode].description}
              tooltipPosition="right"
            >
              {ileggelse && ileggelse.betaltKode ? ileggelse.betaltKode : "-"}
            </Tooltip>
          ) : (
            ileggelse && ileggelse.betaltKode
          ),
        description: "",
      },
      {
        name: "Overført betalingsdato",
        value: betaltDato,
        description: "",
      },
      {
        name: "Inedato - ileggelse",
        value:
          ileggelse &&
          ileggelse.sendtTilKemnerDato &&
          toNorwegianDatetime(ileggelse.sendtTilKemnerDato).format(
            "DD.MM.YYYY"
          ),
        description: "",
      },
      { name: "", value: "", description: "" },
      { name: "", value: "", description: "" },
      { name: "", value: "", description: "" },
    ],
  ];
});

const overtredelseskoderIleggelser = (state) =>
  state.overtredelseskoderIleggelser;

export const getOvertredelseskoderIleggelseAsDropDownListSearchFriendly =
  createSelector(
    overtredelseskoderIleggelser,
    (overtredelseskoderIleggelser) => {
      return overtredelseskoderIleggelser.map((overtredelseskode) => {
        return {
          id: overtredelseskode.id,
          navn: overtredelseskode.id.toString(),
          beskrivelse: overtredelseskode.beskrivelse,
        };
      });
    }
  );

export default getGridTableRowData;
