import React from "react";
import EditableTextInput from "../Common/Forms/EditableFieldJira";
import DropdownListSearchable from "../Common/Forms/DropdownListSearchable";
import Table from "../Common/Tables/BaseTable";
import PlusButton from "../Common/Forms/PlusButton";
import { formatDateTimeHavingNorwegianDateTime } from "../../utils/datetimeUtils";
import KlageVedtakNyRad from "./KlageVedtakNyRad";
import LabelControl from "../Common/Forms/LabelControl";
import ConfirmationModal from "../Common/ConfirmationModal";
import { authorized } from "../../utils/authorizationUtils";
import { juridiskArray } from "../../constants/roles";
import { map, includes } from "lodash";
import ModalGenericConfirmCancelContainer from "../Common/ModalGenericConfirmCancelContainer";

const IsNyttBelopDisabled = (vedtakskodeIds, vedtak) => {
  if (!authorized(juridiskArray)) return true;
  if (vedtak.vedtakskodeId === 0) return true;
  if (vedtak.erSentTilKemner) return true;
  if (!vedtak.isVenter) return true;
  return includes(vedtakskodeIds, vedtak.vedtakskodeId) ? false : true;
};

const IsDropdownBoxDisabled = (vedtak) => {
  if (!authorized(juridiskArray)) return true;
  if (vedtak.erSentTilKemner) return true;
  if (!vedtak.isVenter) return true;
  return false;
};

const IsDeleteAble = (vedtak) => {
  return (
    authorized(juridiskArray) && !vedtak.erSentTilKemner && vedtak.isVenter
  );
};

export const VedtakerList = ({
  sak,
  manglerInfo,
  nyRadModus,
  tableHeader,
  klageVedtakerForm,
  vedtaker,
  vedtakskoder,
  onAdd,
  onConfirmKlagevedtakUpdate,
  onChangeVedtakskode,
  onCancelVedtakskode,
  onSubmitNyttBelop,
  onCancelNyttBelop,
  onRemove,
  nyVedtakIleggelsenummer,
  onSubmitNyVedtak,
  onRemoveNyVedtakRad,
  editNyttBelopKlageKoder,
}) => {
  const onlyForJuridisk = authorized(juridiskArray);
  //if (!onlyForJuridisk) return null;
  const getValidationErrorForVedtakskode = (vedtakId) =>
    klageVedtakerForm &&
    klageVedtakerForm[vedtakId] &&
    klageVedtakerForm[vedtakId].validationError &&
    klageVedtakerForm[vedtakId].validationError.vedtakskode;
  return (
    <div style={{ marginTop: 40 }}>
      <div className="fake-label" style={{ fontSize: 19, fontWeight: "bold" }}>
        Vedtak
      </div>
      <hr />
      {onlyForJuridisk && !manglerInfo && (
        <PlusButton
          text="Ny rad"
          onClick={onAdd}
          position="left"
          disabled={nyRadModus}
        />
      )}
      <Table className="vedtakerTable" headerRow={tableHeader} defaultStyle>
        <tbody>
          {map(vedtaker, (vedtak, index) => (
            <tr key={index}>
              <td style={{ width: 200 }}>
                <div className="ileggelsesnummer label-14px">
                  <LabelControl text={vedtak.ileggelsesnummer} />
                </div>
              </td>
              <td style={{ width: 300 }}>
                {vedtakskoder &&
                  vedtak &&
                  vedtak.ileggelsetype &&
                  vedtakskoder[vedtak.ileggelsetype.kode] &&
                  getVedtakskoderDropDown(
                    vedtak,
                    vedtakskoder,
                    klageVedtakerForm,
                    onChangeVedtakskode,
                    onConfirmKlagevedtakUpdate,
                    onCancelVedtakskode,
                    getValidationErrorForVedtakskode,
                    manglerInfo
                  )}
              </td>
              <td>
                <div
                  className="vetaksdato label-14px"
                  style={{ marginTop: 10 }}
                >
                  {vedtak.vedtaksDato
                    ? formatDateTimeHavingNorwegianDateTime(vedtak.vedtaksDato)
                    : "-"}
                </div>
              </td>
              <td>
                <div className="gebyrdato label-14px" style={{ marginTop: 10 }}>
                  {vedtak.ileggelsetype ? vedtak.ileggelsetype.navn : ""}
                </div>
              </td>
              <td>
                <div className="gebyrdato label-14px" style={{ marginTop: 10 }}>
                  {vedtak.ileggelsesDato
                    ? formatDateTimeHavingNorwegianDateTime(
                        vedtak.ileggelsesDato
                      )
                    : "-"}
                </div>
              </td>
              <td>
                <div className="belop label-14px" style={{ marginTop: 10 }}>
                  {vedtak.belop}
                </div>
              </td>
              <td style={{ width: 200 }}>
                {getNyttBelopInput(
                  vedtak,
                  klageVedtakerForm,
                  editNyttBelopKlageKoder,
                  onSubmitNyttBelop,
                  onConfirmKlagevedtakUpdate,
                  onCancelNyttBelop
                )}
              </td>
              <td className="pull-right">
                {IsDeleteAble(vedtak) && (
                  <ConfirmationModal
                    title="Slett vedtak"
                    body={`Vil du slette ileggese "${vedtak.ileggelsesnummer}" fra vedtak?`}
                    confirmText="Slett"
                    confirmButtonText="Slett rad"
                    onConfirm={(e) => onRemove(e, vedtak)}
                  />
                )}
              </td>
            </tr>
          ))}
          {nyRadModus && (
            <KlageVedtakNyRad
              nyVedtakIleggelsenummer={nyVedtakIleggelsenummer}
              onSubmitNyVedtak={onSubmitNyVedtak}
              onRemoveNyVedtakRad={onRemoveNyVedtakRad}
            />
          )}
        </tbody>
      </Table>
    </div>
  );
};

const getVedtakskoderDropDown = (
  vedtak,
  vedtakskoder,
  klageVedtakerForm,
  onChangeVedtakskode,
  onConfirmKlagevedtakUpdate,
  onCancelVedtakskode,
  getValidationErrorForVedtakskode,
  manglerInfo
) => {
  if (vedtak && vedtak.vedtakskodeId) {
    return (
      <ModalGenericConfirmCancelContainer
        id={`${vedtak.id}NyttBelop`}
        modalTitle={`Endre vedtakskode med ileggelsesnummer ${vedtak.ileggelsesnummer}`}
        submitButtonText="Endre vedtakskode"
        submittingButtonText="Endrer vedtakskode..."
        handleConfirmClick={() => onConfirmKlagevedtakUpdate(vedtak)}
        handleCancelAndCloseClick={() => onCancelVedtakskode(vedtak)}
        openModalComponentFunction={(props) => (
          <DropdownListSearchable
            name="vedtakskode"
            title="Velg kode"
            items={vedtakskoder[vedtak.ileggelsetype.kode]}
            selected={
              vedtak &&
              klageVedtakerForm[vedtak.id] &&
              klageVedtakerForm[vedtak.id].vedtakskode
            }
            handleChange={(e) => {
              onChangeVedtakskode(e, vedtak);
              props.openModal();
            }}
            validationError={getValidationErrorForVedtakskode(vedtak.id)}
            disabled={IsDropdownBoxDisabled(vedtak) || manglerInfo}
          />
        )}
      >
        <p>
          {`Vil du endre vedtakskode for vedtak med ileggelsesnummer ${vedtak.ileggelsesnummer}`}
        </p>
      </ModalGenericConfirmCancelContainer>
    );
  } else {
    return (
      <DropdownListSearchable
        name="vedtakskode"
        title="Velg kode"
        items={vedtakskoder[vedtak.ileggelsetype.kode]}
        selected={
          vedtak &&
          klageVedtakerForm[vedtak.id] &&
          klageVedtakerForm[vedtak.id].vedtakskode
        }
        handleChange={async (e) => {
          await onChangeVedtakskode(e, vedtak);
          onConfirmKlagevedtakUpdate(vedtak);
        }}
        validationError={getValidationErrorForVedtakskode(vedtak.id)}
        disabled={IsDropdownBoxDisabled(vedtak) || manglerInfo}
      />
    );
  }
};

const getNyttBelopInput = (
  vedtak,
  klageVedtakerForm,
  editNyttBelopKlageKoder,
  onSubmitNyttBelop,
  onConfirmKlagevedtakUpdate,
  onCancelNyttBelop
) => {
  if (vedtak && vedtak.nyttBelop) {
    return (
      <ModalGenericConfirmCancelContainer
        id={`${vedtak.id}NyttBelop`}
        modalTitle={`Endre vedtak med ileggelsesnummer ${vedtak.ileggelsesnummer}`}
        submitButtonText="Endre boløp"
        submittingButtonText="Endrer beløp..."
        handleConfirmClick={() => onConfirmKlagevedtakUpdate(vedtak)}
        handleCancelAndCloseClick={() => onCancelNyttBelop(vedtak)}
        openModalComponentFunction={(props) => (
          <EditableTextInput
            isNumber
            containsNumber
            name="nyttBelop"
            value={
              vedtak &&
              klageVedtakerForm[vedtak.id] &&
              klageVedtakerForm[vedtak.id].nyttBelop
            }
            onSubmit={(e) => {
              onSubmitNyttBelop(e, vedtak);
              props.openModal();
            }}
            disabled={IsNyttBelopDisabled(editNyttBelopKlageKoder, vedtak)}
          />
        )}
      >
        <p>
          {`Vil du endre beløp for vedtak med ileggelsesnummer ${vedtak.ileggelsesnummer}`}
        </p>
      </ModalGenericConfirmCancelContainer>
    );
  } else {
    return (
      <EditableTextInput
        isNumber
        containsNumber
        name="nyttBelop"
        value={
          vedtak &&
          klageVedtakerForm[vedtak.id] &&
          klageVedtakerForm[vedtak.id].nyttBelop
        }
        onSubmit={async (e) => {
          await onSubmitNyttBelop(e, vedtak);
          onConfirmKlagevedtakUpdate(vedtak);
        }}
        disabled={IsNyttBelopDisabled(editNyttBelopKlageKoder, vedtak)}
      />
    );
  }
};

export default VedtakerList;
