import React from "react";
import PropTypes from "prop-types";
import "./Checkbox.css";

export default class Checkbox extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    checkedValue: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
      PropTypes.string
    ]),
    uncheckedValue: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
      PropTypes.string
    ]),
    currentValue: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
      PropTypes.string
    ]),
    text: PropTypes.string,
    handleChange: PropTypes.func
  };

  state = {
    value:
      this.props.currentValue === this.props.checkedValue
        ? this.props.uncheckedValue
        : this.props.checkedValue
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.currentValue !== nextProps.currentValue ||
      this.props.checkedValue !== nextProps.checkedValue ||
      this.props.uncheckedValue !== nextProps.uncheckedValue
    ) {
      this.setState({
        value:
          nextProps.currentValue === nextProps.checkedValue
            ? nextProps.uncheckedValue
            : nextProps.checkedValue
      });
    }
  }

  render() {
    if (this.props.currentValue === undefined) {
      return <span />;
    } else {
      return (
        <div className="checkbox-container">
          <input
            id={this.props.id}
            type="checkbox"
            name={this.props.name}
            value={this.state.value}
            onChange={this.props.handleChange}
            checked={this.props.currentValue === this.props.checkedValue}
          />
          <label htmlFor={this.props.id}>{this.props.text}</label>
        </div>
      );
    }
  }
}
