import * as axiosUtils from "../utils/axiosUtils";
import * as api from "../constants/api";

export const actionType = "ILEGGELSE_KOMMENTARER";
const metaData = { entitet: "ileggelse kommentar", notify: true };
export const getAllIleggelseKommentarer = (ileggelseId) => {
  const url = api.GET_ALL_ILLEGGELSE_KOMMENTARER_URL(ileggelseId);
  return axiosUtils.GetAll(url, actionType);
};

export const getSingleIleggelseKommentar = (ileggelseId, KommentarId) => {
  const url = api.GET_SINGLE_ILLEGGELSE_KOMMENTAR_URL(ileggelseId, KommentarId);
  return axiosUtils.GetOne(url, actionType);
};

export const createIleggelseKommentar = (ileggelseId, payload) => {
  const url = api.CREATE_ILLEGGELSE_KOMMENTAR_URL(ileggelseId);
  return axiosUtils.Post(url, payload, null, metaData);
};

export const updateIleggelseKommentar = (ileggelseId, KommentarId, payload) => {
  const url = api.UPDATE_ILLEGGELSE_KOMMENTAR_URL(ileggelseId, KommentarId);
  return axiosUtils.Put(url, payload, actionType, metaData);
};
