import { createCachedSelector } from "re-reselect";
import { getStateWithStringPath } from "../utils/stateUtils";

export const getSelectOptions = createCachedSelector(
  (state) => state,
  (state, reducerPathFromState) => reducerPathFromState,
  (state, reducerPathFromState, text) => text,
  (state, reducerPathFromState, text, value) => value,
  (state, reducerPathFromState, text, value, placeholder) => placeholder,
  (state, reducerPathFromState, text, value, placeholder) => {
    if (!state[reducerPathFromState] || state[reducerPathFromState] === 0)
      return [];
    let array = [...state[reducerPathFromState]];
    let selectOptions = [];
    if (placeholder) selectOptions.push(placeholder);
    for (var i = 0; i < array.length; i++) {
      let element = array[i];
      selectOptions.push({ text: element[text], value: element[value] });
    }
    return selectOptions;
  }
)((state, reducerPathFromState, text, value) => reducerPathFromState);

export const getSelectOptionsEnhanced = createCachedSelector(
  (state) => state,
  (state, reducerPathFromState) => reducerPathFromState,
  (state, reducerPathFromState, navn) => navn,
  (state, reducerPathFromState, navn, id) => id,
  (state, reducerPathFromState, navn, id) => {
    if (
      !getStateWithStringPath(state, reducerPathFromState) ||
      getStateWithStringPath(state, reducerPathFromState).length === 0
    ) {
      return [];
    }
    let array = [...getStateWithStringPath(state, reducerPathFromState)];
    let selectOptions = [];
    for (var i = 0; i < array.length; i++) {
      let element = array[i];
      selectOptions.push({
        navn: element[navn].toString(),
        id: element[id],
      });
    }
    return selectOptions;
  }
)((state, reducerPathFromState, navn, id) => reducerPathFromState);
