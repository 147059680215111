import React from "react";
import PropTypes from "prop-types";

import Excel from "../Common/Excel";

import {
  toNorwegianDatetime,
  toNorwegianDateFormat,
  datetimeIsDeactivated,
  formatDateTimeHavingNorwegianDateTime,
} from "../../utils/datetimeUtils";
import sitePaths from "../../constants/sitePaths";

const getExcelFileName = (location) => {
  if (!location) return;
  switch (location.pathname) {
    case sitePaths.grunndata.aktorer:
      return "Aktører.xlsx";
    case sitePaths.grunndata.bypatruljen:
      return "Bypatruljen.xlsx";
    case sitePaths.grunndata.farger:
      return "Farger.xlsx";
    case sitePaths.grunndata.internkoderIleggelser:
      return "Internkoder ileggelser.xlsx";
    case sitePaths.grunndata.internkoderMiljogebyr:
      return "Internkoder miljøgebyr.xlsx";
    case sitePaths.grunndata.kjoretoyMerker:
      return "Kjoretoymerker.xlsx";
    case sitePaths.grunndata.kjoretoyTyper:
      return "Kjoretoytyper.xlsx";
    case sitePaths.grunndata.nasjoner:
      return "Nasjoner.xlsx";
    case sitePaths.grunndata.overtredelseskoderIleggelser:
      return "Overtredelseskoder ileggelser.xlsx";
    case sitePaths.grunndata.overtredelseskoderMiljogebyr:
      return "Overtredelseskoder miljøgebyr.xlsx";
    case sitePaths.grunndata.rettekoder:
      return "Rettekoder.xlsx";
    case sitePaths.grunndata.vedtakskoder:
      return "Vedtakskoder.xlsx";
    default:
      break;
  }
};

const getExcelSheets = (
  location,
  grunndataFilterForm,
  aktorer,
  bybetjenter,
  farger,
  internkoderIleggelser,
  internkoderMiljogebyr,
  kjoretoyMerker,
  kjoretoyTyper,
  nasjoner,
  overtredelseskoderIleggelser,
  overtredelseskoderMiljogebyr,
  rettekoder,
  vedtakskoder
) => {
  if (!location) return;
  let data = [];
  switch (location.pathname) {
    case sitePaths.grunndata.aktorer:
      data = aktorer
        .filter(
          (a) =>
            a.isAktiv === grunndataFilterForm.aktorerAktivStatus ||
            grunndataFilterForm.aktorerAktivStatus === null
        )
        .map((aktor) => {
          return {
            Kjøretøymerke: aktor.kjoretoyMerke && aktor.kjoretoyMerke.merke,
            "Kjøretøymerke kode":
              aktor.kjoretoyMerke && aktor.kjoretoyMerke.kode,
            Organisasjonsnummer: aktor.orgNummer,
            "Adresselinje 1": aktor.adresse1,
            "Adresselinje 2": aktor.adresse2,
            Poststed: aktor.poststed,
            Postnummer: aktor.postnummer,
            "Aktivert Dato": formatDateTimeHavingNorwegianDateTime(
              aktor.aktivertDato
            ),
            "Deaktivert Dato": formatDateTimeHavingNorwegianDateTime(
              aktor.deaktivertDato
            ),
          };
        });
      return [{ name: "Aktører", data }];
    case sitePaths.grunndata.bypatruljen:
      data = bybetjenter.map((bb) => {
        const teamNavnArray = bb.team && bb.team.map((t) => t.navn);
        return {
          Tjenestesnummer: bb.nummer,
          Pulsnummer: bb.pulsNummer,
          Team: teamNavnArray.toString(),
          Navn: bb.navn,
          Mobilnummer: bb.mobil,
          "E-post": bb.epost,
          "Start dato": toNorwegianDateFormat(
            toNorwegianDatetime(bb.aktivertDato)
          ),
          "Slutt dato": toNorwegianDateFormat(bb.deaktivertDato),
          Rolle: bb.rolle && bb.rolle.navn,
          Seksjon: bb.seksjon && bb.seksjon.navn,
        };
      });
      return [
        {
          name: "Bypatruljen",
          data,
        },
      ];
    case sitePaths.grunndata.farger:
      data = farger.map((farge) => {
        return {
          Kode: farge.kode,
          Navn: farge.navn,
        };
      });
      return [
        {
          name: "Farger",
          data,
        },
      ];
    case sitePaths.grunndata.internkoderIleggelser:
      data = internkoderIleggelser
        .filter(
          (iki) =>
            iki.aktiv ===
              grunndataFilterForm.internkoderIleggelserAktivStatus ||
            grunndataFilterForm.internkoderIleggelserAktivStatus === null
        )
        .map((iki) => ({
          ID: iki.id,
          Forklaring: iki.beskrivelse,
          Status: iki.aktiv === true ? "Aktiv" : "Deaktivert",
        }));
      return [
        {
          name: "Internkoder ileggelser",
          data,
        },
      ];
    case sitePaths.grunndata.internkoderMiljogebyr:
      data = internkoderMiljogebyr
        .filter(
          (ikm) =>
            ikm.aktiv ===
              grunndataFilterForm.internkoderMiljogebyrAktivStatus ||
            grunndataFilterForm.internkoderMiljogebyrAktivStatus === null
        )
        .map((ikm) => ({
          ID: ikm.id,
          Forklaring: ikm.beskrivelse,
          Status: ikm.aktiv === true ? "Aktiv" : "Deaktivert",
        }));
      return [
        {
          name: "Internkoder miljøgebyr",
          data,
        },
      ];
    case sitePaths.grunndata.kjoretoyMerker:
      data = kjoretoyMerker.map((kjoretoyMerker) => {
        return {
          Kode: kjoretoyMerker.kode,
          Merke: kjoretoyMerker.merke,
        };
      });
      return [
        {
          name: "Kjoretoymerker",
          data,
        },
      ];
    case sitePaths.grunndata.kjoretoyTyper:
      data = kjoretoyTyper.map((kjoretoyType) => {
        return {
          Kode: kjoretoyType.kode,
          Navn: kjoretoyType.navn,
        };
      });
      return [
        {
          name: "Kjoretoytyper",
          data,
        },
      ];
    case sitePaths.grunndata.nasjoner:
      data = nasjoner.map((nasjon) => {
        return {
          Kode: nasjon.kode,
          Navn: nasjon.navn,
        };
      });
      return [
        {
          name: "Nasjoner",
          data,
        },
      ];
    case sitePaths.grunndata.overtredelseskoderIleggelser:
      data = overtredelseskoderIleggelser
        .filter(
          (oki) =>
            oki.aktiv ===
              grunndataFilterForm.overtredelseskoderIleggelserAktivStatus ||
            grunndataFilterForm.overtredelseskoderIleggelserAktivStatus === null
        )
        .map((oki) => {
          let kreverMeter = undefined;
          if (oki.kreverAvstand) kreverMeter = "JA";
          else if (oki.kreverAvstand === false) kreverMeter = "VALGFRITT";
          else kreverMeter = "NEI";

          return {
            ID: oki.id,
            Forklaring: oki.beskrivelse,
            Gruppe:
              oki.overtredelseGruppe &&
              oki.overtredelseGruppe &&
              oki.overtredelseGruppe.navn
                ? oki.overtredelseGruppe.navn
                : "",
            Beløp: oki.belop,
            "Krever meter": kreverMeter,
            Status: oki.aktiv === true ? "Aktiv" : "Deaktivert",
          };
        });
      return [
        {
          name: "Overtredelseskoder ileggelser",
          data,
        },
      ];
    case sitePaths.grunndata.overtredelseskoderMiljogebyr:
      data = overtredelseskoderMiljogebyr
        .filter(
          (okm) =>
            okm.aktiv ===
              grunndataFilterForm.overtredelseskoderMiljogebyrAktivStatus ||
            grunndataFilterForm.overtredelseskoderMiljogebyrAktivStatus === null
        )
        .map((okm) => ({
          Overtredelseskode: okm.id,
          Paragraf: okm.paragraf,
          Del: okm.del,
          Beløp: okm.belop,
          "Kort tekst": okm.kortTekst,
          "Lang tekst": okm.langTekst,
          Arkivkode: okm.arkivKode,
          Status: okm.aktiv === true ? "Aktiv" : "Deaktivert",
        }));
      return [
        {
          name: "Overtredelseskoder miljøgebyr",
          data,
        },
      ];
    case sitePaths.grunndata.rettekoder:
      data = rettekoder.map((rettekode) => {
        return {
          Kode: rettekode.kode,
          Navn: rettekode.navn,
        };
      });
      return [
        {
          name: "Rettekoder",
          data,
        },
      ];
    case sitePaths.grunndata.vedtakskoder:
      data = vedtakskoder
        .filter(
          (vk) =>
            !datetimeIsDeactivated(vk.deaktivertDato) ===
              grunndataFilterForm.vedtakskoderAktivStatus ||
            grunndataFilterForm.vedtakskoderAktivStatus === null
        )
        .map((vk) => {
          const ileggelsetyper =
            vk.ileggelsetyper &&
            vk.ileggelsetyper.map((vk) => vk.kode).join(", ");
          return {
            ID: vk.id,
            Kode: vk.kode,
            Tekst: vk.navn,
            Mehold: vk.klagekode ? vk.klagekode.navn : "-",
            Klagekode: vk.klagekode ? vk.klagekode.id : "-",
            Ileggelsestyper: ileggelsetyper,
            "Til INE": vk.tilkemner ? vk.tilkemner : "",
            Status: datetimeIsDeactivated(vk.deaktivertDato)
              ? "Deaktivert"
              : "Aktiv",
          };
        });
      return [
        {
          name: "Vedtakskoder",
          data,
        },
      ];
    default:
      return [{}];
  }
};

const excelExportElement = (
  excelClassNames,
  excelStyle,
  onExportExcelClick
) => {
  return (
    <button
      className={
        excelClassNames
          ? `btn btn-bym-medium btn-bym-action ${excelClassNames}`
          : "btn btn-bym-medium btn-bym-action"
      }
      style={excelStyle}
      onClick={onExportExcelClick}
    >
      Lag rapport
    </button>
  );
};

const ExportToExcel = ({
  location,
  grunndataFilterForm,
  aktorer,
  bybetjenter,
  farger,
  internkoderIleggelser,
  internkoderMiljogebyr,
  kjoretoyMerker,
  kjoretoyTyper,
  nasjoner,
  overtredelseskoderIleggelser,
  overtredelseskoderMiljogebyr,
  rettekoder,
  vedtakskoder,
  excelClassNames,
  excelStyle,
}) => {
  return (
    <>
      <Excel
        element={(onExportToExcelClick) =>
          excelExportElement(excelClassNames, excelStyle, onExportToExcelClick)
        }
        filename={getExcelFileName(location)}
        sheets={getExcelSheets(
          location,
          grunndataFilterForm,
          aktorer,
          bybetjenter,
          farger,
          internkoderIleggelser,
          internkoderMiljogebyr,
          kjoretoyMerker,
          kjoretoyTyper,
          nasjoner,
          overtredelseskoderIleggelser,
          overtredelseskoderMiljogebyr,
          rettekoder,
          vedtakskoder
        )}
      />
    </>
  );
};

ExportToExcel.propTypes = {
  location: PropTypes.object,
  grunndataFilterForm: PropTypes.object,
  aktorer: PropTypes.arrayOf(PropTypes.object),
  bybetjenter: PropTypes.arrayOf(PropTypes.object),
  farger: PropTypes.arrayOf(PropTypes.object),
  internkoderIleggelser: PropTypes.arrayOf(PropTypes.object),
  internkoderMiljogebyr: PropTypes.arrayOf(PropTypes.object),
  kjoretoyMerker: PropTypes.arrayOf(PropTypes.object),
  kjoretoyTyper: PropTypes.arrayOf(PropTypes.object),
  overtredelseskoderIleggelser: PropTypes.arrayOf(PropTypes.object),
  overtredelseskoderMiljogebyr: PropTypes.arrayOf(PropTypes.object),
  rettekoder: PropTypes.arrayOf(PropTypes.object),
  vedtakskoder: PropTypes.arrayOf(PropTypes.object),
  excelClassNames: PropTypes.string,
};

export default ExportToExcel;
