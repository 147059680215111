import React, { useEffect } from "react";

import "./lightbox.css";

import { ReactComponent as Close } from "../../../images/lightbox/close.svg";

const Lightbox = ({
  showLightbox,
  images,
  imageIndex,
  onPrevious,
  onNext,
  onClose,
}) => {
  useEffect(() => {
    return () => {
      onClose();
    }
  }, [onClose])

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "Escape") {
        onClose();
      } else if (event.key === "ArrowLeft") {
        onPrevious();
      } else if (event.key === "ArrowRight") {
        onNext();
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose, onNext, onPrevious]);

  return (
    <div
      className="transparent-overlay"
      style={showLightbox ? {} : { display: "none" }}
    >
      <div className="lightbox-image-container">
        <div>
          <button
            className="carousel-navigation-button"
            onClick={onPrevious}
            title="Goto previous image"
          >
            <span
              aria-label="Previous image"
              className="osg-icons--chevron-left carousel-navigation-button-icon"
            ></span>
          </button>
        </div>
        <div style={{ maxWidth: 1400 }}>
          <div style={{ display: "flex" }}>
            <div style={{ marginLeft: "auto" }}>
              <button
                style={{
                  width: 30,
                  height: 30,
                  backgroundColor: "transparent",
                  border: "none",
                }}
                onClick={onClose}
              >
                <Close />
              </button>
            </div>
          </div>
          <img
            title="Bildebevis"
            src={images[imageIndex].src}
            alt={`Bildebevis for gebyr`}
          />
          <div style={{ display: "flex" }}>
            <div style={{ color: "white", marginLeft: "auto" }}>
              {`${imageIndex + 1} av ${images.length}`}
            </div>
          </div>
        </div>
        <div>
          <button
            className="carousel-navigation-button"
            onClick={onNext}
            title="Goto next image"
          >
            <span
              aria-label="Next image"
              className="osg-icons--chevron-right carousel-navigation-button-icon"
            ></span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Lightbox;
