import axios from "../services/axios";
import * as api from "../constants/api";
import moment from "moment";
import { setTotal as setTotalAction } from "./advancedListActions";

import { addFlashMessage } from "./FlashMessagesAction";

import { toIsoString } from "../utils/datetimeUtils";
import { errorData } from "../utils/apiResponseUtils";

export const GET_MILJOGEBYR_TIL_BEHANDLING_SUCCESS =
  "GET_MILJOGEBYR_TIL_BEHANDLING_SUCCESS";

export function getMiljogebyrTilBehandling(params, page, count) {
  const statusFilter = {
    // Skal stå på gamla måtet tils det er endret på backend
    isVenter: null,
    isMaKvalitetsikres: true,
    isTilEttersendelse: true,
    isEttersendt: null,
    isMakulert: false,
    isKlarTilKemner: null,
    isStoppetAvSystemet: true,
    isFeiletKemner: null,
    isSendtKemner: null,
    isBetalt: null,
  };

  return async (dispatch) => {
    return axios
      .get(api.GET_MILJOGEBYRER_URL, {
        params: {
          ...params,
          fraDato: params.fraDato && toIsoString(params.fraDato),
          tilDato: params.tilDato && toIsoString(params.tilDato),
          ...statusFilter,
          page: page,
          pageSize: count,
        },
      })
      .then(async (response) => {
        let result = response && response.data && response.data.result;

        if (!result) {
          return Promise.reject(false);
        }

        var total = JSON.parse(response.headers["x-pagination"]).Total;
        dispatch(setTotalAction("miljogebyrTilBehandling", total));

        result = result.sort((r1, r2) => {
          const momentR1 = moment(r1.ileggelsesdatoTil);
          const momentR2 = moment(r2.ileggelsesdatoTil);
          if (momentR1.isBefore(momentR2)) return -1;
          else if (momentR1.isAfter(momentR2)) return 1;
          else return 0;
        });

        dispatch({
          type: GET_MILJOGEBYR_TIL_BEHANDLING_SUCCESS,
          payload: result,
        });
      })
      .catch(catchFunction);
  };
}

const catchFunction = (error) => {
  return (dispatch) => {
    const errorMessage = errorData(error);
    dispatch(
      addFlashMessage({
        type: "error",
        text: errorMessage,
      })
    );
    return Promise.reject(error);
  };
};
