import * as api from "../constants/api";
import * as axiosUtils from "../utils/axiosUtils";

export const actionType = "INE_REPORTS";

// reportStyle: string (COUNT_ONLY, ALL_DETAILS)
export function getIneIleggelseReports(reportStyle) {
  if (reportStyle === "ALL_DETAILS") {
    return axiosUtils.GetAll(
      api.GET_INE_ILEGGELSE_REPORTS_URL(reportStyle),
      actionType
    );
  }

  return axiosUtils.GetAll(api.GET_INE_ILEGGELSE_REPORTS_URL(reportStyle));
}
