// import { getIleggelser as getIleggelserAction } from "./ileggelserActions";
import { getStateWithStringPath } from "../utils/stateUtils";

const SET_PAGE = (storeKey) => "SET_PAGE_" + storeKey.toUpperCase();
const SET_PAGE_COUNT = (storeKey) => "SET_PAGE_COUNT_" + storeKey.toUpperCase();
const SET_TOTAL = (storeKey) => "SET_TOTAL_" + storeKey.toUpperCase();
const SET_SORT = (storeKey) => "SET_SORT_" + storeKey.toUpperCase();
// const SET_FILTER = storeKey => "SET_FILTER_" + storeKey.toUpperCase();

export function setPage(storeKey, value) {
  return (dispatch) => {
    dispatch({ type: SET_PAGE(storeKey), payload: value });
  };
}

export function setPageWithConstantQueriesForApiCallObject(
  storeKey,
  apiObject,
  value
) {
  return (dispatch, getState) => {
    dispatch(
      apiObject.apiCallAction(
        apiObject.constantQueriesForApiCallObject,
        value,
        getStateWithStringPath(getState(), storeKey).count
      )
    );
    dispatch({ type: SET_PAGE(storeKey), payload: value });
  };
}

export function setPageWithQueriesForApiReducerName(
  storeKey,
  apiObject,
  value,
  sort = undefined
) {
  return (dispatch, getState) => {
    let filter = getStateWithStringPath(
      getState(),
      apiObject.queriesForApiReducerName
    );
    const parameters =
      sort && sort.cellKey && sort.orders
        ? [
            filter,
            value,
            getStateWithStringPath(getState(), storeKey).count,
            sort,
          ]
        : [filter, value, getStateWithStringPath(getState(), storeKey).count];
    dispatch(apiObject.apiCallAction(...parameters));
    dispatch({ type: SET_PAGE(storeKey), payload: value });
  };
}

export function setPageCount(storeKey, value) {
  return (dispatch, getState) => {
    dispatch({ type: SET_PAGE_COUNT(storeKey), payload: value });
  };
}

export function setPageCountWithConstantQueriesForApiCallObject(
  storeKey,
  apiObject,
  value
) {
  return (dispatch, getState) => {
    dispatch(
      apiObject.apiCallAction(
        1,
        value,
        apiObject.constantQueriesForApiCallObject
      )
    );
    dispatch({ type: SET_PAGE_COUNT(storeKey), payload: value });
  };
}

export function setPageCountWithQueriesForApiReducerName(
  storeKey,
  apiObject,
  count,
  sort = undefined
) {
  return (dispatch, getState) => {
    const parameters =
      sort && sort.cellKey && sort.orders
        ? [
            getStateWithStringPath(
              getState(),
              apiObject.queriesForApiReducerName
            ),
            1,
            count,
            sort,
          ]
        : [
            getStateWithStringPath(
              getState(),
              apiObject.queriesForApiReducerName
            ),
            1,
            count,
          ];
    dispatch(apiObject.apiCallAction(...parameters));
    dispatch({ type: SET_PAGE_COUNT(storeKey), payload: count });
  };
}

export function setTotal(storeKey, value) {
  return { type: SET_TOTAL(storeKey), payload: value };
}

export function setSort(storeKey, value) {
  return { type: SET_SORT(storeKey), payload: value };
}

// export function setFilter(storeKey, filterKey, value) {
//   return { type: SET_FILTER(storeKey), payload: value, meta: { filterKey } };
// }
