import Gallery from "../../Common/Gallery";
import { formatDateTimeHavingNorwegianDateTime } from "../../../utils/datetimeUtils";

const Observasjonsgruppe = ({ observasjonsgruppe, observasjonsgruppenr }) => {
  return (
    <section>
      <div
        style={{
          display: "flex",
          columnGap: 24,
          paddingTop: 10,
          paddingBottom: 10,
          flexWrap: "wrap",
        }}
      >
        <div style={{ width: 500, minWidth: 500 }}>
          <h2 style={{ fontSize: 18 }}>{observasjonsgruppenr}. Kontroll</h2>
          <div style={{ borderTop: "solid 1px #CCCCCC" }} />
          {observasjonsgruppe?.kommentarer?.map((k, index) => (
            <div key={`observasjonsgruppe-kommentar-${index}`}>
              <h3 style={{ fontSize: 18 }}>
                {index === 0 ? "Kommentar" : `Kommentar ${index + 1}`}
              </h3>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: 10,
                  }}
                >
                  <div>
                    <div>Tidspunkt</div>
                    <div>
                      {k.tidspunkt
                        ? formatDateTimeHavingNorwegianDateTime(k.tidspunkt)
                        : "\u00A0"}
                    </div>
                  </div>
                  <div>
                    <div>Kommentar</div>
                    <div>{k.kommentar ?? "\u00A0"}</div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: 10,
                  }}
                >
                  <div>
                    <div>Tjenestenummer</div>
                    <div>{k.betjentnummer ?? "\u00A0"}</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {observasjonsgruppe?.ventilstillinger?.map((v, index) => (
            <div
              key={`observasjonsgruppe-ventilstilling-${index}`}
              style={{ borderBottom: "solid 1px #CCCCCC" }}
            >
              <h3 style={{ fontSize: 18 }}>
                {index === 0 ? "Ventilstilling" : `Ventilstilling ${index + 1}`}
              </h3>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: 10,
                  }}
                >
                  <div>
                    <div>Tidspunkt</div>
                    <div>
                      {v.tidspunkt
                        ? formatDateTimeHavingNorwegianDateTime(v.tidspunkt)
                        : "\u00A0"}
                    </div>
                  </div>
                  <div>
                    <div>VF</div>
                    <div>{v.vf ?? "\u00A0"}</div>
                  </div>
                  <div>
                    <div>VB</div>
                    <div>{v.vb ?? "\u00A0"}</div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: 10,
                  }}
                >
                  <div>
                    <div>Tjenestenummer</div>
                    <div>{v.betjentnummer ?? "\u00A0"}</div>
                  </div>
                  <div>
                    <div>HF</div>
                    <div>{v.hf ?? "\u00A0"}</div>
                  </div>
                  <div>
                    <div>HB</div>
                    <div>{v.hb ?? "\u00A0"}</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="no-print" style={{ minWidth: 404 }}>
          <h3 style={{ fontSize: 18 }}>Bilder</h3>
          <div
            style={{
              display: "flex",
              gap: 10,
              borderTop: "solid 1px #CCCCCC",
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <Gallery
              images={observasjonsgruppe?.thumbnailBilder}
              hideMetadata
              hideExpandButton
              hideEditButton
              lightboxImages={observasjonsgruppe?.lightboxBilder}
            />
          </div>
        </div>
      </div>
      <div className="only-print" style={{ width: 500 }}>
        <h3 style={{ fontSize: 18 }}>Bilder</h3>
        <div
          style={{
            borderBottom: "solid 1px #CCCCCC",
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          {observasjonsgruppe?.lightboxBilder?.map((lb) => (
            <img
              key={lb.id}
              alt={`Bildebevis ${lb.id}`}
              style={{ width: 500, marginTop: 15, marginBottom: 15 }}
              src={lb.src}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Observasjonsgruppe;
