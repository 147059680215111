import {
  handleChangeActionType,
  handleChangeToInitStateActionType
} from "../../actions/handleChangeAction";

export const INITIAL_STATE = {
  kjennemerke: "",
  fraDato: "",
  tilDato: "",

  fraDatoValidationErrorText: null,
  tilDatoValidationErrorText: null
};

export default function piggdekkontrollFilterFormReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case handleChangeActionType("PIGGDEKKONTROLL_FILTER_FORM"):
      return { ...state, [action.variableName]: action.payload };
    case handleChangeToInitStateActionType("PIGGDEKKONTROLL_FILTER_FORM"):
      return INITIAL_STATE;
    default:
      return state;
  }
}
