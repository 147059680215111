import axios from "../services/axios";
import * as api from "../constants/api";
import * as handle from "../utils/actionUtils";

// import * as apiResponseUtils from "../utils/apiResponseUtils";

export const GET_MILJOGEBYR_ETTERSENDELSER_SUCCESS =
  "GET_MILJOGEBYR_ETTERSENDELSER_SUCCESS";
export const GET_MILJOGEBYR_ETTERSENDELSER_FAILURE =
  "GET_MILJOGEBYR_ETTERSENDELSER_FAILURE";

export function getMiljogebyrEttersendelser(miljogebyrId) {
  return async (dispatch) => {
    return axios
      .get(api.GET_MILJOGEBYR_ETTERSENDELSER_URL(miljogebyrId))
      .then(handle.success(dispatch, GET_MILJOGEBYR_ETTERSENDELSER_SUCCESS))
      .catch(handle.error(dispatch, GET_MILJOGEBYR_ETTERSENDELSER_FAILURE));
  };
}

export const POST_MILJOGEBYR_ETTERSENDELSER_SUCCESS =
  "POST_MILJOGEBYR_ETTERSENDELSER_SUCCESS";
export const POST_MILJOGEBYR_ETTERSENDELSER_FAILURE =
  "POST_MILJOGEBYR_ETTERSENDELSER_FAILURE";

export function postMiljogebyrEttersendelser(miljogebyrId, params) {
  return (dispatch, getState) => {
    return axios
      .post(api.POST_MILJOGEBYR_ETTERSENDELSER_URL(miljogebyrId), params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  };
}

export const POST_MILJOGEBYR_ETTERSENDELSEBREV_SUCCESS =
  "POST_MILJOGEBYR_ETTERSENDELSEBREV_SUCCESS";
export const POST_MILJOGEBYR_ETTERSENDELSEBREV_FAILURE =
  "POST_MILJOGEBYR_ETTERSENDELSEBREV_FAILURE";

export function postMiljogebyrEttersendelsebrev(miljogebyrEttersendelseId) {
  return (dispatch, getState) => {
    return axios
      .post(
        api.POST_MILJOGEBYR_ETTERSENDELSEBREV_URL(miljogebyrEttersendelseId)
      )
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  };
}
