import { createSelector } from "reselect";
import _ from "lodash";

const overtredelseskoderIleggelserList = (state) =>
  state.overtredelseskoderIleggelser;
const overtredelsegrupperList = (state) => state.overtredelsegrupper;
const overtredelseskoderIleggelserAktivStatus = (state) =>
  state.forms.grunndataFilterForm.overtredelseskoderIleggelserAktivStatus;

export const getOvertredelseskoderIleggelserList = createSelector(
  [
    overtredelseskoderIleggelserList,
    overtredelsegrupperList,
    overtredelseskoderIleggelserAktivStatus,
  ],
  (
    overtredelseskoderIleggelserList,
    overtredelsegrupperList,
    overtredelseskoderIleggelserAktivStatus
  ) => {
    return overtredelseskoderIleggelserList
      .map((item) => {
        return {
          ...item,
          gruppe: {
            gruppeNummer: item.gruppe,
            gruppeString:
              _.find(
                overtredelsegrupperList,
                (gruppe) => gruppe.nummer === item.gruppe
              ) &&
              _.find(
                overtredelsegrupperList,
                (gruppe) => gruppe.nummer === item.gruppe
              ).navn,
          },
          aktiv: {
            aktivBool: item.aktiv,
            aktivString: item.aktiv ? "Aktiv" : "Deaktivert",
          },
          er_deaktivert: item.aktiv ? false : true,
        };
      })
      .filter(
        (oki) =>
          (oki.aktiv &&
            oki.aktiv.aktivBool === overtredelseskoderIleggelserAktivStatus) ||
          overtredelseskoderIleggelserAktivStatus === null
      );
  }
);

export default getOvertredelseskoderIleggelserList;
