import { connect } from "react-redux";

import BilderAndObservasjonsbilder from "./BilderAndObservasjonsbilder";

import { toNorwegianDatetime } from "../../utils/datetimeUtils";
import { getDatoSortedImages } from "../../utils/imageUtils";

const cleanMiljogebyrBilderForDatoSortedBilder = (
  miljogebyrBilder,
  miljogebyr
) => {
  if (
    !miljogebyr ||
    !miljogebyr.ileggelsesdatoTil ||
    !miljogebyrBilder ||
    miljogebyrBilder.length < 0
  ) {
    return [];
  }

  return [
    {
      dato: toNorwegianDatetime(miljogebyr.ileggelsesdatoTil).format(
        "DD.MM.YYYY"
      ),
      tidspan: toNorwegianDatetime(miljogebyr.ileggelsesdatoTil).format(
        "HH:mm"
      ),
      images: [...miljogebyrBilder],
    },
  ];
};

const mapStateToProps = (state, ownProps) => {
  let datoSortedThumbnailBilder = [];

  const miljoKontrollerThumbnailBilder = getDatoSortedImages(
    state.klageMiljoKontrollThumbnailImage
  );
  if (
    miljoKontrollerThumbnailBilder &&
    miljoKontrollerThumbnailBilder.length > 0
  ) {
    datoSortedThumbnailBilder = [...miljoKontrollerThumbnailBilder];
  }

  const miljogebyrThumbnailBilder = cleanMiljogebyrBilderForDatoSortedBilder(
    state.klageIleggelseThumbnailImage,
    state.klageIleggelse
  );
  if (miljogebyrThumbnailBilder && miljogebyrThumbnailBilder.length > 0) {
    datoSortedThumbnailBilder = [
      ...datoSortedThumbnailBilder,
      ...miljogebyrThumbnailBilder,
    ];
  }

  let datoSortedLightboxBilder = [];

  const miljoKontrollerLightboxBilder = getDatoSortedImages(
    state.klageMiljoKontrollLightboxImage
  );
  if (
    miljoKontrollerLightboxBilder &&
    miljoKontrollerLightboxBilder.length > 0
  ) {
    datoSortedLightboxBilder = [...miljoKontrollerLightboxBilder];
  }

  const miljogebyrLightboxBilder = cleanMiljogebyrBilderForDatoSortedBilder(
    state.klageIleggelseLightboxImage,
    state.klageIleggelse
  );
  if (miljogebyrLightboxBilder && miljogebyrLightboxBilder.length > 0) {
    datoSortedLightboxBilder = [
      ...datoSortedLightboxBilder,
      ...miljogebyrLightboxBilder,
    ];
  }

  return {
    miljogebyr: state.klageIleggelse,
    miljogebyrThumbnailBilder: state.klageIleggelseThumbnailImage,
    datoSortedThumbnailBilder: datoSortedThumbnailBilder,
    datoSortedLightboxBilder: datoSortedLightboxBilder,
  };
};

export default connect(mapStateToProps)(BilderAndObservasjonsbilder);
