import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import createPaginationControl from "../Common/createPaginationControl";
import IleggelseFilterContainer from "./InnsynIleggelseFilterContainer";
import IleggelserList from "./InnsynIleggelserList";
import NavigationBarContainer from "./NavigationBarContainer";

import { getIleggelseList } from "../../selectors/ileggelserSelector";

import { getIleggelser } from "../../actions/ileggelserActions";

import "./innsyn.css";

const PaginationControl = createPaginationControl(
  "ileggelser",
  {
    apiCallAction: getIleggelser,
    queriesForApiReducerName: "forms.InnsynIleggelserFilterFormState",
  },
  getIleggelseList
);

class InnsynIleggelsePage extends Component {
  static propTypes = {
    ileggelser: PropTypes.object,
  };

  render() {
    return (
      <Container fluid>
        <Row>
          <Col>
            <NavigationBarContainer page="ileggelser" />
            <div className="SearchFormDiv">
              <IleggelseFilterContainer />
            </div>
            <div
              className="pull-right ileggelse-list-page-total-ileggelser"
              style={{ height: 50, marginTop: 20, paddingRight: 50 }}
            >
              {this.props.ileggelser.total} ileggelser
            </div>
            <div>
              <div className="tableContainer">
                <IleggelserList body={this.props.ileggelser} />
                <PaginationControl storeKey="ileggelser" />
                <div style={{ height: 50 }} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  ileggelser: state.ileggelser,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getIleggelser }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InnsynIleggelsePage);
