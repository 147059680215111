import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "./confirmationModal.css";

export const ConfirmationModal = ({
  onConfirm,
  title,
  body,
  confirmText,
  cancelButtonText,
  confirmButtonText,
  deleteButtonOpenModal,
}) => {
  const [show, setShow] = useState(false);

  const handleClickShow = () => {
    setShow(true);
  };

  const handleClickCancel = () => {
    setShow(false);
  };

  const handleClickConfirm = () => {
    setShow(false);
    onConfirm();
  };

  const getDeleteButtonOpenModal = () => {
    if (deleteButtonOpenModal) {
      return deleteButtonOpenModal(handleClickShow);
    }
    return (
      <Button
        onClick={handleClickShow}
        className="btn-link red removeButtonLink"
      >
        {confirmButtonText}
      </Button>
    );
  };

  return (
    <>
      {getDeleteButtonOpenModal()}
      <Modal show={show}>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClickCancel} className="btn-link">
            {cancelButtonText || "Avbryt"}
          </Button>
          <Button className="btn-bym-action" onClick={handleClickConfirm}>
            {confirmText}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
