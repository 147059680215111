import * as ileggelserActions from "../actions/ileggelserActions";

const INITIAL_STATE = [];

export default function ileggelserReducer(state = [...INITIAL_STATE], action) {
  switch (action.type) {
    case ileggelserActions.GET_ILEGGELSER_SUCCESS:
      return action.payload;
    case ileggelserActions.GET_ILEGGELSER_FAILURE:
      return [...INITIAL_STATE];
    default:
      return state;
  }
}
