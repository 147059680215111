import React from "react";
import { createSelector } from "reselect";
import { toNorwegianDatetime } from "../utils/datetimeUtils";
import MiljogebyrOvertredelseskoder from "../components/Common/MiljogebyrOvertredelseskoder";
import IleggelseInternkoder from "../components/Common/IleggelseInternkoder";
import { Badge } from "react-bootstrap";

const miljogebyr = (state) => state.klageIleggelse;
const overtredelseskoder = (state) => state.overtredelseskoderMiljogebyr;
const internkoder = (state) => state.internkoderMiljogebyr;
const mottakertyper = (state) => state.mottakertyper;

export const getGridTableRowData = createSelector(
  [miljogebyr, overtredelseskoder, internkoder, mottakertyper],
  (miljogebyr, overtredelseskoder, internkoder, mottakertyper) => {
    let adresse = "";
    if (miljogebyr.mottakerGate) {
      adresse = miljogebyr.mottakerGate + " ";
    }
    if (miljogebyr.mottakerGateNummer) {
      adresse = adresse + miljogebyr.mottakerGateNummer;
    }

    let klokkeslett = "";
    if (miljogebyr.ileggelsesdatoFra) {
      klokkeslett =
        toNorwegianDatetime(miljogebyr.ileggelsesdatoFra).format("HH:mm") +
        " - ";
    }
    if (miljogebyr.ileggelsesdatoTil) {
      klokkeslett =
        klokkeslett +
        toNorwegianDatetime(miljogebyr.ileggelsesdatoTil).format("HH:mm");
    }

    let gatenummer = "";
    if (miljogebyr.gatenummer) {
      gatenummer = miljogebyr.gatenummer;
    }
    if (miljogebyr.oppgang) {
      gatenummer = gatenummer + " " + miljogebyr.oppgang;
    }

    let vedGatenummer = "";
    if (miljogebyr.vedGatenummer) {
      vedGatenummer = miljogebyr.vedGatenummer;
    }
    if (miljogebyr.vedOppgang) {
      vedGatenummer = vedGatenummer + " " + miljogebyr.vedOppgang;
    }
    let mottakertype =
      miljogebyr &&
      miljogebyr.mottakertype &&
      mottakertyper.find(
        (mottakertype) => mottakertype.id === miljogebyr.mottakertype
      );

    const miljogebyrOvertredelseskoder = (
      <MiljogebyrOvertredelseskoder
        ileggelse={miljogebyr}
        overtredelseskoder={overtredelseskoder}
      />
    );

    const ileggelseInternkoder = (
      <IleggelseInternkoder ileggelse={miljogebyr} internkoder={internkoder} />
    );

    let untattOffentlighet = miljogebyr.untattOffentlighet ? "Ja" : "Nei";

    const ileggelsesnummer = String(miljogebyr.ileggelsesnummer);
    let makulertStatus = undefined;
    if (miljogebyr.isMakulert) {
      makulertStatus = [
        {
          name: " ",
          value: (
            <React.Fragment>
              <Badge bsClass="badge-bym-red">Makulert</Badge>
            </React.Fragment>
          ),
          description: "",
        },
      ];
    }

    let details = [
      [
        {
          name: "Ileggelsesnummer",
          value: ileggelsesnummer,
          description: "",
        },
        {
          name: "Ileggelsesdato",
          value:
            miljogebyr.ileggelsesdatoTil &&
            toNorwegianDatetime(miljogebyr.ileggelsesdatoTil).format(
              "DD.MM.YYYY"
            ),
          description: "",
        },
        {
          name: "Klokkeslett",
          value: klokkeslett,
          description: "",
        },
        {
          name: "Tjenestenummer",
          value: `${miljogebyr.betjentNummer}`,
          description: "",
        },
      ],
      [
        {
          name: "Ileggelsestype",
          value: "Miljøgebyr",
          description: "",
        },
        {
          name: "Leveringstype",
          value: miljogebyr.leveringstype,
          description: "",
        },
        { name: "", value: "", description: "" },
        { name: "", value: "", description: "" },
      ],
      [
        { name: "Gatenavn", value: miljogebyr.gatenavn, description: "" },
        {
          name: "Gatenummer",
          value: gatenummer,
          description: "",
        },
        { name: "Ved", value: miljogebyr.vedGate, description: "" },
        {
          name: "Ved nr",
          value: vedGatenummer,
          description: "",
        },
      ],
      [
        {
          name: "Overtredelseskode",
          value: miljogebyrOvertredelseskoder,
          description: "",
        },
        {
          name: "Internkode",
          value: ileggelseInternkoder,
          description: "",
        },
        {
          name: "Beløp",
          value:
            miljogebyr.belop || miljogebyr.belop === 0
              ? String(miljogebyr.belop)
              : "-",
          description: "",
        },
        { name: "", value: "", description: "" },
      ],
      [
        {
          name: "Navn på mottaker",
          value: miljogebyr.mottaker,
          description: "",
        },
        {
          name: "Adresse",
          value: adresse,
          description: "",
        },
        {
          name: "Gårdsnummer",
          value: miljogebyr && miljogebyr.gnr && `${miljogebyr.gnr}`,
          description: "",
        },
        {
          name: "Bruksnummer",
          value: miljogebyr && miljogebyr.bnr && `${miljogebyr.bnr}`,
          description: "",
        },
      ],
      [
        {
          name: "Postnummer",
          value: miljogebyr.mottakerPostnummer,
          description: "",
        },
        {
          name: "Poststed",
          value: miljogebyr.mottakerPostSted,
          description: "",
        },

        { name: "", value: "", description: "" },
        { name: "", value: "", description: "" },
      ],
      [
        {
          name: "Type mottaker",
          value: mottakertype && mottakertype.navn,
          description: "",
        },
        {
          name: "Fødselsdato/ org.nr.",
          value: miljogebyr.mottakerId,
          description: "",
        },
        {
          name: "Unntatt offentlighet",
          value: untattOffentlighet,
          description: "",
        },
        { name: "", value: "", description: "" },
      ],
      // [
      //   {
      //     name: "Interkommentar",
      //     value: miljogebyr.internkommentar,
      //     description: ""
      //   },

      //   { name: "", value: "", description: "" },
      //   { name: "", value: "", description: "" },
      //   {
      //     name: "Kommentar til fører/eier",
      //     value: miljogebyr.kommentar,
      //     description: ""
      //   }
      // ]
    ];
    //add makulertstatus to the array at index 0
    if (makulertStatus !== undefined) {
      details.splice(0, 0, makulertStatus);
    }
    return details;
  }
);

export default getGridTableRowData;
