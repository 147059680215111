import { connect } from "react-redux";
import UregistrerteKontrollerList from "./UregistrerteKontrollerList";

const mapStateToProps = (state) => {
  return {
    uregistrertekontroller: state.uregistrertekontroller,
    uregistrertekontrollerFilterForm:
      state.forms.uregistrertekontrollerFilterForm,
  };
};

export default connect(mapStateToProps)(UregistrerteKontrollerList);
