import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ModalGeneric from "../../Common/ModalGeneric";
import OpenModalComponent from "./OpenModalComponent";
import OvertredelseskoderMiljogebyrCreateModalContent from "../OvertredelseskoderMiljogebyrForm";

import { postOvertredelseskoderMiljogebyr as postOvertredelseskoderMiljogebyrAction } from "../../../actions/overtredelseskoderMiljogebyrActions";
import {
  IsValidated,
  onChangeValidation,
} from "../../../utils/validationUtils";

class OvertredelseskoderMiljogebyr extends React.Component {
  static propTypes = {
    postOvertredelseskoderMiljogebyr: PropTypes.func,
    overtredelsegrupper: PropTypes.array,
  };

  get initState() {
    return {
      open: false,
      submitButtonText: "Legg til ny",
      id: "",
      paragraf: "",
      del: "",
      belop: "",
      kortTekst: "",
      langTekst: "",
      arkivKode: "",
      aktivertDato: "",
      deaktivertDato: "",
      overtredelseGruppeId: 0,
      validationError: {},

      openDeaktiverConfirm: false,
      deaktiveringConfirmed: false,
    };
  }

  state = this.initState;
  rules = {
    id: "required;isDigit;minValue=1",
    paragraf: "required;hasFormat=^.+ §.+$,1.3 §3-7",
    del: "required",
    belop: "required;isDigit",
    kortTekst: "required",
    langTekst: "required",
    arkivKode: "required",
    aktivertDato: "isDato;isLessThan=deaktivertDato",
    deaktivertDato: "isDato;isLargerThan=aktivertDato",
    overtredelseGruppeId: "required",
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { deaktiveringConfirmed } = this.state;
    if (
      deaktiveringConfirmed === true &&
      prevState.deaktiveringConfirmed !== deaktiveringConfirmed
    ) {
      this.saveAndClose();
    }
  };

  closeModal = () => {
    this.setState(this.initState);
  };

  openModal = () => {
    this.setState({ open: true });
  };

  isValid = () => {
    const { isValid, validationError } = IsValidated(this.state, this.rules);
    this.setState({ validationError });
    return isValid;
  };

  saveAndClose = () => {
    const { deaktiveringConfirmed, deaktivertDato } = this.state;

    if (this.isValid() && (deaktiveringConfirmed || !deaktivertDato)) {
      this.setState({ submitButtonText: "Legger til..." });

      const aktivertDato = this.state.aktivertDato
        ? this.state.aktivertDato
        : null;
      const deaktivertDato = this.state.deaktivertDato
        ? this.state.deaktivertDato
        : null;

      this.props
        .postOvertredelseskoderMiljogebyr({
          id: this.state.id && Number(this.state.id),
          paragraf: this.state.paragraf,
          del: this.state.del,
          belop: this.state.belop && Number(this.state.belop),
          kortTekst: this.state.kortTekst,
          langTekst: this.state.langTekst,
          arkivKode: this.state.arkivKode,
          overtredelseGruppeId: this.state.overtredelseGruppeId,
          aktivertDato,
          deaktivertDato,
        })
        .then(() => {
          this.closeModal();
        })
        .catch(() =>
          this.setState({ submitButtonText: this.initState.submitButtonText })
        );
    } else if (this.isValid() && deaktivertDato) {
      this.setState({ open: false, openDeaktiverConfirm: true });
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    onChangeValidation(e, this.state, this.rules);
  };

  render() {
    const { overtredelsegrupper } = this.props;
    const { openDeaktiverConfirm } = this.state;

    return (
      <>
        <ModalGeneric
          modalTitle="Legg til ny overtredelseskode for miljøgebyr"
          open={this.state.open}
          closeModal={this.closeModal}
          openModal={this.openModal}
          OpenModalComponent={OpenModalComponent}
          submit={this.saveAndClose}
          submitButtonText="Legg til ny"
        >
          <OvertredelseskoderMiljogebyrCreateModalContent
            id={this.state.id}
            paragraf={this.state.paragraf}
            del={this.state.del}
            belop={this.state.belop}
            kortTekst={this.state.kortTekst}
            langTekst={this.state.langTekst}
            arkivkode={this.state.arkivKode}
            overtredelseGruppeId={this.state.overtredelseGruppeId}
            aktivertDato={this.state.aktivertDato}
            deaktivertDato={this.state.deaktivertDato}
            overtredelseGrupper={overtredelsegrupper}
            validationError={this.state.validationError}
            handleChange={this.handleChange.bind(this)}
          />
        </ModalGeneric>
        <ModalGeneric
          modalTitle="Deaktiver overtredelseskode"
          open={openDeaktiverConfirm}
          closeModal={() =>
            this.setState({ openDeaktiverConfirm: false, open: true })
          }
          submit={() => this.setState({ deaktiveringConfirmed: true })}
          submitButtonText={this.state.submitButtonText}
        >
          <p>Vil du deaktivere overtredelseskode {this.state.id}?</p>
        </ModalGeneric>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    overtredelsegrupper: state.overtredelsegrupper,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    postOvertredelseskoderMiljogebyr: (data) => {
      return dispatch(postOvertredelseskoderMiljogebyrAction(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OvertredelseskoderMiljogebyr);
