import * as miljokontrollActions from "../actions/miljokontrollerActions";

const INITIAL_STATE = {};

export default function miljoKontrollerLightboxBilderReducer(
  state = { ...INITIAL_STATE },
  action
) {
  switch (action.type) {
    case miljokontrollActions.GET_MILJOKONTROLL_LIGHTBOX_BILDER_SUCCESS:
      return {
        ...state,
        [action.payload.miljokontrollId]: action.payload.bilder,
      };
    default:
      return state;
  }
}
