import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import includes from "lodash/includes";

import ModalGenericConfirmCancelContainer from "../../../Common/ModalGenericConfirmCancelContainer";
import EndreMiljogebyrModalForm from "../EndreMiljogebyrModalForm";

import { putStedsbeskrivelse } from "../../../../actions/commonActions";
import {
  getMiljogebyr as getMiljogebyrAction,
  putMiljogebyrer as putMiljogebyrerAction,
  getMiljogebyrerHistorikk as getMiljogebyrerHistorikkAction,
} from "../../../../actions/miljogebyrerActions";
import { getMiljoleveringstyper as getMiljoleveringstyperAction } from "../../../../actions/miljoleveringstyperActions";
import { getOvertredelseskoderMiljogebyr as getOvertredelseskoderMiljogebyrAction } from "../../../../actions/overtredelseskoderMiljogebyrActions";
import { getInternkoderMiljogebyr as getInternkoderMiljogebyrAction } from "../../../../actions/internkoderMiljogebyrActions";
import { addFlashMessage as addFlashMessageAction } from "../../../../actions/FlashMessagesAction";
import {
  getMiljogebyrEttersendelser as getMiljogebyrEttersendelserAction,
  postMiljogebyrEttersendelser as postMiljogebyrEttersendelserAction,
} from "../../../../actions/miljogebyrEttersendelserActions";

import { getSelectOptionsEnhanced } from "../../../../selectors/selectOptionsSelector";
import { getOvertredelseskoderMiljogebyrAsDropDownListSearchFriendly } from "../../../../selectors/miljogebyrSelector";

import { stateModified } from "../../../../utils/stateUtils";

import {
  ettersendelsesrapportMinLength,
  ettersendelsesrapportMaxLength,
} from "../../../../constants/globals";
import { commentHasToBeBetween } from "../../../../constants/ErrorMessages";

class EndreMiljogebyrContainer extends React.Component {
  static propTypes = {
    miljogebyr: PropTypes.object,
    addFlashMessage: PropTypes.func.isRequired,
    getMiljogebyr: PropTypes.func.isRequired,
    putMiljogebyrer: PropTypes.func.isRequired,
    getMiljoleveringstyper: PropTypes.func.isRequired,
    getOvertredelseskoderMiljogebyr: PropTypes.func.isRequired,
    getInternkoderMiljogebyr: PropTypes.func.isRequired,
  };

  get initialState() {
    const { miljogebyr } = this.props;
    return {
      gateNavn: miljogebyr.gatenavn ? miljogebyr.gatenavn : "",
      gateNummer: miljogebyr.gatenummer ? miljogebyr.gatenummer : "",
      oppgang: miljogebyr.oppgang ? miljogebyr.oppgang : "",
      stedsBeskrivelseId: miljogebyr.stedsBeskrivelseId
        ? miljogebyr.stedsBeskrivelseId
        : "",
      bydel: miljogebyr.bydel ? miljogebyr.bydel : "",
      vedGate: miljogebyr.vedGate ? miljogebyr.vedGate : "",
      vedOppgang: miljogebyr.vedOppgang ? miljogebyr.vedOppgang : "",
      vedGateNummer: miljogebyr.vedGatenummer ? miljogebyr.vedGatenummer : "",
      mottaker: miljogebyr.mottaker ? miljogebyr.mottaker : "",
      fodsnrOrOrgnr: miljogebyr.mottakerId ? miljogebyr.mottakerId : "",
      mottakertypeId: miljogebyr.mottakertype ? miljogebyr.mottakertype : null,
      mottakerGate: miljogebyr.mottakerGate ? miljogebyr.mottakerGate : "",
      mottakerGateNummer: miljogebyr.mottakerGateNummer
        ? miljogebyr.mottakerGateNummer
        : "",
      mottakerPostnummer: miljogebyr.mottakerPostnummer
        ? miljogebyr.mottakerPostnummer
        : "",
      mottakerPoststed: miljogebyr.mottakerPostSted
        ? miljogebyr.mottakerPostSted
        : "",
      gnr: miljogebyr.gnr ? miljogebyr.gnr : null, //HGK-80: changed type of 'gnr' and 'bnr' string -> number
      bnr: miljogebyr.bnr ? miljogebyr.bnr : null, // to match types in backend 
      belop: miljogebyr.belop ? miljogebyr.belop : null,
      leveringstype: miljogebyr.leveringstype && miljogebyr.leveringstype.id,
      overtredelse1: miljogebyr.overtredelse1 && miljogebyr.overtredelse1.id,
      overtredelse2: miljogebyr.overtredelse2
        ? miljogebyr.overtredelse2.id
        : null,
      overtredelse3: miljogebyr.overtredelse3
        ? miljogebyr.overtredelse3.id
        : null,
      internkode1: miljogebyr.internkode1 ? miljogebyr.internkode1.id : null,
      internkode2: miljogebyr.internkode2 ? miljogebyr.internkode2.id : null,
      internkode3: miljogebyr.internkode3 ? miljogebyr.internkode3.id : null,
      ettersendelsesrapport: null,
      showEttersendelsesrapportTextArea: false,
      ettersendelsesrapportValidationErrorText: null,
      internkommentar: miljogebyr.internkommentar
        ? miljogebyr.internkommentar
        : "",
      kommentarTilMottaker: miljogebyr.kommentar ? miljogebyr.kommentar : "",
      stedModified: false,
      miljoModified: false,
      ettersendelsesrapportModified: false,
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      ...this.initialState,
    };
  }

  componentDidMount() {
    this.props.getMiljoleveringstyper();
    this.props.getOvertredelseskoderMiljogebyr();
    this.props.getInternkoderMiljogebyr();
  }

  componentDidUpdate(prevProps, prevState) {
    const { miljogebyr } = this.props;
    const { showEttersendelsesrapportTextArea, leveringstype } = this.state;

    if (
      !showEttersendelsesrapportTextArea &&
      prevState.leveringstype !== leveringstype &&
      leveringstype === 3 &&
      miljogebyr &&
      miljogebyr.leveringstype &&
      miljogebyr.leveringstype.id !== leveringstype
    ) {
      this.setState({ showEttersendelsesrapportTextArea: true });
    } else if (
      showEttersendelsesrapportTextArea &&
      miljogebyr &&
      miljogebyr.leveringstype &&
      miljogebyr.leveringstype.id === leveringstype &&
      (prevProps.miljogebyr.leveringstype.id !== miljogebyr.leveringstype.id ||
        prevState.leveringstype !== leveringstype)
    ) {
      this.setState({
        showEttersendelsesrapportTextArea: false,
        ettersendelsesrapport: this.initialState.ettersendelsesrapport,
        ettersendelsesrapportValidationErrorText:
          this.initialState.ettersendelsesrapportValidationErrorText,
        ettersendelsesrapportModified:
          this.initialState.ettersendelsesrapportModified,
      });
    }
  }

  areInputFieldsValidChange = (name, value) => {
    const { ettersendelsesrapportValidationErrorText } = this.state;

    if (
      name === "ettersendelsesrapport" &&
      ettersendelsesrapportValidationErrorText &&
      value &&
      value.length >= ettersendelsesrapportMinLength &&
      value.length <= ettersendelsesrapportMaxLength
    ) {
      this.setState({
        ettersendelsesrapportValidationErrorText:
          this.initialState.ettersendelsesrapport,
      });
    }
  };

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    this.parseNumberAndSetState(name, value);
    this.areInputFieldsValidChange(name, value);
    this.checkStateIsModified(name, value);
  };

  handleBlur = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    if (typeof value === "string") {
      value = value.trim();
    }
    this.parseNumberAndSetState(name, value);
  };

  //HGK-80: parser function for number fields in form
  parseNumberAndSetState = (name, value) => {
    if (name === "bnr" || name === "gnr" || name === "belop")  {
      this.setState({ [name]: Number(value) });
    } else {
      this.setState({ [name]: value });
    }
  };

  checkStateIsModified = (name, val) => {
    if (includes(this.stedsBeskrivelseProps(), name)) {
      stateModified(name, val, this.initialState, this.state, "stedModified");
    } else if (includes(this.miljogebyrProps(), name)) {
      stateModified(name, val, this.initialState, this.state, "miljoModified");
    } else if (
      name === "ettersendelsesrapport" &&
      this.initialState &&
      val !== this.initialState.ettersendelsesrapport
    ) {
      stateModified(
        name,
        val,
        this.initialState,
        this.state,
        "ettersendelsesrapportModified"
      );
    }
  };

  setPropsToInitialState = () => {
    this.setState({ ...this.initialState });
  };

  miljogebyrProps = () => {
    return [
      "mottaker",
      "fodsnrOrOrgnr",
      "mottakertypeId",
      "mottakerGate",
      "mottakerGateNummer",
      "mottakerPostnummer",
      "mottakerPoststed",
      "gnr",
      "bnr",
      "leveringstype",
      "overtredelse1",
      "overtredelse2",
      "overtredelse3",
      "internkode1",
      "internkode2",
      "internkode3",
      "kommentarTilMottaker",
      "internkommentar",
      "belop",
    ];
  };

  stedsBeskrivelseProps = () => {
    return [
      "bydel",
      "gateNavn",
      "gateNummer",
      "oppgang",
      "vedGate",
      "vedGateNummer",
      "vedOppgang",
    ];
  };

  handleApiCall = async (miljogebyrId) => {
    if (!this.areInputFieldsValidSubmit()) {
      return Promise.reject("Valideringsfeil");
    }
    const { addFlashMessage } = this.props;
    const {
      stedsBeskrivelseId,
      stedModified,
      miljoModified,
      ettersendelsesrapportModified,
    } = this.state;
    let putError = null;
    if (miljoModified && !putError) {
      let putMiljogebyrerData = await this.props.putMiljogebyrer(
        miljogebyrId,
        this.state
      );
      putError = putMiljogebyrerData.resolved
        ? putError
        : putMiljogebyrerData.data;
    }
    if (ettersendelsesrapportModified && !putError) {
      await this.props
        .postMiljogebyrEttersendelser(
          this.props.miljogebyr.id,
          this.state.ettersendelsesrapport
        )
        .then((response) =>
          this.props.getMiljogebyrEttersendelser(miljogebyrId)
        )
        .catch((error) => (putError = error));
    }
    if (stedModified && !putError) {
      let putStedsbeskrivelseData = await this.props.putStedsbeskrivelse(
        stedsBeskrivelseId,
        this.state
      );
      putError = putStedsbeskrivelseData.resolved
        ? putError
        : putStedsbeskrivelseData.data;
    }
    if (
      !putError &&
      (miljoModified || ettersendelsesrapportModified || stedModified)
    ) {
      this.props.getMiljogebyr(miljogebyrId);
      await this.props.getMiljogebyrerHistorikk(miljogebyrId);
      this.setState({
        miljoModified: false,
        ettersendelsesrapportModified: false,
        stedModified: false,
      });
      addFlashMessage("success", "Endring fullført");
      return Promise.resolve(true);
    }
    addFlashMessage("error", "Endring feilet");
    return Promise.reject(false);
  };

  areInputFieldsValidSubmit = () => {
    const { showEttersendelsesrapportTextArea, ettersendelsesrapport } =
      this.state;

    if (
      showEttersendelsesrapportTextArea &&
      (!ettersendelsesrapport ||
        ettersendelsesrapport.length < ettersendelsesrapportMinLength ||
        ettersendelsesrapport.length > ettersendelsesrapportMaxLength)
    ) {
      var ettersendelsesrapportValidationErrorText = commentHasToBeBetween(
        ettersendelsesrapportMinLength,
        ettersendelsesrapportMaxLength
      );
      this.setState(() => ({ ettersendelsesrapportValidationErrorText }));
      return false;
    }
    return true;
  };

  render() {
    if (!this.props.miljogebyr.isMakulert) {
      return (
        <ModalGenericConfirmCancelContainer
          id={this.props.miljogebyr.id}
          className="miljogebyrDetailPutModal"
          modalTitle={`Endre miljøgebyr  ${this.props.miljogebyr.ileggelsesnummer}`}
          submitButtonText="Lagre endring"
          submittingButtonText="Lagrer endring..."
          handleConfirmClick={() =>
            this.handleApiCall(this.props.miljogebyr.id)
          }
          handleCancelAndCloseClick={this.setPropsToInitialState}
          openModalComponentFunction={(endreModalProps) => (
            <Button
              className="btn btn-bym-standard"
              style={{ marginLeft: 20 }}
              onClick={endreModalProps.openModal}
            >
              Endre miljøgebyr
            </Button>
          )}
        >
          <EndreMiljogebyrModalForm
            {...this.state}
            warningText={
              this.props.miljogebyr.isSendtKemner // Skal stå isSendtKemner tils backend er endret til isSendtIne
                ? "Dette miljøgebyret er allerede sendt til INE"
                : ""
            }
            showEttersendelsesrapportTextArea={
              this.state.showEttersendelsesrapportTextArea
            }
            mottakertyper={this.props.mottakertyper}
            leveringstyper={this.props.leveringstyper}
            overtredelseskoder={this.props.overtredelseskoder}
            internkoder={this.props.internkoder}
            handleChange={this.handleChange}
            handleBlur={this.handleBlur}
          />
        </ModalGenericConfirmCancelContainer>
      );
    } else {
      return <span />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    miljogebyr: state.miljogebyr,
    mottakertyper: getSelectOptionsEnhanced(
      state,
      "mottakertyper",
      "navn",
      "id"
    ),
    leveringstyper: getSelectOptionsEnhanced(
      state,
      "miljoleveringstyper",
      "navn",
      "id"
    ),
    overtredelseskoder:
      getOvertredelseskoderMiljogebyrAsDropDownListSearchFriendly(state),
    internkoder: getSelectOptionsEnhanced(
      state,
      "internkoderMiljogebyr",
      "beskrivelse",
      "id"
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addFlashMessage: (type, text) =>
      dispatch(addFlashMessageAction({ type, text })),
    getMiljogebyr: (miljogebyrId) =>
      dispatch(getMiljogebyrAction(miljogebyrId)),
    putMiljogebyrer: (miljogebyrId, params) =>
      dispatch(putMiljogebyrerAction(miljogebyrId, params)),
    getMiljoleveringstyper: () => dispatch(getMiljoleveringstyperAction()),
    getOvertredelseskoderMiljogebyr: () =>
      dispatch(getOvertredelseskoderMiljogebyrAction()),
    getInternkoderMiljogebyr: () => dispatch(getInternkoderMiljogebyrAction()),
    putStedsbeskrivelse: (id, params) =>
      dispatch(putStedsbeskrivelse(id, params)),
    getMiljogebyrerHistorikk: (miljogebyrId) =>
      dispatch(getMiljogebyrerHistorikkAction(miljogebyrId)),
    getMiljogebyrEttersendelser: (miljogebyrId) =>
      dispatch(getMiljogebyrEttersendelserAction(miljogebyrId)),
    postMiljogebyrEttersendelser: (miljogebyrId, ettersendelsesrapport) =>
      dispatch(
        postMiljogebyrEttersendelserAction(miljogebyrId, {
          kommentar: ettersendelsesrapport,
        })
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EndreMiljogebyrContainer);
