import { connect } from "react-redux";

import GrunndataFilter from "./GrunndataFilter";

import { handleChange as handleChangeAction } from "../../actions/handleChangeAction";

const mapStateToProps = (state) => ({
  grunndataFilterForm: state.forms.grunndataFilterForm,
});

const mapDispatchToProps = (dispatch) => ({
  handleChange: (name, value) =>
    dispatch(handleChangeAction("GRUNNDATA_FILTER_FORM", name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GrunndataFilter);
