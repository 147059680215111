import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ModalGeneric from "../../../Common/ModalGeneric";
import UpdateOpenModal from "../../CreateModals/UpdateOpenModal";
import OvertredelseskoderMiljogebyrUpdateModalContent from "../../OvertredelseskoderMiljogebyrForm";

import { putOvertredelseskoderMiljogebyr } from "../../../../actions/overtredelseskoderMiljogebyrActions";

import {
  IsValidated,
  onChangeValidation,
} from "../../../../utils/validationUtils";

export class OvertredelseskoderMiljogebyr extends React.Component {
  static propTypes = {
    overtredelseskoderMiljogebyr: PropTypes.object,
    postOvertredelseskoderMiljogebyr: PropTypes.func,
  };

  get initState() {
    const overtredelseskode = this.props.overtredelseskoderMiljogebyr;
    return {
      open: false,
      submitButtonText: "Lagre",
      id: overtredelseskode.id,
      paragraf: overtredelseskode.paragraf,
      del: overtredelseskode.del,
      belop: overtredelseskode.belop,
      kortTekst: overtredelseskode.kortTekst,
      langTekst: overtredelseskode.langTekst,
      arkivKode: overtredelseskode.arkivKode,
      aktiv: overtredelseskode.aktiv,
      aktivertDato: overtredelseskode.aktivertDato,
      deaktivertDato: overtredelseskode.deaktivertDato,
      overtredelseGruppeId: overtredelseskode.overtredelseGruppe
        ? overtredelseskode.overtredelseGruppe.id
        : "",
      validationError: {},

      openDeaktiverConfirm: false,
      deaktiveringConfirmed: false,
    };
  }
  state = this.initState;
  rules = {
    id: "required;isDigit",
    paragraf: "required;hasFormat=^.+ §.+$,1.3 §3-7",
    del: "required",
    belop: "required;isDigit",
    kortTekst: "required",
    langTekst: "required",
    arkivKode: "required",
    overtredelseGruppeId: "required",
    aktivertDato: "required;isDato;isLessThan=deaktivertDato",
    deaktivertDato: "required;isDato;isFuture;isLargerThan=aktivertDato",
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { deaktiveringConfirmed } = this.state;
    if (
      deaktiveringConfirmed === true &&
      prevState.deaktiveringConfirmed !== deaktiveringConfirmed
    ) {
      this.saveAndClose();
    }
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (JSON.stringify(nextProps) !== JSON.stringify(this.props)) {
      const overtredelseskode = nextProps.overtredelseskoderMiljogebyr;

      this.setState({
        id: overtredelseskode.id,
        paragraf: overtredelseskode.paragraf,
        del: overtredelseskode.del,
        belop: overtredelseskode.belop,
        kortTekst: overtredelseskode.kortTekst,
        langTekst: overtredelseskode.langTekst,
        arkivKode: overtredelseskode.arkivKode,
        aktiv: overtredelseskode.aktiv,
        aktivertDato: overtredelseskode.aktivertDato,
        deaktivertDato: overtredelseskode.deaktivertDato,
        overtredelseGruppeId: overtredelseskode.overtredelseGruppe
          ? overtredelseskode.overtredelseGruppe.id
          : "",
      });
    }
  };

  closeModal = () => {
    this.setState(this.initState);
  };

  openModal = () => {
    this.setState({ open: true });
  };

  isValid = () => {
    const { isValid, validationError } = IsValidated(this.state, this.rules);
    this.setState({ validationError });
    return isValid;
  };

  saveAndClose = () => {
    const { deaktiveringConfirmed, deaktivertDato } = this.state;

    if (this.isValid() && (deaktiveringConfirmed || !deaktivertDato)) {
      this.setState({ submitButtonText: "Lagrer..." });

      this.props
        .putOvertredelseskoderMiljogebyr(this.state.id, {
          paragraf: this.state.paragraf,
          del: this.state.del,
          belop: this.state.belop && Number(this.state.belop),
          kortTekst: this.state.kortTekst,
          langTekst: this.state.langTekst,
          arkivKode: this.state.arkivKode,
          overtredelseGruppeId: this.state.overtredelseGruppeId,
          aktivertDato: this.state.aktivertDato,
          deaktivertDato: this.state.deaktivertDato,
        })
        .then(() => {
          this.closeModal();
        })
        .catch(() =>
          this.setState({ submitButtonText: this.state.submitButtonText })
        );
    } else if (this.isValid() && deaktivertDato) {
      this.setState({ open: false, openDeaktiverConfirm: true });
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    onChangeValidation(e, this.state, this.rules);
  };

  render() {
    const { overtredelsegrupper } = this.props;
    const { openDeaktiverConfirm } = this.state;

    return (
      <>
        <ModalGeneric
          modalTitle="Rediger overtredelseskoder ileggelser"
          open={this.state.open}
          closeModal={this.closeModal}
          openModal={this.openModal}
          OpenModalComponent={UpdateOpenModal}
          submit={this.saveAndClose}
          submitButtonText={this.state.submitButtonText}
        >
          <OvertredelseskoderMiljogebyrUpdateModalContent
            id={this.state.id}
            paragraf={this.state.paragraf}
            del={this.state.del}
            belop={this.state.belop}
            kortTekst={this.state.kortTekst}
            langTekst={this.state.langTekst}
            arkivKode={this.state.arkivKode}
            aktiv={this.state.aktiv}
            aktivertDato={this.state.aktivertDato}
            deaktivertDato={this.state.deaktivertDato}
            overtredelseGruppeId={this.state.overtredelseGruppeId}
            overtredelseGrupper={overtredelsegrupper}
            validationError={this.state.validationError}
            handleChange={this.handleChange}
            actionType="update"
          />
        </ModalGeneric>
        <ModalGeneric
          modalTitle="Deaktiver overtredelseskode"
          open={openDeaktiverConfirm}
          closeModal={() =>
            this.setState({ openDeaktiverConfirm: false, open: true })
          }
          submit={() => this.setState({ deaktiveringConfirmed: true })}
          submitButtonText={this.state.submitButtonText}
        >
          <p>Vil du deaktivere overtredelseskode {this.state.id}?</p>
        </ModalGeneric>
      </>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    overtredelsegrupper: state.overtredelsegrupper,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({ putOvertredelseskoderMiljogebyr }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OvertredelseskoderMiljogebyr);
