import React from "react";
import { Button } from "react-bootstrap";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const PlusButton = (props) => {
  return (
    <Button
      className={`btn-bym-standard pull-${props.position}`}
      style={{ marginBottom: 10, paddingRight: 20 }}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <FontAwesomeIcon
        icon={faPlus}
        style={{ color: "#ffa500", paddingTop: 3, paddingRight: 10 }}
        size="lg"
        fixedWidth
      />
      {props.text}
    </Button>
  );
};

export default PlusButton;
