import axios from "../services/axios";
import * as api from "../constants/api";

export function putStedsbeskrivelse(stedsBeskrivelseId, state) {
  const payload = Object.assign({
    bydel: state.bydel,
    gateNavn: state.gateNavn,
    gateNummer: state.gateNummer,
    oppgang: state.oppgang,
    vedGate: state.vedGate,
    vedGateNummer: state.vedGateNummer,
    vedOppgang: state.vedOppgang
  });
  return async () => {
    return axios
      .put(api.PUT_STEDSBESKRIVELSE_URL(stedsBeskrivelseId), payload)
      .then(response => ({ resolved: true, data: response }))
      .catch(error => ({ resolved: false, data: error }));
  };
}

export function putKjoretoy(kjoretoyId, payload) {
  return async () => {
    return axios
      .put(api.PUT_KJORETOY_URL(kjoretoyId), payload)
      .then(response => ({ resolved: true, data: response }))
      .catch(error => ({ resolved: false, data: error }));
  };
}

export function putBillett(billetId, billettNummer) {
  const payload = { billettnummer: billettNummer };
  return async () => {
    return axios
      .put(api.PUT_BILLET_URL(billetId), payload)
      .then(response => ({ resolved: true, data: response }))
      .catch(error => ({ resolved: false, data: error }));
  };
}
