import { get, update, pull, isArray, cloneDeep } from "lodash";

export function setWithArrayPush(state, path, item) {
  return update(cloneDeep(state), path, (array = []) => {
    if (!isArray(array)) throw new Error("Not an array");
    array.push(item);
    return array;
  });
}

export function setWithArrayPull(state, path, item) {
  return update(cloneDeep(state), path, (array = []) => {
    if (!isArray(array)) throw new Error("Not an array");
    pull(array, item);
    return array;
  });
}

export function getStateWithStringPath(state, path) {
  return get(state, path.split("."));
}

export function stateModified(name, value, initState, state, modifiedFlag) {
  const initStateValue = initState[name];
  if (initStateValue !== value) state[modifiedFlag] = true;
  else state[modifiedFlag] = false;
}

export const SetItem = (key, data) => {
  try {
    if (data && typeof data === "object")
      localStorage.setItem(key, JSON.stringify(data));
    else localStorage.setItem(key, data);
  } catch (e) {
    console.log(e);
  }
};

export const GetItem = key => {
  try {
    const data = localStorage.getItem(key);
    if (data && typeof data === "object") return JSON.parse(data);
  } catch (e) {
    console.log(e);
  }
};

export const RemoveItem = key => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    console.log(e);
  }
};

export const ClearItems = () => {
  try {
    localStorage.clear();
  } catch (e) {
    console.log(e);
  }
};
