import { ILEGGELSE_SERVICE_BASE_URL } from "../config";

//klagekoder
export const GET_KLAGE_KODER_URL = `${ILEGGELSE_SERVICE_BASE_URL}/klagekoder`;

// klagebehandling & vedtaker
export const ADD_KLAGE_URL = `${ILEGGELSE_SERVICE_BASE_URL}/klager`;
export const UPDATE_KLAGER_URL = (klageId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/klager/${klageId}`;
export const GET_KLAGER_URL = (saksnummer) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/klager/?saksnummer=${saksnummer}`;
export const DELETE_PERMANENT_KLAGE_URL = (klageId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/klager/${klageId}/delete-permanent`;
export const KLAGE_VEDTAKER_URL = (klageId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/klager/${klageId}/vedtaker`;
export const UPDATE_KLAGE_VEDTAK_URL = (klageId, vedtakId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/klager/${klageId}/vedtaker/${vedtakId}`;

export const UPDATE_VEDTAK_FERDIGBEHANDLET_URL = (klageId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/klager/${klageId}/vedtaker/FerdigBehandlet`;

export const DELETE_KLAGE_VEDTAK_URL = (klageId, vedtakId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/klager/${klageId}/vedtaker/${vedtakId}`;

export const POST_KLAGER_DOKUMENTER = (klageId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/klager/${klageId}/dokumenter`;

export const KLAGE_ILEGGELSE_URL = (klageId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/klager/${klageId}/ileggelser`;

export const ADD_KLAGE_ILEGGELSE_URL = (klageId, ileggelsesnummer) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/klager/${klageId}/ileggelser/${ileggelsesnummer}`;
export const GET_KLAGE_ILEGGELSE_URL = (klageId, ileggelsesnummer) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/klager/${klageId}/ileggelser/${ileggelsesnummer}`;
export const DELETE_KLAGE_ILEGGELSE_URL = (klageId, ileggelseId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/klager/${klageId}/ileggelser/${ileggelseId}`;
