import React, { useEffect } from "react";

import NavigationBarContainer from "../NavigationBarContainer";
import AArsrapportFilterFormContainer from "./AArsrapportFilterFormContainer";
import AArsrapportExcelContainer from "./AArsrapportExcelContainer";
import AArsrapportListsContainer from "./AArsrapportListsContainer";

import { rapporter } from "../../../constants/titles";

const AArsrapportPage = () => {
  useEffect(() => {
    document.title = rapporter.aarsrapport;
  }, []);

  return (
    <React.Fragment>
      <NavigationBarContainer page="arsrapport" />
      <AArsrapportFilterFormContainer />
      <div className="rapport-buttons-container">
        <AArsrapportExcelContainer />
      </div>
      <div className="rapport-table">
        <AArsrapportListsContainer />
      </div>
    </React.Fragment>
  );
};

export default AArsrapportPage;
