import { combineReducers } from "redux";

import aarsrapportReducer from "./aarsrapportReducer";
import antallIleggelserPerIleggelsetypeRapportReducer from "./antallIleggelserPerIleggelsetypeRapportReducer";
import makuleringerRapportReducer from "./makuleringerRapportReducer";
import ubetalteIleggelserKjennemerkeGroupsReducer from "./ubetalteIleggelserKjennemerkeGroupsReducer";

import createAdvancedListReducer from "../createAdvancedListReducer";
import ubetalteIleggelserSingleKjennemerkeReducer from "./ubetalteIleggelserSingleKjennemerkeReducer";

export default combineReducers({
  aarsrapport: aarsrapportReducer,
  antallIleggelserPerIleggelsetypeRapport:
    antallIleggelserPerIleggelsetypeRapportReducer,
  makuleringerRapport: createAdvancedListReducer(
    "RAPPORTER.MAKULERINGERRAPPORT",
    makuleringerRapportReducer,
    { cellKey: "ileggelsesdatoTil", orders: "desc" }
  ),
  ubetalteIleggelserKjennemerkeGroups:
    ubetalteIleggelserKjennemerkeGroupsReducer,
  ubetalteIleggelserSingleKjennemerke:
    ubetalteIleggelserSingleKjennemerkeReducer,
});
