import React from "react";
import { Row, Col } from "react-bootstrap";
import FormButtonsForCloseAndSubmit from "../../Common/Forms/FormButtonsForCloseAndSubmit";
import DatetimePicker from "../../Common/Forms/DatetimePickerEnhanced";
import DropDownListMultiCheckboxOptions from "../../Common/Forms/DropDownListMultiCheckboxOptions";
import DropDown from "../../Common/Forms/DropDown";

export const KlagerapportFilterForm = ({ ...props }) => {
  return (
    <form onSubmit={props.handleSubmit} className="SearchHistorikkForm">
      <div className="searchHistorikkOverskrift">
        Klagestatistikk basert på ileggelsesdato
      </div>
      <div className="filter-flexbox-container klager-filter-flexbox">
        <DatetimePicker
          id="fraDato"
          name="fraDato"
          locale="nb"
          label="Dato fra"
          placeHolder="Velg dato"
          onChange={props.handleChange}
          value={props.klageRapportFilterFormState.fraDato}
          validationError={
            props.klageRapportFilterFormState.fraDatoValidationErrorText
          }
          timeFormat={false}
        />
        <DatetimePicker
          id="tilDato"
          name="tilDato"
          locale="nb"
          label="Dato til"
          placeHolder="Velg dato"
          onChange={props.handleChange}
          value={props.klageRapportFilterFormState.tilDato}
          validationError={
            props.klageRapportFilterFormState.tilDatoValidationErrorText
          }
          timeFormat={false}
        />
        <DropDownListMultiCheckboxOptions
          label="Ileggelsestype"
          name="selectedIleggelsestyper"
          items={props.ileggelsestyper.filter((it) => it.navn !== "Avgift")}
          selected={props.klageRapportFilterFormState.selectedIleggelsestyper}
          title={
            props.klageRapportFilterFormState.selectedIleggelsestyper.length > 0
              ? `${props.klageRapportFilterFormState.selectedIleggelsestyper.length} valg`
              : "Velg"
          }
          onChange={props.handleChange}
        />
        <DropDown
          id="sortOptions"
          name="selectedSort"
          label="Sorter på"
          title="Velg"
          selected={props.klageRapportFilterFormState.selectedSort}
          items={props.sortOptions}
          onChange={props.handleChange}
        />
      </div>
      <Row>
        <Col sm={12}>{props.error}</Col>
      </Row>
      <Row>
        <Col sm={12}>
          <FormButtonsForCloseAndSubmit
            submitText="Søk"
            onClose={props.handleChangeToInitState}
            showSubmitSpinner={props.showSokSpinner}
          />
        </Col>
      </Row>
    </form>
  );
};

export default KlagerapportFilterForm;
