import { GET_P360_SAK } from "../actions/p360Action";

export const p360Reducer = (state = {}, action) => {
  switch (action.type) {
    case GET_P360_SAK:
      return action.payload;
    default:
      return state;
  }
};

export default p360Reducer;
