import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import List from "./List";
import createPaginationControl from "../../Common/createPaginationControl";
import FilterContainer from "./FilterContainer";
import NavigationBarContainer from "../NavigationBarContainer";
import Spinner from "../../Common/Spinner";
import PrintOut from "../../Common/PrintOut";
import Excel from "../../Common/Excel";

import {
  getIleggelseList,
  getIleggelserForOversikt,
  getIleggelserInExcelFormat,
} from "../../../selectors/ileggelserSelector";

import { getIleggelser as getIleggelserAction } from "../../../actions/ileggelserActions";
import { getOvertredelserkoderIleggelser as getOvertredelserkoderIleggelserAction } from "../../../actions/overtredelseskoderIleggelserActions";

import { authorized } from "../../../utils/authorizationUtils";

import { juridiskArray } from "../../../constants/roles";

import * as title from "../../../constants/titles";
import sitePaths from "../../../constants/sitePaths";
import "./IleggelserOversiktContainer.css";

const PaginationControl = createPaginationControl(
  "ileggelser",
  {
    apiCallAction: getIleggelserAction,
    queriesForApiReducerName: "forms.ileggelserOversiktFilterForm",
  },
  getIleggelseList
);

class OversiktContainer extends Component {
  static propTypes = {
    history: PropTypes.object,
    ileggelser: PropTypes.object,
    ileggelserOversiktFilterForm: PropTypes.object,
    ileggelserInExcelFormat: PropTypes.array,
    getIleggelser: PropTypes.func,
    getOvertredelserkoderIleggelser: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      waitingForGetIleggelser: false,
    };
  }

  componentDidMount() {
    document.title = title.ileggelse.ileggelsesoversikt;
    this.props.getOvertredelserkoderIleggelser();
  }

  render() {
    return (
      <>
        <>
          <NavigationBarContainer page="oversikt" />
          <FilterContainer />
          <div style={{ height: 28 }} />
          {(!this.state.waitingForGetIleggelser ||
            this.props.ileggelser.items.length !== 0) && (
            <React.Fragment>
              <div style={{ marginLeft: 50 }}>
                <div className="pull-left">
                  {!authorized(juridiskArray) && (
                    <div style={{ display: "inline-block", marginRight: 10 }}>
                      <PrintOut
                        url={`${window.location.origin}${sitePaths.ileggelser.oversikt.utskrift}`}
                        dataToStoreInSessionStorage={{
                          queryData: {
                            ...this.props.ileggelserOversiktFilterForm,
                          },
                          paginationData: {
                            page: this.props.ileggelser.page,
                            count: this.props.ileggelser.count,
                          },
                        }}
                      />
                    </div>
                  )}
                  <Excel
                    element={(onClick) => (
                      <button
                        className="btn btn-bym-medium btn-bym-action"
                        onClick={onClick}
                      >
                        Lag rapport
                      </button>
                    )}
                    filename="Ileggelser.xlsx"
                    sheets={this.props.ileggelserInExcelFormat}
                  />
                </div>
                <div
                  className="pull-right ileggelse-list-page-total-ileggelser"
                  style={{ paddingTop: 20, paddingRight: 50 }}
                >
                  {this.props.ileggelser.total} ileggelser
                </div>
              </div>
              <div className="clearfix" />
              <div style={{ height: 22 }} />
              <div style={{ paddingRight: 50, paddingLeft: 50 }}>
                <div
                  id="ileggelse-list-page-content-container"
                  className="ileggelse-list-page-content-container"
                >
                  <List
                    ileggelser={this.props.ileggelser.items}
                    ref={(el) => (this.listComponentRef = el)}
                  />
                </div>
                <PaginationControl storeKey="ileggelser" />
                <div className="clearfix" />
              </div>
            </React.Fragment>
          )}
          <Spinner
            isLoading={
              this.state.waitingForGetIleggelser &&
              this.props.ileggelser.items.length === 0
            }
          />
          <div style={{ height: 50 }} />
        </>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ileggelser: getIleggelserForOversikt(state),
    ileggelserOversiktFilterForm: state.forms.ileggelserOversiktFilterForm,
    ileggelserInExcelFormat: getIleggelserInExcelFormat(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getIleggelser: (ileggelserOversiktFilterForm, page, count) =>
      dispatch(getIleggelserAction(ileggelserOversiktFilterForm, page, count)),
    getOvertredelserkoderIleggelser: () =>
      dispatch(getOvertredelserkoderIleggelserAction()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OversiktContainer));
