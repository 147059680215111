import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";

export default class UpdateOpenModal extends React.Component {
  static propTypes = {
    openModal: PropTypes.func,
  };

  render() {
    return (
      <Button
        className="btn btn-link"
        style={{ fontWeight: "normal", padding: 0 }}
        onClick={this.props.openModal}
      >
        Rediger
      </Button>
    );
  }
}
