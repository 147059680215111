import React from "react";
import { Switch, Redirect } from "react-router-dom";

import AuthorizedRoutes from "../components/Routes/AuthorizedRoutes";

import AntallIleggelserPerIleggelsetypePage from "../components/Rapporter/AntallIleggelserPerIleggelsetype/AntallIleggelserPerIleggelsetypePage";
import AArsrapportPage from "../components/Rapporter/AArsrapport/AArsrapportPage";
import PiggdekkontrollPage from "../components/Rapporter/Piggdekkontroll/PiggdekkontrollPage";
import KlagerapportPage from "../components/Rapporter/Klagerapport/KlagerapportPage";
import VentilstillingPage from "../components/Rapporter/Ventilstilling/VentilstillingPage";
import VentilstillingsdetaljPage from "../components/Rapporter/Ventilstilling/VentilstillingsdetaljPage";
import VentilstillingsdetailUtskriftPage from "../components/Rapporter/Ventilstilling/VentilstillingsdetailUtskriftPage";
import SkiftloggPage from "../components/Rapporter/Skiftlogg/SkiftloggPage";
import UferdigstilteKlagesakerPage from "../components/Rapporter/UferdigstilteKlagesaker/UferdigstilteKlagesakerPage";
import MakuleringerPage from "../components/Rapporter/Makuleringer/MakuleringerPage";
import VentilstillingBildevisning from "../components/Rapporter/Ventilstilling/VentilstillingBildevisning";

import { getRedirectPath } from "../utils/pathnameUtils";
import sitePath from "../constants/sitePaths";
import {
  bypatruljen,
  skiftlogg,
  juridisk,
  juridiskArray,
  bypatruljenArray,
} from "../constants/roles";
import UbetalteIleggelserPage from "../components/Rapporter/UbetalteIleggelser/UbetalteIleggelserPage";
import UbetalteIleggelserDetaljPage from "../components/Rapporter/UbetalteIleggelser/Detalj/UbetalteIleggelserDetaljPage";
import IleggelseDetaljPage from "../components/Ileggelse/Detalj/IleggelseDetaljPage";
import SvarUtBrevHistorikk from "../components/Common/Detalj/SvarUtBrevhistorikk";
import IleggelseImageEditorPageContainer from "../components/Common/Ileggelser/Detalj/ImageEditorPageContainer";
import IleggelseOrginalbilde from "../components/Common/Ileggelser/Detalj/IleggelseOrginalbilde";

const Rapporter = () => (
  <Switch>
    <AuthorizedRoutes
      exact
      path={sitePath.rapporter.piggdekkontroller.oversikt}
      roles={[...Object.values(bypatruljen), ...juridiskArray]}
      component={PiggdekkontrollPage}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.rapporter.ventilstillinger.oversikt}
      roles={[...Object.values(bypatruljen), ...Object.values(juridisk)]}
      component={VentilstillingPage}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.rapporter.ventilstillinger.detalj.shortUrl.routeMatcher}
      roles={[...Object.values(bypatruljen), ...Object.values(juridisk)]}
      component={VentilstillingsdetaljPage}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.rapporter.ventilstillinger.detalj.bilde}
      roles={[...Object.values(bypatruljen), ...Object.values(juridisk)]}
      component={VentilstillingBildevisning}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.rapporter.ventilstillinger.detalj.utskrift}
      roles={[...Object.values(bypatruljen), ...Object.values(juridisk)]}
      component={VentilstillingsdetailUtskriftPage}
    />
    <AuthorizedRoutes
      exact
      path={
        sitePath.rapporter.ventilstillinger.ileggelse.detalj.shortUrl
          .routeMatcher
      }
      roles={[...Object.values(bypatruljen), ...Object.values(juridisk)]}
      component={IleggelseDetaljPage}
    />
    <AuthorizedRoutes
      exact
      path={
        sitePath.rapporter.ventilstillinger.ileggelse.detalj.svarUtHistorikk
      }
      roles={[
        bypatruljen.saksbehandler,
        bypatruljen.saksbehandleradmin,
        juridisk.ledelse,
        juridisk.saksbehandler,
      ]}
      component={SvarUtBrevHistorikk}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.rapporter.ventilstillinger.ileggelse.detalj.toastUi}
      roles={[
        bypatruljen.saksbehandleradmin,
        bypatruljen.saksbehandler,
        juridisk.saksbehandler,
      ]}
      component={IleggelseImageEditorPageContainer}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.rapporter.ventilstillinger.ileggelse.detalj.bilde}
      roles={[...Object.values(bypatruljen), ...Object.values(juridisk)]}
      component={IleggelseOrginalbilde}
    />
    <AuthorizedRoutes
      exact
      path={
        sitePath.rapporter.ventilstillinger.detalj.ileggelse.detalj.shortUrl
          .routeMatcher
      }
      roles={[...Object.values(bypatruljen), ...Object.values(juridisk)]}
      component={IleggelseDetaljPage}
    />
    <AuthorizedRoutes
      exact
      path={
        sitePath.rapporter.ventilstillinger.detalj.ileggelse.detalj
          .svarUtHistorikk
      }
      roles={[
        bypatruljen.saksbehandler,
        bypatruljen.saksbehandleradmin,
        juridisk.ledelse,
        juridisk.saksbehandler,
      ]}
      component={SvarUtBrevHistorikk}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.rapporter.ventilstillinger.detalj.ileggelse.detalj.toastUi}
      roles={[
        bypatruljen.saksbehandleradmin,
        bypatruljen.saksbehandler,
        juridisk.saksbehandler,
      ]}
      component={IleggelseImageEditorPageContainer}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.rapporter.ventilstillinger.detalj.ileggelse.detalj.bilde}
      roles={[...Object.values(bypatruljen), ...Object.values(juridisk)]}
      component={IleggelseOrginalbilde}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.rapporter.skiftlogg.oversikt}
      roles={[skiftlogg, ...Object.values(bypatruljen)]}
      component={SkiftloggPage}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.rapporter.klager.oversikt}
      roles={[
        juridisk.ledelse,
        bypatruljen.saksbehandler,
        bypatruljen.saksbehandleradmin,
        bypatruljen.avdelingdirektor,
        bypatruljen.seksjonssjefStab,
      ]}
      component={KlagerapportPage}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.rapporter.ubetalteIleggelser.oversikt}
      roles={[bypatruljen.saksbehandleradmin]}
      component={UbetalteIleggelserPage}
    />
    <AuthorizedRoutes
      path={
        sitePath.rapporter.ubetalteIleggelser.detalj.ileggelser.shortUrl
          .routeMatcher
      }
      roles={[bypatruljen.saksbehandleradmin]}
      component={IleggelseDetaljPage}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.rapporter.ubetalteIleggelser.detalj.shortUrl.routeMatcher}
      roles={[bypatruljen.saksbehandleradmin]}
      component={UbetalteIleggelserDetaljPage}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.rapporter.uferdigstilteKlagesaker.oversikt}
      roles={[
        ...juridiskArray,
        bypatruljen.avdelingdirektor,
        bypatruljen.saksbehandleradmin,
        bypatruljen.saksbehandler,
      ]}
      component={UferdigstilteKlagesakerPage}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.rapporter.antallIleggelserPerIleggelsetype.oversikt}
      roles={[...bypatruljenArray, ...juridiskArray]}
      component={AntallIleggelserPerIleggelsetypePage}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.rapporter.makuleringer.oversikt}
      roles={[
        bypatruljen.avdelingdirektor,
        bypatruljen.seksjonssjefStab,
        bypatruljen.saksbehandleradmin,
        bypatruljen.saksbehandler,
      ]}
      component={MakuleringerPage}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.rapporter.aarsrapport.oversikt}
      roles={[...bypatruljenArray, ...juridiskArray]}
      component={AArsrapportPage}
    />
    <Redirect
      path={sitePath.rapporter.piggdekkontroller.shortUrl}
      to={sitePath.rapporter.piggdekkontroller.oversikt}
    />
    <Redirect
      path={sitePath.rapporter.ventilstillinger.shortUrl}
      to={sitePath.rapporter.ventilstillinger.oversikt}
    />
    <Redirect
      path={sitePath.rapporter.skiftlogg.shortUrl}
      to={sitePath.rapporter.skiftlogg.oversikt}
    />
    <Redirect
      path={sitePath.rapporter.klager.shortUrl}
      to={sitePath.rapporter.klager.oversikt}
    />
    <Redirect
      path={sitePath.rapporter.juridiskSaksbehandler.shortUrl}
      to={sitePath.rapporter.juridiskSaksbehandler.oversikt}
    />
    <Redirect
      path={sitePath.rapporter.uferdigstilteKlagesaker.shortUrl}
      to={sitePath.rapporter.uferdigstilteKlagesaker.oversikt}
    />
    <Redirect
      path={sitePath.rapporter.antallIleggelserPerIleggelsetype.shortUrl}
      to={sitePath.rapporter.antallIleggelserPerIleggelsetype.oversikt}
    />
    <Redirect
      path={sitePath.rapporter.makuleringer.shortUrl}
      to={sitePath.rapporter.makuleringer.oversikt}
    />
    <Redirect
      path={sitePath.rapporter.aarsrapport.shortUrl}
      to={sitePath.rapporter.aarsrapport.oversikt}
    />

    <Redirect
      path={sitePath.rapporter.shortUrl}
      to={getRedirectPath(sitePath.rapporter.shortUrl)}
    />
  </Switch>
);

export default Rapporter;
