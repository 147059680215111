import moment from "moment";
import axios from "../services/axios";
import * as api from "../constants/api";
import * as handle from "../utils/actionUtils";

import { addFlashMessage } from "./FlashMessagesAction";

// import * as responseMessages from "../constants/responseMessages";

export const GET_MILJO_MAKULERINGER_SUCCESS = "GET_MILJO_MAKULERINGER_SUCCESS";
export const GET_MILJO_MAKULERINGER_FAILURE = "GET_MILJO_MAKULERINGER_FAILURE";

export function getMiljoMakuleringer(miljogebyrId) {
  return async (dispatch) => {
    return axios
      .get(api.GET_MILJO_MAKULERINGER_URL(miljogebyrId))
      .then(handle.success(dispatch, GET_MILJO_MAKULERINGER_SUCCESS))
      .catch((error) => {
        dispatch(
          addFlashMessage({
            type: "error",
            message: error,
          })
        );
        return Promise.reject(true);
      });
  };
}

export const POST_MILJO_MAKULERING_SUCCESS = "POST_MILJO_MAKULERING_SUCCESS";
export const POST_MILJO_MAKULERING_FAILURE = "POST_MILJO_MAKULERING_FAILURE";

export function postMiljoMakulering(miljogebyrId, kommentar) {
  return (dispatch, getState) => {
    return axios
      .post(api.POST_MILJO_MAKULERING_URL(miljogebyrId, kommentar), {
        kommentar,
        makulertDato: moment.utc(),
      })
      .then(() => {
        return Promise.resolve(true);
      })
      .catch((response) => {
        dispatch(
          addFlashMessage({
            type: "error",
            message: response,
          })
        );
        handle.error(dispatch, POST_MILJO_MAKULERING_FAILURE);
        return Promise.reject(false);
      });
  };
}

export const DELETE_ILEGGELSE_MAKULERINGER_SUCCESS =
  "DELETE_ILEGGELSE_MAKULERINGER_SUCCESS";
export const DELETE_ILEGGELSE_MAKULERINGER_FAILURE =
  "DELETE_ILEGGELSE_MAKULERINGER_FAILURE";

export function deleteMiljoMakulering(miljoMakuleringId) {
  return (dispatch, getState) => {
    return axios
      .delete(api.DELETE_MILJO_MAKULERINGER_URL(miljoMakuleringId))
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  };
}
