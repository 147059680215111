import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import List from "./List";
import createPaginationControl from "../../Common/createPaginationControl";
import FilterContainer from "./FilterContainer";
import Spinner from "../../Common/Spinner";
import PrintOut from "../../Common/PrintOut";
import Excel from "../../Common/Excel";

import { getMiljogebyrer as getMiljogebyrerAction } from "../../../actions/miljogebyrerActions";
import { setSort as setSortAction } from "../../../actions/advancedListActions";

import { getMiljogebyrInExcelFormat } from "../../../selectors/miljogebyrerSelector";

import { authorized } from "../../../utils/authorizationUtils";

import * as title from "../../../constants/titles";
import sitePaths from "../../../constants/sitePaths";
import { juridiskArray } from "../../../constants/roles";

const PaginationControl = createPaginationControl("miljogebyrer", {
  apiCallAction: getMiljogebyrerAction,
  queriesForApiReducerName: "forms.miljogebyrerOversiktFilterForm",
});

class OversiktContainer extends Component {
  static propTypes = {
    miljogebyrer: PropTypes.object,
    miljogebyrerOversiktFilterForm: PropTypes.object,
    miljogebyrInExcelFormat: PropTypes.array,
    getMiljogebyrer: PropTypes.func,
    overtredelseskoderMiljogebyr: PropTypes.arrayOf(PropTypes.object),
  };

  constructor(props) {
    super(props);
    this.state = {
      waitingForGetMiljogebyrer: false,
    };
  }

  componentDidMount() {
    document.title = title.miljogebyr.miljogebyroversikt;
  }

  render() {
    const {
      miljogebyrer,
      overtredelseskoderMiljogebyr,
      setSort,
      miljogebyrerOversiktFilterForm,
      getMiljogebyrer,
    } = this.props;
    return (
      <>
        <FilterContainer />
        <div style={{ height: 28 }} />
        {(!this.state.waitingForGetMiljogebyrer ||
          miljogebyrer.items !== 0) && (
          <React.Fragment>
            <div>
              <div className="pull-left" style={{ paddingLeft: 50 }}>
                {!authorized(juridiskArray) && (
                  <div style={{ display: "inline-block", marginRight: 10 }}>
                    <PrintOut
                      url={`${window.location.origin}${sitePaths.miljogebyrer.oversikt.utskrift}`}
                      dataToStoreInSessionStorage={{
                        queryData: {
                          ...this.props.miljogebyrerOversiktFilterForm,
                        },
                        paginationData: {
                          page: this.props.miljogebyrer.page,
                          count: this.props.miljogebyrer.count,
                        },
                      }}
                    />
                  </div>
                )}
                <Excel
                  element={(onClick) => (
                    <button
                      className="btn btn-bym-medium btn-bym-action"
                      onClick={onClick}
                    >
                      Lag rapport
                    </button>
                  )}
                  filename="Miljøgebyr.xlsx"
                  sheets={this.props.miljogebyrInExcelFormat}
                />
              </div>
              <div
                className="pull-right ileggelse-list-page-total-ileggelser"
                style={{ paddingTop: 20, paddingRight: 50 }}
              >
                {miljogebyrer.total} miljøgebyr
              </div>
            </div>
            <div className="clearfix" />
            <div style={{ height: 22 }} />
            <div style={{ paddingRight: 50, paddingLeft: 50 }}>
              <div className="ileggelse-list-page-content-container">
                <List
                  miljøgebyrer={miljogebyrer.items}
                  overtredelseskoderMiljogebyr={overtredelseskoderMiljogebyr}
                  sort={miljogebyrer.sort}
                  setSort={setSort}
                  page={miljogebyrer.page}
                  count={miljogebyrer.count}
                  miljogebyrerOversiktFilterForm={
                    miljogebyrerOversiktFilterForm
                  }
                  getMiljogebyrer={getMiljogebyrer}
                />
              </div>
              <PaginationControl storeKey="miljøgebyrer" />
            </div>
          </React.Fragment>
        )}
        <Spinner
          isLoading={
            this.state.waitingForGetMiljogebyrer &&
            this.props.miljogebyrer.items.length === 0
          }
        />
        <div style={{ height: 50 }} />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    miljogebyrer: state.miljogebyrer,
    miljogebyrerOversiktFilterForm: state.forms.miljogebyrerOversiktFilterForm,
    overtredelseskoderMiljogebyr: state.overtredelseskoderMiljogebyr,
    miljogebyrInExcelFormat: getMiljogebyrInExcelFormat(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMiljogebyrer: (miljogebyrOversiktFilterForm, page, count, sort) => {
      return dispatch(
        getMiljogebyrerAction(miljogebyrOversiktFilterForm, page, count, sort)
      );
    },
    setSort: (sort) => dispatch(setSortAction("MILJOGEBYRER", sort)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OversiktContainer);
