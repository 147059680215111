import React from "react";
import { Switch, Redirect } from "react-router-dom";

import AuthorizedRoutes from "../components/Routes/AuthorizedRoutes";

import MiljogebyrerPage from "../components/Miljogebyr/MiljogebyrerPage";
import OversiktUtskriftContainer from "../components/Miljogebyr/Oversikt/UtskriftContainer";
import TilBehandlingUtskriftContainer from "../components/Miljogebyr/TilBehandling/UtskriftContainer";
import MiljogebyrDetaljPage from "../components/Miljogebyr/Detalj/MiljogebyrDetaljPage";
import SvarUtBrevHistorikk from "../components/Common/Detalj/SvarUtBrevhistorikk";
import ImageEditorPageContainer from "../components/Common/Miljogebyr/Detalj/ImageEditorPageContainer";
import MiljogebyrOrginalbilde from "../components/Common/Miljogebyr/Detalj/MiljogebyrOrginalbilde";
import MiljokontrollDetaljPage from "../components/Miljogebyr/Miljokontroll/Detalj/MiljokontrollDetaljPage";

import sitePath from "../constants/sitePaths";
import { bypatruljen, juridisk } from "../constants/roles";
import TidskontrollOrginalbilde from "../components/Common/Miljogebyr/Detalj/TidskontrollOrginalbilde";

const Miljogebyrer = () => (
  <Switch>
    <AuthorizedRoutes
      exact
      path={sitePath.miljogebyrer.page}
      roles={[...Object.values(bypatruljen), ...Object.values(juridisk)]}
      component={MiljogebyrerPage}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.miljogebyrer.oversikt.utskrift}
      roles={[...Object.values(bypatruljen), ...Object.values(juridisk)]}
      component={OversiktUtskriftContainer}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.miljogebyrer.tilBehandling.utskrift}
      roles={[...Object.values(bypatruljen), ...Object.values(juridisk)]}
      component={TilBehandlingUtskriftContainer}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.miljogebyrer.oversikt.detalj.shortUrl.routeMatcher}
      roles={[...Object.values(bypatruljen), ...Object.values(juridisk)]}
      component={MiljogebyrDetaljPage}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.miljogebyrer.oversikt.detalj.svarUtHistorikk}
      roles={[
        bypatruljen.saksbehandler,
        bypatruljen.saksbehandleradmin,
        juridisk.ledelse,
        juridisk.saksbehandler,
      ]}
      component={SvarUtBrevHistorikk}
    />
    <AuthorizedRoutes
      exact
      path={
        sitePath.miljogebyrer.oversikt.detalj.miljokontroll.shortUrl
          .routeMatcher
      }
      roles={[
        bypatruljen.saksbehandleradmin,
        bypatruljen.saksbehandler,
        bypatruljen.driftleder,
        juridisk.saksbehandler,
      ]}
      component={MiljokontrollDetaljPage}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.miljogebyrer.oversikt.detalj.bilde}
      roles={[...Object.values(bypatruljen), ...Object.values(juridisk)]}
      component={MiljogebyrOrginalbilde}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.miljogebyrer.oversikt.detalj.miljokontroll.bilde}
      roles={[...Object.values(bypatruljen), ...Object.values(juridisk)]}
      component={TidskontrollOrginalbilde}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.miljogebyrer.oversikt.detalj.toastUi}
      roles={[
        bypatruljen.saksbehandleradmin,
        bypatruljen.saksbehandler,
        juridisk.saksbehandler,
      ]}
      component={ImageEditorPageContainer}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.miljogebyrer.oversikt.detalj.miljokontroll.toastUi}
      roles={[
        bypatruljen.saksbehandleradmin,
        bypatruljen.saksbehandler,
        juridisk.saksbehandler,
      ]}
      component={ImageEditorPageContainer}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.miljogebyrer.tilBehandling.detalj.shortUrl.routeMatcher}
      roles={[...Object.values(bypatruljen), ...Object.values(juridisk)]}
      component={MiljogebyrDetaljPage}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.miljogebyrer.tilBehandling.detalj.svarUtHistorikk}
      roles={[bypatruljen.saksbehandler, bypatruljen.saksbehandleradmin]}
      component={SvarUtBrevHistorikk}
    />
    <AuthorizedRoutes
      exact
      path={
        sitePath.miljogebyrer.tilBehandling.detalj.miljokontroll.shortUrl
          .routeMatcher
      }
      roles={[
        bypatruljen.saksbehandleradmin,
        bypatruljen.saksbehandler,
        bypatruljen.driftleder,
        juridisk.saksbehandler,
      ]}
      component={MiljokontrollDetaljPage}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.miljogebyrer.tilBehandling.detalj.bilde}
      roles={[...Object.values(bypatruljen), ...Object.values(juridisk)]}
      component={MiljogebyrOrginalbilde}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.miljogebyrer.tilBehandling.detalj.miljokontroll.bilde}
      roles={[...Object.values(bypatruljen), ...Object.values(juridisk)]}
      component={TidskontrollOrginalbilde}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.miljogebyrer.tilBehandling.detalj.toastUi}
      roles={[
        bypatruljen.saksbehandleradmin,
        bypatruljen.saksbehandler,
        juridisk.saksbehandler,
      ]}
      component={ImageEditorPageContainer}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.miljogebyrer.tilBehandling.detalj.miljokontroll.toastUi}
      roles={[
        bypatruljen.saksbehandleradmin,
        bypatruljen.saksbehandler,
        juridisk.saksbehandler,
      ]}
      component={ImageEditorPageContainer}
    />
    <AuthorizedRoutes
      exact
      path={
        sitePath.miljogebyrer.miljokontrolloversikt.detalj.shortUrl
          .routerMatcher
      }
      roles={[
        bypatruljen.saksbehandleradmin,
        bypatruljen.saksbehandler,
        bypatruljen.driftleder,
        juridisk.saksbehandler,
      ]}
      component={MiljokontrollDetaljPage}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.miljogebyrer.miljokontrolloversikt.detalj.bilde}
      roles={[...Object.values(bypatruljen), ...Object.values(juridisk)]}
      component={TidskontrollOrginalbilde}
    />
    <AuthorizedRoutes
      exact
      path={sitePath.miljogebyrer.miljokontrolloversikt.detalj.toastUi}
      roles={[
        bypatruljen.saksbehandleradmin,
        bypatruljen.saksbehandler,
        juridisk.saksbehandler,
      ]}
      component={ImageEditorPageContainer}
    />
    <AuthorizedRoutes
      exact
      path={
        sitePath.miljogebyrer.miljokontrolloversikt.detalj.miljogebyrer.detalj
          .shortUrl.routeMatcher
      }
      roles={[
        bypatruljen.saksbehandleradmin,
        bypatruljen.saksbehandler,
        bypatruljen.driftleder,
        juridisk.saksbehandler,
      ]}
      component={MiljogebyrDetaljPage}
    />
    <AuthorizedRoutes
      exact
      path={
        sitePath.miljogebyrer.miljokontrolloversikt.detalj.miljogebyrer.detalj
          .svarUtHistorikk
      }
      roles={[bypatruljen.saksbehandler, bypatruljen.saksbehandleradmin]}
      component={SvarUtBrevHistorikk}
    />
    <AuthorizedRoutes
      exact
      path={
        sitePath.miljogebyrer.miljokontrolloversikt.detalj.miljogebyrer.detalj
          .miljogebyrBilde
      }
      roles={[...Object.values(bypatruljen), ...Object.values(juridisk)]}
      component={MiljogebyrOrginalbilde}
    />
    <AuthorizedRoutes
      exact
      path={
        sitePath.miljogebyrer.miljokontrolloversikt.detalj.miljogebyrer.detalj
          .tidskontrollBilde
      }
      roles={[...Object.values(bypatruljen), ...Object.values(juridisk)]}
      component={TidskontrollOrginalbilde}
    />
    <AuthorizedRoutes
      exact
      path={
        sitePath.miljogebyrer.miljokontrolloversikt.detalj.miljogebyrer.detalj
          .toastUi
      }
      roles={[
        bypatruljen.saksbehandleradmin,
        bypatruljen.saksbehandler,
        juridisk.saksbehandler,
      ]}
      component={ImageEditorPageContainer}
    />
    <AuthorizedRoutes
      exact
      path={
        sitePath.miljogebyrer.miljokontrolloversikt.miljogebyrer.detalj.shortUrl
          .routerMatcher
      }
      roles={[
        bypatruljen.saksbehandleradmin,
        bypatruljen.saksbehandler,
        bypatruljen.driftleder,
        juridisk.saksbehandler,
      ]}
      component={MiljogebyrDetaljPage}
    />
    <AuthorizedRoutes
      exact
      path={
        sitePath.miljogebyrer.miljokontrolloversikt.miljogebyrer.detalj
          .svarUtHistorikk
      }
      roles={[bypatruljen.saksbehandler, bypatruljen.saksbehandleradmin]}
      component={SvarUtBrevHistorikk}
    />
    <AuthorizedRoutes
      exact
      path={
        sitePath.miljogebyrer.miljokontrolloversikt.miljogebyrer.detalj.bilde
      }
      roles={[...Object.values(bypatruljen), ...Object.values(juridisk)]}
      component={MiljogebyrOrginalbilde}
    />
    <AuthorizedRoutes
      exact
      path={
        sitePath.miljogebyrer.miljokontrolloversikt.miljogebyrer.detalj
          .miljokontroll.detalj.bilde
      }
      roles={[...Object.values(bypatruljen), ...Object.values(juridisk)]}
      component={TidskontrollOrginalbilde}
    />
    <AuthorizedRoutes
      exact
      path={
        sitePath.miljogebyrer.miljokontrolloversikt.miljogebyrer.detalj.toastUi
      }
      roles={[
        bypatruljen.saksbehandleradmin,
        bypatruljen.saksbehandler,
        juridisk.saksbehandler,
      ]}
      component={ImageEditorPageContainer}
    />
    <AuthorizedRoutes
      exact
      path={
        sitePath.miljogebyrer.miljokontrolloversikt.miljogebyrer.detalj
          .miljokontroll.detalj.toastUi
      }
      roles={[
        bypatruljen.saksbehandleradmin,
        bypatruljen.saksbehandler,
        juridisk.saksbehandler,
      ]}
      component={ImageEditorPageContainer}
    />
    <AuthorizedRoutes
      exact
      path={
        sitePath.miljogebyrer.miljokontrolloversikt.miljogebyrer.detalj
          .miljokontroll.detalj.shortUrl.routerMatcher
      }
      roles={[
        bypatruljen.saksbehandleradmin,
        bypatruljen.saksbehandler,
        bypatruljen.driftleder,
        juridisk.saksbehandler,
      ]}
      component={MiljokontrollDetaljPage}
    />
    <Redirect
      path={sitePath.miljogebyrer.shortUrl}
      to={sitePath.miljogebyrer.oversikt.shortUrl}
    />
  </Switch>
);

export default Miljogebyrer;
