import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Ventilstillingsdetail from "./Ventilstillingsdetail";
import { getVentilstilling } from "../../../actions/ventilstillingerActions";
import * as title from "../../../constants/titles";

export class VentilstillingsdetaljPage extends Component {
  static propTypes = {
    computedMatch: PropTypes.object,
    ventilstilling: PropTypes.object,
    getVentilstilling: PropTypes.func,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    document.title = title.ventilstillingsdetalj(
      this.props.ventilstilling.ileggelsesnummer
    );
    this.props.getVentilstilling(this.props.computedMatch.params.id);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.ventilstilling.ileggelsesnummer !==
      this.props.ventilstilling.ileggelsesnummer
    ) {
      document.title = title.ventilstillingsdetalj(
        this.props.ventilstilling.ileggelsesnummer
      );
    }
  }

  render() {
    if (this.props.computedMatch.params.id === this.props.ventilstilling.id) {
      return (
        <Ventilstillingsdetail ventilstilling={this.props.ventilstilling} />
      );
    }
    return <span />;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ventilstilling: state.ventilstilling,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getVentilstilling }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VentilstillingsdetaljPage);
