import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ModalGeneric from "./ModalGeneric";

import { addFlashMessage as addFlashMessageAction } from "../../actions/FlashMessagesAction";

import { errorData } from "../../utils/apiResponseUtils";

class ModalGenericPostCancelContainer extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    apiCallData: PropTypes.any, // TODO to object?
    modalTitle: PropTypes.string.isRequired,
    submitButtonText: PropTypes.string.isRequired,
    submittingButtonText: PropTypes.string,
    successPostCallFlashMessage: PropTypes.string,
    errorPostCallFlashMessage: PropTypes.string,
    className: PropTypes.string,
    submitButtonDisabled: PropTypes.bool,
    showSubmitSpinner: PropTypes.bool,
    openModalComponentFunction: PropTypes.func,
    handleApiCall: PropTypes.func.isRequired,
    addFlashMessage: PropTypes.func,
    closeModal: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      submitButtonText: this.props.submitButtonText,
      submittingButtonText: this.props.submitButtonText,
    };

    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.saveAndClose = this.saveAndClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  closeModal() {
    this.setState({
      open: false,
    });
    this.props.closeModal && this.props.closeModal();
  }

  openModal() {
    this.setState({ open: true });
  }

  async saveAndClose() {
    this.setState({ submitButtonText: this.props.submittingButtonText });
    let handleApiCallParameters = this.props.apiCallData
      ? [this.props.id, this.props.apiCallData]
      : [this.props.id];
    await this.props
      .handleApiCall(...handleApiCallParameters)
      .then(() => {
        this.setState({ submitButtonText: this.props.submitButtonText });
        this.props.addFlashMessage({
          type: "success",
          text: this.props.successPostCallFlashMessage,
        });
        this.setState({
          open: false,
        });
        return Promise.resolve(true);
      })
      .catch((error) => {
        if (
          error !== "validationError" &&
          error !== "popUpBlocked" &&
          this.props.errorPostCallFlashMessage
        ) {
          this.props.addFlashMessage({
            type: "error",
            text: this.props.errorPostCallFlashMessage + " " + errorData(error),
          });
        }
        this.setState({ submitButtonText: this.props.submitButtonText });
        return Promise.reject(true);
      });
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return (
      <ModalGeneric
        modalTitle={this.props.modalTitle}
        open={this.state.open}
        closeModal={this.closeModal}
        openModal={this.openModal}
        OpenModalComponent={this.props.openModalComponentFunction}
        submit={this.saveAndClose}
        submitButtonText={this.props.submitButtonText}
        close={this.props.handleCancel}
        className={this.props.className}
        disabled={this.props.submitButtonDisabled}
        showSubmitSpinner={this.props.showSubmitSpinner}
      >
        {React.Children.map(this.props.children, (child) => child)}
      </ModalGeneric>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addFlashMessage: (data) => dispatch(addFlashMessageAction(data)),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(ModalGenericPostCancelContainer);
