import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import BilderButtons from "../../Common/Detalj/BilderButtons";

import {
  getMiljogebyr as getMiljogebyrAction,
  getMiljogebyrBilder as getMiljogebyrBilderAction,
  postMiljogebyrerBilder as postMiljogebyrerBilderAction,
  getMiljogebyrerHistorikk,
} from "../../../actions/miljogebyrerActions";
// import { IMAGE_URL } from "../../../constants/api";

// TODO post files to service
// TODO get images from url
class BilderButtonsContainer extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    getMiljogebyr: PropTypes.func.isRequired,
    getMiljogebyrBilder: PropTypes.func.isRequired,
    postMiljogebyrerBilder: PropTypes.func.isRequired,
  };

  handleLeggTilBilderClick(invisibleFileInputButton) {
    invisibleFileInputButton.click();
  }

  handleFileInputChange = (fileArray) => {
    let postMiljogebyrerBilderArray = [];
    for (let index = 0; index < fileArray.length; index++) {
      postMiljogebyrerBilderArray.push(
        this.props.postMiljogebyrerBilder(
          this.props.id,
          fileArray[index.toString()]
        )
      );
    }

    Promise.all(postMiljogebyrerBilderArray)
      .then(() => {
        this.props.getMiljogebyr(this.props.id);
        this.props.getMiljogebyrerHistorikk(this.props.id);
      })
      .catch();
  };
  render() {
    return (
      <BilderButtons
        handleFileInputChange={this.handleFileInputChange}
        handleLeggTilBilderClick={this.handleLeggTilBilderClick}
      />
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getMiljogebyr: (miljogebyrId) =>
      dispatch(getMiljogebyrAction(miljogebyrId)),
    getMiljogebyrBilder: (miljogebyrId) =>
      dispatch(getMiljogebyrBilderAction(miljogebyrId)),
    postMiljogebyrerBilder: (miljogebyrId, file) =>
      dispatch(postMiljogebyrerBilderAction(miljogebyrId, file)),
    getMiljogebyrerHistorikk: (miljogebyrId) =>
      dispatch(getMiljogebyrerHistorikk(miljogebyrId)),
  };
};

export default connect(null, mapDispatchToProps)(BilderButtonsContainer);
