import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";

import { getIleggelseMakuleringer as getIleggelseMakuleringerAction } from "../../../actions/ileggelseMakuleringerActions";

import { getMostRecentObjectComparedOnParameter } from "../../../utils/datetimeUtils";

class MakuleringsarsakContainer extends React.Component {
  static propTypes = {
    ileggelse: PropTypes.object,
    mostRecentMakulering: PropTypes.object,
    getIleggelseMakuleringer: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.getIleggelseMakuleringer(this.props.ileggelse.id);
  }

  render() {
    return (
      <section className="detalj-ileggelse-section-margin">
        <div style={{ marginTop: 17 }}>
          <h5>Makuleringsårsak</h5>
          <Row>
            <Col md={6}>
              <p style={{ fontSize: 16 }}>
                {(this.props.ileggelse.isMakulert &&
                  this.props.mostRecentMakulering &&
                  this.props.ileggelse.id ===
                    this.props.mostRecentMakulering.ileggelseId &&
                  this.props.mostRecentMakulering.kommentar) ||
                  "-"}
              </p>
            </Col>
          </Row>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ileggelse: state.ileggelse,
    mostRecentMakulering:
      state.ileggelseMakuleringer &&
      getMostRecentObjectComparedOnParameter(
        state.ileggelseMakuleringer,
        "makuleringDato"
      ),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getIleggelseMakuleringer: (ileggelseId) =>
      dispatch(getIleggelseMakuleringerAction(ileggelseId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MakuleringsarsakContainer);
