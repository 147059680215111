import React from "react";
import PropTypes from "prop-types";
import SpinLoader from "../SpinLoader";

import Gallery from "../Gallery";

const spinnerLoaderProps = {
  height: 42,
  width: 42,
  thickness: 3,
  pColor: "#ffffff",
  sColor: "#4d4d4d",
};

function Bilder(props) {
  const {
    id,
    isAuthorizedForToastUi,
    isAuthorizedToDeleteImage,
    isLoadingImages,
    handleDeleteImageClick,
    handleCheckboxChange,
    checkboxText,
    imageArray,
    lightboxImages,
    bilderButtonsContainer: BilderButtonsContainer,
  } = props;

  return (
    <div>
      <div className="detalj-ileggelse-header-wrapper-no-margin">
        <h2>Bilder</h2>
      </div>
      {BilderButtonsContainer && (
        <div className="no-print" style={{ marginBottom: 10 }}>
          <BilderButtonsContainer id={id} />
        </div>
      )}
      <div style={{ marginTop: 10 }}>
        {isLoadingImages && (
          <div style={{ margin: "auto", width: 0 }}>
            <SpinLoader {...spinnerLoaderProps} />
          </div>
        )}
        <Gallery
          handleDeleteImageClick={handleDeleteImageClick}
          checkboxText={checkboxText}
          handleCheckboxChange={handleCheckboxChange}
          images={imageArray}
          lightboxImages={lightboxImages}
          isAuthorizedForToastUi={isAuthorizedForToastUi}
          isAuthorizedToDeleteImage={isAuthorizedToDeleteImage}
        />
      </div>
    </div>
  );
}

Bilder.propTypes = {
  id: PropTypes.string.isRequired,
  bilderButtonsContainer: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  baseUrl: PropTypes.string,
  imageArray: PropTypes.arrayOf(PropTypes.object),
  lightboxImages: PropTypes.arrayOf(PropTypes.object),
  isLoadingImages: PropTypes.bool,
  isAuthorizedForToastUi: PropTypes.bool,
  isAuthorizedToDeleteImage: PropTypes.bool,
  checkboxText: PropTypes.string,
  handleDeleteImageClick: PropTypes.func,
  handleCheckboxChange: PropTypes.func,
};

export default Bilder;
