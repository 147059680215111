import * as axiosUtils from "../../utils/axiosUtils";
import axios from "../../services/axios";
import * as api from "../../constants/api";
import {
  NetworkError,
  NotHandledRRessponseErrorsFromService,
} from "../../constants/ErrorMessages";
import { addFlashMessage } from "../FlashMessagesAction";

export const KLAGE_DETAIL = "KLAGE_DETAIL";
export const KLAGE_VEDTAK = "KLAGE_VEDTAK";

export const getVedtaker = (params) => {
  return axiosUtils.GetAllWithParams(api.GET_VEDTAKER_URL, params);
};

export const getKlageVedtaker = (klageId) => {
  const url = api.KLAGE_VEDTAKER_URL(klageId);
  return axiosUtils.GetAll(url, KLAGE_VEDTAK);
};
export const addKlageVedtak = (klageId, ileggelse) => {
  const payload = {
    ileggelseId: ileggelse.id,
    ileggelsesnummer: ileggelse.ileggelsesnummer,
    ileggelsetypeId: ileggelse.ileggelsestypeId,
    vedtakskodeId: 0,
    ileggelsesDato: ileggelse.ileggelsesdatoFra,
    belop: ileggelse.belop ? ileggelse.belop : 0,
  };
  const url = api.KLAGE_VEDTAKER_URL(klageId);
  return axiosUtils.Post(url, payload);
};
export const updateKlageVedtak = (klageId, vedtaksId, payload) => {
  const url = api.UPDATE_KLAGE_VEDTAK_URL(klageId, vedtaksId);
  return axiosUtils.Put(url, payload, KLAGE_VEDTAK, {
    entitet: "Vedtakskoden",
    notify: true,
  });
};

export const deleteKlageVedtak = (klageId, vedtakId) => {
  const url = api.DELETE_KLAGE_VEDTAK_URL(klageId, vedtakId);
  return axiosUtils.Delete(url, KLAGE_VEDTAK);
};

export const UpdateVedtakerFerdigBehandlet = (klageId) => {
  const url = api.UPDATE_VEDTAK_FERDIGBEHANDLET_URL(klageId);
  return (dispatch) =>
    axios
      .put(url, {})
      .then(() => {
        axiosUtils.successMessage(dispatch, "Vedtak", "lagret.");
        dispatch(getKlageVedtaker(klageId));
      })
      .catch((error) => {
        let errorMessage = NotHandledRRessponseErrorsFromService;
        if (error && error.response) {
          if (error.response.status === 504) {
            errorMessage = NetworkError;
          } else if (error.response.status === 400 && error.response.data) {
            errorMessage = error.response.data.errorMessage;
          }
        }

        return axiosUtils.errorMessage(dispatch, errorMessage, KLAGE_VEDTAK);
      });
};

export const CreateKlageSvarDokument = (klageId, payload) => {
  return async (dispatch) => {
    const url = api.POST_KLAGER_DOKUMENTER(klageId);
    return axios
      .post(url, payload)
      .then((response) => {
        const data = axiosUtils.successData(response);
        dispatch(
          addFlashMessage({
            type: "success",
            text: `Dokument med ${data.dokumentNummer} er opprettet, og lagt til saken som utgående dokument`,
          })
        );
        return Promise.resolve(data);
      })
      .catch((error) => {
        axiosUtils.errorMessage(dispatch, error, "Post");
        Promise.reject(error);
      });
  };
};
