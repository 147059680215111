export const historikkToHistorikkComponentData = (historikkData) => {
  if (!historikkData || !Array.isArray(historikkData)) {
    return [];
  }

  return historikkData.map(h => {
    return {
      historikkBrukerNavn: h.brukerNavn,
      historikkTidspunkt: h.tidspunkt,
      felter: h.endringer
    };
  });
}
