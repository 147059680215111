import { ILEGGELSE_SERVICE_BASE_URL } from "../config";

export const GET_KLAGE_RAPPORT_LEDELSE_URL = `${ILEGGELSE_SERVICE_BASE_URL}/rapporter/klager`;
export const GET_VEDTAKER_URL = `${ILEGGELSE_SERVICE_BASE_URL}/rapporter/vedtaker`;
export const GET_KLAGER_SAKSBEHANDERRAPPORTER_URL = `${ILEGGELSE_SERVICE_BASE_URL}/rapporter/saker`;
export const GET_ANTALL_ILEGGELSER_PER_ILEGGELSETYPE_URL = `${ILEGGELSE_SERVICE_BASE_URL}/rapporter/antall-ileggelser-per-ileggelsetype`;
export const GET_RAPPORT_MAKULERINGER_URL = `${ILEGGELSE_SERVICE_BASE_URL}/rapporter/makuleringer`;
export const GET_AARSRAPPORT_URL = `${ILEGGELSE_SERVICE_BASE_URL}/rapporter/aarsrapport`;
export const GET_UREGISTRERTE_ILEGGELSER_KJENNEMERKE_GROUPS_URL = `${ILEGGELSE_SERVICE_BASE_URL}/top_gjeld`;
export const GET_UREGISTRERTE_ILEGGELSER_SINGLE_KJENNEMERKE_URL = `${ILEGGELSE_SERVICE_BASE_URL}/single_gjeld`;
