import * as axiosUtils from "../../utils/axiosUtils";
import * as api from "../../constants/api";
import { toIsoString } from "../../utils/datetimeUtils";

export const actionTypeRapportMakuleringer = "RAPPORT_MAKULERINGER";

export const getMakuleringer = (params, page, pageSize, sort = undefined) => {
  const makuleringFraDato = params.makuleringFraDato
    ? toIsoString(params.makuleringFraDato)
    : null;
  const makuleringTilDato = params.makuleringTilDato
    ? toIsoString(params.makuleringTilDato)
    : null;
  const ileggelseFraDato = params.ileggelseFraDato
    ? toIsoString(params.ileggelseFraDato)
    : null;
  const ileggelseTilDato = params.ileggelseTilDato
    ? toIsoString(params.ileggelseTilDato)
    : null;
  const tjenestenummer = params.tjenestenummer ? params.tjenestenummer : null;
  let sortParam = null;
  let sortOrder = null;
  if (sort && sort.cellKey && sort.orders) {
    sortParam = sort.cellKey;
    sortOrder = sort.orders;
  }

  return axiosUtils.GetAllWithParams(
    api.GET_RAPPORT_MAKULERINGER_URL,
    {
      makuleringFraDato,
      makuleringTilDato,
      ileggelseFraDato,
      ileggelseTilDato,
      tjenestenummer,
      page,
      pageSize,
      sort: sortParam,
      sortOrder,
    },
    actionTypeRapportMakuleringer,
    "rapporter.makuleringerRapport"
  );
};
