import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const TextArea = ({
  label,
  name,
  ref,
  placeHolder,
  value,
  rows,
  cols,
  id,
  validationError,
  onChange,
  onBlur,
}) => {
  return (
    <div className={classnames("form-group", { "has-error": validationError })}>
      <label className="control-label" htmlFor={name}>
        {label}
      </label>
      <textarea
        className="form-control"
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        name={name}
        ref={ref}
        placeholder={placeHolder}
        rows={rows}
        cols={cols}
        id={id}
      />
      {validationError && <span className="help-block">{validationError}</span>}
    </div>
  );
};
TextArea.defaultProps = {
  cols: "50",
  rows: "4",
};

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  placeHolder: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  validationError: PropTypes.string,
  rows: PropTypes.string,
  cols: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
};

export default TextArea;
