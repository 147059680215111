import { connect } from "react-redux";

import Makuleringer from "./Makuleringer";

import { getMakuleringer as getRapportMakuleringerAction } from "../../../actions/rapporter/makuleringerActions";
import { setSort as setSortAction } from "../../../actions/advancedListActions";

const mapStateToProps = (state) => ({
  makuleringerFilterForm: state.forms.makuleringerFilterForm,
  makuleringerSubmittedForm: state.submittedForms.makuleringerSubmittedForm,
  makuleringerRapport: state.rapporter.makuleringerRapport,
  sort: state.rapporter.makuleringerRapport.sort,
});

const mapDispatchToProps = (dispatch) => ({
  getRapportMakuleringer: (params, page, pageSize, sort) =>
    dispatch(getRapportMakuleringerAction(params, page, pageSize, sort)),
  setSort: (sort) =>
    dispatch(setSortAction("RAPPORTER.MAKULERINGERRAPPORT", sort)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const page =
    stateProps &&
    stateProps.makuleringerRapport &&
    stateProps.makuleringerRapport.page
      ? stateProps.makuleringerRapport.page
      : 1;

  const pageSize =
    stateProps &&
    stateProps.makuleringerRapport &&
    stateProps.makuleringerRapport.count
      ? stateProps.makuleringerRapport.count
      : 1;

  return {
    makuleringerSubmittedForm: stateProps.makuleringerSubmittedForm,
    makuleringerRapport: stateProps.makuleringerRapport,
    sort: stateProps.sort,
    onSort: (sort) => {
      dispatchProps.setSort(sort);
      dispatchProps.getRapportMakuleringer(
        stateProps.makuleringerFilterForm,
        page,
        pageSize,
        sort
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Makuleringer);
