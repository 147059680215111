import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";

import { getLandingPage } from "../../utils/pathnameUtils";

import { ReactComponent as IconClose } from "../../images/icon-close.svg";

const styles = {
  closeIcon: { width: 14, height: 14, margin: 6 },
  closeButton: { display: "flex", alignItems: "center" },
};

const handleClick = (history) =>
  history.length > 1 ? history.goBack() : history.push(getLandingPage());

function CloseButton({ text, history, hasCloseIcon, customHandleClick }) {
  return (
    <Button
      type="button"
      className="btn-bym-standard"
      style={styles.closeButton}
      onClick={
        customHandleClick
          ? () => customHandleClick()
          : () => handleClick(history)
      }
    >
      {hasCloseIcon && <IconClose />}
      <span>{text}</span>
    </Button>
  );
}

CloseButton.defaultProps = {
  hasCloseIcon: true,
};
CloseButton.propTypes = {
  text: PropTypes.string,
  hasCloseIcon: PropTypes.bool,
};

export default withRouter(CloseButton);
