import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import DatetimePicker from "../../Common/Forms/DatetimePickerEnhanced";
import TextInput from "../../Common/Forms/TextInput";
import FormButtonsForCloseAndSubmit from "../../Common/Forms/FormButtonsForCloseAndSubmit";

export default class VentilstillingFilterForm extends Component {
  static propTypes = {
    showSokSpinner: PropTypes.bool,
    VentilstillingFilterFormState: PropTypes.shape({
      fraDato: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      tilDato: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      kjennemerke: PropTypes.string,
    }),
    handleChange: PropTypes.func,
    onSubmit: PropTypes.func,
    handleChangeToInitState: PropTypes.func,
    error: PropTypes.string,
  };

  render() {
    const {
      showSokSpinner,
      VentilstillingFilterFormState,
      handleChange,
      handleSubmit,
      handleChangeToInitState,
      error,
    } = this.props;
    return (
      <div className="SearchHistorikkForm">
        <div className="searchHistorikkOverskrift">Søk i tabellen</div>
        <div className="filter-flexbox-container ventilstilling-filter-flexbox">
          <DatetimePicker
            id="fraDato"
            name="fraDato"
            locale="nb"
            label="Dato fra"
            placeHolder="Velg dato"
            onChange={handleChange}
            value={VentilstillingFilterFormState.fraDato}
            validationError={
              VentilstillingFilterFormState.fraDatoValidationErrorText
            }
            timeFormat={false}
          />
          <DatetimePicker
            id="tilDato"
            name="tilDato"
            locale="nb"
            label="Dato til"
            placeHolder="Velg dato"
            onChange={handleChange}
            value={VentilstillingFilterFormState.tilDato}
            validationError={
              VentilstillingFilterFormState.tilDatoValidationErrorText
            }
            timeFormat={false}
          />
          <TextInput
            id="kjennemerke"
            name="kjennemerke"
            label="Kjennemerke"
            placeholder="Kjennemerke"
            value={VentilstillingFilterFormState.kjennemerke}
            onChange={handleChange}
          />
        </div>
        <div style={{ marginTop: 10 }}>
          <FormButtonsForCloseAndSubmit
            submitText="Søk"
            onSubmit={handleSubmit}
            onClose={handleChangeToInitState}
            showSubmitSpinner={showSokSpinner}
          />
        </div>
        <Row>
          <Col md={12}>{error}</Col>
        </Row>
      </div>
    );
  }
}
