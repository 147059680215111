import { createSelector } from "reselect";

const piggdekkontrollerList = state => state.piggdekkontroller;

export const getPiggdekkontrollerList = createSelector(
  piggdekkontrollerList,
  piggdekkontrollerList => {
    return piggdekkontrollerList.items;
  }
);

export default piggdekkontrollerList;
