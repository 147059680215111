import React from "react";
import PropTypes from "prop-types";

import Table from "../../Common/Tables/Table";

import { toNorwegianDatetime } from "../../../utils/datetimeUtils";

class MiljokontrollKommentarer extends React.Component {
  componentDidMount = () => {
    const { miljogebyrId, miljogebyr, getMiljokontrollKommentarer } =
      this.props;
    if (miljogebyrId === miljogebyr.id && miljogebyr.miljoKontrollId) {
      getMiljokontrollKommentarer(miljogebyr.miljoKontrollId);
    }
  };

  componentDidUpdate = (prevProps) => {
    const { miljogebyrId, miljogebyr, getMiljokontrollKommentarer } =
      this.props;

    if (
      miljogebyrId === miljogebyr.id &&
      miljogebyr.miljoKontrollId &&
      prevProps.miljogebyr.id !== miljogebyr.id
    ) {
      getMiljokontrollKommentarer(miljogebyr.miljoKontrollId);
    }
  };

  renderRow = (miljokontrollKommentar) => {
    if (!miljokontrollKommentar) {
      return null;
    }

    const dato =
      miljokontrollKommentar.datoMottatt &&
      toNorwegianDatetime(miljokontrollKommentar.datoMottatt).format(
        "DD.MM.YYYY HH:mm"
      );

    return (
      <tr>
        <td>{dato}</td>
        <td>{miljokontrollKommentar.kommentar}</td>
      </tr>
    );
  };

  render() {
    const { miljogebyrId, miljogebyr, miljokontrollKommentarer } = this.props;
    if (miljogebyrId === miljogebyr.id && miljogebyr.miljoKontrollId) {
      return (
        <section>
          <div className="detalj-ileggelse-header-wrapper">
            <h2>Tidskontrollkommentarer</h2>
          </div>
          <div className="detalj-ileggelse-table-wrapper">
            <Table renderRow={this.renderRow} items={miljokontrollKommentarer}>
              <th>Dato</th>
              <th>Kommentar</th>
            </Table>
          </div>
        </section>
      );
    }
    return null;
  }
}

MiljokontrollKommentarer.propTypes = {
  miljogebyrId: PropTypes.string,
  miljoKontrollId: PropTypes.string,
  miljogebyr: PropTypes.shape({}).isRequired,
  miljokontrollKommentarer: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getMiljokontrollKommentarer: PropTypes.func,
};

export default MiljokontrollKommentarer;
