import React from "react";
import Excel from "../../Common/Excel";

import {
  formatDateHavingNorwegianDateTime,
  formatDateTimeHavingNorwegianDateTime,
} from "../../../utils/datetimeUtils";

const PiggdekkontrollExcel = ({
  piggdekkontroller,
  piggdekkontrollerSubmittedForm,
}) => {
  return (
    <Excel
      element={(onClick) => (
        <button className="btn btn-bym-medium btn-bym-action" onClick={onClick}>
          Lag rapport
        </button>
      )}
      filename={"Piggdekkontroller.xlsx"}
      sheets={[
        {
          name: "Piggdekkontroller",
          data: piggdekkontroller.map((pk) => ({
            Dato: formatDateHavingNorwegianDateTime(pk.dato),
            Hovedgate: pk.hovedgateNavn,
            "Fra gate": pk.fraGateNavn,
            "Til gate": pk.tilGateNavn,
            Tjenestenummer: pk.tjenestenummer,
            Kjennemerke: pk.kjennemerke,
          })),
        },
        {
          name: "Søkdata",
          data: [
            {
              "Timestamp for søk": formatDateTimeHavingNorwegianDateTime(
                piggdekkontrollerSubmittedForm.submitTimestamp
              ),
              "Dato fra": formatDateTimeHavingNorwegianDateTime(
                piggdekkontrollerSubmittedForm.fraDato
              ),
              "Dato til": formatDateTimeHavingNorwegianDateTime(
                piggdekkontrollerSubmittedForm.tilDato
              ),
              Kjennemerke: piggdekkontrollerSubmittedForm.kjennemerke,
            },
          ],
        },
      ]}
    />
  );
};

export default PiggdekkontrollExcel;
