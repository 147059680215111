import { connect } from "react-redux";

import Bilder from "./Bilder";

import { getMiljokontrollBilder as getMiljokontrollBilderAction } from "../../../../actions/miljokontrollerActions";

const mapStateToProps = (state) => ({
  miljokontrollerThumbnailBilder: state.miljokontrollerThumbnailBilder,
  miljokontrollerLightboxBilder: state.miljokontrollerLightboxBilder,
});

const mapDispatchToProps = (dispatch) => ({
  getMiljokontrollBilder: (miljokontrollId, params) =>
    dispatch(getMiljokontrollBilderAction(miljokontrollId, params)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...dispatchProps,
    ...ownProps,
    dateSortedThumbnailImageArray:
      stateProps.miljokontrollerThumbnailBilder[ownProps.id],
    dateSortedLightboxImageArray:
      stateProps.miljokontrollerLightboxBilder[ownProps.id],
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Bilder);
