import React, { Component } from "react";
import PropTypes from "prop-types";
import { Image } from "react-bootstrap";

import "./UserIcon.css";

import defaultLarge from "../../images/profilbilde_large.svg";
import defaultSmall from "../../images/profilbilde_small.svg";

class UserIcon extends Component {
  static propTypes = {
    small: PropTypes.bool,
    medium: PropTypes.bool,
    large: PropTypes.bool,
    clearfix: PropTypes.bool,
    block: PropTypes.bool,
  };

  static defaultProps = {
    icon: null,
    small: false,
    medium: false,
    large: false,
    clearfix: false,
    block: false,
  };

  render() {
    const {
      large,
      medium,
      small,
      clearfix,
      block,
    } = this.props;

    const userIconSize = small
      ? " user-icon-small"
      : medium
      ? " user-icon-medium"
      : large
      ? " user-icon-large"
      : "";
    const className =
      "user-icon" + userIconSize + (clearfix ? " clearfix" : "");
    const containerClassName =
      "user-icon-container" + (block ? " user-icon-container-block" : "");

    let imageSrc = large ? defaultLarge : defaultSmall;

    return (
      <div className={containerClassName}>
        <div className={className}>
          <Image roundedCircle src={imageSrc} title={"Profile icone"} />
        </div>
      </div>
    );
  }
}

export default UserIcon;
