import { connect } from "react-redux";

import OpphevMakuleringMiljokontroll from "./OpphevMakuleringMiljokontroll";

import {
  getMiljokontroller,
  getMiljokontroll,
  getAllMiljoKontrollHistorikk,
  putMiljokontrollAvslutt,
} from "../../../../actions/miljokontrollerActions";

const mapStateToProps = (state, ownProps) => ({
  miljokontroll: state.miljokontroll,
  miljokontrollOversiktFilterForm: state.forms.miljokontrollOversiktFilterForm,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getMiljokontroller: (filter) => dispatch(getMiljokontroller(filter)),
  getMiljokontroll: (miljokontrollId) =>
    dispatch(getMiljokontroll(miljokontrollId)),
  getAllMiljoKontrollHistorikk: (miljokontrollId) =>
    dispatch(getAllMiljoKontrollHistorikk(miljokontrollId)),
  putMiljokontrollAvslutt: (
    miljokontrollId,
    getMiljokontroller,
    getMiljokontroll,
    getAllMiljoKontrollHistorikk
  ) =>
    dispatch(
      putMiljokontrollAvslutt(
        miljokontrollId,
        { avslutt: false },
        getMiljokontroller,
        getMiljokontroll,
        getAllMiljoKontrollHistorikk
      )
    ),
});

const mergeProps = (stateProps, dispatchProps) => ({
  miljokontroll: stateProps.miljokontroll,
  putMiljokontrollAvslutt: () =>
    dispatchProps.putMiljokontrollAvslutt(
      stateProps.miljokontroll.id,
      () =>
        dispatchProps.getMiljokontroller(
          stateProps.miljokontrollOversiktFilterForm
        ),
      () => dispatchProps.getMiljokontroll(stateProps.miljokontroll.id),
      () => dispatchProps.getAllMiljoKontrollHistorikk(stateProps.miljokontroll.id),
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(OpphevMakuleringMiljokontroll);
