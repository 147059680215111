import { ILEGGELSE_SERVICE_BASE_URL } from "../config";

// - Betjenter
export const GET_BYBETJENTER_URL = `${ILEGGELSE_SERVICE_BASE_URL}/betjenter`;
export const GET_BYBETJENTE_BY_Id_URL = (id) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/betjenter/${id}`;
export const POST_BYBETJENTER_URL = `${ILEGGELSE_SERVICE_BASE_URL}/betjenter`;
export const PUT_BYBETJENTER_URL = (id) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/betjenter/${id}`;

// - Bybetjentgrupper
export const GET_BYBETJENTGRUPPER_URL = `${ILEGGELSE_SERVICE_BASE_URL}/team`;
export const POST_BYBETJENTGRUPPE_URL = `${ILEGGELSE_SERVICE_BASE_URL}/team`;
export const PUT_BYBETJENTGRUPPE_URL = `${ILEGGELSE_SERVICE_BASE_URL}/team`;

// - Bybetjentroller
export const GET_BYBETJENTROLLER_URL = `${ILEGGELSE_SERVICE_BASE_URL}/betjentroller`;
export const POST_BYBETJENTROLLER_URL = `${ILEGGELSE_SERVICE_BASE_URL}/betjentroller`;
export const PUT_BYBETJENTROLLER_URL = `${ILEGGELSE_SERVICE_BASE_URL}/betjentroller`;

// - Bybetjentseksjoner
export const GET_BYBETJENTSEKSJONER_URL = `${ILEGGELSE_SERVICE_BASE_URL}/betjentseksjoner`;
export const POST_BYBETJENTSEKSJONER_URL = `${ILEGGELSE_SERVICE_BASE_URL}/betjentseksjoner`;
export const PUT_BYBETJENTSEKSJONER_URL = `${ILEGGELSE_SERVICE_BASE_URL}/betjentseksjoner`;

//- bybetjentteam
export const BETJENT_TEAM_URL = (betjentId, teamId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/betjenter/${betjentId}/team/${teamId}`;

export const BETJENT_TEAM_HISTORIKK_URL = (betjentId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/betjenter/${betjentId}/teamhistorikk`;
