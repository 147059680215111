import * as miljokontrollerActions from "../actions/miljokontrollerActions";

const INITIAL_STATE = {};

export default function miljokontrollReducer(
  state = { ...INITIAL_STATE },
  action
) {
  switch (action.type) {
    case miljokontrollerActions.GET_MILJOKONTROLL_SUCCESS:
      return action.payload;
    case miljokontrollerActions.GET_MILJOKONTROLL_FAILURE:
      return { ...INITIAL_STATE };
    case "MILJOKONTROLL_TO_INIT_STATE":
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}
