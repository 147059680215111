import React from "react";
import PropTypes from "prop-types";

import ModalGeneric from "./ModalGeneric";

class ModalGenericConfirmCancelContainer extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    modalTitle: PropTypes.string.isRequired,
    submitButtonText: PropTypes.string.isRequired,
    submittingButtonText: PropTypes.string,
    openModalComponentFunction: PropTypes.func,
    handleConfirmClick: PropTypes.func,
    handleCancelAndCloseClick: PropTypes.func,
    className: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = { open: false, lagrer: false };
  }

  closeModal = () => {
    const { handleCancelAndCloseClick } = this.props;
    this.setState({
      open: false,
      lagrer: false,
    });
    if (handleCancelAndCloseClick) {
      handleCancelAndCloseClick();
    }
  };

  openModal = () => {
    this.setState({ open: true });
  };

  saveAndClose = async () => {
    const { handleConfirmClick } = this.props;
    let success = true;
    if (handleConfirmClick) {
      this.setState(() => ({
        lagrer: true,
      }));
      success = await handleConfirmClick();
      this.setState(() => ({
        lagrer: false,
      }));
    }
    this.setState({
      open: success ? false : true,
    });
  };

  render() {
    const { submitButtonText, submittingButtonText } = this.props;
    const { lagrer } = this.state;

    return (
      <ModalGeneric
        id={this.props.id}
        modalTitle={this.props.modalTitle}
        open={this.state.open}
        closeModal={this.closeModal}
        openModal={this.openModal}
        OpenModalComponent={this.props.openModalComponentFunction}
        submit={this.saveAndClose}
        submitButtonText={lagrer ? submittingButtonText : submitButtonText}
        close={this.props.handleCancel}
        className={this.props.className}
      >
        {React.Children.map(this.props.children, (child) => child)}
      </ModalGeneric>
    );
  }
}

export default ModalGenericConfirmCancelContainer;
