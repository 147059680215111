import React from "react";
import PropTypes from "prop-types";
import Tooltip from "./Tooltip";

const MiljogebyrOvertredelseskoder = ({ ileggelse, overtredelseskoder }) => {
  const overtredelseskode1 =
    overtredelseskoder &&
    overtredelseskoder.find((ok) => ok.id === ileggelse.overtredelse1);

  const overtredelseskode2 =
    overtredelseskoder &&
    overtredelseskoder.find((ok) => ok.id === ileggelse.overtredelse2);

  const overtredelseskode3 =
    overtredelseskoder &&
    overtredelseskoder.find((ok) => ok.id === ileggelse.overtredelse3);
  return (
    <React.Fragment>
      {ileggelse.overtredelse1 && (
        <Tooltip tooltip={overtredelseskode1 && overtredelseskode1.kortTekst}>
          {overtredelseskode1 && overtredelseskode1.paragraf}
        </Tooltip>
      )}
      {ileggelse.overtredelse2 && (
        <React.Fragment>
          {"/"}
          <Tooltip tooltip={overtredelseskode2 && overtredelseskode2.kortTekst}>
            {overtredelseskode2 && overtredelseskode2.paragraf}
          </Tooltip>
        </React.Fragment>
      )}
      {ileggelse.overtredelse3 && (
        <React.Fragment>
          {ileggelse.overtredelse2 ? "/" : "//"}
          <Tooltip tooltip={overtredelseskode3 && overtredelseskode3.kortTekst}>
            {overtredelseskode3 && overtredelseskode3.paragraf}
          </Tooltip>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

MiljogebyrOvertredelseskoder.propTypes = {
  ileggelse: PropTypes.shape({}).isRequired,
  overtredelseskoder: PropTypes.arrayOf(PropTypes.shape({})),
};

export default MiljogebyrOvertredelseskoder;
