import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormGroup, FormLabel, Button } from "react-bootstrap";
import classnames from "classnames";

import "./EditableFieldJiraForTable.css";
import "./EditableFieldJira.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

class EditableFieldJiraForTable extends Component {
  static propTypes = {
    name: PropTypes.string,
    placeholder: PropTypes.string,
    isNumber: PropTypes.bool,
    inputMinLength: PropTypes.number,
    inputMaxLength: PropTypes.number,
    error: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    id: PropTypes.string,
    inputId: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    label: PropTypes.string,
    objectId: PropTypes.string,
  };

  state = {
    editing: false,
    value: this.props.value,
    error: "",
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({ ...this.state, value: nextProps.value });
    }
  }

  click = () => {
    if (!this.state.editing) {
      this.setState({ editing: true, error: "" });
    }
  };

  cancel = () => {
    this.setState({ editing: false, value: this.props.value, error: "" });
  };

  isValid(value) {
    let minLength = this.props.inputMinLength;
    let maxLength = this.props.inputMaxLength;
    let val = value;
    if (minLength) {
      if (val.length < minLength) {
        this.setState({
          error: "Tekstlengde må være minst " + minLength.toString(),
        });
        return false;
      }
    }
    if (maxLength) {
      if (val.length !== maxLength) {
        this.setState({
          error: "Tekstlengde må ikke være større enn " + maxLength.toString(),
        });
        return false;
      }
    }
    if (this.props.isNumber && isNaN(val.replace(",", "."))) {
      this.setState({ error: "Tekst må være av type nummer." });
      return false;
    } else {
      this.setState({ error: "" });
      return true;
    }
  }

  submit = () => {
    this.setState({ editing: false });
    if (this.isValid(this.state.value)) {
      this.props.objectId
        ? this.props.onSubmit(
            this.props.objectId,
            this.props.name,
            this.state.value
          )
        : this.props.onSubmit(this.props.name, this.state.value);
      this.setState({ error: "" });
    }
  };

  onBlur = (e) => {
    if (!this.node.contains(e.relatedTarget)) {
      this.cancel();
    }
  };

  handleChange = (ev) => {
    this.setState({ value: ev.target.value });
    this.isValid(ev.target.value);
  };

  moveCursorToEnd(e) {
    var temp_value = e.target.value;
    e.target.value = "";
    e.target.value = temp_value;
  }

  renderEditing() {
    const { label } = this.props;
    const { value, error } = this.state;
    return (
      <FormGroup
        id={this.props.id}
        className={classnames("editable-field-form", { "has-error": error })}
      >
        {label && <FormLabel>{label}</FormLabel>}
        <div
          className="editable-field-jira"
          ref={(node) => (this.node = node)}
          onBlur={this.onBlur}
        >
          <input
            className="form-control form-control-for-table"
            id={this.props.inputId}
            value={value}
            onChange={this.handleChange}
            autoFocus
            onFocus={this.moveCursorToEnd}
          />
          <div className="editable-field-jira-buttons">
            <Button bsSize="small" onClick={this.cancel}>
              <FontAwesomeIcon icon={faTimes} className="cancel" />
            </Button>
            <Button bsSize="small" onClick={this.submit} type="submit">
              <FontAwesomeIcon icon={faCheck} className="save" />
            </Button>
          </div>
        </div>
        {error.length > 0 && <span className="help-block">{error}</span>}
      </FormGroup>
    );
  }

  renderNormal() {
    const { value } = this.props;
    const { error } = this.state;
    return (
      <div
        id={this.props.id}
        className={classnames("editable-field-for-table", {
          "has-error": error,
        })}
        onClick={this.click}
      >
        {value}
        {error.length > 0 && <span className="help-block">{error}</span>}
      </div>
    );
  }

  render() {
    return this.state.editing ? this.renderEditing() : this.renderNormal();
  }
}

export default EditableFieldJiraForTable;
