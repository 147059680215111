import {
  handleChangeActionType,
  handleChangeAllActionType,
  handleChangeToInitStateActionType
} from "../../actions/handleChangeAction";
import _ from "lodash";
import moment from "moment-timezone";

export const INITIAL_STATE = {
  aar: moment()
    .tz("Europe/Oslo")
    .format("YYYY"),
  selectedIleggelsestyper: [
    { id: 1, navn: "Gebyr" },
    { id: 2, navn: "Tilleggsgebyr piggdekk" },
    { id: 3, navn: "Vilkårsparkering/Kontrollsanksjon" }
  ],

  aarValidationErrorText: undefined,
  selectedIleggelsestyperValidationErrorText: undefined
};

export default function aarsrapportFilterFormReducer(
  state = {
    ...INITIAL_STATE,
    validationError: { ...INITIAL_STATE.validationError }
  },
  action
) {
  switch (action.type) {
    case handleChangeActionType("RAPPORT_AARSRAPPORT_FILTER_FORM"):
      _.set(state, action.variableName, action.payload);
      return { ...state };
    case handleChangeAllActionType("RAPPORT_AARSRAPPORT_FILTER_FORM"):
      return { ...action.payload };
    case handleChangeToInitStateActionType("RAPPORT_AARSRAPPORT_FILTER_FORM"):
      return {
        ...INITIAL_STATE,
        validationError: { ...INITIAL_STATE.validationError }
      };
    default:
      return state;
  }
}
