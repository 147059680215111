import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Datetime from "react-datetime";
import { FormLabel } from "react-bootstrap";
import classnames from "classnames";
import { isValid, toNorwegianDatetime } from "../../../utils/datetimeUtils";
import "./DatetimePicker.css";

export default class DatetimePicker extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    locale: PropTypes.string,
    label: PropTypes.string,
    timeFormat: PropTypes.bool,
    validationError: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    placeholder: PropTypes.string,
    datetimeProps: PropTypes.any, // Check Datetime on "https://www.npmjs.com/package/react-datetime"
    rules: PropTypes.string,
  };

  handleDateTime = (datetime) => {
    if (isValid(datetime)) {
      let date = toNorwegianDatetime(datetime);
      return date.toISOString();
    }
    return "";
  };

  handleChange = (id, value, onChange) => {
    let date = this.handleDateTime(value);
    let fakeEvent = {
      target: {
        name: id,
        value: value,
        formatedValue: date,
      },
    };
    onChange(fakeEvent);
  };

  getValue = (value) => {
    return moment(value, moment.ISO_8601, true).isValid()
      ? moment(value)
      : value;
  };

  // This one is used so that the input field actually removes the value when value isn't truthy
  getInputProps = () => {
    const { id, placeholder, name, rules, value } = this.props;
    let inputProps = {
      id: id,
      placeholder: placeholder,
      name: name,
      rules: rules,
      autoComplete: "off",
    };
    if (!value) {
      inputProps = {
        ...inputProps,
        value: "",
      };
    }
    return inputProps;
  };

  render() {
    const {
      id,
      label,
      onChange,
      placeholder,
      value,
      validationError,
      locale,
      rules,
      name,
      ...datetimeProps
    } = this.props;

    return (
      <div
        className={classnames(
          "form-group",
          validationError ? { "has-error": validationError } : ""
        )}
      >
        <FormLabel>{label}</FormLabel>
        <Datetime
          locale={locale}
          onChange={(value) => this.handleChange(id, value, onChange)}
          value={this.getValue(value)}
          inputProps={this.getInputProps()}
          {...datetimeProps}
          closeOnSelect
        />
        {validationError && (
          <span className="validation-error">{validationError}</span>
        )}
      </div>
    );
  }
}
