import axios from "../services/axios";
import * as api from "../constants/api";

import { addFlashMessage } from "./FlashMessagesAction";

export const putStedbeskrivelse = (
  stedbeskrivelseId,
  payload,
  getSingleAfterUpdateSuccess,
  getAllAfterUpdateSuccess,
  getHistorikkAfterUpdateSuccess
) => async (dispatch) => {
  return axios
    .put(api.PUT_STEDSBESKRIVELSE_URL(stedbeskrivelseId), payload)
    .then((response) => {
      dispatch(addFlashMessage({ type: "success", text: "Oppdatert" }));
      if (getSingleAfterUpdateSuccess) {
        getSingleAfterUpdateSuccess();
      }
      if (getAllAfterUpdateSuccess) {
        getAllAfterUpdateSuccess();
      }
      if (getHistorikkAfterUpdateSuccess) {
        getHistorikkAfterUpdateSuccess();
      }

      let result = response && response.data && response.data.result;
      return Promise.resolve(result);
    })
    .catch((error) => {
      dispatch(addFlashMessage({ type: "error", message: error }));
      return Promise.reject(error);
    });
};
