import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import InnsynMotorvognFilterForm from "./InnsynMotorvognFilterForm";

import {
  handleChange as handleChangeAction,
  handleChangeAll as handleChangeAllAction,
  handleChangeToInitState as handleChangeToInitStateAction,
} from "../../actions/handleChangeAction";
import {
  GET_SINGLE_MOTORVOGN_SUCCESS,
  getSingleMotorvognByQuery as getSingleMotorvognByQueryAction,
} from "../../actions/motorvognActions";
import { isValidDateTime } from "../../utils/datetimeUtils";
import {
  DatoenErIkkeGyldig,
  DatoIsRequired,
  TimeIsNotValid,
} from "../../constants/ErrorMessages";

export class InnsynMotorvognFilterContainer extends React.Component {
  static propTypes = {
    innsynMotorvognFilterForm: PropTypes.shape({
      kjennemerke: PropTypes.string.isRequired,
    }),
    handleChange: PropTypes.func.isRequired,
    handleChangeAll: PropTypes.func.isRequired,
    handleChangeToInitState: PropTypes.func.isRequired,
    getSingleMotorvognByQuery: PropTypes.func.isRequired,
  };

  state = {
    showSokSpinner: false,
  };

  handleOnSubmit = (e) => {
    e.preventDefault();
    const { innsynMotorvognFilterForm, handleChangeAll } = this.props;

    handleChangeAll({
      kjennemerkeValidationError: null,
      datoValidationError: null,
      understellsnummerValidationError: null,
    });

    if (
      innsynMotorvognFilterForm &&
      this.isValid() &&
      (innsynMotorvognFilterForm.kjennemerke ||
        innsynMotorvognFilterForm.understellsnummer)
    ) {
      this.setState(() => ({ showSokSpinner: true }));

      let dato = null;
      if (
        typeof innsynMotorvognFilterForm.dato === "object" &&
        typeof innsynMotorvognFilterForm.tid === "object"
      ) {
        dato = innsynMotorvognFilterForm.dato
          .hours(innsynMotorvognFilterForm.tid.hours())
          .minutes(innsynMotorvognFilterForm.tid.minutes());
      } else if (typeof innsynMotorvognFilterForm.dato === "object") {
        dato = innsynMotorvognFilterForm.dato.hours(0).minutes(0);
      }
      const payload = {
        kjennemerke: innsynMotorvognFilterForm.kjennemerke,
        dato: dato,
        understellsnummer: innsynMotorvognFilterForm.understellsnummer,
      };
      this.props
        .getSingleMotorvognByQuery(payload)
        .then(() => this.setState(() => ({ showSokSpinner: false })))
        .catch(() => this.setState(() => ({ showSokSpinner: false })));
      handleChangeAll({
        kjennemerkeValidationError: null,
        datoValidationError: null,
        tidValidationError: null,
        understellsnummerValidationError: null,
      });
    }
  };

  isValid = () => {
    const { innsynMotorvognFilterForm, handleChangeAll } = this.props;

    let kjennemerkeValidationError = null;
    let datoValidationError = null;
    let tidValidationError = null;
    let understellsnummerValidationError = null;

    if (
      innsynMotorvognFilterForm.tid &&
      typeof innsynMotorvognFilterForm.tid === "object" &&
      isValidDateTime(innsynMotorvognFilterForm.tid) &&
      !innsynMotorvognFilterForm.dato &&
      !innsynMotorvognFilterForm.understellsnummer
    ) {
      datoValidationError = DatoIsRequired;
    }
    if (
      innsynMotorvognFilterForm.dato &&
      (typeof innsynMotorvognFilterForm.dato !== "object" ||
        !isValidDateTime(innsynMotorvognFilterForm.dato)) &&
      !innsynMotorvognFilterForm.understellsnummer
    ) {
      datoValidationError = DatoenErIkkeGyldig;
    }
    if (
      innsynMotorvognFilterForm.tid &&
      (typeof innsynMotorvognFilterForm.tid !== "object" ||
        !isValidDateTime(innsynMotorvognFilterForm.tid)) &&
      !innsynMotorvognFilterForm.understellsnummer
    ) {
      tidValidationError = TimeIsNotValid;
    }
    if (
      (innsynMotorvognFilterForm.dato || innsynMotorvognFilterForm.tid) &&
      (typeof innsynMotorvognFilterForm.dato === "object" ||
        typeof innsynMotorvognFilterForm.tid === "object") &&
      (isValidDateTime(innsynMotorvognFilterForm.dato) ||
        isValidDateTime(innsynMotorvognFilterForm.tid)) &&
      !innsynMotorvognFilterForm.kjennemerke &&
      !innsynMotorvognFilterForm.understellsnummer
    ) {
      kjennemerkeValidationError = "Kjennemerke må velges";
    }
    if (
      innsynMotorvognFilterForm.understellsnummer &&
      (innsynMotorvognFilterForm.kjennemerke ||
        innsynMotorvognFilterForm.dato ||
        innsynMotorvognFilterForm.tid)
    ) {
      understellsnummerValidationError = "Må brukes uten annen filtrering";
    }
    if (
      kjennemerkeValidationError ||
      datoValidationError ||
      tidValidationError ||
      understellsnummerValidationError
    ) {
      handleChangeAll({
        kjennemerkeValidationError,
        datoValidationError,
        tidValidationError,
        understellsnummerValidationError,
      });
      return false;
    }

    return true;
  };

  handleOnChange = (e) => {
    if (e.preventDefault !== undefined) e.preventDefault();
    this.props.handleChange(e);
  };

  handleOnChangeToInitState = () => {
    this.props.handleChangeToInitState();
  };

  render() {
    const { showSokSpinner } = this.state;
    return (
      <InnsynMotorvognFilterForm
        showSokSpinner={showSokSpinner}
        innsynMotorvognFilterForm={this.props.innsynMotorvognFilterForm}
        handleOnChange={this.handleOnChange}
        handleOnSubmit={this.handleOnSubmit}
        handleOnChangeToInitState={this.handleOnChangeToInitState}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    innsynMotorvognFilterForm: state.forms.innsynMotorvognFilterForm,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleChange: (e) =>
      dispatch(
        handleChangeAction(
          "INNSYN_MOTORVOGN_FILTER_FORM",
          e.target.name,
          e.target.value
        )
      ),
    handleChangeAll: (payload) =>
      dispatch(handleChangeAllAction("INNSYN_MOTORVOGN_FILTER_FORM", payload)),
    handleChangeToInitState: () =>
      dispatch(handleChangeToInitStateAction("INNSYN_MOTORVOGN_FILTER_FORM")),
    getSingleMotorvognByQuery: (params) =>
      dispatch(
        getSingleMotorvognByQueryAction(params, GET_SINGLE_MOTORVOGN_SUCCESS)
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InnsynMotorvognFilterContainer);
