import React, { Component } from "react";
import PropTypes from "prop-types";
import Modal from "../Common/ModalGeneric";
import TextInput from "../Common/Forms/TextInput";

export default class AddIleggelseToKlageModal extends Component {
  static propTypes = {
    ileggelser: PropTypes.array,
    currentKlageIleggelser: PropTypes.array,
    onSubmit: PropTypes.func,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    open: PropTypes.bool,
    validationError: PropTypes.object,
    closeModal: PropTypes.func,
    openModal: PropTypes.func,
    handleChange: PropTypes.func,
    ileggelsenummer: PropTypes.string,
    showSubmitSpinner: PropTypes.bool,
  };

  render() {
    const {
      id,
      ileggelsenummer,
      closeModal,
      openModal,
      onSubmit,
      disabled,
      handleChange,
      validationError,
      open,
      showSubmitSpinner,
    } = this.props;
    return (
      <Modal
        id={id}
        modalTitle="Legg til ileggelse for klage"
        open={open}
        closeModal={closeModal}
        openModal={openModal}
        popUpButtonText="Legg til ileggelse"
        isButtonLink
        submit={onSubmit}
        disabled={disabled}
        submitButtonText="Legg til"
        showSubmitSpinner={showSubmitSpinner}
        popUpButtonType="button"
        isForm
      >
        <TextInput
          label="Ileggelsesnummer"
          name="ileggelsenummer"
          value={ileggelsenummer}
          onChange={handleChange}
          autofocus
          validationError={validationError.ileggelsenummer}
        />
      </Modal>
    );
  }
}
