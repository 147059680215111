import React from "react";
import PropTypes from "prop-types";
import Tooltip from "./Tooltip";
import { get } from "lodash";

const IleggelseOvertredelseskoder = ({ ileggelse, overtredelseskoder }) => (
  <React.Fragment>
    {ileggelse.overtredelse1 && (
      <Tooltip
        tooltip={
          overtredelseskoder &&
          get(
            overtredelseskoder.find((ok) => ok.id === ileggelse.overtredelse1),
            ["beskrivelse"]
          )
        }
      >
        {ileggelse.overtredelse1}
      </Tooltip>
    )}
    {ileggelse.overtredelse2 && (
      <React.Fragment>
        {"/"}
        <Tooltip
          tooltip={
            overtredelseskoder &&
            get(
              overtredelseskoder.find(
                (ok) => ok.id === ileggelse.overtredelse2
              ),
              ["beskrivelse"]
            )
          }
        >
          {ileggelse.overtredelse2}
        </Tooltip>
      </React.Fragment>
    )}
    {ileggelse.overtredelse3 && (
      <React.Fragment>
        {ileggelse.overtredelse2 ? "/" : "//"}
        <Tooltip
          tooltip={
            overtredelseskoder &&
            get(
              overtredelseskoder.find(
                (ok) => ok.id === ileggelse.overtredelse3
              ),
              ["beskrivelse"]
            )
          }
        >
          {ileggelse.overtredelse3}
        </Tooltip>
      </React.Fragment>
    )}
  </React.Fragment>
);

IleggelseOvertredelseskoder.propTypes = {
  ileggelse: PropTypes.shape({}).isRequired,
  overtredelseskoder: PropTypes.arrayOf(PropTypes.shape({})),
};

export default IleggelseOvertredelseskoder;
