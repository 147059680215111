import React, { useRef } from "react";
import { Button } from "react-bootstrap";

export const BilderButtons = (props) => {
  const invisibleFileInputRef = useRef(null);

  return (
    <div>
      <div style={{ marginTop: 10 }}>
        <Button
          className="btn btn-bym-standard"
          onClick={() =>
            props.handleLeggTilBilderClick(invisibleFileInputRef.current)
          }
        >
          Legg til bilder
        </Button>
        <input
          type="file"
          multiple
          accept="image/*"
          style={{ display: "none" }}
          onChange={(e) => props.handleFileInputChange(e.target.files)}
          ref={invisibleFileInputRef}
        />
      </div>
    </div>
  );
};

export default BilderButtons;
