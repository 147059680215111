import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import moment from "moment-timezone";

import DropDown from "../../Common/Forms/DropDown";
import DropDownListMultiCheckboxOptions from "../../Common/Forms/DropDownListMultiCheckboxOptions";
import FormButtonsForCloseAndSubmit from "../../Common/Forms/FormButtonsForCloseAndSubmit";

const aarItems = [
  {
    id: moment().tz("Europe/Oslo").format("YYYY"),
    navn: moment().tz("Europe/Oslo").format("YYYY"),
  },
  {
    id: moment().tz("Europe/Oslo").subtract(1, "year").format("YYYY"),
    navn: moment().tz("Europe/Oslo").subtract(1, "year").format("YYYY"),
  },
  {
    id: moment().tz("Europe/Oslo").subtract(2, "year").format("YYYY"),
    navn: moment().tz("Europe/Oslo").subtract(2, "year").format("YYYY"),
  },
  {
    id: moment().tz("Europe/Oslo").subtract(3, "year").format("YYYY"),
    navn: moment().tz("Europe/Oslo").subtract(3, "year").format("YYYY"),
  },
  {
    id: moment().tz("Europe/Oslo").subtract(4, "year").format("YYYY"),
    navn: moment().tz("Europe/Oslo").subtract(4, "year").format("YYYY"),
  },
];

const handleSubmit = (
  event,
  aar,
  selectedIleggelsestyper,
  handleSubmit,
  setShowSokSpinner
) => {
  setShowSokSpinner(true);
  handleSubmit(event, aar, selectedIleggelsestyper)
    .then(() => setShowSokSpinner(false))
    .catch(() => setShowSokSpinner(false));
};

const AArsrapportFilterForm = (props) => {
  const { getIleggelsestyper } = props;
  const [showSokSpinner, setShowSokSpinner] = useState(false);

  useEffect(() => {
    getIleggelsestyper();
  }, [getIleggelsestyper]);

  return (
    <form
      onSubmit={(event) =>
        handleSubmit(
          event,
          props.aarsrapportFilterForm.aar,
          props.aarsrapportFilterForm.selectedIleggelsestyper,
          props.handleSubmit,
          setShowSokSpinner
        )
      }
      className="filter-container"
    >
      <div className="searchHistorikkOverskrift">
        Årsrapport basert på ileggelsestype
      </div>
      <div className="filter-flexbox-container klager-filter-flexbox">
        <div>
          <DropDown
            label="År"
            name="aar"
            items={aarItems}
            selected={props.aarsrapportFilterForm.aar}
            title="Velg"
            onChange={props.handleChangeAAr}
            validationError={props.aarsrapportFilterForm.aarValidationErrorText}
          />
        </div>
        <DropDownListMultiCheckboxOptions
          label="Ileggelsestype"
          name="selectedIleggelsestyper"
          items={props.ileggelsestyper
            .filter((it) => it.navn !== "Avgift")
            .map((filteredIleggelsestype) => ({
              id: filteredIleggelsestype.id,
              navn: filteredIleggelsestype.navn,
            }))}
          selected={props.aarsrapportFilterForm.selectedIleggelsestyper}
          title={
            props.aarsrapportFilterForm.selectedIleggelsestyper.length > 0
              ? `${props.aarsrapportFilterForm.selectedIleggelsestyper.length} valg`
              : "Velg"
          }
          onChange={props.handleChangeIleggelsestype}
          validationError={
            props.aarsrapportFilterForm.ileggelsestyperValidationErrorText
          }
        />
      </div>
      <Row>
        <Col sm={12}>{props.error}</Col>
      </Row>
      <Row>
        <Col sm={12}>
          <FormButtonsForCloseAndSubmit
            submitText="Søk"
            onClose={props.handleChangeToInitState}
            showSubmitSpinner={showSokSpinner}
          />
        </Col>
      </Row>
    </form>
  );
};

export default AArsrapportFilterForm;
