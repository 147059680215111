import { createSelector } from "reselect";
import _ from "lodash";
import { toNorwegianDatetime } from "../utils/datetimeUtils";
import { INITIAL_STATE as initMiljogebyrTilBehandlingQueryParameters } from "../reducers/forms/miljogebyrTilBehandlingFilterFormReducer";

const overtredelseskoder = (state) => state.overtredelseskoderMiljogebyr;

export const getOvertredelseskoder = createSelector(
  [overtredelseskoder],
  (overtredelseskoder) => {
    return [
      { id: "", navn: "Velg", beskrivelse: "" },
      ...overtredelseskoder.map((ok) => {
        return {
          id: ok.id,
          navn: ok.paragraf,
          beskrivelse: ok.kortTekst,
        };
      }),
    ];
  }
);

const miljogebyrTilBehandlingItems = (state) =>
  state.miljogebyrTilBehandling && state.miljogebyrTilBehandling.items;

export const getMiljogebyrTilBehandlingItems = createSelector(
  [miljogebyrTilBehandlingItems, overtredelseskoder],
  (miljogebyrTilBehandlingItems, overtredelseskoder) => {
    let vmMiljogebyrTilBehandlingItems = miljogebyrTilBehandlingItems.map(
      (miljogebyr) => {
        let paragrafer = [];
        [
          miljogebyr.overtredelse1,
          miljogebyr.overtredelse2,
          miljogebyr.overtredelse3,
        ].forEach((overtredelse) => {
          if (overtredelse && overtredelseskoder) {
            let matchingOvertredelseskode = overtredelseskoder.find(
              (overtredelseskode) => overtredelseskode.id === overtredelse
            );
            if (matchingOvertredelseskode) {
              paragrafer.push(matchingOvertredelseskode.paragraf);
            }
          }
        });
        miljogebyr.paragrafer = paragrafer;
        // if (miljogebyr.leveringstyp)
        return miljogebyr;
      }
    );
    return vmMiljogebyrTilBehandlingItems;
  }
);

const getOvertredelseParagrafer = (
  overtredelser,
  overtredelseskoderMiljogebyr
) => {
  const paragrafer = [];
  overtredelser.forEach((overtredelse) => {
    if (overtredelse) {
      const overtredelsesKode = _.find(
        overtredelseskoderMiljogebyr,
        (overtredelsesKode) => overtredelsesKode.id === overtredelse
      );
      if (overtredelsesKode) {
        paragrafer.push(overtredelsesKode.paragraf);
      }
    }
  });
  return paragrafer;
};

const showImageIcon = (ileggelse) => {
  if (ileggelse.bilder && ileggelse.bilder.length > 1) return "ShowImageIcon";
  return "";
};

export const getMiljogebyrTilBehandlingTableRows = createSelector(
  [miljogebyrTilBehandlingItems, overtredelseskoder],
  (miljogebyrTilBehandlingItems, overtredelseskoder) => {
    return miljogebyrTilBehandlingItems.map((miljogebyr) => {
      const ileggelsesdatoFra =
        miljogebyr.ileggelsesdatoFra &&
        toNorwegianDatetime(miljogebyr.ileggelsesdatoFra).format("DD.MM.YYYY");
      const ileggelsesdatoFraTid =
        miljogebyr.ileggelsesdatoFra &&
        toNorwegianDatetime(miljogebyr.ileggelsesdatoFra).format("HH:mm");
      const ileggelsesdatoTilTid =
        miljogebyr.ileggelsesdatoTil &&
        ` - ${toNorwegianDatetime(miljogebyr.ileggelsesdatoTil).format(
          "HH:mm"
        )}`;
      const klokkeslett = ileggelsesdatoFraTid + ileggelsesdatoTilTid;

      return {
        metadata: { isMakulert: miljogebyr.isMakulert },
        data: [
          miljogebyr.ileggelsesnummer && miljogebyr.ileggelsesnummer.toString(),
          ileggelsesdatoFra,
          klokkeslett,
          miljogebyr.betjentNummer && miljogebyr.betjentNummer.toString(),
          getOvertredelseParagrafer(
            [
              miljogebyr.overtredelse1,
              miljogebyr.overtredelse2,
              miljogebyr.overtredelse3,
            ],
            overtredelseskoder
          ).toString(),
          miljogebyr.leveringstype,
          miljogebyr.mottaker,
          miljogebyr.gatenavn,
          (miljogebyr.gatenummer || "") + " " + (miljogebyr.oppgang || ""),
          miljogebyr.vedGate,
          miljogebyr.vedGatenummer,
          miljogebyr.gnr,
          miljogebyr.bnr,
          showImageIcon(miljogebyr),
        ],
      };
    });
  }
);

// export const getMiljogebyrTilBehandlingTableRows = createSelector(
//   [miljogebyrTilBehandlingItems, overtredelseskoder],
//   (miljogebyrTilBehandlingItems, overtredelseskoder) => {
//     return miljogebyrTilBehandlingItems.map(miljogebyr => {
//       const ileggelsesdatoFra =
//         miljogebyr.ileggelsesdatoFra &&
//         toNorwegianDatetime(miljogebyr.ileggelsesdatoFra).format("DD.MM.YYYY");
//       const ileggelsesdatoFraTid =
//         miljogebyr.ileggelsesdatoFra &&
//         toNorwegianDatetime(miljogebyr.ileggelsesdatoFra).format("HH:mm");
//       const ileggelsesdatoTilTid =
//         miljogebyr.ileggelsesdatoTil &&
//         ` - ${toNorwegianDatetime(miljogebyr.ileggelsesdatoTil).format(
//           "HH:mm"
//         )}`;
//       const klokkeslett = ileggelsesdatoFraTid + ileggelsesdatoTilTid;

//       return {
//         metadata: { isMakulert: miljogebyr.isMakulert },
//         data: [
//           miljogebyr.ileggelsesnummer && miljogebyr.ileggelsesnummer.toString(),
//           ileggelsesdatoFra,
//           klokkeslett,
//           miljogebyr.betjentNummer && miljogebyr.betjentNummer.toString(),
//           getOvertredelseParagrafer(
//             [
//               miljogebyr.overtredelse1,
//               miljogebyr.overtredelse2,
//               miljogebyr.overtredelse3
//             ],
//             overtredelseskoder
//           ).toString(),
//           miljogebyr.gatenavn,
//           miljogebyr.gatenummer,
//           miljogebyr.vedGate,
//           miljogebyr.vedGatenummer
//         ]
//       };
//     });
//   }
// );

const miljogebyrTilBehandlingQueryParameters = (state) =>
  state.forms.miljogebyrerOversiktFilterForm;

const overtredelseskoderMiljogebyr = (state) =>
  state.overtredelseskoderMiljogebyr;

const miljoleveringstyper = (state) => state.miljoleveringstyper;

const getNavnById = (id, objectArray) => {
  const object = objectArray.filter((leveringstype) => leveringstype.id === id);
  return object.length > 0 && object[0].navn ? object[0].navn : "";
};

export const getMiljogebyrTilBehandlingQueryParameters = createSelector(
  [
    miljogebyrTilBehandlingQueryParameters,
    overtredelseskoderMiljogebyr,
    miljoleveringstyper,
  ],
  (
    miljogebyrTilBehandlingQueryParameters,
    overtredelseskoderMiljogebyr,
    miljoleveringstyper
  ) => {
    let changedMiljogebyrQueryParameters = [];
    if (
      initMiljogebyrTilBehandlingQueryParameters.ileggelsesnummer !==
      miljogebyrTilBehandlingQueryParameters.ileggelsesnummer
    ) {
      changedMiljogebyrQueryParameters = [
        ...changedMiljogebyrQueryParameters,
        {
          key: "Ileggelsesnummer",
          value: miljogebyrTilBehandlingQueryParameters.ileggelsesnummer,
        },
      ];
    }
    if (
      miljogebyrTilBehandlingQueryParameters.fraDato &&
      miljogebyrTilBehandlingQueryParameters.tilDato
    ) {
      changedMiljogebyrQueryParameters = [
        ...changedMiljogebyrQueryParameters,
        {
          key: "Dato",
          value: `${toNorwegianDatetime(
            miljogebyrTilBehandlingQueryParameters.fraDato
          ).format("DD.MM.YYYY HH:mm")} - ${toNorwegianDatetime(
            miljogebyrTilBehandlingQueryParameters.tilDato
          ).format("DD.MM.YYYY HH:mm")}`,
        },
      ];
    }
    if (
      initMiljogebyrTilBehandlingQueryParameters.tjenestenummer !==
      miljogebyrTilBehandlingQueryParameters.tjenestenummer
    ) {
      changedMiljogebyrQueryParameters = [
        ...changedMiljogebyrQueryParameters,
        {
          key: "Tjenestenummer",
          value: miljogebyrTilBehandlingQueryParameters.tjenestenummer,
        },
      ];
    }
    if (
      initMiljogebyrTilBehandlingQueryParameters.overtredelseskode !==
      miljogebyrTilBehandlingQueryParameters.overtredelseskode
    ) {
      changedMiljogebyrQueryParameters = [
        ...changedMiljogebyrQueryParameters,
        {
          key: "Overtredelsekode",
          value: getOvertredelseParagrafer(
            [miljogebyrTilBehandlingQueryParameters.overtredelseskode],
            overtredelseskoderMiljogebyr
          ).toString(),
        },
      ];
    }
    if (
      initMiljogebyrTilBehandlingQueryParameters.mottaker !==
      miljogebyrTilBehandlingQueryParameters.mottaker
    ) {
      changedMiljogebyrQueryParameters = [
        ...changedMiljogebyrQueryParameters,
        {
          key: "Mottaker",
          value: miljogebyrTilBehandlingQueryParameters.mottaker,
        },
      ];
    }
    if (
      initMiljogebyrTilBehandlingQueryParameters.gatenavn !==
      miljogebyrTilBehandlingQueryParameters.gatenavn
    ) {
      changedMiljogebyrQueryParameters = [
        ...changedMiljogebyrQueryParameters,
        {
          key: "Gatenavn/Ved",
          value: miljogebyrTilBehandlingQueryParameters.gatenavn,
        },
      ];
    }
    if (
      initMiljogebyrTilBehandlingQueryParameters.gatenummer !==
      miljogebyrTilBehandlingQueryParameters.gatenummer
    ) {
      changedMiljogebyrQueryParameters = [
        ...changedMiljogebyrQueryParameters,
        {
          key: "Gatenummer/Ved nr.",
          value: miljogebyrTilBehandlingQueryParameters.gatenummer,
        },
      ];
    }
    if (
      initMiljogebyrTilBehandlingQueryParameters.bydel !==
      miljogebyrTilBehandlingQueryParameters.bydel
    ) {
      changedMiljogebyrQueryParameters = [
        ...changedMiljogebyrQueryParameters,
        {
          key: "Bydel",
          value: miljogebyrTilBehandlingQueryParameters.bydel,
        },
      ];
    }
    if (
      initMiljogebyrTilBehandlingQueryParameters.leveringstype !==
      miljogebyrTilBehandlingQueryParameters.leveringstype
    ) {
      changedMiljogebyrQueryParameters = [
        ...changedMiljogebyrQueryParameters,
        {
          key: "Leveringstype",
          value: getNavnById(
            miljogebyrTilBehandlingQueryParameters.leveringstype,
            miljoleveringstyper
          ),
        },
      ];
    }

    return changedMiljogebyrQueryParameters;
  }
);
