import React from "react";
import PropTypes from "prop-types";
import Datetime from "react-datetime";
import { FormLabel, FormGroup } from "react-bootstrap";
import "./DatetimePicker.css";
import {
  isValid,
  fromNorwegianDate,
  toNorwegianDateFormat,
} from "../../../utils/datetimeUtils";

export default class DatePicker extends React.Component {
  static defaultProps = {
    locale: "nb",
    dateFormat: "DD.MM.YYYY",
    utc: true,
  };
  state = {
    initial: true,
  };
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    locale: PropTypes.string,
    label: PropTypes.string,
    timeFormat: PropTypes.bool,
    dateFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    validationError: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    placeHolder: PropTypes.string,
    datetimeProps: PropTypes.any, // Check Datetime on "https://www.npmjs.com/package/react-datetime"
    rules: PropTypes.string,
  };

  handleDateTime = (datetime) => {
    if (isValid(datetime)) {
      let date = fromNorwegianDate(datetime);
      return date.toISOString();
    }
    return "";
  };

  handleChange = (id, value, onChange) => {
    this.setState({ initial: false });
    let date = this.handleDateTime(value);
    let fakeEvent = {
      target: {
        name: id,
        value: value,
        formatedValue: date,
      },
    };
    onChange(fakeEvent);
  };

  render() {
    const {
      id,
      label,
      onChange,
      placeHolder,
      value,
      validationError,
      locale,
      dateFormat,
      rules,
      name,
      ...datetimeProps
    } = this.props;
    const { initial } = this.state;
    return (
      <FormGroup validationState={validationError && "error"}>
        <FormLabel>{label}</FormLabel>
        <Datetime
          onChange={(value) => this.handleChange(id, value, onChange)}
          value={initial ? toNorwegianDateFormat(value) : value}
          inputProps={{ placeholder: placeHolder, name: name, rules: rules }}
          locale={locale}
          dateFormat={dateFormat}
          id={id}
          {...datetimeProps}
        />
        {validationError && (
          <span className="validation-error">{validationError}</span>
        )}
      </FormGroup>
    );
  }
}
