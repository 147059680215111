import React, { Component } from "react";
import PropTypes from "prop-types";
import Table from "../Common/Tables/BaseTable";
import { formatDateTimeHavingNorwegianDateTime } from "../../utils/datetimeUtils";
import { map, isEmpty } from "lodash";

export default class TilbakeMeldingerFraBybetjent extends Component {
  static propTypes = {
    tilbakemeldinger: PropTypes.array,
  };

  renderRow() {
    const { tilbakemeldinger } = this.props;
    if (!isEmpty(tilbakemeldinger)) {
      return map(tilbakemeldinger, (t, index) => (
        <tr key={index}>
          <td>
            {t.rettelseDato &&
              formatDateTimeHavingNorwegianDateTime(t.rettelseDato)}
          </td>
          <td>{t.tjenestenummer}</td>
          <td>{t.rettelse}</td>
          <td>{t.kommentar}</td>
        </tr>
      ));
    }
  }

  render() {
    const header = ["Dato", "Tjenestenummer", "Rettelse", "Kommentar"];
    const trStyle = {
      borderTop: "none",
      borderBottom: "none",
    };

    return (
      <div>
        <div
          className="fake-label"
          style={{ fontSize: 16, fontWeight: "bold" }}
        >
          Tilbakemelding
        </div>
        <div style={{ marginTop: 10 }} />
        <Table headerRow={header} striped trStyle={trStyle}>
          <tbody>{this.renderRow()}</tbody>
        </Table>
      </div>
    );
  }
}
