import { GET_ILEGGELSESTYPER_SUCCESS } from "../actions/ileggelsestyperActions";

const INITIAL_STATE = [];

export default function ileggelsestyperReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ILEGGELSESTYPER_SUCCESS:
      return action.payload
    default:
      return state;
  }
}