import axios from "../services/axios";
import * as api from "../constants/api";
import join from "lodash/join";
import { errorMessage, fackePromis } from "../utils/axiosUtils";
import { addFlashMessage } from "../actions/FlashMessagesAction";

export const GET_P360_SAK = "GET_P360_SAK";
export const GET_SAK_DOKUMENTER = "GET_SAK_DOKUMENTER";
export const GET_SAK_FIL = "GET_SAK_FIL";

export const getP360Sak = (saksnummerObject, cleanstate = true) => {
  return async (dispatch) => {
    cleanstate && dispatch(cleanUpAllKlageSidenState);
    const url = api.GET_P360_SAK_URL(
      saksnummerObject.year,
      saksnummerObject.nummer
    );
    return axios
      .get(url)
      .then((response) => {
        const result = response.data.result;
        dispatch({ type: GET_P360_SAK, payload: result });
        return result;
      })
      .catch(() => {
        errorMessage(dispatch, "Saksnummer finnes ikke.", GET_P360_SAK);
        return null;
      });
  };
};

export const getP360SakFil = (saksnummerObject, fileId) => {
  const url = api.GET_P360_SAK_FIL_URL(
    saksnummerObject.year,
    saksnummerObject.nummer,
    fileId
  );
  return async (dispatch) => {
    return axios.get(url).then((response) => {
      const result = response.data.result;
      dispatch({ type: GET_SAK_FIL, payload: result });
      return result;
    });
  };
};

const JoinKryssedDokumentNummere = (sakDokumentOrigin, sakDokumentToKryss) => {
  let joinDokumenterNr = "";
  if (sakDokumentToKryss && sakDokumentToKryss.replyDokumenter) {
    var joined = join(sakDokumentToKryss.replyDokumenter, ", ");
    joinDokumenterNr = `${sakDokumentOrigin}, ${joined}`;
  }
  return joinDokumenterNr;
};

export const P360KrysseDokumenter = (sakDokumentOrigin, sakDokumentToKryss) => {
  const url = api.UPDATE_P360_SAK_KRYSS_DOKUMENTER_URL(sakDokumentOrigin);
  const joinDokumenterNr = JoinKryssedDokumentNummere(
    sakDokumentOrigin,
    sakDokumentToKryss
  );
  return async (dispatch) => {
    return axios
      .put(url, sakDokumentToKryss)
      .then((response) => {
        const result = response.data && response.data.result;
        dispatch(
          addFlashMessage({
            type: "success",
            text: `Avkryssing av dokumenter ${joinDokumenterNr} ble oppdatert.`,
          })
        );
        return result;
      })
      .catch((error) => {
        dispatch(
          addFlashMessage({
            type: "error",
            text: `Avkryssing av dokumenter ${joinDokumenterNr} feilet.`,
          })
        );

        return null;
      });
  };
};

export const nullStilleKlageSidenState = () => {
  return (dispatch) =>
    fackePromis("Tilbaksetiller  kalgesiden").then(() => {
      cleanUpAllKlageSidenState(dispatch);
    });
};

const cleanUpAllKlageSidenState = (dispatch) => {
  dispatch({ type: "GET_P360_SAK", payload: {} });
  dispatch({ type: "GET_SAK_DOKUMENTER", payload: {} });
  dispatch({ type: "GET_KLAGE_ILEGGELSE", payload: {} });
  dispatch({ type: "GET_ALL_KLAGE_ILEGGELSER_SUCCESS", payload: [] });
  dispatch({ type: "GET_ALL_KLAGE_VEDTAK_SUCCESS", payload: {} });
  dispatch({ type: "GET_ONE_KLAGE_DETAIL_SUCCESS", payload: {} });
  dispatch({ type: "GET_ILEGGELSE_DETAIL", payload: {} });
  dispatch({ type: "KLAGE_ILEGGELSE_IMAGE", payload: [] });
  dispatch({ type: "GET_ILEGGELSE_RETTELSER_SUCCESS", payload: [] });
  dispatch({ type: "GET_MILJO_RETTELSER_SUCCESS", payload: [] });
  return {};
};
