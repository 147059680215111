import React from "react";
import { connect } from "react-redux";
import Utskrift from "../Utskrift";
import {
  getEttersendelseQueryParameters,
  getEttersendelseTableRows,
} from "../../../selectors/ettersendelserSelector";
import { handleChangeAll as handleChangeAllAction } from "../../../actions/handleChangeAction";
import { getEttersendelser as getEttersendelserAction } from "../../../actions/ettersendelserActions";
import * as title from "../../../constants/titles";

class UtskriftContainer extends React.Component {
  async componentDidMount() {
    const { handleChangeAll, getEttersendelser } = this.props;
    document.title = title.ileggelse.ettersendelser;
    const sessionStoragePrintData = JSON.parse(
      window.sessionStorage.getItem("printData")
    );
    handleChangeAll(sessionStoragePrintData.queryData);
    await getEttersendelser(
      { ...sessionStoragePrintData.queryData, isElsparksykkelGebyr: false },
      sessionStoragePrintData.paginationData.page,
      sessionStoragePrintData.paginationData.count
    );
    setTimeout(() => {
      window.print();
      window.close();
    }, 1000);
  }

  render() {
    const { ileggelseTableHeaders, ileggelseTableRows, queryParameters } =
      this.props;
    return (
      <Utskrift
        title="Ettersendelser"
        ileggelseTableHeaders={ileggelseTableHeaders}
        ileggelseTableRows={ileggelseTableRows}
        queryParameters={queryParameters}
      />
    );
  }

  componentWillUnmount() {
    window.sessionStorage.removeItem("printData");
  }
}

const mapStateToProps = (state) => {
  return {
    ileggelseTableHeaders: [
      "Ilegg.nr.",
      "Ilegg.dato",
      "Klokkeslett",
      "Tjenestenr.",
      "Overt.kode",
      "Kj.merke",
      "Nasjon",
      "Gatenavn",
      "Gatenr.",
      "Bilde",
      "Ettersendelsesrapport",
      "Tilbakemelding",
    ],
    ileggelseTableRows: getEttersendelseTableRows(state),
    queryParameters: getEttersendelseQueryParameters(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEttersendelser: (params, page, count) =>
      dispatch(getEttersendelserAction(params, page, count)),
    handleChangeAll: (payload) =>
      dispatch(handleChangeAllAction("ETTERSENDELSER_FILTER_FORM", payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UtskriftContainer);
