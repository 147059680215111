import { connect } from "react-redux";
import VentistillingsdetailUtskrift from "./VentilstillingsdetailUtskrift";

import { getVentilstilling as getVentilstillingAction } from "../../../actions/ventilstillingerActions";

const mapStateToProps = (state) => ({ ventilstilling: state.ventilstilling });

const mapDispatchToProps = (dispatch) => ({
  getVentilstilling: (id) => dispatch(getVentilstillingAction(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VentistillingsdetailUtskrift);
