import {
  handleChangeActionType,
  handleChangeToInitStateActionType
} from "../../actions/handleChangeAction";

const VENTILSTILLING_FILTER_FORM = "VENTILSTILLING_FILTER_FORM";

export const INITIAL_STATE = {
  fraDato: "",
  tilDato: "",
  kjennemerke: "",

  fraDatoValidationErrorText: null,
  tilDatoValidationErrorText: null
};

export default function VENTILSTILLINGFilerFormReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case handleChangeActionType(VENTILSTILLING_FILTER_FORM):
      return { ...state, [action.variableName]: action.payload };
    case handleChangeToInitStateActionType(VENTILSTILLING_FILTER_FORM):
      return INITIAL_STATE;
    default:
      return state;
  }
}
