import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import MiljokontrollDetalj from "./MiljokontrollDetalj";

import { getMiljokontroll as getMiljokontrollAction } from "../../../../actions/miljokontrollerActions";

import {
  formatDateHavingNorwegianDateTime,
  formatTimeHavingNorwegianDateTime,
} from "../../../../utils/datetimeUtils";

import sitePaths from "../../../../constants/sitePaths";

const mapStateToProps = (state) => ({ miljokontroll: state.miljokontroll });

const mapDispatchToProps = (dispatch) => ({
  getMiljokontroll: (id) => dispatch(getMiljokontrollAction(id)),
});

const getMiljogebyrLinkUrl = (miljokontroll, location) => {
  if (
    location.pathname.search(
      sitePaths.miljogebyrer.oversikt.detalj.miljokontroll.shortUrl.matcher
    ) === 0
  ) {
    return sitePaths.miljogebyrer.oversikt.detalj.shortUrl.getUrl(
      miljokontroll.miljogebyrId
    );
  } else if (
    location.pathname.search(
      sitePaths.miljogebyrer.tilBehandling.detalj.miljokontroll.shortUrl.matcher
    ) === 0
  ) {
    return sitePaths.miljogebyrer.tilBehandling.detalj.shortUrl.getUrl(
      miljokontroll.miljogebyrId
    );
  } else if (
    location.pathname.search(
      sitePaths.klage.miljogebyrer.detalj.miljokontroll.shortUrl.matcher
    ) === 0
  ) {
    return sitePaths.klage.miljogebyrer.detalj.shortUrl.getUrl(
      miljokontroll.miljogebyrId
    );
  } else if (
    location.pathname.search(
      sitePaths.miljogebyrer.miljokontrolloversikt.miljogebyrer.detalj
        .miljokontroll.detalj.shortUrl.matcher
    ) === 0
  ) {
    return sitePaths.miljogebyrer.miljokontrolloversikt.miljogebyrer.detalj.shortUrl.getUrl(
      miljokontroll.miljogebyrId
    );
  } else if (
    location.pathname.search(
      sitePaths.miljogebyrer.miljokontrolloversikt.detalj.shortUrl.matcher
    ) === 0
  ) {
    return sitePaths.miljogebyrer.miljokontrolloversikt.detalj.miljogebyrer.detalj.shortUrl.getUrl(
      miljokontroll.id,
      miljokontroll.miljogebyrId
    );
  }
  return null;
};

const miljokontrollGridTableRows = (miljokontroll, location) => {
  if (!miljokontroll) {
    return [];
  }

  const to = miljokontroll.miljogebyrId
    ? getMiljogebyrLinkUrl(miljokontroll, location)
    : null;

  let ileggelsesnummer =
    miljokontroll && miljokontroll.miljogebyrId ? (
      <Link to={to}>
        {miljokontroll.ileggelsesnummer &&
          miljokontroll.ileggelsesnummer.toString()}
      </Link>
    ) : (
      ""
    );

  return [
    [
      {
        name: "Kontrollnummer",
        value: miljokontroll.nummer ? miljokontroll.nummer.toString() : "",
        description: "",
      },
      {
        name: "Dato",
        value: formatDateHavingNorwegianDateTime(miljokontroll.mottattDato),
        description: "",
      },
      {
        name: "Klokkeslett",
        value: formatTimeHavingNorwegianDateTime(miljokontroll.mottattDato),
        description: "",
      },
      {
        name: "Tjenestenummer",
        value: miljokontroll.tjenestenummer
          ? miljokontroll.tjenestenummer.toString()
          : "",
        description: "",
      },
      {
        name: "Overtredelseskode",
        value:
          miljokontroll.overtredelse1 && miljokontroll.overtredelse1.paragraf,
        description: "",
      },
      {
        name: "Ileggelsesnummer",
        value: ileggelsesnummer,
        description: "",
      },
    ],
    [
      {
        name: "Gatenavn",
        value: miljokontroll.gatenavn,
        description: "",
      },
      {
        name: "Gatenummer",
        value: miljokontroll.gatenummer,
        description: "",
      },
      {
        name: "Bydel",
        value: miljokontroll.bydel,
        description: "",
      },
      {
        name: "",
        value: "",
        description: "",
      },
      {
        name: "",
        value: "",
        description: "",
      },
      {
        name: "",
        value: "",
        description: "",
      },
    ],
    [
      {
        name: "Ved gate",
        value: miljokontroll.vedGate,
        description: "",
      },
      {
        name: "Ved gatenummer",
        value: miljokontroll.vedGatenummer,
        description: "",
      },
      {
        name: "Latitude",
        value: miljokontroll.latitude ? miljokontroll.latitude.toString() : "",
        description: "",
      },
      {
        name: "Longitude",
        value: miljokontroll.longitude
          ? miljokontroll.longitude.toString()
          : "",
        description: "",
      },
      {
        name: "",
        value: "",
        description: "",
      },
      {
        name: "",
        value: "",
        description: "",
      },
    ],
    [
      {
        name: "Antall observasjoner",
        value:
          miljokontroll.observasjoner &&
          miljokontroll.observasjoner.length.toString(),
        description: "",
      },
      {
        name: "",
        value: "",
        description: "",
      },
      {
        name: "",
        value: "",
        description: "",
      },
      {
        name: "",
        value: "",
        description: "",
      },
      {
        name: "",
        value: "",
        description: "",
      },
      {
        name: "",
        value: "",
        description: "",
      },
    ],
  ];
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  miljokontroll: stateProps.miljokontroll,

  getMiljokontroll: (id) => dispatchProps.getMiljokontroll(id),

  miljokontrollGridTableRows: miljokontrollGridTableRows(
    stateProps.miljokontroll,
    ownProps.location
  ),

  ...ownProps,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(MiljokontrollDetalj)
);
