const text = {
  makulerModal: {
    saveButtonText: "Makuler ileggelse",
    saveButtonIsSaving: "Lagrer...",
    inputLabel: "Makuleringsårsak",
    inputPlaceHolder: "Skriv inn makuleringsårsak",
    //empty strings will be dynamic text functions, will be overwritten by the init function
    title: (titleType, ileggelseNr) => `Makuler ${titleType} ${ileggelseNr}`,
    askUser: ileggelseNr => `Vil du makulere ileggelse ${ileggelseNr}?`
  },

  ileggelseGebyr: {
    pageName: "Ileggelse"
  },

  miljoGebyr: {
    pageName: "Miljøgebyr"
  },

  detailPage: {
    closeButtonText: pagename => `Lukk ${pagename}`
  },
  ettersendelseOversikt: {
    tjenesteNr: "Tjenestenr.",
    ileggelseNr: "Ilegg.nr.",
    ileggelsedato: "Ilegg.dato",
    kjennemerke: "Kj.merke",
    nasjon: "Nasjon",
    overtredelsekode: "Overt.kode",
    klokkeslett: "Klokkeslett",
    gatenavn: "Gatenavn",
    gatenr: "Gatenr.",
    bilde: "Bilde",
    team: "Team",
    ettersendelserRapport: "Ettersendelsesrapport",
    tilbakemelding: "Tilbakemelding"
  }
};

export { text };
