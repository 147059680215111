import { combineReducers } from "redux";

import aktorerReducer from "./aktorerReducer";
import bybetjenterReducer from "./bybetjenterReducer";
import bybetjenterGrupperReducer from "./bybetjentGrupperReducer";
import byBetjentTeamHistorikkReducer from "./byBetjentTeamHistorikkReducer";
import bybetjenterRollerReducer from "./bybetjentRollerReducer";
import bybetjenterSeksjonerReducer from "./bybetjentSeksjonerReducer";
import fargerReducer from "./fargerReducer";
import folgebrevReducer from "./folgebrevReducer";
import skiftloggerReducer from "./skiftloggerReducer";
import ventilstillingerReducer from "./ventilstillingerReducer";
import ventilstillingerBilderReducer from "./ventilstillingerBilderReducer";
import ventilstillingReducer from "./ventilstillingReducer";
import formReducers from "./forms/formReducers";
import flashMessagesReducer from "./flashMessagesReducer";
import elsparksykkelGebyrReducer from "./elsparksykkelGebyrReducer";
import ettersendelserReducer from "./ettersendelserReducer";
import ettersendelserTilGodkjenningReducer from "./ettersendelserTilGodkjenningReducer";
import ileggelseReducer from "./ileggelseReducer";
import ileggelseBilderReducer from "./ileggelseBilderReducer";
import ileggelserReducer from "./ileggelserReducer";
import ileggelseHistorikkReducer from "./ileggelseHistorikkReducer";
import ileggelseRettelserReducer from "./ileggelseRettelserReducer";
import ileggelseMakuleringerReducer from "./ileggelseMakuleringerReducer";
import ileggelseKlagerReducer from "./ileggelseKlagerReducer";
import ileggelsestyperReducer from "./ileggelsestyperReducer";
import innkrevingReducer from "./innkrevingReducer";
import internkoderIleggelserReducer from "./internkoderIleggelserReducer";
import overtredelsegrupperReducer from "./overtredelsegrupperReducer";
import internkoderMiljogebyrReducer from "./internkoderMiljogebyrReducer";
import vedtakskoderReducer from "./vedtakskoderReducer";
import vedtakerReducer from "./vedtakerReducer";
import overtredelseskoderIleggelserReducer from "./overtredelseskoderIleggelserReducer";
import overtredelseskoderMiljogebyrReducer from "./overtredelseskoderMiljogebyrReducer";
import ileggelseleveringstyperReducer from "./ileggelseleveringstyperReducer";
import kjoretoyTyperReducer from "./kjoretoyTyperReducer";
import kjoretoyMerkerReducer from "./kjoretoyMerkerReducer";
import miljogebyrerReducer from "./miljogebyrerReducer";
import miljogebyrTilBehandlingReducer from "./miljogebyrTilBehandlingReducer";
import miljogebyrReducer from "./miljogebyrReducer";
import miljogebyrHistorikkReducer from "./miljogebyrHistorikkReducer";
import miljogebyrKlagerReducer from "./miljogebyrKlagerReducer";
import miljoRettelserReducer from "./miljoRettelserReducer";
import miljogebyrEttersendelserReducer from "./miljogebyrEttersendelserReducer";
import miljoMakuleringerReducer from "./miljoMakuleringerReducer";
import piggdekkontrollerReducer from "./piggdekkontrollerReducer";
import innsynMotorvognReducer from "./innsynMotorvognReducer";
import klageIleggelseReducer from "./klageIleggelseReducer";
import klageIleggelserReducer from "./klageIleggelserReducer";
import klageIleggelseImageReducer from "./klageIleggelseimageReducers";
import klageIleggelseLightboxImageReducer from "./klageIleggelseLightboxImageReducer";
import klageIleggelseThumbnailImageReducer from "./klageIleggelseThumbnailImageReducer";
import klageDetailReducer from "./klageDetailReducer";
import klageMiljoKontrollImageReducer from "./klageMiljoKontrollImageReducer";
import klageMiljoKontrollLightboxImageReducer from "./klageMiljoKontrollLightboxImageReducer";
import klageMiljoKontrollThumbnailImageReducer from "./klageMiljoKontrollThumbnailImageReducer";
import ileggelseEttersendelserReducer from "./ileggelseEttersendelserReducer";
import miljoleveringstyperReducer from "./miljoleveringstyperReducer";
import klageKoderReducer from "./klageKoderReducer";
import mottakertyperReducer from "./mottakertyperReducer";
import p360Reducer from "./p360Reducer";
import ileggelseKommentarReducer from "./ileggelseKommentarReducer";
import miljogebyrKommentarReducer from "./miljogebyrKommentarReducer";
import miljogebyrBilderReducer from "./miljogebyrBilderReducer";
import miljokontrollerReducer from "./miljokontrollerReducer";
import miljokontrollReducer from "./miljokontrollReducer";
import miljokontrollKommentarerReducer from "./miljokontrollKommentarerReducer";
import miljokontrollHistorikkReducer from "./miljokontrollHistorikkReducer";
import uregistrertekontrollerReducer from "./uregistrertekontrollerReducer";
import nasjonerReducer from "./nasjonerReducer";
import klageRapportReducer from "./klageRapportReducer";
import kalgeRapportSaksbehandlerReducer from "./klageRapportSaksbehandlerReducer";
import rapportReducers from "./rapporter/rapportReducers";
import rettekoderReducer from "./rettekoderReducer";
import uferdigstilteKlagesakerReducer from "./uferdigstilteKlagesakerReducer";
import submittedFormReducers from "./submittedForms/submittedFormReducers";

import createAdvancedListReducer from "./createAdvancedListReducer";
import miljoKontrollerBilderReducer from "./miljokontrollBilderReducer";
import miljoKontrollerLightboxBilderReducer from "./miljokontrollLightboxBilderReducer";
import miljoKontrollerThumbnailBilderReducer from "./miljokontrollThumbnailBilderReducer";

const rootReducer = combineReducers({
  aktorer: aktorerReducer,
  bybetjenter: bybetjenterReducer,
  betjentgrupper: bybetjenterGrupperReducer,
  betjentTeamHistorikk: byBetjentTeamHistorikkReducer,
  betjentroller: bybetjenterRollerReducer,
  bybetjenterseksjoner: bybetjenterSeksjonerReducer,
  farger: fargerReducer,
  folgebrev: folgebrevReducer,
  skiftlogger: skiftloggerReducer,
  ventilstillinger: ventilstillingerReducer,
  ventilstillingerBilder: ventilstillingerBilderReducer,
  ventilstilling: ventilstillingReducer,
  innsynMotorvogn: innsynMotorvognReducer,
  klageIleggelse: klageIleggelseReducer,
  klageIleggelser: klageIleggelserReducer,
  klageIleggelseImage: klageIleggelseImageReducer,
  klageIleggelseLightboxImage: klageIleggelseLightboxImageReducer,
  klageIleggelseThumbnailImage: klageIleggelseThumbnailImageReducer,
  klageMiljoKontrollImage: klageMiljoKontrollImageReducer,
  klageMiljoKontrollLightboxImage: klageMiljoKontrollLightboxImageReducer,
  klageMiljoKontrollThumbnailImage: klageMiljoKontrollThumbnailImageReducer,
  klageDetail: klageDetailReducer,
  forms: formReducers,
  flashMessages: flashMessagesReducer,
  elsparksykkelGebyr: createAdvancedListReducer(
    "ELSPARKSYKKELGEBYR",
    elsparksykkelGebyrReducer
  ),
  ettersendelser: createAdvancedListReducer(
    "ETTERSENDELSER",
    ettersendelserReducer
  ),
  ettersendelserTilGodkjenning: createAdvancedListReducer(
    "ETTERSENDELSERTILGODKJENNING",
    ettersendelserTilGodkjenningReducer
  ),
  ileggelse: ileggelseReducer,
  ileggelseBilder: ileggelseBilderReducer,
  ileggelser: createAdvancedListReducer("ILEGGELSER", ileggelserReducer),
  ileggelseHistorikk: ileggelseHistorikkReducer,
  ileggelseRettelser: ileggelseRettelserReducer,
  ileggelseMakuleringer: ileggelseMakuleringerReducer,
  ileggelseKlager: ileggelseKlagerReducer,
  ileggelsestyper: ileggelsestyperReducer,
  innkreving: innkrevingReducer,
  internkoderIleggelser: internkoderIleggelserReducer,
  internkoderMiljogebyr: internkoderMiljogebyrReducer,
  ileggelseleveringstyper: ileggelseleveringstyperReducer,
  overtredelseskoderIleggelser: overtredelseskoderIleggelserReducer,
  overtredelsegrupper: overtredelsegrupperReducer,
  overtredelseskoderMiljogebyr: overtredelseskoderMiljogebyrReducer,
  kjoretoyTyper: kjoretoyTyperReducer,
  kjoretoyMerker: kjoretoyMerkerReducer,
  miljogebyrer: createAdvancedListReducer("MILJOGEBYRER", miljogebyrerReducer),
  miljogebyrTilBehandling: createAdvancedListReducer(
    "MILJOGEBYRTILBEHANDLING",
    miljogebyrTilBehandlingReducer
  ),
  miljogebyr: miljogebyrReducer,
  miljogebyrBilder: miljogebyrBilderReducer,
  miljogebyrEttersendelser: miljogebyrEttersendelserReducer,
  miljogebyrHistorikk: miljogebyrHistorikkReducer,
  miljogebyrKlager: miljogebyrKlagerReducer,
  miljoRettelser: miljoRettelserReducer,
  miljoMakuleringer: miljoMakuleringerReducer,

  miljokontroller: miljokontrollerReducer,
  miljokontroll: miljokontrollReducer,
  miljokontrollerBilder: miljoKontrollerBilderReducer,
  miljokontrollHistorikk: miljokontrollHistorikkReducer,
  miljokontrollerLightboxBilder: miljoKontrollerLightboxBilderReducer,
  miljokontrollerThumbnailBilder: miljoKontrollerThumbnailBilderReducer,
  miljokontrollKommentarer: miljokontrollKommentarerReducer,
  uregistrertekontroller: createAdvancedListReducer(
    "UREGISTRERTEKONTROLLER",
    uregistrertekontrollerReducer
  ),
  nasjoner: nasjonerReducer,
  vedtakskoder: vedtakskoderReducer,
  vedtaker: vedtakerReducer,
  klagekoder: klageKoderReducer,
  piggdekkontroller: createAdvancedListReducer(
    "PIGGDEKKONTROLLER",
    piggdekkontrollerReducer
  ),
  ileggelseEttersendelser: ileggelseEttersendelserReducer,
  miljoleveringstyper: miljoleveringstyperReducer,
  mottakertyper: mottakertyperReducer,
  p360Sak: p360Reducer,
  ileggelseKommentarer: ileggelseKommentarReducer,
  miljoKommentarer: miljogebyrKommentarReducer,
  klagerapport: klageRapportReducer,
  kalgeRapportSaksbehandler: kalgeRapportSaksbehandlerReducer,
  rapporter: rapportReducers,
  rettekoder: rettekoderReducer,
  uferdigstilteKlagesaker: createAdvancedListReducer(
    "UFERDIGSTILTEKLAGESAKER",
    uferdigstilteKlagesakerReducer
  ),
  submittedForms: submittedFormReducers,
});

export default rootReducer;
