import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Dropdown } from "react-bootstrap";
import { filter, first, isEmpty, map, includes } from "lodash";
import TextFieldGroupClearIcon from "./TextFieldGroupClearIcon";

import "./DropdownListSearchable.css";

class DropdownListSearchable extends Component {
  static propTypes = {
    title: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    disabled: PropTypes.bool,
    id: PropTypes.string,
    items: PropTypes.array.isRequired,
    handleChange: PropTypes.func.isRequired,
    cleanUp: PropTypes.func,
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string.isRequired,
    clearDropdownFilters: PropTypes.func,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    showBeskrivelse: PropTypes.bool,
    validationError: PropTypes.string,
  };

  static defaultProps = {
    id: "",
    showBeskrivelse: true,
  };

  state = {
    items: [],
    searchText: "",
    toggle: false,
  };

  componentDidMount = () => {
    const { name } = this.props;
    const { searchText } = this.state;
    let textFieldGropuClearIcon = document.getElementById(
      `${name}TextFieldGroupClearIcon`
    );
    if (textFieldGropuClearIcon) {
      textFieldGropuClearIcon.addEventListener(
        "keydown",
        this.eventListnerTextFieldGroupClearIcon
      );
    }
    this.setState({ items: this.searchedItems(searchText) });
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { id, name } = this.props;
    const { items, searchText, toggle } = this.state;

    // props.items only fetched once therefore it's enough to check length
    if (prevProps.items.length !== this.props.items.length) {
      this.setState({ items: this.props.items });
    }

    if (prevState.searchText !== searchText) {
      this.setState({ items: this.searchedItems(searchText) });
    }

    if (JSON.stringify(prevState.items) !== JSON.stringify(items)) {
      for (let index = 0; index < items.length; index++) {
        const itemId = items[index].id;
        const element = document.getElementById(`${id}-${itemId}`);
        if (element) {
          element.removeEventListener("keydown", this.eventListenerListItem);
          element.addEventListener("keydown", this.eventListenerListItem);
        }
      }
    }

    if (prevState.toggle === false && toggle === true) {
      var input = document.getElementById(`${name}TextFieldGroupClearIcon`);
      if (input) {
        input.focus();
        input.select();
      }
    }
  };

  getItemId = (listId) => {
    const splittedListId = listId.split("-");
    if (splittedListId.length === 2) {
      return splittedListId[1];
    }
    // This is for negative numbers
    else if (splittedListId.length === 3) {
      return `-${splittedListId[2]}`;
    }
    return null;
  };

  eventListenerListItem = (event) => {
    const { name } = this.props;
    if (event.key === "Backspace") {
      var input = document.getElementById(`${name}TextFieldGroupClearIcon`);
      if (input) {
        input.focus();
        input.select();
      }
    } else if (event.key === " " || event.key === "Enter") {
      this.handleChange(name, this.getItemId(event.target.id));
    }
  };

  eventListnerTextFieldGroupClearIcon = (event) => {
    const { id } = this.props;
    const { items } = this.state;

    let elementItem;

    switch (event.key) {
      case "Tab":
        this.setState({ toggle: false });
        break;
      case "ArrowUp":
        elementItem = items.length
          ? document.getElementById(`${id}-${items[items.length - 1].id}`)
          : null;
        break;
      case "ArrowDown":
        elementItem = items.length
          ? document.getElementById(`${id}-${items[0].id}`)
          : null;
        break;
      default:
        break;
    }
    if (elementItem) {
      elementItem.focus();
    }
  };

  onChangeSearchText = (e) => {
    this.setState({ searchText: e.target.value });
  };

  searchedItems = (searchText) => {
    if (searchText.length > 0) {
      return filter(this.props.items, (item) => {
        if (
          includes(item.navn.toLowerCase(), searchText.toLowerCase()) ||
          (item.beskrivelse &&
            includes(item.beskrivelse.toLowerCase(), searchText.toLowerCase()))
        ) {
          return item;
        }
      });
    }
    return this.props.items;
  };

  onToggle = (isOpen) => {
    this.setState({ toggle: isOpen });
  };

  clearSearchbox = () => {
    this.setState({ searchText: "" });
  };

  handleChange = (name, value) => {
    const { toggle } = this.state;
    let fakeEvent = {
      target: {
        name,
        value,
      },
    };
    this.props.handleChange(fakeEvent);
    this.onToggle(!toggle);
  };

  selectedItemNavn = () => {
    const { title, selected, items } = this.props;
    if (selected) {
      let selectedItem = first(
        filter(items, (i) => {
          if (!i.id || !selected) {
            return i.id === selected;
          }
          return i.id.toString() === selected.toString();
        })
      );
      return !isEmpty(selectedItem) && selectedItem.navn;
    }
    return title;
  };

  getDropdownListSearchableClassName = () => {
    const { selected } = this.props;
    const { toggle } = this.state;

    if (toggle && !selected) {
      return "toggleButtn dropdown-list-searchable-toggle-placeholder-open";
    } else if (!toggle && !selected) {
      return "toggleButtn dropdown-list-searchable-toggle-placeholder";
    }
    return "toggleButtn";
  };

  render() {
    const {
      id,
      placeholder,
      selected,
      label,
      name,
      validationError,
      disabled,
    } = this.props;
    const { items, searchText, toggle } = this.state;

    const title = this.selectedItemNavn();
    if (disabled) return <div className="disabled">{title}</div>;
    return (
      <div
        className={classnames("form-group", { "has-error": validationError })}
      >
        {label && (
          <label htmlFor="dropdown" className="label-control">
            {label}
          </label>
        )}
        <Dropdown
          className="multi-filter-dropdown"
          id={`${id}Dropdown`}
          name={name}
          show={toggle}
          onToggle={this.onToggle}
          disabled={disabled}
        >
          <Dropdown.Toggle
            className={this.getDropdownListSearchableClassName()}
          >
            {title}
          </Dropdown.Toggle>
          <Dropdown.Menu
            className="multi-filter-dropdown-list"
            id={`${id}DropdownMenu`}
          >
            <TextFieldGroupClearIcon
              id={`${name}TextFieldGroupClearIcon`}
              onSearch={this.onChangeSearchText}
              onClear={this.clearSearchbox}
              value={searchText}
              placeHolder={placeholder}
              searchIcon={true}
            />
            {map(items, (item, index) => {
              const isSelected =
                selected &&
                item.id &&
                (selected === item.id ||
                  selected.toString() === item.id.toString());
              return (
                <li
                  key={`${id}${index}itemList`}
                  id={`${id}-${item.id}`}
                  className={isSelected ? "selectedItemBgColor" : ""}
                  onClick={(e) => this.handleChange(name, item.id)}
                  tabIndex="0"
                >
                  <span className="menuItem">
                    <div className="name">{item.navn}</div>
                    <div className="description">
                      {item.beskrivelse && item.beskrivelse}
                    </div>
                  </span>
                </li>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
        {validationError && (
          <span className="help-block">{validationError}</span>
        )}
      </div>
    );
  }
}

export default DropdownListSearchable;
