import React from "react";
import { connect } from "react-redux";
import Utskrift from "../Utskrift";
import { getMiljogebyrTilBehandling as getMiljogebyrTilBehandlingAction } from "../../../actions/miljogebyrTilBehandlingActions";
import { handleChangeAll as handleChangeAllAction } from "../../../actions/handleChangeAction";
import { getOvertredelseskoderMiljogebyr as getOvertredelseskoderMiljogebyrAction } from "../../../actions/overtredelseskoderMiljogebyrActions";
import { getMiljoleveringstyper as getMiljoleveringstyperAction } from "../../../actions/miljoleveringstyperActions";
import {
  getMiljogebyrTilBehandlingQueryParameters,
  getMiljogebyrTilBehandlingTableRows,
} from "../../../selectors/miljogebyrTilBehandlingSelector";

import { miljogebyr as miljogebyrTitle } from "../../../constants/titles";

export const UtskriftContainer = (props) => (
  <Utskrift
    {...props}
    title="Til behandling"
    documentTitle={miljogebyrTitle.tilBehandling}
  />
);

const mapStateToProps = (state) => {
  return {
    queryParameters: getMiljogebyrTilBehandlingQueryParameters(state),
    miljogebyrTableHeaders: [
      "Ilegg.nr.",
      "Ilegg.dato",
      "Klokkeslett",
      "Tjenestenr.",
      "Overt.kode",
      "Leveringstype",
      "Mottaker",
      "Gatenavn",
      "Gatenr.",
      "Ved",
      "Ved nr.",
      "GNR",
      "BNR",
      "Bilde",
    ],
    miljogebyrTableRows: getMiljogebyrTilBehandlingTableRows(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMiljogebyrer: (params, page, count) =>
      dispatch(getMiljogebyrTilBehandlingAction(params, page, count)),
    handleChangeAll: (payload) =>
      dispatch(
        handleChangeAllAction("MILJOGEBYRER_OVERSIKT_FILTER_FORM", payload)
      ),
    getOvertredelseskoderMiljogebyr: () =>
      dispatch(getOvertredelseskoderMiljogebyrAction()),
    getMiljoleveringstyper: () => dispatch(getMiljoleveringstyperAction()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UtskriftContainer);
