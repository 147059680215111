import { createSelector } from "reselect";

const vedtakskoder = state => state.vedtakskoder;

export const getVedtakskoderList = createSelector(
  vedtakskoder,
  vedtakskoder => {
    return vedtakskoder.items;
  }
);

export const getVedtakskoderVmByIleggelsetyper = createSelector(
  vedtakskoder,
  vedtakskoder => {
    const vedtakskoderByIleggelsetyper = {};
    for (let index = 0; index < vedtakskoder.length; index++) {
      const vedtakskode = vedtakskoder[index];
      for (
        let index2 = 0;
        index2 < vedtakskode.ileggelsetyper.length;
        index2++
      ) {
        const ileggelsetype = vedtakskode.ileggelsetyper[index2];
        if (
          vedtakskode.kode === "AF" &&
          ileggelsetype &&
          ileggelsetype.kode === "P"
        ) {
        }
        const ileggelsetypeKode = ileggelsetype && ileggelsetype.kode;
        if (!vedtakskoderByIleggelsetyper[ileggelsetypeKode]) {
          vedtakskoderByIleggelsetyper[ileggelsetypeKode] = [
            getVedtakskodeVm(vedtakskode)
          ];
        } else {
          vedtakskoderByIleggelsetyper[ileggelsetypeKode] = [
            ...vedtakskoderByIleggelsetyper[ileggelsetypeKode],
            getVedtakskodeVm(vedtakskode)
          ];
        }
      }
    }
    const sortedVedtakskoderByIleggelsetyper = sortForTop5Functionality(
      vedtakskoderByIleggelsetyper
    );
    return sortedVedtakskoderByIleggelsetyper;
  }
);

const getVedtakskodeVm = vedtakskode => {
  return {
    id: vedtakskode.id,
    navn: `${vedtakskode.kode}`,
    beskrivelse: `${vedtakskode.navn}`,
    klageKodeId: vedtakskode.klagekode.id,
    ileggelsetyper: vedtakskode.ileggelsetyper
  };
};

const sortForTop5Functionality = objectWithArrays => {
  let sortedVedtakskoderByIleggelsetyper = { ...objectWithArrays };
  if (sortedVedtakskoderByIleggelsetyper["P"]) {
    sortedVedtakskoderByIleggelsetyper["P"] = getFavoriteSortedVedtakskoder(
      sortedVedtakskoderByIleggelsetyper["P"],
      ["AF", "F8", "F11", "F10", "O1"]
    );
  }
  if (sortedVedtakskoderByIleggelsetyper["G"]) {
    sortedVedtakskoderByIleggelsetyper["G"] = getFavoriteSortedVedtakskoder(
      sortedVedtakskoderByIleggelsetyper["G"],
      ["AF", "F10", "F11", "INFO", "O1"]
    );
  }
  if (sortedVedtakskoderByIleggelsetyper["V"]) {
    sortedVedtakskoderByIleggelsetyper["V"] = getFavoriteSortedVedtakskoder(
      sortedVedtakskoderByIleggelsetyper["V"],
      ["KSA", "F8", "F11", "O1", "INFO"]
    );
  }
  return sortedVedtakskoderByIleggelsetyper;
};

const getFavoriteSortedVedtakskoder = (
  vedtakskoder,
  favoriteVedtakskoderNavnArray
) => {
  let top5Vedtakskoder = [];
  for (
    let index1 = 0;
    index1 < favoriteVedtakskoderNavnArray.length;
    index1++
  ) {
    const vedtakskodeNavn = favoriteVedtakskoderNavnArray[index1];
    for (let index2 = 0; index2 < vedtakskoder.length; index2++) {
      const vedtakskode = vedtakskoder[index2];
      if (vedtakskode && vedtakskode.navn === vedtakskodeNavn) {
        top5Vedtakskoder.push(vedtakskode);
      }
    }
  }
  let filteredVedtakskoder = vedtakskoder.filter(
    vVedtakskode =>
      !favoriteVedtakskoderNavnArray.some(
        top5Navn => top5Navn === vVedtakskode.navn
      )
  );
  return [...top5Vedtakskoder, ...filteredVedtakskoder];
};
