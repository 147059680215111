import React from "react";
import PropTypes from "prop-types";
import { map } from "lodash";
import { Row, Col } from "react-bootstrap";

import TextArea from "../Forms/TextArea";
import { formatDateTimeHavingNorwegianDateTime } from "../../../utils/datetimeUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

function Kommentar(props) {
  return (
    <div>
      <div className="form-group">
        {!props.showKommentarTextarea && (
          <button
            name="showKommentarTextarea"
            className="btn btn-bym-standard btn-lg pull-left"
            onClick={props.handleClick}
          >
            Legg til kommentar
          </button>
        )}
        {props.showKommentarTextarea && (
          <form>
            <Row>
              <Col md={5}>
                <TextArea
                  validationError={props.validationError.kommentarTextarea}
                  label="Kommentar:"
                  onChange={props.handleChange}
                  value={props.kommentarTextarea}
                  name="kommentarTextarea"
                />
              </Col>
            </Row>
            <button
              name="cancelKommentarTextarea"
              className="btn btn-link"
              onClick={props.handleClick}
            >
              Avbryt
            </button>
            <button
              type="submit"
              className="btn btn-bym-standard btn-lg"
              onClick={props.handleSubmit}
            >
              Legg til kommentar
            </button>
          </form>
        )}
      </div>
      <div className="clearfix" />
      <hr />
      {renderKommentarer(props.kommentarer)}
    </div>
  );
}

Kommentar.propTypes = {
  kommentarer: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  showKommentarTextarea: PropTypes.bool,
  kommentarTextarea: PropTypes.string,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  handleClick: PropTypes.func,
  handleSubmit: PropTypes.func,
};
Kommentar.defaultProps = {};

function renderKommentarer(kommentarer) {
  return map(kommentarer, (kommentar, index) => {
    return (
      <div key={`kommentar${index}`}>
        <div>
          <div>
            <FontAwesomeIcon icon={faUser} />
            &nbsp;
            <strong>{kommentar.opprettetAv}</strong>
            &nbsp;&nbsp;
            <span>
              {formatDateTimeHavingNorwegianDateTime(kommentar.opprettetDato)}
            </span>
          </div>
          <div>
            <p>{kommentar.tekst}</p>
          </div>
        </div>
        <hr />
      </div>
    );
  });
}

export default Kommentar;
