import * as miljogebyrerActions from "../actions/miljogebyrerActions";

const INITIAL_STATE = {};

export default function miljogebyrBilderReducer(
  state = { ...INITIAL_STATE },
  action
) {
  switch (action.type) {
    case miljogebyrerActions.GET_MILJOGEBYR_BILDER_SUCCESS:
      return { ...state, [action.payload.miljogebyrId]: action.payload.bilder };
    default:
      return state;
  }
}
