import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import map from "lodash/map";
import size from "lodash/size";
import "./RadioButtonGroup.css";

export default class RadioButtonGroup extends Component {
  static defaultProps = {
    items: [],
  };
  static propTypes = {
    items: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    selectedValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ]),
    validationError: PropTypes.string,
  };
  render() {
    const {
      label,
      id,
      name,
      items,
      onChange,
      selectedValue,
      validationError,
    } = this.props;
    if (size(items) === 0) return <div />;
    return (
      <div
        className={classnames("form-group radiobuttongroup", {
          "has-error": validationError,
        })}
        id={id}
      >
        <label className="control-label" htmlFor={name}>
          {label}
        </label>

        {map(items, (i, index) => {
          const id = `${i.navn}-input-container`;
          return (
            <div key={index} id={id}>
              <input
                className={`${name}-input`}
                type="radio"
                name={name}
                value={i.navn}
                onChange={onChange}
                checked={i.value === selectedValue ? true : false}
              />
              <span>{i.navn}</span>
            </div>
          );
        })}
        {validationError && validationError.length > 0 && (
          <span className="help-block">{validationError}</span>
        )}
      </div>
    );
  }
}
