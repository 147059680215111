import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import map from "lodash/map";
import filter from "lodash/filter";

import DropDownListWithCheckbox from "../Common/Forms/DropDownListMultiCheckboxOptions";
import DropDown from "../Common/Forms/DropDown";
import TextInput from "../Common/Forms/TextInput";
import LabelControl from "../Common/Forms/LabelControl";
import Datepicker from "../Common/Forms/Datepicker";

export default class VedtakskoderForm extends React.Component {
  static propTypes = {
    validationError: PropTypes.object,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    kode: PropTypes.string,
    navn: PropTypes.string,
    klageKodeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    handleChange: PropTypes.func,
    onChangeOneIleggelsetype: PropTypes.func,
    actionType: PropTypes.string,
    klagekoder: PropTypes.array,
    ileggelsestyper: PropTypes.array, // all ileggelsestyper
    ileggelsetype: PropTypes.array, // selected ileggelsestyper
  };
  renderKlageKoder = () => {
    return map(this.props.klagekoder, (i) => {
      return { id: i.id, navn: i.id, mehold: i.navn };
    });
  };
  renderMehold = (klagekoder, klageKodeId) => {
    const klagekode = filter(klagekoder, (i) => i.id === klageKodeId);
    return klagekode && klagekode.length > 0 ? klagekode[0].mehold : "";
  };
  render() {
    const {
      validationError,
      id,
      kode,
      navn,
      deaktivertDato,
      klageKodeId,
      ileggelsestyper,
      ileggelsestype,
      handleChange,
      actionType,
    } = this.props;

    let klagekoder = this.renderKlageKoder();
    let medhold = this.renderMehold(klagekoder, klageKodeId);

    return (
      <form>
        <Row>
          <Col md={6}>
            <TextInput
              label="ID"
              onChange={handleChange}
              value={id}
              name="id"
              type="number"
              disabled={actionType === "update" ? true : false}
              validationError={validationError.id}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <TextInput
              label="Vedtakskode"
              onChange={handleChange}
              value={kode}
              name="kode"
              // disabled={actionType === "update" ? true : false}
              validationError={validationError.kode}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <TextInput
              label="Tekst"
              onChange={handleChange}
              value={navn}
              name="navn"
              validationError={validationError.navn}
            />
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Datepicker
              label="Deaktivert dato"
              id="deaktivertDato"
              name="deaktivertDato"
              onChange={handleChange}
              locale="nb"
              timeFormat={false}
              value={deaktivertDato}
              placeHolder="Velg"
              validationError={validationError.deaktivertDato}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <DropDown
              label="Klagekode"
              items={klagekoder}
              name="klageKodeId"
              textMapping="id"
              selected={klageKodeId}
              title="Velg"
              validationError={validationError.klageKodeId}
              onChange={handleChange}
            />
          </Col>
          <Col md={6} className="medholdLabel">
            <LabelControl label="Medhold" text={medhold} />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <DropDownListWithCheckbox
              label="Ileggelsestype"
              items={ileggelsestyper}
              name="ileggelsestype"
              selected={ileggelsestype}
              title={
                ileggelsestype && ileggelsestype.length > 0
                  ? `${ileggelsestype.length} valg`
                  : "Velg"
              }
              validationError={validationError.ileggelsestype}
              onChange={handleChange}
              selectedType="id"
            />
          </Col>
        </Row>
      </form>
    );
  }
}
