import React from "react";
import Excel from "../../Common/Excel";

import { formatDateTimeHavingNorwegianDateTime } from "../../../utils/datetimeUtils";

const AntallIleggleserPerIleggelsetypeExcel = ({
  antallIleggelserPerIleggelsetypeSubmittedForm,
  antallIleggelserPerIleggelsetypeRapport,
}) => {
  return (
    <Excel
      element={(onClick) => (
        <button className="btn btn-bym-medium btn-bym-action" onClick={onClick}>
          Lag rapport
        </button>
      )}
      filename={"Antall gebyr per type.xlsx"}
      sheets={[
        {
          name: "Antall gebyr per type",
          data: antallIleggelserPerIleggelsetypeRapport.map((aipir) => ({
            Ileggelsetype: `${aipir.navn} (${aipir.kode})`,
            Antall: aipir.antall,
          })),
        },
        {
          name: "Søkdata",
          data: [
            {
              "Timestamp for søk": formatDateTimeHavingNorwegianDateTime(
                antallIleggelserPerIleggelsetypeSubmittedForm.submitTimestamp
              ),
              "Dato fra": formatDateTimeHavingNorwegianDateTime(
                antallIleggelserPerIleggelsetypeSubmittedForm.fraDato
              ),
              "Dato til": formatDateTimeHavingNorwegianDateTime(
                antallIleggelserPerIleggelsetypeSubmittedForm.tilDato
              ),
            },
          ],
        },
      ]}
    />
  );
};

export default AntallIleggleserPerIleggelsetypeExcel;
