import * as api from "../constants/api";
import * as axiosUtils from "../utils/axiosUtils";

export const actionType = "AKTORER";
const metaData = { entitet: "Aktor", notify: true };

export function getAktorer() {
  return axiosUtils.GetAll(api.GET_AKTORER_URL, actionType);
}

export function postAktor(payload) {
  return axiosUtils.Post(api.POST_AKTOR_URL, payload, actionType, metaData);
}

export function putAktor(payload) {
  return axiosUtils.Put(
    api.PUT_AKTOR_URL(payload.id),
    payload,
    actionType,
    metaData
  );
}
