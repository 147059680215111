import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { map, isEmpty } from "lodash";

import Table from "../../Common/Tables/BaseTable";
import VedtakskoderModal from "./UpdateModals/VedtakskoderModal";

import { datetimeIsDeactivated } from "../../../utils/datetimeUtils";
import { getVedtakskoder } from "../../../actions/vedtakskoderAction";
import * as title from "../../../constants/titles";

class VedtakskoderList extends Component {
  static propTypes = {
    authorizedToCreateAndEdit: PropTypes.bool.isRequired,
    vedtakskoder: PropTypes.array,
    ileggelsestyper: PropTypes.array,
    modalToRender: PropTypes.string,
  };

  componentDidMount() {
    document.title = title.grunndataVedtakskoder;
    this.props.getVedtakskoder();
  }

  selectedIleggelsetyper = (vedtakskode) => {
    if (vedtakskode.ileggelsetyper) {
      const arr = map(vedtakskode.ileggelsetyper, (i) => {
        return i.kode;
      });
      if (!isEmpty(arr)) {
        return arr.filter(Boolean).join(", ");
      }
    }
  };

  selectedIleggelsetypeIds = (vedtakskode) => {
    if (vedtakskode.ileggelsetyper) {
      return map(vedtakskode.ileggelsetyper, (i) => i.id);
    }
    return [];
  };

  renderRow = () => {
    const { vedtakskoder, vedtakskoderAktivStatus } = this.props;
    return map(
      vedtakskoder.filter(
        (vk) =>
          !datetimeIsDeactivated(vk.deaktivertDato) ===
            vedtakskoderAktivStatus || vedtakskoderAktivStatus === null
      ),
      (vedtakskode) => (
        <tr key={`vedtakskoderTableBodyTr${vedtakskode.id}`}>
          <td>{vedtakskode.id}</td>
          <td>{vedtakskode.kode}</td>
          <td>{vedtakskode.navn}</td>
          <td>{vedtakskode.klagekode ? vedtakskode.klagekode.navn : "-"}</td>
          <td>{vedtakskode.klagekode ? vedtakskode.klagekode.id : "-"}</td>
          <td>{this.selectedIleggelsetyper(vedtakskode)}</td>
          <td>{vedtakskode.tilkemner ? vedtakskode.tilkemner : ""}</td>
          <td>
            {datetimeIsDeactivated(vedtakskode.deaktivertDato)
              ? "Deaktivert"
              : "Aktiv"}
          </td>
          <td>
            {this.props.authorizedToCreateAndEdit && (
              <VedtakskoderModal
                modalToRender={this.props.modalToRender}
                vedtakskode={vedtakskode}
                selectedIleggelsestyper={this.selectedIleggelsetypeIds(
                  vedtakskode
                )}
              />
            )}
          </td>
        </tr>
      )
    );
  };

  render() {
    const headerRow = [
      "ID",
      "Kode",
      "Tekst",
      "Mehold",
      "Klagekode",
      "Ileggelsestyper",
      "Til INE",
      "Status",
      "",
    ];

    return (
      <div className="table-wrapper-horizontal-scroll">
        <Table
          id="vedtakskoder-liste"
          headerRow={headerRow}
          defaultStyle
          striped
        >
          <tbody>{this.renderRow()}</tbody>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    vedtakskoderAktivStatus:
      state.forms.grunndataFilterForm.vedtakskoderAktivStatus,
    vedtakskoder: state.vedtakskoder,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getVedtakskoder }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(VedtakskoderList);
