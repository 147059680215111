import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Table from "../../Tables/Table";

import { getMiljoRettelser as getMiljoRettelserAction } from "../../../../actions/miljoRettelserActions";

import { getMiljoRettelser } from "../../../../selectors/miljogebyrTilbakameldingerFraBybetjenter";

import { toNorwegianDatetime } from "../../../../utils/datetimeUtils";

class TilbakemeldingerFraBybetjenterContainer extends React.Component {
  static propTypes = {
    miljogebyrId: PropTypes.string,
    miljoRettelser: PropTypes.array,
    bybetjenter: PropTypes.array,
    getMiljoRettelser: PropTypes.func.isRequired,
  };

  componentDidMount = () => {
    this.props.miljogebyrId &&
      this.props.getMiljoRettelser(this.props.miljogebyrId);
  };

  renderRow = (miljoRettelser) => {
    return (
      <tr>
        <td>
          {miljoRettelser.rettelseDato &&
            toNorwegianDatetime(miljoRettelser.rettelseDato).format(
              "DD.MM.YYYY HH:mm"
            )}
        </td>
        <td>{miljoRettelser.tjenestenummer}</td>
        <td>{miljoRettelser.rettelse}</td>
        <td>{miljoRettelser.kommentar}</td>
      </tr>
    );
  };

  render() {
    return (
      <section>
        <div className="detalj-ileggelse-header-wrapper">
          <h2>Tilbakemelding</h2>
        </div>
        <div className="detalj-ileggelse-table-wrapper">
          <Table renderRow={this.renderRow} items={this.props.miljoRettelser}>
            <th>Dato</th>
            <th>Tjenestenummer</th>
            <th>Rettelse</th>
            <th>Kommentar</th>
          </Table>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    miljoRettelser: getMiljoRettelser(state),
    bybetjenter: state.bybetjenter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMiljoRettelser: (miljogebyrId) =>
      dispatch(getMiljoRettelserAction(miljogebyrId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TilbakemeldingerFraBybetjenterContainer);
