import React from "react";
import { PropTypes } from "prop-types";
import { map, isEmpty } from "lodash";

import Table from "../../Common/Tables/BaseTable";
import Excel from "../../Common/Excel";
import InformationButton from "../../Common/InformationButton";

import { numberWithSpace } from "../../../utils/stringUtils";

import * as title from "../../../constants/titles";
import { searchMeldingError } from "../../../constants/ErrorMessages";

import excelLogo from "../../../images/excel-icon-32.png";

class KlagerapportLedelse extends React.Component {
  static propTypes = {
    klagerapport: PropTypes.object,
    bydelerArr: PropTypes.array,
    overtredelseKodeArr: PropTypes.array,
    selectedIleggelsestyper: PropTypes.array,
  };

  state = {
    innerWidth: window.innerWidth,
    showInformationResultatFor: false,
    showInformationBydeler: false,
    showInformationOvertredelsekoder: false,
  };

  componentDidMount = () => {
    document.title = title.rapporter.klager;

    window.addEventListener("resize", this.resizeEventListener);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.resizeEventListener);
  };

  resizeEventListener = () => {
    this.setState(() => ({ innerWidth: window.innerWidth }));
  };

  baseHeader = () => {
    return ["Total antall", "Klagesaker", "Klageprosent", "Frafallsprosent"];
  };

  generateRapportData = (sheetName, items) => {
    let data = items.map((item) => {
      return {
        Navn: item.navn,
        "Total antall": item.antallGebyrer,
        Klageprosent: item.klageSakProsent,
        Frafallsprosent: item.frafallProsent,
      };
    });
    
    return [
      {
        name: sheetName,
        data,
      },
    ];
  };

  handleInformationResultatForClick = () => {
    this.setState({
      showInformationResultatFor: !this.state.showInformationResultatFor,
    });
  };

  handleInformationBydelerClick = () => {
    this.setState({
      showInformationBydeler: !this.state.showInformationBydeler,
    });
  };

  handleInformationOvertredelsekoderClick = () => {
    this.setState({
      showInformationOvertredelsekoder:
        !this.state.showInformationOvertredelsekoder,
    });
  };

  getBydelExcelButton = () => (
    <Excel
      filename="Bydeler-rapport.xlsx"
      sheets={this.generateRapportData("Bydeler", this.props.bydelerArr)}
      element={(onClick) => (
        <img
          src={excelLogo}
          alt="logo"
          title="Eksportere data til Excel"
          style={{ cursor: "pointer" }}
          className="pull-right"
          // onClick={onClick}
        />
      )}
    />
  );

  getOvertredelsekoderExcelButton = () => (
    <Excel
      filename="Overtredelsekoder-rapport.xlsx"
      sheets={this.generateRapportData(
        "Overtredelsekoder",
        this.props.overtredelseKodeArr
      )}
      element={(onClick) => (
        <img
          src={excelLogo}
          alt="logo"
          title="Eksportere data til Excel"
          style={{ cursor: "pointer" }}
          className="pull-right"
          onClick={onClick}
        />
      )}
    />
  );

  getBydelerFakeLabelStyle = () => {
    const { innerWidth, showInformationBydeler } = this.state;

    if (innerWidth > 767 && showInformationBydeler) {
      return { fontSize: 16, fontWeight: "bold" };
    } else if (innerWidth < 768 && showInformationBydeler) {
      return {
        fontSize: 16,
        fontWeight: "bold",
        marginRight: 20,
        marginLeft: 20,
      };
    } else if (innerWidth > 767 && !showInformationBydeler) {
      return {
        fontSize: 16,
        fontWeight: "bold",
        position: "absolute",
        bottom: 0,
      };
    } else if (innerWidth < 768 && !showInformationBydeler) {
      return {
        fontSize: 16,
        fontWeight: "bold",
        position: "absolute",
        marginRight: 20,
        marginLeft: 20,
        bottom: 0,
      };
    }
  };

  getOvertredelsekoderFakeLabelStyle = () => {
    const { innerWidth, showInformationOvertredelsekoder } = this.state;

    if (innerWidth > 767 && showInformationOvertredelsekoder) {
      return { fontSize: 16, fontWeight: "bold" };
    } else if (innerWidth < 768 && showInformationOvertredelsekoder) {
      return {
        fontSize: 16,
        fontWeight: "bold",
        marginRight: 20,
        marginLeft: 20,
      };
    } else if (innerWidth > 767 && !showInformationOvertredelsekoder) {
      return {
        fontSize: 16,
        fontWeight: "bold",
        position: "absolute",
        bottom: 0,
      };
    } else if (innerWidth < 768 && !showInformationOvertredelsekoder) {
      return {
        fontSize: 16,
        fontWeight: "bold",
        position: "absolute",
        marginRight: 20,
        marginLeft: 20,
        bottom: 0,
      };
    }
  };

  render() {
    const {
      klagerapport,
      bydelerArr,
      overtredelseKodeArr,
      selectedIleggelsestyper,
    } = this.props;
    const { innerWidth } = this.state;

    const header = this.baseHeader();

    const overtredelseHeader = ["Kode", ...header];

    const stedsHeader = ["Navn", ...header];

    if (isEmpty(klagerapport)) return null;

    if (klagerapport.antallGebyrer === 0)
      return <div>{searchMeldingError}</div>;

    return (
      <div>
        <div
          className="fake-label"
          style={
            innerWidth > 767
              ? { fontSize: 16, fontWeight: "bold", marginTop: 10 }
              : {
                  fontSize: 16,
                  fontWeight: "bold",
                  marginTop: 10,
                  marginRight: 20,
                  marginLeft: 20,
                }
          }
        >
          Resultat for - {selectedIleggelsestyper.join(", ")}{" "}
          <InformationButton onClick={this.handleInformationResultatForClick} />
        </div>
        {this.state.showInformationResultatFor && (
          <div
            style={
              innerWidth > 767
                ? { marginTop: 10, marginBottom: 10 }
                : {
                    marginTop: 10,
                    marginRight: 20,
                    marginBottom: 10,
                    marginLeft: 20,
                  }
            }
          >
            <i>
              <b>Total antall:</b> Antall ileggelser som ikke er makulerte og er
              i henhold til filtreringen
            </i>
            <br />
            <i>
              <b>Klagesaker:</b> Antall ileggelser med ett eller flere vedtak
              som ikke er makulerte
            </i>
            <br />
            <i>
              <b>Klageprosent:</b> Basert på “Total antall” og “Klagesaker”
            </i>
            <br />
            <i>
              <b>Frafallsprosent:</b> Basert på “Total antall” og ileggelser som
              ikke er makulerte og som har ett eller flere vedtak med klagekode
              3 eller 13 (medhold)
            </i>
          </div>
        )}
        <div
          className="table-wrapper-horizontal-scroll"
          style={{ maxWidth: 750 }}
        >
          <Table headerRow={header} striped>
            <tbody>
              {klagerapport && (
                <tr key="1">
                  <td>{numberWithSpace(klagerapport.antallGebyrer)}</td>
                  <td>{numberWithSpace(klagerapport.antallKlageSaker)}</td>
                  <td>{klagerapport.klageSakProsent} %</td>
                  <td>{klagerapport.frafallProsent} %</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
        {!isEmpty(bydelerArr) && (
          <div style={{ maxWidth: 750 }}>
            <div
              style={
                this.state.showInformationBydeler
                  ? { marginTop: 10, marginBottom: 10 }
                  : { position: "relative" }
              }
            >
              {!this.state.showInformationBydeler && (
                <>
                  {this.getBydelExcelButton()}
                  <div className="clearfix" />
                </>
              )}
              <div
                className="fake-label"
                style={this.getBydelerFakeLabelStyle()}
              >
                Bydeler{" "}
                <InformationButton
                  onClick={this.handleInformationBydelerClick}
                />
              </div>
            </div>
            {this.state.showInformationBydeler && (
              <div
                style={{
                  position: "relative",
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <div style={{ position: "absolute", right: 0, bottom: 0 }}>
                  {this.getBydelExcelButton()}
                </div>
                <div
                  style={
                    innerWidth > 767
                      ? { paddingRight: 50 }
                      : { marginRight: 20, marginLeft: 20, paddingRight: 50 }
                  }
                >
                  <i>Alle parametere nedenfor er gruppert på sted</i>
                  <br />
                  <i>
                    <b>Navn:</b> Navn på bydel
                  </i>
                  <br />
                  <i>
                    <b>Total antall:</b> Antall ileggelser som ikke er makulerte
                    og er i henhold til filtreringen
                  </i>
                  <br />
                  <i>
                    <b>Klagesaker:</b> Antall ileggelser med ett eller flere
                    vedtak som ikke er makulerte
                  </i>
                  <br />
                  <i>
                    <b>Klageprosent:</b> Basert på “Total antall” og
                    “Klagesaker”
                  </i>
                  <br />
                  <i>
                    <b>Frafallsprosent:</b> Basert på “Total antall” og
                    Ileggelser som ikke er makulerte og som har ett eller flere
                    vedtak med klagekode 3 eller 13 (medhold)
                  </i>
                </div>
              </div>
            )}
            <div className="table-wrapper-horizontal-scroll">
              <Table headerRow={stedsHeader} striped>
                <tbody>
                  {bydelerArr &&
                    map(bydelerArr, (item, index) => (
                      <tr key={index}>
                        <td>{item.navn}</td>
                        <td>{numberWithSpace(item.antallGebyrer)}</td>
                        <td>{numberWithSpace(item.antallKlageSaker)}</td>
                        <td>{item.klageSakProsent} %</td>
                        <td>{String(item.frafallProsent)} %</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </div>
        )}
        {!isEmpty(overtredelseKodeArr) && (
          <div style={{ maxWidth: 750 }}>
            <div
              style={
                this.state.showInformationOvertredelsekoder
                  ? { marginTop: 10, marginBottom: 10 }
                  : { position: "relative" }
              }
            >
              {!this.state.showInformationOvertredelsekoder && (
                <>
                  {this.getOvertredelsekoderExcelButton()}
                  <div className="clearfix" />
                </>
              )}
              <div
                className="fake-label"
                style={this.getOvertredelsekoderFakeLabelStyle()}
              >
                Overtredelsekoder{" "}
                <InformationButton
                  onClick={this.handleInformationOvertredelsekoderClick}
                />
              </div>
            </div>
            {this.state.showInformationOvertredelsekoder && (
              <div
                style={{
                  position: "relative",
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <div style={{ position: "absolute", right: 0, bottom: 0 }}>
                  {this.getOvertredelsekoderExcelButton()}
                </div>
                <div
                  style={
                    innerWidth > 767
                      ? { paddingRight: 50 }
                      : { marginRight: 20, marginLeft: 20, paddingRight: 50 }
                  }
                >
                  <i>
                    Alle parametere nedenfor er gruppert på overtredelsekode
                  </i>
                  <br />
                  <i>
                    <b>Kode:</b> Beskrivelse av overtredelsekode
                  </i>
                  <br />
                  <i>
                    <b>Total antall:</b> Antall ileggelser som ikke er makulerte
                    og er i henhold til filtreringen
                  </i>
                  <br />
                  <i>
                    <b>Klagesaker:</b> Antall ileggelser med ett eller flere
                    vedtak som ikke er makulerte
                  </i>
                  <br />
                  <i>
                    <b>Klageprosent:</b> Basert på “Total antall” og
                    “Klagesaker”
                  </i>
                  <br />
                  <i>
                    <b>Frafallsprosent:</b> Basert på “Total antall” og
                    Ileggelser som ikke er makulerte og som har ett eller flere
                    vedtak med klagekode 3 eller 13 (medhold)
                  </i>
                </div>
              </div>
            )}
            <div className="table-wrapper-horizontal-scroll-white-space-normal">
              <Table headerRow={overtredelseHeader} striped>
                <tbody>
                  {overtredelseKodeArr &&
                    map(overtredelseKodeArr, (item, index) => (
                      <tr key={index}>
                        <td>{item.navn}</td>
                        <td>{numberWithSpace(item.antallGebyrer)}</td>
                        <td>{numberWithSpace(item.antallKlageSaker)}</td>
                        <td>{item.klageSakProsent} %</td>
                        <td>{String(item.frafallProsent)} %</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default KlagerapportLedelse;
