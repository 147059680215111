import React, { useState } from "react";
import moment from "moment";

import DatetimePicker from "../../Common/Forms/DatetimePickerEnhanced";
import TextInput from "../../Common/Forms/TextInput";
import FormButtonsForCloseAndSubmit from "../../Common/Forms/FormButtonsForCloseAndSubmit";
import * as inputValidation from "../../../utils/inputValidationUtils";

const handleChangeDateInputs = (e, handleChange) => {
  const name = e.target.name;
  let value = e.target.value;

  if (
    (name === "makuleringFraDato" || name === "ileggelseFraDato") &&
    value &&
    moment.isMoment(value)
  ) {
    handleChange(name, value.startOf("day"));
  } else if (
    (name === "makuleringTilDato" || name === "ileggelseTilDato") &&
    value &&
    moment.isMoment(value)
  ) {
    handleChange(name, value.endOf("day"));
  } else {
    handleChange(name, value);
  }
};

const handleMakuleringDatoValidationChange = (
  variableName,
  errorText,
  handleChange
) => {
  if (variableName === "fraDatoValidationErrorText") {
    handleChange("makuleringFraDatoValidationErrorText", errorText);
  } else if (variableName === "tilDatoValidationErrorText") {
    handleChange("makuleringTilDatoValidationErrorText", errorText);
  }
};

const handleIleggelseDatoValidationChange = (
  variableName,
  errorText,
  handleChange
) => {
  if (variableName === "fraDatoValidationErrorText") {
    handleChange("ileggelseFraDatoValidationErrorText", errorText);
  } else if (variableName === "tilDatoValidationErrorText") {
    handleChange("ileggelseTilDatoValidationErrorText", errorText);
  }
};

const valueExistsValidation = (
  makuleringFraDato,
  makuleringTilDato,
  ileggelseFraDato,
  ileggelseTilDato,
  tjenestenummer,
  handleChange
) => {
  if (
    makuleringFraDato ||
    makuleringTilDato ||
    ileggelseFraDato ||
    ileggelseTilDato ||
    tjenestenummer
  ) {
    handleChange("filterValidationErrorText", null);
    return true;
  } else {
    handleChange(
      "filterValidationErrorText",
      "Dato eller tjenestenummer må være utfylt"
    );
    return false;
  }
};

const fraDateTimeTilDateTimeValidations = (
  makuleringFraDato,
  makuleringTilDato,
  ileggelseFraDato,
  ileggelseTilDato,
  handleChange
) => {
  let makuleringFraDateTimeTilDateTimeValidation =
    inputValidation.fraDateTimeTilDateTimeValidation(
      {
        fraDato: makuleringFraDato,
        tilDato: makuleringTilDato,
      },
      (variableName, errorText) =>
        handleMakuleringDatoValidationChange(
          variableName,
          errorText,
          handleChange
        )
    );

  let ileggelseFraDateTimeTilDateTimeValidation =
    inputValidation.fraDateTimeTilDateTimeValidation(
      {
        fraDato: ileggelseFraDato,
        tilDato: ileggelseTilDato,
      },
      (variableName, errorText) =>
        handleIleggelseDatoValidationChange(
          variableName,
          errorText,
          handleChange
        )
    );

  return (
    makuleringFraDateTimeTilDateTimeValidation &&
    ileggelseFraDateTimeTilDateTimeValidation
  );
};

const tjenestenummerValidation = (tjenestenummer, handleChange) => {
  if (tjenestenummer === "") {
    handleChange("tjenestenummerValidationErrorText", null);
    return true;
  }
  const validationErrorText =
    inputValidation.mustBeAPositiveInteger(tjenestenummer);
  if (validationErrorText) {
    handleChange("tjenestenummerValidationErrorText", validationErrorText);
    return false;
  }
  handleChange("tjenestenummerValidationErrorText", null);
  return true;
};

const isValid = (makuleringerFilterForm, handleChange) => {
  return (
    valueExistsValidation(
      makuleringerFilterForm.makuleringFraDato,
      makuleringerFilterForm.makuleringTilDato,
      makuleringerFilterForm.ileggelseFraDato,
      makuleringerFilterForm.ileggelseTilDato,
      makuleringerFilterForm.tjenestenummer,
      handleChange
    ) &&
    fraDateTimeTilDateTimeValidations(
      makuleringerFilterForm.makuleringFraDato,
      makuleringerFilterForm.makuleringTilDato,
      makuleringerFilterForm.ileggelseFraDato,
      makuleringerFilterForm.ileggelseTilDato,
      handleChange
    ) &&
    tjenestenummerValidation(
      makuleringerFilterForm.tjenestenummer,
      handleChange
    )
  );
};

const handleSubmit = (
  e,
  makuleringerFilterForm,
  handleChange,
  handleChangeAll,
  getRapportMakuleringer,
  setShowSokSpinner
) => {
  e.preventDefault();
  if (isValid(makuleringerFilterForm, handleChange)) {
    setShowSokSpinner(true);
    getRapportMakuleringer({
      makuleringFraDato: makuleringerFilterForm.makuleringFraDato,
      makuleringTilDato: makuleringerFilterForm.makuleringTilDato,
      ileggelseFraDato: makuleringerFilterForm.ileggelseFraDato,
      ileggelseTilDato: makuleringerFilterForm.ileggelseTilDato,
      tjenestenummer: makuleringerFilterForm.tjenestenummer,
    })
      .then(() => {
        setShowSokSpinner(false);
        handleChangeAll("RAPPORT_MAKULERINGER_SUBMITTED_FORM", {
          makuleringFraDato: makuleringerFilterForm.makuleringFraDato,
          makuleringTilDato: makuleringerFilterForm.makuleringTilDato,
          ileggelseFraDato: makuleringerFilterForm.ileggelseFraDato,
          ileggelseTilDato: makuleringerFilterForm.ileggelseTilDato,
          tjenestenummer: makuleringerFilterForm.tjenestenummer,
          submitTimestamp: moment(),
        });
      })
      .catch(() => setShowSokSpinner(false));
  }
};

const MakuleringFilterForm = ({
  makuleringerFilterForm,
  handleChange,
  handleChangeAll,
  handleChangeToInitState,
  getRapportMakuleringer,
}) => {
  const [showSokSpinner, setShowSokSpinner] = useState(false);

  return (
    <form
      className="rapportSearch SearchHistorikkForm"
      onSubmit={(e) =>
        handleSubmit(
          e,
          makuleringerFilterForm,
          handleChange,
          handleChangeAll,
          getRapportMakuleringer,
          setShowSokSpinner
        )
      }
    >
      <div className="searchHistorikkOverskrift">Søk etter makuleringer</div>
      <div className="filter-flexbox-container ventilstilling-filter-flexbox">
        <DatetimePicker
          id="makuleringFraDato"
          name="makuleringFraDato"
          locale="nb"
          label="Makulert dato fra"
          placeHolder="Velg dato"
          onChange={(e) => handleChangeDateInputs(e, handleChange)}
          value={makuleringerFilterForm.makuleringFraDato}
          validationError={
            makuleringerFilterForm.makuleringFraDatoValidationErrorText
          }
          timeFormat={false}
        />
        <DatetimePicker
          id="makuleringTilDato"
          name="makuleringTilDato"
          locale="nb"
          label="Makulert dato til"
          placeHolder="Velg dato"
          onChange={(e) => handleChangeDateInputs(e, handleChange)}
          value={makuleringerFilterForm.makuleringTilDato}
          validationError={
            makuleringerFilterForm.makuleringTilDatoValidationErrorText
          }
          timeFormat={false}
        />
        <DatetimePicker
          id="ileggelseFraDato"
          name="ileggelseFraDato"
          locale="nb"
          label="Ileggelsesdato fra"
          placeHolder="Velg dato"
          onChange={(e) => handleChangeDateInputs(e, handleChange)}
          value={makuleringerFilterForm.ileggelseFraDato}
          validationError={
            makuleringerFilterForm.ileggelseFraDatoValidationErrorText
          }
          timeFormat={false}
        />
        <DatetimePicker
          id="ileggelseTilDato"
          name="ileggelseTilDato"
          locale="nb"
          label="Ileggelsesdato til"
          placeHolder="Velg dato"
          onChange={(e) => handleChangeDateInputs(e, handleChange)}
          value={makuleringerFilterForm.ileggelseTilDato}
          validationError={
            makuleringerFilterForm.ileggelseTilDatoValidationErrorText
          }
          timeFormat={false}
        />
        <TextInput
          label="Tjenestenummer"
          name="tjenestenummer"
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          value={makuleringerFilterForm.tjenestenummer}
          validationError={
            makuleringerFilterForm.tjenestenummerValidationErrorText
          }
        />
      </div>
      <div style={{ color: "#a94442" }}>
        {makuleringerFilterForm.filterValidationErrorText &&
          makuleringerFilterForm.filterValidationErrorText}
      </div>
      <div style={{ marginTop: 10 }}>
        <FormButtonsForCloseAndSubmit
          submitText="Søk"
          onClose={() =>
            handleChangeToInitState("RAPPORT_MAKULERINGER_FILTER_FORM")
          }
          showSubmitSpinner={showSokSpinner}
        />
      </div>
    </form>
  );
};

export default MakuleringFilterForm;
