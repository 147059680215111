import { connect } from "react-redux";
import UferdigstilteKlagesaker from "./UferdigstilteKlagesaker";
import { setSort as setSortAction } from "../../../actions/advancedListActions";
import { getVedtaker as getVedtakerAction } from "../../../actions/klager/vedtakAction";
import { handleChangeAll as handleChangeAllAction } from "../../../actions/handleChangeAction";

const mapStateToProps = (state) => ({
  uferdigstilteKlagesaker: state.uferdigstilteKlagesaker,
  uferdigstilteKlagesakerSubmittedForm:
    state.submittedForms.uferdigstilteKlagesakerSubmittedForm,
});

const mapDispatchToProps = (dispatch) => ({
  setSort: (sort) => dispatch(setSortAction("UFERDIGSTILTEKLAGESAKER", sort)),
  getVedtaker: (params) => dispatch(getVedtakerAction(params)),
  dispatchUferdigstilteKlagesaker: (uferdigstilteKlagesaker) =>
    dispatch({
      type: "GET_ALL_UFERDIGSTILTE_KLAGESAKER_SUCCESS",
      payload: uferdigstilteKlagesaker,
    }),
  handleChangeAll: (reducerName, payload) =>
    dispatch(handleChangeAllAction(reducerName, payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UferdigstilteKlagesaker);
