import { connect } from "react-redux";
import FilterForm from "./FilterForm";

import { getMiljogebyrTilBehandling as getMiljogebyrTilBehandlingAction } from "../../../actions/miljogebyrTilBehandlingActions";
import {
  handleChangeAll as handleChangeAllAction,
  handleChangeToInitState as handleChangeToInitStateAction,
} from "../../../actions/handleChangeAction";
import { getOvertredelseskoderMiljogebyr as getOvertredelseskoderMiljogebyrAction } from "../../../actions/overtredelseskoderMiljogebyrActions";
import { getMiljoleveringstyper as getMiljoleveringstyperAction } from "../../../actions/miljoleveringstyperActions";

import { getOvertredelseskoder } from "../../../selectors/miljogebyrTilBehandlingSelector";

const mapStateToProps = (state) => {
  return {
    miljogebyrTilBehandling: state.miljogebyrTilBehandling,
    leveringstyper: state.miljoleveringstyper,
    overtredelseskoder: getOvertredelseskoder(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMiljogebyrTilBehandling: (params, page, count) =>
      dispatch(getMiljogebyrTilBehandlingAction(params, page, count)),
    getOvertredelseskoderMiljogebyr: () =>
      dispatch(getOvertredelseskoderMiljogebyrAction()),
    getMiljoleveringstyper: () => dispatch(getMiljoleveringstyperAction()),
    handleChangeAll: (payload) =>
      dispatch(
        handleChangeAllAction(
          "MILJOGEBYRER_TIL_BEHANDLING_FILTER_FORM",
          payload
        )
      ),
    handleChangeToInitState: () =>
      dispatch(
        handleChangeToInitStateAction("MILJOGEBYRER_TIL_BEHANDLING_FILTER_FORM")
      ),
  };
};

const mergeProps = (stateProps, dispatchProps) => {
  return {
    ...dispatchProps,
    overtredelseskoder: stateProps.overtredelseskoder,
    leveringstyper: stateProps.leveringstyper,
    getMiljogebyrTilBehandling: (params) =>
      dispatchProps.getMiljogebyrTilBehandling(
        params,
        stateProps.miljogebyrTilBehandling.page,
        stateProps.miljogebyrTilBehandling.count
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(FilterForm);
