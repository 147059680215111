import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";

import TextInput from "../Common/Forms/TextInput";
import Datepicker from "../Common/Forms/Datepicker";
import DropDown from "../Common/Forms/DropDown";
import TextArea from "../Common/Forms/TextArea";

export default class OvertredelseskoderMiljogebyrForm extends React.Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    paragraf: PropTypes.string,
    del: PropTypes.string,
    belop: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    kortTekst: PropTypes.string,
    langTekst: PropTypes.string,
    arkivKode: PropTypes.string,
    aktivertDato: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    deaktivertDato: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    aktiv: PropTypes.bool,
    actionType: PropTypes.string,
    validationError: PropTypes.object,
    overtredelseGruppeId: PropTypes.number,
    overtredelseGrupper: PropTypes.array,
    handleChange: PropTypes.func,
  };

  render() {
    const {
      paragraf,
      del,
      belop,
      kortTekst,
      langTekst,
      arkivKode,
      aktivertDato,
      deaktivertDato,
      handleChange,
      validationError,
      actionType,
      overtredelseGruppeId,
      overtredelseGrupper,
      id,
    } = this.props;
    return (
      <form>
        <Row>
          <Col md={6}>
            <TextInput
              label="ID"
              value={id}
              name="id"
              type="number"
              onChange={handleChange}
              disabled={actionType === "update" ? true : false}
              validationError={validationError.id}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <TextInput
              label="Paragraf"
              onChange={handleChange}
              value={paragraf}
              name="paragraf"
              validationError={validationError.paragraf}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <TextInput
              label="Del"
              onChange={handleChange}
              value={del}
              name="del"
              validationError={validationError.del}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <TextInput
              label="Beløp"
              onChange={handleChange}
              value={belop}
              name="belop"
              type="number"
              validationError={validationError.belop}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <TextInput
              label="Arkivkode"
              id="arkivKode"
              onChange={handleChange}
              value={arkivKode}
              name="arkivKode"
              validationError={validationError.arkivKode}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <DropDown
              id="overtredelsesgruppe"
              label="Overtredelsesgruppe"
              items={overtredelseGrupper}
              name="overtredelseGruppeId"
              selected={overtredelseGruppeId}
              title="Velg"
              onChange={handleChange}
              validationError={validationError.overtredelseGruppeId}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Datepicker
              label="Aktivert dato"
              id="aktivertDato"
              onChange={handleChange}
              locale="nb"
              timeFormat={false}
              value={aktivertDato}
              placeHolder="Velg"
              validationError={validationError.aktivertDato}
            />
          </Col>
          <Col md={6}>
            <Datepicker
              label="Deaktivert dato"
              id="deaktivertDato"
              onChange={handleChange}
              locale="nb"
              timeFormat={false}
              value={deaktivertDato}
              placeHolder="Velg"
              validationError={validationError.deaktivertDato}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <TextInput
              label="Kort tekst"
              onChange={handleChange}
              value={kortTekst}
              name="kortTekst"
              validationError={validationError.kortTekst}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <TextArea
              label="Lang tekst"
              onChange={handleChange}
              value={langTekst}
              name="langTekst"
              validationError={validationError.langTekst}
            />
          </Col>
        </Row>
      </form>
    );
  }
}
