import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Tab, Tabs } from "react-bootstrap";
import Kommentar from "../../Common/Detalj/Kommentar";
import Historikk from "../../Common/Detalj/Historikk";

import { getMiljogebyrerHistorikk } from "../../../actions/miljogebyrerActions";
import {
  createMiljogebyrKommentar,
  getAllMiljogebyrKommentarer,
} from "../../../actions/miljoKommentarerAction";

import {
  IsValidated,
  onChangeValidation,
} from "../../../utils/validationUtils";

class HistorikkOgKommentarerContainer extends React.Component {
  static propTypes = {
    miljogebyrId: PropTypes.string,
  };

  state = {
    showKommentarTextarea: false,
    kommentarTextarea: "",
    validationError: {},
  };
  rules = {
    kommentarTextarea: "required",
  };

  componentDidMount() {
    this.props.getMiljogebyrerHistorikk(this.props.miljogebyrId);
    this.props.getAllMiljogebyrKommentarer(this.props.miljogebyrId);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    onChangeValidation(e, this.state, this.rules);
  }

  handleClick(e) {
    if (e.target.name === "showKommentarTextarea") {
      this.setState({ showKommentarTextarea: true });
    }
    if (e.target.name === "cancelKommentarTextarea") {
      this.setState({
        showKommentarTextarea: false,
        kommentarTextarea: "",
        validationError: {},
      });
    }
  }
  isValid = () => {
    const { isValid, validationError } = IsValidated(this.state, this.rules);
    this.setState({ validationError });
    return isValid;
  };

  handleSubmit(e) {
    e.preventDefault();
    const payload = { tekst: this.state.kommentarTextarea };
    if (this.isValid()) {
      const miljogebyrId = this.props.miljogebyrId;
      this.props.createMiljogebyrKommentar(miljogebyrId, payload).then(() => {
        this.props.getAllMiljogebyrKommentarer(miljogebyrId);
        this.setState({ kommentarTextarea: "" });
      });
    }
  }

  render() {
    return (
      <section className="detalj-miljogebyr-historikk-section-margin no-print">
        <Tabs
          defaultActiveKey={1}
          id="tabs-kommentarer-og-historikk-for-miljogebyr"
        >
          <Tab eventKey={1} title="Kommentarer">
            <Kommentar
              kommentarer={this.props.miljoKommentarer}
              showKommentarTextarea={this.state.showKommentarTextarea}
              kommentarTextarea={this.state.kommentarTextarea}
              validationError={this.state.validationError}
              handleChange={this.handleChange.bind(this)}
              handleClick={this.handleClick.bind(this)}
              handleSubmit={this.handleSubmit.bind(this)}
            />
          </Tab>
          <Tab eventKey={2} title="Historikk">
            <div>
              <Historikk historikkArray={this.props.miljogebyrHistorikk} />
            </div>
          </Tab>
        </Tabs>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    miljogebyrHistorikk: state.miljogebyrHistorikk
      ? state.miljogebyrHistorikk.map((mh) => ({
          historikkBrukerNavn: mh.brukerNavn,
          historikkTidspunkt: mh.tidspunkt,
          felter: mh.endringer,
        }))
      : [],
    miljoKommentarer: state.miljoKommentarer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getMiljogebyrerHistorikk,
      createMiljogebyrKommentar,
      getAllMiljogebyrKommentarer,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HistorikkOgKommentarerContainer);
