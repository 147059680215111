import React from "react";
import { Row, Col } from "react-bootstrap";
import {
  toNorwegianDatetime,
  toNorwegianDateFormat,
} from "../../utils/datetimeUtils";

function P360InfoColumn({ property, label, required }) {
  return (
    <Col xl={6}>
      <div className="klageinfo-label">{label}</div>
      <div
        className={
          required && !property
            ? "klageinfo-text text-danger"
            : "klageinfo-text"
        }
      >
        {property ? property : required ? "*Mangler info fra akrivsystem" : "-"}
      </div>
    </Col>
  );
}

export const KlageInfoDetail = ({ sak }) => {
  const klageInfo = sak && sak.kontakt;
  return (
    <div className="klageinfo">
      <div className="klageinfo-row">
        <Row>
          <P360InfoColumn
            property={klageInfo && klageInfo.navn}
            label="Klager"
            required
          />
          <P360InfoColumn
            property={klageInfo && klageInfo.adresse}
            label="Adresse"
          />
        </Row>
      </div>
      <div className="klageinfo-row">
        <Row>
          <P360InfoColumn
            property={klageInfo && klageInfo.postnummer}
            label="Postnr."
            required
          />
          <P360InfoColumn
            property={klageInfo && klageInfo.poststed}
            label="Poststed"
            required
          />
        </Row>
      </div>
      <div className="klageinfo-row">
        <Row>
          <P360InfoColumn property={klageInfo && klageInfo.land} label="Land" />
          <P360InfoColumn
            property={klageInfo && klageInfo.epost}
            label="Epost"
          />
        </Row>
      </div>
      <div className="klageinfo-row">
        <Row>
          <P360InfoColumn
            property={
              sak &&
              toNorwegianDateFormat(toNorwegianDatetime(sak.opprettetDato))
            }
            label="Opprettet dato"
          />
          <P360InfoColumn
            property={
              sak && toNorwegianDateFormat(toNorwegianDatetime(sak.dato))
            }
            label="Ferdig dato"
          />
        </Row>
      </div>
    </div>
  );
};

export default KlageInfoDetail;
