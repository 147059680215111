import axiosPackage from "axios";
import axios from "../services/axios";
import * as api from "../constants/api";
import * as handle from "../utils/actionUtils";
import { setTotal as setTotalAction } from "./advancedListActions";

import { addFlashMessage } from "./FlashMessagesAction";

import { toIsoString } from "../utils/datetimeUtils";
import { errorData } from "../utils/apiResponseUtils";
import { popUpBlocked } from "../constants/ErrorMessages";
import { getBase64ObjectURL } from "../utils/imageUtils";

export const GET_MILJOGEBYRER_SUCCESS = "GET_MILJOGEBYRER_SUCCESS";
export const GET_MILJOGEBYRER_FAILURE = "GET_MILJOGEBYRER_FAILURE";

export function getMiljogebyrer(
  params,
  page,
  count,
  sort = undefined,
  cancelToken = null
) {
  let sortParam = "";
  let sortOrder = "";
  if (sort && sort.cellKey && sort.orders) {
    sortParam = sort.cellKey;
    sortOrder = sort.orders;
  }
  return async (dispatch) => {
    return axios
      .get(api.GET_MILJOGEBYRER_URL, {
        cancelToken,
        params: {
          ...params,
          fraDato: params.fraDato && toIsoString(params.fraDato),
          tilDato: params.tilDato && toIsoString(params.tilDato),
          fraIneOverforingsdato:
            params.fraIneOverforingsdato &&
            toIsoString(params.fraIneOverforingsdato),
          tilIneOverforingsdato:
            params.tilIneOverforingsdato &&
            toIsoString(params.tilIneOverforingsdato),
          page: page,
          pageSize: count,
          sort: sortParam,
          sortOrder,
        },
      })
      .then((response) => {
        dispatch(
          setTotalAction(
            "miljogebyrer",
            JSON.parse(response.headers["x-pagination"]).Total
          )
        );
        dispatch({
          type: GET_MILJOGEBYRER_SUCCESS,
          payload: response.data.result,
        });

        return Promise.resolve(response);
      })
      .catch((error) => {
        if (axiosPackage.isCancel(error)) {
          return false;
        }
        handle.error(dispatch, GET_MILJOGEBYRER_FAILURE);
        return Promise.reject(error);
      });
  };
}

export const GET_MILJOGEBYR_SUCCESS = "GET_MILJOGEBYR_SUCCESS";
export const GET_MILJOGEBYR_FAILURE = "GET_MILJOGEBYR_FAILURE";

export function getMiljogebyr(miljogebyrId) {
  return async (dispatch) => {
    return axios
      .get(api.GET_MILJOGEBYR_URL(miljogebyrId))
      .then(handle.success(dispatch, GET_MILJOGEBYR_SUCCESS))
      .catch(handle.error(dispatch, GET_MILJOGEBYR_FAILURE));
  };
}

// params
// "mottaker",
// "fodsnrOrOrgnr",
// "mottakertypeId",
// "mottakerGate",
// "mottakerGateNummer",
// "mottakerPostnummer",
// "mottakerPoststed",
// "gnr",
// "bnr",
// "leveringstype",
// "overtredelse1",
// "overtredelse2",
// "overtredelse3",
// "internkode1",
// "internkode2",
// "internkode3",
// "kommentarTilMottaker",
// "internkommentar",
// "belop",
export function putMiljogebyrer(miljogebyrId, params) {
  return async (dispatch) => {
    return axios
      .put(api.PUT_MILJOGEBYRER_URL(miljogebyrId), params)
      .then((response) => ({ resolved: true, data: response }))
      .catch((error) => ({ resolved: false, data: error }));
  };
}

export function getMiljogebyrByNummer(ileggelsesnummer) {
  return (dispatch, getState) => {
    return axios
      .get(api.GET_MILJOGEBYRER_URL, {
        params: { ileggelsesnummer: ileggelsesnummer },
      })
      .then(handle.success(dispatch, GET_MILJOGEBYR_SUCCESS, null, {}, true))
      .catch(handle.error(dispatch, GET_MILJOGEBYR_SUCCESS));
  };
}

export function postMiljogebyrerBilder(
  miljogebyrId,
  file,
  showSuccessFlashMessage = false,
  showErrorFlashMessage = false
) {
  return async (dispatch) => {
    let formData = new FormData();
    formData.append("Bilde", file);
    return axios
      .post(api.POST_MILJOGEBYRER_BILDER_URL(miljogebyrId), formData, {
        headers: { "content-type": "multipart/form-data" },
      })
      .then((response) => {
        if (showSuccessFlashMessage) {
          dispatch(
            addFlashMessage({ type: "success", text: "Lagt til bilde" })
          );
        }
        return Promise.resolve(response);
      })
      .catch((error) => {
        if (showErrorFlashMessage) {
          const errorMessage = errorData(error);
          dispatch(
            addFlashMessage({
              type: "error",
              message: errorMessage,
            })
          );
        }
        return Promise.reject(error);
      });
  };
}

export function getMiljogebyrBilde(url) {
  return (dispatch) => {
    return axios.get(url + "/base64").then((response) => {
      const result = response.data.result;
      const base64 = "data:image/png;base64," + result.base64;
      return { src: base64, navn: result.filename };
    });
  };
}

export const getMiljogebyrBildeBase64 = (miljogebyrId, bildeId) => {
  return async (dispatch) => {
    return axios
      .get(api.GET_MILJOGEBYR_BILDE_BASE64_URL(miljogebyrId, bildeId))
      .then(async (response) => {
        const result = response.data.result;

        const src = await getBase64ObjectURL(
          `data:image/png;base64,${result.base64}`
        );

        return Promise.resolve({ navn: result.filename, src });
      })
      .catch((error) => {
        const errorMessage = errorData(error);
        dispatch(
          addFlashMessage({
            type: "error",
            message: errorMessage,
          })
        );

        return Promise.reject(false);
      });
  };
};

export const GET_MILJOGEBYR_BILDER_SUCCESS = "GET_MILJOGEBYR_BILDER_SUCCESS";

export function getMiljogebyrBilder(
  miljogebyrId,
  params = {},
  displayFlashMessage = true
) {
  return async (dispatch) => {
    const url = api.GET_MILJOGEBYRER_BILDER_BASE_URL(miljogebyrId);
    return axios
      .get(url + "/base64", { params: params })
      .then(async (response) => {
        const result = response.data.result;
        const bilder = await Promise.all(
          result.map(async (item) => ({
            ...item,
            src: await getBase64ObjectURL(item.src),
          }))
        );

        dispatch({
          type: GET_MILJOGEBYR_BILDER_SUCCESS,
          payload: {
            miljogebyrId,
            bilder: bilder.reduce(
              (acc, bilde) => ({
                ...acc,
                [bilde.id]: bilde,
              }),
              {}
            ),
          },
        });

        return bilder;
      })
      .catch((error) => {
        if (displayFlashMessage) {
          const errorMessage = errorData(error);
          dispatch(
            addFlashMessage({
              type: "error",
              message: errorMessage,
            })
          );
        }

        return Promise.reject(false);
      });
  };
}

export function putMiljogebyrerBilder(miljogebyrId, bildeId, isGoodQuality) {
  return async (dispatch) => {
    return axios
      .put(api.PUT_MILJOGEBYRER_BILDER_URL(miljogebyrId, bildeId), {
        isGoodQuality,
      })
      .then((response) => {
        dispatch(addFlashMessage({ type: "success", text: "Oppdatert" }));
        return true;
      })
      .catch((error) => {
        const errorMessage = errorData(error);
        dispatch(
          addFlashMessage({
            type: "error",
            message: errorMessage,
          })
        );
        return false;
      });
  };
}

export function deleteMiljogebyrerBilder(miljogebyrId, bildeId) {
  return async (dispatch) => {
    return axios
      .delete(api.DELETE_MILJOGEBYRER_BILDER_URL(miljogebyrId, bildeId))
      .then((response) => {
        dispatch(addFlashMessage({ type: "success", text: "Fjernet" }));
        return response;
      })
      .catch((error) => {
        const errorMessage = errorData(error);
        dispatch(
          addFlashMessage({
            type: "error",
            message: errorMessage,
          })
        );
        return errorMessage;
      });
  };
}

export function putMiljogebyrIsKvalitetsikret(miljogebyrId, params) {
  return async () => {
    return axios
      .put(api.PUT_MILJOGEBYR_IS_KVALITETSIKRET_URL(miljogebyrId), params)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  };
}

export function getMiljogebyrKopi(miljogebyrId) {
  return async (dispatch) => {
    return axios
      .get(api.GET_MILJOGEBYR_KOPI_URL(miljogebyrId))
      .then((response) => {
        let tabName = "E-Brev.pdf";
        var byteCharacters = window.atob(response.data.result.base64_kopi);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var blob = new Blob([byteArray], { type: "application/pdf" });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE workaround
          window.navigator.msSaveOrOpenBlob(blob, tabName);
        } else {
          window.URL = window.URL || window.webkitURL;
          var blobUrl = window.URL.createObjectURL(blob);
          let pdfWindow = window.open("");
          if (
            !pdfWindow ||
            pdfWindow.closed ||
            typeof pdfWindow.closed === "undefined"
          ) {
            throw new Error(popUpBlocked);
          } else {
            pdfWindow.document.write(
              "<iframe width='100%' height='100%' src='" +
                blobUrl +
                "' frameborder='0' allowfullscreen></iframe>"
            );
            pdfWindow.document.title = tabName;
          }
        }
        return Promise.resolve(true);
      })
      .catch((error) => {
        let allowDangerouslySetInnerHtml = false;
        let errorMessage = "";
        if (!error.response && error.message) {
          allowDangerouslySetInnerHtml = true;
          errorMessage = error.message;
        } else {
          errorMessage = errorData(error);
        }
        dispatch(
          addFlashMessage({
            type: "error",
            text: errorMessage,
            allowDangerouslySetInnerHtml,
          })
        );
        Promise.reject(false);
      });
  };
}

export const GET_MILJOGEBYRER_HISTORIKK_SUCCESS =
  "GET_MILJOGEBYRER_HISTORIKK_SUCCESS";
export const GET_MILJOGEBYRER_HISTORIKK_FAILURE =
  "GET_MILJOGEBYRER_HISTORIKK_FAILURE";

export function getMiljogebyrerHistorikk(miljogebyrId) {
  return (dispatch, getState) => {
    return axios
      .get(api.GET_MILJOGEBYRER_HISTORIKK_URL(miljogebyrId)) // TODO Change url
      .then(handle.success(dispatch, GET_MILJOGEBYRER_HISTORIKK_SUCCESS))
      .catch(handle.error(dispatch, GET_MILJOGEBYRER_HISTORIKK_FAILURE));
  };
}

export const GET_MILJOGEBYRER_MILJOGEBYRKLAGER_SUCCESS =
  "GET_MILJOGEBYRER_MILJOGEBYRKLAGER_SUCCESS";

export function getMiljogebyrKlager(miljogebyrId) {
  return async (dispatch) => {
    return axios
      .get(api.GET_MILJOGEBYRER_MILJOGEBYRKLAGER(miljogebyrId))
      .then(handle.success(dispatch, GET_MILJOGEBYRER_MILJOGEBYRKLAGER_SUCCESS))
      .catch((error) => {
        throw error;
        // dispatch(
        //   addFlashMessage({
        //     type: "error",
        //     message: error
        //  })
        //);
      });
  };
}

export function putMiljogebyrerSettKlarTilIne(miljogebyrId) {
  return (dispatch) => {
    return axios
      .put(api.PUT_MILJOGEBYRER_SETT_KLAR_TIL_INE_URL(miljogebyrId), {
        settKlarTilKemner: true,
      })
      .then((response) => {
        dispatch(addFlashMessage({ type: "success", text: "Oppdatert" }));
        Promise.resolve(response);
      })
      .catch((error) => {
        const errorMessage = errorData(error);
        dispatch(
          addFlashMessage({
            type: "error",
            text: errorMessage,
          })
        );
        return Promise.reject(error);
      });
  };
}
