import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { getSkiftlogger as getSkiftloggerAction } from "../../../actions/skiftloggerActions";
import { getBybetjentGrupper } from "../../../actions/bybetjentgrupperAction";
import { getBybetjenter as getBybetjenterAction } from "../../../actions/bybetjenterActions";
import {
  handleChange as handleChangeAction,
  handleChangeAll as handleChangeAllAction,
  handleChangeToInitState as handleChangeToInitStateAction,
} from "../../../actions/handleChangeAction";

import { getSelectOptionsEnhanced } from "../../../selectors/selectOptionsSelector";

import { INITIAL_STATE as SkiftloggFilterFormStateInitial } from "../../../reducers/forms/SkiftloggFilterFormReducer";
import { handleFromToDateTime } from "../../../utils/fromToDateTimeUtils";
import * as inputValidation from "../../../utils/inputValidationUtils";

import SkiftloggFilterForm from "./SkiftloggFilterForm";
import { toIsoString } from "../../../utils/datetimeUtils";
import moment from "moment";

class SkiftloggFilterContainer extends Component {
  static propTypes = {
    SkiftloggFilterFormState: PropTypes.object,
    handleChange: PropTypes.func,
    bybetjenter: PropTypes.arrayOf(PropTypes.object),
    teams: PropTypes.array,
    getBybetjentGrupper: PropTypes.func,
    getSkiftlogger: PropTypes.func,
  };

  state = {
    error: "",
    shallGetSkiftloggDueToHandleChangeToInitState: false,
    showSokSpinner: false,
  };

  componentDidMount() {
    this.props.getBybetjentGrupper();
    this.props.getBybetjenter();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(this.props.SkiftloggFilterFormState) ===
        JSON.stringify(SkiftloggFilterFormStateInitial) &&
      this.state.shallGetSkiftloggDueToHandleChangeToInitState === true
    ) {
      this.setState({ shallGetSkiftloggDueToHandleChangeToInitState: false });
    }
  }

  handleChange = (e) => {
    const { SkiftloggFilterFormState } = this.props;
    if (e.target.name === "fraDato" || e.target.name === "tilDato") {
      handleFromToDateTime(
        e,
        SkiftloggFilterFormState,
        this.props.handleChange
      );
      return;
    }
    this.props.handleChange(e.target.name, e.target.value);
  };

  handleChangeToInitState = () => {
    this.props.handleChangeToInitState();
    this.setState({ shallGetSkiftloggDueToHandleChangeToInitState: true });
  };

  resetValidationErrorText() {
    this.props.handleChange("fraDatoValidationErrorText", null);
    this.props.handleChange("tilDatoValidationErrorText", null);
  }

  validate() {
    return inputValidation.fraDateTimeTilDateTimeValidation(
      this.props.SkiftloggFilterFormState,
      this.props.handleChange
    );
  }

  getTeamNavn = () => {
    const { SkiftloggFilterFormState, team } = this.props;

    if (!team) return "";
    for (let index = 0; index < team.length; index++) {
      const teamElement = team[index];
      if (teamElement.id === SkiftloggFilterFormState.teamId)
        return teamElement.navn;
    }

    return "";
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { SkiftloggFilterFormState, handleChangeAll } = this.props;
    if (this.validate()) {
      this.setState(() => ({ showSokSpinner: true }));
      const params = {
        fraDato: toIsoString(SkiftloggFilterFormState.fraDato),
        tilDato: toIsoString(SkiftloggFilterFormState.tilDato),
        teamIds: SkiftloggFilterFormState.selectedTeams.map((team) => team.id), //parseInt(SkiftloggFilterFormState.teamId, 10),
        tjenestenummer: SkiftloggFilterFormState.selectedTjenestenummer.map(
          (tjenestenummer) => tjenestenummer.id
        ),
      };
      this.props
        .getSkiftlogger(params)
        .then(() => {
          this.setState(() => ({ showSokSpinner: false }));
          handleChangeAll("RAPPORT_SKIFTLOGG_SUBMITTED_FORM", {
            submitTimestamp: moment(),
            fraDato: SkiftloggFilterFormState.fraDato,
            tilDato: SkiftloggFilterFormState.tilDato,
            teams: SkiftloggFilterFormState.selectedTeams
              .map((team) => team.navn)
              ?.toString(),
            tjenestenummer: SkiftloggFilterFormState.selectedTjenestenummer
              .map((tjenestenummer) => tjenestenummer.id)
              ?.toString(),
          });
        })
        .catch(() => this.setState(() => ({ showSokSpinner: false })));
    }
  };

  render() {
    const { bybetjenter, teams, SkiftloggFilterFormState } = this.props;
    const { showSokSpinner } = this.state;
    return (
      <SkiftloggFilterForm
        showSokSpinner={showSokSpinner}
        skiftloggFilterFormState={SkiftloggFilterFormState}
        bybetjenter={bybetjenter}
        teams={teams}
        handleChange={this.handleChange}
        handleChangeToInitState={this.handleChangeToInitState}
        handleSubmit={this.handleSubmit.bind(this)}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  teams: getSelectOptionsEnhanced(state, "betjentgrupper", "navn", "id"), //state.betjentgrupper,
  bybetjenter: getSelectOptionsEnhanced(
    state,
    "bybetjenter",
    "nummer",
    "nummer"
  ),
  SkiftloggFilterFormState: state.forms.SkiftloggFilterForm,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getBybetjentGrupper: () => dispatch(getBybetjentGrupper()),
    getBybetjenter: () => dispatch(getBybetjenterAction({ onlyActive: true })),
    handleChange: (variableName, value) =>
      dispatch(
        handleChangeAction("SKIFTLOGG_FILTER_FORM", variableName, value)
      ),
    handleChangeAll: (reducer, payload) =>
      dispatch(handleChangeAllAction(reducer, payload)),
    handleChangeToInitState: () =>
      dispatch(handleChangeToInitStateAction("SKIFTLOGG_FILTER_FORM")),
    getSkiftlogger: (filters) => dispatch(getSkiftloggerAction(filters)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SkiftloggFilterContainer);
