export function successData(response) {
  if (response.data) {
    let data = response.data;
    let status = response.status;
    if (status === 200) {
      return data.result;
    }
  }
  return response;
}

export function errorData(err) {
  if (err === null) return "";
  if (err.response) {
    if (err.response.status && err.response.status === 500) {
      return "Feil oppsto på server siden med feilkode(500). vennligst kontakt systemadministrator.";
    } else {
      if (err.response.data) {
        let res = err.response.data.errorMessage;
        return res;
      }
    }
  }
  return err;
}

export function removeError(state, e) {
  const errors = state.errors;
  const felt = e.target.name;
  const value = e.target.value;
  if (value && value !== "") {
    delete errors[felt];
    return errors;
  }
}
