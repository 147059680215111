import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import NavigationBar from "../Common/NavigationBar";

import { authorized } from "../../utils/authorizationUtils";

import sitePaths from "../../constants/sitePaths";
import { bypatruljen, juridisk } from "../../constants/roles";

class NavigationBarContainer extends React.Component {
  static propTypes = {
    page: PropTypes.string,
    children: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.state = {
      navigationItems: [],
      authorizedEttersendelser: authorized([
        bypatruljen.saksbehandler,
        bypatruljen.saksbehandleradmin,
        bypatruljen.avdelingdirektor,
        bypatruljen.seksjonssjefStab,
      ]),
      authorizedEttersendelserTilGodkjenning: authorized([
        bypatruljen.saksbehandler,
        bypatruljen.saksbehandleradmin,
      ]),
      authorizedElsparksykkelGebyr: authorized([
        bypatruljen.saksbehandler,
        bypatruljen.saksbehandleradmin,
        bypatruljen.avdelingdirektor,
        bypatruljen.seksjonssjefStab,
        bypatruljen.seksjonssjef,
        bypatruljen.driftleder,
        juridisk.ledelse,
        juridisk.saksbehandler,
      ]),
    };
  }

  componentDidMount() {
    this.setNavigationItems();
  }

  setNavigationItems = () => {
    let navigationItems = [
      {
        navn: "Ileggelsesoversikt",
        path: sitePaths.ileggelser.oversikt.shortUrl,
      },
    ];
    if (this.state.authorizedEttersendelser) {
      navigationItems = [
        ...navigationItems,
        {
          navn: "Ettersendelser",
          path: sitePaths.ileggelser.ettersendelser.shortUrl,
        },
      ];
    }
    if (this.state.authorizedEttersendelserTilGodkjenning) {
      navigationItems = [
        ...navigationItems,
        {
          navn: "Ettersendelser til godkjenning",
          path: sitePaths.ileggelser.ettersendelserTilGodkjenning.shortUrl,
        },
      ];
    }
    if (this.state.authorizedElsparksykkelGebyr) {
      navigationItems = [
        ...navigationItems,
        {
          navn: "ELS",
          path: sitePaths.ileggelser.elsparksykkelGebyr.shortUrl,
        },
      ];
    }
    this.setState({ navigationItems });
  };

  render() {
    return <NavigationBar navigationItems={this.state.navigationItems} />;
  }
}

export default withRouter(NavigationBarContainer);
