import * as ileggelserActions from "../actions/ileggelserActions";

const INITIAL_STATE = [];

export default function ileggelseKlagerReducer(
  state = [...INITIAL_STATE],
  action
) {
  switch (action.type) {
    case ileggelserActions.GET_ILEGGELSER_ILEGGELSEKLAGER_SUCCESS:
      return [...action.payload];
    default:
      return state;
  }
}
