import React from "react";

const ListTotal = ({ total }) => {
  return (
    <div
      className="pull-right ileggelse-list-page-total-ileggelser"
      style={{ paddingTop: 20, paddingRight: 50 }}
    >
      {total} miljøgebyr
    </div>
  );
};

export default ListTotal;
