import { createSelector } from "reselect";
import { toNorwegianDatetime } from "../utils/datetimeUtils";
import { ileggelseClientSpecificUtils } from "../utils/ileggelseClientSpecificUtils";
import { authorized } from "../utils/authorizationUtils";
import { INITIAL_STATE as initIleggelseQueryParameters } from "../reducers/forms/ileggelserOversiktFilterFormReducer";
import { visAlleJaNei } from "../constants/globals";
import { juridiskArray, bypatruljen } from "../constants/roles";

const ileggelseList = (state) => state.ileggelser;

export const getIleggelseList = createSelector(
  ileggelseList,
  (ileggelseList) => {
    return ileggelseList.items;
  }
);

const ileggelsestyper = (state) => state.ileggelsestyper;

export const getIleggelserForOversikt = createSelector(
  [ileggelseList, ileggelsestyper],
  (ileggelseList, ileggelsestyper) => ({
    ...ileggelseList,
    items: ileggelseList.items.map((item) => {
      let ileggelsestype = ileggelsestyper.find(
        (ileggelsestype) => item.type === ileggelsestype.navn
      );
      let type = ileggelsestype && ileggelsestype.kode;
      return { ...item, type };
    }),
  })
);

const klagekoder = (state) => state.klagekoder;

export const getSelectOptionKlagekoder = createSelector(
  [klagekoder],
  (klagekoder) => {
    return klagekoder.map((k) => {
      return { id: k.id, navn: `${k.id} - ${k.navn}` };
    });
  }
);

const vedtakskoder = (state) => state.vedtakskoder;

export const getDropDownListSearchVedtakskoder = createSelector(
  [vedtakskoder],
  (vedtakskoder) => {
    return [{ id: "", navn: "Velg", beskrivelse: "" }].concat(
      vedtakskoder.map((v) => {
        return {
          id: v.id,
          navn: v.kode,
          beskrivelse: v.navn,
        };
      })
    );
  }
);

const getNavnById = (id, objectArray) => {
  const object = objectArray.filter((leveringstype) => leveringstype.id === id);
  return object.length > 0 && object[0].navn ? object[0].navn : "";
};

const showImageIcon = (ileggelse) => {
  if (ileggelse.antallBilder > 1) return "ShowImageIcon";
  return "";
};

const ileggelserItems = (state) => state.ileggelser && state.ileggelser.items;
const ELSIleggelserItems = (state) => state?.elsparksykkelGebyr?.items;

export const getIleggelseTableRows = createSelector(
  [ileggelserItems],
  (ileggelserItems) => {
    return ileggelserItems.map((ileggelse) => {
      const ileggelsesdatoTil =
        ileggelse.ileggelsesdatoTil &&
        toNorwegianDatetime(ileggelse.ileggelsesdatoTil).format("DD.MM.YYYY");
      const ileggelsesdatoFraTid =
        ileggelse.ileggelsesdatoFra &&
        toNorwegianDatetime(ileggelse.ileggelsesdatoFra).format("HH:mm");
      const ileggelsesdatoTilTid =
        ileggelse.ileggelsesdatoTil &&
        ` - ${toNorwegianDatetime(ileggelse.ileggelsesdatoTil).format(
          "HH:mm"
        )}`;
      const klokkeslett = ileggelsesdatoFraTid + ileggelsesdatoTilTid;

      let data = [
        ileggelse?.ileggelsesnummer.toString(),
        ileggelsesdatoTil,
        klokkeslett,
        ileggelse?.tjenestenummer.toString(),
        ileggelseClientSpecificUtils.getOvertredelserString(
          ileggelse.overtredelse1,
          ileggelse.overtredelse2,
          ileggelse.overtredelse3
        ),
        ileggelse.kjennemerke,
        ileggelse.nasjon,
        ileggelse.gatenavn,
        ileggelse.gatenummer,
        ileggelse.vedGate,
        ileggelse.vedGatenummer,
        ileggelse.bydel,
        ileggelse.type[0],
        showImageIcon(ileggelse),
      ];

      return {
        metadata: { isMakulert: ileggelse.isMakulert },
        data,
      };
    });
  }
);

export const getELSIleggelseTableRows = createSelector(
  [ELSIleggelserItems],
  (ileggelserItems) => {
    return ileggelserItems.map((ileggelse) => {
      const ileggelsesdatoTil =
        ileggelse.ileggelsesdatoTil &&
        toNorwegianDatetime(ileggelse.ileggelsesdatoTil).format("DD.MM.YYYY");
      const ileggelsesdatoFraTid =
        ileggelse.ileggelsesdatoFra &&
        toNorwegianDatetime(ileggelse.ileggelsesdatoFra).format("HH:mm");
      const ileggelsesdatoTilTid =
        ileggelse.ileggelsesdatoTil &&
        ` - ${toNorwegianDatetime(ileggelse.ileggelsesdatoTil).format(
          "HH:mm"
        )}`;
      const klokkeslett = ileggelsesdatoFraTid + ileggelsesdatoTilTid;

      let data = [
        ileggelse?.ileggelsesnummer.toString(),
        ileggelsesdatoTil,
        klokkeslett,
        ileggelse?.tjenestenummer.toString(),
        ileggelseClientSpecificUtils.getOvertredelserString(
          ileggelse.overtredelse1,
          ileggelse.overtredelse2,
          ileggelse.overtredelse3
        ),
        ileggelse.kjennemerke,
        ileggelse.nasjon,
        ileggelse.gatenavn,
        ileggelse.gatenummer,
        showImageIcon(ileggelse),
      ];

      return {
        metadata: { isMakulert: ileggelse.isMakulert },
        data,
      };
    });
  }
);

const ileggelseQueryParameters = (state) =>
  state.forms.ileggelserOversiktFilterForm;

const getIleggelseQueryParameterStatusString = (ileggelseQueryParameters) => {
  let statuser = "";
  statuser =
    ileggelseQueryParameters.isVenter !== initIleggelseQueryParameters.isVenter
      ? `${statuser}Venter, `
      : statuser;
  statuser =
    ileggelseQueryParameters.isTilEttersendelse !==
    initIleggelseQueryParameters.isTilEttersendelse
      ? `${statuser}Til ettersendelse, `
      : statuser;
  statuser =
    ileggelseQueryParameters.isEttersendelseTilGodkjenning !==
    initIleggelseQueryParameters.isEttersendelseTilGodkjenning
      ? `${statuser}Ettersendelse til godkjenning, `
      : statuser;
  statuser =
    ileggelseQueryParameters.isEttersendt !==
    initIleggelseQueryParameters.isEttersendt
      ? `${statuser}Ettersendt, `
      : statuser;
  statuser =
    ileggelseQueryParameters.isKlarTilKemner !== // Skal stå som isKlarTilKemner tils endret på backend
    initIleggelseQueryParameters.isKlarTilKemner
      ? `${statuser}Klar til INE, `
      : statuser;
  statuser =
    ileggelseQueryParameters.isSendtKemner !== // Skal stå isSendtKemner tils backend er endret til isSendtIne
    initIleggelseQueryParameters.isSendtKemner
      ? `${statuser}Sendt til INE, `
      : statuser;
  statuser =
    ileggelseQueryParameters.isBetalt !== initIleggelseQueryParameters.isBetalt
      ? `${statuser}Betalt, `
      : statuser;

  return statuser ? statuser.slice(0, statuser.length - 2) : statuser;
};

export const getIleggelseQueryParameters = createSelector(
  [ileggelseQueryParameters],
  (ileggelseQueryParameters) => {
    let changedIleggelseQueryParameters = [];
    if (
      initIleggelseQueryParameters.ileggelsesnummer !==
      ileggelseQueryParameters.ileggelsesnummer
    ) {
      changedIleggelseQueryParameters = [
        ...changedIleggelseQueryParameters,
        {
          key: "Ileggelsesnummer",
          value: ileggelseQueryParameters.ileggelsesnummer,
        },
      ];
    }
    if (ileggelseQueryParameters.fraDato && ileggelseQueryParameters.tilDato) {
      changedIleggelseQueryParameters = [
        ...changedIleggelseQueryParameters,
        {
          key: "Dato",
          value: `${toNorwegianDatetime(
            ileggelseQueryParameters.fraDato
          ).format("DD.MM.YYYY HH:mm")} - ${toNorwegianDatetime(
            ileggelseQueryParameters.tilDato
          ).format("DD.MM.YYYY HH:mm")}`,
        },
      ];
    }
    if (
      initIleggelseQueryParameters.tjenestenummer !==
      ileggelseQueryParameters.tjenestenummer
    ) {
      changedIleggelseQueryParameters = [
        ...changedIleggelseQueryParameters,
        {
          key: "Tjenestenummer",
          value: ileggelseQueryParameters.tjenestenummer,
        },
      ];
    }
    if (
      initIleggelseQueryParameters.overtredelse !==
      ileggelseQueryParameters.overtredelse
    ) {
      changedIleggelseQueryParameters = [
        ...changedIleggelseQueryParameters,
        {
          key: "Overtredelsekode",
          value: ileggelseQueryParameters.overtredelse,
        },
      ];
    }
    if (
      initIleggelseQueryParameters.kjennemerke !==
      ileggelseQueryParameters.kjennemerke
    ) {
      changedIleggelseQueryParameters = [
        ...changedIleggelseQueryParameters,
        {
          key: "Kjennemerke",
          value: ileggelseQueryParameters.kjennemerke,
        },
      ];
    }
    if (
      initIleggelseQueryParameters.gatenavn !==
      ileggelseQueryParameters.gatenavn
    ) {
      changedIleggelseQueryParameters = [
        ...changedIleggelseQueryParameters,
        {
          key: "Gatenavn",
          value: ileggelseQueryParameters.gatenavn,
        },
      ];
    }
    if (initIleggelseQueryParameters.bydel !== ileggelseQueryParameters.bydel) {
      changedIleggelseQueryParameters = [
        ...changedIleggelseQueryParameters,
        {
          key: "Bydel",
          value: ileggelseQueryParameters.bydel,
        },
      ];
    }
    if (
      initIleggelseQueryParameters.nasjon !== ileggelseQueryParameters.nasjon
    ) {
      changedIleggelseQueryParameters = [
        ...changedIleggelseQueryParameters,
        {
          key: "nasjon",
          value: ileggelseQueryParameters.nasjon,
        },
      ];
    }
    if (
      initIleggelseQueryParameters.leveringstypeId !==
      ileggelseQueryParameters.leveringstypeId
    ) {
      changedIleggelseQueryParameters = [
        ...changedIleggelseQueryParameters,
        {
          key: "Leveringstype",
          value: ileggelseQueryParameters.leveringstypeId,
        },
      ];
    }
    if (initIleggelseQueryParameters.type !== ileggelseQueryParameters.type) {
      changedIleggelseQueryParameters = [
        ...changedIleggelseQueryParameters,
        {
          key: "Ileggelsestype",
          value: ileggelseQueryParameters.type,
        },
      ];
    }
    if (
      initIleggelseQueryParameters.isMakulert !==
      ileggelseQueryParameters.isMakulert
    ) {
      changedIleggelseQueryParameters = [
        ...changedIleggelseQueryParameters,
        {
          key: "Makulert",
          value: getNavnById(ileggelseQueryParameters.isMakulert, visAlleJaNei),
        },
      ];
    }
    if (getIleggelseQueryParameterStatusString(ileggelseQueryParameters)) {
      changedIleggelseQueryParameters = [
        ...changedIleggelseQueryParameters,
        {
          key: "Status",
          value: getIleggelseQueryParameterStatusString(
            ileggelseQueryParameters
          ),
        },
      ];
    }

    return changedIleggelseQueryParameters;
  }
);

export const getIleggelserInExcelFormat = createSelector(
  [ileggelserItems],
  (ileggelserItems) => {
    const data = ileggelserItems.map((ileggelse) => {
      const ileggelsesdatoTil =
        ileggelse.ileggelsesdatoTil &&
        toNorwegianDatetime(ileggelse.ileggelsesdatoTil).format("DD.MM.YYYY");

      let klokkeslett = "";
      if (ileggelse.ileggelsesdatoFra) {
        klokkeslett = toNorwegianDatetime(ileggelse.ileggelsesdatoFra).format(
          "HH:mm"
        );
      }
      if (ileggelse.ileggelsesdatoTil) {
        klokkeslett += ` - ${toNorwegianDatetime(
          ileggelse.ileggelsesdatoTil
        ).format("HH:mm")}`;
      }

      const overtredelseskoder =
        ileggelseClientSpecificUtils.getOvertredelserString(
          ileggelse.overtredelse1,
          ileggelse.overtredelse2,
          ileggelse.overtredelse3
        );

      if (authorized([...juridiskArray, bypatruljen.saksbehandleradmin])) {
        return {
          "Ilegg.nr.": ileggelse.ileggelsesnummer,
          "Ilegg.dato": ileggelsesdatoTil,
          Klokkeslett: klokkeslett,
          "Tjenestenr.": ileggelse.tjenestenummer,
          "Overt.kode": overtredelseskoder,
          "Kj.merke": ileggelse.kjennemerke,
          Gatenavn: ileggelse.gatenavn,
          "Gatenr.":
            (ileggelse.gatenummer || "") + " " + (ileggelse.oppgang || ""),
          Ved: ileggelse.vedGate,
          "Ved nr.": ileggelse.vedGatenummer,
          Bydel: ileggelse.bydel,
          Nasjon: ileggelse.nasjon,
          Klagekode: ileggelse.klageKode,
          Saksbehandler: ileggelse.saksbehandler,
          Type: ileggelse.type,
        };
      }
      return {
        "Ilegg.nr.": ileggelse.ileggelsesnummer,
        "Ilegg.dato": ileggelsesdatoTil,
        Klokkeslett: klokkeslett,
        "Tjenestenr.": ileggelse.tjenestenummer,
        "Overt.kode": overtredelseskoder,
        "Kj.merke": ileggelse.kjennemerke,
        Gatenavn: ileggelse.gatenavn,
        "Gatenr.":
          (ileggelse.gatenummer || "") + " " + (ileggelse.oppgang || ""),
        Ved: ileggelse.vedGate,
        "Ved nr.": ileggelse.vedGatenummer,
        Bydel: ileggelse.bydel,
        Nasjon: ileggelse.nasjon,
        Type: ileggelse.type,
      };
    });

    return [
      {
        name: "Ileggelser",
        data,
      },
    ];
  }
);

export default getIleggelseList;
