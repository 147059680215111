import React from "react";
import PropTypes from "prop-types";
import { Row, Col, FormGroup, FormLabel, FormControl } from "react-bootstrap";
import DropDown from "../../../Common/Forms/DropDown";
import DropDownListSearch from "../../../Common/Forms/DropdownListSearchable";
import TextInput from "../../../Common/Forms/TextInput";
import TextArea from "../../../Common/Forms/TextArea";
import LabelControl from "../../../Common/Forms/LabelControl";
import DatetimePicker from "../../../Common/Forms/DatetimePickerEnhanced";
import { toNorwegianDatetime } from "../../../../utils/datetimeUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

export const EndreIleggelseModalForm = (props) => {
  const getBetaltDato = () => {
    let betaltDato = "-";
    if (props.ileggelse.betaltDato) {
      betaltDato = toNorwegianDatetime(props.ileggelse.betaltDato).format(
        "DD.MM.YYYY"
      );
      betaltDato =
        betaltDato === "01.01.2000"
          ? "Ingen betaling"
          : toNorwegianDatetime(props.ileggelse.betaltDato).format(
              "DD.MM.YYYY HH:mm"
            );
    }

    return betaltDato;
  };

  return (
    <div>
      {props.warningText && (
        <Row>
          <Col xl={12}>
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              style={{ color: "#C12618" }}
              size="lg"
            />
            <p style={{ color: "#C12618" }}>{props.warningText}</p>
          </Col>
        </Row>
      )}
      {props.ileggelse &&
        props.ileggelse.kjoretoy &&
        props.ileggelse.kjoretoy.nasjon !== "Norge" && (
          <Row>
            <Col sm={12}>Denne motorvogn er ikke Norsk</Col>
          </Row>
        )}
      <Row>
        <Col sm={4}>
          <TextInput
            name="kjennemerke"
            label="Kjennemerke"
            value={props.kjennemerke}
            onChange={props.handleChange}
            disabled={
              props.ileggelse &&
              props.ileggelse.kjoretoy &&
              props.ileggelse.kjoretoy.nasjon !== "Norge"
            }
            validationError={
              props.validationError ? props.validationError.kjennemerke : ""
            }
          />
        </Col>
        <Col sm={4}>
          <button
            className="btn btn-default btn-bym-standard"
            style={{ marginTop: 33, minWidth: 190, whiteSpace: "nowrap" }}
            onClick={props.hentFraMotorvogn}
            disabled={
              props.ileggelse &&
              props.ileggelse.kjoretoy &&
              props.ileggelse.kjoretoy.nasjon !== "Norge"
            }
          >
            Hent Norsk motorvogn
          </button>
        </Col>
      </Row>
      <Row>
        <Col xl={4}>
          <LabelControl
            name="kjoretoytype"
            label="Kjøretøy type"
            text={props.kjoretoyType}
          />
        </Col>
        <Col xl={4}>
          <LabelControl
            name="fabrikkmerke"
            label="Fabrikkmerke"
            text={props.fabrikkmerke}
          />
        </Col>
        <Col xl={4}>
          <LabelControl
            name="annetFabrikkmerke"
            label="Annet fabrikkmerke"
            text={props.annetFabrikkMerke}
          />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col xl={3}>
          <TextInput
            name="gateNavn"
            label="Gatenavn"
            value={props.gateNavn}
            onChange={props.handleChange}
          />
        </Col>
        <Col xl={3}>
          <TextInput
            name="gateNummer"
            label="Gatenummer"
            value={props.gateNummer}
            onChange={props.handleChange}
          />
        </Col>
        <Col xl={3}>
          <TextInput
            label="Oppgang"
            value={props.oppgang}
            name="oppgang"
            onChange={props.handleChange}
          />
        </Col>
        <Col xl={3}>
          <TextInput
            name="bydel"
            label="Bydel"
            value={props.bydel}
            onChange={props.handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col xl={4}>
          <TextInput
            name="vedGate"
            label="Ved"
            value={props.vedGate}
            onChange={props.handleChange}
          />
        </Col>
        <Col xl={4}>
          <TextInput
            name="vedGateNummer"
            label="Ved nummer"
            value={props.vedGateNummer}
            onChange={props.handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col xl={4}>
          <Row style={{ paddingBottom: 15 }}>
            <Col xl={4}>
              <label htmlFor="overtredelseskoder1">Overtredelseskoder</label>
              <DropDownListSearch
                name="overtredelse1"
                placeholder="Søk etter kode"
                items={props.overtredelseskoder}
                selected={props.overtredelse1}
                handleChange={props.handleChange}
              />
            </Col>
            <Col xl={4}>
              <label htmlFor="overtredelse2" className="top--3px" />
              <DropDownListSearch
                name="overtredelse2"
                placeholder="Søk etter kode"
                title={"Velg"}
                items={[{ id: "", navn: "Velg" }].concat(
                  props.overtredelseskoder
                )}
                selected={props.overtredelse2}
                handleChange={props.handleChange}
              />
            </Col>
            <Col xl={4}>
              <label htmlFor="overtredelse3" className="top--3px" />
              <DropDownListSearch
                name="overtredelse3"
                placeholder="Søk etter kode"
                title={"Velg"}
                items={[{ id: "", navn: "Velg" }].concat(
                  props.overtredelseskoder
                )}
                selected={props.overtredelse3}
                handleChange={props.handleChange}
              />
            </Col>
          </Row>
        </Col>
        <Col xl={4}>
          <Row>
            <Col xl={6}>
              <label htmlFor="internkode1">Internkoder</label>
              <DropDown
                id="internkode1"
                name="internkode1"
                selected={props.internkode1}
                items={props.internkoder}
                onChange={props.handleChange}
              />
            </Col>
            <Col xl={6}>
              <label htmlFor="Internkoder" className="top--3px" />
              <DropDown
                id="internkode2"
                name="internkode2"
                title="Velg"
                selected={props.internkode2}
                items={props.internkoder}
                onChange={props.handleChange}
              />
            </Col>
          </Row>
        </Col>
        <Col xl={4}>
          <label htmlFor="leveringstype">Leveringstype</label>
          <DropDown
            id="leveringstype"
            name="leveringstype"
            selected={props.leveringstype}
            items={props.ileggelseleveringstyper}
            onChange={props.handleChange}
          />
        </Col>
      </Row>
      {props.showEttersendelsesrapportTextArea && (
        <Row>
          <Col xl={12}>
            <FormGroup
              validationState={
                props.ettersendelsesrapportValidationErrorText ? "error" : null
              }
            >
              <FormLabel>Ettersendelsesrapport</FormLabel>
              <FormControl
                name="ettersendelsesrapport"
                componentClass="textarea"
                onChange={props.handleChange}
              />
              {props.ettersendelsesrapportValidationErrorText && (
                <span className="validation-error">
                  {props.ettersendelsesrapportValidationErrorText}
                </span>
              )}
            </FormGroup>
          </Col>
        </Row>
      )}
      <Row>
        <Col xl={4}>
          <Row>
            <Col xl={6}>
              <TextInput
                name="antallMeter"
                label="Antall meter"
                value={props.antallMeter}
                onChange={props.handleChange}
              />
            </Col>
            <Col xl={6}>
              <TextInput
                name="belop"
                label="Beløp"
                type="number"
                value={props.belop}
                onChange={props.handleChange}
              />
            </Col>
          </Row>
        </Col>
        <Col xl={4}>
          <Row>
            <Col xl={6}>
              <DatetimePicker
                id="skiltFraTid"
                name="skiltFraTid"
                locale="nb"
                label="Underskilt klokkeslett fra"
                placeHolder="Velg tid"
                onChange={props.handleChange}
                value={props.skiltFraTid}
                validationError={props.fraTidValidationErrorText}
                dateFormat={false}
              />
            </Col>
            <Col xl={6}>
              <DatetimePicker
                id="skiltTilTid"
                name="skiltTilTid"
                locale="nb"
                label="Underskilt klokkeslett til"
                placeHolder="Velg tid"
                onChange={props.handleChange}
                value={props.skiltTilTid}
                validationError={props.tilTidValidationErrorText}
                dateFormat={false}
              />
            </Col>
          </Row>
        </Col>
        <Col xl={4}>
          <TextInput
            name="billettNummer"
            label="Billettnummer"
            value={props.billettNummer}
            onChange={props.handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col xl={6}>
          <TextArea
            name="internkommentar"
            label="Internkommentar"
            value={props.internkommentar}
            onChange={props.handleChange}
          />
        </Col>
        <Col xl={6}>
          <TextArea
            name="kommentarTilMottaker"
            label="Kommentar til mottaker"
            value={props.kommentarTilMottaker}
            onChange={props.handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col xl={4}>
          <LabelControl
            name="betaltkode"
            label="Betaltkode"
            text={props.betaltKode || "-"}
          />
        </Col>
        <Col xl={4}>
          <LabelControl
            name="overfortbetalingsdato"
            label="Overført betalingsdato"
            text={getBetaltDato()}
          />
        </Col>
        <Col xl={4}>
          <LabelControl
            name="kemnerDato"
            label="Inedato-ileggelse"
            text={
              (props.sendtTilKemnerDato &&
                toNorwegianDatetime(props.sendtTilKemnerDato).format(
                  "DD.MM.YYYY HH:mm"
                )) ||
              "-"
            }
          />
        </Col>
      </Row>
    </div>
  );
};

EndreIleggelseModalForm.propTypes = {
  warningText: PropTypes.string,
  leveringstype: PropTypes.number.isRequired,
  overtredelse1: PropTypes.number.isRequired,
  overtredelse2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  overtredelse3: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  internkode1: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  internkode2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showEttersendelsesrapportTextArea: PropTypes.bool,
  ettersendelsesrapport: PropTypes.string,
  ettersendelsesrapportValidationErrorText: PropTypes.string,
  betaltKode: PropTypes.number,
  betaltDato: PropTypes.string,
  sendtTilKemnerDato: PropTypes.string,
  ileggelseleveringstyper: PropTypes.array,
  overtredelseskoder: PropTypes.array,
  internkoder: PropTypes.array,
  handleChange: PropTypes.func.isRequired,
};

export default EndreIleggelseModalForm;
