import React, { Component } from "react";
import PropTypes from "prop-types";

import { Dropdown } from "react-bootstrap";

import Checkbox from "./CheckBoxEnhanced";
import TextFieldGroupClearIcon from "./TextFieldGroupClearIcon";

import { map, filter, some, size, includes } from "lodash";
import "./DropDownListMultiCheckboxOptions.css";
import classnames from "classnames";

class DropdownListSearchableMultiOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      toggle: false,
      items: [],
      selected: [],
    };
  }

  componentDidMount() {
    if (this.props.items) {
      this.setState({
        items: this.props.items,
        selected: this.props.selected,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { toggle, items } = this.state;

    if (prevProps.selected.length > 0 && this.props.selected.length === 0) {
      this.setState({ selected: [] });
    }

    if (prevState.items.length === 0 && items.length > 0) {
      for (let index = 0; index < items.length; index++) {
        const item = items[index];
        let elementItem = document.getElementById(item.id);
        if (elementItem) {
          elementItem.addEventListener("keydown", (event) =>
            this.eventListenerHandleCheckboxNavigation(event, index, items)
          );
        }
      }
    }

    if (
      prevState.toggle === false &&
      toggle === true &&
      items &&
      items.length > 0
    ) {
      var elementCheckbox = document.getElementById(items[0].id);
      if (elementCheckbox) {
        elementCheckbox.focus();
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.items !== nextProps.items) {
      this.setState({ items: nextProps.items });
    }
  }

  eventListenerHandleCheckboxNavigation = (event, index, items) => {
    let elementCheckbox;
    if (event.key === "ArrowUp" && index - 1 >= 0) {
      elementCheckbox = document.getElementById(items[index - 1].id);
    } else if (event.ket === "ArrowUp" && index - 1 < 0) {
      elementCheckbox = document.getElementById(items[items.length - 1].id);
    } else if (event.key === "ArrowDwon" && index + 1 < items.length) {
      elementCheckbox = document.getElementById(items[index + 1].id);
    } else if (event.key === "ArrowDwon" && index + 1 >= items.length) {
      elementCheckbox = document.getElementById(items[0].id);
    }

    if (elementCheckbox) {
      elementCheckbox.focus();
    }
  };

  onChangeOne = (e, v, name) => {
    const event = { target: { name: name, item: v, value: e.target.value } };
    this.props.onChangeOne && this.props.onChangeOne(event);
  };

  onChange = async (e, v, name) => {
    const { selectedType } = this.props;

    const target = e.target;
    const checked = target.value;

    let updateArray = [];
    if (selectedType && selectedType !== "") {
      if (checked) {
        updateArray = [...this.state.selected, v[selectedType]];
      } else {
        updateArray = filter(
          this.state.selected,
          (val) => val !== v[selectedType]
        );
      }
    } else {
      if (checked) {
        updateArray = [...this.state.selected, v];
      } else {
        updateArray = filter(this.state.selected, (val) => val.id !== v.id);
      }
    }

    this.setState({ selected: updateArray });
    const event = { target: { name: name, value: updateArray } };
    this.props.onChange && this.props.onChange(event);
  };

  onClear = (e, name) => {
    this.setState({ selected: [] });
    const event = { target: { name: name, value: [] } };
    this.props.onChange(event);
  };

  onChangeSearchText = (e) => {
    this.setState({ searchText: e.target.value });
  };

  clearSearchbox = () => {
    this.setState({ searchText: "" });
  };

  searchedItems(text) {
    if (text.length > 0) {
      return filter(this.state.items, (item) => {
        if (item.navn.toLowerCase().includes(text.toLowerCase())) return item;
      });
    } else {
      return this.state.items;
    }
  }

  onToggle = (isOpen) => {
    this.setState(() => ({ toggle: isOpen }));
  };

  getSelectedDropdownTitleClassName = () => {
    const { title } = this.props;
    const { toggle } = this.state;

    if (!toggle && title === "Velg") {
      return "multi-checkbox-options-toggle-placeholder-velg";
    }
    return undefined;
  };

  render() {
    const {
      title,
      label,
      id,
      name,
      simpleMode,
      textMapping,
      valueMapping,
      clearOptionsText,
      validationError,
      selectedType,
      isSearchable,
    } = this.props;
    const { searchText, selected } = this.state;
    const items = this.searchedItems(searchText);
    const sizeOfSelected = size(selected);
    return (
      <div
        className={classnames("multi-checkbox-select-dropdown-container", {
          "has-error": validationError,
        })}
      >
        {label && (
          <label className="control-label" htmlFor={id}>
            {" "}
            {label}{" "}
          </label>
        )}
        <Dropdown
          className="multi-checkbox-select-dropdown"
          id={id}
          name={name}
          onToggle={this.onToggle}
        >
          <Dropdown.Toggle
            style={sizeOfSelected > 0 ? { color: "#555555" } : {}}
            className={this.getSelectedDropdownTitleClassName()}
          >
            {title}
          </Dropdown.Toggle>
          <Dropdown.Menu className="multi-checkbox-select-dropdown-ul" id={id}>
            {isSearchable && (
              <TextFieldGroupClearIcon
                id={`${name}TextFieldGroupClearIcon`}
                onSearch={this.onChangeSearchText}
                onClear={this.clearSearchbox}
                value={searchText}
                searchIcon={true}
              />
            )}
            {sizeOfSelected > 0 && (
              <button
                className="btn-link"
                onClick={(e) => this.onClear(e, name)}
              >
                {clearOptionsText ? clearOptionsText : "Nullstill valg"}
              </button>
            )}
            {map(items, (item) => {
              const isChecked = selectedType
                ? includes(selected, item[selectedType])
                : some(selected, item);
              return (
                <li key={item.id}>
                  <Checkbox
                    className="menuItem"
                    id={item.id}
                    name={`checkbox-${item.navn}`}
                    text={textMapping ? item[textMapping] : item.navn}
                    value={valueMapping ? item[valueMapping] : item.id}
                    onChange={(e) =>
                      this.onChange(e, item, name) &&
                      this.onChangeOne(e, item, name)
                    }
                    checked={isChecked ? true : false}
                  />
                </li>
              );
            })}
            {!simpleMode && (
              <center>
                {" "}
                <button
                  onClick={this.onToggle}
                  className="btnClose btn btn-lg btn-default"
                >
                  Lukk
                </button>
              </center>
            )}
          </Dropdown.Menu>
        </Dropdown>
        {validationError && (
          <span className="help-block">{validationError}</span>
        )}
      </div>
    );
  }
}

DropdownListSearchableMultiOptions.defaultProps = {
  id: "",
  simpleMode: true,
  items: [],
};

DropdownListSearchableMultiOptions.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  onChangeOne: PropTypes.func,
  selected: PropTypes.any,
  name: PropTypes.string.isRequired,
  onClear: PropTypes.func,
  simpleMode: PropTypes.bool,
  isSearchable: PropTypes.bool,
  valueMapping: PropTypes.string,
  textMapping: PropTypes.string,
  clearOptionsText: PropTypes.string,
  validationError: PropTypes.string,
};

export default DropdownListSearchableMultiOptions;
