import React, { useEffect } from "react";
import PropTypes from "prop-types";

import InnsynMotorvognInfo from "./InnsynMotorvognInfo";

import { innsyn } from "../../constants/titles";

const asyncGetSingleMotorvognByQuery = async (
  params,
  getSingleMotorvognByQuery
) => getSingleMotorvognByQuery(params);

const InnsynMotorvognUtskrift = ({ motorvogn, getSingleMotorvognByQuery }) => {
  useEffect(() => {
    document.title = innsyn("motorvogn");
    const innsynMotorvognFilterFormString = window.sessionStorage.getItem(
      "printData"
    );
    window.sessionStorage.removeItem("printData");
    let innsynMotorvognFilterFormObject = undefined;
    if (typeof innsynMotorvognFilterFormString === "string") {
      innsynMotorvognFilterFormObject = JSON.parse(
        innsynMotorvognFilterFormString
      );
    }
    if (
      innsynMotorvognFilterFormObject &&
      innsynMotorvognFilterFormObject.kjennemerke
    ) {
      asyncGetSingleMotorvognByQuery(
        innsynMotorvognFilterFormObject,
        getSingleMotorvognByQuery
      );
    }
  }, [getSingleMotorvognByQuery]);

  useEffect(() => {
    if (motorvogn && motorvogn.kjennemerke) {
      window.print();
      window.close();
    }
  }, [motorvogn]);

  return (
    <div>
      <InnsynMotorvognInfo motorvogn={motorvogn} />
    </div>
  );
};

InnsynMotorvognUtskrift.propTypes = {
  motorvogn: PropTypes.object,
  getSingleMotorvognByQuery: PropTypes.func.isRequired,
};

export default InnsynMotorvognUtskrift;
