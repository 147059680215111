import * as miljokontrollerActions from "../actions/miljokontrollerActions";

const INITIAL_STATE = [];

export default function miljokontrollHistorikkReducer(
  state = [ ...INITIAL_STATE ],
  action
) {
  switch (action.type) {
    case miljokontrollerActions.GET_MILJOKONTROLL_HISTORIKK_SUCCESS:
      return action.payload;
    case miljokontrollerActions.GET_MILJOKONTROLL_HISTORIKK_FAILURE:
      return [...INITIAL_STATE ];
    default:
      return state;
  }
}
