import React from "react";
import { Switch, Redirect } from "react-router-dom";

import AuthorizedRoutes from "../components/Routes/AuthorizedRoutes";

import UregistrertePage from "../components/Uregistrerte/UregistrertePage";

import sitePath from "../constants/sitePaths";
import { bypatruljen, juridisk, innsyn } from "../constants/roles";

const Uregistrerte = () => (
  <Switch>
    <AuthorizedRoutes
      path={sitePath.uregistrerte.page}
      roles={[
        ...Object.values(bypatruljen),
        ...Object.values(juridisk),
        innsyn.vaktsentralen,
        innsyn.serviceavdeling,
      ]}
      component={UregistrertePage}
    />
    <Redirect
      path={sitePath.uregistrerte.shortUrl}
      to={sitePath.uregistrerte.kontroller.shortUrl}
    />
  </Switch>
);

export default Uregistrerte;
