import React from "react";
import { withRouter, Switch, Redirect } from "react-router-dom";

import AuthorizedRoutes from "../Routes/AuthorizedRoutes";
import NavigationBar from "../Common/NavigationBar";
import MiljogebyrerOversikt from "./Oversikt/OversiktContainer";
import TilBehandlingContainer from "./TilBehandling/TilBehandlingContainer";
import MiljokontrollOversiktPage from "./Miljokontroll/MiljokontrollOversiktPage";

import { authorized } from "../../utils/authorizationUtils";

import paths from "../../constants/sitePaths";
import {
  bypatruljen,
  juridisk,
  stedfortrederDriftsleder,
} from "../../constants/roles";

class MiljogebyrerPage extends React.Component {
  state = {
    activeNavigationItem: undefined,
  };

  getNavigationItems = () => {
    if (authorized([stedfortrederDriftsleder])) {
      return [
        {
          navn: "Miljøgebyroversikt",
          path: paths.miljogebyrer.oversikt.shortUrl,
        },
      ];
    } else if (
      authorized([
        bypatruljen.saksbehandleradmin,
        bypatruljen.saksbehandler,
        bypatruljen.driftleder,
        juridisk.saksbehandler,
      ])
    ) {
      return [
        {
          navn: "Miljøgebyroversikt",
          path: paths.miljogebyrer.oversikt.shortUrl,
        },
        {
          navn: "Til behandling",
          path: paths.miljogebyrer.tilBehandling.shortUrl,
        },
        {
          navn: "Tidskontrolloversikt",
          path: paths.miljogebyrer.miljokontrolloversikt.shortUrl,
        },
      ];
    } else if (
      authorized([...Object.values(bypatruljen), ...Object.values(juridisk)])
    ) {
      return [
        {
          navn: "Miljøgebyroversikt",
          path: paths.miljogebyrer.oversikt.shortUrl,
        },
        {
          navn: "Til behandling",
          path: paths.miljogebyrer.tilBehandling.shortUrl,
        },
        {
          navn: "Tidskontrolloversikt",
          path: paths.miljogebyrer.miljokontrolloversikt.shortUrl,
        },
      ];
    }
  };

  render() {
    return (
      <div>
        <NavigationBar navigationItems={this.getNavigationItems()} />
        <Switch>
          <AuthorizedRoutes
            exact
            path={paths.miljogebyrer.oversikt.shortUrl}
            roles={[...Object.values(bypatruljen), ...Object.values(juridisk)]}
            component={MiljogebyrerOversikt}
          />
          <AuthorizedRoutes
            exact
            path={paths.miljogebyrer.tilBehandling.shortUrl}
            roles={[...Object.values(bypatruljen), ...Object.values(juridisk)]}
            component={TilBehandlingContainer}
          />
          <AuthorizedRoutes
            exact
            path={paths.miljogebyrer.miljokontrolloversikt.shortUrl}
            roles={[...Object.values(bypatruljen), ...Object.values(juridisk)]}
            component={MiljokontrollOversiktPage}
          />
          <Redirect
            path={paths.miljogebyrer.shortUrl}
            to={paths.miljogebyrer.oversikt.shortUrl}
          />
        </Switch>
      </div>
    );
  }
}

export default withRouter(MiljogebyrerPage);
