import React, { useState, useEffect } from "react";

import Table from "../../Common/Tables/BaseTable";
import InformationButton from "../../Common/InformationButton";

import { formatDateTimeHavingNorwegianDateTime } from "../../../utils/datetimeUtils";
import { numberWithSpace } from "../../../utils/stringUtils";

const antallIleggelserPerIleggelsetypeRapportToObject = (
  antallIleggelserPerIleggelsetypeRapport,
  submitTimestamp
) => {
  let antallIleggelserPerIleggelsetypeRapportObject = {};
  for (
    let index = 0;
    index < antallIleggelserPerIleggelsetypeRapport.length;
    index++
  ) {
    const element = antallIleggelserPerIleggelsetypeRapport[index];
    antallIleggelserPerIleggelsetypeRapportObject = {
      ...antallIleggelserPerIleggelsetypeRapportObject,
      [element.kode]: {
        navn: element.navn,
        kode: element.kode,
        antall: element.antall,
      },
    };
  }
  if (!antallIleggelserPerIleggelsetypeRapportObject.G && submitTimestamp) {
    antallIleggelserPerIleggelsetypeRapportObject = {
      ...antallIleggelserPerIleggelsetypeRapportObject,
      G: { navn: "Gebyr", kode: "G", antall: 0 },
    };
  }
  if (!antallIleggelserPerIleggelsetypeRapportObject.V && submitTimestamp) {
    antallIleggelserPerIleggelsetypeRapportObject = {
      ...antallIleggelserPerIleggelsetypeRapportObject,
      V: { navn: "Vilkårsparkering/Kontrollsanksjon", kode: "V", antall: 0 },
    };
  }
  if (!antallIleggelserPerIleggelsetypeRapportObject.M && submitTimestamp) {
    antallIleggelserPerIleggelsetypeRapportObject = {
      ...antallIleggelserPerIleggelsetypeRapportObject,
      M: { navn: "Miljøgebyr", kode: "M", antall: 0 },
    };
  }
  if (!antallIleggelserPerIleggelsetypeRapportObject.P && submitTimestamp) {
    antallIleggelserPerIleggelsetypeRapportObject = {
      ...antallIleggelserPerIleggelsetypeRapportObject,
      P: { navn: "Tilleggsgebyr piggdekk", kode: "P", antall: 0 },
    };
  }
  return antallIleggelserPerIleggelsetypeRapportObject;
};

const showTotal = (ileggelsetype) => {
  if (
    ileggelsetype.G ||
    ileggelsetype.V ||
    ileggelsetype.M ||
    ileggelsetype.P ||
    ileggelsetype.A
  ) {
    return true;
  }

  return false;
};

const getTotal = (ileggelsetype) => {
  let total = 0;
  total =
    ileggelsetype.G && (ileggelsetype.G.antall || ileggelsetype.G.antall === 0)
      ? total + ileggelsetype.G.antall
      : total + 0;
  total =
    ileggelsetype.V && (ileggelsetype.V.antall || ileggelsetype.V.antall === 0)
      ? total + ileggelsetype.V.antall
      : total + 0;
  total =
    ileggelsetype.M && (ileggelsetype.M.antall || ileggelsetype.M.antall === 0)
      ? total + ileggelsetype.M.antall
      : total + 0;
  total =
    ileggelsetype.P && (ileggelsetype.P.antall || ileggelsetype.P.antall === 0)
      ? total + ileggelsetype.P.antall
      : total + 0;
  total =
    ileggelsetype.A && (ileggelsetype.A.antall || ileggelsetype.A.antall === 0)
      ? total + ileggelsetype.A.antall
      : total + 0;

  return total;
};

const AntallIleggelserPerIleggelsetypeList = ({
  antallIleggelserPerIleggelsetypeRapport,
  antallIleggelserPerIleggelsetypeSubmittedForm,
}) => {
  const [ileggelsetype, setIleggelsetype] = useState({});
  const [showInformation, setShowInformation] = useState(false);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  const headers = ["Ileggelsetype", "Antall"];

  useEffect(() => {
    window.addEventListener("resize", resizeEventListener);

    return function cleanUp() {
      window.removeEventListener("resize", resizeEventListener);
    };
  }, []);

  useEffect(() => {
    setIleggelsetype(
      antallIleggelserPerIleggelsetypeRapportToObject(
        antallIleggelserPerIleggelsetypeRapport,
        antallIleggelserPerIleggelsetypeSubmittedForm.submitTimestamp
      )
    );
  }, [
    antallIleggelserPerIleggelsetypeRapport,
    antallIleggelserPerIleggelsetypeSubmittedForm.submitTimestamp,
  ]);

  const resizeEventListener = () => {
    setInnerWidth(window.innerWidth);
  };

  return (
    <>
      <h3
        style={
          innerWidth > 767
            ? { fontSize: 16, fontWeight: "bold" }
            : {
                fontSize: 16,
                fontWeight: "bold",
                marginRight: 20,
                marginLeft: 20,
              }
        }
      >
        Antall ileggelser per ileggelsestype{" "}
        <InformationButton
          onClick={() => setShowInformation(!showInformation)}
        />
      </h3>
      {showInformation && (
        <div
          style={
            innerWidth > 767
              ? {}
              : { marginRight: 20, marginLeft: 20, whiteSpace: "normal" }
          }
        >
          <i>Filtrerer på når ileggelsen ble utstedt av bybetjenten</i>
          <br />
          <i>
            <b>Ileggelsestype:</b> De ulike typer ileggelser som finnes
          </i>
          <br />
          <i>
            <b>Antall:</b> Antall ileggelser som ikke er makulert
          </i>
        </div>
      )}
      <div className="only-print">
        <div>
          Dato:{" "}
          {`${formatDateTimeHavingNorwegianDateTime(
            antallIleggelserPerIleggelsetypeSubmittedForm.fraDato
          )} -
            ${formatDateTimeHavingNorwegianDateTime(
              antallIleggelserPerIleggelsetypeSubmittedForm.tilDato
            )}`}
        </div>
        <div>
          Timestamp:{" "}
          {formatDateTimeHavingNorwegianDateTime(
            antallIleggelserPerIleggelsetypeSubmittedForm.submitTimestamp
          )}
        </div>
      </div>
      <div
        className="table-wrapper-horizontal-scroll"
        style={{ maxWidth: 450 }}
      >
        <Table
          tdStyle={{
            headerName: "Antall",
            style: { textAlign: "right", paddingRight: 30 },
          }}
          headerRow={headers}
          striped
        >
          <tbody>
            {ileggelsetype.G && (
              <tr>
                <td>{`${ileggelsetype.G.navn} (${ileggelsetype.G.kode})`}</td>
                <td style={{ textAlign: "right", paddingRight: 30 }}>
                  {numberWithSpace(ileggelsetype.G.antall)}
                </td>
              </tr>
            )}
            {ileggelsetype.V && (
              <tr>
                <td>{`${ileggelsetype.V.navn} (${ileggelsetype.V.kode})`}</td>
                <td style={{ textAlign: "right", paddingRight: 30 }}>
                  {numberWithSpace(ileggelsetype.V.antall)}
                </td>
              </tr>
            )}
            {ileggelsetype.M && (
              <tr>
                <td>{`${ileggelsetype.M.navn} (${ileggelsetype.M.kode})`}</td>
                <td style={{ textAlign: "right", paddingRight: 30 }}>
                  {numberWithSpace(ileggelsetype.M.antall)}
                </td>
              </tr>
            )}
            {ileggelsetype.P && (
              <tr>
                <td>{`${ileggelsetype.P.navn} (${ileggelsetype.P.kode})`}</td>
                <td style={{ textAlign: "right", paddingRight: 30 }}>
                  {numberWithSpace(ileggelsetype.P.antall)}
                </td>
              </tr>
            )}
            {ileggelsetype.A && (
              <tr>
                <td>{`${ileggelsetype.A.navn} (${ileggelsetype.A.kode})`}</td>
                <td style={{ textAlign: "right", paddingRight: 30 }}>
                  {numberWithSpace(ileggelsetype.A.antall)}
                </td>
              </tr>
            )}
            {showTotal(ileggelsetype) && (
              <tr className="RapportListTotalRow">
                <td>Total</td>
                <td style={{ textAlign: "right", paddingRight: 30 }}>
                  {numberWithSpace(getTotal(ileggelsetype))}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default AntallIleggelserPerIleggelsetypeList;
