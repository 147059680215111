import React from "react";
import { Button } from "react-bootstrap";

export const OpenMakulerModalStandardButton = (props) => (
  <Button
    className="btn btn-bym-standard"
    style={{ marginLeft: 20 }}
    onClick={props.openModal}
  >
    Makuler
  </Button>
);

export default OpenMakulerModalStandardButton;
