//import XLSX from "xlsx";
import FileSaver from "file-saver";
import b64toBlob from "b64-to-blob";

export const mimeTypes = {
  gif: "image/gif",
  jpeg: "image/jpeg",
  jpg: "image/jpg",
  png: "image/png",
  svg: "image/svg+xml",
  wave: "audio/wave",
  pdf: "application/pdf",
  doc: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.documen",
  xls: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};

export function FormatToMonthAndYear(month, year) {
  if (month && year) {
    const optionsDate = { year: "numeric", month: "long" };
    const date = new Date(year, month);
    var d = date.toLocaleString("nb-NO", optionsDate);
    return d.substr(0, 1).toString().toUpperCase() + d.substring(1);
  }
}
export function createFileName(filename, month, year, extension) {
  const dato = FormatToMonthAndYear(
    parseInt(month, 10) - 1,
    parseInt(year, 10)
  );
  return `${filename}_${dato}_Rapport.${extension}`;
}

export const stringToArrayBuffer = (s) => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (var i = 0; i !== s.length; ++i) {
    view[i] = s.charCodeAt(i) & 0xff;
  }
  return buf;
};
//saves csv file from response
export function downloadCSVRapport(response, filename) {
  if (!response) return null;
  try {
    const data = response.data;
    const s2ab = this.stringToArrayBuffer(data);
    const blob = new Blob([s2ab], { type: "application/octet-stream" });
    FileSaver.saveAs(blob, filename);
  } catch (err) {
    throw ("Feil ved lagring av csv fil.\n", err);
  }
}
// saves/downloads excel (xlsx) file from response
export function downloadExcelStream(response, filename) {
  if (!response) return null;
  try {
    const excelMimeType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const contentType = response.headers["content-type"]
      ? response.headers["content-type"]
      : excelMimeType;
    const data = response.data;
    const blob = new Blob([data], { type: contentType });
    FileSaver.saveAs(blob, filename);
  } catch (err) {
    throw ("Feil ved lagring av excel fil.\n", err);
  }
}
// saves/downloads images file from response
export function downloadBase64(response) {
  if (!response) return null;
  try {
    const base64 = response.base64;
    const contentType = response.contentType;
    const filename = response.filename;
    const blob = b64toBlob(base64, contentType);
    FileSaver.saveAs(blob, filename);
  } catch (err) {
    throw ("Feil ved lagring av excel fil.\n", err);
  }
}

export const createObjectUrlToDownloadGeneralFile = (response, fileName) => {
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style.visibility = "hidden";
  try {
    const contentType = response.headers["content-type"];
    const data = response.data;
    const blob = new Blob([data], { type: contentType });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    document.body.removeChild(a);
  } catch (err) {
    throw ("Feil ved lagring av fil.\n", err);
  }
};

export const OpenBase64InNewTab = (
  base64,
  fileName,
  contentType,
  fileformat
) => {
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style.visibility = "hidden";
  try {
    const mimeType = contentType
      ? contentType
      : mimeTypes[fileformat.toLowerCase()];
    if (mimeType && base64 !== "") {
      const blob = b64toBlob(base64, mimeType);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.target = "_blank";
      a.click();
      document.body.removeChild(a);
    }
  } catch (err) {
    throw ("Feil ved lagring av fil.\n", err);
  }
};
