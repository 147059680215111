import { connect } from "react-redux";
import Folgebrev from "./Folgebrev";

import { getFolgebrevWithParams as getFolgebrevWithParamsAction } from "../../../actions/folgebrevActions";
import {
  getMiljogebyr as getMiljogebyrAction,
  putMiljogebyrer as putMiljogebyrerAction,
  getMiljogebyrerHistorikk as getMiljogebyrerHistorikkAction,
} from "../../../actions/miljogebyrerActions";

const mapStateToProps = (state, ownProps) => ({
  miljogebyrId: ownProps.miljogebyrId,
  miljogebyr: state.miljogebyr,
  folgebrev: state.folgebrev,
});

const mapDispatchToProps = (dispatch) => ({
  getFolgebrev: (params) => dispatch(getFolgebrevWithParamsAction(params)),
  getMiljogebyr: (miljogebyrId) => dispatch(getMiljogebyrAction(miljogebyrId)),
  putMiljogebyrer: (miljogebyrId, params) =>
    dispatch(putMiljogebyrerAction(miljogebyrId, params)),
  getMiljogebyrerHistorikk: (miljogebyrId) =>
    dispatch(getMiljogebyrerHistorikkAction(miljogebyrId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Folgebrev);
