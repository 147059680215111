import {
  handleChangeActionType,
  handleChangeAllActionType,
  handleChangeToInitStateActionType
} from "../../actions/handleChangeAction";

export const INITIAL_STATE = {};

export default function klageVedtakerFormReducer(
  state = { ...INITIAL_STATE },
  action
) {
  switch (action.type) {
    case handleChangeActionType("KLAGE_VEDTAKER_FORM"):
      return { ...state, [action.variableName]: action.payload };
    case handleChangeAllActionType("KLAGE_VEDTAKER_FORM"):
      return { ...action.payload };
    case handleChangeToInitStateActionType("KLAGE_VEDTAKER_FORM"):
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}
