import { actionTypeAntallIleggelserPerIleggelsetype } from "../../actions/rapporter/antallIleggelserPerIleggelsetypeActions";
import { CreateNewReducer } from "../../utils/reducerUtils";

const initialState = [];
export const antallIleggelserPerIleggelsetypeRapportReducer = (
  state = [...initialState],
  action
) =>
  CreateNewReducer(state, action, actionTypeAntallIleggelserPerIleggelsetype);

export default antallIleggelserPerIleggelsetypeRapportReducer;
