// import moment from "moment";
// eslint-disable-next-line
import { tz } from "moment-timezone";
import _ from "lodash";
import {
  handleChangeActionType,
  handleChangeToInitStateActionType,
} from "../../actions/handleChangeAction";

export const INITIAL_STATE = {
  // input
  aktorId: "",
  ileggelsesnummer: "",
  tjenestenummer: "",
  fraDato: "",
  fraTid: "",
  tilDato: "",
  tilTid: "",
  isMakulert: false,

  isElsparksykkelGebyr: true,
  include: ["ettersendelse", "rettelse"],

  validationError: {
    aktorId: null,
    ileggelsesnummer: null,
    tjenestenummer: null,
    fraDatoValidationErrorText: null,
    fraTidValidationErrorText: null,
    tilDatoValidationErrorText: null,
    tilTidValidationErrorText: null,
    isMakulertValidationErrorText: null,
  },
};

export default function elsparksykkelGebyrFilterFormReducer(
  state = {
    ...INITIAL_STATE,
    validationError: { ...INITIAL_STATE.validationError },
  },
  action
) {
  switch (action.type) {
    case handleChangeActionType("ELSPARKSYKKEL_GEBYR_FILTER_FORM"):
      _.set(state, action.variableName, action.payload);
      return { ...state };
    case handleChangeToInitStateActionType("ELSPARKSYKKEL_GEBYR_FILTER_FORM"):
      return {
        ...INITIAL_STATE,
        validationError: { ...INITIAL_STATE.validationError },
      };
    default:
      return state;
  }
}
