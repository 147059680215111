export const replaceWhitespacesWithEmptyStrings = (value) =>
  typeof value === "string" ? value.replace(/\s/g, "") : null;

export const sanitizeHtml = (str, acceptedElementArray) => {
  const doc = new DOMParser().parseFromString(str, 'text/html');
  const nodes = doc.body.childNodes;
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];
    // if (node.nodeType === 1 && node.tagName.toLowerCase() !== 'br') {
    if (node.nodeType === 1 && acceptedElementArray.some(ae => ae !== node.tagName.toLowerCase())) {
      node.parentNode.replaceChild(document.createTextNode(node.outerHTML), node);
    }
  }

  return doc.body.innerHTML;
}
