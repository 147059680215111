import React from "react";
import { connect } from "react-redux";
import SendTilIne from "../../../Common/Detalj/SendTilIne";
import { getIleggelse as getIleggelseAction } from "../../../../actions/ileggelserActions";
import { putIleggelserSettKlarTilIne as putIleggelserSettKlarTilIneAction } from "../../../../actions/ileggelserActions";

class SendTilIneContainer extends React.Component {
  render() {
    const { ileggelse } = this.props;
    if (!ileggelse.isMakulert && ileggelse.isFeiletKemner) {
      return <SendTilIne {...this.props} />;
    }
    return <span />;
  }
}

const mapStateToProps = (state) => {
  return {
    ileggelse: state.ileggelse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getIleggelse: (ileggelseId) => dispatch(getIleggelseAction(ileggelseId)),
    putIleggelserSettKlarTilIne: (ileggelseId) =>
      dispatch(putIleggelserSettKlarTilIneAction(ileggelseId)),
  };
};

const mergeProps = (stateProps, dispatchProps) => {
  return {
    ileggelse: stateProps.ileggelse,
    putIleggelserSettKlarTilIne: () =>
      dispatchProps
        .putIleggelserSettKlarTilIne(stateProps.ileggelse.id)
        .then(() => dispatchProps.getIleggelse(stateProps.ileggelse.id))
        .catch(),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(SendTilIneContainer);
