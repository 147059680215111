import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getVentilstillingBilde } from "../../../actions/ventilstillingerActions";
import SpinLoader from "../../Common/SpinLoader";

const spinnerLoaderProps = {
  height: 42,
  width: 42,
  thickness: 3,
  pColor: "#ffffff",
  sColor: "#4d4d4d",
};

const VentilstillingBildevisning = (props) => {
  const [bilde, setBilde] = useState(undefined);
  const [showSpinner, setShowSpinner] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setShowSpinner(true);
    dispatch(
      getVentilstillingBilde(
        props.computedMatch.params.id,
        props.computedMatch.params.bildeId
      )
    )
      .then((result) => {
        setShowSpinner(false);
        setBilde(result);
      })
      .catch(() => setShowSpinner(false));
  }, [
    dispatch,
    props.computedMatch.params.id,
    props.computedMatch.params.bildeId,
  ]);

  return (
    <div>
      {showSpinner && (
        <div style={{ margin: "auto", paddingTop: 50, width: 0 }}>
          <SpinLoader {...spinnerLoaderProps} />
        </div>
      )}
      {!showSpinner && (
        <img
          src={bilde?.src}
          alt={`{Ventillstilling ${props.computedMatch.params.id} - orginalbilde" }`}
        />
      )}
    </div>
  );
};

export default VentilstillingBildevisning;
