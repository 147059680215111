import React from "react";
import { withRouter, Switch, Redirect } from "react-router-dom";

import AuthorizedRoutes from "../Routes/AuthorizedRoutes";
import UregistrerteKontrollerPage from "./Kontroller/UregistrerteKontrollerPage";
import UregistrerteDetaljPage from "./Detalj/UregistrerteDetaljPage";

import paths from "../../constants/sitePaths";
import { bypatruljen, juridisk, innsyn } from "../../constants/roles";

class UregistrertePage extends React.Component {
  render() {
    return (
      <div>
        <Switch>
          <AuthorizedRoutes
            exact
            path={paths.uregistrerte.kontroller.shortUrl}
            roles={[
              ...Object.values(bypatruljen),
              ...Object.values(juridisk),
              innsyn.vaktsentralen,
              innsyn.serviceavdeling,
            ]}
            component={UregistrerteKontrollerPage}
          />
          <AuthorizedRoutes
            exact
            path={paths.uregistrerte.kontroller.detalj.shortUrl.routeMatcher}
            roles={[
              ...Object.values(bypatruljen),
              ...Object.values(juridisk),
              innsyn.vaktsentralen,
              innsyn.serviceavdeling,
            ]}
            component={UregistrerteDetaljPage}
          />
          <Redirect
            path={paths.uregistrerte.shortUrl}
            to={paths.uregistrerte.kontroller.shortUrl}
          />
        </Switch>
      </div>
    );
  }
}

export default withRouter(UregistrertePage);
