import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import map from "lodash/map";

import Table from "../../Common/Tables/BaseTable";
import BybetjenterUpdateModal from "./UpdateModals/Bybetjent";
import BypatruljeteamHistorikkModal from "./BypatruljeTeamHistorikk";
import {
  toNorwegianDatetime,
  toNorwegianDateFormat,
} from "../../../utils/datetimeUtils";
import { getBybetjenter } from "../../../actions/bybetjenterActions";
import * as title from "../../../constants/titles";

class BybetjentList extends Component {
  static propTypes = {
    authorizedToCreateAndEdit: PropTypes.bool.isRequired,
    bybetjenter: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        nummer: PropTypes.number,
        navn: PropTypes.string,
        mobilnummer: PropTypes.string,
        epost: PropTypes.string,
        rolle: PropTypes.object,
        seksjon: PropTypes.object,
        team: PropTypes.array,
        startDato: PropTypes.object,
        sluttDato: PropTypes.object,
      })
    ),
  };

  headerRow = [
    "Tjenestesnummer",
    "Pulsnummer",
    "Team",
    "Navn",
    "Mobilnummer",
    "E-post",
    "Start dato",
    "Slutt dato",
    "Rolle",
    "Seksjon",
    "",
  ];

  constructor(props) {
    super(props);
    this.renderRow = this.renderRow.bind(this);
  }
  componentDidMount() {
    document.title = title.grunndataBupatruljen;
    this.props.getBybetjenter();
  }

  renderBetjentTeam = (team) => {
    if (team && team.length > 0) {
      const arr = team.map((i) => i.navn);
      return arr.map((item) => (
        <div key={item}>
          {item}
          <br />
        </div>
      ));
    }
    return "";
  };

  renderRow = () => {
    return map(this.props.bybetjenter, (bybetjent) => (
      <tr key={`bybetjenterTableBodyTr${bybetjent.id}`}>
        <td>
          <BypatruljeteamHistorikkModal
            bybetjentId={bybetjent.id}
            tjenestenummer={bybetjent.nummer}
            team={bybetjent.teamer}
          />
        </td>
        <td>{bybetjent.pulsNummer}</td>
        <td>{this.renderBetjentTeam(bybetjent.team)}</td>
        <td>{bybetjent.navn}</td>
        <td>{bybetjent.mobil}</td>
        <td>{bybetjent.epost}</td>
        <td>
          {toNorwegianDateFormat(toNorwegianDatetime(bybetjent.aktivertDato))}
        </td>
        <td>
          {toNorwegianDateFormat(toNorwegianDatetime(bybetjent.deaktivertDato))}
        </td>
        <td>{bybetjent.rolle ? bybetjent.rolle.navn : ""}</td>
        <td>{bybetjent.seksjon ? bybetjent.seksjon.navn : ""}</td>
        <td>
          {this.props.authorizedToCreateAndEdit && (
            <BybetjenterUpdateModal
              id={bybetjent.id}
              nummer={bybetjent.nummer}
              pulsNummer={bybetjent.pulsNummer}
              team={bybetjent.team}
              navn={bybetjent.navn}
              mobil={bybetjent.mobil}
              epost={bybetjent.epost}
              rolle={bybetjent.rolle ? bybetjent.rolle.id : 0}
              seksjon={bybetjent.seksjon ? bybetjent.seksjon.id : 0}
              aktivertDato={bybetjent.aktivertDato}
              deaktivertDato={bybetjent.deaktivertDato}
            />
          )}
        </td>
      </tr>
    ));
  };

  render() {
    return (
      <Table
        id="bybetjent-liste"
        className="table-wrapper-horizontal-scroll-white-space-normal"
        headerRow={this.headerRow}
        defaultStyle
        striped
      >
        <tbody>{this.renderRow()}</tbody>
      </Table>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bybetjenter: state.bybetjenter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getBybetjenter }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(BybetjentList);
