import React, { useEffect } from "react";

import MiljokontrollOversiktFilterContainer from "./MiljokontrollOversiktFilterContainer";
import MiljokontrollOversiktListContainer from "./MiljokontrollOversiktListContainer";

import { miljogebyr as miljogebyrTitles } from "../../../constants/titles";

const MiljokontrollOversikt = () => {
  useEffect(() => {
    document.title = miljogebyrTitles.tidskontroll;
  }, []);

  return (
    <>
      <MiljokontrollOversiktFilterContainer />
      <MiljokontrollOversiktListContainer />
    </>
  );
};

export default MiljokontrollOversikt;
