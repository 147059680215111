import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { Table } from "./Tables";
import { setSort } from "../../actions/advancedListActions";

const mapStateToProps = (state, ownProps) => {
  const advList = state[ownProps.storeKey];
  return {
    sort: advList.sort,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({ setSort }, dispatch);
};

const mergeProps = (stateProps, dispatchProps, { storeKey, ...ownProps }) => {
  return {
    ...ownProps,
    ...stateProps,
    onSort: (value) => dispatchProps.setSort(storeKey, value),
  };
};

const SortableTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Table);

SortableTableContainer.propTypes = {
  storeKey: PropTypes.string.isRequired,
};

export default SortableTableContainer;
