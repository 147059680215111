import { connect } from "react-redux";

import ImageEditorPage from "../../Detalj/ImageEditorPage";

import {
  getMiljogebyrBildeBase64,
  postMiljogebyrerBilder,
} from "../../../../actions/miljogebyrerActions";
import {
  getMiljokontrollBildeBase64,
  postMiljokontrollObservasjonBilde,
} from "../../../../actions/miljokontrollerActions";

const mapDispatchToProps = (dispatch, ownProps) => {
  const miljogebyrId = ownProps.computedMatch.params.id;
  const miljokontrollId = ownProps.computedMatch.params.miljokontrollId;
  const observasjonId = ownProps.computedMatch.params.observasjonId;
  const bildeId = ownProps.computedMatch.params.bildeId;

  if (miljokontrollId && observasjonId) {
    return {
      getBilde: () =>
        dispatch(
          getMiljokontrollBildeBase64(miljokontrollId, observasjonId, bildeId)
        ),
      postIleggelserBilder: (unUsed, file) =>
        dispatch(
          postMiljokontrollObservasjonBilde(
            miljokontrollId,
            observasjonId,
            file,
            bildeId
          )
        ),
    };
  } else {
    return {
      getBilde: () => dispatch(getMiljogebyrBildeBase64(miljogebyrId, bildeId)),
      postIleggelserBilder: (unUsed, file) =>
        dispatch(postMiljogebyrerBilder(miljogebyrId, file, true, true)),
    };
  }
};

export default connect(null, mapDispatchToProps)(ImageEditorPage);
