import moment from "moment";
import { toNorwegianDatetime } from "../utils/datetimeUtils";
import { createSelector } from "reselect";

const miljogebyr = (state) => state.miljogebyr;

export const getGridTableRowData = createSelector(miljogebyr, (miljogebyr) => {
  return [
    [
      {
        name: "Registrert i HGK",
        value: moment(toNorwegianDatetime(miljogebyr.opprettetDato)).format(
          "DD.MM.YYYY HH:mm"
        ),
        description: "",
      },
      {
        name: "Opprettet i Pocket Control",
        value: moment(toNorwegianDatetime(miljogebyr.ileggelsesdatoFra)).format(
          "DD.MM.YYYY HH:mm"
        ),
        description: "",
      },
    ],
  ];
});

export default getGridTableRowData;
