import React from "react";
import PropTypes from "prop-types";
import LoadButton from "../../Common/LoadButton";
import DropDown from "../../Common/Forms/DropDown";
import DatetimePicker from "../../Common/Forms/DatetimePickerEnhanced";
import DropDownListSearch from "../../Common/Forms/DropdownListSearchable";
import DropDownListMultiCheckboxOptions from "../../Common/Forms/DropDownListMultiCheckboxOptions";
import TextInput from "../../Common/Forms/TextInput";
import { authorized } from "../../../utils/authorizationUtils";
import { visAlleJaNei } from "../../../constants/globals";
import {
  juridisk,
  bypatruljen,
  stedfortrederDriftsleder,
} from "../../../constants/roles";

export default class FilterForm extends React.Component {
  static propTypes = {
    showSokSpinner: PropTypes.bool,
    miljogebyrFilterState: PropTypes.shape({
      ileggelsesnummer: PropTypes.string,
      fraDato: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      tilDato: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      fraTid: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      tilTid: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      tjenestenummer: PropTypes.string,
      mottaker: PropTypes.string,
      gatenavn: PropTypes.string,
      gatenummer: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      leveringstype: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      overtredelseskode: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      selectedStatuser: PropTypes.PropTypes.arrayOf(
        PropTypes.shape({
          navn: PropTypes.string.isRequired,
          id: PropTypes.string.isRequired,
        })
      ),
    }),
    overtredelseskodeMiljogebyr: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        kode: PropTypes.string,
        kortTekst: PropTypes.string,
        aktiv: PropTypes.bool,
      })
    ),
    betjentgrupper: PropTypes.array,
    miljogebyrStatuser: PropTypes.array,
    saksnummer: PropTypes.string,
    vedtakskoder: PropTypes.array,
    klagekoder: PropTypes.array,
    handleChange: PropTypes.func,
    handleChangeToInitState: PropTypes.func,
    handleSubmit: PropTypes.func,
  };

  state = {
    authorizedOverforingsdatoIne: authorized([bypatruljen.saksbehandleradmin]),
    authorizedSaksnummer: authorized([
      ...Object.values(juridisk),
      bypatruljen.saksbehandleradmin,
    ]),
    authorizedVedtagskode: authorized([
      ...Object.values(juridisk),
      bypatruljen.saksbehandleradmin,
    ]),
    authorizedKlagekode: authorized([
      ...Object.values(juridisk),
      bypatruljen.saksbehandleradmin,
    ]),
    authorizedTjenestennummer: !authorized([stedfortrederDriftsleder]),
  };

  getOvertredelseskoderMiljogebyr(id) {
    for (
      let index = 0;
      index < this.props.overtredelseskoderMiljogebyr.length;
      index++
    ) {
      const overtredelseskoder = this.props.overtredelseskoderMiljogebyr[index];
      if (id === overtredelseskoder.id) {
        return overtredelseskoder.paragraf;
      }
    }
  }

  render() {
    const {
      showSokSpinner,
      miljogebyrFilterState,
      handleChange,
      handleBlur,
      handleSubmit,
      miljogebyrStatuser,
    } = this.props;
    return (
      <form id="ileggelse-filter" onSubmit={handleSubmit}>
        <div
          className="filter-flexbox-container ileggelse-filter-flexbox-item"
          style={{ marginBottom: 10 }}
        >
          <div>
            <TextInput
              label="Ileggelsesnummer"
              name="ileggelsesnummer"
              onChange={handleChange}
              onBlur={handleBlur}
              value={miljogebyrFilterState.ileggelsesnummer}
              validationError={
                miljogebyrFilterState.validationError.ileggelsesnummer
              }
            />
          </div>
          <div>
            <DatetimePicker
              id="fraDato"
              name="fraDato"
              locale="nb"
              label="Dato fra"
              placeholder="Velg"
              onChange={handleChange}
              value={miljogebyrFilterState.fraDato}
              validationError={miljogebyrFilterState.fraDatoValidationErrorText}
              timeFormat={false}
            />
          </div>
          <div>
            <DatetimePicker
              id="fraTid"
              name="fraTid"
              locale="nb"
              label="Tid fra"
              placeholder="Velg"
              onChange={handleChange}
              value={miljogebyrFilterState.fraTid}
              validationError={miljogebyrFilterState.fraTidValidationErrorText}
              dateFormat={false}
            />
          </div>
          <div>
            <DatetimePicker
              id="tilDato"
              name="tilDato"
              locale="nb"
              label="Dato til"
              placeholder="Velg"
              onChange={handleChange}
              value={miljogebyrFilterState.tilDato}
              validationError={miljogebyrFilterState.tilDatoValidationErrorText}
              timeFormat={false}
            />
          </div>
          <div>
            <DatetimePicker
              id="tilTid"
              name="tilTid"
              locale="nb"
              label="Tid til"
              placeholder="Velg"
              onChange={handleChange}
              value={miljogebyrFilterState.tilTid}
              validationError={miljogebyrFilterState.tilTidValidationErrorText}
              dateFormat={false}
            />
          </div>
          {this.state.authorizedTjenestennummer && (
            <div>
              <TextInput
                label="Tjenestenummer"
                name="tjenestenummer"
                onChange={handleChange}
                onBlur={handleBlur}
                value={miljogebyrFilterState.tjenestenummer}
                validationError={
                  miljogebyrFilterState.validationError.tjenestenummer
                }
              />
            </div>
          )}
          <div>
            <DropDown
              label="Venter"
              name="isVenter"
              items={visAlleJaNei}
              selected={miljogebyrFilterState.isVenter}
              title="Velg"
              onChange={handleChange}
            />
          </div>
          <div>
            <DropDown
              label="Makulert"
              name="isMakulert"
              items={visAlleJaNei}
              selected={miljogebyrFilterState.isMakulert}
              title="Velg"
              onChange={handleChange}
            />
          </div>
        </div>
        <div
          className="filter-flexbox-container ileggelse-filter-flexbox-item"
          style={{ marginBottom: 10 }}
        >
          <div style={{ marginBottom: 15 }}>
            <label htmlFor="mottaker">Mottaker</label>
            <input
              id="mottaker"
              name="mottaker"
              className="form-control"
              type="text"
              value={miljogebyrFilterState.mottaker}
              onChange={handleChange}
            />
          </div>
          <div style={{ marginBottom: 15 }}>
            <label htmlFor="gatenavn">Gatenavn/Ved</label>
            <input
              id="gatenavn"
              name="gatenavn"
              className="form-control"
              type="text"
              value={miljogebyrFilterState.gatenavn}
              onChange={handleChange}
            />
          </div>
          <div style={{ marginBottom: 15 }}>
            <label htmlFor="gatenummer">Gatenummer/Ved nr.</label>
            <input
              id="gatenummer"
              name="gatenummer"
              className="form-control"
              type="text"
              value={miljogebyrFilterState.gatenummer}
              onChange={handleChange}
            />
          </div>
          <div style={{ marginBottom: 15 }}>
            <label htmlFor="bydel">Bydel</label>
            <input
              id="bydel"
              name="bydel"
              className="form-control"
              type="text"
              value={miljogebyrFilterState.bydel}
              onChange={handleChange}
            />
          </div>
          <DropDownListSearch
            id="overtredelseskode"
            label="Overtredelseskode"
            name="overtredelseskode"
            title={
              miljogebyrFilterState.overtredelseskode
                ? this.getOvertredelseskoderMiljogebyr(
                    this.props.miljogebyrFilterState.overtredelseskode
                  )
                : "Velg"
            }
            items={[{ id: "", navn: "Velg", beskrivelse: "" }].concat(
              this.props.overtredelseskoderMiljogebyr.map((ok) => {
                return {
                  id: ok.id,
                  navn: ok.paragraf,
                  beskrivelse: ok.kortTekst,
                };
              })
            )}
            selected={this.props.miljogebyrFilterState.overtredelseskode}
            handleChange={handleChange}
          />
          <div>
            <label htmlFor="leveringstypeId">Leveringstype</label>
            <DropDown
              id="leveringstype"
              name="leveringstype"
              title="Velg"
              selected={miljogebyrFilterState.leveringstype}
              items={this.props.miljoleveringstyper}
              onChange={handleChange}
            />
          </div>
          <div style={{ marginBottom: 10 }}>
            <DropDownListMultiCheckboxOptions
              label="Status"
              name="selectedStatuser"
              items={miljogebyrStatuser}
              selected={this.props.miljogebyrFilterState.selectedStatuser}
              title={
                this.props.miljogebyrFilterState.selectedStatuser.length > 0
                  ? `${this.props.miljogebyrFilterState.selectedStatuser.length} valg`
                  : "Velg"
              }
              onChange={handleChange}
            />
          </div>
        </div>

        {this.state.authorizedOverforingsdatoIne && (
          <div className="filter-flexbox-container ileggelse-filter-flexbox-item">
            <div>
              <DatetimePicker
                id="fraIneOverforingsdato"
                name="fraIneOverforingsdato"
                locale="nb"
                placeholder="Velg"
                label="INE overføringsdato fra"
                onChange={handleChange}
                value={miljogebyrFilterState.fraIneOverforingsdato}
                validationError={
                  miljogebyrFilterState.fraIneOverforingsdatoValidationErrorText
                }
                timeFormat={false}
              />
            </div>
            <div>
              <DatetimePicker
                id="tilIneOverforingsdato"
                name="tilIneOverforingsdato"
                locale="nb"
                placeholder="Velg"
                label="INE overføringsdato til"
                onChange={handleChange}
                value={miljogebyrFilterState.tilIneOverforingsdato}
                validationError={
                  miljogebyrFilterState.tilIneOverforingsdatoValidationErrorText
                }
                timeFormat={false}
              />
            </div>
          </div>
        )}
        {this.state.authorizedSaksnummer &&
          this.state.authorizedVedtagskode &&
          this.state.authorizedKlagekode && (
            <div
              className="filter-flexbox-container ileggelse-filter-flexbox-item"
              style={{ marginBottom: 10 }}
            >
              {this.state.authorizedSaksnummer && (
                <TextInput
                  label="Saksnummer"
                  name="saksnummer"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={miljogebyrFilterState.saksnummer}
                  validationError={
                    miljogebyrFilterState.validationError.saksnummer
                  }
                />
              )}
              {this.state.authorizedVedtagskode && (
                <DropDown
                  id="Vedtakskode"
                  name="vedtakskode"
                  label="Vedtakskode"
                  title="Velg"
                  selected={miljogebyrFilterState.vedtakskode}
                  items={this.props.vedtakskoder}
                  onChange={handleChange}
                />
              )}
              {this.state.authorizedKlagekode && (
                <DropDown
                  id="klagekode"
                  name="klagekode"
                  label="Klagekode"
                  title="Velg"
                  selected={miljogebyrFilterState.klagekode}
                  items={this.props.klagekoder}
                  onChange={handleChange}
                />
              )}
            </div>
          )}
        <div>
          <LoadButton
            buttonProps={{
              onClick: this.props.handleChangeToInitState,
              className: "btn-link",
              style: { height: 40, paddingRight: 17, paddingLeft: 0 },
            }}
            spinnerProps={{ pColor: "#ffffff", sColor: "#4d4d4d" }}
          >
            Tilbakestill
          </LoadButton>
          <LoadButton
            isLoading={showSokSpinner}
            buttonProps={{
              type: "submit",
              className: "btn-bym-action btn-bym-large",
              disabled: showSokSpinner,
            }}
            spinnerProps={{ pColor: "#ffffff", sColor: "#4d4d4d" }}
          >
            Søk
          </LoadButton>
        </div>
      </form>
    );
  }
}
