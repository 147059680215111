import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { css, StyleSheet } from "aphrodite/no-important";
import Lightbox from "./Lightbox/Lightbox";
import { map } from "lodash";

import Checkbox from "./Forms/Checkbox";

import "./Gallery.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faTrashAlt,
  faExpand,
} from "@fortawesome/free-solid-svg-icons";
import ConfirmationModal from "./ConfirmationModal";

const gutter = {
  small: 2,
  large: 4,
};

const classes = StyleSheet.create({
  gallery: {
    marginRight: -gutter.small,
    overflow: "hidden",

    "@media (min-width: 500px)": {
      marginRight: -gutter.large,
    },
  },

  // anchor
  thumbnail: {
    boxSizing: "border-box",
    display: "block",
    float: "left",
    lineHeight: 0,
    paddingRight: gutter.small,
    paddingBottom: gutter.small,
    overflow: "hidden",

    "@media (min-width: 500px)": {
      paddingRight: gutter.large,
      paddingBottom: gutter.large,
    },
  },

  // orientation
  landscape: {
    width: "30%",
  },
  square: {
    paddingBottom: 0,
    width: "40%",

    "@media (min-width: 500px)": {
      paddingBottom: 0,
    },
  },
});

class Gallery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lightboxIsOpen: false,
      currentImage: 0,
    };
  }

  openLightbox = (index, event) => {
    event.preventDefault();
    this.setState({
      currentImage: index,
      lightboxIsOpen: true,
    });

    // Ingen scroll
    let htmlElement = document.getElementsByTagName("html");
    if (htmlElement && htmlElement.length > 0) {
      htmlElement[0].style.overflow = "hidden";
    }
    let bodyElement = document.getElementsByTagName("body");
    if (bodyElement && bodyElement.length > 0) {
      bodyElement[0].style.overflow = "hidden";
    }
  };

  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });

    // Scroll
    let htmlElement = document.getElementsByTagName("html");
    if (htmlElement && htmlElement.length > 0) {
      htmlElement[0].style.overflow = "";
    }
    let bodyElement = document.getElementsByTagName("body");
    if (bodyElement && bodyElement.length > 0) {
      bodyElement[0].style.overflow = "";
    }
  };

  gotoPrevious = () => {
    const { lightboxImages } = this.props;
    const { currentImage } = this.state;

    if (currentImage <= 0) {
      this.setState({
        currentImage: lightboxImages && lightboxImages.length - 1,
      });
      return;
    }
    this.setState({
      currentImage: currentImage - 1,
    });
  };

  gotoNext = () => {
    const { lightboxImages } = this.props;
    const { currentImage } = this.state;

    if (currentImage >= lightboxImages.length - 1) {
      this.setState({
        currentImage: 0,
      });
      return;
    }
    this.setState({
      currentImage: currentImage + 1,
    });
  };

  createImageUrl(baseUrl, images) {
    let imagesData = [];
    map(images, (i) => {
      let imgUrl = this.imageUrl(baseUrl, i);
      imagesData.push(Object.assign({}, { src: imgUrl }));
    });

    return imagesData;
  }

  handleCheckboxChange = (event) => {
    this.props.handleCheckboxChange(event);
  };

  getDeleteButton = (handleClickShow) => (
    <Button
      className="btn-bym-standard"
      style={{
        width: 42,
        height: 30,
        paddingTop: 6,
        paddingRight: 12,
        paddingBottom: 6,
        paddingLeft: 12,
      }}
      onClick={handleClickShow}
    >
      <FontAwesomeIcon icon={faTrashAlt} />
    </Button>
  );

  renderGallery = (images, hideMetadata, hideEditButton, hideExpandButton) => {
    const gallery = map(images, (obj, index) => {
      return (
        <div key={index} style={{ display: "inline-block", paddingRight: 4 }}>
          {obj.src && (
            <>
              <div
                style={{
                  position: "relative",
                  display: "block",
                  paddingBottom: 4,
                }}
              >
                <div
                  className={css(classes.thumbnail, classes[obj.orientation])}
                  style={{
                    width: 200,
                    height: 150,
                  }}
                >
                  <div
                    className="gallery-image-wrapper"
                    style={{
                      position: "absolute",
                      width: 200,
                      height: 150,
                      paddingTop: "4px",
                      paddingBottom: "4px",
                      backgroundColor: "#000",
                    }}
                  >
                    <a
                      href={obj.src}
                      onClick={(event) => this.openLightbox(index, event)}
                    >
                      <img
                        alt=""
                        src={obj.src}
                        style={{
                          width: "auto",
                          height: "142px" /*, transform: transform*/,
                          margin: "auto",

                          border: 0,
                          display: "block",
                          maxWidth: "100%",
                        }}
                      />
                    </a>
                  </div>
                  <div
                    className="image-buttons-container"
                    style={{ position: "absolute" }}
                  >
                    {this.props.isAuthorizedForToastUi && !hideEditButton && (
                      <div
                        style={{ marginTop: 4, marginRight: 4, marginLeft: 4 }}
                      >
                        <span title="Rediger">
                          <Link
                            to={
                              this.props.getToastUiUrl
                                ? this.props.getToastUiUrl(obj.id)
                                : `${window.location.pathname}/bilder/${obj.id}/toastui`
                            }
                          >
                            <Button
                              className="btn-bym-standard"
                              style={{
                                width: 42,
                                height: 30,
                                paddingTop: 6,
                                paddingRight: 12,
                                paddingBottom: 6,
                                paddingLeft: 12,
                              }}
                            >
                              <FontAwesomeIcon icon={faPencilAlt} />
                            </Button>
                          </Link>
                        </span>
                      </div>
                    )}
                    {!hideExpandButton && (
                      <div
                        style={{ marginTop: 4, marginRight: 4, marginLeft: 4 }}
                      >
                        <span title="Ekspandere">
                          <Button
                            className="btn-bym-standard"
                            style={{
                              width: 42,
                              height: 30,
                              paddingTop: 6,
                              paddingRight: 12,
                              paddingBottom: 6,
                              paddingLeft: 12,
                            }}
                            onClick={() => {
                              const url = this.props.getBildeUrl
                                ? this.props.getBildeUrl(obj.id)
                                : `${window.location.pathname}/bilder/${obj.id}`;
                              window.open(url);
                            }}
                          >
                            <FontAwesomeIcon icon={faExpand} />
                          </Button>
                        </span>
                      </div>
                    )}
                    {this.props.isAuthorizedToDeleteImage &&
                      obj.opprettetAvRolle === "Saksbehandler" && (
                        <div
                          style={{
                            marginTop: 4,
                          }}
                        >
                          <span title="Slett">
                            <ConfirmationModal
                              body="Vil du slette dette bildet?"
                              confirmText="Slett"
                              title="Slett bilde"
                              onConfirm={() =>
                                this.props.handleDeleteImageClick(obj.id)
                              }
                              deleteButtonOpenModal={this.getDeleteButton}
                            />
                          </span>
                        </div>
                      )}
                  </div>
                </div>
              </div>
              {!hideMetadata && (
                <div className="gallery-image-meta-data-wrapper">
                  <div className="gallery-file-name-wrapper">
                    {obj.navn ? obj.navn : "filenavn.jpeg"}
                  </div>
                  <div className="gallery-file-name-wrapper">
                    {obj.opprettetAvRolle}
                  </div>
                  {(this.props.checkboxText ||
                    typeof this.props.handleCheckboxChange !==
                      typeof undefined) && (
                    <div className="gallery-checkbox-wrapper">
                      <Checkbox
                        id={obj.id}
                        name="tilEttersendelse"
                        checkedValue={true}
                        uncheckedValue={false}
                        currentValue={obj.isGoodQuality}
                        handleChange={this.handleCheckboxChange}
                        text={this.props.checkboxText}
                      />
                    </div>
                  )}
                </div>
              )}
            </>
          )}
          {!obj.src && (
            <>
              <div
                style={{
                  position: "relative",
                  display: "block",
                  paddingBottom: 4,
                }}
              >
                <div
                  style={{
                    width: 200,
                    height: 150,
                  }}
                >
                  ⚠️ {obj.errorMessage} ⚠️
                </div>
              </div>
            </>
          )}
        </div>
      );
    });

    return <div className={css(classes.gallery)}>{gallery}</div>;
  };

  render() {
    const {
      baseUrl,
      images,
      lightboxImages,
      hideMetadata,
      hideEditButton,
      hideExpandButton,
    } = this.props;
    const { currentImage } = this.state;

    if (!images) return null;

    const gallaryImages =
      baseUrl && baseUrl !== "" ? this.createImageUrl(baseUrl, images) : images;

    return (
      <div className={this.props.id}>
        {this.props.heading && (
          <h3
            className="GalleryHeadingText fake-label"
            style={{ fontSize: 19, fontWeight: "bold" }}
          >
            {this.props.heading}
          </h3>
        )}
        {this.props.subheading && (
          <p className="GallerySubHeadingText">{this.props.subheading}</p>
        )}
        <div>
          {this.renderGallery(
            gallaryImages,
            hideMetadata,
            hideEditButton,
            hideExpandButton
          )}
        </div>
        {lightboxImages && lightboxImages.length > 0 && (
          <Lightbox
            showLightbox={this.state.lightboxIsOpen}
            images={lightboxImages}
            imageIndex={currentImage}
            onNext={this.gotoNext}
            onPrevious={this.gotoPrevious}
            onClose={this.closeLightbox}
          />
        )}
      </div>
    );
  }
}

Gallery.displayName = "Gallery";
Gallery.propTypes = {
  heading: PropTypes.string,
  images: PropTypes.array,
  lightboxImages: PropTypes.array,
  showThumbnails: PropTypes.bool,
  subheading: PropTypes.string,
  id: PropTypes.string,
  theme: PropTypes.string,
  baseUrl: PropTypes.string,
  checkboxText: PropTypes.string,
  isAuthorizedForToastUi: PropTypes.bool,
  isAuthorizedToDeleteImage: PropTypes.bool,
  hideMetadata: PropTypes.bool,
  hideEditButton: PropTypes.bool,
  hideExpandButton: PropTypes.bool,
  handleCheckboxChange: PropTypes.func,
  getToastUiUrl: PropTypes.func,
  getBildeUrl: PropTypes.func,
};
export default Gallery;
