import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";

import DropDown from "../Common/Forms/DropDown";
import TextInput from "../Common/Forms/TextInput";
import Datepicker from "../Common/Forms/Datepicker";
import TextArea from "../Common/Forms/TextArea";
import RadioButtonGroup from "../Common/Forms/RadioButtonGroup";
import { authorized } from "../../utils/authorizationUtils";
import { juridiskArray } from "../../constants/roles";

export default class OvertredelseskoderIleggelseForm extends React.Component {
  static propTypes = {
    validationError: PropTypes.object,
    internkoder: PropTypes.array,
    overtredelseInternkoder: PropTypes.array,
    overtredelseGruppeId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    overtredelseGrupper: PropTypes.array,
    kreverAvstand: PropTypes.bool,
    kreverAutomatinfo: PropTypes.bool,
    kreverBilettnummer: PropTypes.bool,
    kreverUtlopstidBillett: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    beskrivelse: PropTypes.string,
    forklaring: PropTypes.string,
    aktiv: PropTypes.bool,
    aktivertDato: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    deaktivertDato: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    handleChange: PropTypes.func,
    handleChangeGroupButtons: PropTypes.func,
    handleChangeSelect: PropTypes.func,
    belop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    actionType: PropTypes.string,
  };

  render() {
    const {
      validationError,
      // internkoder,
      // internkode,
      overtredelseGruppeId,
      overtredelseGrupper,
      kreverAvstand,
      kreverAutomatinfo,
      kreverBilettnummer,
      kreverUtlopstidBillett,
      beskrivelse,
      forklaring,
      aktivertDato,
      deaktivertDato,
      belop,
      handleChange,
      handleChangeGroupButtons,
      actionType,
      id,
    } = this.props;

    const radioButtonItems = [
      { id: 1, navn: "Kreves", value: true },
      { id: 2, navn: "Valgfritt", value: false },
      { id: 3, navn: "Skjult", value: null },
    ];

    if (authorized(juridiskArray)) {
      return (
        <form>
          <Row>
            <Col md={6}>
              <TextInput
                label="ID"
                value={id}
                name="id"
                type="number"
                onChange={handleChange}
                disabled={actionType === "update" ? true : false}
                validationError={validationError.id}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <TextArea
                label="Forklaring for innbyggere"
                placeholder="forklaring"
                onChange={handleChange}
                value={forklaring}
                name="forklaring"
              />
            </Col>
          </Row>
        </form>
      );
    }

    return (
      <form>
        <Row>
          <Col md={6}>
            <TextInput
              label="ID"
              value={id}
              name="id"
              type="number"
              onChange={handleChange}
              disabled={actionType === "update" ? true : false}
              validationError={validationError.id}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <TextArea
              label="Forklaring"
              placeholder="forklaring"
              onChange={handleChange}
              value={beskrivelse}
              name="beskrivelse"
              validationError={validationError.beskrivelse}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <DropDown
              id="overtredelsesgruppe"
              label="Overtredelsesgruppe"
              items={overtredelseGrupper}
              name="overtredelseGruppeId"
              selected={overtredelseGruppeId}
              title="Velg"
              onChange={handleChange}
              validationError={validationError.overtredelseGruppeId}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <TextInput
              label="Beløp"
              onChange={handleChange}
              value={belop}
              name="belop"
              type="number"
              validationError={validationError.belop}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Datepicker
              label="Aktivert dato"
              id="aktivertDato"
              onChange={handleChange}
              locale="nb"
              timeFormat={false}
              value={aktivertDato}
              placeHolder="Velg"
              validationError={validationError.aktivertDato}
            />
          </Col>
          <Col md={6}>
            <Datepicker
              label="Deaktivert dato"
              id="deaktivertDato"
              onChange={handleChange}
              locale="nb"
              timeFormat={false}
              value={deaktivertDato}
              placeHolder="Velg"
              validationError={validationError.deaktivertDato}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <RadioButtonGroup
              label="Krever avstand"
              items={radioButtonItems}
              onChange={handleChangeGroupButtons}
              selectedValue={kreverAvstand}
              id="kreverAvstand"
              name="kreverAvstand"
              validationError={validationError.kreverAvstand}
            />
          </Col>
          <Col md={6}>
            <RadioButtonGroup
              label="Krever Automatinfo"
              items={radioButtonItems}
              onChange={handleChangeGroupButtons}
              selectedValue={kreverAutomatinfo}
              id="kreverAutomatinfo"
              name="kreverAutomatinfo"
              validationError={validationError.kreverAutomatinfo}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <RadioButtonGroup
              label="Krever Bilettnummer"
              items={radioButtonItems}
              onChange={handleChangeGroupButtons}
              selectedValue={kreverBilettnummer}
              id="kreverBilettnummer"
              name="kreverBilettnummer"
              validationError={validationError.kreverBilettnummer}
            />
          </Col>
          <Col md={6}>
            <RadioButtonGroup
              label="Krever utløpstid billet"
              items={radioButtonItems}
              onChange={handleChangeGroupButtons}
              selectedValue={kreverUtlopstidBillett}
              id="kreverUtlopstidBillett"
              name="kreverUtlopstidBillett"
              validationError={validationError.kreverUtlopstidBillett}
            />
          </Col>
        </Row>
      </form>
    );
  }
}
