import axios from "../services/axios";
import * as api from "../constants/api";
import * as handle from "../utils/actionUtils";
// import * as apiResponseUtils from "../utils/apiResponseUtils";
import { setTotal as setTotalAction } from "./advancedListActions";
import { toIsoString } from "../utils/datetimeUtils";

export const GET_ETTERSENDELSER_SUCCESS = "GET_ETTERSENDELSER_SUCCESS";
export const GET_ETTERSENDELSER_FAILURE = "GET_ETTERSENDELSER_FAILURE";

export function getEttersendelser(params, page, count) {
  return async (dispatch) => {
    dispatch(setTotalAction("ettersendelser", 1)); // WTF?? Does this work when pagination is used?
    return axios
      .get(api.GET_ILEGGELSER_URL, {
        params: {
          ...params,
          fraDato: params.fraDato && toIsoString(params.fraDato),
          tilDato: params.tilDato && toIsoString(params.tilDato),
          orderBy: "ascending",
          page: page,
          pageSize: count,
        },
        paramsSerializer: {
          indexes: null,
        },
      })
      .then((response) => {
        dispatch(
          setTotalAction(
            "ettersendelser",
            JSON.parse(response.headers["x-pagination"]).Total
          )
        );
        dispatch({
          type: GET_ETTERSENDELSER_SUCCESS,
          payload: response.data.result,
        });
        return Promise.resolve(response);
      })
      .catch((error) => {
        handle.error(dispatch, GET_ETTERSENDELSER_FAILURE);
        return Promise.reject(error);
      });
  };
}

export function putIleggelseEttersendelserTilGodkjenning(
  ileggelseEttersendelseId,
  data
) {
  let putApi = api.PUT_ILEGGELSE_ETTERSENDELSER_TIL_GODKJENNING(
    ileggelseEttersendelseId
  );
  return async () => {
    return axios
      .put(putApi, data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  };
}

export function putIleggelseTilEttersendelse(ileggelseId, data) {
  let putApi = api.PUT_ILEGGELSE_TIL_ETTERSENDELSE_URL(ileggelseId);
  return (dispatch) => {
    return axios.put(putApi, data);
  };
}

export function putMiljogebyrTilEttersendelse(miljogebyrId, data) {
  let putApi = api.PUT_MILJOGEBYR_TIL_ETTERSENDELSE_URL(miljogebyrId);
  return (dispatch) => {
    return axios.put(putApi, data);
  };
}
