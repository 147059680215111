import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";

import ModalGenericPostCancelContainer from "../../../Common/ModalGenericPostCancelContainer";
import TextareaModalContent from "../../../Common/IleggelseClient/TextareaModalContent";

import { getMiljogebyr as getMiljogebyrAction } from "../../../../actions/miljogebyrerActions";
import { getMiljogebyrEttersendelser as getMiljogebyrEttersendelserAction } from "../../../../actions/miljogebyrEttersendelserActions";
import { postMiljogebyrEttersendelser as postMiljogebyrEttersendelserAction } from "../../../../actions/miljogebyrEttersendelserActions";

import { commentHasToBeBetween } from "../../../../constants/ErrorMessages";
import {
  ettersendelsesrapportMinLength,
  ettersendelsesrapportMaxLength,
} from "../../../../constants/globals";

class NyEttersendelseContainer extends React.Component {
  static propTypes = {
    miljogebyr: PropTypes.object,
    getMiljogebyr: PropTypes.func.isRequired,
    getMiljogebyrEttersendelser: PropTypes.func.isRequired,
    postMiljogebyrEttersendelser: PropTypes.func.isRequired,
    reloadHistorikk: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      kommentar: null,
      kommentarValidationError: null,
    };

    this.handleApiCall = this.handleApiCall.bind(this);
  }

  validation = () => {
    return this.state.kommentar &&
      this.state.kommentar.length >= ettersendelsesrapportMinLength &&
      this.state.kommentar.length <= ettersendelsesrapportMaxLength
      ? true
      : false;
  };

  async handleApiCall(miljogebyrId) {
    if (this.validation()) {
      return this.props
        .postMiljogebyrEttersendelser(miljogebyrId, {
          kommentar: this.state.kommentar,
        })
        .then(() => {
          this.setState({ kommentar: null, kommentarValidationError: null });
          this.props.getMiljogebyr(miljogebyrId);
          this.props.getMiljogebyrEttersendelser(miljogebyrId);
          this.props.reloadHistorikk();
          return Promise.resolve();
        })
        .catch((error) => Promise.reject(error));
    } else {
      this.setState({ kommentarValidationError: true });
      return Promise.reject("validationError");
    }
  }

  handleKommentarChange = (event) => {
    this.setState({ kommentar: event.target.value });
  };

  render() {
    if (
      !this.props.miljogebyr.isTilEttersendelse &&
      !this.props.miljogebyr.isMakulert &&
      !this.props.miljogebyr.isBetalt
    ) {
      return (
        <ModalGenericPostCancelContainer
          id={this.props.miljogebyr.id}
          modalTitle={`Ny ettersendelse av miljogebyr ${this.props.miljogebyr.ileggelsesnummer}`}
          submitButtonText="Lag ny ettersendelse"
          submittingButtonText="Lager ny ettersendelse..."
          successPostCallFlashMessage="Endring fullført"
          errorPostCallFlashMessage="Endring feilet"
          handleApiCall={this.handleApiCall}
          openModalComponentFunction={(props) => (
            <Button
              className="btn btn-bym-standard"
              style={{ marginLeft: 20 }}
              onClick={props.openModal}
            >
              Ny Ettersendelse
            </Button>
          )}
        >
          <TextareaModalContent
            paragrapghText={`Ønsker du å lage en ny ettersendelse for ileggele ${this.props.miljogebyr.ileggelsesnummer}?`}
            kommentarValidationError={this.state.kommentarValidationError}
            textareaLabel="Ettersendelsesrapport"
            textareaPlaceholder="Ettersendelsesrapport"
            validationErrorText={commentHasToBeBetween(
              ettersendelsesrapportMinLength,
              ettersendelsesrapportMaxLength
            )}
            handleChange={this.handleKommentarChange}
          />
        </ModalGenericPostCancelContainer>
      );
    } else {
      return <span />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    miljogebyr: state.miljogebyr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMiljogebyr: (miljogebyrId) =>
      dispatch(getMiljogebyrAction(miljogebyrId)),
    getMiljogebyrEttersendelser: (miljogebyrId) =>
      dispatch(getMiljogebyrEttersendelserAction(miljogebyrId)),
    postMiljogebyrEttersendelser: (miljogebyrId, params) =>
      dispatch(postMiljogebyrEttersendelserAction(miljogebyrId, params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NyEttersendelseContainer);
