import React, { useState } from "react";
import { HeaderCell } from "../../Common/Tables/index";
import Table from "../../Common/Tables/Table";
import { useDispatch, useSelector } from "react-redux";
import rapporterSitePaths from "../../../constants/SitePaths/rapporterSitePaths";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { numberWithSpace } from "../../../utils/stringUtils";
import { getUbetalteIleggelser } from "../../../actions/rapporter/ubetalteIleggelserActions";

const UbetalteIleggelserList = (props) => {
  const [showSokSpinner, setShowSokSpinner] = useState(false);

  const dispatch = useDispatch();

  const ubetalteIleggelserKjennemerkeGroups = useSelector(
    (state) => state.rapporter.ubetalteIleggelserKjennemerkeGroups
  );

  const ileggelseType = useSelector(
    (state) => state.forms.ubetalteIleggelserFilterForm.ileggelseType
  );

  const onSort = async (sort) => {
    props.setSort(sort);
    props.setOppdatertTid(new Date());
    setShowSokSpinner(true);
    await dispatch(
      getUbetalteIleggelser({
        ileggelseType: ileggelseType,
        sort: sort.cellKey,
        sortOrder: sort.orders,
      })
    )
      .then(() => setShowSokSpinner(false))
      .catch(() => setShowSokSpinner(false));
  };

  const getTableHeaders = (sort) => (
    <React.Fragment>
      <HeaderCell cellKey="regnr" sort={sort} onSort={onSort}>
        Regnr.
      </HeaderCell>
      <HeaderCell cellKey="nasjon" sort={sort} onSort={onSort}>
        Nasjon
      </HeaderCell>
      <HeaderCell cellKey="fabrikkmerke" sort={sort} onSort={onSort}>
        Fabrikkmerke
      </HeaderCell>
      <HeaderCell cellKey="kjoretoystype" sort={sort} onSort={onSort}>
        Kjøretøystype
      </HeaderCell>
      <HeaderCell cellKey="farge" sort={sort} onSort={onSort}>
        Farge
      </HeaderCell>
      <HeaderCell cellKey="belop" sort={sort} onSort={onSort}>
        Beløp
      </HeaderCell>
      <HeaderCell cellKey="antall" sort={sort} onSort={onSort}>
        Antall
      </HeaderCell>
    </React.Fragment>
  );

  const renderRow = (ubetalteIleggelserKjennemerkeGroup) => {
    return (
      <tr
        key={`UbetalteIleggelserList-${ubetalteIleggelserKjennemerkeGroup.kjoretoyId}`}
      >
        <td>
          <Link
            to={{
              pathname:
                rapporterSitePaths.ubetalteIleggelser.detalj.shortUrl.getUrl(
                  ubetalteIleggelserKjennemerkeGroup.kjoretoyId
                ),
              search: `?ileggelseType=${ileggelseType}`,
            }}
            className="underline"
          >
            {ubetalteIleggelserKjennemerkeGroup.kjennemerke}
          </Link>
        </td>
        <td>{ubetalteIleggelserKjennemerkeGroup.nasjon}</td>
        <td>{ubetalteIleggelserKjennemerkeGroup.fabrikkmerke}</td>
        <td>{ubetalteIleggelserKjennemerkeGroup.kjoretoyType}</td>
        <td>{ubetalteIleggelserKjennemerkeGroup.farge}</td>
        <td>
          <div style={{ maxWidth: 100, textAlign: "right", paddingRight: 24 }}>
            {numberWithSpace(ubetalteIleggelserKjennemerkeGroup.belop)}
          </div>
        </td>
        <td>
          <div style={{ maxWidth: 100, textAlign: "right", paddingRight: 24 }}>
            {numberWithSpace(ubetalteIleggelserKjennemerkeGroup.antall)}
          </div>
        </td>
      </tr>
    );
  };

  return (
    <div>
      <h3 style={{ fontSize: 16, fontWeight: "bold" }}>Ubetalte ileggelser</h3>
      <Table
        id="ubetalte-ileggelser-table"
        items={ubetalteIleggelserKjennemerkeGroups}
        renderRow={renderRow}
        showSpinner={showSokSpinner}
      >
        {getTableHeaders(props.sort)}
      </Table>
    </div>
  );
};

export default UbetalteIleggelserList;
