import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SpinLoader from "../../../Common/SpinLoader";
import Gallery from "../../../Common/Gallery";

const spinnerLoaderProps = {
  height: 42,
  width: 42,
  thickness: 3,
  pColor: "#ffffff",
  sColor: "#4d4d4d",
};

const getBilde = (bildeId, observasjonImages) => {
  for (let index = 0; index < observasjonImages.length; index++) {
    const bilde = observasjonImages[index];
    if (bilde.id === bildeId) {
      return bilde;
    }
  }

  return null;
};

const getBildeUrl = (bildeId, observasjonImages) => {
  const bilde = getBilde(bildeId, observasjonImages);
  if (!bilde) {
    return "";
  }

  let bildeIdUrl = bildeId;

  if (bilde.orginalMiljoKontrollObservasjonBildeId) {
    bildeIdUrl = bilde.orginalMiljoKontrollObservasjonBildeId;
  }
  return `${window.location.pathname}/observasjoner/${bilde.observasjonId}/bilder/${bildeIdUrl}`;
};

const getGalleryView = (
  dateSortedThumbnailImageArray,
  dateSortedLightboxImageArray
) => {
  return dateSortedThumbnailImageArray.map((imagesForDato, index) => (
    <div key={`${imagesForDato.dato}${imagesForDato.tidspan}`}>
      <h3
        style={{
          fontSize: 16,
          fontWeight: "bold",
          marginTop: 20,
          marginBottom: 20,
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        {imagesForDato.dato} {imagesForDato.tidspan}
      </h3>
      <Gallery
        images={imagesForDato.images}
        lightboxImages={dateSortedLightboxImageArray[index]?.images}
        getBildeUrl={(bildeId) => getBildeUrl(bildeId, imagesForDato.images)}
      />
    </div>
  ));
};

const Bilder = ({
  id,
  dateSortedThumbnailImageArray,
  dateSortedLightboxImageArray,
  getMiljokontrollBilder,
  bilderButtonsContainer: BilderButtonsContainer,
}) => {
  const [isLoadingImages, setIsLoadingImages] = useState(false);

  useEffect(() => {
    setIsLoadingImages(true);
    let isLoadingMiljokontrollLightboxBilder = true;
    let isLoadingMiljokontrollThumbnailBilder = true;

    getMiljokontrollBilder(id, { s3BucketCategory: "miljogebyrer_lightbox" })
      .then(() => {
        isLoadingMiljokontrollLightboxBilder = false;
        if (
          !isLoadingMiljokontrollLightboxBilder &&
          !isLoadingMiljokontrollThumbnailBilder
        ) {
          setIsLoadingImages(false);
        }
      })
      .catch(() => {
        isLoadingMiljokontrollLightboxBilder = false;
        if (
          !isLoadingMiljokontrollLightboxBilder &&
          !isLoadingMiljokontrollThumbnailBilder
        ) {
          setIsLoadingImages(false);
        }
      });

    getMiljokontrollBilder(id, {
      s3BucketCategory: "miljogebyrer_thumbnail",
    })
      .then(() => {
        isLoadingMiljokontrollThumbnailBilder = false;
        if (
          !isLoadingMiljokontrollLightboxBilder &&
          !isLoadingMiljokontrollThumbnailBilder
        ) {
          setIsLoadingImages(false);
        }
      })
      .catch(() => {
        isLoadingMiljokontrollThumbnailBilder = false;
        if (
          !isLoadingMiljokontrollLightboxBilder &&
          !isLoadingMiljokontrollThumbnailBilder
        ) {
          setIsLoadingImages(false);
        }
      });
  }, [id, setIsLoadingImages, getMiljokontrollBilder]);

  return (
    <div>
      <div className="detalj-ileggelse-header-wrapper-no-margin">
        <h2>Bilder</h2>
      </div>
      {BilderButtonsContainer && (
        <div>
          <BilderButtonsContainer id={id} />
        </div>
      )}
      <div style={{ marginTop: 10 }}>
        {isLoadingImages && (
          <div style={{ margin: "auto", width: 0 }}>
            <SpinLoader {...spinnerLoaderProps} />
          </div>
        )}
        {dateSortedThumbnailImageArray &&
          dateSortedThumbnailImageArray.length > 0 &&
          dateSortedLightboxImageArray &&
          dateSortedLightboxImageArray.length > 0 &&
          getGalleryView(
            dateSortedThumbnailImageArray,
            dateSortedLightboxImageArray
          )}
      </div>
    </div>
  );
};

Bilder.propTypes = {
  id: PropTypes.string,
  bilderButtonsContainer: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  imageArray: PropTypes.arrayOf(PropTypes.object),
  isLoadingImages: PropTypes.bool,
};

export default Bilder;
