import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import map from "lodash/map";

import Table from "../../Common/Tables/BaseTable";
import OvertredelseskoderMiljogebyrUpdateModal from "./UpdateModals/OvertredelseskoderMiljogebyrModal";

import { getOvertredelseskoderMiljogebyr } from "../../../actions/overtredelseskoderMiljogebyrActions";
import * as title from "../../../constants/titles";

class OvertredelseskoderMiljogebyrList extends Component {
  static propTypes = {
    authorizedToCreateAndEdit: PropTypes.bool.isRequired,
    overtredelseskoderMiljogebyr: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        paragraf: PropTypes.string,
        del: PropTypes.string,
        belop: PropTypes.number,
        kortTekst: PropTypes.string,
        langTekst: PropTypes.string,
        arkivKode: PropTypes.string,
        aktiv: PropTypes.bool,
      })
    ),
  };

  constructor(props) {
    super(props);
    this.renderRow = this.renderRow.bind(this);
  }
  componentDidMount() {
    document.title = title.overtredelseskoderMiljogebyr;
    this.props.getOvertredelseskoderMiljogebyr();
  }

  renderRow() {
    const {
      overtredelseskoderMiljogebyr,
      overtredelseskoderMiljogebyrAktivStatus,
    } = this.props;
    if (overtredelseskoderMiljogebyr) {
      return map(
        overtredelseskoderMiljogebyr.filter(
          (okm) =>
            okm.aktiv === overtredelseskoderMiljogebyrAktivStatus ||
            overtredelseskoderMiljogebyrAktivStatus === null
        ),
        (overtredelseskoderMiljogebyr) => (
          <tr
            key={`overtredelseskoderMiljogebyrTableBodyTr${overtredelseskoderMiljogebyr.id}`}
          >
            <td>{overtredelseskoderMiljogebyr.id}</td>
            <td>{overtredelseskoderMiljogebyr.paragraf}</td>
            <td>{overtredelseskoderMiljogebyr.del}</td>
            <td>{overtredelseskoderMiljogebyr.belop}</td>
            <td>{overtredelseskoderMiljogebyr.kortTekst}</td>
            <td>{overtredelseskoderMiljogebyr.langTekst}</td>
            <td>{overtredelseskoderMiljogebyr.arkivKode}</td>
            <td>
              {overtredelseskoderMiljogebyr.aktiv === true
                ? "Aktiv"
                : "Deaktivert"}
            </td>
            <td>
              {this.props.authorizedToCreateAndEdit && (
                <OvertredelseskoderMiljogebyrUpdateModal
                  overtredelseskoderMiljogebyr={overtredelseskoderMiljogebyr}
                />
              )}
            </td>
          </tr>
        )
      );
    }
    return null;
  }

  render() {
    const headerRow = [
      "Overtredelseskode",
      "Paragraf",
      "Del",
      "Beløp",
      "Kort tekst",
      "Lang tekst",
      "Arkivkode",
      "Status",
      "",
    ];
    return (
      <Table
        id="overtredelseskoderMiljogebyr-liste"
        className="table-wrapper-horizontal-scroll-white-space-normal"
        defaultStyle
        striped
        headerRow={headerRow}
      >
        <tbody>{this.renderRow()}</tbody>
      </Table>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    overtredelseskoderMiljogebyrAktivStatus:
      state.forms.grunndataFilterForm.overtredelseskoderMiljogebyrAktivStatus,
    overtredelseskoderMiljogebyr: state.overtredelseskoderMiljogebyr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getOvertredelseskoderMiljogebyr }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OvertredelseskoderMiljogebyrList);
