import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useDispatch } from "react-redux";
import CloseButton from "../../Common/CloseButton";
import Observasjonsgruppe from "./Observasjonsgruppe";
import PrintOut from "../../Common/PrintOut";

import {
  getSingleUregistrertekontroller as getSingleUregistrertekontrollerAction,
  getAllUregistrertekontrollerObservasjonsgrupperBilder as getAllUregistrertekontrollerObservasjonsgrupperBilderAction,
} from "../../../actions/uregistrertekontrollerActions";
import { authorized } from "../../../utils/authorizationUtils";
import { juridiskArray } from "../../../constants/roles";
import sitePaths from "../../../constants/sitePaths";
import Spinner from "../../Common/Spinner";

const UregistrerteDetaljPage = (props) => {
  const [uregistrertKontroll, setUregistrertKontroll] = useState({});
  const [
    uregistrertKontrollObservasjonsgruppeLightboxBilder,
    setUregistrertKontrollObservasjonsgruppeLightboxBilder,
  ] = useState([]);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getSingleUregistrertekontrollerAction(props.computedMatch.params.id)
    ).then((response) => setUregistrertKontroll(response.data.result));
    dispatch(
      getAllUregistrertekontrollerObservasjonsgrupperBilderAction(
        props.computedMatch.params.id,
        "uregbilde_lightbox"
      )
    ).then((response) => {
      return setUregistrertKontrollObservasjonsgruppeLightboxBilder(
        response.data.result
      );
    });
  }, [props.computedMatch.params.id, dispatch]);

  const handleCloseButtonClick = () => {
    history.push(sitePaths.uregistrerte.kontroller.shortUrl);
  };

  if (
    uregistrertKontroll.id &&
    uregistrertKontroll.id === props.computedMatch.params.id
  ) {
    return (
      <div style={{ paddingRight: 50, paddingLeft: 50 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h1>Kontroll av uregistrertkjøretøy</h1>
          <div className="no-print" style={{ alignSelf: "flex-end" }}>
            <CloseButton
              customHandleClick={handleCloseButtonClick}
              text="Lukk kontroll av uregistrert kjøretøy"
            />
          </div>
        </div>
        {!authorized(juridiskArray) && (
          <div
            className="no-print"
            style={{ display: "inline-block", marginTop: 20 }}
          >
            <PrintOut />
          </div>
        )}
        <section style={{ maxWidth: 500 }}>
          <h2 style={{ fontSize: 18 }}>Kjøretøy</h2>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderTop: "solid 1px #CCCCCC",
              borderBottom: "solid 1px #CCCCCC",
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "column", rowGap: 10 }}
            >
              <div>
                <div>Id</div>
                <div>{uregistrertKontroll.nummer ?? "\u00A0"}</div>
              </div>
              <div>
                <div>Fabrikkmerke</div>
                <div>{uregistrertKontroll.fabrikkMerke ?? "\u00A0"}</div>
              </div>
              <div>
                <div>Gate</div>
                <div>{uregistrertKontroll.gate ?? "\u00A0"}</div>
              </div>
              <div>
                <div>Ved gate</div>
                <div>{uregistrertKontroll.vedGate ?? "\u00A0"}</div>
              </div>
              <div>
                <div>Status</div>
                <div>{uregistrertKontroll.status ?? "\u00A0"}</div>
              </div>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", rowGap: 10 }}
            >
              <div>
                <div>Kjøretøytype</div>
                <div>{uregistrertKontroll.kjoretoyType ?? "\u00A0"}</div>
              </div>
              <div>
                <div>Farge</div>
                <div>{uregistrertKontroll.farge ?? "\u00A0"}</div>
              </div>
              <div>
                <div>Husnr</div>
                <div>{uregistrertKontroll.gatenummer ?? "\u00A0"}</div>
              </div>
              <div>
                <div>Ved nr.</div>
                <div>{uregistrertKontroll.vedGatenummer ?? "\u00A0"}</div>
              </div>
            </div>
          </div>
        </section>
        {uregistrertKontroll.observasjonsgrupper &&
          uregistrertKontroll.observasjonsgrupper?.map((og, index) => {
            og["lightboxBilder"] =
              uregistrertKontrollObservasjonsgruppeLightboxBilder.find(
                (lbg) => lbg.intervalStart === og.intervalStart
              )?.bilder;
            return (
              <Observasjonsgruppe
                observasjonsgruppe={og}
                observasjonsgruppenr={index + 1}
                key={`observasjonsgruppe-${index}`}
              />
            );
          })}
      </div>
    );
  } else {
    return <Spinner isLoading />;
  }
};

export default UregistrerteDetaljPage;
