import PropTypes from "prop-types";
import React, { Component } from "react";
import Collapsible from "../Common/Collapsible";
import DokumentVedlegger from "./DokumentVedlegger";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";

export default class Dokumenter extends Component {
  static propTypes = {
    sak: PropTypes.object,
    download: PropTypes.func,
  };
  render() {
    const { sak } = this.props;

    return (
      <div className="klagevedleggDiv">
        <div
          className="fake-label overskrift"
          style={{ fontSize: 16, fontWeight: "bold" }}
        >
          Vedlegg
        </div>
        {sak &&
          !isEmpty(sak.dokumenter) &&
          map(sak.dokumenter, (i, index) => (
            <Collapsible
              id={i.dokumentNummer}
              key={index}
              title={i.dokumentNummer + " : " + i.dokumentTittel}
            >
              <DokumentVedlegger dokument={i} download={this.props.download} />
            </Collapsible>
          ))}
      </div>
    );
  }
}
