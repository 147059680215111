import React, { useState } from "react";
import { useDispatch } from "react-redux";

import ModalGeneric from "../../../Common/ModalGeneric";
import UpdateOpenModal from "../../CreateModals/UpdateOpenModal";

import ForsinketMakuleringForm from "../../ForsinketMakuleringForm";

import { putForsinketMakuleringMal } from "../../../../actions/forsinketMakuleringActions";

const ForsinketMakuleringModal = () => {
  const [open, setOpen] = useState(false);
  const [submitButtonText, setSubmitButtonText] = useState("Oppdater");
  const [forsinketMakuleringFile, setForsinketMakuleringFile] =
    useState(undefined);
  const [forsinketMakuleringFileName, setForsinketMakuleringFileName] =
    useState(undefined);

  const dispatch = useDispatch();

  const closeModal = () => {
    setOpen(false);
    setForsinketMakuleringFile(undefined);
    setSubmitButtonText("Oppdater");
    setForsinketMakuleringFileName(undefined);
  };

  const handlePut = async () => {
    setSubmitButtonText("Oppdaterer...");

    const payload = {
      forsinketMakuleringFile: forsinketMakuleringFile,
    };

    dispatch(putForsinketMakuleringMal(payload))
      .then(() => {
        closeModal();
      })
      .catch(() => setSubmitButtonText("Oppdater"));
  };

  const saveAndClose = (e) => {
    handlePut();
  };

  const handleChange = (e) => {
    const name = e.target.name;
    switch (name) {
      case "forsinketMakuleringFile":
        setForsinketMakuleringFile(e.target.files[0]);
        setForsinketMakuleringFileName(e.target.files[0].name);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <ModalGeneric
        modalTitle={"Legg til nytt forsinket makuleringsbrev"}
        open={open}
        closeModal={closeModal}
        openModal={() => setOpen(true)}
        OpenModalComponent={UpdateOpenModal}
        submit={saveAndClose}
        submitButtonText={submitButtonText}
        disabled={!!!forsinketMakuleringFileName}
      >
        <ForsinketMakuleringForm
          forsinketMakuleringFileName={forsinketMakuleringFileName}
          handleChange={handleChange}
        />
      </ModalGeneric>
    </>
  );
};

export default ForsinketMakuleringModal;
