import React from "react";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import PrintOutTable from "../Common/PrintOutTable";

import { ReactComponent as IconImage } from "../../images/icon-image.svg";

class Utskrift extends React.Component {
  static propTypes = {
    queryParameters: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool,
        ]).isRequired,
      })
    ).isRequired,
    miljogebyrTableHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
    miljogebyrTableRows: PropTypes.arrayOf(
      PropTypes.shape({
        metadata: PropTypes.shape({}),
        data: PropTypes.arrayOf(
          PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        ),
      })
    ).isRequired,
    handleChangeAll: PropTypes.func.isRequired,
    getOvertredelseskoderMiljogebyr: PropTypes.func.isRequired,
    getMiljoleveringstyper: PropTypes.func.isRequired,
    getMiljogebyrerTilBehandling: PropTypes.func.isRequired,
  };

  async componentDidMount() {
    const {
      documentTitle,
      handleChangeAll,
      getOvertredelseskoderMiljogebyr,
      getMiljoleveringstyper,
      getMiljogebyrer,
    } = this.props;
    document.title = documentTitle;
    const sessionStoragePrintData = JSON.parse(
      window.sessionStorage.getItem("printData")
    );
    handleChangeAll(sessionStoragePrintData.queryData);
    await getOvertredelseskoderMiljogebyr();
    await getMiljoleveringstyper();
    await getMiljogebyrer(
      sessionStoragePrintData.queryData,
      sessionStoragePrintData.paginationData.page,
      sessionStoragePrintData.paginationData.count
    );
    setTimeout(() => {
      window.print();
      window.close();
    }, 1000);
  }

  renderQueryParameters = (queryParameters) => {
    if (queryParameters.length === 0) return <span />;
    let cols =
      queryParameters.length < 7 ? (
        <Col xs={4}>
          {queryParameters.map((queryParameter) => (
            <div key={queryParameter.key}>
              {queryParameter.key}:{" "}
              {queryParameter.value && queryParameter.value.toString()}
            </div>
          ))}
        </Col>
      ) : (
        <React.Fragment>
          <Col xs={4}>
            {queryParameters.slice(0, 6).map((queryParameter) => (
              <div key={queryParameter.key}>
                {queryParameter.key}:{" "}
                {queryParameter.value && queryParameter.value.toString()}
              </div>
            ))}
          </Col>
          <Col xs={4}>
            {queryParameters
              .slice(6, queryParameters.length)
              .map((queryParameter) => (
                <div>
                  {queryParameter.key}:{" "}
                  {queryParameter.value && queryParameter.value.toString()}
                </div>
              ))}
          </Col>
        </React.Fragment>
      );
    return cols;
  };

  renderMiljogebyrTableTd = (miljogebyrTableTd) => {
    if (miljogebyrTableTd === "ShowImageIcon") {
      return <IconImage />;
    }
    return miljogebyrTableTd;
  };

  getTdClassName = (tableRowMetadata) =>
    tableRowMetadata.isMakulert ? "print-out-table-row-makulert" : "";

  render() {
    const {
      title,
      queryParameters,
      miljogebyrTableHeaders,
      miljogebyrTableRows,
    } = this.props;
    return (
      <PrintOutTable
        title={title}
        queryParameters={queryParameters}
        tableHeaders={miljogebyrTableHeaders}
        tableRows={miljogebyrTableRows}
        renderQueryParameters={this.renderQueryParameters}
        renderTableTd={this.renderMiljogebyrTableTd}
        getTdClassName={this.getTdClassName}
      />
    );
  }
  componentWillUnmount() {
    window.sessionStorage.removeItem("printData");
  }
}

export default Utskrift;
