import React from "react";
import Excel from "../../Common/Excel";

import {
  formatDateHavingNorwegianDateTime,
  formatDateTimeHavingNorwegianDateTime,
} from "../../../utils/datetimeUtils";

const UferdigstilteKlagesakerExcel = ({
  uferdigstilteKlagesaker,
  uferdigstilteKlagesakerSubmittedForm,
  saksbehandler,
}) => {
  return (
    <Excel
      element={(onClick) => (
        <button className="btn btn-bym-medium btn-bym-action" onClick={onClick}>
          Lag rapport
        </button>
      )}
      filename={"Uferdigstilte klagesaker.xlsx"}
      sheets={[
        {
          name: "Uferdigstilte klagesaker",
          data: uferdigstilteKlagesaker.map((uk) => ({
            Saksnummer: uk.saksnummer,
            Vedtakskode: uk.vedtakskode,
            Vedtaksdato: formatDateHavingNorwegianDateTime(uk.vedtaksDato),
            "Ileggelsesnr.": uk.ileggelsesnummer,
            Klagekode: uk.klagekode,
            Saksbehandler: uk.saksbehandler,
          })),
        },
        {
          name: "Søkdata",
          data: [
            {
              "Timestamp for søk": formatDateTimeHavingNorwegianDateTime(
                uferdigstilteKlagesakerSubmittedForm.submitTimestamp
              ),
              Saksbehandler: saksbehandler,
            },
          ],
        },
      ]}
    />
  );
};

export default UferdigstilteKlagesakerExcel;
