export const ileggelseadmin = "ileggelse-admin";
export const stedfortrederDriftsleder = "ileggelse-stedfortreder-driftsleder";

export const skiftlogg = "ileggelse-skiftlogg"; // Tilhører bypatrluljen men skal kun ha tilgång til skiftlogg

export const bypatruljen = {
  saksbehandler: "ileggelse-saksbehandler",
  saksbehandleradmin: "ileggelse-saksbehandler-admin",
  avdelingdirektor: "ileggelse-avdelingsdirektor",
  seksjonssjefStab: "ileggelse-seksjonssjefbypatruljen-plan-forvaltning",
  seksjonssjef: "ileggelse-seksjonssjefbypatruljen",
  driftleder: "ileggelse-driftsleder",
  stedfortrederDriftsleder: stedfortrederDriftsleder,
};

export const juridisk = {
  saksbehandler: "ileggelse-saksbehandler-juridisk",
  ledelse: "ileggelse-ledelse-juridisk",
};

export const innsyn = {
  serviceavdelingVaktsentralen: "ileggelse-serviceavdeling-vaktsentral",
  kemnerkontoret: "ileggelse-kemner",
  seksjonssjefVaktsentralen: "ileggelse-seksjonsjef-vaktsentral",
  regnskap: "ileggelse-regnskap",
  serviceavdeling: "ileggelse-innsyn-service",
  vaktsentralen: "ileggelse-innsyn-vaktsentralen",
};

export const all = {
  ileggelseadmin: ileggelseadmin,
  ...bypatruljen,
  juridiskSaksbehandler: juridisk.saksbehandler,
  juridiskLedelse: juridisk.ledelse,
  ...innsyn,
  skiftlogg: skiftlogg,
};

export const All = [
  ileggelseadmin,
  bypatruljen.saksbehandler,
  bypatruljen.saksbehandleradmin,
  bypatruljen.avdelingdirektor,
  bypatruljen.seksjonssjefStab,
  bypatruljen.seksjonssjef,
  bypatruljen.driftleder,
  bypatruljen.stedfortrederDriftsleder,
  juridisk.saksbehandler,
  juridisk.ledelse,
  innsyn.serviceavdelingVaktsentralen,
  innsyn.vaktsentralen,
  innsyn.serviceavdeling,
  innsyn.kemnerkontoret,
  innsyn.seksjonssjefVaktsentralen,
  innsyn.regnskap,
];

export const bypatruljenArray = [
  bypatruljen.saksbehandler,
  bypatruljen.saksbehandleradmin,
  bypatruljen.avdelingdirektor,
  bypatruljen.seksjonssjefStab,
  bypatruljen.seksjonssjef,
  bypatruljen.driftleder,
  bypatruljen.stedfortrederDriftsleder,
];

export const juridiskArray = [juridisk.saksbehandler, juridisk.ledelse];

export const bypatruljenAndJuridiskArray =
  bypatruljenArray.concat(juridiskArray);

export const innsynArray = [
  innsyn.serviceavdelingVaktsentralen,
  innsyn.vaktsentralen,
  innsyn.serviceavdeling,
  innsyn.kemnerkontoret,
  innsyn.seksjonssjefVaktsentralen,
  innsyn.regnskap,
];
