import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Table from "../../Tables/Table";

import { getIleggelseRettelser as getIleggelseRettelserAction } from "../../../../actions/ileggelseRettelserActions";
import { getBybetjenter as getBybetjenterAction } from "../../../../actions/bybetjenterActions";

import { getIleggelseRettelser } from "../../../../selectors/ileggelseTilbakameldingerFraBybetjenter";

import { toNorwegianDatetime } from "../../../../utils/datetimeUtils";

class TilbakemeldingerFraBybetjenterContainer extends React.Component {
  static propTypes = {
    ileggelseId: PropTypes.string,
    ileggelseRettelser: PropTypes.array,
    bybetjenter: PropTypes.array,
    getIleggelseRettelser: PropTypes.func.isRequired,
    getBybetjenter: PropTypes.func.isRequired,
  };

  componentDidMount = () => {
    this.props.ileggelseId &&
      this.props.getIleggelseRettelser(this.props.ileggelseId);
    if (this.props.bybetjenter.length === 0) {
      this.props.getBybetjenter();
    }
  };

  renderRow = (ileggelseRettelse) => {
    return (
      <tr key={ileggelseRettelse.id}>
        <td>
          {ileggelseRettelse.rettelseDato &&
            toNorwegianDatetime(ileggelseRettelse.rettelseDato).format(
              "DD.MM.YYYY HH:mm"
            )}
        </td>
        <td>{ileggelseRettelse.tjenestenummer}</td>
        <td>{ileggelseRettelse.rettelse}</td>
        <td>{ileggelseRettelse.kommentar}</td>
      </tr>
    );
  };

  render() {
    return (
      <section>
        <div className="detalj-ileggelse-header-wrapper">
          <h2>Tilbakemelding</h2>
        </div>
        <div className="detalj-ileggelse-table-wrapper">
          <Table
            renderRow={this.renderRow}
            items={this.props.ileggelseRettelser}
          >
            <th>Dato</th>
            <th>Tjenestenummer</th>
            <th>Rettelse</th>
            <th>Kommentar</th>
          </Table>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ileggelseRettelser: getIleggelseRettelser(state),
    bybetjenter: state.bybetjenter,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getIleggelseRettelser: (ileggelseId) =>
      dispatch(getIleggelseRettelserAction(ileggelseId)),
    getBybetjenter: () => dispatch(getBybetjenterAction()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TilbakemeldingerFraBybetjenterContainer);
