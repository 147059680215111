import { ILEGGELSE_SERVICE_BASE_URL } from "../config";

export const GET_P360_SAK_URL = (year, saksnummer) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/p360/saker/${year}/${saksnummer}`;
export const GET_P360_SAK_DOKUMENTER_URL = (year, dokumentnummer) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/p360/dokumenter/${year}/${dokumentnummer}`;

export const GET_P360_SAK_FIL_URL = (year, dokumentnummer, fileId) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/p360/dokumenter/${year}/${dokumentnummer}/filer/${fileId}`;

export const UPDATE_P360_SAK_KRYSS_DOKUMENTER_URL = (sakDokumentOrigin) =>
  `${ILEGGELSE_SERVICE_BASE_URL}/p360/dokumenter/${sakDokumentOrigin}/avkrysse`;
