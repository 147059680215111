import React, { Component } from "react";
import PropTypes from "prop-types";
import NavigationBar from "../Common/NavigationBar";
import { withRouter } from "react-router-dom";

import { authorized } from "../../utils/authorizationUtils";

import {
  bypatruljenArray,
  juridiskArray,
  innsyn,
  stedfortrederDriftsleder,
} from "../../constants/roles";
import sitePaths from "../../constants/sitePaths";
import * as title from "../../constants/titles";

export class NavigationBarContainer extends Component {
  static propTypes = {
    page: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      navigationItems: [],
    };
  }

  authorizedIleggelser = authorized([
    innsyn.serviceavdelingVaktsentralen,
    innsyn.vaktsentralen,
    innsyn.serviceavdeling,
    innsyn.kemnerkontoret,
    innsyn.seksjonssjefVaktsentralen,
  ]);
  authorizedMiljogebyr = authorized([
    innsyn.serviceavdelingVaktsentralen,
    innsyn.vaktsentralen,
    innsyn.serviceavdeling,
    innsyn.kemnerkontoret,
    innsyn.seksjonssjefVaktsentralen,
  ]);
  authorizedMotorvogn = authorized([
    ...bypatruljenArray,
    ...juridiskArray,
    innsyn.serviceavdelingVaktsentralen,
    innsyn.vaktsentralen,
    innsyn.serviceavdeling,
    innsyn.seksjonssjefVaktsentralen,
    stedfortrederDriftsleder,
  ]);

  componentDidMount() {
    document.title = title.innsyn(this.props.page);
    this.setNavigationItems();
  }

  setNavigationItems() {
    let navigationItems = [];
    if (this.authorizedIleggelser) {
      navigationItems.push({
        navn: "Ileggelsesøk",
        path: sitePaths.innsyn.ileggelser.oversikt,
      });
    }
    if (this.authorizedMiljogebyr) {
      navigationItems.push({
        navn: "Miljøgebyrsøk",
        path: sitePaths.innsyn.miljogebyrer.oversikt,
      });
    }
    if (this.authorizedMotorvogn) {
      navigationItems.push({
        navn: "Motorvognsøk",
        path: sitePaths.innsyn.motorvogn.shortUrl,
      });
    }
    this.setState({ navigationItems: [...navigationItems] });
  }

  render() {
    return <NavigationBar navigationItems={this.state.navigationItems} />;
  }
}

export default withRouter(NavigationBarContainer);
