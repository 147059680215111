import axios from "../services/axios";
import * as api from "../constants/api";

import { addFlashMessage } from "./FlashMessagesAction";

import { toIsoString } from "../utils/datetimeUtils";
import * as handle from "../utils/actionUtils";
import { getDatoSortedImages, getBase64ObjectURL } from "../utils/imageUtils";
import { errorData } from "../utils/apiResponseUtils";

export const GET_MILJOKONTROLLER_SUCCESS = "GET_MILJOKONTROLLER_SUCCESS";
export const GET_MILJOKONTROLLER_FAILURE = "GET_MILJOKONTROLLER_FAILURE";

export function getMiljokontroller(params) {
  return async (dispatch) => {
    return axios
      .get(api.GET_MILJOKONTROLLER_URL, {
        params: {
          ...params,
          fraDato: params.fraDato && toIsoString(params.fraDato),
          tilDato: params.tilDato && toIsoString(params.tilDato),
        },
      })
      .then((response) => {
        dispatch({
          type: GET_MILJOKONTROLLER_SUCCESS,
          payload: response.data.result,
        });

        return Promise.resolve(response);
      })
      .catch((error) => {
        dispatch(addFlashMessage({ type: "error", message: error }));
        return Promise.reject(error);
      });
  };
}

export const GET_MILJOKONTROLL_SUCCESS = "GET_MILJOKONTROLL_SUCCESS";
export const GET_MILJOKONTROLL_FAILURE = "GET_MILJOKONTROLL_FAILURE";

export function getMiljokontroll(miljokontrollId) {
  return async (dispatch) => {
    return axios
      .get(api.GET_MILJOKONTROLL_URL(miljokontrollId))
      .then(handle.success(dispatch, GET_MILJOKONTROLL_SUCCESS))
      .catch(handle.error(dispatch, GET_MILJOKONTROLL_FAILURE));
  };
}

export const getMiljokontrollBildeBase64 = (
  miljokontrollId,
  observasjonId,
  bildeId
) => {
  return async (dispatch) => {
    return axios
      .get(
        api.GET_MILJOKONTRLL_OBSERVASJON_BILDE_BASE64_URL(
          miljokontrollId,
          observasjonId,
          bildeId
        )
      )
      .then(async (response) => {
        const result = response.data.result;

        const src = await getBase64ObjectURL(
          `data:image/png;base64,${result.base64}`
        );

        return Promise.resolve({ navn: result.filename, src });
      })
      .catch((error) => {
        const errorMessage = errorData(error);
        dispatch(
          addFlashMessage({
            type: "error",
            message: errorMessage,
          })
        );

        return Promise.reject(false);
      });
  };
};

export const GET_MILJOKONTROLL_BILDER_SUCCESS =
  "GET_MILJOKONTROLL_BILDER_SUCCESS";
export const GET_MILJOKONTROLL_LIGHTBOX_BILDER_SUCCESS =
  "GET_MILJOKONTROLL_LIGHTBOX_BILDER_SUCCESS";
export const GET_MILJOKONTROLL_THUMBNAIL_BILDER_SUCCESS =
  "GET_MILJOKONTROLL_THUMBNAIL_BILDER_SUCCESS";

export function getMiljokontrollBilder(miljokontrollId, params = {}) {
  return async (dispatch) => {
    const url = api.GET_MILJOKONTROLLER_BILDER_BASE_URL(miljokontrollId);
    return axios
      .get(url, { params: params })
      .then(async (response) => {
        const result = response.data.result;

        // Wheels within wheels...
        const dateSortedImages = await Promise.all(
          getDatoSortedImages(result).map(async (date) => ({
            ...date,
            images: await Promise.all(
              date.images.map(async (image) => ({
                ...image,
                src: await getBase64ObjectURL(image.src),
              }))
            ),
          }))
        );

        // All promises have resolved
        switch (params["s3BucketCategory"]) {
          case "miljogebyrer_lightbox":
            dispatch({
              type: GET_MILJOKONTROLL_LIGHTBOX_BILDER_SUCCESS,
              payload: {
                miljokontrollId,
                bilder: dateSortedImages,
              },
            });
            break;
          case "miljogebyrer_thumbnail":
            dispatch({
              type: GET_MILJOKONTROLL_THUMBNAIL_BILDER_SUCCESS,
              payload: {
                miljokontrollId,
                bilder: dateSortedImages,
              },
            });
            break;
          default:
            dispatch({
              type: GET_MILJOKONTROLL_BILDER_SUCCESS,
              payload: {
                miljokontrollId,
                bilder: dateSortedImages,
              },
            });
            break;
        }

        return dateSortedImages;
      })
      .catch((error) => {
        dispatch(
          addFlashMessage({
            type: "error",
            message: error,
          })
        );
        return Promise.reject(false);
      });
  };
}

export const putMiljokontrollObservasjonBilde =
  (
    bildeId,
    miljokontrollId,
    observasjonId,
    payload,
    getSingleAfterUpdateSuccess,
    getAllAfterUpdateSuccess
  ) =>
  async (dispatch) => {
    return axios
      .put(
        api.PUT_MILJOKONTROLL_OBSERVASJON_BILDE_URL(
          bildeId,
          miljokontrollId,
          observasjonId
        ),
        payload
      )
      .then((response) => {
        dispatch(addFlashMessage({ type: "success", text: "Oppdatert" }));
        if (getSingleAfterUpdateSuccess) {
          getSingleAfterUpdateSuccess();
        }
        if (getAllAfterUpdateSuccess) {
          getAllAfterUpdateSuccess();
        }

        let result = response && response.data && response.data.result;
        return Promise.resolve(result);
      })
      .catch((error) => {
        dispatch(addFlashMessage({ type: "error", message: error }));
        return Promise.reject(error);
      });
  };

export const postMiljokontrollObservasjonBilde =
  (
    miljokontrollId,
    observasjonId,
    file,
    orginalbildeId,
    getSingleAfterUpdateSuccess,
    getAllAfterUpdateSuccess
  ) =>
  async (dispatch) => {
    let formData = new FormData();
    formData.append("Bilde", file);
    formData.append("OrginalbildeId", orginalbildeId);
    return axios
      .post(
        api.POST_MILJOKONTROLL_OBSERVASJON_BILDE_URL(
          miljokontrollId,
          observasjonId
        ),
        formData
      )
      .then((response) => {
        dispatch(addFlashMessage({ type: "success", text: "Opprettet bilde" }));
        if (getSingleAfterUpdateSuccess) {
          getSingleAfterUpdateSuccess();
        }
        if (getAllAfterUpdateSuccess) {
          getAllAfterUpdateSuccess();
        }

        let result = response && response.data && response.data.result;
        return Promise.resolve(result);
      })
      .catch((error) => {
        dispatch(addFlashMessage({ type: "error", message: error }));
        return Promise.reject(error);
      });
  };

export const putMiljokontrollAvslutt =
  (
    miljokontrollId,
    payload,
    getSingleAfterUpdateSuccess,
    getAllAfterUpdateSuccess,
    getHistorikkAfterUpdateSuccess,
  ) =>
  async (dispatch) => {
    return axios
      .put(api.PUT_MILJOKONTROLL_AVSLUTT_URL(miljokontrollId), payload)
      .then((response) => {
        dispatch(addFlashMessage({ type: "success", text: "Oppdatert" }));
        if (getSingleAfterUpdateSuccess) {
          getSingleAfterUpdateSuccess();
        }
        if (getAllAfterUpdateSuccess) {
          getAllAfterUpdateSuccess();
        }
        if (getHistorikkAfterUpdateSuccess) {
          getHistorikkAfterUpdateSuccess();
        }

        let result = response && response.data && response.data.result;
        return Promise.resolve(result);
      })
      .catch((error) => {
        dispatch(addFlashMessage({ type: "error", message: error }));
        return Promise.reject(error);
      });
  };

export const GET_MILJOKONTROLL_KOMMENTARER_SUCCESS =
  "GET_MILJOKONTROLL_KOMMENTARER_SUCCESS";

export const getMiljokontrollKommentarer = (miljokontrollId) => {
  return async (dispatch) => {
    const url = api.GET_MILJOKONTROLL_KOMMENTARER_BASE_URL(miljokontrollId);
    return axios
      .get(url)
      .then((response) => {
        dispatch({
          type: GET_MILJOKONTROLL_KOMMENTARER_SUCCESS,
          payload: response.data.result,
        });
        return Promise.resolve(response.data.result);
      })
      .catch((error) => {
        dispatch(
          addFlashMessage({
            type: "error",
            message: error,
          })
        );
        return Promise.reject(false);
      });
  };
};

export const deleteMiljokontrollObservasjonBilde =
  (bildeId, miljokontrollId, observasjonId, getAllAfterUpdateSuccess) =>
  async (dispatch) => {
    return axios
      .delete(
        api.DELETE_MILJOKONTROLL_OBSERVASJON_BILDE_URL(
          bildeId,
          miljokontrollId,
          observasjonId
        )
      )
      .then((response) => {
        dispatch(addFlashMessage({ type: "success", text: "Oppdatert" }));
        if (getAllAfterUpdateSuccess) {
          getAllAfterUpdateSuccess();
        }

        let result = response && response.data && response.data.result;
        return Promise.resolve(result);
      })
      .catch((error) => {
        dispatch(addFlashMessage({ type: "error", message: error }));
        return Promise.reject(error);
      });
  };

export const GET_MILJOKONTROLL_HISTORIKK_SUCCESS = "GET_MILJOKONTROLL_HISTORIKK_SUCCESS";
export const GET_MILJOKONTROLL_HISTORIKK_FAILURE = "GET_MILJOKONTROLL_HISTORIKK_FAILURE";

export const getAllMiljoKontrollHistorikk = (miljoKontrollId) =>
  async (dispatch) => {
    return axios.get(api.GET_MILJOKONTROLL_HISTORIKK(miljoKontrollId))
      .then(handle.success(dispatch, GET_MILJOKONTROLL_HISTORIKK_SUCCESS))
      .catch(handle.error(dispatch, GET_MILJOKONTROLL_HISTORIKK_FAILURE));
};
