const inntauingSitePaths = {
    shortUrl: "/inntauing",
    ileggelser: {
      shortUrl: "/inntauing/ileggelser",
      detalj: {
        shortUrl:
          "/inntauing/ileggelser/:id([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})",
      },
    },
  };
  
  export default inntauingSitePaths;