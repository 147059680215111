import React from "react";
import PropTypes from "prop-types";

import "./GridTable.css";
// style={{ display: "block", overflowX: "auto" }}
export const GridTable = (props) => {
  return (
    <div id={props.id} className="grid-table-wrapper">
      <table
        className={
          props.oneColoredGridTable ? "one-colored-grid-table" : "grid-table"
        }
      >
        <tbody>
          {props.rows.map((row, index1) => (
            <tr key={`${props.name}${index1}`}>
              {row.map((columnObject, index2) => {
                return (
                  <td key={`${props.name}${index1}${index2}`}>
                    {(columnObject.name ||
                      columnObject.value ||
                      columnObject.description) && (
                      <div>
                        <div className="grid-table-name">
                          {columnObject.name || "-"}
                        </div>
                        <div className="grid-table-value">
                          {columnObject.value || "-"}
                        </div>
                      </div>
                    )}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

GridTable.propTypes = {
  name: PropTypes.string.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
        description: PropTypes.string,
      })
    )
  ),
  oneColoredGridTable: PropTypes.bool,
};

export default GridTable;
