import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import DatetimePicker from "../../Common/Forms/DatetimePickerEnhanced";
import TextInput from "../../Common/Forms/TextInput";
import DropDown from "../../Common/Forms/DropDown";
import LoadButton from "../../Common/LoadButton";

import { getAktorer } from "../../../actions/aktorerActions";
import { getElsparksykkelGebyr } from "../../../actions/elsparksykkelGebyrActions";
import {
  handleChange as handleChangeAction,
  handleChangeToInitState as handleChangeToInitStateAction,
} from "../../../actions/handleChangeAction";

import { handleFromToDateTime } from "../../../utils/fromToDateTimeUtils";
import * as inputValidation from "../../../utils/inputValidationUtils";
import { IsValidated } from "../../../utils/validationUtils";
import { visAlleJaNei } from "../../../constants/globals";

const rules = {
  ileggelsesnummer: "isPositiveInteger",
  tjenestenummer: "isPositiveInteger",
};

const FilterForm = () => {
  const [touched, setIsTouched] = useState(false);
  const [showSokSpinner, setShowSokSpinner] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAktorer());
  }, [dispatch]);

  const elsparksykkelGebyrFilterForm = useSelector(
    (store) => store.forms.elsparksykkelGebyrFilterForm
  );

  const elsparksykkelGebyr = useSelector((store) => store.elsparksykkelGebyr);

  const aktorer = useSelector((store) => store.aktorer);

  const getPropsAsFakeState = () => {
    return {
      ileggelsesnummer: elsparksykkelGebyrFilterForm.ileggelsesnummer,
      tjenestenummer: elsparksykkelGebyrFilterForm.tjenestenummer,
      validationError: {
        ileggelsesnummer: elsparksykkelGebyrFilterForm.ileggelsesnummer,
        tjenestenummer: elsparksykkelGebyrFilterForm.tjenestenummer,
      },
    };
  };

  const isValid = (eventName = undefined, eventValue = undefined) => {
    let propsAsFakeState =
      eventName && eventValue
        ? { ...getPropsAsFakeState(), [eventName]: eventValue }
        : getPropsAsFakeState();
    const { isValid, validationError } = IsValidated(propsAsFakeState, rules);
    dispatch(
      handleChangeAction(
        "ELSPARKSYKKEL_GEBYR_FILTER_FORM",
        "validationError.ileggelsesnummer",
        validationError.ileggelsesnummer
      )
    );
    dispatch(
      handleChangeAction(
        "ELSPARKSYKKEL_GEBYR_FILTER_FORM",
        "validationError.tjenestenummer",
        validationError.tjenestenummer
      )
    );

    const fraDateTimeTilDateTimeValid =
      inputValidation.fraDateTimeTilDateTimeValidation(
        elsparksykkelGebyrFilterForm,
        (name, value) =>
          dispatch(
            handleChangeAction(
              "ELSPARKSYKKEL_GEBYR_FILTER_FORM",
              `validationError.${name}`,
              value
            )
          )
      );

    return isValid && fraDateTimeTilDateTimeValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsTouched(true);
    if (isValid()) {
      setShowSokSpinner(true);
      dispatch(
        getElsparksykkelGebyr(
          elsparksykkelGebyrFilterForm,
          1,
          elsparksykkelGebyr.count
        )
      )
        .then(() => {
          setIsTouched(false);
          setShowSokSpinner(false);
        })
        .catch(() => {
          setIsTouched(false);
          setShowSokSpinner(false);
        });
    }
  };

  const handleChange = (e) => {
    if (touched) {
      isValid(e.target.name, e.target.value);
    }

    if (
      e.target.name === "fraDato" ||
      e.target.name === "tilDato" ||
      e.target.name === "fraTid" ||
      e.target.name === "tilTid"
    ) {
      handleFromToDateTime(e, elsparksykkelGebyrFilterForm, (name, value) =>
        dispatch(
          handleChangeAction("ELSPARKSYKKEL_GEBYR_FILTER_FORM", name, value)
        )
      );
    } else {
      dispatch(
        handleChangeAction(
          "ELSPARKSYKKEL_GEBYR_FILTER_FORM",
          e.target.name,
          e.target.value
        )
      );
    }
  };

  const handleChangeToInitState = () => {
    dispatch(handleChangeToInitStateAction("ELSPARKSYKKEL_GEBYR_FILTER_FORM"));
  };

  return (
    <div className="ettersendelse-filter-container">
      <h1>Søk etter elsparkesykler</h1>
      <form id="ettersendelse-filter" onSubmit={handleSubmit}>
        <div className="filter-flexbox-container ettersendelse-filter-flexbox-item">
          <TextInput
            label="Ileggelsesnummer"
            name="ileggelsesnummer"
            onChange={handleChange}
            value={elsparksykkelGebyrFilterForm.ileggelsesnummer}
            validationError={
              elsparksykkelGebyrFilterForm.validationError.ileggelsesnummer
            }
          />
          <DatetimePicker
            id="fraDato"
            name="fraDato"
            locale="nb"
            label="Dato fra"
            onChange={handleChange}
            value={elsparksykkelGebyrFilterForm.fraDato}
            validationError={
              elsparksykkelGebyrFilterForm.validationError
                .fraDatoValidationErrorText
            }
            timeFormat={false}
          />
          <DatetimePicker
            id="fraTid"
            name="fraTid"
            locale="nb"
            label="Tid fra"
            onChange={handleChange}
            value={elsparksykkelGebyrFilterForm.fraTid}
            validationError={
              elsparksykkelGebyrFilterForm.validationError
                .fraTidValidationErrorText
            }
            dateFormat={false}
          />
          <DatetimePicker
            id="tilDato"
            name="tilDato"
            locale="nb"
            label="Dato til"
            onChange={handleChange}
            value={elsparksykkelGebyrFilterForm.tilDato}
            validationError={
              elsparksykkelGebyrFilterForm.validationError
                .tilDatoValidationErrorText
            }
            timeFormat={false}
          />
          <DatetimePicker
            id="tilTid"
            name="tilTid"
            locale="nb"
            label="Tid til"
            onChange={handleChange}
            value={elsparksykkelGebyrFilterForm.tilTid}
            validationError={
              elsparksykkelGebyrFilterForm.validationError
                .tilTidValidationErrorText
            }
            dateFormat={false}
          />
          <TextInput
            label="Tjenestenummer"
            name="tjenestenummer"
            onChange={handleChange}
            value={elsparksykkelGebyrFilterForm.tjenestenummer}
            validationError={
              elsparksykkelGebyrFilterForm.validationError.tjenestenummer
            }
          />
          <DropDown
            label="Aktør"
            name="aktorId"
            items={aktorer}
            selected={elsparksykkelGebyrFilterForm.aktorId}
            title="Velg"
            onChange={handleChange}
          />
          <DropDown
            label="Makulert"
            name="isMakulert"
            items={visAlleJaNei}
            selected={elsparksykkelGebyrFilterForm.isMakulert}
            title="Velg"
            onChange={handleChange}
          />
        </div>
        <div style={{ height: 20 }} />
        <div>
          <LoadButton
            buttonProps={{
              onClick: handleChangeToInitState,
              className: "btn-link",
              style: { height: 40, paddingRight: 17, paddingLeft: 0 },
            }}
            spinnerProps={{ pColor: "#ffffff", sColor: "#4d4d4d" }}
          >
            Tilbakestill
          </LoadButton>
          <LoadButton
            isLoading={showSokSpinner}
            buttonProps={{
              type: "submit",
              className: "btn-bym-action btn-bym-large",
              disabled: showSokSpinner,
            }}
            spinnerProps={{ pColor: "#ffffff", sColor: "#4d4d4d" }}
          >
            Søk
          </LoadButton>
        </div>
      </form>
    </div>
  );
};

export default FilterForm;
