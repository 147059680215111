import { connect } from "react-redux";
import MiljokontrollOversiktList from "./MiljokontrollOversiktList";

const mapStateToProps = (state) => {
  return {
    miljokontroller: state.miljokontroller,
  };
};

export default connect(mapStateToProps)(MiljokontrollOversiktList);
