import _ from "lodash";
import axios from "axios";
import { getAccessToken } from "../utils/authorizationUtils";

let userToken = null;

const instance = axios.create({});

instance.interceptors.request.use(
  (config) => {
    const accessToken = getAccessToken();
    config.headers['Authorization'] = `Bearer ${accessToken}`;
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

export function setToken(token) {
  userToken = token;
  // TODO Bug in axios https://github.com/axios/axios/issues/385
  // Remove first and third line when bug is fixed
  const originalHeaders = _.cloneDeep(axios.defaults.headers);
  instance.defaults.headers.common.Authorization = "Bearer " + token;
  axios.defaults.headers = originalHeaders;
}

export function clearToken() {
  userToken = null;
  delete instance.defaults.headers.common.Authorization;
}

export function getToken() {
  return userToken;
}

export default instance;
