import * as elsparksykkelGebyrActions from "../actions/elsparksykkelGebyrActions";

const INITIAL_STATE = [];

export default function elsparksykkelGebyrReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case elsparksykkelGebyrActions.GET_ELSPARKSYKKELGEBYR_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
