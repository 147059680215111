import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty } from "lodash";

import KlageSearchForm from "./KlageSearchForm";
import ModalGeneric from "../Common/ModalGeneric";
import Spinner from "../Common/Spinner";
import TextInput from "../Common/Forms/TextInput";

import {
  getKlageInfo,
  addKlage,
  updateKlage,
  getKlageIleggelser,
  deletePermanentKlage,
} from "../../actions/klager/klageAction";
import { getKlageVedtaker } from "../../actions/klager/vedtakAction";
import {
  getP360Sak,
  nullStilleKlageSidenState,
} from "../../actions/p360Action";
import {
  handleChange as handleChangeAction,
  handleChangeToInitState as handleChangeToInitStateAction,
} from "../../actions/handleChangeAction";
import {
  KLAGE_ILEGGELSER_FILTER_FORM,
  INITIAL_STATE as klageIleggelseFilterFormStateInitial,
} from "../../reducers/forms/klageIleggelseFilterFormReducer";
import klageUtils from "../../utils/klageUtils";
import { replaceWhitespacesWithEmptyStrings } from "../../utils/sanitizationUtils";

import { ikkeGyldigSaksnummer } from "../../constants/ErrorMessages";

export class KlageSearchFormContainer extends PureComponent {
  static propTypes = {
    KlageIleggelserFilterFormState: PropTypes.object,
    vedtaker: PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.arrayOf(PropTypes.shape({})),
    ]),
    handleChange: PropTypes.func,
    onClean: PropTypes.func,
    addKlage: PropTypes.func,
    updateKlage: PropTypes.func,
  };

  state = {
    error: "",
    p360Sak: {},
    shallGetIleggelserDueToHandleChangeToInitState: false,
    isLoading: false,
    showSokSpinner: false,
    showConfirmation: false,
    showKobleTilP360: false,
    kobleTilP360SubmitSpinner: false,
    p360Saksnummer: undefined,
    p360SaksnummerValidationError: undefined,
    inputSaksnummer: null,
    showKlageSearchWithoutFerdigbehandletVedtakConfirmationModal: false,
  };

  componentDidUpdate() {
    if (
      JSON.stringify(this.props.KlageIleggelserFilterFormState) ===
        JSON.stringify(klageIleggelseFilterFormStateInitial) &&
      this.state.shallGetIleggelserDueToHandleChangeToInitState === true
    ) {
      this.setState({ shallGetIleggelserDueToHandleChangeToInitState: false });
    }
  }

  loadKlageOgVedtakIleggelser = async (klageId) => {
    await this.props.getKlageIleggelser(klageId);
    await this.props.getKlageVedtaker(klageId);
  };

  handleChange = (e) => {
    this.props.handleChange(e.target.name, e.target.value);
  };

  handleChangeToInitState = () => {
    this.props.handleChangeToInitState();
    this.setState({ shallGetIleggelserDueToHandleChangeToInitState: true });
    this.props.nullStilleKlageSidenState();
  };

  klagePayload = (saksnummer, kontakt) => {
    return {
      saksnummer: saksnummer,
      navn: (kontakt && kontakt.navn) || "",
      gateadresse: (kontakt && kontakt.adresse) || "",
      poststed: (kontakt && kontakt.poststed) || "",
      postnummer: (kontakt && kontakt.postnummer) || "",
      land: (kontakt && kontakt.land) || "",
      epost: (kontakt && kontakt.epost) || "",
    };
  };

  handleBlur = (e) => {
    const { handleChange } = this.props;

    if (e.target.name === "saksnummer") {
      handleChange(
        e.target.name,
        replaceWhitespacesWithEmptyStrings(e.target.value)
      );
    }
  };

  validateSaksnummer = (saksnummer) => {
    const inputSaksnummer =
      saksnummer && replaceWhitespacesWithEmptyStrings(saksnummer);
    if (!inputSaksnummer) {
      return false;
    }
    if (!klageUtils.getSaksnummerObject(inputSaksnummer)) {
      return false;
    }
    return inputSaksnummer;
  };

  onKlageSearchSubmitJob = async (inputSaksnummer) => {
    const { KlageIleggelserFilterFormState } = this.props;

    const saksnummerObject = klageUtils.getSaksnummerObject(inputSaksnummer);
    try {
      this.setState(() => ({ isLoading: true, showSokSpinner: true }));
      const p360Sak = await this.props.getP360Sak(saksnummerObject);
      this.setState(() => ({
        isLoading: false,
        showSokSpinner: false,
      }));
      if (p360Sak) {
        this.setState({ p360Sak: p360Sak });
        if (KlageIleggelserFilterFormState.kobleTilP360) {
          this.setState({
            showKobleTilP360: true,
            isLoading: false,
            showSokSpinner: false,
          });
          const inputP360SaksnummerElement =
            document.getElementById("p360Saksnummer");
          inputP360SaksnummerElement.focus();
          return;
        } else if (
          !KlageIleggelserFilterFormState.kobleTilP360 &&
          p360Sak.accessGroup &&
          p360Sak.accessGroup.toUpperCase() !== "KLAGE"
        ) {
          this.setState({
            showConfirmation: true,
            // isLoading: false,
            // showSokSpinner: false,
          });
        } else {
          await this.confirmSakSearch(null, p360Sak);
        }
      } else {
        this.setState({
          isLoading: false,
          showSokSpinner: false,
        });
      }
    } catch (err) {
      this.setState({
        isLoading: false,
        showSokSpinner: false,
      });
    }
  };

  getHasIkkeFerdigbehandletVedtaker = () => {
    const { vedtaker } = this.props;

    var vedtakerArray = Object.values(vedtaker);
    var hasIkkeFerdigbehandletVedtaker = false;
    for (const vedtak of vedtakerArray) {
      if (vedtak.vedtaksDato === null) {
        hasIkkeFerdigbehandletVedtaker = true;
        break;
      }
    }

    return hasIkkeFerdigbehandletVedtaker;
  };

  onKlageSearchSubmit = async (e) => {
    e.preventDefault();
    const { KlageIleggelserFilterFormState } = this.props;
    const inputSaksnummer = this.validateSaksnummer(
      KlageIleggelserFilterFormState.saksnummer
    );
    if (inputSaksnummer && !this.getHasIkkeFerdigbehandletVedtaker()) {
      this.onKlageSearchSubmitJob(inputSaksnummer);
    } else if (inputSaksnummer && this.getHasIkkeFerdigbehandletVedtaker()) {
      this.setState({
        inputSaksnummer: inputSaksnummer,
        showKlageSearchWithoutFerdigbehandletVedtakConfirmationModal: true,
      });
    }
  };

  onConfirmKlageSearchSubmitJob = () => {
    const { inputSaksnummer } = this.state;

    this.onKlageSearchSubmitJob(inputSaksnummer);
    this.closeKlageSearchWithoutFerdigbehandletVedtakConfirmationModal();
  };

  closeKlageSearchWithoutFerdigbehandletVedtakConfirmationModal = () => {
    this.setState({
      showKlageSearchWithoutFerdigbehandletVedtakConfirmationModal: false,
    });
  };

  closeModal = () => {
    this.setState({
      showConfirmation: false,
      showKobleTilP360: false,
      p360Sak: {},
    });
  };

  openModal = () => {
    this.setState({ showConfirmation: true });
  };

  confirmSakSearch = async (e, p360SakParam = undefined) => {
    this.setState({ showConfirmation: false });
    const { p360Sak } = this.state;
    const p360SakVar =
      p360SakParam && !isEmpty(p360SakParam) ? p360SakParam : p360Sak;
    const {
      getKlageInfo,
      addKlage,
      updateKlage,
      KlageIleggelserFilterFormState,
    } = this.props;
    const inputSaksnummer = this.validateSaksnummer(
      KlageIleggelserFilterFormState.saksnummer
    );
    let klage;
    if (!isEmpty(p360SakVar)) {
      klage = await getKlageInfo(inputSaksnummer);
    }
    if (!isEmpty(klage)) {
      await updateKlage({
        ...this.klagePayload(inputSaksnummer, p360SakVar.kontakt),
        id: klage.id,
      });
      klage = await getKlageInfo(inputSaksnummer);
    }
    if (isEmpty(klage) && !isEmpty(p360SakVar)) {
      const kontakt = p360SakVar.kontakt;
      const payload = this.klagePayload(inputSaksnummer, kontakt);
      await addKlage(payload);
    }
    this.setState(() => ({
      isLoading: false,
      showSokSpinner: false,
      p360SakVar: {},
    }));
  };

  submitKobleTilP360Sak = async (e) => {
    e.preventDefault();

    const {
      getKlageInfo,
      deletePermanentKlage,
      updateKlage,
      KlageIleggelserFilterFormState,
    } = this.props;
    const { p360Saksnummer, p360Sak } = this.state;

    if (!this.validateSaksnummer(p360Saksnummer)) {
      this.setState({ p360SaksnummerValidationError: ikkeGyldigSaksnummer });
      return;
    }
    this.setState({ p360SaksnummerValidationError: null });

    this.setState(() => ({
      kobleTilP360SubmitSpinner: true,
    }));

    const p360KlageFraHgk = await getKlageInfo(p360Saksnummer, false, null);
    if (!!!isEmpty(p360KlageFraHgk)) {
      const klageSak = await getKlageInfo(
        KlageIleggelserFilterFormState.saksnummer
      );
      let shouldUpdateKlage = true;
      if (!!!isEmpty(klageSak)) {
        await deletePermanentKlage(klageSak.id).catch(() => {
          this.setState({ kobleTilP360SubmitSpinner: false });
          shouldUpdateKlage = false;
        });
      }

      if (shouldUpdateKlage) {
        await updateKlage({
          ...this.klagePayload(
            KlageIleggelserFilterFormState.saksnummer,
            p360Sak.kontakt
          ),
          id: p360KlageFraHgk.id,
        });

        await getKlageInfo(KlageIleggelserFilterFormState.saksnummer);
      }

      this.setState({
        showKobleTilP360: false,
        kobleTilP360SubmitSpinner: false,
      });

      return;
    }

    this.setState({ kobleTilP360SubmitSpinner: false });
  };

  handleP360SaksnummerChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { KlageIleggelserFilterFormState } = this.props;
    const {
      isLoading,
      showSokSpinner,
      showConfirmation,
      showKobleTilP360,
      kobleTilP360SubmitSpinner,
      p360SaksnummerValidationError,
      showKlageSearchWithoutFerdigbehandletVedtakConfirmationModal,
    } = this.state;
    return (
      <div className="kalge">
        <KlageSearchForm
          showSokSpinner={showSokSpinner}
          KlageIleggelseFilterFormState={KlageIleggelserFilterFormState}
          handleChange={this.handleChange}
          handleBlur={this.handleBlur}
          handleChangeToInitState={this.handleChangeToInitState}
          onSubmit={this.onKlageSearchSubmit}
        />
        {showKobleTilP360 && (
          <ModalGeneric
            id="kobleTilP360"
            modalTitle={"Finn gammel P360-sak og endre til nytt Acos-nummer"}
            open={showKobleTilP360}
            closeModal={() => this.closeModal()}
            submit={(e) => this.submitKobleTilP360Sak(e)}
            showSubmitSpinner={kobleTilP360SubmitSpinner}
            submitButtonText="Endre"
            isForm
          >
            <TextInput
              id="p360Saksnummer"
              name="p360Saksnummer"
              label="Saksnummer for P360-sak"
              placeholder="Saksnummer"
              onChange={this.handleP360SaksnummerChange}
              validationError={p360SaksnummerValidationError}
            />
          </ModalGeneric>
        )}
        {showConfirmation && (
          <ModalGeneric
            id="ids"
            modalTitle={"Saksnummer uten klage"}
            open={showConfirmation}
            closeModal={() => this.closeModal()}
            openModal={() => this.openModal()}
            submit={() => this.confirmSakSearch()}
            submitButtonText="Fortsett"
          >
            <p>Saksnummer har ingen klage, Vil du fortsette? </p>
          </ModalGeneric>
        )}
        {showKlageSearchWithoutFerdigbehandletVedtakConfirmationModal && (
          <ModalGeneric
            id="sifvc"
            modalTitle="Det finnes vedtak som ikke er ferdigbehandlet"
            open={showKlageSearchWithoutFerdigbehandletVedtakConfirmationModal}
            closeModal={
              this.closeKlageSearchWithoutFerdigbehandletVedtakConfirmationModal
            }
            submit={this.onConfirmKlageSearchSubmitJob}
            submitButtonText="Fortsett"
          >
            <p>Vil du gå videre uten å ferdigbehandle vedtakene?</p>
          </ModalGeneric>
        )}
        <Spinner isLoading={isLoading} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  KlageIleggelserFilterFormState: state.forms.KlageIleggelseFilterFormState,
  vedtaker: state.vedtaker,
});

const mapDispatchToProps = (dispatch) => {
  return {
    handleChange: (variableName, value) =>
      dispatch(
        handleChangeAction(KLAGE_ILEGGELSER_FILTER_FORM, variableName, value)
      ),
    handleChangeToInitState: () =>
      dispatch(handleChangeToInitStateAction(KLAGE_ILEGGELSER_FILTER_FORM)),
    getKlageInfo: (saksnummer) => dispatch(getKlageInfo(saksnummer)),
    deletePermanentKlage: (klageId) => dispatch(deletePermanentKlage(klageId)),
    addKlage: (payload) => dispatch(addKlage(payload)),
    updateKlage: (payload) => dispatch(updateKlage(payload)),
    getKlageIleggelser: (klageId) => dispatch(getKlageIleggelser(klageId)),
    getKlageVedtaker: (klageId) => dispatch(getKlageVedtaker(klageId)),
    getP360Sak: (saksnummerObject) => dispatch(getP360Sak(saksnummerObject)),
    nullStilleKlageSidenState: () => dispatch(nullStilleKlageSidenState()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KlageSearchFormContainer);
