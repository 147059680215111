import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Modal from "../../../Common/ModalGeneric";

import {
  getIleggelse as getIleggelseAction,
  getIleggelseKlager as getIleggelseKlagerAction,
} from "../../../../actions/ileggelserActions";
import { deleteIleggelserForsinketMakulering as deleteIleggelserForsinketMakuleringAction } from "../../../../actions/ileggelseForsinketMakuleringerActions";

class OpphevForsinketMakuleringContainer extends React.Component {
  static propTypes = {
    ileggelse: PropTypes.object,
    deleteIleggelserForsinketMakulering: PropTypes.func.isRequired,
    getIleggelse: PropTypes.func.isRequired,
    reloadHistorikk: PropTypes.func.isRequired,
  };

  state = {
    open: false,
    disabled: false,
    showSubmitSpinner: false,
  };

  handleApiCall = async (event) => {
    event.preventDefault();

    const {
      ileggelse,
      deleteIleggelserForsinketMakulering,
      getIleggelse,
      getIleggelseKlager,
      reloadHistorikk,
    } = this.props;
    this.setState({ showSubmitSpinner: true });
    deleteIleggelserForsinketMakulering(ileggelse.id)
      .then(() => {
        getIleggelse(ileggelse.id);
        getIleggelseKlager(ileggelse.id);
        reloadHistorikk();
        this.closeModal();
      })
      .catch(() => {
        this.setState({ showSubmitSpinner: false });
      });
  };

  openModal = () => {
    this.setState({ open: true });
  };

  closeModal = () => {
    this.setState({ open: false, showSubmitSpinner: false });
  };

  showForsinketMakulering = () => {
    const { ileggelse } = this.props;
    if (
      ileggelse &&
      ileggelse.forsinketMakuleringStatus &&
      ileggelse.forsinketMakuleringStatus.deletePossible
    ) {
      return true;
    }
    return false;
  };

  render() {
    const { ileggelse } = this.props;
    const { open, disabled, showSubmitSpinner } = this.state;

    const ileggelseId = ileggelse && ileggelse.id;

    if (this.showForsinketMakulering()) {
      return (
        <span style={{ marginLeft: 20 }}>
          <Modal
            id={ileggelseId}
            modalTitle={`Opphev forsinket makulering av ileggelse ${ileggelse.ileggelsesnummer}`}
            open={open}
            closeModal={this.closeModal}
            openModal={this.openModal}
            popUpButtonText="Opphev forsinket makulering"
            isButtonLink
            submit={this.handleApiCall}
            disabled={disabled}
            submitButtonText="Opphev forsinket makulering"
            showSubmitSpinner={showSubmitSpinner}
            popUpButtonType="button"
          >
            <React.Fragment>
              <p>
                Vil du oppeheve forsinket makulering av ileggelse{" "}
                {ileggelse.ileggelsesnummer}?
              </p>
            </React.Fragment>
          </Modal>
        </span>
      );
    }
    return <span />;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ileggelse: state.ileggelse,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    deleteIleggelserForsinketMakulering: (ileggelseId) =>
      dispatch(deleteIleggelserForsinketMakuleringAction(ileggelseId)),
    getIleggelse: (ileggelseId) => dispatch(getIleggelseAction(ileggelseId)),
    getIleggelseKlager: (ileggelseId) =>
      dispatch(getIleggelseKlagerAction(ileggelseId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OpphevForsinketMakuleringContainer);
