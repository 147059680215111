import { actionTypeUbetalteIleggelserKjennemerkeGroups } from "../../actions/rapporter/ubetalteIleggelserActions";
import { CreateNewReducer } from "../../utils/reducerUtils";

const initialState = [];
export const ubetalteIleggelserKjennemerkeGroupsReducer = (
  state = [...initialState],
  action
) =>
  CreateNewReducer(
    state,
    action,
    actionTypeUbetalteIleggelserKjennemerkeGroups
  );

export default ubetalteIleggelserKjennemerkeGroupsReducer;
