import { authorized } from "./authorizationUtils";
import {
  innsynArray,
  innsyn,
  juridiskArray,
  juridisk,
  bypatruljenArray,
  bypatruljen,
  skiftlogg,
  ileggelseadmin,
  stedfortrederDriftsleder,
} from "../constants/roles";
import landingPages from "../constants/landingPages";

import sitePaths from "../constants/sitePaths";

export const getLandingPage = () => {
  if (authorized([ileggelseadmin])) {
    return landingPages.ileggelseAdmin;
  }
  if (authorized([stedfortrederDriftsleder])) {
    return landingPages.stedfortrederDriftsleder;
  }
  if (authorized(bypatruljenArray)) {
    return landingPages.bypatruljen;
  }
  if (authorized(juridiskArray)) {
    return landingPages.juridisk;
  }
  if (authorized(innsynArray)) {
    return landingPages.innsyn;
  }

  if (authorized([skiftlogg])) {
    return landingPages.skiftlogg;
  }

  return sitePaths.login;
};

export const getRedirectPath = (path) => {
  switch (path) {
    case sitePaths.innsyn.shortUrl:
      if (
        authorized([
          ...bypatruljenArray,
          ...juridiskArray,
          stedfortrederDriftsleder,
        ])
      ) {
        return sitePaths.innsyn.motorvogn.shortUrl;
      } else if (
        authorized([
          innsyn.serviceavdelingVaktsentralen,
          innsyn.vaktsentralen,
          innsyn.serviceavdeling,
          innsyn.kemnerkontoret,
          innsyn.seksjonssjefVaktsentralen,
        ])
      ) {
        return sitePaths.innsyn.ileggelser.oversikt;
      }
      return getLandingPage();
    case sitePaths.rapporter.shortUrl:
      if (
        authorized([
          bypatruljen.saksbehandler,
          bypatruljen.saksbehandleradmin,
          bypatruljen.avdelingdirektor,
          bypatruljen.seksjonssjefStab,
          bypatruljen.seksjonssjef,
          bypatruljen.driftleder,
          ...Object.values(juridisk),
        ])
      ) {
        return sitePaths.rapporter.ventilstillinger.oversikt;
      } else if (authorized([stedfortrederDriftsleder])) {
        return sitePaths.rapporter.skiftlogg.oversikt;
      }
      return getLandingPage();
    default:
      return getLandingPage();
  }
};

export const comparePathWithSitePath = (sitePath) => {
  const sitePathSplit = sitePath.split("/");
  const pathSplit = window.location.pathname.split("/");

  if (sitePathSplit.length !== pathSplit.length) {
    return false;
  }

  for (let index = 0; index < pathSplit.length; index++) {
    const sitePathElement = sitePathSplit[index];
    const pathElement = pathSplit[index];

    if (sitePathElement.includes(":")) {
      continue;
    }

    if (sitePathElement === pathElement) {
      continue;
    }

    return false;
  }

  return true;
};
