import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";

import "./DetaljKommentarer.css";

function DetaljKommentarer(props) {
  return (
    <div className="detalj-ileggelse-kommentarerer">
      <Row>
        <Col xs={6}>
          <h5>Internkommentar</h5>
          <p>{props.internkommentarFraBybetjent || "-"}</p>
        </Col>
        <Col xs={6}>
          <h5>Kommentar til mottaker</h5>
          <p>{props.kommentarTilMottager || "-"}</p>
        </Col>
      </Row>
    </div>
  );
}

DetaljKommentarer.propTypes = {
  internkommentarFraBybetjent: PropTypes.string,
  kommentarTilMottager: PropTypes.string
};
DetaljKommentarer.defaultProps = {};

export default DetaljKommentarer;
