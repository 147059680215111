import React from "react";
import PropTypes from "prop-types";
import "./TextFieldGroupClearIcon.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const TextFieldGroupClearIcon = ({
  id,
  name,
  value,
  onSearch,
  onClear,
  placeHolder,
  colCount,
  searchIcon,
}) => {
  const column = colCount ? `col-md-${colCount}` : "";
  return (
    <div>
      <div className={`form-group ${column}`}>
        <input
          id={id}
          tabIndex={0}
          type="text"
          name={name}
          className="searchfield"
          placeholder={value.length > 0 ? value : placeHolder}
          onChange={onSearch}
          value={value}
        />
        {searchIcon && value === "" && (
          <div className="text-field-search-icon">
            <FontAwesomeIcon icon={faSearch} size="lg" />
          </div>
        )}
        {value !== "" && (
          <span id="searchCleanBtnNavn" onClick={onClear} role="button">
            <span className="fa fa-times"></span>
          </span>
        )}
      </div>
    </div>
  );
};

TextFieldGroupClearIcon.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  placeHolder: PropTypes.string,
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  onSearch: PropTypes.func,
  onClear: PropTypes.func,
  colCount: PropTypes.number,
  searchIcon: PropTypes.bool,
};
TextFieldGroupClearIcon.defaultProps = {
  placeHolder: "Søk...",
  searchIcon: false,
};

export default TextFieldGroupClearIcon;
