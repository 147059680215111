import React from "react";
import { AuthProvider } from "react-oidc-context";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import packageJson from "../package.json";
import { oidcConfig } from "./constants/api/autentiseringService/index";

import "./pollyfills";

// Import external styles
import "leaflet/dist/leaflet.css";
import "bootstrap/dist/css/bootstrap.css";

// Import internal styles
import "./styles/common.css";
import "./styles/Datetime.css";

import configureStore from "./store/configureStore";
import AppContainer from "./AppContainer";

require("moment/locale/nb");

// esri-leaflet error thrown as proj4 is not global (create-react-app shit?!)
window.proj4 = require("proj4");

console.log(packageJson.version); // ex: "1.0.0"

export const store = configureStore();

const getUserConfirmation = (dialogKey, callback) => {
  const dialogTrigger = window[Symbol.for(dialogKey)];

  if (dialogTrigger) {
    // pass the callback to delegate
    // to the invoked dialog
    return dialogTrigger(callback);
  }

  // Fallback to allowing navigation
  callback(true);
};

createRoot(document.getElementById("root")).render(
  <AuthProvider {...oidcConfig}>
    <Provider store={store}>
      <BrowserRouter getUserConfirmation={getUserConfirmation}>
        <AppContainer />
      </BrowserRouter>
    </Provider>
  </AuthProvider>
);

// Can't get it to work.
// Should only be used if not prod...
// if (module.hot) {
//   module.hot.accept("./AppContainer", () => {
//     const NextApp = require("./AppContainer").default;
//     ReactDOM.render(NextApp);
//   });
// }
