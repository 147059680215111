import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import App from "./App";

const mapStateToProps = (state) => ({
  vedtaker: state.vedtaker,
});

export default withRouter(connect(mapStateToProps, null)(App));
