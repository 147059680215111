import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ModalGeneric from "../../../Common/ModalGeneric";
import UpdateOpenModal from "../../CreateModals/UpdateOpenModal";
import VedtakskoderForm from "../../VedtakskoderForm";
import {
  putVedtakskoder,
  getVedtakskoder,
  addVedtakskodeIleggelseType,
  deleteVedtakskodeIleggelseType,
} from "../../../../actions/vedtakskoderAction";
import {
  IsValidated,
  onChangeValidation,
} from "../../../../utils/validationUtils";
import { map, difference } from "lodash";

export class VedtakskodeModal extends React.Component {
  static propTypes = {
    vedtakskode: PropTypes.object,
    selectedIleggelsestyper: PropTypes.array,
  };

  get initState() {
    const { vedtakskode, selectedIleggelsestyper } = this.props;
    return {
      open: false,
      submitButtonText: "Lagre",
      id: vedtakskode.id,
      kode: vedtakskode.kode,
      navn: vedtakskode.navn,
      medhold: "",
      deaktivertDato: vedtakskode.deaktivertDato
        ? vedtakskode.deaktivertDato
        : "",
      tilkemner: vedtakskode.tilkemner ? vedtakskode.tilkemner : false,
      selectedIleggelsestyper: selectedIleggelsestyper,
      modifiedIleggelsestyper: selectedIleggelsestyper,
      klageKodeId: vedtakskode.klagekode ? vedtakskode.klagekode.id : 0,
      validationError: {},

      openDeaktiverConfirm: false,
      deaktiveringConfirmed: false,
    };
  }
  state = this.initState;
  rules = {
    id: "required;isDigit",
    kode: "required;minLength=2;maxLength=50",
    navn: "required;minLength=2;maxLength=50",
    klageKodeId: "required",
    ileggelsestype: "required",
    deaktivertDato: "required;isDato",
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { deaktiveringConfirmed } = this.state;
    if (
      deaktiveringConfirmed === true &&
      prevState.deaktiveringConfirmed !== deaktiveringConfirmed
    ) {
      this.saveAndClose();
    }
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (JSON.stringify(nextProps) !== JSON.stringify(this.props)) {
      let vedtakskode = nextProps.vedtakskode;
      if (vedtakskode) {
        this.setState({
          id: vedtakskode.id,
          kode: vedtakskode.kode,
          navn: vedtakskode.navn,
          deaktivertDato: vedtakskode.deaktivertDato
            ? vedtakskode.deaktivertDato
            : "",
          klageKodeId: vedtakskode.klagekode ? vedtakskode.klagekode.id : 0,
          tilkemner: vedtakskode.tilkemner ? vedtakskode.tilkemner : false,
          selectedIleggelsestyper: nextProps.selectedIleggelsestyper,
          modifiedIleggelsestyper: nextProps.selectedIleggelsestyper,
        });
      }
    }
  };

  closeModal = () => {
    this.setState(this.initState);
  };

  openModal = () => {
    this.setState({ open: true });
  };

  isValid = () => {
    const { isValid, validationError } = IsValidated(this.state, this.rules);
    this.setState({ validationError });
    return isValid;
  };

  generateIleggesestyperPayload = () => {
    const { selectedIleggelsestyper, modifiedIleggelsestyper } = this.state;
    const IleggelsestypeIdsToDelete = difference(
      selectedIleggelsestyper,
      modifiedIleggelsestyper
    );
    const IleggelsestypeIdsToAdd = difference(
      modifiedIleggelsestyper,
      selectedIleggelsestyper
    );
    return {
      IleggelsestypeIdsToAdd: IleggelsestypeIdsToAdd,
      IleggelsestypeIdsToDelete: IleggelsestypeIdsToDelete,
    };
  };

  updateVedtakskodeIleggelsetyper = async (payload) => {
    const { IleggelsestypeIdsToAdd, IleggelsestypeIdsToDelete } =
      this.generateIleggesestyperPayload();
    const { vedtakskode } = this.props;
    if (IleggelsestypeIdsToAdd.length > 0) {
      await Promise.all(
        map(IleggelsestypeIdsToAdd, async (id) => {
          await this.props.addVedtakskodeIleggelseType(vedtakskode.id, id);
        })
      );
    }
    if (IleggelsestypeIdsToDelete.length > 0) {
      await Promise.all(
        map(IleggelsestypeIdsToDelete, async (id) => {
          await this.props.deleteVedtakskodeIleggelseType(vedtakskode.id, id);
        })
      );
    }
  };

  saveAndClose = async () => {
    const { deaktiveringConfirmed, deaktivertDato } = this.state;

    if (this.isValid() && (deaktiveringConfirmed || !deaktivertDato)) {
      this.setState({ submitButtonText: "Lagrer..." });

      const deaktivertDato = this.state.deaktivertDato
        ? this.state.deaktivertDato
        : null;

      const payload = {
        kode: this.state.kode,
        navn: this.state.navn,
        deaktivertDato,
        klageKodeId: this.state.klageKodeId && Number(this.state.klageKodeId),
      };

      await this.props.putVedtakskoder(this.state.id, payload);
      await this.updateVedtakskodeIleggelsetyper();
      await this.props.getVedtakskoder();
      this.closeModal();
    } else if (this.isValid() && deaktivertDato) {
      this.setState({ open: false, openDeaktiverConfirm: true });
    }
  };

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "ileggelsestype") {
      this.setState({ modifiedIleggelsestyper: value });
    }
    this.setState({ [name]: value });
    onChangeValidation(e, this.state, this.rules);
  };

  render() {
    const { submitButtonText, openDeaktiverConfirm } = this.state;

    return (
      <>
        <ModalGeneric
          modalTitle="Rediger vedtakskode"
          open={this.state.open}
          closeModal={this.closeModal}
          openModal={this.openModal}
          OpenModalComponent={UpdateOpenModal}
          submit={this.saveAndClose}
          submitButtonText={submitButtonText}
        >
          <VedtakskoderForm
            id={this.state.id}
            kode={this.state.kode}
            navn={this.state.navn}
            tilkemner={this.state.tilkemner}
            klageKodeId={this.state.klageKodeId}
            deaktivertDato={this.state.deaktivertDato}
            klagekoder={this.props.klagekoder}
            ileggelsestyper={this.props.ileggelsestyper}
            ileggelsestype={this.state.modifiedIleggelsestyper}
            handleChange={this.handleChange}
            validationError={this.state.validationError}
            actionType="update"
          />
        </ModalGeneric>
        <ModalGeneric
          modalTitle="Deaktiver vedtakskode"
          open={openDeaktiverConfirm}
          closeModal={() =>
            this.setState({ openDeaktiverConfirm: false, open: true })
          }
          submit={() => this.setState({ deaktiveringConfirmed: true })}
          submitButtonText={this.state.submitButtonText}
        >
          <p>Vil du deaktivere vedtakskode {this.state.id}?</p>
        </ModalGeneric>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ileggelsestyper: state.ileggelsestyper,
    vedtakskoder: state.vedtakskoder,
    klagekoder: state.klagekoder,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(
    {
      putVedtakskoder,
      getVedtakskoder,
      addVedtakskodeIleggelseType,
      deleteVedtakskodeIleggelseType,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(VedtakskodeModal);
