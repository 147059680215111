import React, { Component } from "react";
import PropTypes from "prop-types";
import { Collapse, Row, Col } from "react-bootstrap";
import "./Collapsible.css";
import SpinLoader from "./SpinLoader";
import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Collapsible extends Component {
  static propTypes = {
    title: PropTypes.any,
    elm: PropTypes.any,
    children: PropTypes.any,
    id: PropTypes.string,
    isLoading: PropTypes.bool,
  };

  state = {
    open: null,
  };

  spinLoaderSpanWrapperStyle = { display: "inline-block", marginLeft: 10 };

  spinnerLoaderProps = {
    height: 16,
    width: 16,
    thickness: 3,
    pColor: "#ffffff",
    sColor: "#4d4d4d",
  };

  toggleOpen = () => {
    const { id } = this.props;
    this.setState({ open: this.state.open ? null : id });
  };

  renderToggleColumn = () => {
    const { title, elm, id, isLoading } = this.props;
    const { open } = this.state;
    const toggle = open === id;
    if (toggle)
      return (
        <div className="collapsibleDiv">
          <span>{elm}</span>
          <FontAwesomeIcon
            icon={faCaretDown}
            size="lg"
            fixedWidth
            style={{ cursor: "pointer" }}
            onClick={this.toggleOpen}
          />
          <span className="title" onClick={this.toggleOpen}>
            {title}
            {isLoading && (
              <span style={this.spinLoaderSpanWrapperStyle}>
                <SpinLoader {...this.spinnerLoaderProps} />
              </span>
            )}
          </span>
        </div>
      );
    else
      return (
        <div className="collapsibleDiv">
          <span>{elm}</span>
          <FontAwesomeIcon
            icon={faCaretRight}
            size="lg"
            fixedWidth
            style={{ cursor: "pointer" }}
            onClick={this.toggleOpen}
          />
          <span className="title" onClick={this.toggleOpen}>
            {title}
            {isLoading && (
              <span style={this.spinLoaderSpanWrapperStyle}>
                <SpinLoader {...this.spinnerLoaderProps} />
              </span>
            )}
          </span>
        </div>
      );
  };
  renderCollapsibleContent = () => {
    const { children, id } = this.props;
    const { open } = this.state;
    return (
      <Collapse in={open === id}>
        <Row>{children}</Row>
      </Collapse>
    );
  };

  render() {
    const { id } = this.props;
    return (
      <div id={id} className="collapsible">
        <Row className="titleRow">
          <Col>{this.renderToggleColumn()}</Col>
        </Row>
        <Row className="ContentRow">
          <Col> {this.renderCollapsibleContent()}</Col>
        </Row>
      </div>
    );
  }
}

export default Collapsible;
