import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Klageinformasjon from "../../Detalj/Klageinformasjon";

import { getIleggelseKlager } from "../../../../actions/ileggelserActions";

class KlageinformasjonContainer extends React.Component {
  static propTypes = {
    ileggelseId: PropTypes.string,
    ileggelseKlager: PropTypes.arrayOf(PropTypes.object),
  };

  componentDidMount() {
    this.props.ileggelseId &&
      this.props.getIleggelseKlager(this.props.ileggelseId);
  }

  render() {
    const { ileggelseKlager } = this.props;
    return <Klageinformasjon klager={ileggelseKlager} />;
  }
}

const mapStateToProps = (state) => {
  return {
    ileggelseKlager: state.ileggelseKlager,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getIleggelseKlager }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KlageinformasjonContainer);
