import React from "react";
import PropTypes from "prop-types";
import TextInput from "../../Common/Forms/TextInput";

const UferdigstilteKlagesakerFilterForm = (props) => {
  return (
    <div className="filter-container">
      <h1>Søk i tabellen</h1>
      <div style={{ maxWidth: 200 }}>
        <TextInput
          id="saksbehandler"
          name="saksbehandler"
          label="Saksbehandler"
          placeholder="Saksbehandler"
          value={props.saksbehanler}
          onChange={(event) => props.setSaksbehandler(event.target.value)}
        />
      </div>
    </div>
  );
};

UferdigstilteKlagesakerFilterForm.propTypes = {
  saksbehandler: PropTypes.string,
  setSaksbehandler: PropTypes.func.isRequired,
};

export default UferdigstilteKlagesakerFilterForm;
