import { CreateNewReducer } from "../utils/reducerUtils";
import { KLAGE_ILEGGELSE_THUMBNAIL_IMAGE } from "../actions/klager/klageAction";

const INITIAL_STATE = [];

export const klageIleggelseThumbnailImageReducer = (
  state = INITIAL_STATE,
  action
) => CreateNewReducer(state, action, KLAGE_ILEGGELSE_THUMBNAIL_IMAGE);

export default klageIleggelseThumbnailImageReducer;
