import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import VentilstillingFilterContainer from "./VentilstillingFilterContainer";
import VentilstillingExcel from "./VentilstillingExcel";
import VentilstillingList from "./VentilstillingList";
import { getVentilstillinger } from "../../../actions/ventilstillingerActions";
import NavigationBarContainer from "../NavigationBarContainer";
import * as title from "../../../constants/titles";

class VentilstillingPage extends Component {
  static propTypes = {
    ventilstillinger: PropTypes.array,
    getVentilstillinger: PropTypes.func,
  };

  componentDidMount() {
    document.title = title.rapporter.ventilstillingskontroll;
  }

  render() {
    const { ventilstillingerSubmittedForm, ventilstillinger } = this.props;

    const antallVentilstillinger = ventilstillinger
      ? ventilstillinger.length
      : 0;

    return (
      <Container fluid>
        <Row>
          <Col>
            <NavigationBarContainer page="ventilstillingskontroll" />
            <div className="rapportSearch">
              <VentilstillingFilterContainer />
            </div>
            <div
              className="rapport-buttons-container"
              style={{
                display: "flex",
              }}
            >
              <div>
                <VentilstillingExcel
                  ventilstillingerSubmittedForm={ventilstillingerSubmittedForm}
                  ventilstillinger={ventilstillinger}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-end",
                  fontWeight: "bold",
                  marginTop: "auto",
                }}
              >
                {antallVentilstillinger} ventilstillinger
              </div>
            </div>
            <div className="rapport-table">
              <VentilstillingList
                ventilstillinger={ventilstillinger}
                id="ventilstillingDiv"
              />
            </div>
          </Col>
          <div style={{ height: 28 }} />
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  ventilstillingerSubmittedForm:
    state.submittedForms.ventilstillingerSubmittedForm,
  bybetjentgrupper: state.bybetjentgrupper,
  ventilstillinger: state.ventilstillinger,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getVentilstillinger }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(VentilstillingPage);
