import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import shortid from "shortid";
import { Link } from "react-router-dom";

import LoadButton from "../LoadButton";

import { getBrevstatistikkHistorikk } from "../../../actions/brevstatistikkActions";

import { toNorwegianDatetime } from "../../../utils/datetimeUtils";

const RenderRowEttersendelse = ({
  ettersendelse,
  authorized,
  getEttersendelserEttersendelsebrevPdf,
}) => {
  const dispatch = useDispatch();
  const tdIdSeBrev = shortid.generate();
  const [isFetchingBrev, setIsFetchingBrev] = useState(false);
  const [latestBrevstatistikkHistorikk, setLatestBrevstatistikkHistorikk] =
    useState({});

  const spinnerPropsRef = useRef({});
  useEffect(() => {
    const tdElementSeBrev = document.getElementById(tdIdSeBrev);
    if (tdElementSeBrev) {
      const style = getComputedStyle(tdElementSeBrev);
      spinnerPropsRef.current = { pColor: style.backgroundColor };
    }
  });

  useEffect(() => {
    if (authorized) {
      const fetchBrevstatistikkHistorikk = async () => {
        if (ettersendelse && ettersendelse.ettersendelseBrev) {
          const result = await dispatch(
            getBrevstatistikkHistorikk(
              ettersendelse.ettersendelseBrev.svarutReferanseId
            )
          );

          const latestBrevstatistikkHistorikk =
            getLatestBrevstatistikkHistorikk(result);
          setLatestBrevstatistikkHistorikk(latestBrevstatistikkHistorikk);
        }
      };
      if (
        ettersendelse?.ettersendelseBrev &&
        authorized?.isAuthorizedEttersendelsebrev
      ) {
        fetchBrevstatistikkHistorikk();
      }
    }
  }, [authorized, ettersendelse, dispatch]);

  const getLatestBrevstatistikkHistorikk = (brevstatistikkHistorikk) => {
    if (brevstatistikkHistorikk && brevstatistikkHistorikk.length > 0) {
      return brevstatistikkHistorikk[brevstatistikkHistorikk.length - 1];
    }
  };

  const getBrev = () => {
    if (!ettersendelse || !ettersendelse.ettersendelseBrev) return null;
    setIsFetchingBrev(true);
    getEttersendelserEttersendelsebrevPdf(
      ettersendelse.ettersendelseBrev.id
    ).then(() => setIsFetchingBrev(false));
  };

  return (
    <tr>
      <td>{ettersendelse && ettersendelse.kommentar}</td>
      <td>{ettersendelse && ettersendelse.tjenestenummer}</td>
      <td>
        {ettersendelse &&
          ettersendelse.ettersendelseDato &&
          toNorwegianDatetime(ettersendelse.ettersendelseDato).format(
            "DD.MM.YYYY HH:mm"
          )}
      </td>
      <td>
        {ettersendelse &&
          ettersendelse.ettersendelseBrev &&
          ettersendelse.ettersendelseBrev.saksnummer}
      </td>
      <td>
        {ettersendelse &&
          ettersendelse.ettersendelseBrev &&
          ettersendelse.ettersendelseBrev.opprettetAvBruker}
      </td>
      <td>
        {ettersendelse &&
          ettersendelse.ettersendelseBrev &&
          ettersendelse.ettersendelseBrev.datoLagretIp360 &&
          toNorwegianDatetime(
            ettersendelse.ettersendelseBrev.datoLagretIp360
          ).format("DD.MM.YYYY HH:mm")}
      </td>
      {authorized && authorized.isAuthorizedEttersendelsebrev && (
        <td>
          {ettersendelse?.ettersendelseBrev && (
            <Link
              to={`${window.location.pathname}/svar-ut/${ettersendelse.ettersendelseBrev.svarutReferanseId}/historikk`}
              style={{ textDecoration: "none" }}
            >
              {ettersendelse.ettersendelseBrev.svarutReferanseId}
            </Link>
          )}
        </td>
      )}
      {authorized && authorized.isAuthorizedEttersendelsebrev && (
        <td>
          {latestBrevstatistikkHistorikk &&
            latestBrevstatistikkHistorikk["tidspunkt"] && (
              <>{latestBrevstatistikkHistorikk["tidspunkt"]}</>
            )}
        </td>
      )}
      {authorized && authorized.isAuthorizedEttersendelsebrev && (
        <td>
          {latestBrevstatistikkHistorikk &&
            latestBrevstatistikkHistorikk["hendelse"] && (
              <>{latestBrevstatistikkHistorikk["hendelse"]}</>
            )}
        </td>
      )}
      {authorized && authorized.isAuthorizedEttersendelsebrev && (
        <td id={tdIdSeBrev}>
          {ettersendelse &&
            ettersendelse.ettersendelseBrev &&
            getEttersendelserEttersendelsebrevPdf && (
              <LoadButton
                buttonProps={{ bsStyle: "link", onClick: getBrev }}
                isLoading={isFetchingBrev}
                spinnerProps={spinnerPropsRef.current}
              >
                Se brev
              </LoadButton>
            )}
        </td>
      )}
    </tr>
  );
};

export default RenderRowEttersendelse;
