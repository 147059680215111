import * as ileggelseEttersendelserActions from "../actions/ileggelseEttersendelserActions";

const INITIAL_STATE = [];

export default function ileggelseEttersendelserReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case ileggelseEttersendelserActions.GET_ILEGGELSE_ETTERSENDELSER_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
