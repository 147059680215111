import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useAuth } from "react-oidc-context";

export const PublicRoute = ({
  component: Comp,
  forcePublicRoute = false,
  ...rest
}) => {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.isAuthenticated || forcePublicRoute) {
    return <Route {...rest} children={(props) => <Comp {...props} />} />;
  }

  return (
    <Redirect
      to={{
        pathname: "/",
        state: { from: location },
      }}
    />
  );
};

export default PublicRoute;
