import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import NavigationBar from "./NavigationBar";

import InnkrevingIleggelser from "./InnkrevingIleggelser";
import InnkrevingMiljogebyr from "./InnkrevingMiljogebyr";

import { getIneIleggelseReports } from "../../actions/ineReportsActions";

import sitePaths from "../../constants/sitePaths";
import { innkreving as titleInnkreving } from "../../constants/titles";

import "./innkrevingPageStyles.css";

const InnkrevingPage = () => {
  const [reportCountData, setReportCountData] = useState(undefined);
  // const [reportData, setReportData] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const reportData = useSelector((state) => state.innkreving);

  const dispatch = useDispatch();

  useEffect(() => {
    document.title = titleInnkreving(window.location.pathname);
    setIsLoading(true);

    dispatch(getIneIleggelseReports("COUNT_ONLY")).then((data) =>
      setReportCountData(data)
    );

    dispatch(getIneIleggelseReports("ALL_DETAILS"))
      .then(() => {
        // setReportData(data);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, [setReportCountData, setIsLoading, dispatch]);

  return (
    <div>
      <NavigationBar />
      <Switch>
        <Route exact path={sitePaths.innkreving.ileggelser.page}>
          <InnkrevingIleggelser
            reportCountData={reportCountData}
            reportData={reportData}
            isLoading={isLoading}
          />
        </Route>
        <Route exact path={sitePaths.innkreving.miljogebyr.page}>
          <InnkrevingMiljogebyr
            reportCountData={reportCountData}
            reportData={reportData}
            isLoading={isLoading}
          />
        </Route>
      </Switch>
    </div>
  );
};

export default InnkrevingPage;
